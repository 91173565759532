import { Component } from '@angular/core';
import { AccountTypeService } from 'src/app/services/account-type/account-type.service';
import { AccountService } from 'src/app/services/account/account.service';
import { DataModelService } from '../../../../services/data-model/data-model.service';
import { AccountDetailsComponent } from '../account-details/account-details.component';

@Component({
  selector: 'app-auto-insurance-account-details',
  templateUrl: './auto-insurance-account-details.component.html',
  styleUrls: ['../account-details-template/account-details-template.component.scss']
})
export class AutoInsuranceAccountDetailsComponent extends AccountDetailsComponent {
  protected extraYouPayValue = 0;

  constructor(public dataModelService: DataModelService, accountTypeService: AccountTypeService, accountService: AccountService) {
    super(dataModelService, accountTypeService, accountService);
  }

  protected onPremiumChange(value: string) {
    this.wizeFiAccount.monthlyMinimum.val = parseFloat(value) || 0;
  }
}
