import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { isUndefined } from 'lodash';
import * as moment from 'moment';
import { IAccount } from '../../../interfaces/iAccount.interface';
import { DataModelService } from '../../../services/data-model/data-model.service';

@Component({
  selector: 'app-category-list-item',
  templateUrl: './category-list-item.component.html',
  styleUrls: ['./category-list-item.component.scss']
})
export class CategoryListItemComponent implements OnInit {
  @Input() public categoryGroup: string; // todo: change name to category
  @Input() public categoryKey: string; // todo: change name to subcategory
  public category: { label: string; accounts: IAccount[] };
  public isShowingDropdown = false;
  public categorySum = 0;
  public remainingSum = 0;

  spentLastMonthSum = 0;

  protected lastMonthsYearMonth = moment(new Date()).subtract(1, 'month').toISOString().substr(0, 7);
  protected dateRange = {
    wantMonthRange: true,
    yearMonth: this.lastMonthsYearMonth
  };

  constructor(protected dataModelService: DataModelService, protected router: Router, protected renderer: Renderer2) {}

  public ngOnInit() {
    this.category = this.getCategory();
    this.sumCategory();
    this.sumSpentLastMonthSum();
  }

  public setRowClasses(review = false) {
    return {
      'category-item': true,
      'round-bottom-corners': !this.isShowingDropdown,
      'review-rows': review
    };
  }

  public setArrowIconClasses(review = false) {
    if (review === false) {
      return {
        'vertically-flipped': this.isShowingDropdown
      };
    } else {
      return {
        invisible: true
      };
    }
  }

  public toggleDropdown(review = false, state = null) {
    if (review === false && state == null) {
      this.isShowingDropdown = !this.isShowingDropdown;
    }
    if (state != null) {
      this.isShowingDropdown = state;
    }
  }

  public getCategory(): { label: string; accounts: IAccount[] } {
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;
    return this.dataModelService.dataModel.persistent.plans[curplan][this.categoryGroup][this.categoryKey];
  }

  public sumSpentLastMonthSum() {
    let sum = 0;
    this.category.accounts.forEach(acct => {
      sum += acct.actualMonthlyAmount.val;
    });
    this.spentLastMonthSum = sum;
    return sum;
  }

  public sumCategory() {
    let sum = 0;
    //console.log(this.category);
    this.category.accounts.forEach(acct => {
      //console.log(acct.monthlyAmount.val);
      sum += acct.monthlyAmount.val;
    });
    //console.log(sum);
    this.categorySum = sum;
    return sum;
  }
}
