import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-setup-step-menu-item',
  templateUrl: './setup-step-menu-item.component.html',
  styleUrls: ['./setup-step-menu-item.component.scss']
})
export class SetupStepMenuItemComponent implements OnInit {
  @Output() public onStepClicked: EventEmitter<any> = new EventEmitter<any>();
  @Input() public state: string;
  @Input() public stepNumber: number;
  @Input() public stepName: string;

  constructor() {}

  public ngOnInit() {}
  public setActiveClass() {
    return {
      active: this.state === 'inProgress'
    };
  }
  public setItemClasses() {
    return {
      'active-wrapper': this.state === 'inProgress'
    };
  }

  public setNumberClasses() {
    return {
      'step-number': true
    };
  }

  public setNameClasses() {
    return {};
  }

  protected _onStepClicked() {
    if (this.state !== 'locked') {
      this.onStepClicked.emit(this.stepNumber);
    }
  }
}
