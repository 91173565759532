import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { NetWorthProjectionChartComponent } from '../../charts/net-worth-projection-chart/net-worth-projection-chart.component';
import { ProjectionChartComponent } from '../../charts/projection-chart/projection-chart.component';

@Component({
  selector: 'app-net-worth-projection',
  templateUrl: './net-worth-projection.component.html',
  styleUrls: ['./net-worth-projection.component.scss']
})
export class NetWorthProjectionComponent implements OnInit {
  @ViewChild(NetWorthProjectionChartComponent, { static: false })
  public chart: ProjectionChartComponent;
  public shouldShowDebtProjections = true;

  protected dob: string;
  protected ffWealth: any;
  protected debtGoal = 0;
  protected goalDates: any;
  protected chartDuration: number; // months
  protected aWealth: any;

  // net worth
  protected wizeFiFFDate: string;
  protected currentFFDate: string;
  protected originalFFDate: string;
  protected wizeFiFFAge: number;
  protected originalWizeFiFFAge: number;

  protected currentFFAge: number;
  protected originalFFAge: number;
  protected wizeFiFFData: any;
  protected currentFFData: any;
  protected originalFFData: any;
  // debt
  protected wizeFiDebtDate: string;
  protected currentDebtDate: string;
  protected originalDebtDate: string;
  protected wizeFiDebtAge: number;
  protected currentDebtAge: number;
  protected originalDebtAge: number;
  protected wizeFiDebtData: any;
  protected currentDebtData: any;
  protected originalDebtData: any;

  protected black = '#2f2f2f';
  protected darkBlue = '#385097';
  protected lightBlue = '#4272ff';
  protected purple = '#5a4dbf';

  public static readonly MAXIMUM_AGE = 999;
  public readonly MAXIMUM_AGE = NetWorthProjectionComponent.MAXIMUM_AGE;

  constructor(protected dataModelService: DataModelService) {}

  public ngOnInit() {
    this.setupPrerequisiteData();
    this.initWizeFiProjectionData();
    this.initCurrentProjectionData();
    this.initOriginalProjectionData();
    this.calculateNetWorthAges();
    this.calculateDebtAges();
    this.truncateDebtData();
    this.truncateNetWorthData();
    // this.logDebtData();
  }

  public setupPrerequisiteData() {
    this.dob = this.dataModelService.dataModel.persistent.profile.birthDate;
    this.ffWealth = this.dataModelService.dataModel.persistent.profile.financialFreedomTargetAmount;
  }

  protected initWizeFiProjectionData() {
    // // console.log("run initWizeFiProjectionData")
    // // console.log("ff wealth", this.ffWealth)
    const guidelineDataProjInfo = this.dataModelService.cafrManagement.getCafrDataProjectionInfo(
      {
        terminationType: 'financialFreedomTargetAmount',
        terminationValue: this.ffWealth
      },
      'guideline'
    );
    // // console.log("guideline projection info", guidelineDataProjInfo);
    // // console.log("wizefiFFData", guidelineDataProjInfo.netWorth.summary.projection);
    this.wizeFiFFData = guidelineDataProjInfo.netWorth.summary.projection;
    this.wizeFiDebtData = guidelineDataProjInfo.allDebt.summary.projection;
    // console.log("ff data", this.wizeFiFFData);
  }

  protected initCurrentProjectionData() {
    // // console.log("initCurrentProjectionData")
    const actualDataProjInfo = this.dataModelService.cafrManagement.getCafrDataProjectionInfo(
      {
        terminationType: 'financialFreedomTargetAmount',
        terminationValue: this.ffWealth
      },
      'actual'
    );
    // // console.log("actual projection info", actualDataProjInfo)
    this.currentFFData = actualDataProjInfo.netWorth.summary.projection;
    this.currentDebtData = actualDataProjInfo.allDebt.summary.projection;
  }

  protected initOriginalProjectionData() {
    // console.log("initOriginalProjectionData")

    // temporarily save current plan while we grab original projection information
    const savedPlan = this.dataModelService.dataModel.persistent.header.curplan;

    this.dataModelService.setCurPlan('original');
    const originalDataProjInfo = this.dataModelService.cafrManagement.getDataProjection(
      'netWorth',
      this.getPrelimChartDuration(),
      this.dataModelService.dataModel.persistent.plans.original.assets,
      this.dataModelService.dataModel.persistent.plans.original.assetProtection,
      this.dataModelService.dataModel.persistent.plans.original.liabilities
    );
    const originalDebtDataProjInfo = this.dataModelService.cafrManagement.getDataProjection(
      'allDebt',
      this.getPrelimChartDuration(),
      this.dataModelService.dataModel.persistent.plans.original.assets,
      this.dataModelService.dataModel.persistent.plans.original.assetProtection,
      this.dataModelService.dataModel.persistent.plans.original.liabilities
    );
    // console.log("chart duration", this.getPrelimChartDuration())
    // console.log("original data projection info", originalDataProjInfo);
    this.originalFFData = originalDataProjInfo.summary.projection;
    this.originalDebtData = originalDebtDataProjInfo.summary.projection;

    // restore current plan after generating original projections
    this.dataModelService.setCurPlan(savedPlan);
  }

  protected calculateNetWorthAges() {
    // console.log(this.wizeFiFFData);
    // console.log(this.currentFFData);
    // console.log(this.originalFFData);
    // get date of last item in each projection (date that user reaches FFA)
    this.wizeFiFFDate = this.wizeFiFFData[this.wizeFiFFData.length - 1].x;
    const guidelinePlanReachedFF = this.wizeFiFFData[this.wizeFiFFData.length - 1].y >= this.ffWealth;
    this.currentFFDate = this.currentFFData[this.currentFFData.length - 1].x;
    const currentPlanReachedFF = this.currentFFData[this.currentFFData.length - 1].y >= this.ffWealth;
    this.originalFFDate = this.originalFFData[this.originalFFData.length - 1].x;
    const originalPlanReachedFF = this.originalFFData[this.originalFFData.length - 1].y >= this.ffWealth;

    const originalFFDateIdx = this.originalFFData.findIndex(data => data.y >= this.ffWealth);

    if (originalFFDateIdx > 0) {
      this.originalFFDate = this.originalFFData[originalFFDateIdx].x;
    }

    // calculate how old user will be at A wealth for each projection
    this.wizeFiFFAge = guidelinePlanReachedFF ? this.dateDiff(this.dob, this.wizeFiFFDate) : this.MAXIMUM_AGE;
    this.currentFFAge = currentPlanReachedFF ? this.dateDiff(this.dob, this.currentFFDate) : this.MAXIMUM_AGE;
    this.originalFFAge = originalPlanReachedFF ? this.dateDiff(this.dob, this.originalFFDate) : this.MAXIMUM_AGE;
  }

  public yrsToAWealth(data) {
    const yr = this.aWealthDateOriginal(data); // returns zero if never
    return yr ? this.dateDiff(new Date().toString(), new Date((yr as any).x).toString()) : 0; // zero if never
  }

  public aWealthDateOriginal(data) {
    const aTarget = this.aWealth;
    const yr = _.find(data, d => (d as any).y > aTarget);
    return typeof yr === 'undefined' || !yr.hasOwnProperty('x') ? 0 : new Date((yr as any).x);
  }

  protected calculateDebtAges() {
    const firstCurrentZeroIndex = this.currentDebtData.find(item => item.y === 0);
    const firstOriginalZeroIndex = this.originalDebtData.find(item => item.y === 0);
    const firstWizeFiZeroIndex = this.wizeFiDebtData.find(item => item.y === 0);

    if (firstCurrentZeroIndex) {
      this.currentDebtDate = firstCurrentZeroIndex.x;
    }
    if (firstOriginalZeroIndex) {
      this.originalDebtDate = firstOriginalZeroIndex.x;
    }
    if (firstWizeFiZeroIndex) {
      this.wizeFiDebtDate = firstWizeFiZeroIndex.x;
    }

    this.currentDebtAge = firstCurrentZeroIndex ? this.dateDiff(this.dob, firstCurrentZeroIndex.x) : this.MAXIMUM_AGE;
    this.originalDebtAge = firstOriginalZeroIndex ? this.dateDiff(this.dob, firstOriginalZeroIndex.x) : this.MAXIMUM_AGE;
    this.wizeFiDebtAge = firstWizeFiZeroIndex ? this.dateDiff(this.dob, firstWizeFiZeroIndex.x) : this.MAXIMUM_AGE;
  }

  protected dataUpToFFWealth = (data: { x: Date; y: number }) => data.y <= this.ffWealth;
  protected dataUpToZeroDebt = (data: { x: Date; y: number }) => data.y > this.debtGoal;

  // truncates debt data so that
  protected truncateDebtData() {
    const wizeFiZeroIndex = this.wizeFiDebtData.findIndex(data => data.y === 0);
    const currentZeroIndex = this.currentDebtData.findIndex(data => data.y === 0);
    const originalZeroIndex = this.originalDebtData.findIndex(data => data.y === 0);

    if (wizeFiZeroIndex !== -1) {
      this.wizeFiDebtData = this.wizeFiDebtData.slice(0, wizeFiZeroIndex + 12);
    }

    if (currentZeroIndex !== -1) {
      this.currentDebtData = this.currentDebtData.slice(0, currentZeroIndex + 12);
    }

    if (originalZeroIndex !== -1) {
      this.originalDebtData = this.originalDebtData.slice(0, originalZeroIndex + 12);
    }
  }

  protected truncateNetWorthData() {
    const durationInMonths = this.getChartDuration();
    this.wizeFiFFData = this.wizeFiFFData.slice(0, durationInMonths);
    this.currentFFData = this.currentFFData.slice(0, durationInMonths);
    this.originalFFData = this.originalFFData.slice(0, durationInMonths);
  }

  protected getPrelimChartDuration() {
    const curDate = new Date().toISOString();
    const maxAge = 100; // Math.min(this.originalFFAge,100); // we can also lock at 100 by setting this to 100
    const age = this.dateDiff(this.dob, curDate);
    // console.log(this.originalFFAge, age, maxAge);
    const duration = (maxAge - Math.floor(age)) * 12;
    return duration;
  }

  protected getChartDuration() {
    const curDate = new Date().toISOString();
    const maxAge = Math.min(this.originalFFAge, 100) + 1; // Math.min(this.originalFFAge, 100) + 1;
    const age = this.dateDiff(this.dob, curDate);
    const duration = (maxAge - Math.floor(age)) * 12;
    return duration;
  }

  // date differece in number of years; a and b are javascript Date objects
  protected dateDiff(date1: string, date2: string): any {
    const a = new Date(date1);
    const b = new Date(date2);
    const msYr = 1000 * 60 * 60 * 24 * 365;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.round(((utc2 - utc1) / msYr) * 100) / 100;
  }
  protected roundNumber(value) {
    return Math.round(value);
  }
  protected getYears(age: number, abs: boolean = false): number | string {
    // console.log("AGE", age)
    let value = 0;
    if (age === this.MAXIMUM_AGE) {
      return this.MAXIMUM_AGE;
    } else {
      if (isNaN(age)) {
        value = Math.floor(this.currentDebtAge);
        if (this.getMonths(age, false, true) === 12) {
          value += 1;
        }
      } else {
        value = Math.floor(age);
        if (this.getMonths(age, false, true) === 12) {
          value += 1;
        }
      }
      return abs ? Math.round(Math.abs(value)) : Math.round(value);
    }
  }

  protected getMonths(age: number, abs: boolean = false, skipCheck = false): number | string {
    let value;

    if (isNaN(age)) {
      value = Math.round((this.currentDebtAge % 1) * 12);
      if (!skipCheck && value === 12) {
        return 0;
      }
    } else {
      value = Math.round((age % 1) * 12);
      if (!skipCheck && value === 12) {
        return 0;
      }
    }
    return abs ? Math.abs(value) : value;
  }

  public toggleWizeFiData() {
    const lineIndex = this.chart.lineChartData.findIndex(dataset => dataset.backgroundColor === this.purple);
    const endpointIndex = this.chart.lineChartData.findIndex(dataset => dataset.pointBorderColor === this.purple);
    this.chart.toggleDataAtDatasetIndex(lineIndex);
    this.chart.toggleDataAtDatasetIndex(endpointIndex);
  }

  public toggleOriginalCAFRData() {
    const lineIndex = this.chart.lineChartData.findIndex(dataset => dataset.backgroundColor === this.black);
    const endpointIndex = this.chart.lineChartData.findIndex(dataset => dataset.pointBorderColor === this.black);
    this.chart.toggleDataAtDatasetIndex(lineIndex);
    this.chart.toggleDataAtDatasetIndex(endpointIndex);
  }

  protected logNetWorthData() {
    // console.log("GUIDELINE NET WORTH:");
    // console.log(this.wizeFiFFData);
    // console.log("CURRENT NET WORTH:");
    // console.log(this.currentFFData);
    // console.log("ORIGINAL NET WORTH:");
    // console.log(this.originalFFData);
  }

  protected logDebtData() {
    // console.log("GUIDELINE DEBT:");
    // console.log(this.wizeFiDebtData);
    // console.log("CURRENT DEBT:");
    // console.log(this.currentDebtData);
    // console.log("ORIGINAL DEBT:");
    // console.log(this.originalDebtData);
  }
}
