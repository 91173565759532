import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

const baseSetupPath = '/setup';

@Component({
  selector: 'app-setup-flow-header',
  templateUrl: './setup-flow-header.component.html',
  styleUrls: ['./setup-flow-header.component.scss']
})
export class SetupFlowHeaderComponent implements OnInit {
  public headerState: {
    name: string; // for displaying step name
    step: number;
  };
  public isSetupScreen: boolean;

  // URL Paths for all setup screens

  protected stepOnePaths = [
    ['connect-bank'], // step 1, substep 1
    ['review-accounts'] // step 1, substep 2
  ];
  protected stepTwoPaths = [['goals']];
  protected stepThreePaths = [['income']];
  protected stepFourPaths = [['spending']];
  protected stepFivePaths = [['review']];
  protected stepSixPaths = [['projections']];
  protected stepSevenPaths = [['create-cafr']];
  protected stepEightPaths = [['apply-cafr']];
  protected stepNinePaths = [['finish']];
  protected settings = [['settings']];

  protected allStepPaths = [
    this.stepOnePaths,
    this.stepTwoPaths,
    this.stepThreePaths,
    this.stepFourPaths,
    this.stepFivePaths,
    this.stepSixPaths,
    this.stepSevenPaths,
    this.stepEightPaths,
    this.stepNinePaths,
    this.settings
  ];
  protected stepNames = ['Accounts', 'Goals', 'Income', 'Spending', 'Review', 'Projections', 'Create CAFR', 'Apply CAFR', 'Finish'];

  constructor() {}

  public ngOnInit() {
    this.isSetupScreen = this.detectSetupScreen();
    if (this.isSetupScreen) {
      this.headerState = this.getHeaderState();
    }
  }

  public detectSetupScreen() {
    // return true if any of the paths for setup flow match the current url
    return _.flattenDeep(this.allStepPaths).some(path => window.location.href.indexOf(`${baseSetupPath}/${path}`) > -1);
  }

  protected getHeaderState = () => {
    const step = this.getStep();
    const name = this.getStepName(step);
    return { name, step };
  };

  protected getStep(): number {
    const allStepPathsIndex = this.allStepPaths.findIndex(pathsOfCurrentStep =>
      // get array of path names that correspond to the step of the current iteration of this loop
      _.flatten(pathsOfCurrentStep).some(
        path =>
          // if we are on
          window.location.href.indexOf(path) > -1
      )
    );
    const step = allStepPathsIndex + 1;
    return step;
  }

  protected getStepName(step): string {
    return this.stepNames[step];
  }
}
