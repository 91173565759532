import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { WizefiTransaction } from '../../../interfaces/wizefi-transaction';
import { AccountService } from '../../../services/account/account.service';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { TransactionRuleService } from '../../../services/transaction-rule/transaction-rule.service';
import { AccountTypeSelectorComponent, IAccountTypeSelectorData } from '../accounts/account-type-selector/account-type-selector.component';
import { EditTransactionCategoryComponent } from '../edit-transaction-category/edit-transaction-category.component';

const nameErrorMessage = 'Error saving transaction. Please enter a valid transaction name.';
const dateErrorMessage = 'Error saving transaction. Date must be within the past 90 days.';
const accountErrorMessgae = 'Error saving transaction. Please select an account.';
const categoryErrorMessage = 'Error saving transaction. Please select a type, category and subcategory.';
const amountErrorMessage = 'Error saving transaction. Please enter a non-zero transaction amount.';

@Component({
  selector: 'app-manual-transaction-details',
  templateUrl: './manual-transaction-details.component.html',
  styleUrls: ['./manual-transaction-details.component.scss']
})
export class ManualTransactionDetailsComponent extends EditTransactionCategoryComponent {
  public defaultTransactionDate: string;
  public isInSetup: boolean;
  protected plaidAccounts;
  private origTransactionYearMonth;
  public manualAccount = null;
  public isAddingManualAccount = false;
  private isNewTransaction = true;

  constructor(
    protected dataModelService: DataModelService,
    protected router: Router,
    protected manualAccountService: AccountService,
    dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data: IManualTransactionDialogData,
    private dialogRef: MatDialogRef<ManualTransactionDetailsComponent>,
    public transactionRuleService: TransactionRuleService
  ) {
    super(dataModelService, router, dialog, transactionRuleService);

    this.defaultTransactionDate = data.defaultTransactionDate;
    this.isInSetup = data.isInSetup;
    this.goToSplit = data.goToSplit;
    this.transaction = data.transaction;
    this.setPlaidAccounts();
  }

  private setPlaidAccounts() {
    this.plaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    if (this.plaidAccounts) {
      // Only get manual accounts for dropdown
      this.plaidAccounts = this.plaidAccounts.filter(account => account.isManual && account.isActive);
    }
  }

  public async ngOnInit() {
    this.isNewTransaction = this.transaction === undefined;
    this.initManualTransaction();
    super.ngOnInit();
  }

  private initManualTransaction() {
    const newManualTransaction = {
      transaction_id: this.dataModelService.generateIDcode(8),
      account_id: '',
      originalAmount: 0,
      originalCurrencyCode: 'USD',
      amount: 0,
      date: this.defaultTransactionDate,
      category: 'none',
      merchantName: '',
      accountName: '',
      institutionName: '',
      isManual: true,
      wizeFiCategory: 'unknown',
      splitStatus: 0,
      ruleApplied: false
    };

    if (!this.transaction) {
      this.transaction = newManualTransaction;
    }
    this.origTransactionYearMonth = this.transaction.date.substr(0, 7);
  }

  public async save() {
    if (!this.validateFields()) {
      return;
    }
    if (this.isNewTransaction) {
      this.monthTransactions.push(this.transaction);
    } else if (this.transactionDidChangeYearMonth()) {
      this.removeFromOrigYearMonthTransactions();
    }
    super.save();
    this.dialogRef.close(this.transaction);
  }

  private transactionDidChangeYearMonth(): boolean {
    const curTransactionYearMonth = this.transaction.date.substr(0, 7);
    return curTransactionYearMonth !== this.origTransactionYearMonth;
  }

  private removeFromOrigYearMonthTransactions() {
    const origMonthTransactions = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection[this.origTransactionYearMonth];
    const index = origMonthTransactions.findIndex(transaction => this.transaction.transaction_id === transaction.transaction_id);
    if (index !== -1) {
      origMonthTransactions.splice(index, 1);
    }

    this.dataModelService.dataManagement.putWizeFiTransactions(
      this.dataModelService.dataModel.global.wizeFiID,
      this.origTransactionYearMonth,
      origMonthTransactions
    );
  }

  private validateFields(): boolean {
    let isOK = true;
    const transactionDate = moment(this.transaction.date);
    const threeMonthsAgo = moment().subtract(3, 'months');
    const now = moment();

    // name
    if (this.transaction.merchantName.trim() === '') {
      isOK = false;
      this.dataModelService.showMessage('error', nameErrorMessage, 5000);
    }

    // date
    if (!transactionDate || transactionDate > now || transactionDate < threeMonthsAgo) {
      isOK = false;
      this.dataModelService.showMessage('error', dateErrorMessage, 5000);
    }

    // account
    if (this.transaction.account_id === '') {
      isOK = false;
      this.dataModelService.showMessage('error', accountErrorMessgae, 5000);
    }

    // category
    if (this.selectedCategory === 'Uncategorized' || this.selectedSubcategory === 'Uncategorized' || this.selectedAccount === 'Uncategorized') {
      isOK = false;
      this.dataModelService.showMessage('error', categoryErrorMessage, 5000);
    }

    // amount
    if (this.transaction.amount === 0) {
      isOK = false;
      this.dataModelService.showMessage('error', amountErrorMessage, 5000);
    }

    return isOK;
  }

  protected selectDate(date: Date) {
    // careful here... need to make sure the right month of transactions is being updated
    this.transaction.date = date.toISOString().substr(0, 10);
    this.syncYearMonthForSelectedDate();
  }

  //  updates the component's transactions to correspond to the same yearMonth that has been selected
  //  by the user. This is necessary for saving the correct array of transactions to the correct
  //  yearMonth in the wizeFiTransactionsCollection object in the global area of the data model
  private syncYearMonthForSelectedDate() {
    this.transactionYearMonth = this.transaction.date.substr(0, 7);
    this.monthTransactions = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection[this.transactionYearMonth];
  }

  protected updateNickname(name) {
    this.transaction.merchantName = name.trim();
  }

  protected selectPlaidAccount(plaidAccount) {
    this.transaction.account_id = plaidAccount.account_id;
    this.transaction.accountName = plaidAccount.accountName;
    this.transaction.institutionName = plaidAccount.institutionName;
  }

  protected updateAmount(amount) {
    this.transaction.amount = parseFloat(amount) || 0;
  }

  // eslint-disable-next-line jsdoc/check-alignment, jsdoc/check-indentation, jsdoc/newline-after-description

  public async onAddManualAccount() {
    await this.manualAccountService.addManualInstitutionIfNeeded();
    this.manualAccount = this.manualAccountService.createManualAccount();
    this.isAddingManualAccount = true;
    this.showManualAccountDetailsScreen();
    this.selectPlaidAccount(this.manualAccount);
  }

  public showManualAccountDetailsScreen() {
    this.dialog
      .open<AccountTypeSelectorComponent, IAccountTypeSelectorData>(AccountTypeSelectorComponent, {
        data: {
          isManual: true,
          showManualAccountChooser: true,
          plaidAccount: this.manualAccount
        }
      })
      .afterClosed()
      .subscribe(result => {
        this.saveManualAccountDetails();
      });
  }

  public async closeManualAccountDetails() {
    this.isAddingManualAccount = false;
  }

  public async saveManualAccountDetails() {
    this.isAddingManualAccount = false;
    this.manualAccount = null;
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    const title = this.dataModelService.dataManagement.getDraftTitle();
    const plaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    await this.dataModelService.plaidManagement.storePlaidAccounts(wizeFiID, title, plaidAccounts);

    this.setPlaidAccounts();
  }

  public cancel() {
    this.dialogRef.close();
  }
}

export interface IManualTransactionDialogData {
  defaultTransactionDate?: string;
  transaction?: WizefiTransaction;
  isInSetup?: boolean;
  goToSplit?: boolean;
}
