import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DataModelService } from '../../../services/data-model/data-model.service';

@Component({
  selector: 'app-deductions',
  templateUrl: './deductions.component.html',
  styleUrls: ['./deductions.component.scss']
})
export class DeductionsComponent implements OnInit {
  @Output() public onChange: EventEmitter<any> = new EventEmitter<any>();
  public FICADeduction = 0;
  public stateDeduction = 0;
  public medicareDeduction = 0;
  public localDeduction = 0;
  public childSupportDeduction = 0;
  public totalSum = 0;
  public isLoading = false;
  public isUpdating = false;
  public isShowingOtherDeductions = false;

  constructor(protected dataModelService: DataModelService, protected router: Router) {}

  public ngOnInit() {
    this.updateTotalSum();
  }

  public showOtherDeductions() {
    this.isShowingOtherDeductions = true;
  }

  public hideOtherDeductions() {
    this.isShowingOtherDeductions = false;
  }

  public onFICAChange(value: string) {
    this.FICADeduction = parseInt(value, 10) || 0;
    this.updateTotalSum();
  }

  public onStateChange(value: string) {
    this.stateDeduction = parseInt(value, 10) || 0;
    this.updateTotalSum();
  }

  public onMedicareChange(value: string) {
    this.medicareDeduction = parseInt(value, 10) || 0;
    this.updateTotalSum();
  }

  public onLocalChange(value: string) {
    this.localDeduction = parseInt(value, 10) || 0;
    this.updateTotalSum();
  }

  public onChildSupportChange(value: string) {
    this.childSupportDeduction = parseInt(value, 10) || 0;
    this.updateTotalSum();
  }

  public onSave() {
    // TODO: save deduction data once introduced to the data model
  }

  private updateTotalSum() {
    this.totalSum = this.FICADeduction + this.stateDeduction + this.medicareDeduction + this.localDeduction + this.childSupportDeduction;
    console.log(this.totalSum);
    this.onChange.emit(this.totalSum);
  }
}
