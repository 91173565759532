import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Countries } from '../../../enums/countries.enum';
import { DataModelService } from '../../../services/data-model/data-model.service';

@Component({
  selector: 'app-profile-light',
  templateUrl: './profile-light.component.html',
  styleUrls: ['./profile-light.component.scss']
})
export class ProfileLightComponent implements OnInit {
  public isLoading = true;
  public isUpdating = false;
  public firstName = '';
  public lastName = '';
  public country = '';
  public gender = '';
  public zipCode = '';
  public occupation = '';
  public dateOfBirth = '';
  public dayDate: any = 'dd';
  public monthDate: any = 'mm';
  public yearDate: any = 'yyyy';

  // @TODO: provide valid days (28, 30 or 31) based on month
  protected genders = ['Male', 'Female', 'Other', 'Rather not say'];
  protected countries = Countries;

  constructor(protected dataModelService: DataModelService) {}

  public ngOnInit() {
    this.isLoading = false;
    const profileData = this.dataModelService.dataModel.persistent.profile;
    this.setInputTextfieldValues(profileData);
    this.isLoading = false;
  }

  private setInputTextfieldValues(profileData) {
    this.firstName = profileData.nameFirst;
    this.lastName = profileData.nameLast;
    this.country = profileData.country;
    this.gender = profileData.gender;
    this.zipCode = profileData.zipCode;
    this.occupation = profileData.occupation;
    this.dateOfBirth = profileData.birthDate;
  }

  public onFirstNameChange(value: string) {
    this.firstName = value;
  }

  public onLastNameChange(value: string) {
    this.lastName = value;
  }

  public onCountryChange(value: string) {
    this.country = value;
  }

  public onGenderChange(value: string) {
    this.gender = value;
  }

  public onZipCodeChange(value: string) {
    this.zipCode = value;
  }

  public onOccupationChange(value: string) {
    this.occupation = value;
  }

  protected updateDate(date: string) {
    this.dateOfBirth = date;
  }

  public canSave(): boolean {
    return moment(this.dateOfBirth, 'YYYY-MM-DD', true).isValid();
  }

  public async onSave() {
    this.isUpdating = true;
    const profileData = this.dataModelService.dataModel.persistent.profile;
    profileData.nameFirst = this.firstName;
    profileData.nameLast = this.lastName;
    profileData.country = this.country;
    profileData.gender = this.gender === 'Gender' ? 'Rather not say' : this.gender;
    profileData.zipCode = this.zipCode;
    profileData.occupation = this.occupation;
    profileData.birthDate = this.dateOfBirth;
    this.isUpdating = false;
  }
}
