import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { PlaidAccount } from '@app/interfaces/plaid-account';
import { AccountTypeSelectorComponent, IAccountTypeSelectorData } from '../../accounts/account-type-selector/account-type-selector.component';
import { DialogDisplayDraftsComponent } from '@app/components/dialog-display-drafts/dialog-display-drafts.component';
import { AccountService } from '@app/services/account/account.service';
import { DataModelService } from '@app/services/data-model/data-model.service';
import { SetupService } from '@app/services/setup.service';

@Component({
  selector: 'app-institution-account',
  templateUrl: './institution-account.component.html',
  styleUrls: ['./institution-account.component.scss']
})
export class InstitutionAccountComponent {
  @Input() public account: PlaidAccount;
  @Input() public review: boolean;
  @Input() public disableEditMode = false;
  @Output() public selectAccount: EventEmitter<PlaidAccount> = new EventEmitter<PlaidAccount>();

  constructor(
    private dataModelService: DataModelService,
    private dialog: MatDialog,
    private accountService: AccountService,
    public setupService: SetupService
  ) {}

  public onActivateAccount(account: PlaidAccount) {
    if (this.accountService.isInAccountsReadOnlyPage()) {
      this.dialog.open(DialogDisplayDraftsComponent, { data: 'accounts' });
      return;
    }

    if (!this.review) {
      this.dialog
        .open<AccountTypeSelectorComponent, IAccountTypeSelectorData>(AccountTypeSelectorComponent, {
          width: '590px',
          data: {
            plaidAccount: account
          }
        })
        .afterClosed()
        .subscribe(() => {});
    }
  }

  public showAccountTransactions(account: PlaidAccount) {
    this.selectAccount.emit(account);
  }

  public shouldBeShown() {
    return this.account.status !== '2';
  }

  public getWizeFiCategory() {
    if (this.account.wizeFiCategory && this.dataModelService.categoryManagement.isValidWizeFiCategoryAccount(this.account.wizeFiCategory)) {
      const info = this.dataModelService.categoryManagement.decodeWizeFiCategory(this.account.wizeFiCategory);
      return info.category;
    } else {
      return '';
    }
  }
}
