import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { DataModelService } from '../../services/data-model/data-model.service';
import { PlaidAccount } from '@app/interfaces/plaid-account';
import { AccountService } from '@app/services/account/account.service';
import { DialogDisplayDraftsComponent } from '../dialog-display-drafts/dialog-display-drafts.component';
import { NewAccountDialogComponent, NewAccountDialogResult } from '../new-account-dialog/new-account-dialog.component';
import { filter } from 'rxjs/operators';
import { AccountTypeSelectorComponent, IAccountTypeSelectorData } from '../setup-flow/accounts/account-type-selector/account-type-selector.component';

@Component({
  selector: 'app-accounts-page',
  templateUrl: './accounts-page.component.html',
  styleUrls: ['./accounts-page.component.scss']
})
export class AccountsPageComponent implements OnInit {
  public selectedAccount: PlaidAccount;
  public accountsChanged$ = new EventEmitter<void>();

  constructor(
    protected dataModelService: DataModelService,
    protected router: Router,
    protected dialog: MatDialog,
    public accountService: AccountService
  ) {}

  public async ngOnInit() {
    window.sessionStorage.setItem('reloginUrl', '/accounts#all');
    this.checkForOrphanAccounts();
  }

  public selectAccount(event: PlaidAccount) {
    this.selectedAccount = event;
  }

  private checkForOrphanAccounts() {
    const accounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    // eslint-disable-next-line no-shadow
    accounts.forEach((acct, index, accounts) => {
      if (acct && acct.institution_id) {
        let found = false;
        if (this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions) {
          found = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions.some(
            (inst: { institution_id: any }) => inst.institution_id === acct.institution_id
          );
        }

        if (!found) {
          acct.status = 2;
          accounts.splice(index, 1);
        }
      }
    });

    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    const title = this.dataModelService.dataManagement.getDraftTitle();
    this.dataModelService.plaidManagement.storePlaidAccounts(wizeFiID, title, accounts);
  }

  public openDraftsDialog() {
    this.dialog.open(DialogDisplayDraftsComponent, { data: 'accounts' });
  }

  public openNewAccountDialog() {
    this.dialog
      .open<NewAccountDialogComponent, any, NewAccountDialogResult>(NewAccountDialogComponent)
      .afterClosed()
      .pipe(filter(result => !!result))
      .subscribe(() => {
        this.accountsChanged$.next();
      });
  }

  public showAccountModal() {
    this.dialog.open<AccountTypeSelectorComponent, IAccountTypeSelectorData>(AccountTypeSelectorComponent, {
      data: {
        plaidAccount: this.selectedAccount
      }
    });
  }
}
