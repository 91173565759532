import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountsPageComponent } from '../components/accounts-page/accounts-page.component';
import { AdminDebugUserDataComponent } from '../components/admin/admin-debug-user-data/admin-debug-user-data.component';
import { AdminManageScreensComponent } from '../components/admin/admin-manage-screens/admin-manage-screens.component';
import { AdminManageSuggestionsComponent } from '../components/admin/admin-manage-suggestions/admin-manage-suggestions.component';
import { AdminManageTreeComponent } from '../components/admin/admin-manage-tree/admin-manage-tree.component';
import { AdminRunTestsComponent } from '../components/admin/admin-run-tests/admin-run-tests.component';
import { AdminTestPlaidComponent } from '../components/admin/admin-test-plaid/admin-test-plaid.component';
import { AdminTestYodleeComponent } from '../components/admin/admin-test-yodlee/admin-test-yodlee.component';
import { AdminViewBasicDataComponent } from '../components/admin/admin-view-basic-data/admin-view-basic-data.component';
import { AdminViewBudgetDataComponent } from '../components/admin/admin-view-budget-data/admin-view-budget-data.component';
import { AdminViewErrorLogComponent } from '../components/admin/admin-view-error-log/admin-view-error-log.component';
import { BudgetPageComponent } from '../components/budget-page/budget-page.component';
import { EditCAFRComponent } from '../components/edit-cafr/edit-cafr.component';
import { GoComponent } from '../components/go/go.component';
import { LoginFormComponent } from '../components/login/login-form/login-form.component';
import { MultipleInstitutionInstancesComponent } from '../components/multiple-institution-instances/multiple-institution-instances.component';
import { NewUserStartComponent } from '../components/new-user-start/new-user-start.component';
import { NotFoundComponent } from '../components/not-found/not-found.component';
import { ReloginComponent } from '../components/relogin/relogin.component';
import { ReviewProjectionsComponent } from '../components/review-projections/review-projections.component';
import { SetCountryCodesComponent } from '../components/set-country-codes/set-country-codes.component';
import { SetCurrencyCodeComponent } from '../components/set-currency-code/set-currency-code.component';
import { SettingsComponent } from '../components/settings/settings.component';
import { ApplyCafrScreenComponent } from '../components/setup-flow/apply-cafr-screen/apply-cafr-screen.component';
import { ConnectBankComponent } from '../components/setup-flow/connect-bank/connect-bank.component';
import { CreateCafrScreenComponent } from '../components/setup-flow/create-cafr-screen/create-cafr-screen.component';
import { FinishScreenComponent } from '../components/setup-flow/finish-screen/finish-screen.component';
import { GoalsScreenComponent } from '../components/setup-flow/goals-screen/goals-screen.component';
import { IncomeScreenComponent } from '../components/setup-flow/income-screen/income-screen.component';
import { ProjectionsScreenComponent } from '../components/setup-flow/projections-screen/projections-screen.component';
import { ReviewAccountsComponent } from '../components/setup-flow/review-accounts/review-accounts.component';
import { SetupStepMenuComponent } from '../components/setup-flow/setup-step-menu/setup-step-menu.component';
import { SpendingScreenComponent } from '../components/setup-flow/spending-screen/spending-screen.component';
import { SignupComponent } from '../components/signup/signup.component';
import { TransactionsComponent } from '../components/transactions/transactions.component';
import { ValidateEmailComponent } from '../components/validate-email/validate-email.component';
import { ScreenLoginGuard } from '../utilities/screen-login.guard';
import { SecureScreenGuard } from '../utilities/secure-screen.guard';
import { ResetPasswordComponent } from '../components/reset-password/reset-password.component';
import { SetupReviewComponent } from './../components/setup-flow/setup-review/setup-review.component';
import { SetupSettingsComponent } from './../components/setup-flow/setup-settings/setup-settings.component';
import { BetaGuard } from './../utilities/guards/beta.guard';
import { LoginComponent } from './../components/login/login.component';
import { DashboardComponent } from './../components/dashboard/dashboard.component';

export const routes: Routes = [
  {
    path: 'admin-debug-user-data',
    component: AdminDebugUserDataComponent,
    canActivate: [SecureScreenGuard]
  },
  {
    path: 'admin-test-yodlee',
    component: AdminTestYodleeComponent,
    canActivate: [SecureScreenGuard]
  },
  {
    path: 'admin-test-plaid',
    component: AdminTestPlaidComponent,
    canActivate: [SecureScreenGuard]
  },
  {
    path: 'set-currency-code',
    component: SetCurrencyCodeComponent,
    canActivate: [ScreenLoginGuard]
  },
  {
    path: 'set-country-codes',
    component: SetCountryCodesComponent,
    canActivate: [ScreenLoginGuard]
  },
  {
    path: 'multiple-institution-instances',
    component: MultipleInstitutionInstancesComponent,
    canActivate: [ScreenLoginGuard]
  },
  {
    path: 'admin-view-basic-data',
    component: AdminViewBasicDataComponent,
    canActivate: [SecureScreenGuard]
  },
  {
    path: 'admin-view-budget-data',
    component: AdminViewBudgetDataComponent,
    canActivate: [SecureScreenGuard]
  },
  {
    path: 'admin-view-error-log',
    component: AdminViewErrorLogComponent,
    canActivate: [SecureScreenGuard]
  },
  {
    path: 'admin-manage-screens',
    component: AdminManageScreensComponent,
    // canActivate: [SecureScreenGuard]
    canActivate: [ScreenLoginGuard] // temporary for when SecureScreenGuard is commented out
  },
  {
    path: 'admin-manage-tree',
    component: AdminManageTreeComponent,
    canActivate: [SecureScreenGuard]
  },
  {
    path: 'admin-run-tests',
    component: AdminRunTestsComponent,
    canActivate: [SecureScreenGuard]
  },
  {
    path: 'admin-manage-suggestions',
    component: AdminManageSuggestionsComponent,
    canActivate: [SecureScreenGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'go/:id',
    component: GoComponent
  },
  {
    path: 'register',
    component: SignupComponent,
    canActivate: [BetaGuard],
    data: { mode: SignupComponent.FREE_TRIAL_SIGNUP_MODE }
  },
  {
    path: 'subscription',
    component: SignupComponent,
    canActivate: [ScreenLoginGuard],
    data: { mode: SignupComponent.SUBSCRIPTION_SIGNUP_MODE }
  },
  {
    path: 'setup-step-menu',
    component: SetupStepMenuComponent,
    canActivate: [ScreenLoginGuard]
  },
  {
    path: 'setup/connect-bank',
    component: ConnectBankComponent,
    canActivate: [ScreenLoginGuard]
  },
  {
    path: 'setup/review-accounts',
    component: ReviewAccountsComponent,
    canActivate: [ScreenLoginGuard]
  },
  {
    path: 'setup/goals',
    component: GoalsScreenComponent,
    canActivate: [ScreenLoginGuard]
  },
  {
    path: 'setup/income',
    component: IncomeScreenComponent,
    canActivate: [ScreenLoginGuard]
  },
  {
    path: 'setup/spending',
    component: SpendingScreenComponent,
    canActivate: [ScreenLoginGuard]
  },
  {
    path: 'setup/review',
    component: SetupReviewComponent,
    canActivate: [ScreenLoginGuard]
  },
  {
    path: 'setup/projections',
    component: ProjectionsScreenComponent,
    canActivate: [ScreenLoginGuard]
  },
  {
    path: 'setup/create-cafr',
    component: CreateCafrScreenComponent,
    canActivate: [ScreenLoginGuard]
  },
  {
    path: 'setup/apply-cafr',
    component: ApplyCafrScreenComponent,
    canActivate: [ScreenLoginGuard]
  },
  {
    path: 'setup/finish',
    component: FinishScreenComponent,
    canActivate: [ScreenLoginGuard]
  },
  {
    path: 'setup/settings',
    component: SetupSettingsComponent,
    canActivate: [ScreenLoginGuard]
  },
  {
    path: 'plan',
    component: BudgetPageComponent,
    canActivate: [ScreenLoginGuard]
  },
  {
    path: 'plan/edit-CAFR',
    component: EditCAFRComponent,
    canActivate: [ScreenLoginGuard]
  },
  {
    path: 'plan/review-projections',
    component: ReviewProjectionsComponent,
    canActivate: [ScreenLoginGuard]
  },
  {
    path: 'accounts',
    component: AccountsPageComponent,
    canActivate: [ScreenLoginGuard]
  },
  {
    path: 'setup',
    component: NewUserStartComponent,
    canActivate: [ScreenLoginGuard]
  },
  {
    path: 'validate-email',
    component: ValidateEmailComponent
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [ScreenLoginGuard]
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [ScreenLoginGuard]
  },
  {
    path: 'transactions',
    component: TransactionsComponent,
    pathMatch: 'full',
    canActivate: [ScreenLoginGuard]
  },
  {
    path: 'relogin',
    component: ReloginComponent
  },
  {
    path: 'go/:id',
    component: GoComponent
  },
  {
    path: '',
    redirectTo: '/plan#income',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: NotFoundComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
