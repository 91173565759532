import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from '../components/app/app.component';
import { LoginFormComponent } from '../components/login/login-form/login-form.component';
import { LoginComponent } from '../components/login/login.component';
import { ResetPasswordComponent } from '../components/reset-password/reset-password.component';
import { SettingsDeactivateGuard } from '../components/settings/settings-deactivate.guard';
import { CreateAccountSignupComponent } from '../components/signup/create-account-signup/create-account-signup.component';
import { SignupComponent } from '../components/signup/signup.component';
import { SubscriptionSignupComponent } from '../components/signup/subscription-signup/subscription-signup.component';
import { ValidateEmailComponent } from '../components/validate-email/validate-email.component';
import { DataModelService } from '../services/data-model/data-model.service';
import { LogMessageService } from '../services/log-message/log-message.service';
import { SharedModule } from '../shared/shared.module';
import { CustomErrorHandler } from '../utilities/custom-error-handler.class';
import { ScreenLoginGuard } from '../utilities/screen-login.guard';
import { SecureScreenGuard } from '../utilities/secure-screen.guard';
import { AppRoutingModule } from './routing.module';
import { StructureModule } from './structure.module';

@NgModule({
  declarations: [
    LoginComponent,
    LoginFormComponent,
    SignupComponent,
    ValidateEmailComponent,
    SubscriptionSignupComponent,
    CreateAccountSignupComponent,
    ResetPasswordComponent
  ],
  imports: [BrowserModule, FormsModule, AppRoutingModule, StructureModule, SharedModule],
  providers: [
    DataModelService,
    LogMessageService,
    SettingsDeactivateGuard,
    ScreenLoginGuard,
    SecureScreenGuard,
    { provide: ErrorHandler, useClass: CustomErrorHandler } // this leads to error: Cannot instantiate cyclic dependency
  ],
  bootstrap: [AppComponent]
})
export class SignupModule {}
