import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { AccountTypeService, IDividedSubtypes } from 'src/app/services/account-type/account-type.service';
import { AccountService } from 'src/app/services/account/account.service';
import { DataModelService } from '../../../../services/data-model/data-model.service';
import { ItemManagement } from '../../../../utilities/item-management.class';
import { AccountDetailsTemplateComponent } from '../account-details-template/account-details-template.component';
const UNKNOWN = 'unknown';
const NONE = 'none';
const UNCATEGORIZED = 'Uncategorized';
const DEFAULT_ACCOUNT_NAME = '---';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['../account-details-template/account-details-template.component.scss']
})
export class AccountDetailsComponent implements OnInit {
  @ViewChild(AccountDetailsTemplateComponent, { static: true })
  public template: AccountDetailsTemplateComponent;

  @Input() public isPostSetup: boolean;
  @Input() public plaidAccount: any = {};
  @Input() public selectedCategory = UNKNOWN;
  @Input() public selectedSubcategory = UNKNOWN;
  @Input() public selectedType = UNCATEGORIZED;
  @Input() public selectedSubtype = UNCATEGORIZED;
  @Input() public hasChangedSchema: boolean;
  @Input() public showManualAccountChooser = false;
  @Input() public accountName: string;

  @Output() public save: EventEmitter<any> = new EventEmitter<any>();
  @Output() public cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onChangeAccountSchema: EventEmitter<any> = new EventEmitter<any>();

  public wizeFiAccount: any = {};

  // wizeFiPlaid account attributes
  protected isActive: boolean;
  protected accountStatus = '1'; // hard coded for now
  protected newWizeFiCategory;
  public income;
  public incomeAccounts;
  public newIncome;
  public actualSubType;
  public specifiedIncomeAccount;
  public totalOwed: {
    label: string;
    isRequired: boolean;
    val: number;
  };
  protected selectedTargetAmount: {
    label: string;
    isRequired: boolean;
    val: number;
  };
  // productivity
  public selectedProductivity: {
    label: string;
    isRequired: boolean;
    val: string;
  };
  public defaultProductivity: {
    label: string;
    isRequired: boolean;
    val: string;
  } = this.accountTypeService.getDefaultProductivity(this.selectedSubcategory);

  // options for populating dropdowns
  public categories: string[] = ['assets', 'liabilities', 'assetProtection'];
  public subcategories: string[];
  public types: string[] = ['Asset', 'Debt', 'Insurance']; // labels presented in dropdown
  public subtypes: IDividedSubtypes; // labels presented in dropdown
  public translatedSubType: string;
  public targetAmount;

  constructor(public dataModelService: DataModelService, private accountTypeService: AccountTypeService, private accountService: AccountService) {}

  public productivityList = ['Productive', 'Non-productive', 'Limited'];

  public setIsActive() {
    this.isActive = !this.isActive;
  }

  public onProductivityChange(productivity) {
    this.selectedProductivity.val = productivity;
  }

  public onNameChange(name) {
    this.accountName = name.length ? name : DEFAULT_ACCOUNT_NAME;
  }

  public onTypeChange(type: string) {
    if (this.selectedType !== type) {
      this.selectedSubtype = UNCATEGORIZED; // reset available subtypes on type change
    }
    this.selectedType = type;
    this.selectedCategory = this.getCategoryFromType(type);
    this.subtypes = this.accountTypeService.getDividedSubtypes(this.selectedCategory);
  }

  public onSubtypeChange(subtype) {
    this.showManualAccountChooser = false;
    this.selectedSubtype = subtype;
    this.actualSubType = subtype;
    this.selectedSubcategory = this.accountTypeService.getSubcategoryFromSubtype(this.selectedCategory, subtype);
    this.setNewWizeFiCategory();

    this.onChangeAccountSchema.emit({
      category: this.selectedCategory,
      subcategory: this.selectedSubcategory,
      subtype: this.selectedSubtype,
      accountName: this.accountName
    });
    if (this.selectedCategory !== 'assetProtection') {
      this.plaidAccount.productivity = undefined;
      this.defaultProductivity = this.accountTypeService.getDefaultProductivity(this.selectedSubcategory);
      this.selectedProductivity = this.defaultProductivity;
    }
  }

  protected onTargetAmountChange(value: string) {
    this.selectedTargetAmount.val = parseFloat(value);
  }

  public getTargetAmount() {
    if (!this.wizeFiAccount) {
      return { label: 'Target Amount', isRequired: true, val: 2000 };
    }

    if (!this.wizeFiAccount.targetAmount || this.wizeFiAccount.targetAmount.val < 0) {
      this.wizeFiAccount.targetAmount = { label: 'Target Amount', isRequired: true, val: 2000 };
    }
    if (this.wizeFiAccount.accountType === 'investments') {
      this.wizeFiAccount.targetAmount = null;
    }
    return this.wizeFiAccount.targetAmount;
  }

  public isEmergencySavingsType(): boolean {
    // TODO: write a more robust way of determining type of savings account (emergency or general)
    if (this.wizeFiAccount && this.wizeFiAccount.accountName && this.wizeFiAccount.accountName.val) {
      return this.wizeFiAccount.accountName.val.includes('Emergency Savings');
    } else {
      return false;
    }
  }

  // component initialization

  public ngOnInit() {
    this.isActive = this.plaidAccount.isActive;
    this.wizeFiAccount = this.getCopyOfWizeFiAccount(this.plaidAccount);
    this.selectedTargetAmount = this.getTargetAmount();
    this.selectedType = this.getTypeFromCategory(this.selectedCategory);
    this.subtypes = this.accountTypeService.getDividedSubtypes(this.selectedCategory);
    this.translatedSubType = this.accountService.translateDepricatedAccountName(this.selectedSubtype, this.selectedType);
    this.defaultProductivity = this.accountTypeService.getDefaultProductivity(this.selectedSubcategory);
    this.selectedProductivity = this.wizeFiAccount && this.wizeFiAccount.productivity ? this.wizeFiAccount.productivity : this.defaultProductivity;
    this.selectedSubtype = this.translatedSubType;
    this.income = this.dataModelService.getdata('income');
    this.incomeAccounts = this.income.income.accounts;
  }

  public getCopyOfWizeFiAccount(plaidAccount) {
    const wizeFiCategory = plaidAccount.wizeFiCategory ? plaidAccount.wizeFiCategory : this.makeWizeFiCategory();
    if (wizeFiCategory && wizeFiCategory != NONE) {
      return this.shouldLinkToExistingAccount(wizeFiCategory)
        ? this.getExistingAccountSchema(wizeFiCategory)
        : this.getNewAccountSchema(plaidAccount);
    }
    return this.getNewAccountSchema(plaidAccount);
  }

  public makeWizeFiCategory() {
    const plan = this.dataModelService.dataModel.persistent.plans[this.dataModelService.dataModel.persistent.header.curplan];
    const subcatAccounts =
      (plan[this.selectedCategory] &&
        plan[this.selectedCategory][this.selectedSubcategory] &&
        plan[this.selectedCategory][this.selectedSubcategory].accounts) ||
      [];
    const accountID = this.dataModelService.categoryManagement.accountName2accountID(this.selectedSubtype, subcatAccounts);
    return accountID !== UNKNOWN
      ? this.dataModelService.categoryManagement.makeWizeFiCategory(this.selectedCategory, this.selectedSubcategory, accountID)
      : UNKNOWN;
  }

  public shouldLinkToExistingAccount(wizeFiCategory): boolean {
    const plaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    const shouldLinkToExistingAccount =
      (!this.hasChangedSchema && this.plaidAccount.status === '1') ||
      (this.dataModelService.categoryManagement.isValidWizeFiCategoryAccount(wizeFiCategory) &&
        !plaidAccounts.some(account => account.wizeFiCategory === wizeFiCategory));
    return shouldLinkToExistingAccount;
  }

  public getNewAccountSchema(plaidAccount) {
    if (
      this.selectedCategory !== UNKNOWN &&
      this.selectedCategory !== NONE &&
      this.selectedSubcategory !== UNKNOWN &&
      this.selectedSubcategory !== NONE
    ) {
      const newAccountSchema = this.dataModelService.categoryManagement.getWizeFiAccountSchema(this.selectedCategory, this.selectedSubcategory);

      newAccountSchema.productivity = plaidAccount.productivity;
      // newAccountSchema.accountName.val = this.accountName;
      if (newAccountSchema.accountValue) {
        newAccountSchema.accountValue.val = this.plaidAccount.balance || 0;
      }
      return JSON.parse(JSON.stringify(newAccountSchema));
    } else {
      return null;
    }
  }

  public getExistingAccountSchema(wizeFiCategory) {
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;
    const parts = this.dataModelService.categoryManagement.decodeWizeFiCategory(wizeFiCategory, this.selectedSubtype);
    let account;
    if (parts && this.dataModelService.dataModel.persistent.plans[curplan][parts.category] !== undefined) {
      account = this.dataModelService.dataModel.persistent.plans[curplan][parts.category][parts.subcategory].accounts[parts.acntndx];
      // THIS IS WHERE WE SET THE VALUES SPECIFIC TO THE ACCOUNT.
      // account.accountName.val = this.plaidAccount.accountName;
      if (account) {
        if (account.accountValue) {
          account.accountValue.val = this.plaidAccount.balance || 0;
        }
        account.productivity = this.plaidAccount.productivity;
        account.accountSubcategoryName = { label: 'Account Subcategory Name', isRequired: false, val: this.selectedSubtype.split('---')[0] };
      }
    } else {
      account = this.getNewAccountSchema(this.plaidAccount);
    }

    if (account) {
      return JSON.parse(JSON.stringify(account));
    }
  }

  // save account changes when component is closed

  public onCancel() {
    this.cancel.emit();
  }

  public onSave() {
    const onSave0 = async () => {
      if (!this.isPostSetup) {
        this.isActive = true;
      }

      if (this.canSave()) {
        if (this.wizeFiAccount) {
          this.wizeFiAccount.accountName.val = this.accountName;
        }
        this.plaidAccount.accountName = this.accountName;
        this.plaidAccount.productivity = this.selectedProductivity;
        this.plaidAccount.targetAmount = this.selectedTargetAmount;
        this.plaidAccount.totalAmountOwed = this.totalOwed;
        const linkedWizeFiAccount = await this.savePlaidAccountChanges();
        if (
          linkedWizeFiAccount &&
          linkedWizeFiAccount.accountSubcategoryName &&
          linkedWizeFiAccount.accountSubcategoryName.val !== this.selectedSubtype.split('---')[0]
        ) {
          linkedWizeFiAccount.accountName.val = this.makeUniqueAccountName();
          linkedWizeFiAccount.accountSubcategoryName.val = this.selectedSubtype.split('---')[0];
        }
        if (this.newIncome && this.newIncome !== 0) {
          this.addMonthlySalaryAccountIfNeeded();
        }
        this.saveWizeFiAccountChanges(linkedWizeFiAccount);
        this.storeChanges();
        if (this.newIncome && this.newIncome !== 0) {
          this.onIncomeSave();
        }
        this.save.emit();
      }
    };

    onSave0().catch(err => {
      console.error('error in onSave in account-details: ', err);
    });
  } // onSave

  private async savePlaidAccountChanges() {
    this.setNewWizeFiCategory();

    const accountLinkInfo = {
      wizeFiPlaidAccount: this.plaidAccount,
      accountStatus: this.accountStatus,
      accountIsActive: this.isActive,
      category: this.selectedCategory,
      subcategory: this.selectedSubcategory,
      accountName: this.accountName,
      wizeFiCategory: this.newWizeFiCategory,
      productivity: this.selectedProductivity,
      targetAmount: this.selectedTargetAmount,
      totalAmountOwed: this.totalOwed,
      balance: this.plaidAccount && this.plaidAccount.balance ? this.plaidAccount.balance.val : 0
    };
    const wizeFiAccount = await this.dataModelService.categoryManagement.processPlaidAccountLink(accountLinkInfo);
    return wizeFiAccount;
  }

  private saveWizeFiAccountChanges(linkedWizeFiAccount) {
    let parts: any;
    if (this.hasLinkedWizeFiCategory(linkedWizeFiAccount) && Object.keys(linkedWizeFiAccount).length !== 0) {
      if (linkedWizeFiAccount.accountSubcategoryName && linkedWizeFiAccount.accountSubcategoryName.val) {
        parts = this.dataModelService.categoryManagement.decodeWizeFiCategory(
          linkedWizeFiAccount.wizeFiCategory.val,
          linkedWizeFiAccount.accountSubcategoryName.val
        );
      } else {
        parts = this.dataModelService.categoryManagement.decodeWizeFiCategory(
          linkedWizeFiAccount.wizeFiCategory.val,
          linkedWizeFiAccount.accountSubcategoryName
        );
      }

      const curplan = this.dataModelService.dataModel.persistent.header.curplan;
      // update user input for the linked account; the wizeFiAccount instance variable of this class
      // has all of the user-supplied data (eg. name, type, sub-type, monthlyAmounts.. etc) while
      // the linkedWizeFiAccount has the correct accountID
      this.dataModelService.dataModel.persistent.plans[curplan][parts.category][parts.subcategory].accounts[parts.acntndx] = {
        ...this.wizeFiAccount,
        accountName: { ...linkedWizeFiAccount.accountName },
        accountID: { ...linkedWizeFiAccount.accountID },
        wizeFiCategory: { ...linkedWizeFiAccount.wizeFiCategory },
        productivity: this.selectedProductivity,
        targetAmount: this.selectedTargetAmount,
        totalAmountOwed: this.totalOwed,
        accountSubcategoryName: { label: 'Account Subcategory Name', isRequired: false, val: this.selectedSubtype.split('---')[0] }
      };
    }
  }

  private async storeChanges() {
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    const title = this.dataModelService.dataManagement.getDraftTitle();
    const plaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    await this.dataModelService.plaidManagement.storePlaidAccounts(wizeFiID, title, plaidAccounts);
    this.dataModelService.dataManagement.storeinfo();
  }

  public getCategoryFromType(type: string) {
    switch (type) {
      case 'Asset':
        return 'assets';
      case 'Debt':
        return 'liabilities';
      case 'Insurance':
        return 'assetProtection';
      case 'Budget':
        return 'budget';
      default:
        return UNKNOWN;
    }
  }

  public getTypeFromCategory(category: string) {
    switch (category) {
      case 'assets':
        return 'Asset';
      case 'liabilities':
        return 'Debt';
      case 'assetProtection':
        return 'Insurance';
      case 'budget':
        return 'Budget';
      default:
        return UNCATEGORIZED;
    }
  }

  /**
   * Sets the new wizeFiCategory of the account. This function dictates whether a new account will
   * be created through the categoryManagment.processLinkInfo() function or not
   */
  public setNewWizeFiCategory() {
    const wizeFiCategory = this.makeWizeFiCategory();
    this.newWizeFiCategory = this.shouldLinkToExistingAccount(wizeFiCategory)
      ? wizeFiCategory // no new wizeFiAccount will be created
      : NONE; // a new wizeFiAccount will be created
  }

  public hasLinkedWizeFiCategory(account) {
    return account && account.wizeFiCategory !== UNKNOWN && account.wizeFiCategory !== NONE;
  }

  private canSave() {
    if (this.wizeFiAccount == null) {
      this.wizeFiAccount = this.getCopyOfWizeFiAccount(this.plaidAccount);
    }
    if (this.wizeFiAccount.wizeFiCategory.val == NONE && this.makeWizeFiCategory() == NONE) {
      return true;
    }

    // if its a debt account, it cant possibly have a credit limit of 0.
    if (this.selectedCategory === 'liabilities' && this.wizeFiAccount.accountLimit && this.wizeFiAccount.accountLimit.val < 1) {
      if (this.wizeFiAccount.accountType.val === 'mortgage') {
        this.dataModelService.showMessage('error', 'Mortgage accounts must have an Original Amount above 0', 5000);
        return false;
      } else if (this.selectedSubtype != 'Personal Loan' && this.selectedSubtype != 'Other Nonproductive Debt') {
        this.dataModelService.showMessage('error', 'Debt accounts must have a Credit Limit above 0', 5000);
        return false;
      }
    }
    if (
      this.selectedCategory === 'liabilities' &&
      this.wizeFiAccount.monthlyMinimum.val < 1 &&
      this.wizeFiAccount.accountValue.val !== 0 &&
      this.selectedSubtype != 'Personal Loan' &&
      this.selectedSubtype != 'Other Nonproductive Debt'
    ) {
      console.error(this.wizeFiAccount);
      this.dataModelService.showMessage('error', 'Debt accounts with a remaining balance must have a Minimum payment above 0', 5000);
      return false;
    }
    // if its a savings account, require a target amount above 0;
    if (this.selectedCategory === 'assets' && this.selectedSubcategory === 'emergencySavings' && this.wizeFiAccount.targetAmount.val < 1) {
      this.dataModelService.showMessage('error', 'Savings accounts must have a target amount above 0', 5000);
      return false;
    }
    if (this.selectedCategory === 'assets' && this.selectedSubcategory === 'cashReserves' && this.wizeFiAccount.targetAmount.val < 1) {
      this.dataModelService.showMessage('error', 'Savings accounts must have a target amount above 0', 5000);
      return false;
    }
    return true;
  }

  private makeUniqueAccountName() {
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;
    return ItemManagement.makeUniqueAccountName(
      this.selectedSubtype.split('---')[0],
      this.dataModelService.dataModel.persistent.plans[curplan][this.selectedCategory],
      this.selectedSubcategory
    );
  }

  // INCOME DATA

  public onIncomeSave() {
    this.addMonthlySalaryAccountIfNeeded();
    this.updateDataModel();
  }

  public finishAddingIncomeSource(accountName: string) {
    this.createNewAccount(accountName);
  }

  public getIncomeAccountFromPlaidAccount(plaidAccount) {
    this.incomeAccounts.forEach(acct => {
      if (acct && acct.accountName === plaidAccount.accountName) {
        this.specifiedIncomeAccount = acct;
      }
    });
  }

  // Private Functions

  private addMonthlySalaryAccountIfNeeded() {
    const isNeeded = false;
    if (this.incomeAccounts.length === 0) {
      this.incomeAccounts.push(this.createNewAccount('Monthly Salary'));
      this.updateDataModel();
    }
  }
  private updateDataModel() {
    this.createNewAccount(this.accountName);
    this.dataModelService.putdata('income', this.income);
  }

  protected createNewAccount(accountName: string): any {
    const newAccountID = this.dataModelService.categoryManagement.getNewAccountID(this.incomeAccounts);
    const wizeFiCategory = this.dataModelService.categoryManagement.makeWizeFiCategory('income', 'income', newAccountID);

    const newAccount = {
      accountID: { label: 'Account ID', isRequired: true, val: newAccountID },
      accountName: { label: 'Account Name', isRequired: true, val: accountName || '' },
      accountType: { label: 'Account Type', isRequired: true, val: 'income' },
      genericCategory: { label: 'Generic Category', isRequired: true, val: 'none' },
      isRequired: { label: 'isRequired', isRequired: true, val: false },
      monthlyAmount: { label: 'Monthly Amount', isRequired: true, val: this.newIncome },
      actualMonthlyAmount: { label: 'Actual Monthly Amount', isRequired: true, val: this.newIncome },
      wizeFiCategory: { label: 'WizeFi Category', isRequired: true, val: wizeFiCategory }
    };
    this.incomeAccounts.push(newAccount);
  }
}
