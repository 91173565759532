import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import { IGraphItem } from '../../../interfaces/iGraphItem.interface';
import { DataModelService } from '../../../services/data-model/data-model.service';

@Component({
  selector: 'app-projection-chart',
  templateUrl: './projection-chart.component.html',
  styleUrls: ['./projection-chart.component.scss']
})
export class ProjectionChartComponent implements OnInit {
  // NOTE: inputDatasets are assumed to be based on monthly values (that is the x-values are all dates spaced a month apart)
  // inputs to this component, which are processed before passing to chart
  @Input() public inputDatasets: Array<{ data: IGraphItem[]; color: string }>;
  // some projection charts need to have a dotted line to emphasize max value of chart
  @Input() public showsDottedLineAtMax: boolean;
  @Input() public targetAmount?: number;
  @Input() public dark?: boolean;

  @ViewChild(BaseChartDirective, { static: true })
  public chart: BaseChartDirective;
  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[];
  public lineChartOptions: ChartOptions & { annotation: any };

  protected endpointDatasets: Array<{ data: any[]; color: string }> = [];
  protected endpointLabels: any[] = [];
  protected chartDurationInMonths: number;
  protected maxInputData: number;
  protected yAxisStepSize: number;
  protected cleanMaxChartValue: number;
  protected cleanMinChartValue: number;
  protected labelOffsetPercent: number;

  constructor(protected dataModelService: DataModelService) {}

  public ngOnInit() {
    this.setMaxInputData();
    this.setChartDurationInMonths();
    this.addXAxisLabels();
    this.addDottedLineAtMaxIfNeeded();
    this.tuneFinalDataPoints();
    this.addDatasetsToChart();
    this.setLineChartOptions();
    // console.log("LINE CHART DATA:", this.lineChartData);
  }

  protected setMaxInputData() {
    const ffWealth = this.dataModelService.dataModel.persistent.profile.financialFreedomTargetAmount;
    let maxValue;
    if (this.inputDatasets === undefined) {
      maxValue = ffWealth;
    } else {
      maxValue = this.inputDatasets.reduce(
        (totalMax, dataset) =>
          Math.max(
            dataset.data.reduce((datasetMax, data) => Math.max(data.y, datasetMax), 0),
            totalMax
          ),
        0
      );
    }

    this.maxInputData = maxValue > ffWealth ? ffWealth : maxValue;
  }

  protected setChartDurationInMonths() {
    if (this.inputDatasets === undefined) {
      this.chartDurationInMonths = 12 * 100;
    } else {
      this.chartDurationInMonths = this.inputDatasets.reduce((maxDuration, dataset) => Math.max(dataset.data.length, maxDuration), 0);
    }
  }

  public addXAxisLabels = () => {
    if (this.inputDatasets === undefined) {
      return (this.lineChartLabels = []);
    }

    const longestDataset = this.inputDatasets.find(dataset => dataset.data.length === this.chartDurationInMonths);

    this.lineChartLabels = longestDataset.data.map(point => {
      const date = new Date(point.x);
      return `${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`;
    });
  };

  protected addBaselineToChart() {
    const baselineData = Array(this.chartDurationInMonths).fill(0);
    this.lineChartData.push({
      data: baselineData,
      pointRadius: 0,
      borderWidth: 3,
      borderColor: this.getAccentColor()
    });
  }

  protected addDottedLineAtMaxIfNeeded() {
    if (this.showsDottedLineAtMax) {
      const dottedLineData = Array(this.chartDurationInMonths).fill(this.maxInputData);
      this.lineChartData.push({
        data: dottedLineData,
        pointRadius: 0,
        fill: false,
        borderColor: 'rgba(0,0,0)',
        borderWidth: 2,
        borderDash: [6]
      });
    }
  }

  /**
   * Applies some logic to make the chart datasets look as they do in the designs
   * 1. sorts datasets so that each fill-area is pushed on properly
   * 2. sets up datasets for endpoint bubbles
   */
  protected tuneFinalDataPoints() {
    this.sortDatasets();
    this.setupEndpoints();
  }

  // sorts datasets so that data with the longest time to reach a goal is pushed to a higher zindex
  protected sortDatasets() {
    if (this.inputDatasets !== undefined) {
      this.inputDatasets.sort((a, b) => a.data.length - b.data.length || 0);
    }
  }

  // sets up endpoints at max chart value
  protected setupEndpoints() {
    this.inputDatasets.forEach(dataset => {
      let hasFoundEarliestMin = false;
      const endpointData = dataset.data.map((point, index) => {
        if (!hasFoundEarliestMin && point.y === 0) {
          hasFoundEarliestMin = true;
          return point;
        }
        return { x: new Date(), y: null };
      });

      this.endpointDatasets.push({
        data: endpointData,
        color: dataset.color
      });
    });
  }

  protected addDatasetsToChart() {
    // sort datasets so that the earliest to reach max is added first (fill will look off otherwise)
    if (this.inputDatasets !== undefined) {
      this.inputDatasets.forEach(dataset => this.addDatasetLine(dataset));
    }
    this.endpointDatasets.forEach(dataset => this.addEndpointLine(dataset));
  }

  protected addDatasetLine(dataset: { data: IGraphItem[]; color: string }) {
    this.lineChartData.push({
      data: this.getChartLine(dataset),
      backgroundColor: dataset.color,
      borderColor: 'rgba(0,0,0,0)',
      pointRadius: 0
    });
  }

  protected addEndpointLine(dataset: { data: IGraphItem[]; color: string }) {
    this.lineChartData.push({
      data: this.getChartLine(dataset),
      fill: false,
      pointRadius: 8,
      pointBorderColor: dataset.color,
      pointBackgroundColor: 'rgba(255,255,255,1)',
      pointBorderWidth: 3
    });
  }

  protected getFFAmountLabelText = value => {
    const addCommas = num => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const nearestWholeThousand = Math.round(value / 1000) * 1000;
    return '$' + addCommas(nearestWholeThousand);
  };

  public getChartLine = dataset => dataset.data.map(point => point.y);

  public setLineChartOptions() {
    this.lineChartOptions = {
      responsive: true,
      tooltips: {
        // Disable the on-canvas tooltip
        enabled: false,

        custom(tooltipModel) {
          // Tooltip Element
          let tooltipEl = document.getElementById('chartjs-tooltip');

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<table></table>';
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add('above');
          } else {
            tooltipEl.classList.add('above');
          }

          function getBody(bodyItem) {
            return '';
          }

          // Set Text
          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map(getBody);

            if (titleLines) {
              console.log(tooltipModel);
            }
            let innerHtml = '<thead>';

            titleLines.forEach(title => {
              innerHtml += '<tr><th>' + title + '</th></tr>';
            });
            innerHtml += '</thead><tbody>';

            bodyLines.forEach((body, i) => {});
            innerHtml += '</tbody>';

            const tableRoot = tooltipEl.querySelector('table');
            tableRoot.innerHTML = innerHtml;
          }

          // `this` will be the overall tooltip
          const position = this._chart.canvas.getBoundingClientRect();
          tooltipEl.style.backgroundColor = '#000';
          tooltipEl.style.color = '#FFF';
          tooltipEl.style.opacity = '80%';
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          // Display, position, and set styles for font
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;

          tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
          tooltipEl.style.pointerEvents = 'none';
          tooltipEl.style.cursor = 'pointer';
          tooltipEl.style.borderRadius = '15px';
        }
      },
      hover: { mode: null },
      layout: {
        padding: {
          right: 10 // prevents right most xaxis label from getting cut-off
        }
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            ticks: {
              padding: 10,
              fontSize: 17,
              maxRotation: 0,
              autoSkip: false,
              fontColor: this.getAccentColor(),
              callback: (value, index, values) => {
                if (typeof value === 'string') {
                  return parseInt(value, 10) % 10 === 0 ? value : null;
                } else {
                  return value % 10 === 0 ? value : null;
                }
              }
            },
            gridLines: {
              display: false
            }
          }
        ],
        yAxes: [
          {
            id: 'y-axis-0',
            position: 'left',
            gridLines: {
              display: false
            },
            ticks: {
              max: this.cleanMaxChartValue,
              fontColor: this.getAccentColor(),
              minor: {
                fontSize: 17
              },
              beginAtZero: true,
              callback: (value, index, values) => {
                this.cleanMinChartValue = 0;
                // this callback provides logic for formatting the y axis (scale and ticks)
                if (!this.cleanMaxChartValue && !isNaN(this.cleanMaxChartValue)) {
                  const newMax = 2 * parseInt(values[0].toString(), 10);
                  this.updateYAxisMax(newMax);
                }
                const stepSize = this.getStepSize(values);
                if (this.yAxisStepSize !== stepSize) {
                  this.updateYAxisStepSize(stepSize);
                }
                return this.getYAxisLabel(value);
              }
            }
          }
        ]
      },
      annotation: {
        annotations: [
          {
            type: 'line',
            mode: 'horizontal',
            scaleID: 'y-axis-0',
            value: this.targetAmount || this.maxInputData,
            borderColor: 'transparent',
            borderWidth: 0,
            label: {
              backgroundColor: 'transparent',
              enabled: true,
              fontColor: 'black',
              content: 'Financial Freedom',
              position: 'center',
              yAdjust: -15,
              fontSize: 17,
              fontStyle: 'italic'
            }
          },
          {
            type: 'line',
            mode: 'horizontal',
            scaleID: 'y-axis-0',
            value: this.targetAmount || this.maxInputData,
            borderColor: 'transparent',
            borderWidth: 0,
            label: {
              backgroundColor: '#4b82fa',
              enabled: true,
              fontColor: 'white',
              content: this.getFFAmountLabelText(this.targetAmount || this.maxInputData),
              xPadding: 6,
              yPadding: 6,
              cornerRadius: 40,
              position: 'left',
              fontSize: 17
            }
          }
        ]
      }
    };
  }

  protected getAccentColor(): string {
    return this.dark === true ? 'rgba(255,255,255)' : 'rgba(0,0,0)';
  }

  protected getStepSize(yAxisValues) {
    if (this.targetAmount) {
      return yAxisValues[0] - this.cleanMinChartValue;
    } else {
      return (yAxisValues[0] - this.cleanMinChartValue) / 2;
    }
  }

  protected updateYAxisStepSize = stepSize => {
    this.yAxisStepSize = stepSize;
    // this.lineChartOptions.scales.yAxes[0].ticks.stepSize = stepSize;
    this.chart.update();
  };

  protected updateYAxisMax = max => {
    this.cleanMaxChartValue = max;
    this.lineChartOptions.scales.yAxes[0].ticks.max = max;
    this.chart.update();
  };

  protected getYAxisLabel = value => {
    const prepend = (value < 0 ? '-' : '') + '$';
    const magnitude = Math.abs(value);
    if (magnitude >= 1000000) {
      return prepend + magnitude / 1000000 + 'MM';
    } else if (magnitude >= 1000) {
      return prepend + magnitude / 1000 + 'K';
    } else {
      return prepend + value;
    }
  };

  public lineChartType = 'line';

  public chartHovered({ event, active }: { event: MouseEvent; active: Array<{}> }): void {
    // console.log(event, active);
  }

  public toggleDataAtDatasetIndex(index) {
    if (index > this.chart.datasets.length - 1 || index < 0) {
      // console.log("tried to toggle dataset at invalid index... index:", index);
      return;
    }
    const isHidden = this.chart.isDatasetHidden(index);
    this.chart.hideDataset(index, !isHidden);
  }
}
