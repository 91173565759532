import { ISettings } from '../../interfaces/iSettings.interface';
import { IVerifyResult } from '../../interfaces/iVerifyResults.interface';

export class CSettings {
  constructor(public settings: ISettings) {}

  public verifyAllDataValues(): IVerifyResult {
    // initialize
    const result: IVerifyResult = { hadError: false, messages: [] };

    // check each data field
    // TODO add validity checks here (e.g. verify legal currency code [or use "picklist" to provide values])

    return result;
  } // verifyAllDataValues
} // class CSettings
