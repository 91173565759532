import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DataModelService } from '../../../../services/data-model/data-model.service';

@Component({
  selector: 'app-account-type-selector',
  templateUrl: './account-type-selector.component.html',
  styleUrls: ['./account-type-selector.component.scss']
})
export class AccountTypeSelectorComponent implements OnInit {
  public readonly UNKNOWN = 'unknown';
  public readonly NONE = 'none';
  public readonly UNCATEGORIZED = 'Uncategorized';
  public hasChangedSchema = false;

  public originalCategory;
  public originalSubcategory;
  public originalSubtype;

  constructor(
    protected dataModelService: DataModelService,
    @Inject(MAT_DIALOG_DATA) public data: IAccountTypeSelectorData,
    public matDialogRef: MatDialogRef<AccountTypeSelectorComponent>
  ) {
    this.data = this.data || {};
    this.data.plaidAccount = this.data.plaidAccount || {};
    this.data.selectedCategory = this.data.selectedCategory || this.UNKNOWN;
    this.data.selectedSubcategory = this.data.selectedSubcategory || this.UNKNOWN;
    this.data.selectedType = this.data.selectedType || this.UNCATEGORIZED;
    this.data.selectedSubtype = this.data.selectedSubtype || this.UNCATEGORIZED;
    this.data.accountName = this.data.accountName || this.data.plaidAccount.accountName || '---';
    this.data.showManualAccountChooser = this.data.showManualAccountChooser || false;
  }

  public ngOnInit() {
    this.setAccountDetailsState();
  }

  private setAccountDetailsState() {
    const categoryManagement = this.dataModelService.categoryManagement;
    const parts = categoryManagement.isValidWizeFiCategoryAccount(this.data.plaidAccount.wizeFiCategory)
      ? this.dataModelService.categoryManagement.decodeWizeFiCategory(this.data.plaidAccount.wizeFiCategory)
      : 'unknown';

    this.originalCategory = (parts && parts.category) || this.UNKNOWN;
    this.originalSubcategory = (parts && parts.subcategory) || this.UNKNOWN;
    this.originalSubtype = (parts && parts.accountName) || this.UNCATEGORIZED;

    this.data.selectedCategory = this.originalCategory;
    this.data.selectedSubcategory = this.originalSubcategory;
    this.data.selectedSubtype = this.originalSubtype;
  }

  // offers child components access to change which type of account details modal is shown when a
  // new subtype is selected
  public onChangeAccountSchema({ category, subcategory, subtype, accountName }) {
    this.data.selectedCategory = category;
    this.data.selectedSubcategory = subcategory;
    this.data.selectedSubtype = subtype;
    this.data.accountName = accountName;

    this.hasChangedSchema = this.data.selectedCategory !== this.originalCategory || this.data.selectedSubcategory !== this.originalSubcategory;

    if (this.hasChangedSchema) {
      setTimeout(() => {
        const bodyelement = document.querySelector('.col.body.body-scroll');
        const bodyRowItem = document.querySelectorAll('.col.body.body-scroll .row.item')[4];

        if (bodyelement instanceof HTMLElement && bodyRowItem instanceof HTMLElement) {
          bodyelement.scrollTop = bodyRowItem.offsetTop;
        }
      }, 300);
    }
  }
}

export interface IAccountTypeSelectorData {
  isPostSetup?: boolean;
  isManual?: boolean;
  plaidAccount?: any;
  selectedCategory?: string;
  selectedSubcategory?: string;
  selectedType?: string;
  selectedSubtype?: string;
  accountName?: string;
  showManualAccountChooser?: boolean;
}
