import { Injectable } from '@angular/core';
import { DataModelService } from '../services/data-model/data-model.service';
@Injectable()
export class DataStorage {
  constructor(private dataModelService: DataModelService) {}

  public async fetchDataFromCloud(logins: any): Promise<any> {
    if (logins !== null) {
      this.dataModelService.dataModel.global.wizeFiID = window.sessionStorage.getItem('wizeFiID');
      this.dataModelService.dataModel.global.isFBLogin = window.sessionStorage.getItem('isFBLogin') === '1' ? true : false;

      await this.dataModelService.loginManagement.establishAWSobjects(logins);
      await this.dataModelService.dataManagement.fetchdata();
      await this.dataModelService.getBraintreeData();
      await this.dataModelService.plaidManagement.getPlaidData();
      await this.dataModelService.initializeAffiliateManagement();
    }
  }
}
