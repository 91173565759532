import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cafr-left-to-apply',
  templateUrl: './cafr-left-to-apply.component.html',
  styleUrls: ['./cafr-left-to-apply.component.scss']
})
export class CafrLeftToApplyComponent implements OnInit {
  @Input() public cafrCreated: number;
  @Input() public cafrUsed: number;

  constructor() {}

  public ngOnInit() {}
}
