import { Component, OnInit } from '@angular/core';
import { UrlManagement } from '../../../utilities/url-management.class';

@Component({
  selector: 'app-accounts-submenu',
  templateUrl: './accounts-submenu.component.html',
  styleUrls: ['./accounts-submenu.component.scss']
})
export class AccountsSubmenuComponent implements OnInit {
  protected url = new UrlManagement();
  constructor() {}

  public ngOnInit() {}
}
