// admin-manage-tree.component.ts

import { Component, OnInit } from '@angular/core';
import * as plaid from 'plaid';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { possibleFieldNames } from '../../../services/data-model/data-model_0001.data';

// const plaid = require('plaid');
import { IFilterValues } from '../../../interfaces/iFilterValues.interface';

declare let window: any; // resolve error from TypeScript type checking
declare let Plaid: any; // data object from link-initialize.js script in index.html

@Component({
  selector: 'app-admin-manage-tree',
  templateUrl: './admin-manage-tree.component.html',
  styleUrls: ['./admin-manage-tree.component.css']
})
export class AdminManageTreeComponent implements OnInit {
  public loading: any; // used to control "is working" visual on screen

  // General
  public affiliateID2WizeFiData: any;
  public tree: any;
  public rootAffiliateID: string;
  public orphanAffiliateID: string;
  public wantShowDebug: boolean;
  public filter: string;
  public filterValue: string;
  public userInfoList: any;
  public messages: string[];

  // Change AffiliateAlias
  public wantChangeAffiliateAlias: boolean;
  public affiliateIDchangeAlias: string; // affiliateID of user for whom to change affiliateAlias
  public newAffiliateAlias: string;
  public wantChangeAffiliateAliasAffiliateIDSearch: boolean;
  public wantChangeAffiliateAliasNewParentAffiliateIDSearch: boolean;
  public selectedUserInfo: any;

  // Change Parent AffiliateID
  public wantChangeParentAffiliateID: boolean;
  public affiliateIDchangeParent: string; // affiliateID of user for whom to change parentAffiliateID
  public newParentAffiliateID: string;
  public wantChangeParentAffiliateIDAffiliateIDSearch: boolean;
  public wantChangeParentAffiliateIDNewParentAffiliateIDSearch: boolean;

  // Change AffiliateID
  public wantChangeAffiliateID: boolean;
  public oldAffiliateID: string;
  public newAffiliateID: string;
  public wantChangeAffiliateIDoldSearch: boolean;
  public wantChangeAffiliateIDnewSearch: boolean;

  // Delete AffiliateID
  public wantDeleteAffiliateID: boolean;
  public affiliateIDdelete: string;
  public wantDeleteAffiliateIDSearch: boolean;

  // Display Affiliate Tree
  public wantDisplayAffiliateTree: boolean;
  public affiliateIDdisplay: string; // affiliateID of user for whom to display affiliate tree information
  public wantDisplayTreeAffiliateIDSearch: boolean;
  public parentLevelCount: number;
  public childLevelCount: number;
  public displayList: any;
  public displayListAttributes: any;
  public selectedMode: any;
  public selectedFilter: string;

  // Repair Affiliate Tree
  public wantRepairAffiliateTree: boolean;
  public selectedRepairMode: string;

  // Undo Action
  public wantUndoAction: boolean;
  public selectedManageTreeLogItem: any;
  public manageTreeLogItems: any;
  public filteredManageTreeLogItems: any;

  public logDateRange: string;
  public selectedLogUser: string;
  public wizeFiIDList: any;
  public selectedLogAction: string;

  public selectedLogDateSort: string;
  public selectedLogUserSort: string;
  public selectedLogActionSort: string;

  public promptLogUser: string;
  public promptLogAction: string;
  public promptLogUserSort: string;
  public promptLogActionSort: string;
  public promptLogDateSort: string;

  constructor(public dataModelService: DataModelService) {}

  public ngOnInit() {
    this.loading = {};
    this.loading.isLoading = false;

    // General
    this.affiliateID2WizeFiData = {};
    this.tree = this.dataModelService.affiliateManagement.tree;
    this.rootAffiliateID = this.dataModelService.affiliateManagement.rootAffiliateID;
    this.orphanAffiliateID = this.dataModelService.affiliateManagement.orphanAffiliateID;
    this.wantShowDebug = false;
    this.filter = 'select filter';
    this.filterValue = '';
    this.userInfoList = [];
    this.selectedUserInfo = 'select user info';
    this.messages = [];

    // Change AffiliateAlias
    this.wantChangeAffiliateAlias = false;
    this.affiliateIDchangeAlias = '';
    this.newAffiliateAlias = '';
    this.wantChangeAffiliateAliasAffiliateIDSearch = false;
    this.wantChangeAffiliateAliasNewParentAffiliateIDSearch = false;

    // Change Parent AffiliateID
    this.wantChangeParentAffiliateID = false;
    this.affiliateIDchangeParent = '';
    this.newParentAffiliateID = '';
    this.wantChangeParentAffiliateIDAffiliateIDSearch = false;
    this.wantChangeParentAffiliateIDNewParentAffiliateIDSearch = false;

    // Change AffiliateID
    this.wantChangeAffiliateID = false;
    this.oldAffiliateID = '';
    this.newAffiliateID = '';
    this.wantChangeAffiliateIDoldSearch = false;
    this.wantChangeAffiliateIDnewSearch = false;

    // Delete AffiliateID
    this.wantDeleteAffiliateID = false;
    this.affiliateIDdelete = '';
    this.wantDeleteAffiliateIDSearch = false;

    // Display Affiliate Tree
    this.wantDisplayAffiliateTree = false;
    this.affiliateIDdisplay = this.rootAffiliateID;
    this.parentLevelCount = 1;
    this.childLevelCount = 1;
    this.displayList = [];
    this.displayListAttributes = [];
    this.selectedMode = 'subtree';
    this.selectedFilter = 'all';
    this.wantDisplayTreeAffiliateIDSearch = false;

    // Repair Affiliate Tree
    this.wantRepairAffiliateTree = false;
    this.selectedRepairMode = 'preview';

    // Undo Action
    this.wantUndoAction = false;
    this.selectedManageTreeLogItem = 'select undo information to use';
    this.manageTreeLogItems = [];
    this.filteredManageTreeLogItems = [];

    this.selectedLogUser = 'select user';
    this.wizeFiIDList = [];
    this.selectedLogAction = 'select action';
    this.logDateRange = '';

    this.promptLogUser = 'select user';
    this.promptLogAction = 'select action';
    this.promptLogUserSort = 'user';
    this.promptLogActionSort = 'action';
    this.promptLogDateSort = 'date';

    this.selectedLogUserSort = this.promptLogUserSort;
    this.selectedLogActionSort = this.promptLogActionSort;
    this.selectedLogDateSort = this.promptLogDateSort;
  } // ngOnInit

  /////////////////////////////////////////////////////////
  // utility routines
  /////////////////////////////////////////////////////////

  public getWizeFiDataParentAffiliateID() {
    return this.dataModelService.dataModel.persistent.header.parentAffiliateID;
  } // getWizeFiDataParentAffiliateID

  public getWizeFiAffiliateTreeParentAffiliateID() {
    return this.tree[this.dataModelService.dataModel.affiliateID].node.parent;
  } // getWizeFiAffiliateTreeParentAffiliateID

  public getWizeFiDataAffiliateAlias() {
    return this.dataModelService.dataModel.affiliateAlias;
  } // getWizeFiDataAffiliateAlias

  public getWizeFiAffiliateTreeAffiliateAlias() {
    const affiliateID = this.dataModelService.dataModel.affiliateID;
    return this.tree[affiliateID].affiliateAlias;
  } // getWizeFiAffiliateTreeAffiliateAlias

  public obtainWizeFiID(affiliateID) {
    return new Promise((resolve, reject) => {
      // define params to guide the query operation
      const params = {
        TableName: 'WizeFiData',
        IndexName: 'WizeFiData-AffiliateID',
        KeyConditionExpression: 'affiliateID = :affiliateID',
        ExpressionAttributeValues: { ':affiliateID': affiliateID }
      };

      // get info from DynamoDB WizeFiData-AffiliateID index
      this.dataModelService.dataModel.global.docClient.query(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          if (data.Items.length === 0) {
            reject('in obtainWizeFiID no item exists for affiliateID: ' + affiliateID);
          } else {
            const item = data.Items[0];
            resolve(item.wizeFiID);
          }
        }
      });
    }); // return Promise
  } // obtainWizeFiID

  public fetchWizeFiDataItem(wizeFiID) {
    return new Promise((resolve, reject) => {
      const params = {
        TableName: 'WizeFiData',
        Key: { wizeFiID }
      };

      this.dataModelService.dataModel.global.docClient.get(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          if (!data.hasOwnProperty('Item')) {
            reject('in fetchWizeFiDataItem no Item exists for wizeFiID: ' + wizeFiID);
          } else {
            const item = data.Item;
            item.persistent = JSON.parse(item.persistent);
            resolve(item);
          }
        }
      });
    }); // return Promise
  } // fetchWizeFiDataItem

  public storeWizeFiDataItem(item) {
    return new Promise((resolve, reject) => {
      // define params to guide put operation
      item.persistent = JSON.stringify(item.persistent);
      const params = {
        TableName: 'WizeFiData',
        Item: item
      };

      // put info into DynamoDB table
      this.dataModelService.dataModel.global.docClient.put(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      }); // docClient.put
    }); // return Promise
  } // storeWizeFiDataItem

  public scanWizeFiData(filter, filterValue) {
    return new Promise((resolve, reject) => {
      const scanWizeFiData0 = async (funcFilter, funcFilterValue) => {
        let scanRresult; // contains list of items for a single scan operation (there is a limit on the number of items that a scan can retrieve)
        let items = []; // items retrieved from WizeFiData in a single scan operation
        const userInfoList = []; // list of user info collected from all scan operations
        let params: any;
        params = {};
        params.TableName = 'WizeFiData';

        // scan through all userInfoList from DynamoDB WizeFiData table and captures info on those who match a filter criteria
        do {
          scanRresult = await this.dataModelService.dataModel.global.docClient.scan(params).promise();
          items = scanRresult.Items;

          for (const item of items) {
            item.persistent = JSON.parse(item.persistent);
            let haveMatch = false;

            switch (funcFilter) {
              case 'nameFirst':
              case 'nameLast':
              case 'email':
                haveMatch = item.persistent.profile[funcFilter] === funcFilterValue;
                break;
              case 'affiliateAlias':
                haveMatch = item.affiliateAlias === funcFilterValue;
                break;
              default:
                console.log('Error -- in scanWizeFiData filter (' + funcFilter + ') is not recognized');
            } // switch

            if (haveMatch) {
              const infoItem = {
                affiliateID: item.affiliateID,
                affiliateAlias: item.affiliateAlias,
                nameFirst: item.persistent.profile.nameFirst,
                nameLast: item.persistent.profile.nameLast,
                email: item.persistent.profile.email,
                dateCreated: item.persistent.header.dateCreated,
                dateUpdated: item.persistent.header.dateUpdated
              };
              userInfoList.push(infoItem);
            }
          } // for

          params.ExclusiveStartKey = scanRresult.LastEvaluatedKey;
        } while (typeof scanRresult.LastEvaluatedKey !== 'undefined');

        return userInfoList;
      }; // scanWizeFiData0

      scanWizeFiData0(filter, filterValue)
        .then(userInfoList => {
          resolve(userInfoList);
        })
        .catch(err => {
          reject(err);
        });
    }); // return Promise
  } // scanWizeFiData

  public fetchWizeFiAffiliateTreeItem(affiliateID) {
    return new Promise((resolve, reject) => {
      const params = {
        TableName: 'WizeFiAffiliateTree',
        Key: { affiliateID }
      };

      this.dataModelService.dataModel.global.docClient.get(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          if (!data.hasOwnProperty('Item')) {
            reject('in fetchWizeFiAffiliateTreeItem no Item exists for affiliateID: ' + affiliateID);
          } else {
            const item = data.Item;
            item.node = JSON.parse(item.node);
            resolve(item);
          }
        }
      });
    }); // return Promise
  } // fetchWizeFiAffiliateTreeItem

  public storeWizeFiAffiliateTreeItem(item) {
    return new Promise((resolve, reject) => {
      // define params to guide put operation
      item.node = JSON.stringify(item.node);
      const params = {
        TableName: 'WizeFiAffiliateTree',
        Item: item
      };

      // put info into DynamoDB table
      this.dataModelService.dataModel.global.docClient.put(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      }); // docClient.put
    }); // return Promise
  } // storeWizeFiAffiliateTreeItem

  public deleteWizeFiAffiliateTreeItem(affiliateID) {
    return new Promise((resolve, reject) => {
      // define params to guide delete operation
      const params = {
        TableName: 'WizeFiAffiliateTree',
        Key: { affiliateiID: affiliateID }
      };
      this.dataModelService.dataModel.global.docClient.delete(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      });
    }); // return Promise
  } // deleteWizeFiAffiliateTreeItem

  public scanManageTreeLog(criteria) {
    return new Promise((resolve, reject) => {
      const scanManageTreeLog0 = async () => {
        let scanRresult; // contains list of items for a single scan operation (there is a limit on the number of items that a scan can retrieve)
        let items = []; // items retrieved from ManageTreeLog in a single scan operation
        const manageTreeLogItems = []; // list of manageTreeLogItem data from all scan operations
        let params: any;
        params = {};
        params.TableName = 'ManageTreeLog';

        // scan through all items in ManageTreeLog table
        do {
          scanRresult = await this.dataModelService.dataModel.global.docClient.scan(params).promise();
          items = scanRresult.Items;

          for (const item of items) {
            item.undoData = JSON.parse(item.undoData);
            manageTreeLogItems.push(item); // %//

            /*
                        //TODO rework the following code to utilize criteria information to filter the data
                        let haveMatch = false;

                        switch (filter)
                        {
                            case 'nameFirst':
                            case 'nameLast':
                            case 'email':          haveMatch = (item.persistent.profile[filter] === filterValue);  break;
                            case 'affiliateAlias': haveMatch = (item.affiliateAlias === filterValue);         break;
                            default: console.log('Error -- in scanWizeFiData filter (' + filter + ') is not recognized');
                        }   // switch

                        if (haveMatch)
                        {
                            let infoItem =
                            {
                                affiliateID:    item.affiliateID,
                                affiliateAlias: item.affiliateAlias,
                                nameFirst:      item.persistent.profile.nameFirst,
                                nameLast:       item.persistent.profile.nameLast,
                                email:          item.persistent.profile.email,
                                dateCreated:    item.persistent.header.dateCreated,
                                dateUpdated:    item.persistent.header.dateUpdated
                            };
                            manageTreeLogItems.push(item);
                        }
                        */
          } // for

          params.ExclusiveStartKey = scanRresult.LastEvaluatedKey;
        } while (typeof scanRresult.LastEvaluatedKey !== 'undefined');

        return manageTreeLogItems;
      }; // scanManageTreeLog0

      scanManageTreeLog0()
        .then(manageTreeLogItems => {
          resolve(manageTreeLogItems);
        })
        .catch(err => {
          reject(err);
        });
    }); // return Promise
  } // scanManageTreeLog

  public storeManageTreeLogItem(item) {
    return new Promise((resolve, reject) => {
      // define params to guide put operation
      item.undoData = JSON.stringify(item.undoData);
      const params = {
        TableName: 'ManageTreeLog',
        Item: item
      };

      // put info into DynamoDB table
      this.dataModelService.dataModel.global.docClient.put(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      }); // docClient.put
    }); // return Promise
  } // storeManageTreeLogItem

  public clearMessages() {
    this.messages.splice(0, this.messages.length);
  } // clearMessages

  public isValidAffiliateID(which, affiliateID) {
    let isValid = true;

    if (!affiliateID.match(/^[a-z]{7}$/)) {
      isValid = false;
      this.messages.push('ERROR -- ' + which + ' (' + affiliateID + ') must consist of 7 lower case letters');
    } else if (!this.tree.hasOwnProperty(affiliateID)) {
      isValid = false;
      this.messages.push('ERROR -- ' + which + ' (' + affiliateID + ') is not valid');
    }
    return isValid;
  } // isValidAffiliateID

  public isValidNewAffiliateID(which, affiliateID) {
    let isValid = true;

    if (!affiliateID.match(/^[a-z]{7}$/)) {
      isValid = false;
      this.messages.push('ERROR -- ' + which + ' (' + affiliateID + ') must consist of 7 lower case letters');
    } else if (this.tree.hasOwnProperty(affiliateID)) {
      isValid = false;
      this.messages.push('ERROR -- ' + which + ' (' + affiliateID + ') is not valid new affiliateID');
    }
    return isValid;
  } // isValidNewAffiliateID

  public isValidAffiliateAlias(affiliateAlias) {
    let isValid = true;
    const Alias2ID = this.dataModelService.affiliateManagement.Alias2ID;

    if (!affiliateAlias.match(/^[a-zA-Z0-9]+$/)) {
      isValid = false;
      this.messages.push('ERROR -- affiliateAlias (' + affiliateAlias + ') must contain only lower and upper case letters and numbers');
    } else if (Alias2ID.hasOwnProperty(affiliateAlias)) {
      isValid = false;
      this.messages.push('ERROR --  affiliateAlias (' + affiliateAlias + ') is already in use');
    }
    return isValid;
  } // isValidAffiliateAlias

  public filterChanged() {
    // this.affiliateID = "";
    this.filterValue = '';
    this.selectedUserInfo = 'select user info';
    this.userInfoList = [];
  } // filterChanged

  public filterValueChanged() {
    // this.affiliateID = "";
    this.selectedUserInfo = 'select user info';
    this.userInfoList = [];
  } // filterValueChanged()

  public checkboxChanged(wantSearch) {
    this[wantSearch] = false; // suppress other Search option found in this option
    this.filter = 'select filter';
    this.filterValue = '';
    this.userInfoList = [];
  } // checkboxChanged

  public makeUserInfoList(affiliateIDName, filter, filterValue) {
    const processResult = userInfoList => {
      if (userInfoList.length === 0) {
        this.messages.push('no user matches filter criteria');
      } else if (userInfoList.length === 1) {
        // use result from only user who matched filter criteria
        this[affiliateIDName] = userInfoList[0].affiliateID;
      } else {
        // enable user to select which user result to use from all those who matched filter criteria
        this.userInfoList = userInfoList;
      }
    }; // processResult

    this.loading.isLoading = true;

    this.scanWizeFiData(filter, filterValue)
      // .then((userInfoList) => {this.userInfoList = userInfoList})
      .then(userInfoList => processResult(userInfoList))
      .catch(err => {
        console.error('error in makeUserInfoList: ', err);
      })
      .finally(() => {
        this.loading.isLoading = false;
      });
  } // makeUserInfoList

  /////////////////////////////////////////////////////////
  // routines to support Change AffiliateAlias
  /////////////////////////////////////////////////////////

  public changeAffiliateAlias(affiliateID, newAffiliateAlias) {
    const changeAffiliateAlias0 = async () => {
      // make declaration to circumvent typescript type checks
      let wizeFiDataItem: any;
      let wizeFiAffiliateTreeItem: any;

      // make sure affiliate tree is in a valid state
      this.repairAffiliateTree('execute');

      // validate input
      affiliateID = affiliateID.trim();
      newAffiliateAlias = newAffiliateAlias.trim();
      this.messages.push('changeAffiliateAlias(affiliateID,newAffiliateAlias)');
      this.messages.push('changeAffiliateAlias(' + affiliateID + ',' + newAffiliateAlias + ')');
      let hadError = false;
      if (!this.isValidAffiliateID('affiliateID', affiliateID)) {
        hadError = true;
      }
      if (!this.isValidAffiliateAlias(newAffiliateAlias)) {
        hadError = true;
      }

      if (hadError) {
        this.messages.push('NOTE -- must resolve errors');
      } else {
        /////////////////////////////////////////////////////
        // change affiliateAlias to newAffiliateAlias
        /////////////////////////////////////////////////////

        // memory storage
        this.dataModelService.dataModel.affiliateAlias = newAffiliateAlias;
        this.tree[affiliateID].affiliateAlias = newAffiliateAlias;

        // persistent storage
        const wizeFiID = await this.obtainWizeFiID(affiliateID);
        wizeFiDataItem = await this.fetchWizeFiDataItem(wizeFiID);
        wizeFiAffiliateTreeItem = await this.fetchWizeFiAffiliateTreeItem(affiliateID);
        const oldAffiliateAlias = wizeFiDataItem.affiliateAlias;
        wizeFiDataItem.affiliateAlias = newAffiliateAlias;
        wizeFiAffiliateTreeItem.affiliateAlias = newAffiliateAlias;
        await this.storeWizeFiDataItem(wizeFiDataItem);
        await this.storeWizeFiAffiliateTreeItem(wizeFiAffiliateTreeItem);
        // this.messages.push('NOTE: changeAffiliateAlias save data to persistent storage is turned off');  //%//

        // manage log entry
        const userWizeFiID = this.dataModelService.dataModel.global.wizeFiID; // user who made change to tree
        const logDate = new Date().toISOString().substr(0, 19); // YYYY-MM-DDTHH:MM:SS  // date when change was made
        const undoData = {
          affiliateIDchangeAlias: affiliateID,
          newAffiliateAlias,
          oldAffiliateAlias
        };
        const manageTreeLogItem = {
          wizeFiID: userWizeFiID,
          actionDate: logDate,
          action: 'changeAffiliateAlias',
          undoData
        };
        await this.storeManageTreeLogItem(manageTreeLogItem);
        await this.scanManageTreeLog(null);

        this.messages.push('affiliateAlias has been changed');
      }
    }; // changeAffiliateAlias0

    this.loading.isLoading = true;

    changeAffiliateAlias0()
      .catch(err => {
        console.error('error in error in changeAffiliateAlias: ', err);
        this.messages.push('look in console for error message information');
      })
      .finally(() => {
        this.loading.isLoading = false;
      });
  } // changeAffiliateAlias

  public displayUserInfo(userInfo) {
    const userInfoString =
      userInfo.affiliateID +
      '  ' +
      userInfo.nameFirst +
      '  ' +
      userInfo.nameLast +
      '  ' +
      userInfo.email +
      '  ' +
      userInfo.affiliateAlias +
      '  ' +
      userInfo.dateCreated.substr(0, 10) +
      '  ' +
      userInfo.dateUpdated.substr(0, 10);
    return userInfoString;
  } // displayUserInfo

  public selectedUserInfoChanged(affiliateIDName, selectedUserInfo) {
    this[affiliateIDName] = typeof selectedUserInfo === 'string' ? '' : selectedUserInfo.affiliateID;
  } // selectedUserInfoChanged

  /////////////////////////////////////////////////////////
  // routines to support Change Parent AffiliateID
  /////////////////////////////////////////////////////////

  public changeParentAffiliateID(affiliateID, newParentAffiliateID) {
    const changeParentAffiliateID0 = async () => {
      // initialize
      let wizeFiID, ndx;
      let wizeFiDataItem: any;
      let wizeFiAffiliateTreeItem: any;

      // make sure affiliate tree is in a valid state
      this.repairAffiliateTree('execute');

      // validate input
      affiliateID = affiliateID.trim();
      newParentAffiliateID = newParentAffiliateID.trim();
      this.messages.push('changeParentAffiliateID(affiliateID,newParentAffiliateID)');
      this.messages.push('changeParentAffiliateID(' + affiliateID + ',' + newParentAffiliateID + ')');
      let hadError = false;
      if (!this.isValidAffiliateID('affiliateID', affiliateID)) {
        hadError = true;
      }
      if (!this.isValidAffiliateID('newParentAffiliateID', newParentAffiliateID)) {
        hadError = true;
      }

      if (hadError) {
        this.messages.push('NOTE -- must resolve errors');
      } else {
        /////////////////////////////////////////////////////////////
        // change parent of this node
        /////////////////////////////////////////////////////////////

        // memory storage
        this.dataModelService.dataModel.persistent.header.parentAffiliateID = newParentAffiliateID;
        const oldParentAffiliateID = this.tree[affiliateID].node.parent;
        // console.log('before: tree[affiliateID].node.parent ' + this.tree[affiliateID].node.parent);  //%//
        this.tree[affiliateID].node.parent = newParentAffiliateID;
        // console.log('after:  tree[affiliateID].node.parent ' + this.tree[affiliateID].node.parent);  //%//

        // persistent storage
        wizeFiID = await this.obtainWizeFiID(affiliateID);
        wizeFiDataItem = await this.fetchWizeFiDataItem(wizeFiID);
        wizeFiAffiliateTreeItem = await this.fetchWizeFiAffiliateTreeItem(affiliateID);

        wizeFiDataItem.persistent.header.parentAffiliateID = newParentAffiliateID;
        wizeFiAffiliateTreeItem.node.parent = newParentAffiliateID;

        await this.storeWizeFiDataItem(wizeFiDataItem);
        await this.storeWizeFiAffiliateTreeItem(wizeFiAffiliateTreeItem);

        /////////////////////////////////////////////////////////////
        // remove child entry from oldParentAffiliateID
        /////////////////////////////////////////////////////////////

        // memory storage
        ndx = this.tree[oldParentAffiliateID].node.child.indexOf(affiliateID);
        if (ndx !== -1) {
          // console.log('before: tree[oldParentAffiliateID].node.child', this.tree[oldParentAffiliateID].node.child);  //%//
          this.tree[oldParentAffiliateID].node.child.splice(ndx, 1);
          // console.log('after:  tree[oldParentAffiliateID].node.child', this.tree[oldParentAffiliateID].node.child);  //%//
        }

        // persistent storage
        wizeFiAffiliateTreeItem = await this.fetchWizeFiAffiliateTreeItem(oldParentAffiliateID);
        ndx = wizeFiAffiliateTreeItem.node.child.indexOf(affiliateID);
        if (ndx !== -1) {
          // console.log('before: wizeFiAffiliateTreeItem.node.child', wizeFiAffiliateTreeItem.node.child);  //%//
          wizeFiAffiliateTreeItem.node.child.splice(ndx, 1);
          // console.log('after:  wizeFiAffiliateTreeItem.node.child', wizeFiAffiliateTreeItem.node.child);  //%//
          await this.storeWizeFiAffiliateTreeItem(wizeFiAffiliateTreeItem);
        }

        /////////////////////////////////////////////////////////////
        // add child entry to newParentAffiliateID
        /////////////////////////////////////////////////////////////

        // memory storage
        if (!this.tree[newParentAffiliateID].node.child.includes(affiliateID)) {
          // console.log('before: tree[newParentAffiliateID].node.child', this.tree[newParentAffiliateID].node.child);  //%//
          this.tree[newParentAffiliateID].node.child.push(affiliateID);
          // console.log('after:  tree[newParentAffiliateID].node.child', this.tree[newParentAffiliateID].node.child);  //%//
        }

        // persistent storage
        wizeFiAffiliateTreeItem = await this.fetchWizeFiAffiliateTreeItem(newParentAffiliateID);
        if (!wizeFiAffiliateTreeItem.node.child.includes(affiliateID)) {
          // console.log('before: wizeFiAffiliateTreeItem.node.child', wizeFiAffiliateTreeItem.node.child);  //%//
          wizeFiAffiliateTreeItem.node.child.push(affiliateID);
          // console.log('after:  wizeFiAffiliateTreeItem.node.child', wizeFiAffiliateTreeItem.node.child);  //%//
          await this.storeWizeFiAffiliateTreeItem(wizeFiAffiliateTreeItem);
        }

        // handle log entry
        const userWizeFiID = this.dataModelService.dataModel.global.wizeFiID; // user who made change to tree
        const logDate = new Date().toISOString().substr(0, 19); // YYYY-MM-DDTHH:MM:SS  // date when change was made
        const undoData = {
          affiliateIDchangeParent: affiliateID, // affiliateID of node for which to change parent
          newParentAffiliateID, // parent changed to newParentAffiliateID
          oldParentAffiliateID // parent changed from oldParentAffiliateID
        };
        const manageTreeLogItem = {
          wizeFiID: userWizeFiID,
          actionDate: logDate,
          action: 'changeParentAffiliateID',
          undoData
        };
        console.log('LOG ', manageTreeLogItem); // %//
        await this.storeManageTreeLogItem(manageTreeLogItem);
        const manageTreeLogItems = await this.scanManageTreeLog(null); // %//
        console.log('manageTreeLogItems: ', manageTreeLogItems); // %//

        this.messages.push('parentAffiliateID has been changed'); // %//
      }
    }; // changeParentAffiliateID0

    this.loading.isLoading = true;

    changeParentAffiliateID0()
      .catch(err => {
        console.log('error in changeParentAffiliateID: ', err);
        this.messages.push('look in console for error message information');
      })
      .finally(() => {
        this.loading.isLoading = false;
      });
  } // changeParentAffiliateID

  /////////////////////////////////////////////////////////
  // routines to support Change AffiliateID
  /////////////////////////////////////////////////////////

  public changeAffiliateID(oldAffiliateID, newAffiliateID) {
    const changeAffiliateID0 = async () => {
      // make sure affiliate tree is in a valid state
      this.repairAffiliateTree('execute');

      // validate input
      oldAffiliateID = oldAffiliateID.trim();
      newAffiliateID = newAffiliateID.trim();
      this.messages.push('changeAffiliateID(oldAffiliateID,newAffiliateID)');
      this.messages.push('changeAffiliateID(' + oldAffiliateID + ',' + newAffiliateID + ')');
      let hadError = false;
      if (!this.isValidAffiliateID('oldAffiliateID', oldAffiliateID)) {
        hadError = true;
      }
      if (!this.isValidNewAffiliateID('newAffiliateID', newAffiliateID)) {
        hadError = true;
      }

      if (hadError) {
        this.messages.push('NOTE -- must resolve errors');
      } else {
        ////////////////////
        // code goes here //
        ////////////////////

        // manage log entry
        const userWizeFiID = this.dataModelService.dataModel.global.wizeFiID; // user who made change to tree
        const logDate = new Date().toISOString().substr(0, 19); // YYYY-MM-DDTHH:MM:SS  // date when change was made
        const undoData = {
          oldAffiliateID, // affiliateID changed from oldAffiliateID
          newAffiliateID // affiliateID changed to newAffiliateID
        };
        const manageTreeLogItem = {
          wizeFiID: userWizeFiID,
          actionDate: logDate,
          action: 'changeAffiliateID',
          undoData
        };
        console.log('LOG ', manageTreeLogItem); // %//
        await this.storeManageTreeLogItem(manageTreeLogItem);
        const manageTreeLogItems = await this.scanManageTreeLog(null); // %//
        console.log('manageTreeLogItems: ', manageTreeLogItems); // %//

        this.messages.push('changeAffiliateID has been completed');
      }
    }; // changeAffiliateID0

    this.loading.isLoading = true;

    changeAffiliateID0()
      .catch(err => {
        console.log('error in error in changeAffiliateID: ', err);
        this.messages.push('look in console for error message information');
      })
      .finally(() => {
        this.loading.isLoading = false;
      });
  } // changeAffiliateID

  /////////////////////////////////////////////////////////
  // routines to support Delete AffiliateID
  /////////////////////////////////////////////////////////

  public deleteAffiliateID(affiliateIDdelete) {
    const deleteAffiliateID0 = async () => {
      // make sure affiliate tree is in a valid state
      this.repairAffiliateTree('execute');

      // validate input
      affiliateIDdelete = affiliateIDdelete.trim();
      this.messages.push('deleteAffiliateID(affiliateIDdelete)');
      this.messages.push('deleteAffiliateID(' + affiliateIDdelete + ')');
      let hadError = false;
      if (!this.isValidAffiliateID('affiliateIDdelete', affiliateIDdelete)) {
        hadError = true;
      }

      if (hadError) {
        this.messages.push('NOTE -- must resolve errors');
      } else {
        ////////////////////
        // code goes here //
        ////////////////////

        // manage log entry
        const userWizeFiID = this.dataModelService.dataModel.global.wizeFiID; // user who made change to tree
        const logDate = new Date().toISOString().substr(0, 19); // YYYY-MM-DDTHH:MM:SS  // date when change was made
        const undoData = {
          affiliateID: affiliateIDdelete,
          affiliateAlias: this.tree[affiliateIDdelete].affiliateAlias,
          node: {
            fee: this.tree[affiliateIDdelete].node.fee,
            parent: this.tree[affiliateIDdelete].node.parent,
            child: this.tree[affiliateIDdelete].node.child,
            isActive: this.tree[affiliateIDdelete].node.isActive,
            isInTrialPeriod: this.tree[affiliateIDdelete].node.isInTrialPeriod
          },
          version: this.tree[affiliateIDdelete].version
        };
        const manageTreeLogItem = {
          wizeFiID: userWizeFiID,
          actionDate: logDate,
          action: 'deleteAffiliateID',
          undoData
        };
        console.log('LOG ', manageTreeLogItem); // %//
        await this.storeManageTreeLogItem(manageTreeLogItem);
        const manageTreeLogItems = await this.scanManageTreeLog(null); // %//
        console.log('manageTreeLogItems: ', manageTreeLogItems); // %//

        this.messages.push('deleteAffiliateID has been completed');
      }
    }; // deleteAffiliateID0

    this.loading.isLoading = true;

    deleteAffiliateID0()
      .catch(err => {
        console.log('error in error in deleteAffiliateID: ', err);
        this.messages.push('look in console for error message information');
      })
      .finally(() => {
        this.loading.isLoading = false;
      });
  } // deleteAffiliateID

  /////////////////////////////////////////////////////////
  // routines to support Display Affiliate Tree
  /////////////////////////////////////////////////////////

  public displayAffiliateTree(affiliateIDdisplay) {
    const displayAffiliateTree0 = async () => {
      // make sure affiliate tree is in a valid state
      this.repairAffiliateTree('execute');

      // initialize
      this.displayList = [];

      // validate input
      affiliateIDdisplay = affiliateIDdisplay.trim();
      this.messages.push('displayAffiliateTree(affiliateIDdisplay)');
      this.messages.push('displayAffiliateTree(' + affiliateIDdisplay + ')');
      let hadError = false;
      if (!this.isValidAffiliateID('affiliateIDdisplay', affiliateIDdisplay)) {
        hadError = true;
      }

      if (hadError) {
        this.messages.push('NOTE -- must resolve errors');
      } else {
        // construct data to be displayed
        this.affiliateID2WizeFiData = await this.getAffiliateID2WizeFiData();
        const treeList = [];
        const treeMap = {};
        if (this.selectedMode === 'location') {
          this.selectedFilter = 'all';
        } // must use entire tree for this mode of operation
        const subtree = this.selectedMode === 'subtree' ? affiliateIDdisplay : this.rootAffiliateID;
        this.pretrav(subtree, 0, treeList, treeMap);
        console.log('tree: ', this.tree); // %//
        console.log('treeList: ', treeList); // %//
        console.log('treeMap: ', treeMap); // %//
        this.displayListAttributes = [];
        if (treeList.length > 0) {
          for (const attribute of Object.keys(treeList[0])) {
            this.displayListAttributes.push(attribute);
          }
        }

        // build list of data to display
        if (this.selectedMode === 'location') {
          const ancestors = this.makeAncestorList(affiliateIDdisplay);
          const children = this.makeChildrenList(affiliateIDdisplay);

          // add ancestors to list
          for (const ancestor of ancestors) {
            this.displayList.push(treeMap[ancestor]);
          }

          // add selected affiliateID to list
          this.displayList.push(treeMap[affiliateIDdisplay]);

          // add children to list
          for (const child of children) {
            this.displayList.push(treeMap[child]);
          }
        } else {
          for (const item of treeList) {
            this.displayList.push(item);
          }
        }
        this.messages.push('tree has been displayed');
      }
    }; // displayAffiliateTree0

    displayAffiliateTree0().catch(err => {
      console.log('error in displayAffiliateTree: ', err);
    });
  } // displayAffiliateTree

  public getAffiliateID2WizeFiData() {
    return new Promise((resolve, reject) => {
      const getAffiliateID2WizeFiData0 = async () => {
        // initialize
        const affiliateID2WizeFiData = {};
        let scanResult; // list of items for a single scan operation (there is a limit on the number of items that a scan can retrieve)
        let params: any; // resolve typescript data type problem
        params = {}; // parameters to guide scan operation
        params.TableName = 'WizeFiData';

        // scan through all data items in DynamoDB WizeFiAffiliateTree table
        do {
          // process data for each item in the current scanResult
          scanResult = await this.dataModelService.dataModel.global.docClient.scan(params).promise();
          for (const item of scanResult.Items) {
            // add information to affiliateID2WizeFiData
            item.persistent = JSON.parse(item.persistent);
            affiliateID2WizeFiData[item.affiliateID] = {
              wizeFiID: item.wizeFiID,
              affiliateAlias: item.affiliateAlias,
              parentAffiliateID: item.persistent.header.parentAffiliateID,
              nameFirst: item.persistent.profile.nameFirst,
              nameLast: item.persistent.profile.nameLast,
              email: item.persistent.profile.email
            };
          }
          params.ExclusiveStartKey = scanResult.LastEvaluatedKey;
        } while (typeof scanResult.LastEvaluatedKey !== 'undefined');

        return affiliateID2WizeFiData;
      }; // getAffiliateID2WizeFiData0

      // process the data
      getAffiliateID2WizeFiData0()
        .then(affiliateID2WizeFiData => {
          resolve(affiliateID2WizeFiData);
        })
        .catch(err => {
          reject(err);
        });
    }); // return Promise
  } // getAffiliateID2WizeFiData

  public pretrav(p, level, treeList, treeMap) {
    const getNode = funcP => {
      if (!this.tree.hasOwnProperty(funcP)) {
        return null;
      } else {
        return this.tree[funcP].node;
      }
    }; // getNode

    const haveActiveChild = funcP => {
      let activeChild = false;
      // TODO consider searching through entire subtree for any active users
      for (const child of this.tree[funcP].node.child) {
        if (this.tree[child].node.isActive) {
          activeChild = true;
        }
      }
      return activeChild;
    }; // haveActiveChild

    // visit the current node
    const node = getNode(p);
    if (node === null) {
      // TODO figure out how to log this where the WizeFi support team will see it
      console.log('node (' + p + ') does not exist in the affiliate tree');
    } else {
      // perform the visit action
      let item;
      if (p === 'aaaaaaa' || !this.affiliateID2WizeFiData.hasOwnProperty(p)) {
        item = {
          level,
          affiliateID: p,
          affiliateAlias: '',
          wizeFiID: '',
          parentAffiliateID: '',
          nameFirst: '',
          nameLast: '',
          email: '',
          status: ''
        };
      } else {
        const status = node.isActive ? 'active' : 'inactive';
        item = {
          level,
          affiliateID: p,
          affiliateAlias: this.affiliateID2WizeFiData[p].affiliateAlias,
          wizeFiID: this.affiliateID2WizeFiData[p].wizeFiID,
          // parentAffiliateID: this.affiliateID2WizeFiData[p].parentAffiliateID,
          parentAffiliateID: node.parent,
          nameFirst: this.affiliateID2WizeFiData[p].nameFirst,
          nameLast: this.affiliateID2WizeFiData[p].nameLast,
          email: this.affiliateID2WizeFiData[p].email,
          status
        };
      }

      const wantData =
        this.selectedFilter === 'all' ||
        (this.selectedFilter === 'activeonly' && node.isActive === true) ||
        (this.selectedFilter === 'someinactive' && (node.isActive === true || haveActiveChild(p)));
      if (wantData) {
        treeList.push(item);
        treeMap[p] = item;
      }
    }

    // recursively visit each child of the current node
    for (const q of node.child) {
      this.pretrav(q, level + 1, treeList, treeMap);
    }
  } // pretrav

  public makeAncestorList(affiliateID) {
    const ancestors = [];
    let currentAffiliateID = affiliateID;
    while (currentAffiliateID !== this.rootAffiliateID) {
      // advance to the parent node of the currentAffiliateID
      currentAffiliateID = this.tree[currentAffiliateID].node.parent;

      // add new item to the beginning of the ancestors list
      ancestors.unshift(currentAffiliateID);
    }
    return ancestors;
  } // makeAncestorList

  public makeChildrenList(affiliateID) {
    const children = [];
    for (const child of this.tree[affiliateID].node.child) {
      children.push(child);
    }
    return children;
  } // makeChildrenList

  /////////////////////////////////////////////////////////
  // routines to support Repair Affiliate Tree
  /////////////////////////////////////////////////////////

  public repairAffiliateTree(repairMode) {
    const buildInvalidAffiliateIDList = () => {
      // initialize
      const invalidAffiliateIDList = [];

      // scan through every node in the tree
      for (const affiliateID of Object.keys(this.tree)) {
        // initialize
        const node = this.tree[affiliateID].node;
        let haveInvalidAffiliateID = false;
        const item = {
          affiliateID,
          parentAffiliateID: '',
          childAffiliateIDs: []
        };

        // handle invalid parentAffiliateID
        if (affiliateID !== this.rootAffiliateID) {
          if (!this.tree.hasOwnProperty(node.parent)) {
            haveInvalidAffiliateID = true;
            item.parentAffiliateID = node.parent;
          }
        }

        // handle each invalid childAffiliateID
        for (const childAffiliateID of this.tree[affiliateID].node.child) {
          if (!this.tree.hasOwnProperty(childAffiliateID)) {
            haveInvalidAffiliateID = true;
            item.childAffiliateIDs.push(childAffiliateID);
          }
        }

        // add item to list
        if (haveInvalidAffiliateID) {
          invalidAffiliateIDList.push(item);
        }
      }
      return invalidAffiliateIDList;
    }; // buildInvalidAffiliateIDList

    const resolveInvalidAffiliateID = async () => {
      // initialize
      let wizeFiID;
      let wizeFiDataItem: any;
      let wizeFiAffiliateTreeItem: any;
      const invalidAffiliateIDList = buildInvalidAffiliateIDList();

      // if have work to process, then proceed
      if (invalidAffiliateIDList.length > 0) {
        console.log('resolveInvalidAffiliateID'); // %//
        this.messages.push('resolveInvalidAffiliateID');

        // process each item in list
        for (const item of invalidAffiliateIDList) {
          // change invalid parentAffiliateID of node item.affiliateID
          if (item.parentAffiliateID !== '') {
            ////////////////////////////////////////////////////////////////////
            // change parent of this item.affiliateID node to orphanAffiliateID
            ////////////////////////////////////////////////////////////////////

            // memory storage
            // console.log('before: tree[item.affiliateID].node.parent ' + this.tree[item.affiliateID].node.parent);  //%//
            this.dataModelService.dataModel.persistent.header.parentAffiliateID = this.orphanAffiliateID;
            this.tree[item.affiliateID].node.parent = this.orphanAffiliateID;
            // console.log('after:  tree[item.affiliateID].node.parent ' + this.tree[item.affiliateID].node.parent);  //%//

            // persistent storage
            wizeFiID = await this.obtainWizeFiID(item.affiliateID);
            wizeFiDataItem = await this.fetchWizeFiDataItem(wizeFiID);
            wizeFiAffiliateTreeItem = await this.fetchWizeFiAffiliateTreeItem(item.affiliateID);
            // console.log('before: wizeFiAffiliateTreeItem.node.parent ' + wizeFiAffiliateTreeItem.node.parent);  //%//
            wizeFiDataItem.persistent.header.parentAffiliateID = this.orphanAffiliateID;
            wizeFiAffiliateTreeItem.node.parent = this.orphanAffiliateID;
            // console.log('after:  wizeFiAffiliateTreeItem.node.parent ' + wizeFiAffiliateTreeItem.node.parent);  //%//
            await this.storeWizeFiDataItem(wizeFiDataItem);
            await this.storeWizeFiAffiliateTreeItem(wizeFiAffiliateTreeItem);
            // this.messages.push('NOTE: resolveInvalidAffiliateID save data to persistent storage is turned off');  //%//

            ////////////////////////////////////////////////////////////////////
            // plant item.affiliateID as child under orphanAffiliateID
            ////////////////////////////////////////////////////////////////////

            // memory storage
            if (!this.tree[this.orphanAffiliateID].node.child.includes(item.affiliateID)) {
              // console.log('before: tree[orphanAffiliateID].node.child ', this.tree[this.orphanAffiliateID].node.child);  //%//
              this.tree[this.orphanAffiliateID].node.child.push(item.affiliateID);
              // console.log('after:  tree[orphanAffiliateID].node.child ', this.tree[this.orphanAffiliateID].node.child);  //%//
            }

            // persistent storage
            wizeFiAffiliateTreeItem = await this.fetchWizeFiAffiliateTreeItem(this.orphanAffiliateID);
            if (!wizeFiAffiliateTreeItem.node.child.includes(item.affiliateID)) {
              // console.log('before: wizeFiAffiliateTreeItem.node.child ', wizeFiAffiliateTreeItem.node.child);  //%//
              wizeFiAffiliateTreeItem.node.child.push(item.affiliateID);
              // console.log('after:  wizeFiAffiliateTreeItem.node.child ', wizeFiAffiliateTreeItem.node.child);  //%//
              await this.storeWizeFiAffiliateTreeItem(wizeFiAffiliateTreeItem);
              // this.messages.push('NOTE: resolveInvalidAffiliateID save data to persistent storage is turned off');  //%//
            }
          }

          // remove invalid child affiliateID values of node item.affiliateID
          if (item.childAffiliateIDs.length > 0) {
            ////////////////////////////////////////////////////////////////////
            // remove each invalid childAffiliateID from this node
            ////////////////////////////////////////////////////////////////////

            // memory storage
            // console.log('before: tree[item.affiliateID].node.child ', this.tree[item.affiliateID].node.child);
            for (const childAffiliateID of item.childAffiliateIDs) {
              const ndx = this.tree[item.affiliateID].node.child.indexOf(childAffiliateID);
              if (ndx !== -1) {
                this.tree[item.affiliateID].node.child.splice(ndx, 1);
              }
            }
            // console.log('after:  tree[item.affiliateID].node.child ', this.tree[item.affiliateID].node.child);

            // persistent storage
            wizeFiAffiliateTreeItem = await this.fetchWizeFiAffiliateTreeItem(item.affiliateID);
            // console.log('before: wizeFiAffiliateTreeItem.node.child ', wizeFiAffiliateTreeItem.node.child);  //%//
            for (const childAffiliateID of item.childAffiliateIDs) {
              const ndx = wizeFiAffiliateTreeItem.node.child.indexOf(childAffiliateID);
              if (ndx !== -1) {
                wizeFiAffiliateTreeItem.node.child.splice(ndx, 1);
              }
            }
            // console.log('after:  wizeFiAffiliateTreeItem.node.child ', wizeFiAffiliateTreeItem.node.child);  //%//
            await this.storeWizeFiAffiliateTreeItem(wizeFiAffiliateTreeItem);
            // this.messages.push('NOTE: resolveInvalidAffiliateID save data to persistent storage is turned off');  //%//
          }
        } // for
      } // if have work to process
    }; // resolveInvalidAffiliateID

    const getChildParentMap = () => {
      const childParentMap = {};

      // scan through every node in the tree
      for (const affiliateID of Object.keys(this.tree)) {
        for (const child of this.tree[affiliateID].node.child) {
          if (!childParentMap.hasOwnProperty(child)) {
            childParentMap[child] = { childAffiliateID: child, parentAffiliateID: this.tree[child].node.parent, parentAffiliateIDList: [] };
          }
          childParentMap[child].parentAffiliateIDList.push(affiliateID);
        }
      }
      return childParentMap;
    }; // getChildParentMap

    const buildDuplicateChildList = () => {
      const duplicateChildList = [];
      const childParentMap = getChildParentMap();
      for (const affiliateID of Object.keys(childParentMap)) {
        if (childParentMap[affiliateID].parentAffiliateIDList.length > 1) {
          duplicateChildList.push(childParentMap[affiliateID]);
        }
      }
      return duplicateChildList;
    }; // buildDuplicateChildList

    const removeDuplicateChildren = async () => {
      let wizeFiAffiliateTreeItem: any;
      const duplicateChildList = buildDuplicateChildList();

      // if have work to process, then proceed
      if (duplicateChildList.length > 0) {
        console.log('removeDuplicateChildren'); // %//
        this.messages.push('removeDuplicateChildren');

        for (const duplicateChild of duplicateChildList) {
          // initialize
          let ndx;
          const childAffiliateID = duplicateChild.childAffiliateID;
          const parentAffiliateID = duplicateChild.parentAffiliateID;
          const parentAffiliateIDList = duplicateChild.parentAffiliateIDList;

          // remove parentAffiliateID from parentAffiliateIDList (leaving behind parents with duplicate child to be removed)
          ndx = parentAffiliateIDList.indexOf(parentAffiliateID);
          if (ndx !== -1) {
            parentAffiliateIDList.splice(ndx, 1);
          }

          // process each item in parentAffiliateIDList
          for (const otherParentAffiliateID of parentAffiliateIDList) {
            ////////////////////////////////////////////////////////////////////
            // remove childAffiliateID from node that contains it as a child
            ////////////////////////////////////////////////////////////////////

            // memory storage
            // console.log('before: tree[' + otherParentAffiliateID + '].node.child: ', this.tree[otherParentAffiliateID].node.child);  //%//
            ndx = this.tree[otherParentAffiliateID].node.child.indexOf(childAffiliateID);
            if (ndx !== -1) {
              this.tree[otherParentAffiliateID].node.child.splice(ndx, 1);
            }
            // console.log('after:  tree[' + otherParentAffiliateID + '].node.child: ', this.tree[otherParentAffiliateID].node.child);  //%//

            // persistent storage
            wizeFiAffiliateTreeItem = await this.fetchWizeFiAffiliateTreeItem(otherParentAffiliateID);
            // console.log('before: wizeFiAffiliateTreeItem.node.child: ', wizeFiAffiliateTreeItem.node.child);  //%//
            ndx = wizeFiAffiliateTreeItem.node.child.indexOf(childAffiliateID);
            if (ndx !== -1) {
              wizeFiAffiliateTreeItem.node.child.splice(ndx, 1);
            }
            // console.log('after:  wizeFiAffiliateTreeItem.node.child: ', wizeFiAffiliateTreeItem.node.child);  //%//
            await this.storeWizeFiAffiliateTreeItem(wizeFiAffiliateTreeItem);
            // this.messages.push('NOTE: removeDuplicateChildren save data to persistent storage is turned off');  //%//
          }
        }
      }
    }; // removeDuplicateChildren

    const buildNonChildList = () => {
      const nonChildList = [];

      // scan through every node in the tree
      const affiliateIDList = Object.keys(this.tree);
      for (const affiliateID of affiliateIDList) {
        if (affiliateID !== this.rootAffiliateID) {
          // search through the tree to see if this affiliateID appears in a child role
          let isChild = false;
          let i = affiliateIDList.length;
          while (--i >= 0 && !isChild) {
            const checkAffiliateID = affiliateIDList[i];
            if (this.tree[checkAffiliateID].node.child.includes(affiliateID)) {
              isChild = true;
            }
          }
          if (!isChild) {
            nonChildList.push(affiliateID);
          }
        }
      }
      return nonChildList;
    }; // buildNonChildList

    const resolveNonChildIssue = async () => {
      // initialize
      let wizeFiAffiliateTreeItem: any;
      const nonChildList = buildNonChildList();

      // if have work to process, then proceed
      if (nonChildList.length > 0) {
        console.log('resolveNonChildIssue'); // %//
        this.messages.push('resolveNonChildIssue');

        for (const childAffiliateID of nonChildList) {
          ///////////////////////////////////////////////////////////////////////////////////
          // set parentAffiliateID of this childAffiliateID node to be orphanAffiliateID
          ///////////////////////////////////////////////////////////////////////////////////

          // memory storage
          // console.log('before: tree[childAffiliateID].node.parent = ' + this.tree[childAffiliateID].node.parent);  //%//
          this.tree[childAffiliateID].node.parent = this.orphanAffiliateID;
          // console.log('after:  tree[childAffiliateID].node.parent = ' + this.tree[childAffiliateID].node.parent);  //%//

          // persistent storage
          wizeFiAffiliateTreeItem = await this.fetchWizeFiAffiliateTreeItem(childAffiliateID);
          // console.log('before: wizeFiAffiliateTreeItem.node.parent = ' + wizeFiAffiliateTreeItem.node.parent);  //%//
          wizeFiAffiliateTreeItem.node.parent = this.orphanAffiliateID;
          // console.log('after:  wizeFiAffiliateTreeItem.node.parent = ' + wizeFiAffiliateTreeItem.node.parent);  //%//
          await this.storeWizeFiAffiliateTreeItem(wizeFiAffiliateTreeItem);
          // this.messages.push('NOTE: resolveNonChildIssue save data to persistent storage is turned off');  //%//

          ////////////////////////////////////////////////////////////////////////////
          // add this childAffiliateID as a child in the orphanAffiliateID node
          ////////////////////////////////////////////////////////////////////////////

          // memory storage
          if (!this.tree[this.orphanAffiliateID].node.child.includes(childAffiliateID)) {
            // console.log('before: tree[this.orphanAffiliateID].node.child = ', this.tree[this.orphanAffiliateID].node.child);   //%//
            this.tree[this.orphanAffiliateID].node.child.push(childAffiliateID);
            // console.log('after:  tree[this.orphanAffiliateID].node.child = ', this.tree[this.orphanAffiliateID].node.child);   //%//
          }

          // persistent storage
          wizeFiAffiliateTreeItem = await this.fetchWizeFiAffiliateTreeItem(this.orphanAffiliateID);
          if (!wizeFiAffiliateTreeItem.node.child.includes(childAffiliateID)) {
            // console.log('before: wizeFiAffiliateTreeItem.node.child = ', wizeFiAffiliateTreeItem.node.child);   //%//
            wizeFiAffiliateTreeItem.node.child.push(childAffiliateID);
            // console.log('after:  wizeFiAffiliateTreeItem.node.child = ', wizeFiAffiliateTreeItem.node.child);   //%//
            await this.storeWizeFiAffiliateTreeItem(wizeFiAffiliateTreeItem);
            // this.messages.push('NOTE: resolveNonChildIssue save data to persistent storage is turned off');  //%//
          }
        }
      } // if have data to process
    }; // resolveNonChildIssue

    const repairAffiliateTree0 = async () => {
      console.log('Repair Affiliate Tree');
      this.messages.push('Repair Affiliate Tree');

      if (repairMode === 'preview') {
        const invalidAffiliateIDList = buildInvalidAffiliateIDList();
        console.log('invalidAffiliateIDList: ', invalidAffiliateIDList);

        // must repair the memory resident affiliate tree before doing the steps below
        if (invalidAffiliateIDList.length > 0) {
          resolveInvalidAffiliateID();
        }

        const duplicateChildList = buildDuplicateChildList();
        console.log('duplicateChildList: ', duplicateChildList);

        const nonChildList = buildNonChildList();
        console.log('nonChildList: ', nonChildList);

        this.messages.push('trial run has been completed');
      } else {
        await resolveInvalidAffiliateID(); // note: this action must be done first (to avoid problems with the actions below)
        await removeDuplicateChildren();
        await resolveNonChildIssue();

        this.messages.push('affiliate tree has been repaired');
      }
    }; // repairAffiliateTree0

    repairAffiliateTree0().catch(err => {
      console.log('error in repairAffiliateTree: ', err);
      this.messages.push('look in console for error message information');
    });
  } // repairAffiliateTree

  /////////////////////////////////////////////////////////
  // routines to support Undo Action
  /////////////////////////////////////////////////////////

  public undoAction(manageTreeLogItem) {
    console.log('Undo Action'); // %//
    console.log('manageTreeLogItem: ', manageTreeLogItem); // %//
    const undoData = manageTreeLogItem.undoData;
    switch (manageTreeLogItem.action) {
      case 'changeParentAffiliateID':
        this.changeParentAffiliateID(undoData.affiliateIDchangeParent, undoData.oldParentAffiliateID);
        this.messages.push('undo of changeParentAffiliateID has been completed');
        break;
      case 'changeAffiliateAlias':
        this.changeAffiliateAlias(undoData.affiliateIDchangeAlias, undoData.oldAffiliateAlias);
        this.messages.push('undo of changeAffiliateAlias has been completed');
        break;
      /*
            case "changeAffiliateID":
                this.messages.push("undo of changeAffiliateID has been completed");
                break;
            case "deleteAffiliateID":
                this.messages.push("undo of deleteAffiliateID has been completed");
                break;
            */
    } // switch
  } // undoAction

  public wantUndoActionChanged(event) {
    const wantUndoActionChanged0 = async () => {
      if (event.target.checked) {
        this.manageTreeLogItems = await this.scanManageTreeLog(null);
        const wizeFiIDMap = {};
        for (const manageTreeLogItem of this.manageTreeLogItems) {
          wizeFiIDMap[manageTreeLogItem.wizeFiID] = 0;
        }
        this.wizeFiIDList = Object.keys(wizeFiIDMap);
      }
    }; // wantUndoActionChanged0

    this.loading.isLoading = true;

    wantUndoActionChanged0()
      .catch(err => {})
      .finally(() => {
        this.loading.isLoading = false;
      });
  } // wantUndoActionChanged

  public loadManageTreeLogItems() {
    const loadManageTreeLogItems0 = async () => {
      this.manageTreeLogItems = await this.scanManageTreeLog(null);
    }; // loadManageTreeLogItems0

    this.loading.isLoading = true;

    loadManageTreeLogItems0()
      .catch(err => {
        console.log('error in loadManageTreeLogItems: ', err);
      })
      .finally(() => {
        this.loading.isLoading = false;
      });
  } // loadManageTreeLogItems

  public filterManageTreeLogItems() {
    this.filteredManageTreeLogItems = [];
    this.selectedManageTreeLogItem = 'select undo information to use';

    // check validity of logDateRange
    let startDate, endDate;
    let haveValidLogDateRange = true;
    this.logDateRange = this.logDateRange.trim();
    if (this.logDateRange !== '') {
      const matches = this.logDateRange.match(/^([\dT\-:]*)_([\dT\-:]*)$/);
      if (matches === null) {
        haveValidLogDateRange = false;
        console.log('logDateRange: ' + this.logDateRange + ' is not valid'); // %//
        this.messages.push('logDateRange: ' + this.logDateRange + ' is not valid');
      } else {
        startDate = matches[1];
        endDate = matches[2];
      }
    }

    if (haveValidLogDateRange) {
      // process each manageTreeLogItem
      for (const manageTreeLogItem of this.manageTreeLogItems) {
        let haveMatch = true;

        // selected user
        if (this.selectedLogUser !== this.promptLogUser && manageTreeLogItem.wizeFiID !== this.selectedLogUser) {
          haveMatch = false;
        }

        // selected action
        if (this.selectedLogAction !== this.promptLogAction && manageTreeLogItem.action !== this.selectedLogAction) {
          haveMatch = false;
        }

        // specified logDateRange
        if (this.logDateRange !== '') {
          const actionDate = manageTreeLogItem.actionDate;
          if (startDate !== '' && actionDate < startDate) {
            haveMatch = false;
          }
          if (endDate !== '' && actionDate >= endDate) {
            haveMatch = false;
          }
        }

        if (haveMatch) {
          this.filteredManageTreeLogItems.push(manageTreeLogItem);
        }
      }
    }
    this.filteredManageTreeLogItems.sort((a, b) => b.actionDate.localeCompare(a.actionDate)); // sort in descending order of actionDate
  } // filterManageTreeLogItems

  public displayManageTreeLogItem(manageTreeLogItem) {
    let result = manageTreeLogItem.actionDate + ' ' + manageTreeLogItem.wizeFiID + ' ' + manageTreeLogItem.action + ' ';
    switch (manageTreeLogItem.action) {
      case 'changeParentAffiliateID':
        result +=
          'affiliateIDchangeParent:' +
          manageTreeLogItem.undoData.affiliateIDchangeParent +
          ' ' +
          'newParentAffiliateID:' +
          manageTreeLogItem.undoData.newParentAffiliateID +
          ' ' +
          'oldParentAffiliateID:' +
          manageTreeLogItem.undoData.oldParentAffiliateID;
        break;
      case 'changeAffiliateAlias':
        result +=
          'affiliateIDchangeAlias:' +
          manageTreeLogItem.undoData.affiliateIDchangeAlias +
          ' ' +
          'newAffiliateAlias:' +
          manageTreeLogItem.undoData.newAffiliateAlias +
          ' ' +
          'oldAffiliateAlias:' +
          manageTreeLogItem.undoData.oldAffiliateAlias;
        break;
      case 'changeAffiliateID':
        result += 'oldAffiliateID:' + manageTreeLogItem.undoData.oldAffiliateID + ' ' + 'newAffiliateID:' + manageTreeLogItem.undoData.newAffiliateID;
        break;
      case 'deleteAffiliateID':
        result += 'affiliateID:' + manageTreeLogItem.undoData.affiliateID;
        break;
    } // switch
    return JSON.stringify(result);
  } // displayManageTreeLogItem
} // class AdminManageTreeComponent
