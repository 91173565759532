import { Component, Input, OnInit } from '@angular/core';
import { IncomeScreenComponent } from '../income-screen.component';

@Component({
  selector: 'app-income',
  templateUrl: './income.component.html',
  styleUrls: ['./income.component.scss']
})
export class IncomeComponent extends IncomeScreenComponent implements OnInit {
  @Input() public showOtherDeductions: boolean;
}
