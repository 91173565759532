import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataModelService } from '../../../services/data-model/data-model.service';

@Component({
  selector: 'app-retirement-goal',
  templateUrl: './retirement-goal.component.html',
  styleUrls: ['./retirement-goal.component.scss']
})
export class RetirementGoalComponent implements OnInit {
  public retirementGoal = this.dataModelService.dataModel.persistent.profile.financialFreedomTargetAmount;
  public isLoading = false;
  public isUpdating = false;

  constructor(protected dataModelService: DataModelService, protected router: Router) {}

  public ngOnInit() {}

  public onGoalChange(value) {
    this.retirementGoal = parseInt(value, 10);
  }

  public onNext() {
    this.isUpdating = true;
    this.dataModelService.dataModel.persistent.profile.financialFreedomTargetAmount = this.retirementGoal;
    this.dataModelService.dataManagement
      .storeinfo()
      .then(() => {
        this.router.navigate(['/retirement-income']);
      })
      .catch(err => console.error('Error updating asset goal: ', err))
      .finally(() => (this.isUpdating = false));
  }
}
