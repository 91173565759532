import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataModelService } from '../../services/data-model/data-model.service';

declare const FB: any;
declare const AWS: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  // transient data
  public isCollapsed = true;
  public loading = false;
  public fetching = false;
  public AID: string;
  public alias: string;
  public fee: number;
  public referral: string;
  public referralAID: string;
  public screen = 'login';
  public body = document.getElementsByTagName('body')[0];
  public email: string;
  public password: string;
  public username: string;
  public loadingText = 'Loading...';
  constructor(protected router: Router, protected dataModelService: DataModelService, protected route: ActivatedRoute) {}

  public ngOnInit() {
    this.loading = false;

    document.getElementById('sidebar').style.display = 'none';
    document.getElementById('page-content').style.backgroundColor = 'rgb(47, 47, 47)';
    document.getElementById('page-content').style.boxShadow = 'none';
    document.getElementById('page-content').style.border = '0';
    this.loading = false;

    // Remove all body classes before adding new ones.
    while (this.body.classList.length > 0) {
      this.body.classList.remove(this.body.classList.item(0));
    }
    this.body.classList.add('login');
  }

  public canRegister() {
    this.router.navigate(['/register']);
  }

  public canLogin() {
    this.router.navigate(['/login']);
  }

  public signupHandler(event: any) {
    this.screen = 'register';
    this.canRegister();
  }

  public loginHandler(event: any) {
    this.screen = 'login';
    this.canLogin();
  }

  public login = (): void => {
    this.loading = true;

    this.dataModelService.loginManagement
      .doCognitoLogin(this.email, this.password)
      .then(logins => {
        this.dataModelService.dataModel.global.isFBLogin = false;
        window.sessionStorage.setItem('isFBLogin', '0');
        return this.dataModelService.loginManagement.establishAWSobjects(logins);
      })
      .then(() => this.dataModelService.dataManagement.fetchinfo())
      .then(() => this.dataModelService.draftService.loadDraftsInfo())
      .then(() => this.dataModelService.getBraintreeData())
      .then(() => this.dataModelService.plaidManagement.getPlaidData())
      .then(() => this.dataModelService.initializeAffiliateManagement())
      .then(() => this.dataModelService.ancillaryDataManagement.loadAncillaryData())
      .then(() => this.dataModelService.suggestionManagement.loadSuggestionData())
      .then(() => this.dataModelService.loginManagement.checkReferral())
      .then(() => this.dataModelService.loginManagement.setAffilliate()) // get the affiliate ID from cognito
      .then(() => this.dataModelService.dataManagement.storeinfo())
      .then(() => this.dataModelService.loginManagement.wrapup())
      .then(() => this.pushEmailToLocalStorage())
      .then(() => this.dataModelService.loginManagement.redirect())
      .catch(err => {
        console.error(err);
        this.loading = false;
        this.dataModelService.showErrorMessage(err, 2000);
      });
  };

  private pushEmailToLocalStorage() {
    window.localStorage.setItem('email', this.email);
  }

  public forgotPassword = (): void => {
    this.loading = true;
    this.screen = 'forgot_pass';
    this.dataModelService.loginManagement
      .requestPasswordChangeVerificationCode(this.email)
      .then(() => this.dataModelService.loginManagement.resetPasswordRedirect(this.email))
      .catch(err => {
        this.dataModelService.loginManagement.handleError(err);
        this.loading = false;
      });
  };
}
