import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataModelService } from '../../../services/data-model/data-model.service';

@Component({
  selector: 'app-net-income',
  templateUrl: './net-income.component.html',
  styleUrls: ['./net-income.component.scss']
})
export class NetIncomeComponent implements OnInit {
  @Input() public grossMonthlyIncome = 0;
  @Input() public deductions = 0;
  public isLoading = true;

  constructor(protected dataModelService: DataModelService, protected router: Router) {}

  public ngOnInit() {
    this.isLoading = false;
  }

  public onSave() {
    const income = this.dataModelService.dataModel.persistent.plans.original.income.income;
    income.accounts[0].monthlyAmount.val = this.grossMonthlyIncome - this.deductions;
  }
}
