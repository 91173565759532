import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-go',
  templateUrl: './go.component.html',
  styleUrls: ['./go.component.css']
})
export class GoComponent implements OnInit {
  public referral: string = null;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  public ngOnInit() {
    // check for affiliate id
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      // console.log('queryParams',params);
      if (params.a) {
        this.referral = params.a;
      }
    });
    // check for affiliate alias
    if (!this.referral) {
      this.activatedRoute.params.subscribe((params: Params) => {
        // console.log('params',params);
        if (params.id) {
          this.referral = params.id;
        }
      });
    }
    let url = '/register';
    if (this.referral) {
      window.localStorage.setItem('a', this.referral);
      url += '?a=' + this.referral;
    }
    window.location.href = url;
  }
}
