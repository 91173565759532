import { Component, OnInit } from '@angular/core';
import { AccountTypeService } from 'src/app/services/account-type/account-type.service';
import { AccountService } from 'src/app/services/account/account.service';
import { DataModelService } from 'src/app/services/data-model/data-model.service';
import { AccountDetailsComponent } from '../account-details/account-details.component';

@Component({
  selector: 'app-investment-property-account-details',
  templateUrl: './investment-property-account-details.component.html',
  styleUrls: ['../account-details-template/account-details-template.component.scss']
})
export class InvestmentPropertyAccountDetailsComponent extends AccountDetailsComponent {
  protected bal: number;

  constructor(public dataModelService: DataModelService, accountTypeService: AccountTypeService, accountService: AccountService) {
    super(dataModelService, accountTypeService, accountService);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.bal = (this.wizeFiAccount && this.wizeFiAccount.accountValue && this.wizeFiAccount.accountValue.val) || this.plaidAccount.balance || 0;
    this.totalOwed = {
      label: 'Total Owed',
      isRequired: true,
      val: 0
    };
  }

  protected onGrowthRateChange(value: string) {
    this.wizeFiAccount.interestRate.val = parseInt(value, 10) || 0;
  }

  protected onEstimatedValueChange(value: string) {
    this.wizeFiAccount.accountValue.val = parseInt(value, 10) || 0;
    this.plaidAccount.balance = parseFloat(value) || 0;
  }

  protected onTotalOwedChanged(value: string) {
    // @todo add this field to the data-model; for now it is local to class
    if (this.totalOwed) {
      this.totalOwed.val = parseInt(value, 10) || 0;
    }
  }

  protected onNetIncomeChanged(value: string) {
    const newValue = value.replace(/\,/g, '');
    console.log(newValue);
    this.wizeFiAccount.monthlyIncome.val = parseInt(newValue, 10) || 0;
  }

  protected getNetAssetValue(): number {
    if (this.wizeFiAccount.accountValue && this.totalOwed) {
      return this.wizeFiAccount.accountValue.val - this.totalOwed.val || 0;
    } else {
      return 0;
    }
  }
}
