import { Component, ViewChild, OnInit } from '@angular/core';
import { MatDialog, MatButtonToggleModule, MatMenuModule } from '@angular/material';
import { Router } from '@angular/router';
import { UrlManagement } from '../../utilities/url-management.class';
import { NetWorthProjectionComponent } from '../setup-flow/net-worth-projection/net-worth-projection.component';
import { MonthPlanSelectorComponent } from '../transactions/month-plan-selector/month-plan-selector.component';
import { DataModelService } from './../../services/data-model/data-model.service';
import { DialogDisplayDraftsComponent } from '../dialog-display-drafts/dialog-display-drafts.component';

@Component({
  selector: 'app-budget-page',
  templateUrl: './budget-page.component.html',
  styleUrls: ['./budget-page.component.scss']
})
export class BudgetPageComponent extends NetWorthProjectionComponent implements OnInit {
  @ViewChild('planButtonToggleGroup', { static: false }) public planButtonToggleGroup: MatButtonToggleModule;
  @ViewChild('#planMenu', { static: false }) public planMenu: MatMenuModule;
  protected url = new UrlManagement();
  public congratsModal = false;
  public financialFreedomData: any;
  public financialFreedomDate: any;
  public financialFreedomAge: any;
  public dateOfBirth: any = this.dataModelService.dataModel.persistent.profile.birthDate;
  public ageDifference: any;
  public selectedMonth = new Date();

  //public plan;
  public previousPlan;
  public planDates;
  public wizeFiCategory2MonthlyAmount;
  dateRange: any;

  constructor(public router: Router, protected dataModelService: DataModelService, private dialog: MatDialog) {
    super(dataModelService);
    this.selectedMonth = new Date(this.dataModelService.dataModel.persistent.header.curplanYearMonth + '-02');
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;
    this.planDates = this.dataModelService.categoryManagement.obtainPlanDatesInfo();
    this.previousPlan = this.dataModelService.categoryManagement.getPreviousPlan(curplan, this.planDates);
  }

  public ngOnInit() {
    this.initializeData();
  }

  protected async initializeData() {
    const wizeFiPlaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    this.wizeFiCategory2MonthlyAmount = this.dataModelService.categoryManagement.getWizeFiCategory2monthlyAmount(this.previousPlan);
    const activeWizeFiPlaidAccountIds: any = this.dataModelService.plaidManagement.setActiveWizeFiPlaidAccountIds(wizeFiPlaidAccounts);
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    this.dateRange = {};
    this.dateRange.wantMonthRange = true;
    this.dateRange.yearMonth = new Date().toISOString().substr(0, 7);
    const title = this.dataModelService.dataManagement.getDraftTitle();
    await this.dataModelService.plaidManagement.getTransactions(wizeFiID, title, this.dateRange, activeWizeFiPlaidAccountIds);
    await this.setupPrerequisiteData();
    await this.initWizeFiProjectionData();
    await this.initCurrentProjectionData();
    await this.initOriginalProjectionData();
    await this.truncateDebtData();
    await this.calculateNetWorthAges();
    await this.calculateDebtAges();
    this.ageDifference = this.originalFFAge !== 999 ? this.originalFFAge - this.currentFFAge : 100 - this.currentFFAge;
    if (this.dataModelService.cafrManagement.dataBeforeEditing) {
      this.ageDifference =
        this.dataModelService.cafrManagement.dataBeforeEditing.netWorth.age !== 999
          ? this.dataModelService.cafrManagement.dataBeforeEditing.netWorth.age - this.currentFFAge
          : 100 - this.currentFFAge;
    }

    if (
      document.getElementById('web-body') &&
      document.getElementById('web-body').classList &&
      document.getElementById('web-body').classList.contains('login')
    ) {
      document.getElementById('web-body').classList.remove('login');
    }
    if (history.state.showModal === true) {
      const actualDataProjInfo = this.dataModelService.cafrManagement.getCafrDataProjectionInfo(
        {
          terminationType: 'financialFreedomTargetAmount',
          terminationValue: this.dataModelService.dataModel.persistent.profile.financialFreedomTargetAmount
        },
        'actual'
      );
      // net worth
      this.financialFreedomData = actualDataProjInfo.netWorth.summary.projection;
      this.financialFreedomDate = this.financialFreedomData.slice(-1)[0].x;
      this.financialFreedomAge = this.dateDiff(this.dataModelService.dataModel.persistent.profile.birthDate, this.financialFreedomDate);
      this.congratsModal = true;
    }
  }

  public closeCongratsModal() {
    this.congratsModal = false;
  }

  public openMonthPicker() {
    this.dialog
      .open<MonthPlanSelectorComponent, Date, Date | undefined>(MonthPlanSelectorComponent, {
        data: this.selectedMonth,
        position: { top: '100px', left: '300px' }
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.selectedMonth = result;
          if (this.router.url.includes('#')) {
            const hash = this.router.url.split('#')[1];
            this.url.switchHash('temp');
            this.url.switchHash(hash);
          } else {
            this.url.switchHash('temp');
            this.url.switchHash('expenses');
          }
        }
      });
  }

  openEditDrafts(screenInEditor: string): void {
    this.dialog.open(DialogDisplayDraftsComponent, { data: screenInEditor });
  }
}
