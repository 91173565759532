import { Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { categoryInfo } from '../../../services/data-model/data-model_0001.data';
import { BudgetCategoryComponent } from '../budget-category/budget-category.component';

@Component({
  selector: 'app-reallocate-spending-category',
  templateUrl: './reallocate-spending-category.component.html',
  styleUrls: ['./reallocate-spending-category.component.scss']
})
export class ReallocateSpendingCategoryComponent extends BudgetCategoryComponent {
  @Output() public onValueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onDoneLoading: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Input() public totalIncome = 0;
  @Input() public showGuideline: boolean;
  @Input() public wizeFiCategory2MonthlyAmount: any;
  public guidelineSum = 0;
  public planSum = 0;
  public lastMonthSum = 0;

  constructor(protected dataModelService: DataModelService, protected router: Router, protected renderer: Renderer2) {
    super(dataModelService, router, renderer);
  }

  public ngOnInit() {
    super.ngOnInit();
    //console.log(this.wizeFiCategory2MonthlyAmount);
    this.setTotalIncome();
    this.setGuidelineSum();
    //this.setLastMonthSum();
    this.setPlanSum();
    this.onDoneLoading.emit({
      guidelineSum: this.guidelineSum,
      planSum: this.planSum,
      lastMonthSum: this.lastMonthSum
    });
  }

  public onTextfieldChange(value, account, type) {
    if (type === 'plan') {
      account.monthlyAmount.val = parseInt(value, 10) || 0;
    } else {
      account.actualMonthlyAmount.val = parseInt(value, 10) || 0;
    }
    this.setPlanSum();
    //this.setLastMonthSum();
    this.onValueChange.emit();
  }

  protected deleteAccount(event, account) {
    event.stopPropagation();
    if (this.isDeletingAccount) {
      return;
    }

    setTimeout(() => {
      if (window.confirm('Are you sure you want to delete this subcategory?')) {
        const categoryManagement = this.dataModelService.categoryManagement;
        const wizeFiCategory = categoryManagement.makeWizeFiCategory(this.categoryGroup, this.categoryKey, account.accountName.val);
        categoryManagement.deleteWizeFiAccount(wizeFiCategory);
        this.setPlanSum();
        this.setTotalIncome();
        this.setGuidelineSum();
        this.onUpdate.emit({
          guidelineSum: this.guidelineSum,
          planSum: this.planSum,
          lastMonthSum: this.lastMonthSum
        });
        this.categorySum = this.getCategorySum();
        this.remainingSum = this.getRemainingSum();
        this.onSubcategoryDelete.emit();
        this.isDeletingAccount = false;
      }
    }, 1000);

    this.isDeletingAccount = true;
  }

  private setGuidelineSum() {
    const guidelinePercentage = categoryInfo.budget[this.categoryKey].guidelinePercentage;
    this.guidelineSum = (guidelinePercentage / 100) * this.totalIncome;
  }

  private setLastMonthSum() {
    const budgetAccountsTotal = this.category.accounts.reduce((total, account) => (total += this.getLastMonthsMonthlyAmount(account)), 0);
    const shadowAccountsTotal = this.budgetShadowAccounts.reduce(
      (total, account) => (this.belongsToCurrentCategory(account) ? (total += this.getLastMonthsMonthlyAmount(account)) : total),
      0
    );
    this.lastMonthSum = budgetAccountsTotal + shadowAccountsTotal;
  }

  protected lastMonthsCategoryTotal() {
    const budgetAccountsTotal = this.category.accounts.reduce((total, account) => {
      const amount = this.getLastMonthsMonthlyAmount(account);
      if (typeof amount === 'string') {
        return (total += parseFloat(amount));
      } else {
        return (total += amount);
      }
    }, 0);
    const shadowAccountsTotal = this.budgetShadowAccounts.reduce((total, account) => {
      if (this.belongsToCurrentCategory(account)) {
        const amount = this.getLastMonthsMonthlyAmount(account);
        if (typeof amount === 'string') {
          return (total += parseFloat(amount));
        } else {
          return (total += amount);
        }
      } else {
        return total;
      }
    }, 0);
    this.lastMonthSum = budgetAccountsTotal + shadowAccountsTotal;
    return budgetAccountsTotal + shadowAccountsTotal;
  }

  protected setPlanSum() {
    const budgetAccountsTotal = this.category.accounts.reduce((total, account) => (total += parseFloat(account.monthlyAmount.val)), 0);
    const shadowAccountsTotal = this.budgetShadowAccounts.reduce(
      (total, account) => (this.belongsToCurrentCategory(account) ? total + this.getActualMonthlyAmount(account) : total),
      0
    );

    this.planSum = budgetAccountsTotal + shadowAccountsTotal;
  }

  private setTotalIncome() {
    const income = this.dataModelService.getdata('income');
    const totalSum = this.getMonthlyAmountTotals(income);
    this.totalIncome = totalSum;
  }

  private getMonthlyAmountTotals(category: any): number {
    const subcatsWithAccounts = _.values(category).filter(obj => obj.accounts !== undefined);
    const allExpenseAccounts = _.flatten(subcatsWithAccounts.map(obj => obj.accounts));
    const totalSum = allExpenseAccounts.reduce((totalIncome, account) => (totalIncome += account ? account.monthlyAmount.val : 0), 0);
    return totalSum;
  }

  protected getLastMonthsMonthlyAmount(account) {
    const category = account.wizeFiCategory.val;
    let value = 0;
    for (const cat in this.wizeFiCategory2MonthlyAmount) {
      if (cat === category) {
        value = this.dataModelService.categoryManagement.getMonthlyAccount(this.wizeFiCategory2MonthlyAmount, category);
      }
    }
    return value;
  }

  // @TODO: fix issue where actual monthly amounts are not being copied over to shadow accounts.
  // This workaround gets the wizefi account in the plan and returns THAT actualMonthlyAmount
  protected getLastMonthActualMonthlyAmount(shadowAccount) {
    const wizeFiCategory = shadowAccount.wizeFiCategory.val;
    return this.dataModelService.categoryManagement.getActualMonthlyAccount(this.wizeFiCategory2MonthlyAmount, wizeFiCategory);
  }

  protected getActualMonthlyAmount(account) {
    return this.belongsToCurrentCategory(account) ? account.monthlyMinimum.val : 0;
  }
}
