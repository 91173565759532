import { Component, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { ReallocateSpendingCategoryComponent } from '../reallocate-spending-category/reallocate-spending-category.component';

@Component({
  selector: 'app-current-expenses-category',
  templateUrl: './current-expenses-category.component.html',
  styleUrls: ['./current-expenses-category.component.scss']
})
export class CurrentExpensesCategoryComponent extends ReallocateSpendingCategoryComponent {
  @Output() public didToggleDropdown: EventEmitter<any> = new EventEmitter<any>();
  public typeOfSpendingToDisplay: 'Spent' | 'Remaining' = 'Remaining';
  protected remainingSpending;
  public isInSetup: boolean;

  constructor(protected dataModelService: DataModelService, protected router: Router, protected renderer: Renderer2) {
    super(dataModelService, router, renderer);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.setRemainingSpending();

    this.isInSetup = this.router.url.includes('setup/spending');
  }

  public toggleDropdown(review = false, state = null) {
    super.toggleDropdown(review, state);
    if (state == null && this.isShowingDropdown) {
      this.didToggleDropdown.emit({ component: this });
    }
  }

  private setRemainingSpending() {
    this.remainingSpending = this.planSum - this.lastMonthSum;
  }

  public toggleTypeShowing() {
    this.typeOfSpendingToDisplay = this.typeOfSpendingToDisplay === 'Spent' ? 'Remaining' : 'Spent';
  }

  protected getValue(account) {
    return account.monthlyAmount.val - account.actualMonthlyAmount.val;
  }

  protected getSubcategoryDisplayColor() {
    const isNegative = this.getValueTotal() < 0;
    const isOverBudget = this.typeOfSpendingToDisplay === 'Spent' && this.getValueTotal() > this.planSum;
    return isNegative || isOverBudget ? 'red' : null;
  }

  protected getDisplayColor(account) {
    const isNegative = this.getValue(account) < 0;
    const isOverBudget = this.typeOfSpendingToDisplay === 'Spent' && this.getValue(account) > account.monthlyAmount.val;
    return isNegative || isOverBudget ? 'red' : null;
  }

  protected getValueTotal() {
    if (this.isInSetup) {
      return this.getCategorySum();
    }
    return this.getRemainingSum();
  }

  public getCategorySum(planned = false): number {
    const shadowAccountsMinimumTotal = this.budgetShadowAccounts.reduce(
      (total, account) => (this.belongsToCurrentCategory(account) && account && account.monthlyMinimum ? total + account.monthlyMinimum.val : 0),
      0
    );
    const shadowAccountsMonthlyTotal = this.budgetShadowAccounts.reduce(
      (total, account) => (this.belongsToCurrentCategory(account) && account && account.monthlyAmount ? total + account.monthlyAmount.val : 0),
      0
    );
    if (planned) {
      const actualMonthlyAmountTotal = this.category.accounts.reduce(
        (total, account) => total + account.monthlyAmount.val - account.actualMonthlyAmount.val,
        0
      );

      const actualShadowAccountsTotal = this.budgetShadowAccounts.reduce(
        (total, account) =>
          this.belongsToCurrentCategory(account)
            ? account && account.actualMonthlyAmount
              ? total + account.actualMonthlyAmount.val
              : total + account.monthlyMinimum.val
            : total,
        0
      );
      return actualMonthlyAmountTotal + shadowAccountsMonthlyTotal + shadowAccountsMinimumTotal;
    } else {
      const MonthlyAmountTotal = this.category.accounts.reduce((total, account) => total + account.monthlyAmount.val, 0);

      return MonthlyAmountTotal + shadowAccountsMonthlyTotal;
    }
  }
}
