import { Directive, ElementRef, HostListener } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Directive({
  selector: '[currencyInputFormat]'
})
export class CurrencyInputFormatDirective {
  constructor(private element: ElementRef, private currencyPipe: CurrencyPipe) {}

  @HostListener('keypress', ['$event'])
  public onKeyPress(event: KeyboardEvent): void {
    if (event.key < '0' || event.key > '9') {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event'])
  public onInput(event: any): void {
    console.log(event);

    const htmlInput = this.element.nativeElement as HTMLInputElement;
    // Remove all dots, commas and spaces to get clean value, which is the price * 100 (cents become integers)
    const originalValue = this.element.nativeElement.value;
    let cleanValue = this.element.nativeElement.value.replace(/\./g, '');
    cleanValue = cleanValue.replace(/,/g, '');
    cleanValue = cleanValue.replace(/ /g, '');
    const cleanValueNumber = Number(cleanValue) / 100;
    const formattedValue = this.formatNumberToString(cleanValueNumber);

    if (originalValue !== formattedValue) {
      htmlInput.value = formattedValue;
      this.element.nativeElement.dispatchEvent(new Event('input'), { data: formattedValue });
    }
  }

  private formatNumberToString(value: number): string {
    return this.currencyPipe.transform(value, 'USD', '', '1.2-2', 'en-US').trim();
  }
}
