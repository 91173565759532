import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, AsyncValidatorFn, Validators } from '@angular/forms';
import { DataModelService } from '@app/services/data-model/data-model.service';
import { Draft } from '@app/interfaces/draft';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-dialog-draft-name',
  templateUrl: './dialog-draft-name.component.html',
  styleUrls: ['./dialog-draft-name.component.scss']
})
export class DialogDraftNameComponent {
  renamingDraftInProgress = false;
  public currentDraft?: Draft;
  private cachedDrafts?: Draft[];
  private selectedScreenInEdit: 'income' | 'expenses' | 'four-step-plan';

  get buttonText() {
    if (this.currentDraft) {
      return this.renamingDraftInProgress ? 'Renaming draft...' : 'Rename Draft';
    } else {
      return 'Create Draft';
    }
  }

  draftNameAlreadyExists: AsyncValidatorFn = async control => {
    if (this.cachedDrafts === undefined) {
      this.cachedDrafts = await this.dataModelService.draftService.fetchDraftsInfo();
    }
    const alreadyExists = this.cachedDrafts.some(d => d.title === control.value && (!this.currentDraft || d.title !== this.currentDraft.title));
    return alreadyExists ? { alreadyExists: true } : null;
  };

  formGroup: FormGroup;

  constructor(
    private dataModelService: DataModelService,
    private router: Router,
    private dialogRef: MatDialogRef<DialogDraftNameComponent>,
    @Inject(MAT_DIALOG_DATA) data: DialogData
  ) {
    this.selectedScreenInEdit = data.selectedScreenInEdit;
    this.currentDraft = data.currentDraft;
    this.formGroup = new FormGroup({
      name: new FormControl(this.currentDraft ? this.currentDraft.title : '', Validators.required, this.draftNameAlreadyExists)
    });
  }

  async submitForm() {
    if (!this.currentDraft) {
      await this.confirmDraftCreation();
    } else {
      await this.confirmDraftRename();
    }
  }

  async confirmDraftCreation() {
    await this.dataModelService.draftService.createDraft(this.formGroup.controls.name.value, ``, 'open');
    await this.dataModelService.draftService.fetchDraft(this.formGroup.controls.name.value);
    this.router.navigate(['/plan/edit-CAFR'], { fragment: this.selectedScreenInEdit });
    this.dialogRef.close(true);
  }

  async confirmDraftRename() {
    this.dialogRef.disableClose = true;
    this.renamingDraftInProgress = true;
    await this.dataModelService.draftService.fetchDraft(this.currentDraft.title);
    await this.dataModelService.draftService.createDraft(this.formGroup.controls.name.value, ``, this.currentDraft.draftType);
    await this.dataModelService.dataManagement.fetchdata();
    await this.dataModelService.draftService.deleteDraft(this.currentDraft.title);
    this.renamingDraftInProgress = false;
    this.dialogRef.disableClose = false;
    this.dialogRef.close(true);
  }
}

interface DialogData {
  selectedScreenInEdit: 'income' | 'expenses' | 'four-step-plan';
  currentDraft?: Draft;
}
