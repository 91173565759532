import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-other-deductions',
  templateUrl: './other-deductions.component.html',
  styleUrls: ['./other-deductions.component.scss']
})
export class OtherDeductionsComponent implements OnInit {
  @Output() public onClose: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  public ngOnInit() {}

  public _onClose() {
    this.onClose.emit();
  }
}
