import { Injectable } from '@angular/core';
import { CAssetProtection } from 'src/app/utilities/assets/asset-protection.class';
import { CAssets } from 'src/app/utilities/assets/assets.class';
import { CBudget } from 'src/app/utilities/budget/budget.class';
import { GenericDataManagement } from 'src/app/utilities/generic-data-management.class';
import { CIncome } from 'src/app/utilities/income/income.class';
import { CLiabilities } from 'src/app/utilities/liabilities/liabilities.class';
import { CProfile } from 'src/app/utilities/profile.class';
import { shadowAccounts } from 'src/app/utilities/shadow-accounts.util';
import { DataModelService } from '../data-model/data-model.service';

@Injectable()
export class ProfileService {
  public worthDifferenceAge;
  public static readonly MAXIMUM_AGE = 999;
  public readonly MAXIMUM_AGE = ProfileService.MAXIMUM_AGE;

  protected currentFFDate: any;
  protected currentFFData: any;
  protected ffWealth: any;
  protected originalFFDate: any;
  protected originalFFData: any;
  protected currentFFAge: any;
  protected originalFFAge: any;
  protected dob: string;
  protected budgetShadowAccounts: any = [];
  protected accounts: any;
  protected categoryLabels: any;
  protected cProfile: any;
  protected cAssets: any;
  protected gdAssets: any;
  protected messages: any;
  protected cAssetProtection: any;
  protected gdAssetProtection: any;
  protected cBudget: any;
  protected gdBudget: any;
  protected cIncome: any;
  protected gdIncome: any;
  protected cLiabilities: any;
  protected gdLiabilities: any;

  constructor(protected dataModelService: DataModelService) {
    this.setupPrerequisiteData();
    this.initOriginalProjectionData();
    this.initCurrentProjectionData();
    this.calculateNetWorthAges();
    this.initializeShadowAccountData();
    this.setAccounts();
  }

  public static getCurrentESBalance(accounts) {
    const ESAccounts = [];
    accounts.forEach(element => {
      if (element && element.targetAmount && element.wizeFiCategory.includes('emergencySavings')) {
        ESAccounts.push(element);
      }
    });
    const targetAmountFromAccounts = ESAccounts.reduce((a, b) => a + (b.balance ? b.balance : 0), 0);
    return targetAmountFromAccounts;
  }

  public static getCurrentGSBalance(accounts) {
    const ESAccounts = [];
    accounts.forEach(element => {
      if (element && element.targetAmount && element.wizeFiCategory.includes('cashReserves')) {
        ESAccounts.push(element);
      }
    });
    const targetAmountFromAccounts = ESAccounts.reduce((a, b) => a + (b.balance ? b.balance : 0), 0);
    return targetAmountFromAccounts;
  }

  public static getInvestmentBalance(accounts) {
    const investmentAccounts = [];
    accounts.forEach(element => {
      if (element && element.targetAmount && element.wizeFiCategory.includes('investments')) {
        investmentAccounts.push(element);
      }
    });
    const targetAmountFromAccounts = investmentAccounts.reduce((a, b) => a + (b.balance ? b.balance : 0), 0);
    return targetAmountFromAccounts;
  }

  public getTotalAccountLimit(accounts) {
    return accounts
      .filter(account => account.status === '1')
      .reduce((total, account) => {
        const wizeFiAccount = this.dataModelService.categoryManagement.getWizeFiAccountFromWizeFiCategory(account.wizeFiCategory);
        return total + (wizeFiAccount && wizeFiAccount.accountLimit ? wizeFiAccount.accountLimit.val : 0);
      }, 0);
  }

  public getWorthDifferenceAge() {
    return this.worthDifferenceAge;
  }

  public setupPrerequisiteData() {
    this.dob = this.dataModelService.dataModel.persistent.profile.birthDate;
    this.ffWealth = this.dataModelService.dataModel.persistent.profile.financialFreedomTargetAmount;
  }

  protected dateDiff(date1: string, date2: string): any {
    const a = new Date(date1);
    const b = new Date(date2);
    const msYr = 1000 * 60 * 60 * 24 * 365;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.round(((utc2 - utc1) / msYr) * 100) / 100;
  }

  protected calculateNetWorthAges() {
    this.currentFFDate = this.currentFFData[this.currentFFData.length - 1].x;
    const currentPlanReachedFF = this.currentFFData[this.currentFFData.length - 1].y >= this.ffWealth;
    this.originalFFDate = this.originalFFData[this.originalFFData.length - 1].x;
    const originalPlanReachedFF = this.originalFFData[this.originalFFData.length - 1].y >= this.ffWealth;

    const originalFFDateIdx = this.originalFFData.findIndex(data => data.y >= this.ffWealth);

    if (originalFFDateIdx > 0) {
      this.originalFFDate = this.originalFFData[originalFFDateIdx].x;
    }

    // calculate how old user will be at A wealth for each projection
    this.currentFFAge = currentPlanReachedFF ? this.dateDiff(this.dob, this.currentFFDate) : this.MAXIMUM_AGE;
    this.originalFFAge = originalPlanReachedFF ? this.dateDiff(this.dob, this.originalFFDate) : this.MAXIMUM_AGE;

    this.worthDifferenceAge = this.originalFFAge - this.currentFFAge;
  }

  protected initCurrentProjectionData() {
    const actualDataProjInfo = this.dataModelService.cafrManagement.getCafrDataProjectionInfo(
      {
        terminationType: 'financialFreedomTargetAmount',
        terminationValue: this.ffWealth
      },
      'actual'
    );
    this.currentFFData = actualDataProjInfo.netWorth.summary.projection;
  }

  protected initOriginalProjectionData() {
    // temporarily save current plan while we grab original projection information
    const savedPlan = this.dataModelService.dataModel.persistent.header.curplan;

    this.dataModelService.setCurPlan('original');
    const originalDataProjInfo = this.dataModelService.cafrManagement.getDataProjection(
      'netWorth',
      this.getPrelimChartDuration(),
      this.dataModelService.dataModel.persistent.plans.original.assets,
      this.dataModelService.dataModel.persistent.plans.original.assetProtection,
      this.dataModelService.dataModel.persistent.plans.original.liabilities
    );
    this.originalFFData = originalDataProjInfo.summary.projection;

    // restore current plan after generating original projections
    this.dataModelService.setCurPlan(savedPlan);
  }

  protected getPrelimChartDuration() {
    const curDate = new Date().toISOString();
    const maxAge = 100; // Math.min(this.originalFFAge,100); // we can also lock at 100 by setting this to 100
    const age = this.dateDiff(this.dob, curDate);
    const duration = (maxAge - Math.floor(age)) * 12;
    return duration;
  }

  protected setAccounts() {
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;
    const subcategories = this.dataModelService.dataModel.persistent.plans[curplan].budget;
    let accounts = [];
    const categoryLabels = [];
    for (const key in subcategories) {
      if (!subcategories[key].accounts) {
        continue;
      }
      accounts = accounts.concat(subcategories[key].accounts);
      categoryLabels.push(subcategories[key].label);
    }
    this.categoryLabels = categoryLabels;
    this.accounts = accounts;
  }

  public getBudgetShadowAccounts() {
    return this.budgetShadowAccounts;
  }

  public getRemainingSpendingSum() {
    const monthlyAmountTotal = this.accounts.reduce(
      (total, account) => total + parseFloat(account.monthlyAmount.val) - parseFloat(account.actualMonthlyAmount.val),
      0
    );
    const shadowAccountsTotal = this.budgetShadowAccounts.reduce(
      (total, account) =>
        this.isShadowAccount(account) ? total + parseFloat(account.monthlyMinimum.val) - parseFloat(account.actualMonthlyAmount.val) : total,
      0
    );
    return monthlyAmountTotal + shadowAccountsTotal;
  }

  public getPlannedSpendingSum() {
    const budgetAccountsTotal = this.accounts.reduce((total, account) => (total += parseFloat(account.monthlyAmount.val)), 0);
    const shadowAccountsTotal = this.budgetShadowAccounts.reduce(
      (total, account) => (this.isShadowAccount(account) ? total + account.monthlyMinimum.val : total),
      0
    );
    return budgetAccountsTotal + shadowAccountsTotal;
  }

  protected isShadowAccount(shadowAccount): boolean {
    let isAShadowAccount = false;
    const containsCategory =
      shadowAccount.budgetSubcategory &&
      shadowAccount.budgetSubcategory.val !== '' &&
      this.categoryLabels.find(e => e.toLowerCase().includes(shadowAccount.budgetSubcategory.val.toLowerCase())) != null;
    const belongsToCategory =
      shadowAccount.budgetSubcategory && this.categoryLabels.find(e => e.toLowerCase() === shadowAccount.budgetSubcategory.val.toLowerCase()) != null;

    if (containsCategory === true || belongsToCategory === true) {
      if (shadowAccount.shadowType !== 'liability' && shadowAccount.shadowType !== 'asset') {
        isAShadowAccount = true;
      }
    }

    return isAShadowAccount;
  }

  private initializeShadowAccountData() {
    this.cProfile = new CProfile(this.dataModelService.getdata('profile'));

    this.cAssets = new CAssets(this.dataModelService.getdata('assets'));
    this.gdAssets = new GenericDataManagement(this.dataModelService, this.cAssets.assets, this.messages);

    this.cAssetProtection = new CAssetProtection(this.dataModelService.getdata('assetProtection'));
    this.gdAssetProtection = new GenericDataManagement(this.dataModelService, this.cAssetProtection.assetProtection, this.messages);

    this.cBudget = new CBudget(this.dataModelService.getdata('budget'));
    this.gdBudget = new GenericDataManagement(this.dataModelService, this.cBudget.budget, this.messages);

    this.cIncome = new CIncome(this.dataModelService.getdata('income'));
    this.gdIncome = new GenericDataManagement(this.dataModelService, this.cIncome.income, this.messages);

    this.cLiabilities = new CLiabilities(this.dataModelService.getdata('liabilities'));
    this.gdLiabilities = new GenericDataManagement(this.dataModelService, this.cLiabilities.liabilities, this.messages);

    this.budgetShadowAccounts = shadowAccounts(
      'budgetShadowAccounts',
      'monthlyMinimum',
      this.gdBudget,
      this.gdIncome,
      this.gdAssets,
      this.gdAssetProtection,
      this.gdLiabilities,
      this.cProfile,
      this.cIncome,
      this.cBudget,
      this.cAssets,
      this.cAssetProtection,
      this.cLiabilities
    );
  }
}
