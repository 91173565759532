import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { TransactionRuleService } from 'src/app/services/transaction-rule/transaction-rule.service';
import { WizefiTransaction } from '../../../interfaces/wizefi-transaction';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { IManualTransactionDialogData, ManualTransactionDetailsComponent } from '../manual-transaction-details/manual-transaction-details.component';

@Component({
  selector: 'app-uncategorized-transactions',
  templateUrl: './uncategorized-transactions.component.html',
  styleUrls: ['./uncategorized-transactions.component.scss']
})
export class UncategorizedTransactionsComponent {
  @Input() public transactionsYearMonth;
  @Input() public isInSetup: boolean;
  @Output() public onTransactionWasCategorized: EventEmitter<any> = new EventEmitter<any>();
  public isLoading = true;
  public uncategorizedTransactions: WizefiTransaction[];
  public isEditingTransaction: boolean;
  public transactionToEdit: WizefiTransaction;
  public uncategorizedTransactionsTotal: number;
  public disableCategorizeButton = false;
  protected curYearMonth = new Date().toISOString().substr(0, 7);

  constructor(
    protected dataModelService: DataModelService,
    protected router: Router,
    private dialog: MatDialog,
    public transactionRuleService: TransactionRuleService
  ) {
    this.loadData();
  }

  private async loadData() {
    this.isLoading = true;
    await this.dataModelService.plaidManagement.getPlaidData().catch(err => {
      this.isLoading = false;
      console.log(err);
    });
    await this.updateWizeFiTransactions().catch(err => {
      this.isLoading = false;
      console.log(err);
    });
    await this.setUncategorizedTransactions().catch(err => {
      this.isLoading = false;
      console.log(err);
    });
    this.isLoading = false;
    this.disableCategorizeButton = false;
  }

  public onReview() {
    this.transactionToEdit = this.uncategorizedTransactions[0];
    if (this.transactionToEdit) {
      this.showTransactionModal();
    }
  }

  public closeModal() {
    this.isEditingTransaction = false;
    this.transactionToEdit = null;
  }

  public async saveTransaction() {
    this.onTransactionWasCategorized.emit();
    await this.setUncategorizedTransactions();
    this.goToNextTransaction();
  }

  public uncategorizedTransactionsLength() {
    return (this.uncategorizedTransactions && this.uncategorizedTransactions.length) || 0;
  }

  private async setUncategorizedTransactions() {
    if (!this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection) {
      await this.dataModelService.plaidManagement.getPlaidData();
    }
    if (!this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection) {
      this.uncategorizedTransactions = [];
      this.uncategorizedTransactionsTotal = 0;
      return;
    }
    const transactions = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection[this.transactionsYearMonth];
    this.uncategorizedTransactions = (transactions && transactions.filter(transaction => transaction.wizeFiCategory === 'unknown')) || [];
    this.uncategorizedTransactionsTotal =
      this.uncategorizedTransactions && this.uncategorizedTransactions.reduce((total, t) => (total += Math.abs(t.amount)), 0);
  }

  /**
   * @TODO the following several functions are found in a few different places... when linking accounts
   * in the review accounts screen and in the verify spending screen. This should be consolidated
   */
  private async updateWizeFiTransactions() {
    await this.getUpdatedWizeFiTransactions();
    await this.transactionRuleService.runRulesForMonth(this.transactionsYearMonth);
  }

  private async getUpdatedWizeFiTransactions() {
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    const wizeFiPlaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    const activeWizeFiPlaidAccountIds = this.dataModelService.plaidManagement.setActiveWizeFiPlaidAccountIds(wizeFiPlaidAccounts);
    const title = this.dataModelService.dataManagement.getDraftTitle();
    const transactionInfo = await this.dataModelService.plaidManagement.getTransactions(
      wizeFiID,
      title,
      { wantMonthRange: true, yearMonth: this.transactionsYearMonth },
      activeWizeFiPlaidAccountIds
    );
    const wizeFiTransactionsCollection = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection;
    this.removeTransactionsFromInactiveAccounts(wizeFiPlaidAccounts, wizeFiTransactionsCollection);
    this.addTransactionsFromNewlyActivatedAccounts(wizeFiPlaidAccounts, wizeFiTransactionsCollection, transactionInfo);

    return wizeFiTransactionsCollection;
  }

  private removeTransactionsFromInactiveAccounts(wizeFiPlaidAccounts: any, wizeFiTransactionsCollection: any) {
    if (wizeFiTransactionsCollection && wizeFiTransactionsCollection[this.transactionsYearMonth]) {
      wizeFiTransactionsCollection[this.transactionsYearMonth] = wizeFiTransactionsCollection[this.transactionsYearMonth].filter(
        (transaction: { account_id: any }) => {
          const plaidAccount = (wizeFiPlaidAccounts as any[]).find(account => transaction.account_id === account.account_id);
          return plaidAccount && plaidAccount.isActive && plaidAccount.status === '1';
        }
      );
    }
  }

  private addTransactionsFromNewlyActivatedAccounts(
    wizeFiPlaidAccounts: any,
    wizeFiTransactionsCollection: any,
    transactionInfo: { transactions: any }
  ) {
    for (const transaction of transactionInfo.transactions) {
      if (wizeFiTransactionsCollection && this.isNewPlaidTransaction(transaction, wizeFiTransactionsCollection[this.transactionsYearMonth])) {
        delete transaction.category_id;
        transaction.isManual = false;
        transaction.wizeFiCategory = 'unknown';
        transaction.splitStatus = 0;
        const plaidAccount = (wizeFiPlaidAccounts as any[]).find(account => transaction.account_id === account.account_id);
        if (wizeFiTransactionsCollection && plaidAccount && plaidAccount.isActive && plaidAccount.status === '1') {
          wizeFiTransactionsCollection[this.transactionsYearMonth].push(transaction);
        }
      }
    }
  }

  private isNewPlaidTransaction(transaction, wizeFiTransactions) {
    let isNew = true;
    let i = -1;
    while (++i < wizeFiTransactions.length && isNew) {
      if (transaction.transaction_id === wizeFiTransactions[i].transaction_id) {
        isNew = false;
      }
    }
    return isNew;
  }

  // Private functions for navigation between transactions

  private goToNextTransaction() {
    if (this.uncategorizedTransactions.length >= 1) {
      this.transactionToEdit = this.uncategorizedTransactions[0];
      this.showTransactionModal();
    } else {
      this.hideTransactionModal();
    }
  }

  private showTransactionModal() {
    this.isEditingTransaction = true;
  }

  private hideTransactionModal() {
    this.isEditingTransaction = false;
  }

  protected saveManualTransaction(transaction) {
    this.onTransactionWasCategorized.emit();
  }

  protected showManualTransactionModal() {
    this.dialog
      .open<ManualTransactionDetailsComponent, IManualTransactionDialogData, WizefiTransaction>(ManualTransactionDetailsComponent, {
        data: { isInSetup: this.isInSetup, defaultTransactionDate: this.getDefaultManualTransactionDate() }
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.saveManualTransaction(result);
        }
      });
  }

  protected getDefaultManualTransactionDate() {
    const today = moment(Date.now()).startOf('day');
    const lastMonth = moment(Date.now()).subtract(1, 'months');
    return (this.isInSetup ? lastMonth : today).toISOString().substr(0, 10);
  }
}
