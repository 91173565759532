import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule, MatIconModule, MatMenuModule, MatCheckboxModule, MatDatepickerModule, MatDialogModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { BrowserModule } from '@angular/platform-browser';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { AngularCropperjsModule } from 'angular-cropperjs';
import { AccountSectionComponent } from '../components/accounts-page/account-section/account-section.component';
import { AllAccountsComponent } from '../components/accounts-page/account-section/all-accounts/all-accounts.component';
import { AssetAccountsComponent } from '../components/accounts-page/account-section/asset-accounts/asset-accounts.component';
import { DebtAccountsComponent } from '../components/accounts-page/account-section/debt-accounts/debt-accounts.component';
import { InsuranceAccountsComponent } from '../components/accounts-page/account-section/insurance-accounts/insurance-accounts.component';
import { AccountsPageComponent } from '../components/accounts-page/accounts-page.component';
import { AccountsSubmenuComponent } from '../components/accounts-page/accounts-submenu/accounts-submenu.component';
import { BudgetPageExpensesComponent } from '../components/budget-page/budget-page-expenses/budget-page-expenses.component';
import { BudgetPageIncomeComponent } from '../components/budget-page/budget-page-income/budget-page-income.component';
import { BudgetPagePlanComponent } from '../components/budget-page/budget-page-plan/budget-page-plan.component';
import { BudgetPageSubmenuComponent } from '../components/budget-page/budget-page-submenu/budget-page-submenu.component';
import { BudgetPageComponent } from '../components/budget-page/budget-page.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { DefaultInfoDialogComponent } from '../components/default-info-dialog/default-info-dialog.component';
import { EditCafrSubmenuComponent } from '../components/edit-cafr-submenu/edit-cafr-submenu.component';
import { EditCAFRComponent } from '../components/edit-cafr/edit-cafr.component';
import { ReviewProjectionsSubmenuComponent } from '../components/review-projections-submenu/review-projections-submenu.component';
import { ReviewProjectionsComponent } from '../components/review-projections/review-projections.component';
import { ChangeLogComponent } from '../components/settings/change-log/change-log.component';
import { SettingsDetailsComponent } from '../components/settings/settings-details/settings-details.component';
import { SettingsProfileComponent } from '../components/settings/settings-profile/settings-profile.component';
import { SettingsComponent } from '../components/settings/settings.component';
import { TransactionRuleDialogComponent } from '../components/transaction-rule-dialog/transaction-rule-dialog.component';
import { MonthPlanSelectorComponent } from '../components/transactions/month-plan-selector/month-plan-selector.component';
import { TransactionsFilterComponent } from '../components/transactions/transactions-filter/transactions-filter.component';
import { TransactionsComponent } from '../components/transactions/transactions.component';
import { UpdatedDebtProjectionsComponent } from '../components/updated-debt-projections/updated-debt-projections.component';
import { UpdatedProjectionsComponent } from '../components/updated-projections/updated-projections.component';
import { DataModelService } from '../services/data-model/data-model.service';
import { LogMessageService } from '../services/log-message/log-message.service';
import { SharedModule } from '../shared/shared.module';
import { CustomErrorHandler } from '../utilities/custom-error-handler.class';
import { ScreenLoginGuard } from '../utilities/screen-login.guard';
import { AccountTransactionsComponent } from '../components/cards/account-transactions/account-transactions.component';
import { TransactionListItemComponent } from '../components/cards/transaction-list-item/transaction-list-item.component';
import fileUploadService from '../services/fileupload.service';
import { Crop } from '../components/cropper/cropper.component';
import { StructureModule } from './structure.module';
import { SetupModule } from './setup.module';
import { AppRoutingModule } from './routing.module';
import { DialogDisplayDraftsComponent } from '@app/components/dialog-display-drafts/dialog-display-drafts.component';
import { DialogDraftNameComponent } from '@app/components/dialog-display-drafts/dialog-draft-name/dialog-draft-name.component';

@NgModule({
  declarations: [
    InsuranceAccountsComponent,
    AssetAccountsComponent,
    DashboardComponent,
    DebtAccountsComponent,
    BudgetPageComponent,
    BudgetPageSubmenuComponent,
    BudgetPageIncomeComponent,
    BudgetPageExpensesComponent,
    BudgetPagePlanComponent,
    ChangeLogComponent,
    SettingsProfileComponent,
    SettingsDetailsComponent,
    EditCAFRComponent,
    EditCafrSubmenuComponent,
    ReviewProjectionsComponent,
    ReviewProjectionsSubmenuComponent,
    UpdatedProjectionsComponent,
    UpdatedDebtProjectionsComponent,
    SettingsComponent,
    TransactionsComponent,
    AccountsPageComponent,
    AccountSectionComponent,
    AccountsSubmenuComponent,
    AllAccountsComponent,
    TransactionsFilterComponent,
    TransactionRuleDialogComponent,
    MonthPlanSelectorComponent,
    DefaultInfoDialogComponent,
    AccountTransactionsComponent,
    TransactionListItemComponent,
    Crop,
    DialogDisplayDraftsComponent,
    DialogDraftNameComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    StructureModule,
    SetupModule,
    MatDialogModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatMenuModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatProgressBarModule,
    SharedModule,
    AngularCropperjsModule
  ],
  exports: [FormsModule],
  providers: [
    DataModelService,
    LogMessageService,
    ScreenLoginGuard,
    fileUploadService,
    { provide: ErrorHandler, useClass: CustomErrorHandler } // this leads to error: Cannot instantiate cyclic dependency
  ],
  entryComponents: [
    TransactionsFilterComponent,
    TransactionRuleDialogComponent,
    MonthPlanSelectorComponent,
    DefaultInfoDialogComponent,
    Crop,
    DialogDisplayDraftsComponent,
    DialogDraftNameComponent
  ]
})
export class DashboardModule {}
