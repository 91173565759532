import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cafr-screen',
  templateUrl: './cafr-screen.component.html',
  styleUrls: ['./cafr-screen.component.scss']
})
export class CafrScreenComponent implements OnInit {
  constructor(protected router: Router) {}

  public ngOnInit() {}

  public onNext() {
    this.router.navigate(['/reallocate-spending']);
  }
}
