import { DataModelService } from '../services/data-model/data-model.service';

export class ScreenDataManagement {
  public headerWizeFiID = '0000000';

  constructor(public dataModelService: DataModelService) {}

  //////////////////////////////////////////////////////////////////////
  // routines to support access to Lambda function features
  //////////////////////////////////////////////////////////////////////

  public invokeManageScreenData(action: string, actionParms: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // set params to guide function invocation
      let payload = {
        action,
        actionParms
      };
      const params = {
        FunctionName: 'manageScreenData',
        Payload: JSON.stringify(payload)
      };

      // invoke lambda function to process data
      this.dataModelService.dataModel.global.lambda.invoke(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          payload = JSON.parse(data.Payload);
          if (payload !== null && typeof payload === 'object' && payload.hasOwnProperty('errorMessage')) {
            reject(payload);
          } else {
            resolve(payload);
          }
        }
      }); // lambda invoke
    }); // return Promise
  } // invokeManageScreenData

  public fetchScreenData(wizeFiID) {
    const action = 'fetchScreenData';
    const actionParms = { wizeFiID };
    return this.invokeManageScreenData(action, actionParms);
  } // fetchScreenData

  public storeScreenData(wizeFiID, info) {
    const action = 'storeScreenData';
    const actionParms = { wizeFiID, info };
    return this.invokeManageScreenData(action, actionParms);
  } // storeScreenData

  public deleteScreenData(wizeFiID) {
    const action = 'deleteScreenData';
    const actionParms = { wizeFiID };
    return this.invokeManageScreenData(action, actionParms);
  } // deleteScreenData

  public fetchAllScreenData() {
    const action = 'fetchAllScreenData';
    const actionParms = {};
    return this.invokeManageScreenData(action, actionParms);
  } // fetchAllScreenData

  public hasScreenAccess(wizeFiID, secureScreen) {
    const action = 'hasScreenAccess';
    const actionParms = { wizeFiID, secureScreen };
    return this.invokeManageScreenData(action, actionParms);
  } // hasScreenAccess

  public identifyUser = wizeFiID => {
    const action = 'identifyUser';
    const actionParms = { wizeFiID };
    return this.invokeManageScreenData(action, actionParms);
  }; // identifyUser

  public fetchAllUserIdentity(filter, filterValue) {
    const action = 'fetchAllUserIdentity';
    const actionParms = { filter, filterValue };
    return this.invokeManageScreenData(action, actionParms);
  } // fetchAllUserIdentity

  //////////////////////////////////////////////////////////////////////
  // utility routines
  //////////////////////////////////////////////////////////////////////

  public async fetchScreenInterfaceData() {
    // retrieve from DynamoDB the data used in the interface screen
    const items = await this.dataModelService.screenDataManagement.fetchAllScreenData();

    // reformat the items data to facilitate utilization of the data in the ManageScreenData interface screen
    let data: any;
    data = {};
    data.headerData = {};
    data.headerData.info = {};
    data.headerData.info.secureScreens = [];
    data.authorizedUsers = {};

    // handle header item -- note: must do this first because headerWizeFiID is not guaranteed to appear first in the list of items --
    // headerData needs to be set so that actions that follow later will work correctly.
    for (const item of items) {
      if (item.wizeFiID === this.headerWizeFiID) {
        data.headerData.info.secureScreens = item.info.secureScreens;
      }
    }
    data.headerData.info.secureScreens.sort();

    // handle individual user items
    for (const item of items) {
      if (item.wizeFiID !== this.headerWizeFiID) {
        // prepare screenCheck data for this user
        const screenCheck = {};
        for (const secureScreen of data.headerData.info.secureScreens) {
          screenCheck[secureScreen] = item.info.authorizedScreens.indexOf(secureScreen) !== -1;
        }

        data.authorizedUsers[item.wizeFiID] = {};
        data.authorizedUsers[item.wizeFiID].info = item.info;
        data.authorizedUsers[item.wizeFiID].screenCheck = screenCheck;
      }
    }

    // return the screen interface data
    return data;
  } // fetchScreenInterfaceData
} // class ScreenDataManagement
