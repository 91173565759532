import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

const CLOSE_ICON = '../../../assets/icon_close.svg';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() public dark?: boolean;
  @Input() public black?: boolean;
  @Input() public size?: string;
  @Input() public nox?: boolean = false;
  @Input() public isInformational = false; // this is specific to the information modal on the verify spending screen...DO NOT USE
  @Input() public border?: boolean = false;
  @Output() public onClose: EventEmitter<any> = new EventEmitter<any>();
  public closeButtonSrc = CLOSE_ICON;

  constructor(private ref: ElementRef) {}

  public ngOnInit() {}

  public setStyleClasses() {
    return {
      'dark-modal': this.dark === true,
      'black-modal': this.black === true,
      'modal-xxsm': this.size === 'xxsm',
      'modal-xsm': this.size === 'xsm',
      'modal-sm': this.size === 'sm',
      'modal-lg': this.size === 'lg',
      'modal-md': this.size === 'md',
      'information-position': this.isInformational === true,
      'modal-border': this.border === true
    };
  }

  public _onClose() {
    this.onClose.emit();
  }

  @HostListener('document:keydown.escape', ['$event'])
  protected onKeydownHandler = (event: KeyboardEvent) => {
    this._onClose();
  };
}
