import { TransactionRuleService } from 'src/app/services/transaction-rule/transaction-rule.service';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DataModelService } from './../../../services/data-model/data-model.service';

@Component({
  selector: 'app-budget-page-expenses',
  templateUrl: './budget-page-expenses.component.html',
  styleUrls: ['./budget-page-expenses.component.scss']
})
export class BudgetPageExpensesComponent implements OnInit {
  wizeFiCategory2MonthlyAmount: {};
  dateRange: any;
  previousPlan: any;
  transactionsYearMonth: any;

  constructor(public dataModelService: DataModelService, private transactionRuleService: TransactionRuleService) {}

  public ngOnInit() {
    this.setTransactionsYearMonth();
    this.setupData();
  }

  protected async setupData() {
    console.log('fetch expenses data');
    const wizeFiPlaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    this.wizeFiCategory2MonthlyAmount = this.dataModelService.categoryManagement.getWizeFiCategory2monthlyAmount(this.previousPlan);
    const activeWizeFiPlaidAccountIds: any = this.dataModelService.plaidManagement.setActiveWizeFiPlaidAccountIds(wizeFiPlaidAccounts);
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    this.dateRange = {};
    this.dateRange.wantMonthRange = true;
    this.dateRange.yearMonth = new Date().toISOString().substr(0, 7);
    const title = this.dataModelService.dataManagement.getDraftTitle();
    await this.dataModelService.plaidManagement.getTransactions(
      wizeFiID,
      title,
      { wantMonthRange: true, yearMonth: this.transactionsYearMonth },
      activeWizeFiPlaidAccountIds
    );
    await this.transactionRuleService.runRulesForMonth(this.dataModelService.dataModel.persistent.header.curplanYearMonth);
  }

  private setTransactionsYearMonth() {
    const header = this.dataModelService.dataModel.persistent.header;
    if (header.curplan === 'original') {
      const origTransactionsYearMonth = header.origTransactionsYearMonth;
      const lastMonth = moment(new Date()).subtract(1, 'month').toISOString().substr(0, 7);
      this.transactionsYearMonth = origTransactionsYearMonth || lastMonth;
    } else {
      this.transactionsYearMonth = header.curplanYearMonth;
    }
  }
}
