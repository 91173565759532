import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() protected screenName: string;
  protected heading = this.screenName ? this.screenName : 'W';
  constructor(elem: ElementRef) {
    //console.log(elem);
  }

  @ViewChild('HeaderComponent', { static: false }) public parent;

  public ngOnInit() {}
}
