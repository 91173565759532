import { Component, OnInit, Inject } from '@angular/core';
import { DataModelService } from '@app/services/data-model/data-model.service';
import { Router } from '@angular/router';
import { Draft } from '@app/interfaces/draft';
import { MatButtonToggleChange, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { DialogDraftNameComponent } from './dialog-draft-name/dialog-draft-name.component';

@Component({
  selector: 'app-dialog-display-drafts',
  templateUrl: './dialog-display-drafts.component.html',
  styleUrls: ['./dialog-display-drafts.component.scss']
})
export class DialogDisplayDraftsComponent implements OnInit {
  public plansByMonth: Draft[][] = [];
  private planDrafts: Draft[];
  private planVersions: Draft[];
  public selectedPlanType: 'versions' | 'drafts' = 'drafts';
  private selectedScreenInEdit: 'income' | 'expenses' | 'four-step-plan';

  constructor(
    private dataModelService: DataModelService,
    private router: Router,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogDisplayDraftsComponent>,
    @Inject(MAT_DIALOG_DATA) data: 'income' | 'expenses' | 'four-step-plan'
  ) {
    this.selectedScreenInEdit = data;
  }

  ngOnInit() {
    this.parseDraftsAndVersionHistory();
  }

  async editDraft(plan: Draft) {
    await this.dataModelService.draftService.fetchDraft(plan.title);

    this.dialogRef.close();
    this.router.navigate(['/plan/edit-CAFR'], { fragment: this.selectedScreenInEdit });
  }

  showRenameDraft(draft: Draft) {
    this.dialog
      .open(DialogDraftNameComponent, { data: { selectedScreenInEdit: this.selectedScreenInEdit, currentDraft: draft } })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.parseDraftsAndVersionHistory();
        }
      });
  }

  async deleteDraft(draft: Draft) {
    await this.dataModelService.draftService.deleteDraft(draft.title);
    this.parseDraftsAndVersionHistory();
  }

  private parseDraftsAndVersionHistory() {
    const allDrafts = this.dataModelService.dataModel.global.draftsInfo;
    this.planDrafts = allDrafts.filter(allDraftsPlan => allDraftsPlan.draftType === 'open');
    this.planVersions = allDrafts.filter(allDraftsPlan => allDraftsPlan.draftType === 'versionHistory');
    this.splitSelectedPlansByDate();
  }

  private splitSelectedPlansByDate() {
    if (this.selectedPlanType === 'versions') {
      this.setPlansByDate(this.planVersions);
    } else {
      this.setPlansByDate(this.planDrafts);
    }
  }

  planTypeSelected(event: MatButtonToggleChange) {
    this.selectedPlanType = event.value;
    this.splitSelectedPlansByDate();
  }

  setPlansByDate(plans: Draft[]) {
    if (!plans) {
      return;
    }

    const draftDates = plans.map(plan => new Date(plan.dateUpdated).getMonth());
    const uniqueMonths = [...new Set(draftDates)];
    this.plansByMonth = uniqueMonths.map(month => plans.filter(plan => new Date(plan.dateUpdated).getMonth() === month));
  }

  async openCurrentPlan() {
    const oldTemporaryDraft = this.dataModelService.dataModel.global.draftsInfo.find(d => d.draftType === 'temporary');
    if (oldTemporaryDraft) {
      await this.deleteDraft(oldTemporaryDraft);
    }
    const newTemporaryDraftName = new Date().toISOString();
    await this.dataModelService.draftService.createDraft(newTemporaryDraftName, ``, 'temporary');
    await this.dataModelService.draftService.fetchDraft(newTemporaryDraftName);

    this.dialogRef.close();
    this.router.navigate(['/plan/edit-CAFR'], { fragment: this.selectedScreenInEdit });
  }

  openNewDraft() {
    this.dialog
      .open(DialogDraftNameComponent, { data: { selectedScreenInEdit: this.selectedScreenInEdit } })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.dialogRef.close();
        }
      });
  }
}
