import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ICurrencyInfo } from '../../../../interfaces/iCurrencyInfo.interface';
import { DataModelService } from '../../../../services/data-model/data-model.service';

@Component({
  selector: 'app-currency-confirmation',
  templateUrl: './currency-confirmation.component.html',
  styleUrls: ['./currency-confirmation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CurrencyConfirmationComponent implements OnInit {
  public selectedCurrency: ICurrencyInfo;
  public availableCurrencies: ICurrencyInfo[] = [];
  public topCurrencyCodesOrder = ['USD', 'EUR', 'CAD', 'GBP'];
  public confirmationStep = false;

  constructor(private dataModelService: DataModelService, private dialogRef: MatDialogRef<CurrencyConfirmationComponent>) {}

  public ngOnInit() {
    const currencyObject = this.dataModelService.ancillaryDataManagement.currencyCode2currencyCodeItem;
    for (const key of Object.keys(currencyObject)) {
      const cloneCurrency = { ...currencyObject[key] };
      cloneCurrency.countryNames = cloneCurrency.countryNames.toLocaleLowerCase().replace(/\b\w/g, l => l.toLocaleUpperCase()); // Capitalize country names
      this.availableCurrencies.push(cloneCurrency);
    }
    this.availableCurrencies.sort((a, b) => {
      const aTopOrder = this.topCurrencyCodesOrder.findIndex(i => i === a.currencyCode);
      const bTopOrder = this.topCurrencyCodesOrder.findIndex(i => i === b.currencyCode);
      if (aTopOrder !== -1 && bTopOrder !== -1) {
        return aTopOrder - bTopOrder;
      } else if (aTopOrder !== -1) {
        return -1;
      } else if (bTopOrder !== -1) {
        return 1;
      } else {
        return a.currencyCode < b.currencyCode ? -1 : 1;
      }
    });

    this.selectedCurrency = this.availableCurrencies[0];
  }

  public confirmCurrency(): void {
    this.confirmationStep = true;
  }

  public returnStep(): void {
    this.confirmationStep = false;
  }

  public saveCurrency(): void {
    const settingsData = {
      currencyCode: this.selectedCurrency.currencyCode,
      decimalDigits: this.selectedCurrency.decimalDigits,
      haveSelectedCurrencyCode: true,
      decimalSeparator: this.dataModelService.dataModel.persistent.settings.decimalSeparator,
      thousandsSeparator: this.dataModelService.dataModel.persistent.settings.thousandsSeparator,
      userCountryCodes: this.dataModelService.dataModel.persistent.settings.userCountryCodes
    };
    this.dataModelService.putdata('settings', settingsData);
    console.log(this.dataModelService);
    this.dialogRef.close();
  }
}
