import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-dialog-header-action',
  templateUrl: './dialog-header-action.component.html',
  styleUrls: ['./dialog-header-action.component.scss']
})
export class DialogHeaderActionComponent implements OnInit {
  @Output() protected backButtonEvent: any = new EventEmitter<void>();
  @Output() protected closeButtonEvent: any = new EventEmitter<void>();

  constructor() {}

  public ngOnInit() {}
}
