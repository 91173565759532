// multiple-institution-instances.component.ts

import { Component, OnInit } from '@angular/core';
import { DataModelService } from '../../services/data-model/data-model.service';

@Component({
  selector: 'app-multiple-institution-instances',
  templateUrl: './multiple-institution-instances.component.html',
  styleUrls: ['./multiple-institution-instances.component.scss']
})
export class MultipleInstitutionInstancesComponent implements OnInit {
  public metadata: any;
  public institutionName: string;
  public oldAccountNamesStrings: string[];
  public oldInstanceCount: number;
  public newAccountNamesString: string;

  constructor(public dataModelService: DataModelService) {}

  public ngOnInit() {
    this.metadata = this.getMetadata();
    this.institutionName = 'Chase';
    this.oldAccountNamesStrings = this.getOldAccountNamesStrings();
    this.newAccountNamesString = this.getNewAccountNamesString();
  } // ngOnInit

  public getMetadata() {
    // TODO rework the role played by this function to get the metadata from the "onSuccess" function metadata input parameter
    // this is sample test data for user in demo with wizeFiID of zbpizmq
    const metadata = {
      account: {
        id: null,
        mask: null,
        name: null,
        subtype: null,
        type: null
      },
      account_id: null,
      accounts: [
        {
          id: 'zKez7x1JP9Cz6JGrMdVlC55GrbNon8Uovx65L',
          mask: '0000',
          name: 'Plaid Checking',
          subtype: 'checking',
          type: 'depository'
        },
        {
          id: 'BPjkQdWwG9UAg6XeLqM8fJJ8verwM4CwK3Vbj',
          mask: '1111',
          name: 'Plaid Saving',
          subtype: 'savings',
          type: 'depository'
        },
        {
          id: 'Z74BNDAbQeUEBaL7kNvGcLL7noAXgWigK79RR',
          mask: '3333',
          name: 'Plaid Credit Card',
          subtype: 'credit card',
          type: 'credit'
        }
      ],
      institution: {
        institution_id: 'ins_13',
        name: 'PNC'
      },
      link_session_id: '13fb59f2-3b09-405e-9db2-b6891f02f836',
      public_token: 'public-sandbox-ed7de47a-94ef-4a31-a9d3-6ace18eb6038'
    };
    return metadata;
  } // getMetadata

  public convertArrayToString(nameList) {
    nameList.sort();
    let str = '';
    for (let i = 0; i < nameList.length; i++) {
      const prefix = i === 0 ? '' : ', ';
      str += prefix + nameList[i];
    }
    return str;
  } // convertArrayToString

  public getOldAccountNamesStrings() {
    // construct a map that contains a list of account names associated with a given item_id for a given institution_id
    const institution_id = this.metadata.institution.institution_id;
    const itemId2accountNames = {};
    const wizeFiPlaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    for (const wizeFiPlaidAccount of wizeFiPlaidAccounts) {
      if (wizeFiPlaidAccount.institution_id === institution_id) {
        if (!itemId2accountNames.hasOwnProperty(wizeFiPlaidAccount.item_id)) {
          itemId2accountNames[wizeFiPlaidAccount.item_id] = [];
        }
        this.institutionName = wizeFiPlaidAccount.institutionName;
        itemId2accountNames[wizeFiPlaidAccount.item_id].push(wizeFiPlaidAccount.accountName + '(' + wizeFiPlaidAccount.mask + ')');
      }
    }
    //console.log("item_id2accountNames: ", itemId2accountNames);  // %//

    // build an array of account names for each institution instance (identified by its item_id value)
    const oldAccountNamesStrings = [];
    for (const itemId of Object.keys(itemId2accountNames)) {
      oldAccountNamesStrings.push(this.convertArrayToString(itemId2accountNames[itemId]));
    }

    return oldAccountNamesStrings;
  } // getOldAccountNamesStrings

  public getNewAccountNamesString() {
    const newAccountNamesArray = [];
    for (const account of this.metadata.accounts) {
      newAccountNamesArray.push(account.name + '(' + account.mask + ')');
    }

    const newAccountNamesString = this.convertArrayToString(newAccountNamesArray);
    return newAccountNamesString;
  } // getNewAccountNamesString

  public cancelLinkInstance() {
    //console.log("cancelLinkInstance");  // %//
  } // cancelLinkInstance

  public continueLinkInstance() {
    //console.log("continueLinkInstance");  // %//
  } // continueLinkInstance
} // class MultipleInstitutionInstancesComponent
