// ancillary-data-management.class.ts

import { ICurrencyInfo } from '../interfaces/iCurrencyInfo.interface';
import { DataModelService } from '../services/data-model/data-model.service';

/*
This class manages data and functions that work with the CountryCodes and CurrencyCodes DynamoDB tables.
*/
export class AncillaryDataManagement {
  public countryCodeItems: any;
  public countryCode2countryCodeItem: any;
  public countryName2countryCodeItem: any;
  public currencySymbols: any;
  public plaidCountryCodeItems: any;
  public plaidCountryCodes: any;

  public currencyCodeItems: any;
  public currencyCode2currencyCodeItem: { [key: string]: ICurrencyInfo };
  public countryName2currencyCodeItem: any;

  public currencyConversion: any;
  public currencyCode2exchangeRate: any;

  constructor(private dataModelService: DataModelService) {}

  ////////////////////////////////////////////////////
  // routines to access DynamoDB data
  ////////////////////////////////////////////////////

  public fetchCountryCodeItems() {
    return new Promise((resolve, reject) => {
      // define params to guide get operation
      const params = {
        TableName: 'CountryCodes',
        Key: { WizeFi: 'WizeFi' }
      };

      // fetch data
      this.dataModelService.dataModel.global.docClient.get(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          let countryCodeItems = [];
          if (data.hasOwnProperty('Item') && data.Item.hasOwnProperty('countryCodeItems')) {
            countryCodeItems = JSON.parse(data.Item.countryCodeItems);
          }
          resolve(countryCodeItems);
        }
      }); // docClient.get
    }); // return Promise
  } // fetchCountryCodeItems

  public storeCountryCodeItems(countryCodeItems) {
    return new Promise((resolve, reject) => {
      // define params to guide put operation
      const params = {
        TableName: 'CountryCodes',
        Item: { WizeFi: 'WizeFi', countryCodeItems: JSON.stringify(countryCodeItems) }
      };

      // put info in DynamoDB table
      this.dataModelService.dataModel.global.docClient.put(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve('Data has been stored');
        }
      });
    }); // return Promise
  } // storeCountryCodeItems

  public fetchCurrencyCodeItems() {
    return new Promise((resolve, reject) => {
      // define params to guide get operation
      const params = {
        TableName: 'CurrencyCodes',
        Key: { WizeFi: 'WizeFi' }
      };

      // fetch data
      this.dataModelService.dataModel.global.docClient.get(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          let currencyCodeItems = [];
          if (data.hasOwnProperty('Item') && data.Item.hasOwnProperty('currencyCodeItems')) {
            currencyCodeItems = JSON.parse(data.Item.currencyCodeItems);
          }
          resolve(currencyCodeItems);
        }
      }); // docClient.get
    }); // return Promise
  } // fetchCurrencyCodeItems

  public storeCurrencyCodeItems(currencyCodeItems) {
    return new Promise((resolve, reject) => {
      // define params to guide put operation
      const params = {
        TableName: 'CurrencyCodes',
        Item: { WizeFi: 'WizeFi', currencyCodeItems: JSON.stringify(currencyCodeItems) }
      };

      // put info in DynamoDB table
      this.dataModelService.dataModel.global.docClient.put(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve('Data has been stored');
        }
      });
    }); // return Promise
  } // storeCurrencyCodeItems

  public fetchCurrencySymbols() {
    return new Promise((resolve, reject) => {
      // define params to guide get operation
      const params = {
        TableName: 'CurrencySymbols',
        Key: { WizeFi: 'WizeFi' }
      };

      // fetch data
      this.dataModelService.dataModel.global.docClient.get(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          let currencySymbols = {};
          if (data.hasOwnProperty('Item') && data.Item.hasOwnProperty('currencySymbols')) {
            currencySymbols = JSON.parse(data.Item.currencySymbols);
          }
          resolve(currencySymbols);
        }
      }); // docClient.get
    }); // return Promise
  } // fetchCurrencySymbols

  public storeCurrencySymbols(currencySymbols) {
    return new Promise((resolve, reject) => {
      // define params to guide put operation
      const params = {
        TableName: 'CurrencySymbols',
        Item: { WizeFi: 'WizeFi', CurrencySymbols: JSON.stringify(currencySymbols) }
      };

      // put info in DynamoDB table
      this.dataModelService.dataModel.global.docClient.put(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve('currencySymbols data has been stored');
        }
      });
    }); // return Promise
  } // storeCurrencySymbols

  public fetchCurrencyConversion() {
    return new Promise((resolve, reject) => {
      // define params to guide get operation
      const params = {
        TableName: 'CurrencyConversion',
        Key: { WizeFi: 'WizeFi' }
      };

      // fetch data
      this.dataModelService.dataModel.global.docClient.get(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          let currencyConversion: any;
          currencyConversion = {};
          let item: any;
          item = {};
          if (data.hasOwnProperty('Item')) {
            item = data.Item;
            item.rates = JSON.parse(item.rates);
            currencyConversion = item;
          }
          resolve(currencyConversion);
        }
      }); // docClient.get
    }); // return Promise
  } // fetchCurrencyConversion

  public storeCurrencyConversion(item) {
    return new Promise((resolve, reject) => {
      // convert data to JSON
      item.rates = JSON.stringify(item.rates);

      // define params to guide put operation
      const params = {
        TableName: 'CurrencyConversion',
        Item: item
      };

      // put info in DynamoDB table
      this.dataModelService.dataModel.global.docClient.put(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve('Data has been stored');
        }
      });
    }); // return Promise
  } // storeCurrencyConversion

  public storePlaidCountryCodes(plaidCountryCodes) {
    return new Promise((resolve, reject) => {
      // define params to guide put operation
      const params = {
        TableName: 'PlaidCountryCodes',
        Item: { WizeFi: 'WizeFi', plaidCountryCodes: JSON.stringify(plaidCountryCodes) }
      };

      // put info in DynamoDB table
      this.dataModelService.dataModel.global.docClient.put(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve('Data has been stored');
        }
      });
    }); // return Promise
  } // storePlaidCountryCodes

  public fetchPlaidCountryCodes() {
    return new Promise((resolve, reject) => {
      // define params to guide get operation
      const params = {
        TableName: 'PlaidCountryCodes',
        Key: { WizeFi: 'WizeFi' }
      };

      // fetch data
      this.dataModelService.dataModel.global.docClient.get(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          let plaidCountryCodes = [];
          if (data.hasOwnProperty('Item') && data.Item.hasOwnProperty('plaidCountryCodes')) {
            plaidCountryCodes = JSON.parse(data.Item.plaidCountryCodes);
          }
          resolve(plaidCountryCodes);
        }
      }); // docClient.get
    }); // return Promise
  } // fetchPlaidCountryCodes

  ////////////////////////////////////////////////////
  // utility routines
  ////////////////////////////////////////////////////

  /*
    This routine initializes all of the data values available in the AncillaryDataManagement class.
    You must invoke this function after creating an instance of the AncillaryDataManagement class and before attempting to utilize the data that needs to be initialized.
    */
  public async loadAncillaryData(): Promise<any> {
    // Note:
    // countryCodeItem = {countryCode:string, countryName:string}
    // currencyCodeItem = {currencyCode:string, currencyName:string, decimalDigits:number, countryName:string}
    // currencySymbols = {<currencyCode - string>: <currencySymbol - string>, ...}
    // currencyConversion = {base:string, date:string, rates:{currencyCode(string):rate(number), ...}}
    // plaidCountryCodes = string[]
    // plaidCountryCodeItem = {countryCode:string, countryName:string}

    let prefix;

    // obtain fundamental data from DynamoDB
    this.countryCodeItems = await this.fetchCountryCodeItems();
    this.currencyCodeItems = await this.fetchCurrencyCodeItems();
    this.currencySymbols = await this.fetchCurrencySymbols();
    this.currencyConversion = await this.fetchCurrencyConversion();
    this.plaidCountryCodes = await this.fetchPlaidCountryCodes();

    // set up plaidCountryCodeItems array to include only countries supported by Plaid
    this.plaidCountryCodeItems = [];
    for (const countryCodeItem of this.countryCodeItems) {
      if (this.plaidCountryCodes.includes(countryCodeItem.countryCode)) {
        // add country code that is supported by Plaid
        this.plaidCountryCodeItems.push(countryCodeItem);
      }
    }

    // add currency symbol information to currencyCodeItems
    for (const currencyCodeItem of this.currencyCodeItems) {
      currencyCodeItem.currencySymbol = !this.currencySymbols.hasOwnProperty(currencyCodeItem.currencyCode)
        ? '-'
        : this.currencySymbols[currencyCodeItem.currencyCode];
    }

    // set up mapping data for countryCodeItems
    this.countryCode2countryCodeItem = {};
    this.countryName2countryCodeItem = {};
    for (const countryCodeItem of this.countryCodeItems) {
      this.countryCode2countryCodeItem[countryCodeItem.countryCode] = countryCodeItem;
      this.countryName2countryCodeItem[countryCodeItem.countryName] = countryCodeItem;
    }

    // set up mapping data for currencyCodeItems
    this.currencyCode2currencyCodeItem = {};
    this.countryName2currencyCodeItem = {};
    for (const currencyCodeItem of this.currencyCodeItems) {
      // fill in data for currencyCode2currencyCodeItem
      const currencyCode = currencyCodeItem.currencyCode;
      if (!this.currencyCode2currencyCodeItem.hasOwnProperty(currencyCode)) {
        const item = {
          currencyCode: currencyCodeItem.currencyCode,
          currencyName: currencyCodeItem.currencyName,
          currencySymbol: currencyCodeItem.currencySymbol,
          decimalDigits: currencyCodeItem.decimalDigits,
          countryNames: ''
        };
        this.currencyCode2currencyCodeItem[currencyCode] = item;
      }
      let countryNames = this.currencyCode2currencyCodeItem[currencyCode].countryNames;
      prefix = countryNames.length === 0 ? '' : ', ';
      countryNames += prefix + currencyCodeItem.countryName;
      this.currencyCode2currencyCodeItem[currencyCode].countryNames = countryNames;

      // fill in data for countryName2currencyCodeItem
      const countryName = currencyCodeItem.countryName;
      if (!this.countryName2currencyCodeItem.hasOwnProperty(countryName)) {
        const item = {
          currencyCode: currencyCodeItem.currencyCode,
          currencyName: currencyCodeItem.currencyName,
          decimalDigits: currencyCodeItem.decimalDigits,
          currencyCodes: ''
        };
        this.countryName2currencyCodeItem[countryName] = item;
      }
      let currencyCodes = this.countryName2currencyCodeItem[countryName].currencyCodes;
      prefix = currencyCodes.length === 0 ? '' : ', ';
      currencyCodes += prefix + currencyCodeItem.currencyCode;
      this.countryName2currencyCodeItem[countryName].currencyCodes = currencyCodes;
    } // for

    // set up mapping data for currency exchange rates
    this.currencyCode2exchangeRate = {};
    for (const currencyCode of Object.keys(this.currencyConversion.rates).sort()) {
      this.currencyCode2exchangeRate[currencyCode] = this.currencyConversion.rates[currencyCode];
    }

    // %//  \/
    // number of currency codes before deleting codes that do not match up
    // console.log("currencyCodeCount before removal:");
    // console.log("currencyCode2currencyCodeItem: " + Object.keys(this.currencyCode2currencyCodeItem).length);
    // console.log("currencyCode2exchangeRate:     " + Object.keys(this.currencyCode2exchangeRate).length);
    // %//  /\

    // remove currencyCode from currencyCode2exchangeRate that does not appear in currencyCode2currencyCodeItem
    for (const currencyCode of Object.keys(this.currencyCode2exchangeRate)) {
      if (!this.currencyCode2currencyCodeItem.hasOwnProperty(currencyCode)) {
        delete this.currencyCode2exchangeRate[currencyCode];
      }
    }

    // remove currencyCode from currencyCode2currencyCodeItem that does not appear in currencyCode2exchangeRate
    for (const currencyCode of Object.keys(this.currencyCode2currencyCodeItem)) {
      if (!this.currencyCode2exchangeRate.hasOwnProperty(currencyCode)) {
        delete this.currencyCode2currencyCodeItem[currencyCode];
      }
    }

    // %//  \/
    // number of currency codes after deleting codes that do not match up
    // console.log("currencyCodeCount after removal:");
    // console.log("currencyCode2currencyCodeItem: " + Object.keys(this.currencyCode2currencyCodeItem).length);
    // console.log("currencyCode2exchangeRate:     " + Object.keys(this.currencyCode2exchangeRate).length);
    // %//  /\
  } // loadAncillaryData

  public convertCurrency(amount, fromCurrencyCode, toCurrencyCode) {
    const convertedAmount = (amount * this.currencyCode2exchangeRate[toCurrencyCode]) / this.currencyCode2exchangeRate[fromCurrencyCode];
    return convertedAmount;
  } // convertCurrency
} // class AncillaryDataManagement
