import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-date-dropdown',
  templateUrl: './date-dropdown.component.html',
  styleUrls: ['./date-dropdown.component.scss']
})
export class DateDropdownComponent implements OnInit {
  @Input() public date: string;
  @Input() public futureRange?: boolean = false;
  @Input() public fontWeight?: string;
  @Input() public lockToPreviousMonth?: boolean = false;
  @Input() public lockToYear?: boolean = false;
  @Output() public onItemChange: EventEmitter<any> = new EventEmitter<any>();

  protected dayDate: any = 'dd';
  protected monthDate: any = 'mm';
  protected yearDate: any = 'yy';

  protected validDays = [...Array(32).keys()].slice(1);
  protected validMonths = this.lockToPreviousMonth ? [...Array(1).keys()].slice(1) : [...Array(13).keys()].slice(1);
  protected validYears;
  protected currentYear = [...Array(new Date().getFullYear())];
  protected pastYears = [...Array(new Date().getFullYear() + 1).keys()].slice(-100).reverse();
  protected futureYears = [...Array(new Date().getFullYear() + 50).keys()].slice(-50);

  constructor() {}

  public ngOnInit() {
    this.validYears = this.getYears();
    this.updateValidDays();
    this.setDateFields();
  }

  public getYears() {
    if (this.futureRange) {
      return this.futureYears;
    } else if (this.lockToYear) {
      return this.currentYear;
    } else {
      return this.pastYears;
    }
  }
  public setInputClasses() {
    return {
      'font-weight--bold': this.fontWeight === 'bold'
    };
  }

  public onDayChange(value: number) {
    this.dayDate = value;
    this.updateValidDays();
    this.onItemChange.emit(this.getFullDateFromFields());
  }

  public onMonthChange(value: number) {
    this.monthDate = value;
    this.updateValidDays();
    this.onItemChange.emit(this.getFullDateFromFields());
  }

  public onYearChange(value: number) {
    this.yearDate = value;
    this.updateValidDays();
    this.onItemChange.emit(this.getFullDateFromFields());
  }

  private getFullDateFromFields(): string {
    if (isNaN(this.yearDate) || isNaN(this.monthDate) || isNaN(this.dayDate)) {
      return null;
    }
    const dateString = String(this.yearDate) + '-' + String(this.monthDate) + '-' + String(this.dayDate);
    const momentDate = moment(dateString, 'YYYY-MM-DD');
    const date = momentDate && momentDate.toISOString() && momentDate.toISOString().substr(0, 10);
    return date;
  }

  private setDateFields() {
    const parts = (this.date && this.date.split('-')) || '';
    this.yearDate = parseInt(parts[0], 10) || 'yy';
    this.monthDate = parseInt(parts[1], 10) || 'mm';
    this.dayDate = parseInt(parts[2], 10) || 'dd';
  }

  private updateValidDays() {
    // update array that populates days dropdown
    const dateString = String(this.yearDate) + '-' + String(this.monthDate) + '-' + '01';
    const daysInMonth = moment(dateString, 'YYYY-MM-DD').daysInMonth();
    this.validDays = [...Array(daysInMonth + 1).keys()].slice(1);

    // update selected day, if number is invalid after re-selecting month or year from dropdowns
    if (this.dayDate > daysInMonth) {
      this.dayDate = daysInMonth;
    }
  }
}
