import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WizefiTransaction } from '../../../interfaces/wizefi-transaction';
import { DataModelService } from '../../../services/data-model/data-model.service';

const iconsPath = 'src/assets/budget_icons/';

@Component({
  selector: 'app-transaction-list-item',
  templateUrl: './transaction-list-item.component.html',
  styleUrls: ['./transaction-list-item.component.scss']
})
export class TransactionListItemComponent implements OnInit {
  @Input() public transaction: WizefiTransaction;
  @Output() public editTransaction: EventEmitter<any> = new EventEmitter<any>();

  public isHidden = false;
  public accountName;

  constructor(protected dataModelService: DataModelService) {}

  public ngOnInit() {
    console.log(this.transaction);
    this.getAccountNickname(this.transaction);
  }

  public getAccountNickname(transaction?) {
    if (transaction) {
      const wizefiAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
      console.log(wizefiAccounts);
      wizefiAccounts.forEach(acct => {
        if (acct.account_id === transaction.account_id) {
          this.accountName = acct.accountName;
          return acct;
        }
      });
    } else {
      this.accountName = this.transaction.accountName;
    }
  }

  public onEditTransaction() {
    this.editTransaction.emit(this.transaction);
  }

  public onSplitTransaction() {}

  public onHideTransaction() {
    this.isHidden = !this.isHidden;
  }

  protected getAmount(transaction): number {
    const info = this.dataModelService.categoryManagement.extractWizeFiCategoryNameParts(transaction.wizeFiCategory);
    return info.category !== 'assets' ? -transaction.amount : transaction.amount;
  }

  protected getSubcategory() {
    const wizeFiCategoryParts = this.transaction.wizeFiCategory.split('_');
    const subcategory = wizeFiCategoryParts.length > 2 && wizeFiCategoryParts[1];
    return subcategory;
  }
}
