import { Component, QueryList, ViewChild, ViewChildren, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { SetupFlowDataManagementService, SetupFlowScreen } from '../../../services/setup/setup-flow-data-management.service';
import { CategoryTransactionsComponent } from '../category-transactions/category-transactions.component';
import { VerifySpendingComponent } from '../verify-spending/verify-spending.component';
import { SetupService } from '@app/services/setup.service';

@Component({
  selector: 'app-spending-screen',
  templateUrl: './spending-screen.component.html',
  styleUrls: ['./spending-screen.component.scss']
})
export class SpendingScreenComponent implements SetupFlowScreen, OnInit {
  @ViewChild(CategoryTransactionsComponent, { static: false })
  public transactionsComponent: CategoryTransactionsComponent;
  @ViewChild(VerifySpendingComponent, { static: true })
  public verifySpendingComponent: VerifySpendingComponent;
  public readonly;

  public selectedCategory = '';
  public selectedSubcategory = '';
  public selectedAccountID = '';

  protected transactionsYearMonth;

  constructor(
    protected dataModelService: DataModelService,
    protected dataManagement: SetupFlowDataManagementService,
    protected router: Router,
    protected setupService: SetupService
  ) {}

  public async ngOnInit() {
    this.dataManagement.currentSetupScreen = this;
    this.setupService.updateCurrentSetupStep(4);
    if (this.router.url.includes('/plan')) {
      this.readonly = true;
    }
  }

  // displays side component with all transactions for the category-subcategory pair
  public showTransactions({ wizeFiCategory, transactionsYearMonth }) {
    const parts = this.dataModelService.categoryManagement.decodeWizeFiCategory(wizeFiCategory);
    this.selectedCategory = parts.category;
    this.selectedSubcategory = parts.subcategory;
    this.selectedAccountID = parts.accountID;
    this.transactionsYearMonth = transactionsYearMonth;

    // the following chunk of code needs to occur after a timeout so that template can
    // register that the component should be rendered
    setTimeout(() => {
      this.transactionsComponent.categoryGroup = parts.category;
      this.transactionsComponent.categoryKey = parts.subcategory;
      this.transactionsComponent.accountID = parts.accountID;
      this.transactionsComponent.ngOnInit();
    });
  }

  public hideTransactions() {
    this.selectedCategory = '';
    this.selectedSubcategory = '';
    this.selectedAccountID = '';
  }

  public canSave() {
    return true; // for convenience during development
    // return !this.verifySpendingComponent.hasAnyUncategorizedTransactions()
  }

  public onSave() {}

  public showOnSaveErrorMessage(): void {
    this.dataModelService.showMessage('error', 'Please categorize all transactions before continuing', 20000);
  }

  public updateBudgetCategoryItem(category) {
    this.verifySpendingComponent.updateBudgetCategorySum();
  }
}
