import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wizefi-meter',
  templateUrl: './wizefi-meter.component.html',
  styleUrls: ['./wizefi-meter.component.scss']
})
export class WizefiMeterComponent implements OnInit {
  constructor(protected router: Router) {}

  public ngOnInit() {}

  public onNext() {
    this.router.navigate(['/cafr']);
  }
}
