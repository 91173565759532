import { Component, OnInit, ViewChild } from '@angular/core';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { SetupFlowDataManagementService, SetupFlowScreen } from '../../../services/setup/setup-flow-data-management.service';
import { WizefiFFAComponent } from '../wizefi-ffa/wizefi-ffa.component';
import { SetupService } from '@app/services/setup.service';

@Component({
  selector: 'app-projections-screen',
  templateUrl: './projections-screen.component.html',
  styleUrls: ['./projections-screen.component.scss']
})
export class ProjectionsScreenComponent implements SetupFlowScreen, OnInit {
  @ViewChild(WizefiFFAComponent, { static: false })
  public financialFreedomProjection: WizefiFFAComponent;

  constructor(
    protected dataManagement: SetupFlowDataManagementService,
    protected dataModelService: DataModelService,
    private setupService: SetupService
  ) {
    this.dataManagement.currentSetupScreen = this;
  }

  public ngOnInit() {
    this.setupService.updateCurrentSetupStep(6);
    this.updateDateOrigPlanCompleted();
  }

  public canSave() {
    return true;
  }

  public onSave() {}

  private updateDateOrigPlanCompleted() {
    const header = this.dataManagement.dataModelService.dataModel.persistent.header;
    header.dateOrigPlanCompleted = new Date().toISOString();
  }

  public showOnSaveErrorMessage(): void {}
}
