import { Injectable, OnInit } from '@angular/core';
import { DataModelService } from '../data-model/data-model.service';

@Injectable({
  providedIn: 'root'
})
export class SetupFlowDataManagementService {
  // this singleton service should have the currentSetupScreen variable set on each screen of
  // the setup flow, thereby allowing the side-bar "save & continue" button to have access to
  // each screen's specific data saving methods
  public currentSetupScreen: SetupFlowScreen;

  constructor(public dataModelService: DataModelService) {}

  public currentScreenCanSave(): boolean {
    return this.currentSetupScreen && this.currentSetupScreen.canSave();
  }

  public showCurrentScreenErrorMessage() {
    this.currentSetupScreen.showOnSaveErrorMessage();
  }

  public async saveData(): Promise<any> {
    if (this.currentScreenCanSave()) {
      this.currentSetupScreen.onSave();
      await this.dataModelService.dataManagement.storeinfo();
    }
  }
  public async createNewLinkToken() {}
}

export class SetupFlowScreen {
  public canSave(): boolean {
    throw new Error('Method not implemented.');
  }

  public onSave(): void {
    throw new Error('Method not implemented.');
  }

  public showOnSaveErrorMessage(): void {
    throw new Error('Method not implemented.');
  }
}
