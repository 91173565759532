import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataModelService } from '../../services/data-model/data-model.service';
import { NetWorthProjectionComponent } from '../setup-flow/net-worth-projection/net-worth-projection.component';
import { UpdatedProjectionsComponent } from '../updated-projections/updated-projections.component';

@Component({
  selector: 'app-review-projections',
  templateUrl: './review-projections.component.html',
  styleUrls: ['./review-projections.component.scss']
})
export class ReviewProjectionsComponent implements OnInit {
  @ViewChild(UpdatedProjectionsComponent, { static: false })
  public updatedProjections: UpdatedProjectionsComponent;
  public isLoading = true;
  public netWorthDataBeforeEditing: { data: any[]; age: number; wealth?: number; income?: number };
  public debtDataBeforeEditing: { data: any[]; age: number };

  constructor(protected dataModelService: DataModelService, protected router: Router) {}

  public async ngOnInit() {
    this.setCustomStylingForEditScreens();
    this.loadPlanFromBeforeEditing();
    this.isLoading = false;
  }

  private setCustomStylingForEditScreens() {
    document.getElementById('page-content').style.padding = '0';
    document.getElementsByClassName('page-content')[0].className += ' edit-page-content';
    document.getElementById('sidebar').style.display = 'none';
  }

  private loadPlanFromBeforeEditing() {
    // store current, edited plan in a temp variable
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;
    const editedPlan = this.dataModelService.dataModel.persistent.plans[curplan];

    // grab and store plan from database from before it was altered, so that it can
    // be displayed in projection chart
    const dataBeforeEditing = this.getDataBeforeEditingPlan();
    if (dataBeforeEditing) {
      this.netWorthDataBeforeEditing = dataBeforeEditing.netWorth;
      this.debtDataBeforeEditing = JSON.parse(JSON.stringify(dataBeforeEditing.debt));
      this.dataModelService.cafrManagement.dataBeforeEditing = JSON.parse(JSON.stringify(dataBeforeEditing));
      this.notifyProjectionsComponentOfNewData();
      // restore the dataModel to once again contain the edited plan
      this.dataModelService.dataModel.persistent.plans[curplan] = editedPlan;
    }
  }

  protected notifyProjectionsComponentOfNewData() {
    if (this.updatedProjections) {
      this.updatedProjections.dataBeforeEditingPlan = this.netWorthDataBeforeEditing;
      this.updatedProjections.hasReceivedDataFromBeforeEditing = true;
      this.updatedProjections.addDataBeforeEditingPlanIfAvailable();
    }
  }

  protected getDataBeforeEditingPlan(): any {
    let FFData: any = {};
    let FFDate: any = '';
    let FFAge: any = '';
    let debtAge: any = '';
    let debtData: any = {};
    let income: number;
    const plan = this.dataModelService.dataModel.persistent.header.curplan;

    const totalIncome = () => {
      let total: Number = 0;
      this.dataModelService.dataModel.persistent.plans[plan].income.income.accounts.forEach(element => {
        total += element.monthlyAmount.val;
      });
      return total;
    };

    // generate projections data from plan before editing
    const actualDataProjInfo = this.dataModelService.cafrManagement.beforePlanEditCafrDataProjectionInfo;
    if (actualDataProjInfo) {
      // net worth
      FFData = actualDataProjInfo.netWorth.summary.projection;
      FFDate = FFData.slice(-1)[0].x;
      FFAge = this.dateDiff(this.dataModelService.dataModel.persistent.profile.birthDate, FFDate);

      // debt
      debtData = actualDataProjInfo.allDebt.summary.projection;
      const debtZeroCrossDatapoint = debtData.find(item => item.y === 0);

      if (debtZeroCrossDatapoint !== undefined) {
        debtAge = this.dateDiff(this.dataModelService.dataModel.persistent.profile.birthDate, debtZeroCrossDatapoint.x);
      } else {
        debtAge = NetWorthProjectionComponent.MAXIMUM_AGE;
      }
      return {
        netWorth: {
          age: FFAge,
          data: FFData,
          wealth: this.dataModelService.dataModel.persistent.profile.financialFreedomTargetAmount,
          income: totalIncome()
        },
        debt: {
          age: debtAge,
          data: debtData
        }
      };
    } else {
      // grab contents of plan before editing from db
      this.dataModelService.dataManagement.fetchinfo();
    }
  }

  private dateDiff(date1: string, date2: string): any {
    const a = new Date(date1);
    const b = new Date(date2);
    const msYr = 1000 * 60 * 60 * 24 * 365;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.round(((utc2 - utc1) / msYr) * 100) / 100;
  }

  public ngOnDestroy(): void {
    this.removeCustomStylingForEditScreens();
  }

  private removeCustomStylingForEditScreens() {
    document.getElementById('page-content').style.backgroundColor = '';
    document.getElementById('sidebar').style.display = '';
    document.getElementsByClassName('page-content')[0].classList.remove('edit-page-content');
  }
}
