import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ChartsModule } from 'ng2-charts';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ToastyModule } from 'ng2-toasty';
import { SettingsDeactivateGuard } from '../components/settings/settings-deactivate.guard';
import { DataModelService } from '../services/data-model/data-model.service';
import { LogMessageService } from '../services/log-message/log-message.service';
import { ScreenLoginGuard } from '../utilities/screen-login.guard';
import { SecureScreenGuard } from '../utilities/secure-screen.guard';

import { AdminDebugUserDataComponent } from '../components/admin/admin-debug-user-data/admin-debug-user-data.component';
import { AdminManageScreensComponent } from '../components/admin/admin-manage-screens/admin-manage-screens.component';
import { AdminManageSuggestionsComponent } from '../components/admin/admin-manage-suggestions/admin-manage-suggestions.component';
import { AdminManageTreeComponent } from '../components/admin/admin-manage-tree/admin-manage-tree.component';
import { AdminRunTestsComponent } from '../components/admin/admin-run-tests/admin-run-tests.component';
import { AdminTestPlaidComponent } from '../components/admin/admin-test-plaid/admin-test-plaid.component';

import { MultipleInstitutionInstancesComponent } from '../components/multiple-institution-instances/multiple-institution-instances.component';
import { SetCountryCodesComponent } from '../components/set-country-codes/set-country-codes.component';
import { SetCurrencyCodeComponent } from '../components/set-currency-code/set-currency-code.component';

import { AdminTestYodleeComponent } from '../components/admin/admin-test-yodlee/admin-test-yodlee.component';
import { AdminViewBasicDataComponent } from '../components/admin/admin-view-basic-data/admin-view-basic-data.component';
import { AdminViewBudgetDataComponent } from '../components/admin/admin-view-budget-data/admin-view-budget-data.component';
import { AdminViewErrorLogComponent } from '../components/admin/admin-view-error-log/admin-view-error-log.component';
import { CustomErrorHandler } from '../utilities/custom-error-handler.class';
import { SharedModule } from '../shared/shared.module';
import { AppRoutingModule } from './routing.module';

@NgModule({
  declarations: [
    AdminDebugUserDataComponent,
    AdminTestYodleeComponent,
    AdminTestPlaidComponent,
    AdminManageSuggestionsComponent,
    SetCurrencyCodeComponent,
    SetCountryCodesComponent,
    MultipleInstitutionInstancesComponent,
    AdminViewBasicDataComponent,
    AdminViewBudgetDataComponent,
    AdminViewErrorLogComponent,
    AdminManageScreensComponent,
    AdminManageTreeComponent,
    AdminRunTestsComponent
  ],
  imports: [BrowserModule, FormsModule, AppRoutingModule, ChartsModule, ToastyModule.forRoot(), SharedModule],
  providers: [
    DataModelService,
    LogMessageService,
    SettingsDeactivateGuard,
    ScreenLoginGuard,
    SecureScreenGuard,
    { provide: ErrorHandler, useClass: CustomErrorHandler } // this leads to error: Cannot instantiate cyclic dependency
  ]
})
export class AdminModule {}
