// set-country-codes.component.ts

import { Component, OnInit } from '@angular/core';
import { DataModelService } from '../../services/data-model/data-model.service';

@Component({
  selector: 'app-set-country-codes',
  templateUrl: './set-country-codes.component.html',
  styleUrls: ['./set-country-codes.component.scss']
})
export class SetCountryCodesComponent implements OnInit {
  public selectedCountryCodes: string[];
  public oldUserCountryCodes: string[];
  public countryCodeItems: any;
  public plaidCountryCodeItems: any;
  public countryCode2countryCodeItem: any;
  public countryCheckboxStatus: any;

  constructor(public dataModelService: DataModelService) {}

  public ngOnInit() {
    this.selectedCountryCodes = this.getSelectedCountryCodes();
    this.oldUserCountryCodes = JSON.parse(JSON.stringify(this.selectedCountryCodes));
    this.countryCodeItems = this.dataModelService.ancillaryDataManagement.countryCodeItems;
    this.plaidCountryCodeItems = this.dataModelService.ancillaryDataManagement.plaidCountryCodeItems;
    this.countryCode2countryCodeItem = this.dataModelService.ancillaryDataManagement.countryCode2countryCodeItem;
    this.countryCheckboxStatus = this.getCountryCheckboxStatus(this.selectedCountryCodes, this.plaidCountryCodeItems);
  } // ngOnInit

  public getSelectedCountryCodes() {
    let selectedCountryCodes: any;

    selectedCountryCodes = !this.dataModelService.dataModel.persistent.settings.hasOwnProperty('userCountryCodes')
      ? ['US']
      : this.dataModelService.dataModel.persistent.settings.userCountryCodes;

    return selectedCountryCodes;
  } // getSelectedCountryCodes

  public getCountryCheckboxStatus(selectedCountryCodes, plaidCountryCodeItems) {
    const countryCheckboxStatus = [];
    for (const plaidCountryCodeItem of plaidCountryCodeItems) {
      countryCheckboxStatus.push(selectedCountryCodes.indexOf(plaidCountryCodeItem.countryCode) !== -1 ? true : false);
    }
    return countryCheckboxStatus;
  } // getCountryCheckboxStatus

  public processCountryCheckboxStatus(i) {
    const countryCodeCompare = (a, b) => {
      const aCountryName = this.countryCode2countryCodeItem[a].countryName;
      const bCountryName = this.countryCode2countryCodeItem[b].countryName;

      const result = aCountryName === bCountryName ? 0 : aCountryName > bCountryName ? 1 : -1;

      return result;
    }; // countryCodeCompare

    if (this.countryCheckboxStatus[i]) {
      // add new countryCode to current selected countries
      this.selectedCountryCodes.push(this.plaidCountryCodeItems[i].countryCode);
      this.selectedCountryCodes.sort(countryCodeCompare);
    } else {
      // remove country from current selected countries
      let ndx = this.selectedCountryCodes.length;
      while (--ndx >= 0 && this.selectedCountryCodes[ndx] !== this.plaidCountryCodeItems[i].countryCode) {}
      if (ndx >= 0) {
        this.selectedCountryCodes.splice(ndx, 1);
      }
    }
  } // processCountryCheckboxStatus

  public exitSetCountryCodes() {
    if (this.selectedCountryCodes.length < 1) {
      //console.log("You must select at least one country");
      this.dataModelService.showMessage('error', 'You must select at least one country', 10000);
    } else {
      // note: there is no need to return a value from this screen, since there is only one place to store the result
      //console.log("oldUserCountryCodes: ", this.oldUserCountryCodes);  // %//
      //console.log("selectedCountryCodes: ", this.selectedCountryCodes);  // %//
      if (JSON.stringify(this.selectedCountryCodes) !== JSON.stringify(this.oldUserCountryCodes)) {
        this.oldUserCountryCodes = JSON.parse(JSON.stringify(this.selectedCountryCodes));
        this.dataModelService.dataModel.persistent.settings.userCountryCodes = this.selectedCountryCodes;

        // note: await is not used below because memory resident data is already updated,
        // and any time delay in DynamoDB update will not affect anything downstream (TODO -- review accuracy of this)
        this.dataModelService.dataManagement.storeinfo();
        //console.log("selectedCountryCodes have been updated in DynamoDB");  // %//
      }
    }
  } // exitSetCountryCodes
} // class SetCountryCodes
