import { Router } from '@angular/router';

export class Nav {
  public selectedScreen: string;
  public screenList: any;
  public wantNavigation: boolean;

  constructor(private router: Router) {
    this.wantNavigation = false;
    this.setScreenInfo();
  } // constructor

  private setScreenInfo() {
    this.selectedScreen = '';
    this.screenList = [];
    for (const route of this.router.config) {
      const screen = route.path;
      if (screen.length > 0 && screen !== '**') {
        this.screenList.push(screen);
      }
    }
    this.screenList.sort();
  } // setScreenInfo

  public navigate() {
    this.wantNavigation = true;
    this.router.navigateByUrl(this.selectedScreen);
  } // navigate
} // class Nav
