export class SubscriptionManagement {
  constructor(public dataModelService: any) {}

  public generateClientToken(customerId: string = null) {
    return new Promise((resolve, reject) => {
      // set params to guide function invocation
      const payload = customerId === null ? {} : { customerId };
      const params = {
        FunctionName: 'generateClientToken',
        Payload: JSON.stringify(payload)
      };

      // invoke Lambda function to process data
      this.dataModelService.dataModel.global.lambda.invoke(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const clientToken = JSON.parse(data.Payload);
          resolve(clientToken);
        }
      }); // lambda invoke
    }); // return Promise
  } // generateClientToken

  public establishSubscription(subscriptionInfo: any) {
    return new Promise((resolve, reject) => {
      // set params to guide function invocation
      const payload = subscriptionInfo;
      const params = {
        FunctionName: 'establishSubscription2',
        Payload: JSON.stringify(payload)
      };

      // invoke Lambda function to process data
      this.dataModelService.dataModel.global.lambda.invoke(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const funcPayload = JSON.parse(data.Payload);
          resolve(funcPayload);
        }
      }); // lambda invoke
    }); // return Promise
  } // establishSubscription

  public computeAffiliateHistory(dayToProcess: string = null, updateAffiliateCount: boolean = null) {
    return new Promise((resolve, reject) => {
      // set params to guide function invocation
      const payload = {} as any;
      if (dayToProcess) {
        payload.dayToProcess = dayToProcess;
      }
      if (updateAffiliateCount) {
        payload.updateAffiliateCount = updateAffiliateCount;
      }
      const params = {
        FunctionName: 'computeAffiliateHistory',
        Payload: JSON.stringify(payload)
      };

      // invoke Lambda function to process data
      this.dataModelService.dataModel.global.lambda.invoke(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const funcPayload = JSON.parse(data.Payload);
          resolve(funcPayload);
        }
      }); // lambda invoke
    }); // return Promise
  } // computeAffiliateHistory
} // SubscriptionManagement
