import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { WizefiTransaction } from '@app/interfaces/wizefi-transaction';
import { SetupFlowDataManagementService } from 'src/app/services/setup/setup-flow-data-management.service';
import { DataModelService } from '../../../../services/data-model/data-model.service';
import { CategoryTransactionsComponent } from '../../category-transactions/category-transactions.component';
import {
  ManualTransactionDetailsComponent,
  IManualTransactionDialogData
} from '../../manual-transaction-details/manual-transaction-details.component';
import { SpendingScreenComponent } from '../spending-screen.component';
import { SetupService } from '@app/services/setup.service';
@Component({
  selector: 'app-spending',
  templateUrl: './spending.component.html',
  styleUrls: ['./spending.component.scss']
})
export class SpendingComponent extends SpendingScreenComponent implements OnInit {
  @Output() public onRecategorizeTransaction: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('transactions', { static: false }) public categoryTransactionsComponent: CategoryTransactionsComponent;
  protected budget;
  protected actualSpending;
  public isEditingTransaction = false;
  public transactionToEdit: WizefiTransaction;
  protected dialog: MatDialog;

  constructor(
    protected dataModelService: DataModelService,
    protected dataManagement: SetupFlowDataManagementService,
    protected router: Router,
    protected setupService: SetupService
  ) {
    super(dataModelService, dataManagement, router, setupService);
  }

  public getPlannedSpendingPerCategory(budget) {}

  public editTransaction(event) {
    this.isEditingTransaction = true;
    this.transactionToEdit = event.transaction;
    if (this.transactionToEdit.isManual) {
      this.dialog
        .open<ManualTransactionDetailsComponent, IManualTransactionDialogData>(ManualTransactionDetailsComponent, {
          data: { transaction: { ...this.transactionToEdit } }
        })
        .afterClosed()
        .subscribe(result => {
          if (result) {
            this.saveTransaction(this.transactionToEdit);
          } else {
            this.closeModal();
          }
        });
    }
  }

  public saveTransaction(transaction) {
    const newSubcategory = this.dataModelService.categoryManagement.decodeWizeFiCategory(transaction.wizeFiCategory).subcategory;
    this.reloadBudgetCategoryListItem(newSubcategory);
    this.reloadBudgetCategoryListItem(this.selectedSubcategory); // aka old subcategory
    this.closeModal();
    this.categoryTransactionsComponent.ngOnInit();
  }

  private reloadBudgetCategoryListItem(category) {
    this.onRecategorizeTransaction.emit(category);
  }

  public closeModal() {
    this.isEditingTransaction = false;
    this.transactionToEdit = undefined;
  }
}
