// data-model_0001.data.ts

import { IDataModel } from '../../interfaces/iDataModel.interface';

// the following defines attribute names that are skipped over when scanning through subcategory attributes in a for loop.
export const skipSubcatAttributes = ['attributeName', 'label', 'tooltip'];

// the following provides the name of the objectKey for the "picture not available" image
export const pictureNotAvailableObjectKey = 'pictureNotAvailable.png';

// the following identifies values that can appear as a wizeFiCategory for use in special contexts
export const specialWizefiCategory = ['unknown', 'none', 'ignore']; // excluded, rules

export const categoryExcludeList =
  // categories to ignore when scanning through the WizeFi schema
  ['planDataVersion', 'dateTransactionsUpdate', 'assets2'];

export const subcategoryExcludeList =
  // subcategories to ignore when scanning through the WizeFi schema
  ['attributeName', 'label', 'tooltip'];

export const wizeFiPlaidInstitutionSchema = {
  access_tokan: 'unknown',
  item_id: 'unknown',
  institution_id: 'unknown',
  institutionName: 'unknown',
  error: 'none',
  isManual: false,
  isActive: true,
  transactionDate: 'unknown'
};

export const wizeFiPlaidAccountSchema = {
  account_id: 'unknown',
  accountName: 'unknown',
  accountType: 'unknown',
  accountSubtype: 'unknown',
  mask: 'unknown',
  originalBalance: 0,
  originalCurrencyCode: 'unknown',
  balance: 0, // balance = convertCurrency(originalBalance, originalCurrencyCode, userCurrencyCode)
  institutionName: 'unknown',
  institution_id: 'unknown',
  item_id: 'unknown',
  status: 0,
  isActive: false,
  isManual: false,
  wizeFiCategory: 'none'
};

export const wizeFiTransactionSchema = {
  transaction_id: 'unknown',
  originalAmount: 0,
  originalCurrencyCode: 'unknown',
  amount: 0, // amount = convertCurrency(originalAmount, originalCurrencyCode, userCurrencyCode)
  date: 'unknown',
  category: 'unknown',
  account_id: 'unknown',
  merchantName: 'unknown',
  accountName: 'unknown',
  institutionName: 'unknown',
  wizeFiCategory: 'unknown',
  isManual: false,
  splitStatus: 0,
  ruleApplied: false
};

/*
The following defines all possible field names.  NOTE: it is possible that not all possibleFieldNames have been added below
*/
export const possibleFieldNames: any = {
  accountID: { label: 'Account ID', val: 'ID000' }, // unique identifier for an account
  accountName: { label: 'Account Name', val: '' },
  accountType: { label: 'Account Type', val: '' },
  accountValue: { label: 'Account Value', val: 0 },
  balanceDate: { label: 'Balance Date', val: '' },
  monthlyAmount: { label: 'Monthly Amount', val: 0 },
  actualMonthlyAmount: { label: 'Actual Monthly Amount', val: 0 },
  genericCategory: { label: 'Generic Category', val: 'none' },
  monthlyMinimum: { label: 'Monthly Minimum', val: 0 },
  employerContribution: { label: 'Employer Contribution', val: 0 },
  interestRate: { label: 'Interest Rate', val: 0 },
  coverageAmount: { label: 'Coverage amount', val: 0 },
  expirationDate: { label: 'Expiration Date', val: '' }, // term life insurance
  accountLimit: { label: 'Credit Card Limit', val: 0 },
  productivity: { label: 'Productivity', val: '' },
  budgetSubcategory: { label: 'Budget Category', val: '' },
  incomeSubcategory: { label: 'Income Category', val: '' },
  monthlyIncome: { label: 'Monthly Income', val: 0 },
  targetAmount: { label: 'Target Amount', val: 0 },
  totalAmountOwed: { label: 'Total Amount Owed', val: 0 },
  accountSubcategoryName: { label: 'Account Subcategory Name', val: '' } // name of account that identifies back-end subcategory
}; // possibleFieldNames

/*
The following defines all possible account types (the JavaScript attribute name is the name of the type).
Each type is a distinct list of field names that are required for a particular account under a given subaccount.
The presence or absence of a field, and the value of the isRequired attribute are the things that distinguish one type from another.
*/
export const accountTypes = {
  income: {
    /* Hidden Fields */
    accountID: { label: 'Account ID', isRequired: true, val: 'ID000' },
    tooltip: { label: 'Tooltip', isRequired: false, val: '' },
    isRequired: { label: 'Required', val: false },
    isEditable: { label: 'isEditable', isRequired: true, val: true },
    accountType: { label: 'Account Type', isRequired: true, val: 'income' },
    accountSubcategoryName: {
      label: 'Account Subcategory Name',
      isRequired: true,
      val: ''
    },

    /* Editable Fields */
    accountName: { label: 'Account Name', isRequired: true, val: '' },
    monthlyAmount: { label: 'Monthly Amount', isRequired: true, val: 0 },
    actualMonthlyAmount: {
      label: 'Actual Monthly Amount',
      isRequired: true,
      val: 0
    },
    genericCategory: {
      label: 'Generic Category',
      isRequired: true,
      val: 'none'
    },
    wizeFiCategory: { label: 'WizeFi Category', isRequired: true, val: 'none' },

    federalTax: { label: 'Federal Tax', isRequired: true, val: 0 },
    stateTax: { label: 'State Tax', isRequired: true, val: 0 },
    localTax: { label: 'Local Tax', isRequired: true, val: 0 },
    ficaTax: { label: 'FICA', isRequired: true, val: 0 },
    childSupport: { label: 'Child Support', isRequired: true, val: 0 },
    grossMonthlyIncome: {
      label: 'Gross Monthly Income',
      isRequired: true,
      val: 0
    }
  },
  expenses: {
    /* Hidden Fields */
    accountID: { label: 'Account ID', isRequired: true, val: 'ID000' },
    tooltip: { label: 'Tooltip', isRequired: false, val: '' },
    isRequired: { label: 'Required', val: false },
    isEditable: { label: 'isEditable', isRequired: true, val: true },
    accountType: { label: 'Account Type', isRequired: true, val: 'expenses' },
    accountSubcategoryName: {
      label: 'Account Subcategory Name',
      isRequired: true,
      val: ''
    },

    /* Editable Fields */
    accountName: { label: 'Account Name', isRequired: true, val: '' },
    monthlyAmount: { label: 'Monthly Amount', isRequired: true, val: 0 },
    actualMonthlyAmount: {
      label: 'Actual Monthly Amount',
      isRequired: true,
      val: 0
    },
    genericCategory: {
      label: 'Generic Category',
      isRequired: true,
      val: 'none'
    },
    wizeFiCategory: { label: 'WizeFi Category', isRequired: true, val: 'none' }
  },
  savingsRequired: {
    /* Hidden Fields */
    accountID: { label: 'Account ID', isRequired: true, val: 'ID000' },
    tooltip: { label: 'Tooltip', isRequired: false, val: '' },
    isRequired: { label: 'Required', val: false },
    isEditable: { label: 'isEditable', isRequired: true, val: true },
    accountType: {
      label: 'Account Type',
      isRequired: true,
      val: 'savingsRequired'
    },
    accountSubcategoryName: {
      label: 'Account Subcategory Name',
      isRequired: true,
      val: ''
    },

    /* Editable Fields */
    accountName: { label: 'Account Name', isRequired: true, val: '' },
    interestRate: {
      label: 'Est. Growth Rate',
      isRequired: false,
      val: 0.01,
      tooltip: 'Enter value as an annual percentage rate, e.g. 4.25.'
    },
    accountValue: { label: 'Account Balance', isRequired: true, val: 0 },
    balanceDate: { label: 'Balance Date', isRequired: true, val: '' },
    employerContribution: {
      label: 'Employer Contribution',
      isRequired: true,
      val: 0
    },
    monthlyAmount: { label: 'Monthly Contribution', isRequired: true, val: 0 },
    actualMonthlyAmount: {
      label: 'Actual Monthly Amount',
      isRequired: true,
      val: 0
    },
    targetAmount: {
      label: 'Target Amount',
      isRequired: true,
      val: 0
    },
    productivity: {
      label: 'Productivity',
      isRequired: true,
      val: 'Productive'
    },
    genericCategory: {
      label: 'Generic Category',
      isRequired: true,
      val: 'none'
    },
    wizeFiCategory: { label: 'WizeFi Category', isRequired: true, val: 'none' }
  },
  savingsOptional: {
    /* Hidden Fields */
    accountID: { label: 'Account ID', isRequired: true, val: 'ID000' },
    tooltip: { label: 'Tooltip', isRequired: false, val: '' },
    isRequired: { label: 'Required', val: false },
    isEditable: { label: 'isEditable', isRequired: true, val: true },
    accountType: {
      label: 'Account Type',
      isRequired: true,
      val: 'savingsOptional'
    },
    accountSubcategoryName: {
      label: 'Account Subcategory Name',
      isRequired: true,
      val: ''
    },

    /* Editable Fields */
    accountName: { label: 'Account Name', isRequired: true, val: '' },
    interestRate: {
      label: 'Est. Growth Rate',
      isRequired: false,
      val: 0.01,
      tooltip: 'Enter value as an annual percentage rate, e.g. 4.25.'
    },
    accountValue: { label: 'Account Balance', isRequired: true, val: 0 },
    balanceDate: { label: 'Balance Date', isRequired: true, val: '' },
    employerContribution: {
      label: 'Employer Contribution',
      isRequired: true,
      val: 0
    },
    monthlyAmount: { label: 'Monthly Contribution', isRequired: true, val: 0 },
    actualMonthlyAmount: {
      label: 'Actual Monthly Amount',
      isRequired: true,
      val: 0
    },
    targetAmount: {
      label: 'Target Amount',
      isRequired: true,
      val: 0
    },
    productivity: {
      label: 'Productivity',
      isRequired: true,
      val: 'Productive'
    },
    genericCategory: {
      label: 'Generic Category',
      isRequired: true,
      val: 'none'
    },
    wizeFiCategory: { label: 'WizeFi Category', isRequired: true, val: 'none' }
  },
  checking: {
    /* Hidden Fields */
    accountID: { label: 'Account ID', isRequired: true, val: 'ID000' },
    tooltip: { label: 'Tooltip', isRequired: false, val: '' },
    isRequired: { label: 'Required', val: false },
    isEditable: { label: 'isEditable', isRequired: true, val: true },
    accountType: { label: 'Account Type', isRequired: true, val: 'checking' },
    accountSubcategoryName: {
      label: 'Account Subcategory Name',
      isRequired: true,
      val: ''
    },

    /* Editable Fields */
    accountName: { label: 'Account Name', isRequired: true, val: '' },
    interestRate: {
      label: 'Est. Growth Rate',
      isRequired: false,
      val: 0.0,
      tooltip: 'Enter value as an annual percentage rate, e.g. 4.25.'
    },
    credits: { label: 'Credits', isRequired: true, val: 0 },
    debits: { label: 'Debits', isRequired: true, val: 0 },
    interestPaid: { label: 'InterestPaid', isRequired: true, val: 0 },
    fees: { label: 'Fees', isRequired: true, val: 0 },
    accountValue: { label: 'Account Balance', isRequired: true, val: 0 },
    balanceDate: { label: 'Balance Date', isRequired: true, val: '' },
    actualMonthlyAmount: {
      label: 'Actual Monthly Amount',
      isRequired: true,
      val: 0
    },
    productivity: {
      label: 'Productivity',
      isRequired: true,
      val: 'Productive'
    },
    genericCategory: {
      label: 'Generic Category',
      isRequired: true,
      val: 'none'
    },
    wizeFiCategory: { label: 'WizeFi Category', isRequired: true, val: 'none' }
  },
  investments: {
    /* Hidden Fields */
    accountID: { label: 'Account ID', isRequired: true, val: 'ID000' },
    tooltip: { label: 'Tooltip', isRequired: false, val: '' },
    isRequired: { label: 'Required', val: false },
    isEditable: { label: 'isEditable', isRequired: true, val: true },
    accountType: {
      label: 'Account Type',
      isRequired: true,
      val: 'investments'
    },
    accountSubcategoryName: {
      label: 'Account Subcategory Name',
      isRequired: true,
      val: ''
    },
    incomeSubcategory: {
      label: 'Income Category',
      isRequired: true,
      val: 'income',
      tooltip: 'Income Category indicates this information will be carried over to another area of the WizeFi Wealth Building program.'
    },

    /* Editable Fields */
    accountName: { label: 'Account Name', isRequired: true, val: '' },
    interestRate: {
      label: 'Est. Growth Rate',
      isRequired: true,
      val: 7.5,
      tooltip: 'Enter value as an annual percentage rate, e.g. 4.25.'
    },
    monthlyIncome: {
      label: 'Monthly Income',
      isRequired: true,
      val: 0,
      tooltip: 'Enter amount of monthly income you receive from this account, if any.'
    },
    accountValue: { label: 'Account Balance', isRequired: true, val: 0 },
    balanceDate: { label: 'Balance Date', isRequired: true, val: '' },
    employerContribution: {
      label: 'Employer Contribution',
      isRequired: false,
      val: 0
    },
    monthlyAmount: { label: 'Monthly Contributon', isRequired: true, val: 0 },
    actualMonthlyAmount: {
      label: 'Actual Monthly Amount',
      isRequired: true,
      val: 0
    },
    productivity: {
      label: 'Productivity',
      isRequired: true,
      val: 'Productive'
    },
    genericCategory: {
      label: 'Generic Category',
      isRequired: true,
      val: 'none'
    },
    wizeFiCategory: { label: 'WizeFi Category', isRequired: true, val: 'none' }
  },
  residenceProperty: {
    /* Hidden Fields */
    accountID: { label: 'Account ID', isRequired: true, val: 'ID000' },
    tooltip: { label: 'Tooltip', isRequired: false, val: '' },
    isRequired: { label: 'Required', val: false },
    isEditable: { label: 'isEditable', isRequired: true, val: true },
    accountType: {
      label: 'Account Type',
      isRequired: true,
      val: 'residenceProperty'
    },
    accountSubcategoryName: {
      label: 'Account Subcategory Name',
      isRequired: true,
      val: ''
    },

    /* Editable Fields */
    accountName: { label: 'Account Name', isRequired: true, val: '' },
    interestRate: {
      label: 'Est. Growth Rate',
      isRequired: true,
      val: 3,
      tooltip: 'Enter value as an annual percentage rate, e.g. 4.25.'
    },
    accountValue: { label: 'Est. Value', isRequired: true, val: 0 },
    balanceDate: { label: 'Balance Date', isRequired: true, val: '' },
    actualMonthlyAmount: {
      label: 'Actual Monthly Amount',
      isRequired: true,
      val: 0
    },
    productivity: {
      label: 'Productivity',
      isRequired: true,
      val: 'Non-productive'
    },
    genericCategory: {
      label: 'Generic Category',
      isRequired: true,
      val: 'none'
    },
    wizeFiCategory: { label: 'WizeFi Category', isRequired: true, val: 'none' }
  },
  investmentProperty: {
    /* Hidden Fields */
    accountID: { label: 'Account ID', isRequired: true, val: 'ID000' },
    tooltip: { label: 'Tooltip', isRequired: false, val: '' },
    isRequired: { label: 'Required', val: false },
    isEditable: { label: 'isEditable', isRequired: true, val: true },
    accountType: {
      label: 'Account Type',
      isRequired: true,
      val: 'investmentProperty'
    },
    incomeSubcategory: {
      label: 'Income Category',
      isRequired: true,
      val: 'income',
      tooltip: 'Income Category indicates this information will be carried over to another area of the WizeFi Wealth Building program.'
    },
    accountSubcategoryName: {
      label: 'Account Subcategory Name',
      isRequired: true,
      val: ''
    },

    /* Editable Fields */
    accountName: { label: 'Account Name', isRequired: true, val: '' },
    interestRate: {
      label: 'Est. Growth Rate',
      isRequired: true,
      val: 3,
      tooltip: 'Enter value as an annual percentage rate, e.g. 4.25.'
    },
    monthlyIncome: {
      label: 'Net Monthly Income',
      isRequired: true,
      val: 0,
      tooltip:
        'Only enter the net amount you receive after all expenses on property are paid, including mortgage, tax, insurance, utilities, etc. ' +
        'And don’t forget to account for vacancies by averaging out your annual net income.'
    },
    accountValue: { label: 'Est. Value', isRequired: true, val: 0 },
    balanceDate: { label: 'Balance Date', isRequired: true, val: '' },
    actualMonthlyAmount: {
      label: 'Actual Monthly Amount',
      isRequired: true,
      val: 0
    },
    totalAmountOwed: {
      label: 'Total Amount Owed',
      isRequired: true,
      val: 0
    },
    productivity: {
      label: 'Productivity',
      isRequired: true,
      val: 'Productive'
    },
    genericCategory: {
      label: 'Generic Category',
      isRequired: true,
      val: 'none'
    },
    wizeFiCategory: { label: 'WizeFi Category', isRequired: true, val: 'none' }
  },
  property: {
    /* Hidden Fields */
    accountID: { label: 'Account ID', isRequired: true, val: 'ID000' },
    tooltip: { label: 'Tooltip', isRequired: false, val: '' },
    isRequired: { label: 'Required', val: false },
    isEditable: { label: 'isEditable', isRequired: true, val: true },
    accountType: { label: 'Account Type', isRequired: true, val: 'property' },
    incomeSubcategory: {
      label: 'Income Category',
      isRequired: true,
      val: 'income',
      tooltip: 'Income Category indicates this information will be carried over to another area of the WizeFi Wealth Building program.'
    },
    budgetSubcategory: {
      label: 'Budget Category',
      isRequired: false,
      val: '',
      tooltip: 'Budget Category indicates this information will be carried over to another area of the WizeFi Wealth Building program.'
    },
    accountSubcategoryName: {
      label: 'Account Subcategory Name',
      isRequired: true,
      val: ''
    },

    /* Editable Fields */
    accountName: { label: 'Account Name', isRequired: true, val: '' },
    interestRate: {
      label: 'Est. Growth Rate',
      isRequired: true,
      val: 3,
      tooltip: 'Enter value as an annual percentage rate, e.g. 4.25.'
    },
    monthlyIncome: {
      label: 'Monthly Income',
      isRequired: true,
      val: 0,
      tooltip: 'Enter amount of monthly income you receive from this account, if any.'
    },
    accountValue: { label: 'Property Value', isRequired: true, val: 0 },
    balanceDate: { label: 'Balance Date', isRequired: true, val: '' },
    actualMonthlyAmount: {
      label: 'Actual Monthly Amount',
      isRequired: true,
      val: 0
    },
    productivity: {
      label: 'Productivity',
      isRequired: true,
      val: 'Productive'
    },
    genericCategory: {
      label: 'Generic Category',
      isRequired: true,
      val: 'none'
    },
    wizeFiCategory: { label: 'WizeFi Category', isRequired: true, val: 'none' }
  },
  personalProperty: {
    /* Hidden Fields */
    accountID: { label: 'Account ID', isRequired: true, val: 'ID000' },
    tooltip: { label: 'Tooltip', isRequired: false, val: '' },
    isRequired: { label: 'Required', val: false },
    isEditable: { label: 'isEditable', isRequired: true, val: true },
    accountType: {
      label: 'Account Type',
      isRequired: true,
      val: 'personalProperty'
    },
    incomeSubcategory: {
      label: 'Income Category',
      isRequired: true,
      val: 'income',
      tooltip: 'Income Category indicates this information will be carried over to another area of the WizeFi Wealth Building program.'
    },
    budgetSubcategory: {
      label: 'Budget Category',
      isRequired: false,
      val: '',
      tooltip: 'Budget Category indicates this information will be carried over to another area of the WizeFi Wealth Building program.'
    },
    accountSubcategoryName: {
      label: 'Account Subcategory Name',
      isRequired: true,
      val: ''
    },

    /* Editable Fields */
    accountName: { label: 'Account Name', isRequired: true, val: '' },
    interestRate: {
      label: 'Est. Appreciation/Depreciation Rate',
      isRequired: true,
      val: 3,
      tooltip: 'Enter value as an annual percentage rate, e.g. 4.25.'
    },
    monthlyIncome: {
      label: 'Monthly Income',
      isRequired: true,
      val: 0,
      tooltip: 'Enter amount of monthly income you receive from this account, if any.'
    },
    accountValue: { label: 'Property Value', isRequired: true, val: 0 },
    balanceDate: { label: 'Balance Date', isRequired: true, val: '' },
    actualMonthlyAmount: {
      label: 'Actual Monthly Amount',
      isRequired: true,
      val: 0
    },
    productivity: {
      label: 'Productivity',
      isRequired: true,
      val: 'Productive'
    },
    genericCategory: {
      label: 'Generic Category',
      isRequired: true,
      val: 'none'
    },
    wizeFiCategory: { label: 'WizeFi Category', isRequired: true, val: 'none' }
  },
  otherInsurance: {
    /* Hidden Fields */
    accountID: { label: 'Account ID', isRequired: true, val: 'ID000' },
    tooltip: { label: 'Tooltip', isRequired: false, val: '' },
    isRequired: { label: 'Required', val: false },
    isEditable: { label: 'isEditable', isRequired: true, val: true },
    accountType: {
      label: 'Account Type',
      isRequired: true,
      val: 'otherInsurance'
    },
    budgetSubcategory: {
      label: 'Budget Category',
      isRequired: true,
      val: 'health',
      tooltip: 'Budget Category indicates this information will be carried over to another area of the WizeFi Wealth Building program.'
    },
    accountSubcategoryName: {
      label: 'Account Subcategory Name',
      isRequired: true,
      val: ''
    },

    /* Editable Fields */
    accountName: { label: 'Account Name', isRequired: true, val: '' },
    coverageAmount: { label: 'Coverage Amount', isRequired: true, val: 0 },
    monthlyMinimum: { label: 'Monthly Premium', isRequired: true, val: 0 },
    actualMonthlyAmount: {
      label: 'Actual Monthly Amount',
      isRequired: true,
      val: 0
    },
    genericCategory: {
      label: 'Generic Category',
      isRequired: true,
      val: 'none'
    },
    wizeFiCategory: { label: 'WizeFi Category', isRequired: true, val: 'none' }
  },
  permInsurance: {
    /* Hidden Fields */
    accountID: { label: 'Account ID', isRequired: true, val: 'ID000' },
    tooltip: { label: 'Tooltip', isRequired: false, val: '' },
    isRequired: { label: 'Required', val: false },
    isEditable: { label: 'isEditable', isRequired: true, val: true },
    accountType: {
      label: 'Account Type',
      isRequired: true,
      val: 'permInsurance'
    },
    budgetSubcategory: {
      label: 'Budget Category',
      isRequired: true,
      val: 'health',
      tooltip: 'Budget Category indicates this information will be carried over to another area of the WizeFi Wealth Building program.'
    },
    accountSubcategoryName: {
      label: 'Account Subcategory Name',
      isRequired: true,
      val: ''
    },

    /* Editable Fields */
    accountName: { label: 'Account Name', isRequired: true, val: '' },
    interestRate: {
      label: 'Est. Annual Yield',
      isRequired: true,
      val: 4,
      tooltip: 'Your assumed growth rate for cash value of this account. Enter value as an annual percentage rate, e.g. 4.25.'
    },
    coverageAmount: {
      label: 'Death Benefit',
      isRequired: true,
      val: 0,
      tooltip: 'The actual benefit paid when the insured dies.'
    },
    accountValue: { label: 'Cash Surrender Value', isRequired: true, val: 0 },
    balanceDate: { label: 'Balance Date', isRequired: true, val: '' },
    monthlyMinimum: {
      label: 'Monthly Premium',
      isRequired: true,
      val: 0,
      tooltip: 'Include monthly premium amount only.'
    },
    monthlyAmount: {
      label: 'Cash Contribution',
      isRequired: true,
      val: 0,
      tooltip: 'Include premium amount plus any savings contributions you contribute each month on average.'
    },
    actualMonthlyAmount: {
      label: 'Actual Monthly Amount',
      isRequired: true,
      val: 0
    },
    genericCategory: {
      label: 'Generic Category',
      isRequired: true,
      val: 'none'
    },
    wizeFiCategory: { label: 'WizeFi Category', isRequired: true, val: 'none' }
  },
  termInsurance: {
    /* Hidden Fields */
    accountID: { label: 'Account ID', isRequired: true, val: 'ID000' },
    tooltip: { label: 'Tooltip', isRequired: false, val: '' },
    isRequired: { label: 'Required', val: false },
    isEditable: { label: 'isEditable', isRequired: true, val: true },
    accountType: {
      label: 'Account Type',
      isRequired: true,
      val: 'termInsurance'
    },
    budgetSubcategory: {
      label: 'Budget Category',
      isRequired: true,
      val: 'health',
      tooltip: 'Budget Category indicates this information will be carried over to another area of the WizeFi Wealth Building program.'
    },
    accountSubcategoryName: {
      label: 'Account Subcategory Name',
      isRequired: true,
      val: ''
    },

    /* Editable Fields */
    accountName: { label: 'Account Name', isRequired: true, val: '' },
    expirationDate: {
      label: 'Date Policy Expires',
      isRequired: false,
      val: ''
    },
    coverageAmount: { label: 'Coverage Amount', isRequired: true, val: 0 },
    monthlyMinimum: { label: 'Monthly Premium', isRequired: true, val: 0 },
    actualMonthlyAmount: {
      label: 'Actual Monthly Amount',
      isRequired: true,
      val: 0
    },
    genericCategory: {
      label: 'Generic Category',
      isRequired: true,
      val: 'none'
    },
    wizeFiCategory: { label: 'WizeFi Category', isRequired: true, val: 'none' }
  },
  homeInsurance: {
    /* Hidden Fields */
    accountID: { label: 'Account ID', isRequired: true, val: 'ID000' },
    tooltip: { label: 'Tooltip', isRequired: false, val: '' },
    isRequired: { label: 'Required', val: false },
    isEditable: { label: 'isEditable', isRequired: true, val: true },
    accountType: {
      label: 'Account Type',
      isRequired: true,
      val: 'homeInsurance'
    },
    budgetSubcategory: {
      label: 'Budget Category',
      isRequired: true,
      val: 'housing',
      tooltip: 'Budget Category indicates this information will be carried over to another area of the WizeFi Wealth Building program.'
    },
    accountSubcategoryName: {
      label: 'Account Subcategory Name',
      isRequired: true,
      val: ''
    },

    /* Editable Fields */
    accountName: { label: 'Account Name', isRequired: true, val: '' },
    coverageAmount: { label: 'Coverage Amount', isRequired: true, val: 0 },
    monthlyMinimum: { label: 'Monthly Premium', isRequired: true, val: 0 },
    actualMonthlyAmount: {
      label: 'Actual Monthly Amount',
      isRequired: true,
      val: 0
    },
    genericCategory: {
      label: 'Generic Category',
      isRequired: true,
      val: 'none'
    },
    wizeFiCategory: { label: 'WizeFi Category', isRequired: true, val: 'none' }
  },
  autoInsurance: {
    /* Hidden Fields */
    accountID: { label: 'Account ID', isRequired: true, val: 'ID000' },
    tooltip: { label: 'Tooltip', isRequired: false, val: '' },
    isRequired: { label: 'Required', val: false },
    isEditable: { label: 'isEditable', isRequired: true, val: true },
    accountType: {
      label: 'Account Type',
      isRequired: true,
      val: 'autoInsurance'
    },
    budgetSubcategory: {
      label: 'Budget Category',
      isRequired: true,
      val: 'transportation',
      tooltip: 'Budget Category indicates this information will be carried over to another area of the WizeFi Wealth Building program.'
    },
    accountSubcategoryName: {
      label: 'Account Subcategory Name',
      isRequired: true,
      val: ''
    },

    /* Editable Fields */
    accountName: { label: 'Account Name', isRequired: true, val: '' },
    monthlyMinimum: { label: 'Monthly Premium', isRequired: true, val: 0 },
    actualMonthlyAmount: {
      label: 'Actual Monthly Amount',
      isRequired: true,
      val: 0
    },
    genericCategory: {
      label: 'Generic Category',
      isRequired: true,
      val: 'none'
    },
    wizeFiCategory: { label: 'WizeFi Category', isRequired: true, val: 'none' }
  },
  creditCard: {
    /* Hidden Fields */
    accountID: { label: 'Account ID', isRequired: true, val: 'ID000' },
    tooltip: { label: 'Tooltip', isRequired: false, val: '' },
    isRequired: { label: 'Required', val: false },
    isEditable: { label: 'isEditable', isRequired: true, val: true },
    accountType: { label: 'Account Type', isRequired: true, val: 'creditCard' },
    budgetSubcategory: {
      label: 'Budget Category',
      isRequired: false,
      val: '',
      tooltip: 'Budget Category indicates this information will be carried over to another area of the WizeFi Wealth Building program.'
    },
    accountSubcategoryName: {
      label: 'Account Subcategory Name',
      isRequired: true,
      val: ''
    },

    /* Editable Fields */
    accountName: { label: 'Account Name', isRequired: true, val: '' },
    interestRate: { label: 'APR Interest Rate', isRequired: true, val: 17.9 },
    accountValue: { label: 'Account Balance', isRequired: true, val: 0 },
    balanceDate: { label: 'Balance Date', isRequired: true, val: '' },
    accountLimit: { label: 'Credit Card Limit', isRequired: true, val: 0 },
    monthlyMinimum: { label: 'Monthly Minimum', isRequired: true, val: 0 },
    monthlyAmount: {
      label: 'Monthly Total',
      isRequired: true,
      val: 0,
      tooltip: 'Monthly Minimum + Extra you pay = Monthly Total'
    },
    actualMonthlyAmount: {
      label: 'Actual Monthly Amount',
      isRequired: true,
      val: 0
    },
    productivity: {
      label: 'Productivity',
      isRequired: true,
      val: 'Non-productive',
      tooltip: 'Non-productive debt is paid off in Step-2 of the 4-Step plan. Productive Debt is optional and is paid in Step 4'
    },
    genericCategory: {
      label: 'Generic Category',
      isRequired: true,
      val: 'none'
    },
    wizeFiCategory: { label: 'WizeFi Category', isRequired: true, val: 'none' }
  },
  loan: {
    /* Hidden Fields */
    accountID: { label: 'Account ID', isRequired: true, val: 'ID000' },
    tooltip: { label: 'Tooltip', isRequired: false, val: '' },
    isRequired: { label: 'Required', val: false },
    isEditable: { label: 'isEditable', isRequired: true, val: true },
    accountType: { label: 'Account Type', isRequired: true, val: 'loan' },
    budgetSubcategory: {
      label: 'Budget Category',
      isRequired: false,
      val: '',
      tooltip: 'Budget Category indicates this information will be carried over to another area of the WizeFi Wealth Building program.'
    },
    accountSubcategoryName: {
      label: 'Account Subcategory Name',
      isRequired: true,
      val: ''
    },

    /* Editable Fields */
    accountName: { label: 'Account Name', isRequired: true, val: '' },
    interestRate: { label: 'APR Interest Rate', isRequired: true, val: 6.5 },
    accountValue: { label: 'Loan Balance', isRequired: true, val: 0 },
    balanceDate: { label: 'Balance Date', isRequired: true, val: '' },
    accountLimit: {
      label: 'Original Amount',
      isRequired: true,
      val: 0,
      tooltip: 'Amount when the loan was originated. This is used to track your overall progress.'
    },
    monthlyMinimum: { label: 'Monthly Minimum', isRequired: true, val: 0 },
    monthlyAmount: {
      label: 'Monthly Total',
      isRequired: true,
      val: 0,
      tooltip: 'Monthly Minimum + Extra you pay = Monthly Total'
    },
    actualMonthlyAmount: {
      label: 'Actual Monthly Amount',
      isRequired: true,
      val: 0
    },
    productivity: {
      label: 'Productivity',
      isRequired: true,
      val: 'Non-productive',
      tooltip: 'Non-productive debt is paid off in Step-2 of the 4-Step plan. Productive Debt is optional and is paid in Step 4'
    },
    genericCategory: {
      label: 'Generic Category',
      isRequired: true,
      val: 'none'
    },
    wizeFiCategory: { label: 'WizeFi Category', isRequired: true, val: 'none' }
  },
  autoLoan: {
    /* Hidden Fields */
    accountID: { label: 'Account ID', isRequired: true, val: 'ID000' },
    tooltip: { label: 'Tooltip', isRequired: false, val: '' },
    isRequired: { label: 'Required', val: false },
    isEditable: { label: 'isEditable', isRequired: true, val: true },
    accountType: { label: 'Account Type', isRequired: true, val: 'autoLoan' },
    budgetSubcategory: {
      label: 'Budget Category',
      isRequired: true,
      val: 'transportation',
      tooltip: 'Budget Category indicates this information will be carried over to another area of the WizeFi Wealth Building program.'
    },
    accountSubcategoryName: {
      label: 'Account Subcategory Name',
      isRequired: true,
      val: ''
    },

    /* Editable Fields */
    accountName: { label: 'Account Name', isRequired: true, val: '' },
    interestRate: { label: 'APR Interest Rate', isRequired: true, val: 8 },
    accountValue: { label: 'Loan Balance', isRequired: true, val: 0 },
    balanceDate: { label: 'Balance Date', isRequired: true, val: '' },
    accountLimit: {
      label: 'Original Amount',
      isRequired: true,
      val: 0,
      tooltip: 'Amount when the loan was originated. This is used to track your overall progress.'
    },
    monthlyMinimum: { label: 'Monthly Minimum', isRequired: true, val: 0 },
    monthlyAmount: {
      label: 'Monthly Total',
      isRequired: true,
      val: 0,
      tooltip: 'Monthly Minimum + Extra you pay = Monthly Total'
    },
    actualMonthlyAmount: {
      label: 'Actual Monthly Amount',
      isRequired: true,
      val: 0
    },
    productivity: {
      label: 'Productivity',
      isRequired: true,
      val: 'Non-productive',
      tooltip: 'Non-productive debt is paid offin Step-2 of the 4-Step plan. Productive Debt is optional and is paid in Step 4'
    },
    genericCategory: {
      label: 'Generic Category',
      isRequired: true,
      val: 'none'
    },
    wizeFiCategory: { label: 'WizeFi Category', isRequired: true, val: 'none' }
  },
  mortgage: {
    /* Hidden Fields */
    accountID: { label: 'Account ID', isRequired: true, val: 'ID000' },
    tooltip: { label: 'Tooltip', isRequired: false, val: '' },
    isRequired: { label: 'Required', val: false },
    isEditable: { label: 'isEditable', isRequired: true, val: true },
    accountType: { label: 'Account Type', isRequired: true, val: 'mortgage' },
    budgetSubcategory: {
      label: 'Budget Category',
      isRequired: true,
      val: 'housing',
      tooltip: 'Budget Category indicates this information will be carried over to another area of the WizeFi Wealth Building program.'
    },
    accountSubcategoryName: {
      label: 'Account Subcategory Name',
      isRequired: true,
      val: ''
    },

    /* Editable Fields */
    accountName: { label: 'Account Name', isRequired: true, val: '' },
    interestRate: { label: 'APR Interest Rate', isRequired: true, val: 4.5 },
    accountValue: { label: 'Mortage Balance', isRequired: true, val: 0 },
    balanceDate: { label: 'Balance Date', isRequired: true, val: '' },
    accountLimit: {
      label: 'Original Amount',
      isRequired: true,
      val: 0,
      tooltip: 'Amount when the loan was originated. This is used to track your overall progress.'
    },
    monthlyMinimum: { label: 'Monthly Minimum', isRequired: true, val: 0 },
    monthlyAmount: {
      label: 'Monthly Total',
      isRequired: true,
      val: 0,
      tooltip: 'Monthly Minimum + Extra you pay = Monthly Total'
    },
    actualMonthlyAmount: {
      label: 'Actual Monthly Amount',
      isRequired: true,
      val: 0
    },
    productivity: {
      label: 'Productivity',
      isRequired: true,
      val: 'Productive',
      tooltip: 'Non-productive debt is paid offin Step-2 of the 4-Step plan. Productive Debt is optional and is paid in Step 4'
    },
    genericCategory: {
      label: 'Generic Category',
      isRequired: true,
      val: 'none'
    },
    wizeFiCategory: { label: 'WizeFi Category', isRequired: true, val: 'none' }
  }
}; // accountTypes

/*
The following defines account names and account types information under each subcategory for each category.  It also defines percentage guidelines for budget subcategories.
*/
export const categoryInfo = {
  income: {
    label: 'Income',
    tooltip: 'Net Monthly Household Income',
    income: {
      label: 'Income',
      tooltip: 'Net Monthly Household Income',
      accountNames: ['Salary', 'Tips', 'Alimony', 'Affiliate', 'Second Job', 'Commissions', 'Other'],
      accountTypes: ['income']
    }
  }, // income
  assets: {
    label: 'Asset',
    emergencySavings: {
      label: 'Emergency Savings',
      tooltip: 'We recommend a target amount equal to one month of expenses, but at a minimum you should have $2,000 in your Emergency Savings.',
      accountNames: ['Emergency Savings'],
      accountTypes: ['savingsRequired']
    },
    cash: {
      label: 'cash',
      tooltip: '',
      accountNames: ['Cash'],
      accountTypes: ['savingsOptional']
    },
    cashReserves: {
      label: 'General Savings',
      tooltip: 'We recommend 3 to 6 months of net income as your target, depending on the stability of your income',
      accountNames: ['General Savings'],
      accountTypes: ['savingsRequired']
    },
    otherSavings: {
      label: 'Other Savings',
      accountNames: [],
      accountTypes: ['savingsOptional']
    },
    checking: {
      label: 'Checking',
      accountNames: ['Checking'],
      accountTypes: ['checking']
    },
    investments: {
      label: 'Investments',
      accountNames: [
        'IRA',
        'Roth IRA',
        'SEP IRA',
        'Simple IRA',
        '401(k)',
        'Roth 401(k)',
        '403(b)',
        '457(b)',
        'Annuity',
        'HSA',
        'Brokerage Account',
        'Certificate of Deposit',
        'Money Market',
        '529',
        'Business You Own',
        'Other Productive Asset'
      ],
      accountTypes: ['investments']
    },
    residenceProperty: {
      label: 'Residence Property',
      accountNames: ['Non-Income-Producing Real Estate'],
      accountTypes: ['residenceProperty']
    },
    /* incomeProducingResidenceProperty: {
      label: "Residence Property",
      accountNames: ["Income-Producing Real Estate"],
      accountTypes: ["residenceProperty"]
    }, */
    investmentProperty: {
      label: 'Investment Property',
      accountNames: ['Farm', 'Land Investment'],
      accountTypes: ['investmentProperty']
    },
    personalProperty: {
      label: 'Personal Property',
      accountNames: [
        'Appliance',
        'Books/CDs/DVD/Tapes',
        'Camera',
        'Car',
        'Clothing',
        'Computer',
        'Cookware',
        'Cutlery/Silverware',
        'Decorations',
        'Dishes/Fine China',
        'Electronics',
        'Furniture',
        'Gardening',
        'Glass/Crystal',
        'Jewelry',
        'Motorcycle',
        'Musical Instrument',
        'Rec. Vehicle',
        'Rug',
        'Sporting Goods',
        'Tools',
        'Toys',
        'Boat',
        'Other Nonproductive Asset'
      ],
      accountTypes: ['personalProperty']
    },
    limitedPersonalProperty: {
      label: 'Personal Property',
      accountNames: ['Art', 'Classic Car', 'Collectible', 'Gold', 'Other Limited Asset'],
      accountTypes: ['personalProperty']
    }
  }, // assets
  assetProtection: {
    label: 'Insurance',
    termInsurance: {
      label: 'Term Insurance',
      tooltip: 'Your monthly premium will be accounted for in your Health/Insurance budget.',
      accountNames: ['Term Life Insurance'],
      accountTypes: ['termInsurance']
    },
    permInsurance: {
      label: 'Permanent Insurance',
      tooltip: 'Your monthly premium will be accounted for in your Health/Insurance budget.',
      accountNames: ['Permanent Life Insurance'],
      accountTypes: ['permInsurance']
    },
    autoInsurance: {
      label: 'Automobile Insurance',
      tooltip: 'Your monthly premium will be accounted for in your Transportation budget.',
      accountNames: ['Automobile Insurance', 'Motorcycle Insurance', 'RV Insurance', 'Other Automobile Insurance'],
      accountTypes: ['autoInsurance']
    },
    homeInsurance: {
      label: 'Housing Insurance',
      tooltip: 'Your monthly premium will be accounted for in your Housing budget.',
      accountNames: ['Homeowner Insurance', 'Renter Insurance', 'Other Housing Insurance'],
      accountTypes: ['homeInsurance']
    },
    otherInsurance: {
      label: 'Other Insurance',
      tooltip: 'Your monthly premium will be accounted for in your Health/Insurance budget.',
      accountNames: ['Group Life Insurance', 'Disability Insurance', 'Group Disability Insurance', 'Umbrella', 'Other Insurance'],
      accountTypes: ['otherInsurance']
    }
  }, // assetProtection
  liabilities: {
    label: 'Debt',
    /* mortgage: {
      label: "Mortgage",
      tooltip:
        "Your monthly minimum should include principal and interest only, and it will be accounted for in your housing budget.  Property tax and insurance is collected elsewhere.",
      accountNames: ["Mortage for Income-Producing Real Estate"],
      accountTypes: ["mortgage"]
    }, */
    mortgageLimited: {
      label: 'Mortgage',
      tooltip:
        'Your monthly minimum should include principal and interest only, and it will be accounted for in your housing budget.  Property tax and insurance is collected elsewhere.',
      accountNames: ['Mortgage for Non-Income-Producing Real Estate'],
      accountTypes: ['mortgage']
    },
    creditCard: {
      label: 'Credit Card',
      accountNames: ['Credit Card'],
      accountTypes: ['creditCard']
    },
    autoLoan: {
      label: 'Auto Loan',
      tooltip: 'Your monthly minimum will be accounted for in your transportation budget.',
      accountNames: ['Auto Loan'],
      accountTypes: ['autoLoan']
    },
    boatLoan: {
      label: 'Boat Loan',
      accountNames: ['Boat Loan'],
      accountTypes: ['autoLoan']
    },
    educationalLoan: {
      label: 'Educational Loan',
      accountNames: ['Educational Loan'],
      accountTypes: ['loan']
    },
    other: {
      label: 'Other',
      accountNames: ['Business loan', 'Margin Account', 'Other Productive Debt'],
      accountTypes: ['loan']
    },
    otherLimited: {
      label: 'Other',
      accountNames: ['Loan to purchase Limited Asset', 'Other Limited Debt'],
      accountTypes: ['loan']
    },
    otherNonProductive: {
      label: 'Other',
      accountNames: ['Personal Loan', 'Other Nonproductive Debt'],
      accountTypes: ['loan']
    }
  }, // liabilities
  budget: {
    label: 'Budget',
    giving: {
      label: 'Giving',
      accountNames: ['Tithe', 'Charity', 'Other Giving'],
      accountTypes: ['expenses'],
      guidelinePercentage: 10
    },
    housing: {
      label: 'Housing',
      accountNames: [
        'Rent',
        'Property Taxes',
        'Home Maintenance',
        'HOA Dues',
        'Housekeeping/Cleaning',
        'Internet/Cable',
        'Phone',
        'Electric',
        'Gas',
        'Water',
        'Trash',
        'Sewer',
        'Other Housing'
      ],
      accountTypes: ['expenses'],
      guidelinePercentage: 26
    },
    transportation: {
      label: 'Transportation',
      accountNames: ['Auto Lease', 'R.V. Lease', 'Maintenance', 'Gas', 'Registration', 'Ride-Hailing', 'Other Transportation'],
      accountTypes: ['expenses'],
      guidelinePercentage: 8
    },
    food: {
      label: 'Food',
      accountNames: ['Groceries', 'Dining Out', 'Other Food'],
      accountTypes: ['expenses'],
      guidelinePercentage: 16
    },
    health: {
      label: 'Health/Insurance',
      accountNames: [
        'Medical Copays/Deductibles',
        'Prescriptions',
        'Orthodontist',
        'Optometrist',
        'Medical Premiums',
        'Dental Premiums',
        'Vision Premiums',
        'Other Health/Insurance'
      ],
      accountTypes: ['expenses'],
      guidelinePercentage: 12
    },
    clothing: {
      label: 'Clothing',
      accountNames: ['Clothing', 'Laundry/Dry Cleaning', 'Other Clothing'],
      accountTypes: ['expenses'],
      guidelinePercentage: 4
    },
    entertainment: {
      label: 'Entertainment/Misc.',
      accountNames: [
        'Vacations',
        'Hobbies',
        'Subscriptions',
        'Movies',
        'Concerts',
        'Theatre',
        'Education',
        'Kids Activities',
        'Health Club',
        'Grooming & Care',
        'Pets',
        'Other Entertainment'
      ],
      accountTypes: ['expenses'],
      guidelinePercentage: 4
    }
  }, // budget
  // This is present only for test situations.  Eventually is should be removed.
  assets2: {
    label: 'Assets2',
    emergencySavings: {
      label: 'Emergency Savings',
      tooltip: 'We recommend a target amount equal to one month of expenses, but at a minimum you should have $2,000 in your Emergency Savings.',
      accountNames: ['Emergency Savings'],
      accountTypes: ['savingsRequired']
    },
    cashReserves: {
      label: 'General Savings',
      tooltip: 'We recommend 3 to 6 months of net income as your target, depending on the stability of your income',
      accountNames: ['General Savings'],
      accountTypes: ['savingsRequired']
    },
    otherSavings: {
      label: 'Other Savings',
      accountNames: [],
      accountTypes: ['savingsOptional']
    },
    investments: {
      label: 'Investments',
      accountNames: [
        'IRA',
        'Roth IRA',
        'SEP IRA',
        'Simple IRA',
        '401(k)',
        'Roth 401(k)',
        '403(b)',
        '457(b)',
        'Pension',
        'Annuity',
        'HSA',
        'Brokerage Account',
        '529'
      ],
      accountTypes: ['investments']
    },
    residenceProperty: {
      label: 'Residence Property',
      accountNames: ['Primary Residence', 'Secondary Residence'],
      accountTypes: ['residenceProperty']
    }
  } // assets2
}; // categoryInfo

export const dataModel: IDataModel = {
  // this data is transient -- used only at run time
  global: {
    debug: false,
    screen: '',
    debugID: '',
    isLoggedIn: false,
    isNewUser: true,
    isFBLogin: false,
    wizeFiID: 'unknown',
    access_token: '12345',
    lambda: null,
    docClient: null,
    s3: null,
    planList: [],
    plansOldData: {},
    draftsInfo: [],
    screenDataModel: {
      income: null,
      budget: null,
      assets: null,
      assetProtection: null,
      liabilities: null
    },
    braintreeData: null,
    plaidData: null,
    selectedCurrencyCode: 'USD'
  },
  // this data is persistent -- stored in DynamoDB WizeFiData table
  // the topLevelVersion value is used to guide updates to data attributes at the top level of a DynamoDB database Item
  // (these are attributes that appear in the listing of a DynamoDB Item in the console interface)
  // note: wizeFiID is stored in dataModel.global.wizeFiID (so the value is available even if no data has been retrieved from DynamoDB WizeFiData)
  topLevelVersion: 1,
  affiliateID: 'UNKNOWN',
  affiliateAlias: 'UNKNOWN',

  persistent: {
    // the persistentDataVersion value is used to guide updates to header, settings, and profile information
    persistentDataVersion: 1,
    header: {
      originalDateCreated: '2017-05-09T12:14:32.456Z',
      dateCreated: '2017-05-09T12:14:32.456Z',
      dateUpdated: '2017-05-09T12:15:32.456Z',
      curplan: 'original',
      isLatestPlan: false,
      curplanYearMonth: '2017-05',
      setupStepCompleted: 0, // number of highest setup step that has been completed
      dateOrigPlanCompleted: '', // set after step 5 completed
      origTransactionsYearMonth: '', // yearMonth used for original plan transactions (from month prior to month of original plan)
      dateProfileCompleted: '',
      parentAffiliateID: '',
      payoutAccountStatus: 'none', // PayPal    (none,active,suspended,canceled)
      payoutEmail: '', // PayPal
      wizeFiAccountStatus: 'none',
      dateSubscriptionCreated: '', // deprecated  Braintree
      paymentRef: '', // deprecated  Braintree -- (credit card type + last 4 digits of credit card)
      subscriptionAccountStatus: 'none', // deprecated  Braintree -- none,active,pastdue,suspended,canceled
      subscriptionEmail: '', // deprecated  Braintree
      subscriptionFee: 8.99, // deprecated  Braintree  dataModelService.affiliateManagement.defaultFee
      subscriptionID: '', // deprecated  Braintree
      subscriptionPaidThrough: '' // deprecated  Braintree -- YYYY-MM-DD
    },
    settings: {
      haveSelectedCurrencyCode: false,
      currencyCode: 'USD',
      thousandsSeparator: ',',
      decimalSeparator: '.',
      decimalDigits: 2,
      userCountryCodes: ['US']
    },
    profile: {
      nameFirst: '',
      nameLast: '',
      email: '',
      gender: '',
      birthDate: '', // date format: YYYY-MM-DD
      zipCode: '',
      country: '',
      occupation: '',
      userPictureObjectKey: pictureNotAvailableObjectKey, // default object key for user picture
      retirementYears: 15,
      retirementAge: 67,
      socialSecurityDrawAge: 67,
      pensionDrawAge: 67,
      emergencySavingsTargetAmount: 2000,
      cashReservesTargetAmount: 15000,
      financialFreedomTargetAmount: 1500000,
      retirementMonthlyBudget: 0,
      monthlyRetirementIncome: 0,
      monthlySocialSecurity: 0,
      monthlyPension: 0,
      investmentReturnRate: 6,
      inflationRate: 2.07
    },
    plans: {
      original: {
        // the planDataVersion value is used to guide updates to an individual plan
        planDataVersion: 1,
        dateTransactionsUpdate: '', // date of last transactions update for this plan

        income: {
          attributeName: 'income',
          label: 'Income',
          // tooltip: 'Net Monthly Household Income',
          income: {
            label: 'Income',
            isRequired: true,
            // tooltip: 'Net Monthly Household Income',
            accounts: [
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID001'
                },
                tooltip: {
                  label: 'Tooltip',
                  isRequired: true,
                  val: 'Net Monthly Household Income'
                },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: true
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'income'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'income_income_ID001'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Salary'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'none'
                }
                /*
								federalTax:          {label: "Federal Tax",           isRequired: true,  val: 0                      },
								stateTax:            {label: "State Tax",             isRequired: true,  val: 0                      },
								localTax:            {label: "Local Tax",             isRequired: true,  val: 0                      },
								ficaTax:             {label: "FICA",                  isRequired: true,  val: 0                      },
								childSupport:        {label: "Child Support",         isRequired: true,  val: 0                      },
								grossMonthlyIncome:  {label: "Gross Monthly Income",  isRequired: true,  val: 0                      },
								*/
              }
            ]
          }
        }, // income
        assets: {
          attributeName: 'assets',
          label: 'Asset',
          emergencySavings: {
            label: 'Emergency Savings',
            isRequired: true,
            tooltip:
              'We recommend a target amount equal to one month of expenses, but at a minimum you should have $2,000 in your Emergency Savings.',
            accounts: [
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID001'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: true
                },
                isEditable: {
                  label: 'isEditable',
                  isRequired: true,
                  val: true
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'savingsRequired'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'assets_emergencySavings_ID001'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Emergency Savings'
                },
                interestRate: {
                  label: 'Est. Growth Rate',
                  isRequired: false,
                  val: 0.01,
                  tooltip: 'Enter value as an annual percentage rate, e.g. 4.25.'
                },
                accountValue: {
                  label: 'Account Balance',
                  isRequired: true,
                  val: 0
                },
                balanceDate: {
                  label: 'Balance Date',
                  isRequired: true,
                  val: ''
                },
                employerContribution: {
                  label: 'Employer Contribution',
                  isRequired: false,
                  val: 0
                },
                monthlyAmount: {
                  label: 'Monthly Contribution',
                  isRequired: false,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                productivity: {
                  label: 'Productivity',
                  isRequired: true,
                  val: 'Productive'
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'none'
                }
              }
            ]
          },
          cash: {
            label: 'Cash',
            isRequired: false,
            tooltip: '',
            accounts: [
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID001'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: true
                },
                isEditable: {
                  label: 'isEditable',
                  isRequired: true,
                  val: true
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'savingsRequired'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'assets_cash_ID001'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Cash'
                },
                accountValue: {
                  label: 'Account Balance',
                  isRequired: true,
                  val: 0
                },
                targetAmount: {
                  label: 'Target Amount',
                  isRequired: true,
                  val: 0
                },
                productivity: {
                  label: 'Productivity',
                  isRequired: true,
                  val: 'Productive'
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'none'
                }
              }
            ]
          },
          cashReserves: {
            label: 'General Savings',
            isRequired: true,
            tooltip: 'We recommend 3 to 6 months of net income as your target, depending on the stability of your income',
            accounts: [
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID001'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: true
                },
                isEditable: {
                  label: 'isEditable',
                  isRequired: true,
                  val: true
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'savingsRequired'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'assets_cashReserves_ID001'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'General Savings'
                },
                interestRate: {
                  label: 'Est. Growth Rate',
                  isRequired: false,
                  val: 0.01,
                  tooltip: 'Enter value as an annual percentage rate, e.g. 4.25.'
                },
                accountValue: {
                  label: 'Account Balance',
                  isRequired: true,
                  val: 0
                },
                balanceDate: {
                  label: 'Balance Date',
                  isRequired: true,
                  val: ''
                },
                employerContribution: {
                  label: 'Employer Contribution',
                  isRequired: false,
                  val: 0
                },
                monthlyAmount: {
                  label: 'Monthly Contribution',
                  isRequired: false,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                targetAmount: {
                  label: 'Target Amount',
                  isRequired: true,
                  val: 0
                },
                productivity: {
                  label: 'Productivity',
                  isRequired: true,
                  val: 'Productive'
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'none'
                }
              }
            ]
          },
          checking: {
            label: 'Checking',
            isRequired: false,
            accounts: [
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID001'
                },
                tooltip: { label: 'Tooltip', isRequired: false, val: '' },
                isRequired: { label: 'Required', val: false },
                isEditable: {
                  label: 'isEditable',
                  isRequired: true,
                  val: true
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'checking'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'assets_checking_ID001'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Checking'
                },
                interestRate: {
                  label: 'Est. Growth Rate',
                  isRequired: false,
                  val: 0.0,
                  tooltip: 'Enter value as an annual percentage rate, e.g. 4.25.'
                },
                credits: { label: 'Credits', isRequired: true, val: 0 },
                debits: { label: 'Debits', isRequired: true, val: 0 },
                interestPaid: {
                  label: 'InterestPaid',
                  isRequired: true,
                  val: 0
                },
                fees: { label: 'Fees', isRequired: true, val: 0 },
                accountValue: {
                  label: 'Account Balance',
                  isRequired: true,
                  val: 0
                },
                balanceDate: {
                  label: 'Balance Date',
                  isRequired: true,
                  val: ''
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                productivity: {
                  label: 'Productivity',
                  isRequired: true,
                  val: 'Productive'
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'none'
                }
              }
            ]
          }
        }, // assets
        assetProtection: {
          attributeName: 'assetProtection',
          label: 'Insurance'
        }, // assetProtection
        liabilities: {
          attributeName: 'liabilities',
          label: 'Liability'
        }, // liabilities
        budget: {
          attributeName: 'budget',
          label: 'Budget',
          giving: {
            label: 'Giving',
            accounts: [
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID001'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: false
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'expenses'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'budget_giving_ID001'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Tithe'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'none'
                }
              },
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID002'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: false
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'expenses'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'budget_giving_ID002'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Charity'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'none'
                }
              }
            ]
          },
          housing: {
            label: 'Housing',
            isRequired: true,
            accounts: [
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID001'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: false
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'expenses'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'budget_housing_ID001'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Rent'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'rent'
                }
              },
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID002'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: false
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'expenses'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'budget_housing_ID002'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Housekeeping/Cleaning'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'none'
                }
              },
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID003'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: false
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'expenses'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'budget_housing_ID003'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Electric'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'electric'
                }
              },
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID004'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: false
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'expenses'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'budget_housing_ID004'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Gas'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'heatingGas'
                }
              },
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID005'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: false
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'expenses'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'budget_housing_ID005'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Water'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'water'
                }
              },
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID006'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: false
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'expenses'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'budget_housing_ID006'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Internet/Cable'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'internetCable'
                }
              },
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID007'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: false
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'expenses'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'budget_housing_ID007'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Phone'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'phone'
                }
              }
            ]
          },
          transportation: {
            label: 'Transportation',
            accounts: [
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID001'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: false
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'expenses'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'budget_transportation_ID001'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Maintenance'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'vehicleMaintenance'
                }
              },
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID002'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: false
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'expenses'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'budget_transportation_ID002'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Gas'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'gasoline'
                }
              },
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID003'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: false
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'expenses'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'budget_transportation_ID003'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Ride-Hailing'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'none'
                }
              }
            ]
          },
          food: {
            label: 'Food',
            isRequired: true,
            accounts: [
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID001'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: false
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'expenses'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'budget_food_ID001'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Groceries'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'groceries'
                }
              },
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID002'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: false
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'expenses'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'budget_food_ID002'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Dining Out'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'restaurants'
                }
              }
            ]
          },
          health: {
            label: 'Health/Insurance',
            accounts: [
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID001'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: false
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'expenses'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'budget_health_ID001'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Prescriptions'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'prescriptions'
                }
              },
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID002'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: false
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'expenses'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'budget_health_ID002'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Orthodontist'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'none'
                }
              },
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID003'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: false
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'expenses'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'budget_health_ID003'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Medical Premiums'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'none'
                }
              },
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID004'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: false
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'expenses'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'budget_health_ID004'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Dental Premiums'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'none'
                }
              }
            ]
          },
          clothing: {
            label: 'Clothing',
            accounts: [
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID001'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: false
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'expenses'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'budget_clothing_ID001'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Clothing'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'clothing'
                }
              },
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID002'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: false
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'expenses'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'budget_clothing_ID002'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Laundry/Dry Cleaning'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'laundryCleaning'
                }
              }
            ]
          },
          entertainment: {
            label: 'Entertainment/Misc.',
            accounts: [
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID001'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: false
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'expenses'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'budget_entertainment_ID001'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Vacations'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'vacations'
                }
              },
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID002'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: false
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'expenses'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'budget_entertainment_ID002'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Movies'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'movies'
                }
              },
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID003'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: false
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'expenses'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'budget_entertainment_ID003'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Education'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'education'
                }
              },
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID004'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: false
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'expenses'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'budget_entertainment_ID004'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Pets'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'pets'
                }
              },
              {
                /* Hidden Fields */
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID005'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: false
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'expenses'
                },
                wizeFiCategory: {
                  label: 'WizeFi Category',
                  isRequired: true,
                  val: 'budget_entertainment_ID005'
                },
                accountSubcategoryName: {
                  label: 'Account Subcategory Name',
                  isRequired: true,
                  val: ''
                },

                /* Editable Fields */
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Other'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'none'
                }
              }
            ]
          }
        }, // budget
        assets2: {
          attributeName: 'assets2',
          label: 'Assets2',
          emergencySavings: {
            label: 'Emergency Savings',
            // tooltip: 'We recommend an amount equal to 1 month of net income, but the minimum target for your Emergency Fund should be $1,500',
            accounts: [
              {
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID001'
                },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: true
                },
                isEditable: {
                  label: 'isEditable',
                  isRequired: true,
                  val: true
                },
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Emergency Savings'
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'savingsRequired'
                },
                accountValue: {
                  label: 'Account Balance',
                  isRequired: true,
                  val: 0
                },
                interestRate: {
                  label: 'Est. Growth Rate',
                  isRequired: false,
                  val: 0,
                  tooltip: 'Enter value as an annual percentage rate, e.g. 4.25.'
                },
                monthlyAmount: {
                  label: 'Monthly Contribution',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'none'
                },
                employerContribution: {
                  label: 'Employer Contribution',
                  isRequired: true,
                  val: 0
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' }
              }
            ]
          },
          cashReserves: {
            label: 'General Savings',
            // tooltip: 'We recommend 3 to 6 months of net income as your target, depending on the stability of your income',
            accounts: [
              {
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID002'
                },
                isRequired: {
                  label: 'isRequired',
                  isRequired: true,
                  val: true
                },
                isEditable: {
                  label: 'isEditable',
                  isRequired: true,
                  val: true
                },
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'General Savings'
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'savingsRequired'
                },
                accountValue: {
                  label: 'Account Balance',
                  isRequired: true,
                  val: 0
                },
                interestRate: {
                  label: 'Est. Growth Rate',
                  isRequired: false,
                  val: 0,
                  tooltip: 'Enter value as an annual percentage rate, e.g. 4.25.'
                },
                monthlyAmount: {
                  label: 'Monthly Contribution',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'none'
                },
                employerContribution: {
                  label: 'Employer Contribution',
                  isRequired: true,
                  val: 0
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' }
              }
            ]
          },
          otherSavings: {
            label: 'Other Savings',
            accounts: [
              {
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID003'
                },
                isEditable: {
                  label: 'isEditable',
                  isRequired: true,
                  val: true
                },
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Other Savings'
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'savingsOptional'
                },
                accountValue: {
                  label: 'Account Balance',
                  isRequired: true,
                  val: 0
                },
                interestRate: {
                  label: 'Est. Growth Rate',
                  isRequired: false,
                  val: 0,
                  tooltip: 'Enter value as an annual percentage rate, e.g. 4.25.'
                },
                monthlyAmount: {
                  label: 'Monthly Contribution',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'none'
                },
                employerContribution: {
                  label: 'Employer Contribution',
                  isRequired: true,
                  val: 0
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' }
              }
            ]
          },
          investments: {
            label: 'Investments',
            accounts: [
              {
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID001'
                },
                isEditable: {
                  label: 'isEditable',
                  isRequired: true,
                  val: true
                },
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'IRA'
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'investments'
                },
                monthlyAmount: {
                  label: 'Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                actualMonthlyAmount: {
                  label: 'Actual Monthly Amount',
                  isRequired: true,
                  val: 0
                },
                genericCategory: {
                  label: 'Generic Category',
                  isRequired: true,
                  val: 'none'
                },
                interestRate: {
                  label: 'Growth Rate',
                  isRequired: true,
                  val: 7.5,
                  tooltip: 'Enter value as an annual percentage rate, e.g. 4.25.'
                },
                accountValue: {
                  label: 'Account Value',
                  isRequired: true,
                  val: 0
                },
                employerContribution: {
                  label: 'Employer Contribution',
                  isRequired: false,
                  val: 0
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' }
              }
            ]
          },
          residenceProperty: {
            label: 'Residence Property',
            accounts: [
              {
                accountID: {
                  label: 'Account ID',
                  isRequired: true,
                  val: 'ID001'
                },
                isEditable: {
                  label: 'isEditable',
                  isRequired: true,
                  val: true
                },
                accountName: {
                  label: 'Account Name',
                  isRequired: true,
                  val: 'Primary Residence'
                },
                accountType: {
                  label: 'Account Type',
                  isRequired: true,
                  val: 'residenceProperty'
                },
                accountValue: { label: 'Est. Value', isRequired: true, val: 0 },
                interestRate: {
                  label: 'Est. Growth Rate',
                  isRequired: true,
                  val: 3,
                  tooltip: 'Enter value as an annual percentage rate, e.g. 4.25.'
                },
                tooltip: { label: 'Tooltip', isRequired: true, val: '' }
              }
            ]
          }
        } // assets2
      } // original
    } // plans
  } // persistent
}; // dataModel
