import * as _ from 'lodash';
import { ɵCompiler_compileModuleSync__POST_R3__ } from '@angular/core';
import { dataModel } from './../services/data-model/data-model_0001.data';
import { CategoryManagement } from './category-management.class';

const UNCATEGORIZED = 'Uncategorized';

export class DropdownLabelManagement extends CategoryManagement {
  // private helper methods
  public getLabelFromCategory;
  public getCategoryFromLabel;
  public getLabelFromSubcategory;
  public getSubcategoryFromLabel;
  public getAccountLabel;
  public noUncategorizedLabelsFilter;
  public noAnysFilter;

  private wizeFiCategories;

  constructor(public dataModelService: any) {
    super(dataModelService);
    this.resetWizeFiCategories();
    this.setupLabelUtilities();
  }

  // API

  public resetWizeFiCategories() {
    this.wizeFiCategories = super.getWizeFiCategories();
  }

  public getCategoryLabels(): string[] {
    const wizeFiCategoryNames = this.wizeFiCategories.map(category => category.wizeFiCategoryName);
    const categories = this.dataModelService.categoryManagement.getCategoryList(wizeFiCategoryNames).filter(this.noAnysFilter);
    const values = categories.map(category => this.getLabelFromCategory(category)).filter(this.noUncategorizedLabelsFilter);
    values.forEach(elem => {
      console.log(elem);
    });
    return values;
  }

  public getSubcategoryLabels(currentCategory: string): string[] {
    const wizeFiCategoryNames = this.wizeFiCategories.map(category => category.wizeFiCategoryName);
    let subcategories = _.values(this.dataModelService.categoryManagement.getSubcategoryList(wizeFiCategoryNames)).filter(this.noAnysFilter);

    subcategories = _.flatten(subcategories);
    return subcategories.map(subcategory => this.getLabelFromSubcategory(currentCategory, subcategory)).filter(this.noUncategorizedLabelsFilter);
  }

  public getAccountLabels(currentCategory: string, currentSubcategory: string, getCustomLabels: boolean = false): any[] {
    let accountLabels = [];
    const filteredWizeFiCategories = this.wizeFiCategories.filter(
      wizeFiCategory => wizeFiCategory.category === currentCategory && wizeFiCategory.subcategory === currentSubcategory
    );
    if (!getCustomLabels) {
      accountLabels = filteredWizeFiCategories.map(wizeFiCategory => ({ label: wizeFiCategory.account }));
    } else {
      const curplan = this.dataModelService.dataModel.persistent.header.curplan;
      if (currentSubcategory && currentSubcategory !== 'Uncategorized' && currentSubcategory !== 'none') {
        const plan = this.dataModelService.dataModel.persistent.plans[curplan][currentCategory][currentSubcategory].accounts;

        plan.map(account => {
          accountLabels.push({ wizeFiCategory: account.wizeFiCategory.val, label: account.accountName.val });
        });
      }
    }
    return accountLabels;
  }

  // Private

  private setupLabelUtilities() {
    /**
     * This function sets up some handy utility methods for the class that are aimed at translating
     * between the data-model's persistent attributeNames for categories and subcategories, and the
     * corresponding labels that need to be presented within the app's UI
     */
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;
    const plan = this.dataModelService.dataModel.persistent.plans[curplan];

    this.getLabelFromCategory = (category: string) => (plan[category] && plan[category].label) || UNCATEGORIZED;

    this.getCategoryFromLabel = (label: string) => {
      const category = Object.keys(plan || {})
        .map(categoryKey => ({ ...plan[categoryKey], categoryKey }))
        .find(privateCategory => privateCategory.label === label);
      return (category && category.categoryKey) || UNCATEGORIZED;
    };

    this.getLabelFromSubcategory = (category: string, subcategory: string) =>
      (plan[category] && plan[category][subcategory] && plan[category][subcategory].label) || UNCATEGORIZED;

    this.getSubcategoryFromLabel = (label: string, currentCategory: string) => {
      const subcategory = Object.keys(plan[currentCategory] || {})
        .map(subcategoryKey => ({
          ...plan[currentCategory][subcategoryKey],
          subcategoryKey
        }))
        .find(privateSubcategory => privateSubcategory.label === label);
      return (subcategory && subcategory.subcategoryKey) || UNCATEGORIZED;
    };

    this.noUncategorizedLabelsFilter = label => label !== UNCATEGORIZED;
    this.noAnysFilter = label => label !== 'any';
  }
}
