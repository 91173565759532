import { environment } from '../../environments/environment';
import { WizefiTransaction } from '../interfaces/wizefi-transaction';
import { DataModelService } from '../services/data-model/data-model.service';
import { TransactionsCollection } from '@app/interfaces/plaid-data';
import { DraftService } from '@app/services/draft.service';

declare const AWS: any;

export class DataManagement {
  constructor(public dataModelService: DataModelService, private draftService: DraftService) {}

  public getMonthPlan(wizeFiID, plan) {
    return new Promise((resolve, reject) => {
      // define params to guide query operation
      const params = {
        TableName: 'WizeFiDataPlans',
        KeyConditionExpression: 'wizeFiID = :wizeFiID and #plan = :plan',
        ExpressionAttributeNames: { '#plan': 'plan' },
        ExpressionAttributeValues: { ':wizeFiID': wizeFiID, ':plan': plan }
      };

      this.dataModelService.dataModel.global.docClient.query(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          if (data.Items.length === 0) {
            reject('Monthly plan does not exist: ' + plan);
          } else {
            // return the planData as a JSON string (to enable storing that JSON string in plansOldData)
            resolve(data.Items[0].planData);
          }
        }
      });
    }); // return Promise
  } // getMonthPlan

  public putMonthPlan(wizeFiID, plan, planData) {
    return new Promise((resolve, reject) => {
      // define params to guide put operation
      const params = {
        TableName: 'WizeFiDataPlans',
        Item: {
          wizeFiID,
          plan,
          planData: JSON.stringify(planData)
        }
      };

      // put info in DynamoDB table
      this.dataModelService.dataModel.global.docClient.put(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve('Data has been stored');
        }
      });
    }); // return Promise
  } // putMonthPlan

  public deleteMonthPlan(wizeFiID, plan) {
    return new Promise((resolve, reject) => {
      // define params to guide delete operation
      const params = {
        TableName: 'WizeFiDataPlans',
        Key: { wizeFiID, plan }
      };

      // delete item from DynamoDB table
      this.dataModelService.dataModel.global.docClient.delete(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve('Data has been deleted');
        }
      });
    }); // return Promise
  } // deleteMonthPlan

  public getPlanList(wizeFiID) {
    return new Promise((resolve, reject) => {
      // define params to guide query operation
      const params = {
        TableName: 'WizeFiDataPlans',
        KeyConditionExpression: '#wizeFiID = :wizeFiID',
        ExpressionAttributeNames: { '#wizeFiID': 'wizeFiID', '#plan': 'plan' },
        ExpressionAttributeValues: { ':wizeFiID': wizeFiID },
        ProjectionExpression: '#plan'
      };

      this.dataModelService.dataModel.global.docClient.query(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const planList = [];
          for (const item of data.Items) {
            planList.push(item.plan);
          }
          planList.sort();

          resolve(planList);
        }
      });
    }); // return Promise
  } // getPlanList

  public isPlanInMemory(planName) {
    return this.dataModelService.dataModel.persistent.plans.hasOwnProperty(planName);
  } // isPlanInMemory

  public isPlanOnDisk(planName) {
    return this.dataModelService.dataModel.global.planList.indexOf(planName) !== -1;
  } // isPlanOnDisk

  public getMonthHistory(): any[] {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const months: any = [];
    const firstPlan = this.parsePlanName(this.dataModelService.dataModel.global.planList[1]);
    const start = new Date(firstPlan.year, firstPlan.month, 0, 0, 0, 0, 0).toISOString(); // set day and time to 0
    const now = new Date();
    const end = new Date(now.getUTCFullYear(), now.getUTCMonth() + 1, 0, 0, 0, 0, 0).toISOString(); // set day and time to 0

    let curr: any = {};
    for (let i = start; i.substr(0, 7) <= end.substr(0, 7); i = this.dataModelService.changeDate(i, 0, 1, 0)) {
      curr = {};
      curr.year = i.substr(0, 4);
      curr.month = i.substr(5, 2);
      curr.monthName = monthNames[curr.month - 1];
      curr.planName = this.makePlanName(curr.year, curr.month);
      curr.hasPlan = this.isPlanOnDisk(curr.planName) ? 1 : 0;
      curr.curPlan = this.dataModelService.dataModel.persistent.header.curplan === curr.planName ? 1 : 0;

      months.push(curr);
    }

    // console.log('months',months);

    return months;
  } // getMonthHistory

  public makePlanName(year, month) {
    if (typeof month !== 'number') {
      month = Number(month);
    }
    const strmonth = month < 10 ? '0' + month : '' + month;
    return 'p' + year + strmonth;
  } // makePlanName

  public parsePlanName(planName) {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const year = planName.substr(1, 4);
    const month = planName.substr(5, 2);
    const planInfo = {
      year,
      month,
      monthName: monthNames[parseInt(month, 10) - 1]
    };

    return planInfo;
  } // parsePlanName

  public async makeNewPlan(year, month) {
    let memoryPlanList;

    // initialize
    const newPlan = this.makePlanName(year, month);

    memoryPlanList = Object.keys(this.dataModelService.dataModel.persistent.plans).sort();

    // make new plan
    if (this.dataModelService.dataModel.global.planList.indexOf(newPlan) === -1) {
      // find plan with the greatest date that is less than or equal to the given year and month
      const oldPlan = JSON.parse(JSON.stringify(this.dataModelService.dataModel.global.planList))
        .reverse()
        .find(e => this.dataModelService.dataModel.persistent.plans[e] != null);

      if (newPlan !== oldPlan) {
        // bring oldPlan into memory if necessary
        if (!this.dataModelService.dataModel.persistent.plans.hasOwnProperty(oldPlan)) {
          const oldPlanDataJSON = await this.getMonthPlan(this.dataModelService.dataModel.global.wizeFiID, oldPlan);
          this.dataModelService.dataModel.persistent.plans[oldPlan] = JSON.parse(oldPlanDataJSON.toString()); // toString is kludge to avoid type error)
          this.dataModelService.dataModel.global.plansOldData[oldPlan] = oldPlanDataJSON;
        }

        // clone old plan to make a new plan
        this.dataModelService.dataModel.persistent.plans[newPlan] = JSON.parse(
          JSON.stringify(this.dataModelService.dataModel.persistent.plans[oldPlan])
        );

        //////////// set actualMonthlyAmount to zero for all accounts in newPlan  //////////////
        // establish visitWizeFiAccount function to set actualMonthlyAmount to zero for a WizeFi account
        const visitWizeFiAccount = (plan, category, subcategory, acntndx, account, funcResult) => {
          if (account.hasOwnProperty('actualMonthlyAmount')) {
            account.actualMonthlyAmount.val = 0;
          }
        }; // visitWizeFiAccount

        // invoke the function to carry out the task of setting the actualMonthlyAmount to zero for all accounts
        const result = null;
        this.dataModelService.visitAllWizeFiAccounts(newPlan, visitWizeFiAccount, result);
        //////////// end of set actualMonthlyAmount to zero for all accounts in newPlan  //////////////

        // update planList
        this.dataModelService.dataModel.global.planList.push(newPlan);
        this.dataModelService.dataModel.global.planList.sort();

        // update plansOldData
        this.dataModelService.dataModel.global.plansOldData[newPlan] = '';

        memoryPlanList = Object.keys(this.dataModelService.dataModel.persistent.plans).sort();
      }
    }
  } // makeNewPlan

  public async changeCurrentPlan(newCurplan) {
    if (this.dataModelService.dataModel.global.planList.indexOf(newCurplan) === -1) {
      // create new plan
      const planInfo = this.dataModelService.dataManagement.parsePlanName(newCurplan);
      await this.dataModelService.dataManagement.makeNewPlan(planInfo.year, planInfo.month);
    } else if (!this.dataModelService.dataModel.persistent.plans.hasOwnProperty(newCurplan)) {
      // load existing plan
      const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
      const planDataJSON = await this.getMonthPlan(wizeFiID, newCurplan);
      this.dataModelService.dataModel.global.plansOldData[newCurplan] = planDataJSON;
      this.dataModelService.dataModel.persistent.plans[newCurplan] = JSON.parse(planDataJSON.toString()); // toString is kludge to avoid type error
    }

    // update curplan
    this.dataModelService.setCurPlan(newCurplan);
  } // changeCurrentPlan

  public getWizeFiDataItem(wizeFiID) {
    return new Promise((resolve, reject) => {
      // define params to guide get operation
      const params = {
        TableName: 'WizeFiData',
        Key: { wizeFiID }
      };

      // get info from DynamoDB table
      this.dataModelService.dataModel.global.docClient.get(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          if (data === null || typeof data !== 'object' || !data.hasOwnProperty('Item') || !data.Item.hasOwnProperty('persistent')) {
            reject('no user found with given wizeFiID (' + wizeFiID + ')');
          } else {
            const wizeFiDataItem = data.Item;
            wizeFiDataItem.persistent = JSON.parse(wizeFiDataItem.persistent);
            resolve(wizeFiDataItem);
          }
        }
      }); // docClient.get
    }); // return Promise
  } // getWizeFiDataItem

  public async getAliasWizeFiDataItem(
    affiliateAlias
  ): Promise<any> /*
  This routine will return a wizeFiDataItem from the WizeFiData DynamoDB table utilizing an affiliateAlias index to access the data.
  */ {
    let lambda: any;

    const obtainWizeFiDataItem = affiliateAlias =>
      new Promise((resolve, reject) => {
        // set params to guide Lambda function invocation
        const payload = {
          action: 'getAliasWizeFiDataItem',
          actionParms: { affiliateAlias }
        };
        const params = {
          FunctionName: 'preliminaryWork',
          Payload: JSON.stringify(payload)
        };

        // invoke Lambda function to process data
        lambda.invoke(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            const wizeFiDataItem = JSON.parse(data.Payload);
            resolve(wizeFiDataItem);
          }
        }); // lambda invoke
      }); // return Promise // obtainWizeFiDataItem

    // configure AWS object
    AWS.config.update({ region: environment.AWSRegion });
    AWS.config.correctClockSkew = true;
    if (!AWS.config.hasOwnProperty('credentials') || AWS.config.credentials == null) {
      // clear credentials from localStorage to eliminate "Missing credentials in config" error
      const setting = 'aws.cognito.identity-id.' + environment.AWSIdentityPoolId;
      window.localStorage.removeItem(setting);

      // configure AWS object for unauthenticated user (one who has not yet logged in)
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: environment.AWSIdentityPoolId });
    }

    // establish lambda object
    lambda = new AWS.Lambda();

    // obtain wizeFiDataItem object
    const wizeFiDataItem = await obtainWizeFiDataItem(affiliateAlias);

    return wizeFiDataItem;
  } // getAliasWizeFiDataItem

  public async fetchinfo() {
    /*
    This routine will retrieve data from WizeFiData (fetchdata) OR wizeFiDrafts (fetchDraft) depending on whether a user dataModel or draft dataModel is to be fetched.

    Notes:
    1. It is assumed that for a draft, this routine updates from DynamoDB a draft that is already in the dataModel.
    2. You must explicitly use the fetchDraft function (and provide the wizeFiID and title values) if you want to load an already existing draft into a dataModel that does not currently contain a draft.
    */
    if (!this.isDraft()) {
      await this.fetchdata();
    } else {
      const title = this.dataModelService.dataModel.title;
      await this.draftService.fetchDraft(title);
    }
  } // fetchinfo

  public async storeinfo() {
    if (!this.isDraft()) {
      await this.storedata();
    } else {
      await this.draftService.storeDraft();
    }
  } // storeinfo

  public async fetchdata() {
    const getWizeFiData = () =>
      new Promise((resolve, reject) => {
        // define params to guide get operation
        const params = {
          TableName: 'WizeFiData',
          Key: { wizeFiID: this.dataModelService.dataModel.global.wizeFiID }
        };

        // get info from DynamoDB table
        this.dataModelService.dataModel.global.docClient.get(params, (err, funcData) => {
          if (err) {
            reject(err);
          } else {
            resolve(funcData);
          }
        }); // docClient.get
      }); // return Promise // getWizeFiData

    // get WizeFiData info
    const data: any = await getWizeFiData();

    // handle results
    if (data === null || typeof data !== 'object' || !data.hasOwnProperty('Item')) {
      // plant WizeFiData information in dataModel
      this.dataModelService.dataModel.global.isNewUser = true;
      this.dataModelService.dataModel.persistent.header.dateCreated = new Date().toISOString();
      this.dataModelService.dataModel.persistent.header.originalDateCreated = this.dataModelService.dataModel.persistent.header.dateCreated;
      this.dataModelService.dataModel.persistent.header.dateUpdated = this.dataModelService.dataModel.persistent.header.dateCreated;
      this.dataModelService.setCurPlan('original');
      this.dataModelService.dataModel.global.planList = ['original'];
      this.dataModelService.dataModel.global.plansOldData = {};
    } else {
      // plant WizeFiData information in dataModel
      const item = data.Item;
      this.dataModelService.dataModel.global.isNewUser = false;

      this.dataModelService.dataModel.topLevelVersion = item.topLevelVersion;
      this.dataModelService.dataModel.affiliateID = item.affiliateID;
      this.dataModelService.dataModel.affiliateAlias = item.affiliateAlias;
      delete this.dataModelService.dataModel.title; // remove any residual draft information from dataModel
      delete this.dataModelService.dataModel.description; // remove any residual draft information from dataModel
      this.dataModelService.dataModel.persistent = JSON.parse(item.persistent);
      this.dataModelService.setCurPlan('original');

      ////////////////////////////////////////////////////////////
      // plant information to keep track of plans in dataModel
      ////////////////////////////////////////////////////////////

      // set up planList
      this.dataModelService.dataModel.global.planList = await this.getPlanList(this.dataModelService.dataModel.global.wizeFiID);
      this.dataModelService.dataModel.global.planList.unshift('original'); // add 'original' to beginning of list
      const lastPlan = this.dataModelService.dataModel.global.planList[this.dataModelService.dataModel.global.planList.length - 1];

      // initialize plansOldData
      this.dataModelService.dataModel.global.plansOldData = {};
      for (const plan of this.dataModelService.dataModel.global.planList) {
        if (plan !== 'original') {
          this.dataModelService.dataModel.global.plansOldData[plan] = '';
        }
      }

      ////////////////////////////////////////////////////////////////////
      // plant WizeFiDataPlans information in memory resident dataModel
      ////////////////////////////////////////////////////////////////////

      // retrieve latest plan
      if (this.dataModelService.dataModel.global.planList.length > 1) {
        const lastPlanDataJSON = await this.getMonthPlan(this.dataModelService.dataModel.global.wizeFiID, lastPlan);
        this.dataModelService.dataModel.persistent.plans[lastPlan] = JSON.parse(lastPlanDataJSON.toString()); // toString is kludge to avoid type error
        this.dataModelService.dataModel.global.plansOldData[lastPlan] = lastPlanDataJSON;
        this.dataModelService.setCurPlan(lastPlan);
      }
    } // if data has Item
  } // fetchdata

  public async storedata() {
    const putWizeFiData = funcItem =>
      new Promise<void>((resolve, reject) => {
        // define params to guide put operation
        const params = {
          TableName: 'WizeFiData',
          Item: funcItem
        };

        // put info into DynamoDB table
        this.dataModelService.dataModel.global.docClient.put(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        }); // docClient.put
      }); // return Promise // putWizeFiData

    let memoryPlanList;

    // set date of last update
    this.dataModelService.dataModel.persistent.header.dateUpdated = new Date().toISOString();

    memoryPlanList = Object.keys(this.dataModelService.dataModel.persistent.plans).sort();

    // store all plans that need to be written to WizeFiDataPlans
    memoryPlanList = Object.keys(this.dataModelService.dataModel.persistent.plans);
    for (const plan of memoryPlanList) {
      if (plan !== 'original') {
        const newPlanData = this.dataModelService.dataModel.persistent.plans[plan];
        const newPlanDataJSON = JSON.stringify(newPlanData);
        if (newPlanDataJSON !== this.dataModelService.dataModel.global.plansOldData[plan]) {
          await this.putMonthPlan(this.dataModelService.dataModel.global.wizeFiID, plan, newPlanData);
        }
      }
    }

    // clone a copy of the persistent data
    const persistent2 = JSON.parse(JSON.stringify(this.dataModelService.dataModel.persistent));

    // remove all plans except original from persistent2 (only original plan is stored in WizeFiData)
    memoryPlanList = Object.keys(persistent2.plans);
    for (const plan of memoryPlanList) {
      if (plan !== 'original') {
        delete persistent2.plans[plan];
      }
    }

    const wizefiId = this.dataModelService.dataModel.global.wizeFiID;
    const plaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;

    // define item to be stored
    const item = {
      wizeFiID: wizefiId,
      topLevelVersion: this.dataModelService.dataModel.topLevelVersion,
      affiliateID: this.dataModelService.dataModel.affiliateID,
      affiliateAlias: this.dataModelService.dataModel.affiliateAlias,
      persistent: JSON.stringify(persistent2)
    };

    // put info in WizeFiData
    await putWizeFiData(item);
    await this.dataModelService.plaidManagement.storePlaidAccounts(wizefiId, '', plaidAccounts);
  }

  public fetchPlanInfo() {
    // fetch information about the plan associated with the draft that is currently in the dataModel (pYYYYMM)

    // initialize
    const dataModel = this.dataModelService.dataModel;

    // extract data from last plan in planList
    const planList = Object.keys(dataModel.persistent.plans).sort();
    const lastndx = planList.length - 1;
    const planName = planList[lastndx];
    const planDate = planName.substr(1, 4) + '-' + planName.substr(5, 2);

    // set return result
    const planInfo = { planName, planDate };
    return planInfo;
  } // fetchPlanInfo

  public async fetchUserData(wizeFiID: string) {
    // bring user data back into dataModel

    // initialize
    const plaidData = this.dataModelService.dataModel.global.plaidData;
    const plaidManagement = this.dataModelService.plaidManagement;

    // retrieve the data
    await this.fetchdata();

    // reset the global wizeFiPlaidInstitutions and wizeFiPlaidAccounts values
    plaidData.wizeFiPlaidInstitutions = await plaidManagement.getWizeFiPlaidInstitutions(wizeFiID);
    plaidData.wizeFiPlaidAccounts = await plaidManagement.fetchWizeFiPlaidAccounts(wizeFiID);
  } // fetchUserData

  public isDraft(): boolean {
    return this.dataModelService.dataModel.hasOwnProperty('title');
  }

  public getDraftTitle() {
    return this.dataModelService.dataModel.hasOwnProperty('title') ? this.dataModelService.dataModel.title : '';
  }

  public async getInstitutionsCounts(
    wizeFiID /*
  This routine will scan through plaidInstitutions information in both user data and draft data and produce information about the number of distinct item_id and institution_id values that are present.
  */
  ) {
    // initialize
    const itemIdCount = {};
    const institutionIdCount = {};
    const plaidManagement = this.dataModelService.plaidManagement;

    let plaidInstitutions;

    // obtain information for user data
    plaidInstitutions = await plaidManagement.getPlaidInstitutions(wizeFiID, '');
    for (const plaidInstitution of plaidInstitutions) {
      const item_id = plaidInstitution.item_id;
      const institution_id = plaidInstitution.institution_id;

      if (!itemIdCount.hasOwnProperty(item_id)) {
        itemIdCount[item_id] = 0;
      }
      itemIdCount[item_id]++;

      if (!institutionIdCount.hasOwnProperty(institution_id)) {
        institutionIdCount[institution_id] = 0;
      }
      institutionIdCount[institution_id]++;
    }

    // obtain information for all drafts
    const draftsInfo = this.dataModelService.dataModel.global.draftsInfo;
    for (const draftInfo of draftsInfo) {
      plaidInstitutions = await plaidManagement.getPlaidInstitutions(wizeFiID, draftInfo.title);
      for (const plaidInstitution of plaidInstitutions) {
        const item_id = plaidInstitution.item_id;
        const institution_id = plaidInstitution.institution_id;

        if (!itemIdCount.hasOwnProperty(item_id)) {
          itemIdCount[item_id] = 0;
        }
        itemIdCount[item_id]++;

        if (!institutionIdCount.hasOwnProperty(institution_id)) {
          institutionIdCount[institution_id] = 0;
        }
        institutionIdCount[institution_id]++;
      }
    }

    const institutionsInfo = { itemIdCount, institutionIdCount };
    return institutionsInfo;
  } // getInstitutionsCounts

  public async getItemIdCount(
    wizeFiID,
    item_id /*
  This routine will return the itemIdCount for the item_id in the given user wizeFiID account (includes tally of user data and draft data)
  */
  ) {
    const institutionsCounts = await this.getInstitutionsCounts(wizeFiID);
    let itemIdCount = 0;
    if (institutionsCounts.itemIdCount.hasOwnProperty(item_id)) {
      itemIdCount = institutionsCounts.itemIdCount[item_id];
    }
    return itemIdCount;
  } // getItemIdCount

  //////////////////////////////////////////////////////////////////////////
  // routines to manage WizeFi transaction information
  //////////////////////////////////////////////////////////////////////////

  public putWizeFiTransactions(wizeFiID, monthDate, wizeFiTransactions) {
    return new Promise((resolve, reject) => {
      // define params to guide put operation
      const params = {
        TableName: 'WizeFiTransactions',
        Item: {
          wizeFiID,
          monthDate,
          wizeFiTransactions: JSON.stringify(wizeFiTransactions)
        }
      };

      // put info in DynamoDB table
      this.dataModelService.dataModel.global.docClient.put(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve('Data has been stored');
        }
      });
    }); // return Promise
  } // putWizeFiTransactions

  public getWizeFiTransactions(wizeFiID, monthDate) {
    return new Promise((resolve, reject) => {
      // define params to guide query operation
      const params = {
        TableName: 'WizeFiTransactions',
        KeyConditionExpression: '#wizeFiID = :wizeFiID_val and #monthDate = :monthDate_val',
        ExpressionAttributeNames: { '#wizeFiID': 'wizeFiID', '#monthDate': 'monthDate' },
        ExpressionAttributeValues: { ':wizeFiID_val': wizeFiID, ':monthDate_val': monthDate }
      };

      this.dataModelService.dataModel.global.docClient.query(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          if (data.Items.length === 0) {
            resolve([]); // return an empty array as the result
          } else {
            const wizeFiTransactions = JSON.parse(data.Items[0].wizeFiTransactions);
            resolve(wizeFiTransactions);
          }
        }
      });
    }); // return Promise
  } // getWizeFiTransactions

  public getwizeFiTransactionsCollection(wizeFiID, startDate, endDate): Promise<TransactionsCollection> {
    return new Promise((resolve, reject) => {
      // define params to guide query operation
      const params = {
        TableName: 'WizeFiTransactions',
        KeyConditionExpression: 'wizeFiID = :wizeFiID AND #monthDate BETWEEN :startDate AND :endDate',
        ExpressionAttributeNames: { '#monthDate': 'monthDate' },
        ExpressionAttributeValues: {
          ':wizeFiID': wizeFiID,
          ':startDate': startDate,
          ':endDate': endDate
        }
      };

      this.dataModelService.dataModel.global.docClient.query(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          // initialize empty result
          const wizeFiTransactionsCollection = {};
          let curDate = startDate;
          while (curDate <= endDate) {
            wizeFiTransactionsCollection[curDate] = [];
            curDate = this.dataModelService.changeDate(curDate, 0, 1, 0).substr(0, 7);
          }

          // add data for each item
          for (const item of data.Items) {
            // console.log(item.wizeFiTransactions);
            const wizeFiTransactions = JSON.parse(item.wizeFiTransactions);
            wizeFiTransactionsCollection[item.monthDate] = wizeFiTransactions;
          }
          resolve(wizeFiTransactionsCollection);
        }
      });
    }); // return Promise
  } // getwizeFiTransactionsCollection

  public getTransactionsFromCollection(transactionCollection): WizefiTransaction[] {
    if (!transactionCollection) {
      return [];
    }
    const months = Object.keys(transactionCollection);
    const allTransactions = [];

    months.forEach(month => {
      transactionCollection[month].forEach(transaction => {
        allTransactions.push(transaction);
      });
    });
    return allTransactions;
  }
} // class DataManagement
