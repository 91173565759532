import * as moment from 'moment';
import { IProfile } from '../interfaces/iProfile.interface';
import { IVerifyResult } from '../interfaces/iVerifyResults.interface';

export class CProfile {
  constructor(public profile: IProfile) {}

  public verifyProfile(): IVerifyResult {
    let hadError = false;
    const messages = [];
    const result: IVerifyResult = { hadError: false, messages: [] };

    if (this.profile.nameFirst === '') {
      hadError = true;
      messages.push('Please enter a value for First Name');
    }

    if (this.profile.nameLast === '') {
      hadError = true;
      messages.push('Please enter a value for Last Name');
    }

    // TODO consider whether to use more complex pattern: [-.\w]+@([\w-]+\.)+[\w-]{2,20}, or research yet more complete patterns
    if (!this.profile.email.match(/^.+@.+\..+$/)) {
      hadError = true;
      messages.push('Please enter a valid Email');
    }

    // if (this.profile.occupation === '')
    // {
    //     hadError = true;  messages.push('Please select a value to identify your Occupation');
    // }

    const matches = /^(\d{4})-(\d{2})-(\d{2})$/.exec(this.profile.birthDate);
    if (matches === null) {
      hadError = true;
      messages.push('Please enter a valid Birth Date');
    } else {
      const thisYear = Number(moment().year());
      const thisMonth = Number(moment().month() + 1);
      const thisDay = Number(moment().date());
      const yr = Number(matches[1]);
      const mo = Number(matches[2]);
      const da = Number(matches[3]);
      const date = moment(this.profile.birthDate);
      if (!date.isValid()) {
        hadError = true;
        messages.push('Birth Date is not valid');
      }
      if (yr < thisYear - 100 || yr > thisYear) {
        hadError = true;
        messages.push('Birth year is not valid');
      }
      if (yr === thisYear && mo > thisMonth) {
        hadError = true;
        messages.push('Birth month is not valid');
      }
      if (yr === thisYear && mo === thisMonth && da > thisDay) {
        hadError = true;
        messages.push('Birth day is not valid');
      }
    }

    if (this.profile.zipCode === '') {
      hadError = true;
      messages.push('Please enter value for Zip code');
    }
    if (this.profile.inflationRate === null) {
      hadError = true;
      messages.push('Please enter an inflation rate estimate');
    }

    result.hadError = hadError;
    result.messages = messages;
    return result;
  } // verifyProfile
} // class CProfile
