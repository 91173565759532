import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ToastaConfig, ToastaService, ToastOptions } from 'ngx-toasta';

// set dataVersion in DataModelService class to match the number shown above in the data-model_nnnn.data import statement

import * as _ from 'lodash';
import * as moment from 'moment';
import { IDataModel } from '../../interfaces/iDataModel.interface';
import { AffiliateManagement } from '../../utilities/affiliate-management.class';
import { AncillaryDataManagement } from '../../utilities/ancillary-data-management.class';
// %// import { AnalyticsReporting } from "../../utilities/analyticsReporting.class";
import { BraintreeManagement } from '../../utilities/braintree-management.class';
import { CafrManagement } from '../../utilities/cafr-management.class';
import { CategoryManagement } from '../../utilities/category-management.class';
import { DataManagement } from '../../utilities/data-management.class';
import { LoginManagement } from '../../utilities/login-management.class';
import { Nav } from '../../utilities/nav.class';
import { PlaidManagement } from '../../utilities/plaid-management.class';
import { ScreenDataManagement } from '../../utilities/screen-data-management.class';
import { SuggestionManagement } from '../../utilities/suggestion-management.class';
import { YodleeManagement } from '../../utilities/yodlee-management.class';
import { categoryExcludeList, dataModel, skipSubcatAttributes, subcategoryExcludeList, specialWizefiCategory } from './data-model_0001.data';
import { DraftService } from '../draft.service';
import { AwsDynamoDbService } from '../aws-dynamo-db.service';

@Injectable({ providedIn: 'root' })
export class DataModelService {
  public dataModel: IDataModel;
  public affiliateManagement: AffiliateManagement;
  public cafrManagement: CafrManagement;
  public braintreeManagement: BraintreeManagement;
  public dataManagement: DataManagement;
  public ancillaryDataManagement: AncillaryDataManagement;
  public suggestionManagement: SuggestionManagement;
  public draftService: DraftService;

  public loginManagement: LoginManagement;
  public yodleeManagement: YodleeManagement;
  public plaidManagement: PlaidManagement;
  public analyticsService: any; // %//
  public screenDataManagement: ScreenDataManagement;
  public categoryManagement: CategoryManagement;
  public nav: Nav;
  public dataVersion = 1; // synchronize this value with data-model_nnnn.data found in import at beginning of this file
  public maxMonthlyPlans = 12; // maximum number of monthly plans that can be stored in addition to the "origin" plan
  public showDebug = false; // %//
  public showDebug2 = false; // %//
  public specialWizefiCategory = specialWizefiCategory;

  public analytics;

  constructor(private router: Router, public toastaService: ToastaService, public toastaConfig: ToastaConfig, public awsService: AwsDynamoDbService) {
    this.toastaConfig.theme = 'default';
    // set data model to initial default configuration
    this.dataModel = dataModel;
    this.affiliateManagement = new AffiliateManagement(this); // to be instantiated later (due to asynchronous nature of this activity)
    this.cafrManagement = new CafrManagement(this);
    this.braintreeManagement = new BraintreeManagement(this);
    this.draftService = new DraftService(this, this.awsService, this.plaidManagement);
    this.dataManagement = new DataManagement(this, this.draftService);
    this.ancillaryDataManagement = new AncillaryDataManagement(this);
    this.suggestionManagement = new SuggestionManagement(this);

    this.loginManagement = new LoginManagement(router, this);
    this.yodleeManagement = new YodleeManagement(this);
    this.plaidManagement = new PlaidManagement(this);
    this.screenDataManagement = new ScreenDataManagement(this);
    this.categoryManagement = new CategoryManagement(this);
    this.nav = new Nav(this.router);
    // %// this.analyticsService = new AnalyticsReporting();

    // data to establish a single instance of a screen data model
    this.dataModel.global.screenDataModel = {
      income: null,
      assets: null,
      assetProtection: null,
      liabilities: null,
      budget: null
    };

    /*
        // this section of code tests the use of an unauthenticated user to get email count for Cognito users
        let email = 'daveland@oru.edu';
        console.log('it takes 5 to 10 seconds for getEmailCount to return a value');
        this.loginManagement.getEmailCount(email)
        .then((emailCount) => {console.log(email + ' emailCount: ', emailCount)})
        .catch((err) => {console.log(err)});
        */

    // %//  \/
    // this section of code tests the use of an unauthenticated user to access the pendingWork Lambda function (to generate a unique affiliateID)
    /*
    this.affiliateManagement.getUniqueAffiliateID()
        .then((affiliateID) => {console.log('DataModelService -- affiliateID: ', affiliateID)})
        .catch((err) => {console.log(err)});
    */

    // the following solution is unnecessary after clearing credentials from localStorage in getUniqueAffiliateID function in affiliateManagement
    this.affiliateManagement
      .getUniqueAffiliateID()
      .then(affiliateID => {
        console.log('DataModelService -- affiliateID: ', affiliateID);
      })
      .catch(err => {
        if (err.statusCode === 400) {
          // kludge solution
          window.location.reload(); // note: reloading the web page resolves the "Missing credentials in config" error situation (run the code a second time via reload)
        } else {
          // console.log('DataModelService -- error: ', err);
        }
      });

    /*
        // note: function is called twice to accomodate problem with "Missing credentials in config" error (things work on the second try [but not always])
        // note: things can work correctly some of the time without doing this "try two times" approach, and sometimes this approach also fails
        // this version worked correctly most often
        this.affiliateManagement.getUniqueAffiliateID()
        .then((affiliateID) => {Promise.resolve()})
        .catch((err) => {Promise.resolve()})
        .then(this.affiliateManagement.getUniqueAffiliateID)
        .then((affiliateID) => {console.log('DataModelService -- affiliateID: ', affiliateID)})
        .catch((err) => {console.log(err)});
    */

    /*
        this.affiliateManagement.getUniqueAffiliateID()
        .then((affiliateID) => {console.log('DataModelService -- affiliateID: ', affiliateID)})
        .catch((err) =>
        {
            // kludge to work around "Missing credentials in config" error (things work on the second try)
            this.affiliateManagement.getUniqueAffiliateID()
            .then((affiliateID) => {console.log('DataModelService -- affiliateID: ', affiliateID)})
            .catch((err) => {console.log('DataModelService -- error: ', err)});
        })
    */
    // %//  /\
  } // constructor

  public sleep(ms) {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  } // sleep

  public changeObjectPropertyName(obj, oldName, newName) {
    obj[newName] = obj[oldName];
    delete obj[oldName];
  } // changeObjectPropertyName

  // analytics
  public AnalyticsTrackPage() {
    // console.log("tracking a page");
    // %// return this.analyticsService.view();
  }

  public AnalyticsTrackEvent(event, data) {
    // console.log("tracking event", event, data);
    // %// return this.analyticsService.track(event, data);
  }

  public analyticsIdentifyUser(uid) {
    // console.log("identify user", uid);
    // %// return this.analyticsService.identify(uid);
  }

  public initializeAffiliateManagement(): Promise<any> {
    // this.affiliateManagement = new AffiliateManagement(this);
    return this.affiliateManagement.loadAffiliateTree();
  } // initializeAffiliateManagement

  public showErrorMessage(message: string, timeout?: number) {
    this.showMessage('error', message, timeout);
  }

  public showMessage(mode: string, msg: string, timeout: number = 3000) {
    const toastOptions: ToastOptions = {
      title: '', // A string or html for the title
      msg, // A string or html for the message
      showClose: true, // Whether to show a close button
      showDuration: true, // Whether to show a progress bar
      theme: 'default', // The theme to apply to this toast
      timeout, // Time to live until toast is removed. 0 is unlimited
      onAdd: () => {
        // console.log("added toast");
      }, // Function that gets called after this toast is added
      onRemove: () => {
        // console.log("remove toast");
      } // Function that gets called after this toast is removed
    };
    // console.log("DataModelService.showMessage"); // %//
    switch (mode) {
      case 'default':
        this.toastaService.default(toastOptions);
        break;
      case 'info':
        this.toastaService.info(toastOptions);
        break;
      case 'success':
        this.toastaService.success(toastOptions);
        break;
      case 'wait':
        this.toastaService.wait(toastOptions);
        break;
      case 'error':
        this.toastaService.error(toastOptions);
        break;
      case 'warning':
        this.toastaService.warning(toastOptions);
        break;
    } // switch
  } // showMessage

  public makeErrorObject(msg, err) {
    let systemMessage = 'unknown message';
    if (typeof err === 'string') {
      systemMessage = err;
    } else if (err !== null && typeof err === 'object') {
      if (err.hasOwnProperty('message')) {
        systemMessage = err.message;
      }
      // TODO accomodate possibility of whether err object can have message string in other than err.message
    }

    const customErr = new Error(msg + ' -- ' + err.message);

    // TODO determine whether to remove first line from stack stace
    // custom_err.stack = custom_err.stack.substring(custom_err.stack.indexOf("\n") + 1);  // remove first line from stack trace (which contains error message)
    return customErr;
  } // makeErrorObject

  public showMessages(mode: string = 'info', messages: string[], timeout = 3000) {
    let msg = '';
    let separator = '';
    for (const message of messages) {
      msg = msg + separator + message;
      separator = '<br>';
    }
    this.showMessage(mode, msg, timeout);
  } // showMessages

  public setPlanDate(plan) {
    let dateCreated, year, month;

    if (plan === 'original') {
      // dateCreated format is YYYY-MM-DDTHH:MM:SS.SSSZ
      dateCreated = this.dataModel.persistent.header.dateCreated;
      year = dateCreated.substr(0, 4);
      month = dateCreated.substr(5, 2);
    } else {
      // plan format is pYYYYMM
      year = plan.substr(1, 4);
      month = plan.substr(5, 2);
    }

    return year + '-' + month;
  } // setPlanDate

  public setCurPlan(plan) {
    const latestPlan = this.dataModel.global.planList[this.dataModel.global.planList.length - 1];
    this.dataModel.persistent.header.isLatestPlan = plan == latestPlan;

    this.dataModel.persistent.header.curplan = plan;
    this.dataModel.persistent.header.curplanYearMonth = this.setPlanDate(plan);
  } // setCurPlan

  public viewData() {
    let saveLambda, saveDocClient, saveS3: any;

    // remove lambda, docClient, and s3 to eliminate "circular reference" error upon doing JSON.stringify
    saveLambda = this.dataModel.global.lambda;
    this.dataModel.global.lambda = 'lambda object';

    saveDocClient = this.dataModel.global.docClient;
    this.dataModel.global.docClient = 'docClient object';

    saveS3 = this.dataModel.global.s3;
    this.dataModel.global.s3 = 's3 object';

    // report information
    console.log('dataModel: ', this.dataModel);
    console.log('tree: ', this.affiliateManagement);

    // restore AWS service objects
    this.dataModel.global.lambda = saveLambda;
    this.dataModel.global.docClient = saveDocClient;
    this.dataModel.global.s3 = saveS3;
  } // viewData

  public getBraintreeData() {
    return new Promise((resolve, reject) => {
      const processBraintreeData = braintreeData => {
        this.dataModel.global.braintreeData = braintreeData;
        return Promise.resolve();
      }; // processBraintreeData

      const customerID = this.dataModel.global.wizeFiID;

      this.braintreeManagement
        .getBraintreeData(customerID)
        .then(processBraintreeData)
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    }); // return Promise
  } // getBraintreeData

  public updateBraintreeData(result) {
    return new Promise((resolve, reject) => {
      this.getBraintreeData()
        .then(() => {
          resolve(result);
        })
        .catch(err => {
          reject(err);
        });
    }); // return Promise
  } // updateBraintreeData

  public changeWizeFiID(oldWizeFiID, newWizeFiID) {
    return new Promise((resolve, reject) => {
      let payload, params;

      // set params to guide function invocation
      payload = {
        action: 'changeWizeFiID',
        actionParms: { oldWizeFiID, newWizeFiID }
      };
      params = {
        FunctionName: 'manageWizeFiData',
        Payload: JSON.stringify(payload)
      };

      // invoke lambda function to process data
      this.dataModel.global.lambda.invoke(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const funcPayload = JSON.parse(data.Payload);
          resolve(funcPayload);
        }
      }); // lambda invoke
    }); // return Promise
  } // changeWizeFiID

  public findWizeFiUser(parms) {
    return new Promise((resolve, reject) => {
      let payload, params;

      // set params to guide function invocation
      payload = {
        action: 'findWizeFiUser',
        actionParms: parms
      };
      params = {
        FunctionName: 'manageWizeFiData',
        Payload: JSON.stringify(payload)
      };

      // invoke lambda function to process data
      this.dataModel.global.lambda.invoke(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const funcPayload = JSON.parse(data.Payload);
          resolve(funcPayload);
        }
      }); // lambda invoke
    }); // return Promise
  } // findWizeFiUser

  public getSubcategoryList(subcat: string): string[] {
    const list: any[] = [];
    let key = '';
    const plan = this.dataModel.persistent.header.curplan;
    let data: any;

    switch (subcat) {
      case 'budgetSubcategory':
        key = 'budget';
        break;
      case 'incomeSubcategory':
        key = 'income';
        break;
    }
    // if no key set, return empty array
    if (key === '') {
      return list;
    }

    // fill array with subcat value:label pairs
    data = JSON.parse(JSON.stringify(this.dataModel.persistent.plans[plan][key]));
    _.each(data, (funcSubcat, k) => {
      if (k !== 'label' && k !== 'attributeName') {
        list.push({ value: k, label: funcSubcat.label });
      }
    });

    return list;
  } // getSubcategoryList

  public getdata(item) {
    let value: any;
    const plan = this.dataModel.persistent.header.curplan;
    switch (item) {
      case 'header':
        value = JSON.parse(JSON.stringify(this.dataModel.persistent.header));
        break;
      case 'settings':
        value = JSON.parse(JSON.stringify(this.dataModel.persistent.settings));
        break;
      case 'profile':
        value = JSON.parse(JSON.stringify(this.dataModel.persistent.profile));
        break;
      case 'income':
        value = JSON.parse(JSON.stringify(this.dataModel.persistent.plans[plan].income));
        break;
      case 'assets':
        value = JSON.parse(JSON.stringify(this.dataModel.persistent.plans[plan].assets));
        break;
      case 'assetProtection':
        value = JSON.parse(JSON.stringify(this.dataModel.persistent.plans[plan].assetProtection));
        break;
      case 'liabilities':
        value = JSON.parse(JSON.stringify(this.dataModel.persistent.plans[plan].liabilities));
        break;
      case 'budget':
        value = JSON.parse(JSON.stringify(this.dataModel.persistent.plans[plan].budget));
        break;
      case 'assets2':
        value = JSON.parse(JSON.stringify(this.dataModel.persistent.plans[plan].assets2));
        break;
      default:
        value = null;
        const msg = item + ' not found in getdata in DataModelService';
        console.log(msg);
        this.showMessage('error', msg);
    }
    return value;
  } // getdata

  public putdata(item, value) {
    const plan = this.dataModel.persistent.header.curplan;
    switch (item) {
      case 'header':
        this.dataModel.persistent.header = JSON.parse(JSON.stringify(value));
        break;
      case 'settings':
        this.dataModel.persistent.settings = JSON.parse(JSON.stringify(value));
        break;
      case 'profile':
        this.dataModel.persistent.profile = JSON.parse(JSON.stringify(value));
        break;
      case 'income':
        this.dataModel.persistent.plans[plan].income = JSON.parse(JSON.stringify(value));
        break;
      case 'assets':
        this.dataModel.persistent.plans[plan].assets = JSON.parse(JSON.stringify(value));
        break;
      case 'assetProtection':
        this.dataModel.persistent.plans[plan].assetProtection = JSON.parse(JSON.stringify(value));
        break;
      case 'liabilities':
        this.dataModel.persistent.plans[plan].liabilities = JSON.parse(JSON.stringify(value));
        break;
      case 'budget':
        this.dataModel.persistent.plans[plan].budget = JSON.parse(JSON.stringify(value));
        break;
      case 'assets2':
        this.dataModel.persistent.plans[plan].assets2 = JSON.parse(JSON.stringify(value));
        break;
      default:
        value = null;
        const msg = item + ' not found in putdata in DataModelService';
        console.log(msg);
        this.showMessage('error', msg);
    }
  } // putdata

  public getCurrentYear(): number {
    return new Date().getFullYear(); // return 4 digit year
  } // getCurrentYear

  public getCurrentMonth(): number {
    return new Date().getMonth() + 1;
  } // getCurrentMonth

  public addSubcategoryShadowData(category: any, destSubcategory: string, selectedSubcategory: string, field: string): number {
    let sum = 0;
    for (const subcat of Object.keys(category)) {
      if (skipSubcatAttributes.indexOf(subcat) === -1) {
        for (const account of category[subcat].accounts) {
          if (typeof account === 'object' && account.hasOwnProperty(destSubcategory) && account.hasOwnProperty(field)) {
            if (selectedSubcategory === '' || account[destSubcategory].val === selectedSubcategory) {
              const val = account[field].val;
              if (typeof val === 'number') {
                sum += val;
              } else if (typeof val === 'string' && !isNaN(+val)) {
                sum += Number(val);
              }
              // %//   \/
              if (this.showDebug) {
                console.log('addSubcategoryShadowData -- ' + subcat + ',' + account.accountName.val + ': ' + val);
              }
              // %//   /\
            }
          }
        }
      }
    }
    return sum;
  } // addSubcategoryShadowData

  public getSubcategorySum(
    category: any,
    subcat: string,
    field: string,
    assets: any = null,
    liabilities: any = null,
    assetProtection: any = null
  ): number {
    let sum, val: number;
    let monthlyAmount, monthlyMinimum, fieldVal: number;
    let haveMonthlyAmount: boolean;

    // add in data from this category
    sum = 0;
    for (const account of category[subcat].accounts) {
      // gather values necessary to do work
      monthlyAmount = 0;
      haveMonthlyAmount = false;
      if (account.hasOwnProperty('monthlyAmount')) {
        monthlyAmount = account.monthlyAmount.val;
        haveMonthlyAmount = true;
      }
      monthlyMinimum = 0;
      if (account.hasOwnProperty('monthlyMinimum')) {
        monthlyMinimum = account.monthlyMinimum.val;
      }
      fieldVal = 0;
      if (account.hasOwnProperty(field)) {
        fieldVal = account[field].val;
      }

      // process value as appropriate
      val = field === 'monthlyAmount' ? (haveMonthlyAmount ? monthlyAmount : monthlyMinimum) : fieldVal;

      if (typeof val === 'number') {
        sum += val;
      } else if (typeof val === 'string' && !isNaN(+val)) {
        sum += Number(val);
      }
    }

    // %//  \/
    const savesum = sum;
    if (this.showDebug) {
      console.log('getSubcategorySum =============================================================');
      console.log('getSubcategorySum,' + category.attributeName + ',' + subcat + ',' + field + '-- before shadows: ' + sum);
    }
    // %//  /\

    // deal with shadow data
    if (category.attributeName === 'income' || category.attributeName === 'budget') {
      // determine which type of data is required
      let destSubcategory = 'unknown';
      let shadowField = 'unknown';
      if (category.attributeName === 'income') {
        destSubcategory = 'incomeSubcategory';
        shadowField = 'monthlyIncome';
      }
      if (category.attributeName === 'budget') {
        destSubcategory = 'budgetSubcategory';
        shadowField = 'monthlyMinimum';
      }

      // add in data from shadow fields included from assets
      if (assets !== null) {
        sum += this.addSubcategoryShadowData(assets, destSubcategory, subcat, shadowField);
      }

      // add in data from shadow fields included from liabilities
      if (liabilities !== null) {
        sum += this.addSubcategoryShadowData(liabilities, destSubcategory, subcat, shadowField);
      }

      // add in data from shadow fields included from assetProtection
      if (assetProtection !== null) {
        sum += this.addSubcategoryShadowData(assetProtection, destSubcategory, subcat, shadowField);
      }
    }

    // %//  \/
    if (this.showDebug) {
      console.log('getSubcategorySum,' + category.attributeName + ',' + subcat + ',' + field + '-- after shadows:  ' + sum + '  ' + (sum - savesum));
    }
    // %//  /\

    return sum;
  } // getSubcategorySum

  public getStepSubcategorySum(step: number, liabilities: any, assets: any, assetProtection: any = null): number {
    let sum = 0;
    switch (step) {
      case 2:
        // process liability values (isNonProductiveDebt)
        for (const subcat of Object.keys(liabilities)) {
          if (skipSubcatAttributes.indexOf(subcat) === -1) {
            for (const account of liabilities[subcat].accounts) {
              let isNonProductiveDebt = true;
              if (typeof account === 'object') {
                isNonProductiveDebt = account.productivity.val === 'Non-productive';
              }
              if (isNonProductiveDebt && account.hasOwnProperty('monthlyAmount')) {
                const monthlyAmount = account.monthlyAmount.val;
                let monthlyMinimum = 0;
                if (account.hasOwnProperty('monthlyMinimum') && account.hasOwnProperty('budgetSubcategory')) {
                  monthlyMinimum = account.monthlyMinimum.val;
                }
                sum += monthlyAmount - monthlyMinimum;
              }
            }
          }
        }
        break;
      case 4:
        // process liability values (!isNonProductiveDebt)
        // %//   \/
        if (this.showDebug2) {
          console.log(' ');
          console.log('dataModelService');
        }
        // %//   /\
        for (const subcat of Object.keys(liabilities)) {
          if (skipSubcatAttributes.indexOf(subcat) === -1) {
            for (const account of liabilities[subcat].accounts) {
              let isNonProductiveDebt = true;
              if (typeof account === 'object') {
                isNonProductiveDebt = account.productivity.val === 'Non-productive';
              }
              if (!isNonProductiveDebt && account.hasOwnProperty('monthlyAmount')) {
                const monthlyAmount = account.monthlyAmount.val;
                let monthlyMinimum = 0;
                if (account.hasOwnProperty('monthlyMinimum') && account.hasOwnProperty('budgetSubcategory')) {
                  monthlyMinimum = account.monthlyMinimum.val;
                }
                sum += monthlyAmount - monthlyMinimum;
                // %//   \/
                if (this.showDebug2) {
                  console.log(
                    step + ',liabilities,' + subcat + ',' + account.accountName.val + ': ' + (monthlyAmount - monthlyMinimum) + '  ' + monthlyAmount
                  );
                }
                // %//   /\
              }
            }
          }
        }

        // process assets values (for subcategory not emergencySavings and not cashReserves)
        // %//  \/
        if (this.showDebug) {
          console.log(' ');
          console.log(' ');
          console.log('start sum: ' + sum);
        }
        // %//  /\
        for (const subcat of Object.keys(assets)) {
          if (skipSubcatAttributes.indexOf(subcat) === -1 && subcat !== 'emergencySavings' && subcat !== 'cashReserves') {
            for (const account of assets[subcat].accounts) {
              if (typeof account === 'object' && account.hasOwnProperty('monthlyAmount')) {
                const monthlyAmount = account.monthlyAmount.val;
                let monthlyMinimum = 0;
                if (account.hasOwnProperty('monthlyMinimum') && account.hasOwnProperty('budgetSubcategory')) {
                  monthlyMinimum = account.monthlyMinimum.val;
                }
                // %//  \/
                if (this.showDebug) {
                  console.log(subcat + ',' + account.accountName.val + ': ' + (monthlyAmount - monthlyMinimum));
                }
                // %//  /\
                sum += monthlyAmount - monthlyMinimum;
                // %//   \/
                if (this.showDebug2) {
                  console.log(
                    step + ',assets,' + subcat + ',' + account.accountName.val + ': ' + (monthlyAmount - monthlyMinimum) + '  ' + monthlyAmount
                  );
                }
                // %//   /\
              }
            }
          }
        }
        // %//  \/
        if (this.showDebug) {
          console.log('end sum:   ' + sum); // %//
        }
        // %//  /\
        // process assetProtection values
        for (const subcat of Object.keys(assetProtection)) {
          if (skipSubcatAttributes.indexOf(subcat) === -1) {
            for (const account of assetProtection[subcat].accounts) {
              let monthlyAmount = 0;
              let haveMonthlyAmount = false;
              if (account.hasOwnProperty('monthlyAmount')) {
                monthlyAmount = account.monthlyAmount.val;
                haveMonthlyAmount = true;
              }
              let monthlyMinimum = 0;
              if (account.hasOwnProperty('monthlyMinimum') && account.hasOwnProperty('budgetSubcategory')) {
                monthlyMinimum = account.monthlyMinimum.val;
              }

              const adjustedMonthlyAmount = !haveMonthlyAmount ? monthlyMinimum : monthlyAmount - monthlyMinimum;
              // %//   \/
              if (this.showDebug) {
                console.log(subcat + ',' + account.accountName.val + ': ' + adjustedMonthlyAmount);
              }
              // %//   /\
              sum += adjustedMonthlyAmount;
              // %//   \/
              if (this.showDebug2) {
                console.log(
                  step + ',assetProtection,' + subcat + ',' + account.accountName.val + ': ' + adjustedMonthlyAmount + '  ' + monthlyAmount
                );
              }
              // %//   /\
            } // for actndx
          } // if
        } // for subcat
        break;
    } // switch
    return sum;
  } // getStepSubcategorySum

  public processCategorySum(category: any, field: string, wantNonProductiveDebt: boolean = null): number {
    let isNonProductiveDebt: boolean;
    let monthlyAmount, monthlyMinimum, fieldVal: number;
    let haveMonthlyAmount: boolean;
    let sum, val: number;

    sum = 0;
    for (const subcat of Object.keys(category)) {
      if (skipSubcatAttributes.indexOf(subcat) === -1 && subcat !== 'emergencySavings' && subcat !== 'cashReserves') {
        for (const account of category[subcat].accounts) {
          // gather values necessary to do work
          isNonProductiveDebt = true;
          if (typeof account === 'object') {
            isNonProductiveDebt = account.productivity.val === 'Non-productive';
          }
          monthlyAmount = 0;
          haveMonthlyAmount = false;
          if (account.hasOwnProperty('monthlyAmount')) {
            monthlyAmount = account.monthlyAmount.val;
            haveMonthlyAmount = true;
          }
          monthlyMinimum = 0;
          if (account.hasOwnProperty('monthlyMinimum')) {
            monthlyMinimum = account.monthlyMinimum.val;
          }
          fieldVal = 0;
          if (account.hasOwnProperty(field)) {
            fieldVal = account[field].val;
          }

          // process value as appropriate
          if (wantNonProductiveDebt === null || isNonProductiveDebt === wantNonProductiveDebt) {
            val = field === 'monthlyAmount' ? (haveMonthlyAmount ? monthlyAmount : monthlyMinimum) : fieldVal;

            if (typeof val === 'number') {
              sum += val;
            } else if (typeof val === 'string' && !isNaN(+val)) {
              sum += Number(val);
            }
          }
        } // for actndx
      } // if
    } // for subcat
    return sum;
  } // processCategorySum

  public getStepFieldSum(step: number, liabilities: any, assets: any, assetProtection: any, field: string): number {
    let sum = 0;
    switch (step) {
      case 2:
        // process liability values (isNonProductiveDebt)
        sum += this.processCategorySum(liabilities, field, true);
        break;
      case 4:
        // process liability values (!isNonProductiveDebt)
        sum += this.processCategorySum(liabilities, field, false);

        // process assets values (for subcategory not emergencySavings and not cashReserves)
        sum += this.processCategorySum(assets, field);

        // process assetProtection values
        sum += this.processCategorySum(assetProtection, field);
        break;
    } // switch
    return sum;
  } // getStepSubcategorySum

  public getCategorySum(category: any, field: string, assets: any = null, liabilities: any = null, assetProtection: any = null): number {
    let monthlyAmount, monthlyMinimum, fieldVal: number;
    let haveMonthlyAmount: boolean;
    let sum, val: number;

    sum = 0;
    for (const subcat of Object.keys(category)) {
      if (skipSubcatAttributes.indexOf(subcat) === -1) {
        // %//  \/
        if (this.showDebug && category.attributeName === 'budget') {
          console.log('getCategorySum -- process subcategory: ' + subcat); // %//
        }
        // %//  /\
        for (const account of category[subcat].accounts) {
          // gather values necessary to do work
          monthlyAmount = 0;
          haveMonthlyAmount = false;
          if (account && account.hasOwnProperty('monthlyAmount')) {
            monthlyAmount = account.monthlyAmount.val;
            haveMonthlyAmount = true;
          }
          monthlyMinimum = 0;
          if (account && account.hasOwnProperty('monthlyMinimum')) {
            monthlyMinimum = account.monthlyMinimum.val;
          }
          fieldVal = 0;
          if (account && account.hasOwnProperty(field)) {
            fieldVal = account[field].val;
          }

          // process value as appropriate
          val = field === 'monthlyAmount' ? (haveMonthlyAmount ? monthlyAmount : monthlyMinimum) : fieldVal;

          if (typeof val === 'number') {
            sum += val;
          } else if (typeof val === 'string' && !isNaN(+val)) {
            sum += Number(val);
          }
        } // for actndx

        // %//  \/
        const savesum = sum;
        if (this.showDebug && category.attributeName === 'budget') {
          console.log('getCategorySum *************************************************************');
          console.log('getCategorySum,' + category.attributeName + ',' + field + '-- before shadows: ' + sum);
        }
        // %//  /\
        // deal with shadow data
        if (category.attributeName === 'income' || category.attributeName === 'budget') {
          // determine which type of data is required
          let destSubcategory = 'unknown';
          let shadowField = 'unknown';
          if (category.attributeName === 'income') {
            destSubcategory = 'incomeSubcategory';
            shadowField = 'monthlyIncome';
          }
          if (category.attributeName === 'budget') {
            destSubcategory = 'budgetSubcategory';
            shadowField = 'monthlyMinimum';
          }

          // add in data from shadow fields included from assets
          if (assets !== null) {
            sum += this.addSubcategoryShadowData(assets, destSubcategory, subcat, shadowField);
          }

          // add in data from shadow fields included from liabilities
          if (liabilities !== null) {
            sum += this.addSubcategoryShadowData(liabilities, destSubcategory, subcat, shadowField);
          }

          // add in data from shadow fields included from asset protection
          if (assetProtection !== null) {
            sum += this.addSubcategoryShadowData(assetProtection, destSubcategory, subcat, shadowField);
          }
        }
        // %//  \/
        if (this.showDebug && category.attributeName === 'budget') {
          console.log('getCategorySum,' + category.attributeName + ',' + field + '-- after shadows:  ' + sum + '  ' + (sum - savesum));
        }
        // %//  /\
      } // if
    } // for subcat

    return sum;
  } // getCategorySum

  public changeDate(ISOdate, years, months, days) {
    const m = moment(ISOdate, ['YYYY', 'YYYY-MM', 'YYYY-MM-DD']);
    /*  const m = moment.utc(
      moment(ISOdate, ["YYYY", "YYYY-MM", "YYYY-MM-DD"]).format()
    ); // identify expected input formats */
    m.add({ years, months, days });
    return m.format();
  } // changeDate

  public cd(monthDate) {
    const monthAbbrev = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const mthndx = Number(monthDate.substr(5, 2)) - 1;
    return monthAbbrev[mthndx] + ' ' + monthDate.substr(0, 4);
  } // cd

  public visitAllWizeFiAccounts(
    plan,
    visitWizeFiAccount,
    result /*
  This routine will scan through all accounts in a user WizeFi schema.  It is possible to change values in those accounts, and/or retrieve data from those accounts.

  @plan = identifies which plan to work with in this function

  @visitWizeFiAccount = a function that is invoked by the visitAllWizeFiAccounts function.  Upon the visit to each account, this function is invoked.
  The function makes any data changes specified in that function, and populates the result variable with whatever information is specified in that function.
  The full list of the parameters to the visitWizeFiAccount function are illustrated below:

  visitWizeFiAccount(plan,category,subcategory,acntndx,account,result);

  @result = this parameter provides a mechanism to obtain data from all of the accounts that have been visited.
  Typically the result value is initialized to an empty array or an empty object if values are to be returned, or null if no results are expected.
  The visitWizeFiAccount function argument of the visitAllWizeFiAccounts function takes whatever action is necessary to properly populate the result variable.

  Examples of visitWizeFiAccount functions:

  let visitWizeFiAccount1 = (plan,category,subcategory,acntndx,account,result) =>
  // make a list of all wizeFiCategory values in the user WizeFi schema (update note -- wizeFiCategory is now an attribute of an account)
  {
      const wizeFiCategory = this.dataModelService.categoryManagement.makeWizeFiCategory(category,subcategory, account.accountID.val);
      result.push(wizeFiCategory);
  };  // visitWizeFiAccount1

  let visitWizeFiAccount2 = (plan,category,subcategory,acntndx,account,result) =>
  // set actualMonthlyAmount to 0 for all accounts that have an actualMonthlyAmount attribute
  {
      if (account.hasOwnProperty('actualMonthlyAmount'))
      {
          account.actualMonthlyAmount.val = 0;
      }
  };  // visitWizeFiAccount2
  */
  ) {
    // initialize
    const currentPlan = this.dataModel.persistent.plans[plan];

    if (!currentPlan) {
      return;
    }

    // invoke the visitWizeFiAccount function for all accounts in the user WizeFi schema
    for (const category of Object.keys(currentPlan)) {
      if (categoryExcludeList.indexOf(category) === -1) {
        for (const subcategory of Object.keys(currentPlan[category])) {
          if (subcategoryExcludeList.indexOf(subcategory) === -1) {
            for (let acntndx = 0; acntndx < currentPlan[category][subcategory].accounts.length; acntndx++) {
              // visit the current account
              const account = currentPlan[category][subcategory].accounts[acntndx];
              visitWizeFiAccount(plan, category, subcategory, acntndx, account, result);
            } // for acntndx
          } // if include subcategory
        } // for subcategory
      } // if include category
    } // for category
  } // visitAllWizeFiAccounts

  public generateIDcode(length): string {
    /*
  This routine provides a randomly generated string of lower case letters.  The length parameter identifies how many letters to have in the string.

  The output of this routine can be used to represent things like an ID value for manual institutions, accounts, and transactions.
  */
    const letters = 'abcdefghijklmnopqrstuvwxyz';
    let IDcode = '';

    for (let i = 1; i <= length; i++) {
      IDcode += letters[Math.floor(Math.random() * 26)];
    }

    return IDcode;
  } // generateIDcode
} // class DataModelService
