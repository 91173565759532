import { Component, OnInit } from '@angular/core';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { categoryExcludeList, subcategoryExcludeList } from '../../../services/data-model/data-model_0001.data';

@Component({
  selector: 'app-admin-view-budget-data',
  templateUrl: './admin-view-budget-data.component.html',
  styleUrls: ['./admin-view-budget-data.component.scss'],
  host: {
    '(window:resize)': 'refreshLayout($event)'
  }
})
export class AdminViewBudgetDataComponent implements OnInit {
  public plan: string; // current working plan
  public planList: string[]; // list of actual plans that are present in the WizeFi data
  public firstMonth: string; // first month in planList  (value derived from list of plans in user data)
  public lastMonth: string; // last month in planList   (value derived from list of plans in user data)
  public startMonth: string; // first month in exported data  (value entered by user on screen)
  public endMonth: string; // last month in exported data   (value entered by user on screen)
  public selectedMonths: string; // set to 'all' or 'actual' (selects which months to include in reported data)
  public actualPlanList: any[]; // all plans that are actually present in the data for this user within the range of dates requested by the user
  public showBudgetData: boolean; // indicates whether to show budget data on the screen
  public reportMonthList: any[]; // all months within the range of dates requested by the user (all months or only months with actual data)
  public groupReportList: string[]; // list of data groups to process

  public data: any; // object that holds computed budget data for use in HTML on screen and in making CSV file
  public headingGroups: any;
  public headingGroupName: any;
  public totalGroupName: any;
  public groupsWithTotalData: string[];
  public groupsWithDetailData: string[];

  public categoryDiv: any;
  public subcategoryDiv: any;
  public accountDiv: any;
  public topLeftDiv: any;
  public topLeftTable: any;
  public topRightDiv: any;
  public topRightTable: any;
  public bottomLeftDiv: any;
  public bottomRightDiv: any;
  public bottomLeftTable: any;
  public bottomRightTable: any;

  constructor(public dataModelService: DataModelService) {
    this.groupReportList = [
      'INCOME',
      'INCOME-SUBTOTAL',
      'CAFR-EmergencyFund',
      'CAFR-Debts',
      'CAFR-CashReserves',
      'CAFR-Investments',
      'CAFR-SUBTOTAL',
      'BUDGET',
      'BUDGET-SUBTOTAL',
      'TOTAL'
    ];

    this.headingGroups = ['INCOME', 'CAFR-EmergencyFund', 'BUDGET'];

    this.headingGroupName = {
      INCOME: 'INCOME',
      'CAFR-EmergencyFund': 'CAFR',
      BUDGET: 'BUDGET'
    };

    this.groupsWithTotalData = ['INCOME-SUBTOTAL', 'CAFR-SUBTOTAL', 'BUDGET-SUBTOTAL', 'TOTAL'];

    this.totalGroupName = {
      'INCOME-SUBTOTAL': 'SUBTOTAL',
      'CAFR-SUBTOTAL': 'SUBTOTAL',
      'BUDGET-SUBTOTAL': 'SUBTOTAL',
      TOTAL: 'TOTAL'
    };

    this.groupsWithDetailData = ['INCOME', 'CAFR-EmergencyFund', 'CAFR-Debts', 'CAFR-CashReserves', 'CAFR-Investments', 'BUDGET'];
  } // constructor

  public ngOnInit() {
    const ngOnInit2 = async () => {
      const dateCreated = this.dataModelService.dataModel.persistent.header.dateCreated;
      const originalMonth = dateCreated.substr(0, 4) + '-' + dateCreated.substr(5, 2);
      this.planList = this.dataModelService.dataModel.global.planList; // 'original' plus data from DynamoDB table WizeFiPlans
      const lastPlan = this.planList[this.planList.length - 1];
      this.plan = lastPlan; // also used in display of basic data

      this.firstMonth = originalMonth;
      this.lastMonth = lastPlan === 'original' ? originalMonth : lastPlan.substr(1, 4) + '-' + lastPlan.substr(5, 2);
      this.startMonth = this.dataModelService.changeDate(this.lastMonth, 0, -11, 0).substr(0, 7); // default to show 12 months of data
      this.endMonth = this.lastMonth;
      this.selectedMonths = 'actual';
      this.showBudgetData = false; // need to set this to false here to avoid error when budget data does not yet exist

      await this.makeExportBudgetData(this.startMonth, this.endMonth, this.selectedMonths);
      this.showBudgetData = true; // setting this here after budget data has been created makes things work correctly
      setTimeout(() => {
        this.setScreenLayout();
      }, 500); // this kludge is necessary to wait for view content to be fully populated

      console.log('budget data: ', this.data); // %//
    }; // ngOnInit2

    console.log('ngOnInit -- start'); // %//
    ngOnInit2()
      .then(() => {
        console.log('ngOnInit -- end');
      }) // %//
      .catch(err => {
        console.log('error in ViewBudgetDataComponent.ngOnInit: ', err);
      });
  } // ngOnInit

  public refreshData() {
    const refreshData2 = async () => {
      await this.makeExportBudgetData(this.startMonth, this.endMonth, this.selectedMonths);
      setTimeout(() => {
        this.setScreenLayout();
      }, 500); // this kludge is necessary to wait for view content to be fully populated
      console.log('data: ', this.data); // %//
    }; // refreshData2

    refreshData2().catch(err => {
      console.log('error in ViewBudgetDataComponent.refreshData: ', err);
    });
  } // refreshData

  public refreshLayout() {
    this.setScreenLayout();
  } // refreshLayout

  public handleScroll() {
    this.topRightDiv.scrollLeft = this.bottomRightDiv.scrollLeft;
    this.bottomLeftDiv.scrollTop = this.bottomRightDiv.scrollTop;
  } // handleScroll

  public haveGroupHeading(group) {
    return this.headingGroups.indexOf(group) !== -1;
  } // haveGroupHeading

  public haveTotalData(group) {
    return this.groupsWithTotalData.indexOf(group) !== -1;
  } // haveTotalData

  public haveGrandTotalGroup(group) {
    return group === 'TOTAL';
  } // haveGrandTotalGroup

  public haveDetailData(group) {
    return this.groupsWithDetailData.indexOf(group) !== -1;
  } // haveDetailData

  public haveTotalYearMonth(group, yearMonth) {
    if (group.indexOf('CAFR') !== -1) {
      group = 'CAFR-SUBTOTAL';
    }
    return this.data[group].hasOwnProperty(yearMonth);
  } // haveTotalYearMonth

  public haveDetailYearMonth(group, category, subcategory, account, yearMonth) {
    return this.data[group][category][subcategory][account].hasOwnProperty(yearMonth);
  } // haveDetailYearMonth

  public objectKeys(obj) {
    if (obj === undefined) {
      return [];
    } // %//  kludge to enable code to run further, until we can figure out why we get an undefined object
    return Object.keys(obj);
  } // objectKeys

  public setColorName(group, category, subcategory, account, yearMonth, valueName) {
    let color = 'black';

    if (
      this.data.hasOwnProperty(group) &&
      this.data[group].hasOwnProperty(category) &&
      this.data[group][category][subcategory].hasOwnProperty(account) &&
      this.data[group][category][subcategory][account].hasOwnProperty(yearMonth) &&
      this.data[group][category][subcategory][account][yearMonth].hasOwnProperty(valueName)
    ) {
      color = this.data[group][category][subcategory][account][yearMonth][valueName] < 200 ? 'red' : 'lime';
    }

    return color;
  } // setColorName

  public setScreenLayout() {
    if (this.showBudgetData) {
      // initialize
      console.log('setScreenLayout -- start'); // %//
      this.categoryDiv = document.getElementById('categoryDiv');
      this.subcategoryDiv = document.getElementById('subcategoryDiv');
      this.accountDiv = document.getElementById('accountDiv');
      this.topLeftDiv = document.getElementById('topLeftDiv');
      this.topLeftTable = document.getElementById('topLeftTable');
      this.topRightDiv = document.getElementById('topRightDiv');
      this.topRightTable = document.getElementById('topRightTable');
      this.bottomLeftDiv = document.getElementById('bottomLeftDiv');
      this.bottomLeftTable = document.getElementById('bottomLeftTable');
      this.bottomRightDiv = document.getElementById('bottomRightDiv');
      this.bottomRightTable = document.getElementById('bottomRightTable');

      const horizontalScrollbarHeight = this.bottomRightDiv.offsetHeight - this.bottomRightDiv.clientHeight;
      const verticalScrollbarWidth = this.bottomRightDiv.offsetWidth - this.bottomRightDiv.clientWidth;

      // adjust column widths of topLeftTable to match those in bottomLeftTable
      this.categoryDiv.style.width = this.bottomLeftTable.rows[0].cells[0].offsetWidth - 2 + 'px';
      this.subcategoryDiv.style.width = this.bottomLeftTable.rows[0].cells[1].offsetWidth - 2 + 'px';
      this.accountDiv.style.width = this.bottomLeftTable.rows[0].cells[2].offsetWidth - 2 + 'px';
      this.topLeftDiv.style.width = this.bottomLeftDiv.getBoundingClientRect().width + 'px';
      this.topRightDiv.style.width = this.bottomRightDiv.getBoundingClientRect().width + 'px';

      // adjust div dimensions to fit height of screen client area
      this.bottomLeftDiv.style.height = document.documentElement.clientHeight - this.bottomLeftDiv.getBoundingClientRect().top + 'px';
      this.bottomRightDiv.style.height = document.documentElement.clientHeight - this.bottomRightDiv.getBoundingClientRect().top + 'px';

      // adjust div dimensions to accomodate presence of scroll bars
      this.topRightDiv.style.width = this.topRightDiv.getBoundingClientRect().width - verticalScrollbarWidth + 'px';
      this.bottomLeftDiv.style.height = this.bottomLeftDiv.getBoundingClientRect().height - horizontalScrollbarHeight + 'px';
      console.log('setScreenLayout -- end'); // %//
    } // if showBudgetData
  } // setScreenLayout

  public async makeExportBudgetData(startMonth, endMonth, selectedMonths) {
    let planInfo;
    const data = {}; // object to hold all data to be exported

    const setPlanDate = plan => {
      let dateCreated, year, month;

      if (plan === 'original') {
        // dateCreated format is YYYY-MM-DDTHH:MM:SS.SSSZ
        dateCreated = this.dataModelService.dataModel.persistent.header.dateCreated;
        year = dateCreated.substr(0, 4);
        month = dateCreated.substr(5, 2);
      } else {
        // plan format is pYYYYMM
        year = plan.substr(1, 4);
        month = plan.substr(5, 2);
      }

      return year + '-' + month;
    }; // setPlanDate

    const setDateName = monthDate => {
      const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

      const year = monthDate.substr(0, 4);
      const month = Number(monthDate.substr(5, 2));
      const monthName = monthNames[month - 1];

      // note: underscore in month name is to thwart attempt of Excel to format the data in an Excel date format
      return monthName + '_' + year;
    }; // setDateName

    const addExportBudgetDataMonth = async funcPlanInfo => {
      let funcGroup, category, subcategory, account, expected, actual, available;

      const setData = (funcData, plan, funcGroup2, funcCategory, funcSubcategory, acntndx, funcYearMonth, source) => {
        account = this.dataModelService.dataModel.persistent.plans[plan][funcCategory][funcSubcategory].accounts[acntndx].accountName.val;
        if (!funcData[funcGroup2][funcCategory][funcSubcategory].hasOwnProperty(account)) {
          funcData[funcGroup2][funcCategory][funcSubcategory][account] = {};
        }
        funcData[funcGroup2][funcCategory][funcSubcategory][account][funcYearMonth] = {};

        // store appropriate amounts
        switch (source) {
          case 'regular':
            expected = this.dataModelService.dataModel.persistent.plans[plan][funcCategory][funcSubcategory].accounts[acntndx].monthlyAmount.val;
            actual = 0;
            if (
              this.dataModelService.dataModel.persistent.plans[plan][funcCategory][funcSubcategory].accounts[acntndx].hasOwnProperty('actualAmount')
            ) {
              actual = this.dataModelService.dataModel.persistent.plans[plan][funcCategory][funcSubcategory].accounts[acntndx].actualAmount.val;
            }
            break;
          case 'shadowIncome':
            expected = this.dataModelService.dataModel.persistent.plans[plan][funcCategory][funcSubcategory].accounts[acntndx].monthlyIncome.val;
            actual = 0;
            if (
              this.dataModelService.dataModel.persistent.plans[plan][funcCategory][funcSubcategory].accounts[acntndx].hasOwnProperty('actualIncome')
            ) {
              actual = this.dataModelService.dataModel.persistent.plans[plan][funcCategory][funcSubcategory].accounts[acntndx].actualIncome.val;
            }
            break;
          case 'shadowBudget':
            let monthlyAmount = 0;
            let haveMonthlyAmount = false;
            if (
              this.dataModelService.dataModel.persistent.plans[plan][funcCategory][funcSubcategory].accounts[acntndx].hasOwnProperty('monthlyAmount')
            ) {
              monthlyAmount = this.dataModelService.dataModel.persistent.plans[plan][funcCategory][funcSubcategory].accounts[acntndx].monthlyAmount
                .val;
              haveMonthlyAmount = true;
            }
            let monthlyMinimum = 0;
            let haveMonthlyMinimum = false;
            if (
              this.dataModelService.dataModel.persistent.plans[plan][funcCategory][funcSubcategory].accounts[acntndx].hasOwnProperty('monthlyMinimum')
            ) {
              monthlyMinimum = this.dataModelService.dataModel.persistent.plans[plan][funcCategory][funcSubcategory].accounts[acntndx].monthlyMinimum
                .val;
              haveMonthlyMinimum = true;
            }
            expected = haveMonthlyAmount ? monthlyAmount - monthlyMinimum : 0;
            actual = 0;
            if (
              this.dataModelService.dataModel.persistent.plans[plan][funcCategory][funcSubcategory].accounts[acntndx].hasOwnProperty('actualAmount')
            ) {
              actual = this.dataModelService.dataModel.persistent.plans[plan][funcCategory][funcSubcategory].accounts[acntndx].actualAmount.val;
            }
            break;
        } // switch
        expected = Math.round(expected);
        actual = Math.round(actual);
        available = expected - actual;

        funcData[funcGroup2][funcCategory][funcSubcategory][account][funcYearMonth].expected = expected;
        funcData[funcGroup2][funcCategory][funcSubcategory][account][funcYearMonth].actual = actual;
        funcData[funcGroup2][funcCategory][funcSubcategory][account][funcYearMonth].available = available;

        // handle subtotal and total amounts
        if (!funcData.hasOwnProperty('INCOME-SUBTOTAL')) {
          funcData['INCOME-SUBTOTAL'] = {};
        }
        if (!funcData.hasOwnProperty('CAFR-SUBTOTAL')) {
          funcData['CAFR-SUBTOTAL'] = {};
        }
        if (!funcData.hasOwnProperty('BUDGET-SUBTOTAL')) {
          funcData['BUDGET-SUBTOTAL'] = {};
        }
        if (!funcData.hasOwnProperty('TOTAL')) {
          funcData.TOTAL = {};
        }

        if (!funcData['INCOME-SUBTOTAL'].hasOwnProperty(funcYearMonth)) {
          funcData['INCOME-SUBTOTAL'][funcYearMonth] = {};
          funcData['INCOME-SUBTOTAL'][funcYearMonth].expectedSum = 0;
          funcData['INCOME-SUBTOTAL'][funcYearMonth].actualSum = 0;
        }
        if (!funcData['CAFR-SUBTOTAL'].hasOwnProperty(funcYearMonth)) {
          funcData['CAFR-SUBTOTAL'][funcYearMonth] = {};
          funcData['CAFR-SUBTOTAL'][funcYearMonth].expectedSum = 0;
          funcData['CAFR-SUBTOTAL'][funcYearMonth].actualSum = 0;
        }
        if (!funcData['BUDGET-SUBTOTAL'].hasOwnProperty(funcYearMonth)) {
          funcData['BUDGET-SUBTOTAL'][funcYearMonth] = {};
          funcData['BUDGET-SUBTOTAL'][funcYearMonth].expectedSum = 0;
          funcData['BUDGET-SUBTOTAL'][funcYearMonth].actualSum = 0;
        }
        if (!funcData.TOTAL.hasOwnProperty(funcYearMonth)) {
          funcData.TOTAL[funcYearMonth] = {};
          funcData.TOTAL[funcYearMonth].expectedSum = 0;
          funcData.TOTAL[funcYearMonth].actualSum = 0;
        }

        switch (funcGroup2) {
          case 'INCOME':
            funcData['INCOME-SUBTOTAL'][funcYearMonth].expectedSum += expected;
            funcData.TOTAL[funcYearMonth].expectedSum += expected;
            funcData['INCOME-SUBTOTAL'][funcYearMonth].actualSum += actual;
            funcData.TOTAL[funcYearMonth].actualSum += actual;
            break;
          case 'CAFR-EmergencyFund':
          case 'CAFR-Debts':
          case 'CAFR-CashReserves':
          case 'CAFR-Investments':
            funcData['CAFR-SUBTOTAL'][funcYearMonth].expectedSum += expected;
            funcData.TOTAL[funcYearMonth].expectedSum -= expected;
            funcData['CAFR-SUBTOTAL'][funcYearMonth].actualSum += actual;
            funcData.TOTAL[funcYearMonth].actualSum -= actual;
            break;
          case 'BUDGET':
            funcData['BUDGET-SUBTOTAL'][funcYearMonth].expectedSum += expected;
            funcData.TOTAL[funcYearMonth].expectedSum -= expected;
            funcData['BUDGET-SUBTOTAL'][funcYearMonth].actualSum += actual;
            funcData.TOTAL[funcYearMonth].actualSum -= actual;
            break;
        } // switch
      }; // setData

      // change to proper plan
      // console.log('addExportBudgetDataMonth for ' + planInfo.yearMonth);  //%//
      this.plan = funcPlanInfo.planName;
      const saveCurplan = this.dataModelService.dataModel.persistent.header.curplan;
      await this.dataModelService.dataManagement.changeCurrentPlan(this.plan);
      this.dataModelService.setCurPlan(saveCurplan);

      const yearMonth = funcPlanInfo.yearMonth;

      // income
      funcGroup = 'INCOME';
      category = 'income';
      // let e = new Error('trace in makeExportBudgetData:');  console.log(e.stack);  //%//
      if (!data.hasOwnProperty(funcGroup)) {
        data[funcGroup] = {};
      }
      if (!data[funcGroup].hasOwnProperty(category)) {
        data[funcGroup][category] = {};
      }

      for (const funcSubcategory of Object.keys(this.dataModelService.dataModel.persistent.plans[this.plan][category])) {
        if (subcategoryExcludeList.indexOf(funcSubcategory) === -1) {
          if (!data[funcGroup][category].hasOwnProperty(funcSubcategory)) {
            data[funcGroup][category][funcSubcategory] = {};
          }
          for (
            let acntndx = 0;
            acntndx < this.dataModelService.dataModel.persistent.plans[this.plan][category][funcSubcategory].accounts.length;
            acntndx++
          ) {
            setData(data, this.plan, funcGroup, category, funcSubcategory, acntndx, yearMonth, 'regular');
          } // for acntndx
        } // if include subcategory
      } // for subcategory

      // income from shadow accounts
      for (const funcCategory of Object.keys(this.dataModelService.dataModel.persistent.plans[this.plan])) {
        if (categoryExcludeList.indexOf(funcCategory) === -1) {
          for (const funcSubcategory of Object.keys(this.dataModelService.dataModel.persistent.plans[this.plan][funcCategory])) {
            if (subcategoryExcludeList.indexOf(funcSubcategory) === -1) {
              for (
                let acntndx = 0;
                acntndx < this.dataModelService.dataModel.persistent.plans[this.plan][funcCategory][funcSubcategory].accounts.length;
                acntndx++
              ) {
                if (
                  this.dataModelService.dataModel.persistent.plans[this.plan][funcCategory][funcSubcategory].accounts[acntndx].hasOwnProperty(
                    'incomeSubcategory'
                  ) &&
                  this.dataModelService.dataModel.persistent.plans[this.plan][funcCategory][funcSubcategory].accounts[acntndx].incomeSubcategory !==
                    ''
                ) {
                  if (!data[funcGroup].hasOwnProperty(funcCategory)) {
                    data[funcGroup][funcCategory] = {};
                  }
                  if (!data[funcGroup][funcCategory].hasOwnProperty(funcSubcategory)) {
                    data[funcGroup][funcCategory][funcSubcategory] = {};
                  }

                  setData(data, this.plan, funcGroup, funcCategory, funcSubcategory, acntndx, yearMonth, 'shadowIncome');
                }
              } // for acntndx
            } // if include subcategory
          } // for subcategory
        } // if incoude category
      } // for category
      // console.log('data after INCOME: ', JSON.parse(JSON.stringify(data)));  //%//

      // emergency fund
      funcGroup = 'CAFR-EmergencyFund';
      category = 'assets';
      subcategory = 'emergencySavings';
      if (this.dataModelService.dataModel.persistent.plans[this.plan][category].hasOwnProperty(subcategory)) {
        if (!data.hasOwnProperty(funcGroup)) {
          data[funcGroup] = {};
        }
        if (!data[funcGroup].hasOwnProperty(category)) {
          data[funcGroup][category] = {};
        }
        if (!data[funcGroup][category].hasOwnProperty(subcategory)) {
          data[funcGroup][category][subcategory] = {};
        }

        for (
          let acntndx = 0;
          acntndx < this.dataModelService.dataModel.persistent.plans[this.plan][category][subcategory].accounts.length;
          acntndx++
        ) {
          setData(data, this.plan, funcGroup, category, subcategory, acntndx, yearMonth, 'regular');
        } // for acntndx
      } // if have subcategory

      // liabilities
      funcGroup = 'CAFR-Debts';
      category = 'liabilities';
      if (!data.hasOwnProperty(funcGroup)) {
        data[funcGroup] = {};
      }
      if (!data[funcGroup].hasOwnProperty(category)) {
        data[funcGroup][category] = {};
      }

      for (const funcSubcategory of Object.keys(this.dataModelService.dataModel.persistent.plans[this.plan][category])) {
        if (subcategoryExcludeList.indexOf(funcSubcategory) === -1) {
          if (!data[funcGroup][category].hasOwnProperty(funcSubcategory)) {
            data[funcGroup][category][funcSubcategory] = {};
          }
          for (
            let acntndx = 0;
            acntndx < this.dataModelService.dataModel.persistent.plans[this.plan][category][funcSubcategory].accounts.length;
            acntndx++
          ) {
            setData(data, this.plan, funcGroup, category, funcSubcategory, acntndx, yearMonth, 'regular');
          } // for acntndx
        } // if include subcategory
      } // for subcategory

      // cash reserves
      funcGroup = 'CAFR-CashReserves';
      category = 'assets';
      subcategory = 'cashReserves';
      if (this.dataModelService.dataModel.persistent.plans[this.plan][category].hasOwnProperty(subcategory)) {
        if (!data.hasOwnProperty(funcGroup)) {
          data[funcGroup] = {};
        }
        if (!data[funcGroup].hasOwnProperty(category)) {
          data[funcGroup][category] = {};
        }
        if (!data[funcGroup][category].hasOwnProperty(subcategory)) {
          data[funcGroup][category][subcategory] = {};
        }

        for (
          let acntndx = 0;
          acntndx < this.dataModelService.dataModel.persistent.plans[this.plan][category][subcategory].accounts.length;
          acntndx++
        ) {
          setData(data, this.plan, funcGroup, category, subcategory, acntndx, yearMonth, 'regular');
        } // for acntndx
      } // if have subcategory

      // investments
      funcGroup = 'CAFR-Investments';
      category = 'assets';
      subcategory = 'investments';
      if (this.dataModelService.dataModel.persistent.plans[this.plan][category].hasOwnProperty(subcategory)) {
        if (!data.hasOwnProperty(funcGroup)) {
          data[funcGroup] = {};
        }
        if (!data[funcGroup].hasOwnProperty(category)) {
          data[funcGroup][category] = {};
        }
        if (!data[funcGroup][category].hasOwnProperty(subcategory)) {
          data[funcGroup][category][subcategory] = {};
        }

        for (
          let acntndx = 0;
          acntndx < this.dataModelService.dataModel.persistent.plans[this.plan][category][subcategory].accounts.length;
          acntndx++
        ) {
          setData(data, this.plan, funcGroup, category, subcategory, acntndx, yearMonth, 'regular');
        } // for acntndx
      } // if have subcategory
      // console.log('data after CAFR: ', JSON.parse(JSON.stringify(data)));  //%//

      // budget
      funcGroup = 'BUDGET';
      category = 'budget';
      if (!data.hasOwnProperty(funcGroup)) {
        data[funcGroup] = {};
      }
      if (!data[funcGroup].hasOwnProperty(category)) {
        data[funcGroup][category] = {};
      }

      for (const funcSubcategory of Object.keys(this.dataModelService.dataModel.persistent.plans[this.plan][category])) {
        if (subcategoryExcludeList.indexOf(funcSubcategory) === -1) {
          if (!data[funcGroup][category].hasOwnProperty(funcSubcategory)) {
            data[funcGroup][category][funcSubcategory] = {};
          }
          for (
            let acntndx = 0;
            acntndx < this.dataModelService.dataModel.persistent.plans[this.plan][category][funcSubcategory].accounts.length;
            acntndx++
          ) {
            setData(data, this.plan, funcGroup, category, funcSubcategory, acntndx, yearMonth, 'regular');
          } // for acntndx
        } // if include subcategory
      } // for subcategory

      // budget from shadow accounts
      for (const funcCategory of Object.keys(this.dataModelService.dataModel.persistent.plans[this.plan])) {
        if (categoryExcludeList.indexOf(funcCategory) === -1) {
          for (const funcSubcategory of Object.keys(this.dataModelService.dataModel.persistent.plans[this.plan][funcCategory])) {
            if (subcategoryExcludeList.indexOf(funcSubcategory) === -1) {
              for (
                let acntndx = 0;
                acntndx < this.dataModelService.dataModel.persistent.plans[this.plan][funcCategory][funcSubcategory].accounts.length;
                acntndx++
              ) {
                if (
                  this.dataModelService.dataModel.persistent.plans[this.plan][funcCategory][funcSubcategory].accounts[acntndx].hasOwnProperty(
                    'budgetSubcategory'
                  ) &&
                  this.dataModelService.dataModel.persistent.plans[this.plan][funcCategory][funcSubcategory].accounts[acntndx].budgetSubcategory !==
                    ''
                ) {
                  if (!data[funcGroup].hasOwnProperty(funcCategory)) {
                    data[funcGroup][funcCategory] = {};
                  }
                  if (!data[funcGroup][funcCategory].hasOwnProperty(funcSubcategory)) {
                    data[funcGroup][funcCategory][funcSubcategory] = {};
                  }

                  setData(data, this.plan, funcGroup, funcCategory, funcSubcategory, acntndx, yearMonth, 'shadowBudget');
                }
              } // for acntndx
            } // if include subcategory
          } // for subcategory
        } // if incoude category
      } // for category
      // console.log('data after BUDGET: ', JSON.parse(JSON.stringify(data)));  //%//
    }; // addExportBudgetDataMonth

    // initialize
    // console.log('makeExportBudgetData -- start');  //%//
    let line;

    // actualPlanList -- all plans that are actually present in the data for this user within the range of dates requested by the user
    this.actualPlanList = [];
    for (const plan of this.planList) {
      const yearMonth = setPlanDate(plan);
      if (yearMonth >= startMonth && yearMonth <= endMonth) {
        this.actualPlanList.push({ planName: plan, yearMonth });
      }
    }
    // console.log('actualPlanList: ', this.actualPlanList);  //%//

    // reportMonthList -- list of months to be included in exported data
    this.reportMonthList = [];
    if (selectedMonths === 'all') {
      let curYearMonth = startMonth;
      while (curYearMonth <= endMonth) {
        this.reportMonthList.push({ yearMonth: curYearMonth, monthName: setDateName(curYearMonth) });
        curYearMonth = this.dataModelService.changeDate(curYearMonth, 0, 1, 0).substr(0, 7);
      }
    } else {
      for (const funcPlanInfo of this.actualPlanList) {
        const yearMonth = funcPlanInfo.yearMonth;
        this.reportMonthList.push({ yearMonth, monthName: setDateName(yearMonth) });
      }
    }

    /*  sample data
        this.actualPlanList =
        [
            {planName:'original', yearMonth:'2017-10'},
            {planName:'p201803',  yearMonth:'2018-03'},
            {planName:'p201805',  yearMonth:'2018-05'}
        ];
        this.reportMonthList =
        [
            {yearMonth:'2018-02', monthName:'February_2018'},
            {yearMonth:'2018-03', monthName:'March_2018'},
            {yearMonth:'2018-04', monthName:'April_2018'},
            {yearMonth:'2018-05', monthName:'May_2018'},
            {yearMonth:'2018-06', monthName:'June_2018'}
        ];
        */

    // generate data for heading information
    const group = 'HEADING';
    data[group] = [];

    // line 1
    data[group].push('WizeFi Exported Budget\n');

    // line 2
    line = '';
    line += ',,,';
    for (const month of this.reportMonthList) {
      line += month.monthName + ',,,';
    }
    line += '\n';
    data[group].push(line);

    // line 3
    line = '';
    line += 'Category,Subcategory,Account';
    for (const month of this.reportMonthList) {
      line += ',Expected,Actual,Available';
    }
    line += '\n';
    data[group].push(line);

    // console.log('add data for each month');  //%//
    // generate data to be included for each month in the report
    for (const plan of this.actualPlanList) {
      planInfo = plan;
      await addExportBudgetDataMonth(planInfo);
    }

    this.data = data;
  } // makeExportBudgetData

  public makeExportBudgetCSVData(startMonth, endMonth, selectedMonths) {
    let group, csvData, expected, actual, available, expectedSum, actualSum;

    const handleCommaFormat = item => {
      if (item.indexOf(',') !== -1) {
        item = '"' + item + '"';
      }
      return item;
    }; // handleCommaFormat

    // initialize
    csvData = '';
    const data = this.data; // data previously computed using makeExportBudgetData function

    console.log('exportedBudgetData:'); // %//
    console.log(data); // %//

    // headings
    group = 'HEADING';
    for (const line of data[group]) {
      csvData += line;
    }

    for (const funcGroup of this.groupReportList) {
      if (funcGroup.indexOf('TOTAL') !== -1) {
        // handle subtotal and total groups
        const label = funcGroup.indexOf('SUBTOTAL') !== -1 ? 'SUBTOTAL' : 'TOTAL';
        if (label === 'TOTAL') {
          csvData += '\n';
        }
        csvData += ',,' + label + ',';
        for (const month of this.reportMonthList) {
          const yearMonth = month.yearMonth;

          expectedSum = '';
          actualSum = '';
          switch (funcGroup) {
            case 'INCOME-SUBTOTAL':
              if (data['INCOME-SUBTOTAL'].hasOwnProperty(yearMonth)) {
                expectedSum = data['INCOME-SUBTOTAL'][yearMonth].expectedSum;
                actualSum = data['INCOME-SUBTOTAL'][yearMonth].actualSum;
              }
              break;
            case 'CAFR-SUBTOTAL':
              if (data['CAFR-SUBTOTAL'].hasOwnProperty(yearMonth)) {
                expectedSum = data['CAFR-SUBTOTAL'][yearMonth].expectedSum;
                actualSum = data['CAFR-SUBTOTAL'][yearMonth].actualSum;
              }
              break;
            case 'BUDGET-SUBTOTAL':
              if (data['BUDGET-SUBTOTAL'].hasOwnProperty(yearMonth)) {
                expectedSum = data['BUDGET-SUBTOTAL'][yearMonth].expectedSum;
                actualSum = data['BUDGET-SUBTOTAL'][yearMonth].actualSum;
              }
              break;
            case 'TOTAL':
              if (data.TOTAL.hasOwnProperty(yearMonth)) {
                expectedSum = data.TOTAL[yearMonth].expectedSum;
                actualSum = data.TOTAL[yearMonth].actualSum;
              }
              break;
          } // switch

          csvData += expectedSum + ',' + actualSum + ',,';
        } // for
        csvData += '\n';
      } else {
        // handle other groups
        if (funcGroup.indexOf('CAFR') === -1) {
          csvData += '\n' + funcGroup + '\n';
        } else if (funcGroup === 'CAFR-EmergencyFund') {
          csvData += '\nCAFR\n';
        }

        // eslint-disable-next-line guard-for-in
        for (const category in data[funcGroup]) {
          // eslint-disable-next-line guard-for-in
          for (const subcategory in data[funcGroup][category]) {
            // eslint-disable-next-line guard-for-in
            for (const account in data[funcGroup][category][subcategory]) {
              for (let i = 0; i < this.reportMonthList.length; i++) {
                const yearMonth = this.reportMonthList[i].yearMonth;
                if (i === 0) {
                  csvData += handleCommaFormat(category) + ',';
                  csvData += handleCommaFormat(subcategory) + ',';
                  csvData += handleCommaFormat(account);
                }
                expected = '';
                actual = '';
                available = '';
                if (data[funcGroup][category][subcategory][account].hasOwnProperty(yearMonth)) {
                  expected = data[funcGroup][category][subcategory][account][yearMonth].expected;
                  actual = data[funcGroup][category][subcategory][account][yearMonth].actual;
                  available = data[funcGroup][category][subcategory][account][yearMonth].available;
                }
                csvData += ',' + expected;
                csvData += ',' + actual;
                csvData += ',' + available;
              } // for
              csvData += '\n';
            } // for account
          } // for subcategory
        } // for category
      } // if total info
    } // for group

    return csvData;
  } // makeExportBudgetCSVData

  public downloadExportBudgetCSVData(startMonth, endMonth, selectedMonths) {
    let matches, year, month;
    let hadError = false;
    const messages = [];

    // verify data
    if (startMonth === null) {
      hadError = true;
      messages.push('Must enter value for StartMonth');
    } else {
      matches = startMonth.match(/^(\d{4})\-(\d{2})$/);
      if (matches === null) {
        hadError = true;
        messages.push('Must enter value for StartMonth in format YYYY-MM');
      } else {
        year = Number(matches[1]);
        month = Number(matches[2]);
        if (year < 2015 || year > 2050) {
          hadError = true;
          messages.push('For StartMonth must have  2015 <= year <= 2050');
        }
        if (month < 1 || month > 12) {
          hadError = true;
          messages.push('For StartMonth must have  01 <= month <= 12');
        }
      }
    }
    if (endMonth === null) {
      hadError = true;
      messages.push('Must enter value for EndMonth');
    } else {
      matches = endMonth.match(/^(\d{4})\-(\d{2})$/);
      if (matches === null) {
        hadError = true;
        messages.push('Must enter value for EndMonth in format YYYY-MM');
      } else {
        year = Number(matches[1]);
        month = Number(matches[2]);
        if (year < 2015 || year > 2050) {
          hadError = true;
          messages.push('For EndMonth must have  2015 <= year <= 2050');
        }
        if (month < 1 || month > 12) {
          hadError = true;
          messages.push('For EndMonth must have  01 <= month <= 12');
        }
      }
    }
    if (startMonth > endMonth) {
      hadError = true;
      messages.push('Must have startMonth <= endMonth');
    }
    if (startMonth > this.lastMonth) {
      hadError = true;
      messages.push('Must have StartMonth <= LastMonth');
    }
    if (endMonth < this.firstMonth) {
      hadError = true;
      messages.push('Must have EndMonth >= FirstMonth');
    }

    if (hadError) {
      this.dataModelService.showMessages('error', messages, 7000);
    } else {
      try {
        let csvData = this.makeExportBudgetCSVData(startMonth, endMonth, selectedMonths);
        csvData = 'data:text/csv;charset=utf-8,' + csvData;
        //
        const data = encodeURI(csvData);
        const filename = 'WizeFiBudgetData.csv';

        const link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('download', filename);
        link.click();
        //
      } catch (ex) {
        console.log('unable to create export CSV data:'); // %//
        console.log(ex); // %//
        this.dataModelService.showMessage('error', 'unable to create export CSV data', 7000);
      }
    } // if hadError
  } // downloadExportBudgetCSVData
} // class AdminViewBudgetDataComponent
