import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DataModelService } from '../../services/data-model/data-model.service';

@Injectable({
  providedIn: 'root'
})
export class BetaGuard implements CanActivate, CanLoad {
  constructor(private router: Router, private dataModelService: DataModelService) {}
  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url: URL = new URL(window.location.href);
    console.log(url.origin);
    const params: URLSearchParams = url.searchParams;
    // get target key/value from URLSearchParams object
    const alias: string = params.get('a');
    console.log(alias);
    if (alias === null) {
      this.dataModelService.loginManagement.handleError('No Affiliate ID found. please contact support.');
      if (window.location.href.indexOf('/login') > -1) {
        console.log(window.location.href.indexOf('/login'));
      } else {
        window.location.href = '/login';
      }
      return false;
    } else {
      if (!alias.match(/^[a-zA-Z0-9]+$/)) {
        this.dataModelService.loginManagement.handleError('Invalid affiliate id');
        if (window.location.href.indexOf('/login') > -1) {
          console.log(window.location.href.indexOf('/login'));
        } else {
          window.location.href = '/login';
        }
        return false;
      }
      // todo traverse up the affiliate tree to find valid affiliate ids
      return true;
    }
  }
  public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
