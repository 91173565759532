import { Component, ElementRef, EventEmitter, Input, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { WizefiTransaction } from '../../../interfaces/wizefi-transaction';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { categoryInfo } from '../../../services/data-model/data-model_0001.data';
import { CAssetProtection } from '../../../utilities/assets/asset-protection.class';
import { CAssets } from '../../../utilities/assets/assets.class';
import { CBudget } from '../../../utilities/budget/budget.class';
import { GenericDataManagement } from '../../../utilities/generic-data-management.class';
import { CIncome } from '../../../utilities/income/income.class';
import { CLiabilities } from '../../../utilities/liabilities/liabilities.class';
import { CProfile } from '../../../utilities/profile.class';
import { TextInputComponent } from '../../text-input/text-input.component';
import { CategoryListItemComponent } from '../category-list-item/category-list-item.component';
@Component({
  selector: 'app-budget-category',
  templateUrl: './budget-category.component.html',
  styleUrls: ['./budget-category.component.scss']
})
export class BudgetCategoryComponent extends CategoryListItemComponent {
  @Input() public transactionsYearMonth;
  @Input() public review;
  @ViewChild(TextInputComponent, { static: false })
  public textInputRef: ElementRef;
  @ViewChild(TextInputComponent, { static: false })
  public textInputComponent: TextInputComponent;
  @ViewChildren(TextInputComponent) public textInputComponents: QueryList<TextInputComponent>;
  @Output() public didToggleDropdown: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onSubcategoryClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onSubcategoryDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() public getTotalActualSpending: EventEmitter<any> = new EventEmitter<any>();
  @Output() public getTotalPlannedSpending: EventEmitter<any> = new EventEmitter<any>();
  @Output() public updateBudgetCategorySum: EventEmitter<any> = new EventEmitter<any>();
  public isLoading = true;
  public transactions: { [accountName: string]: WizefiTransaction[] } = {};
  public isShowingAddSubcategoryDropdown = false;
  protected profileService: ProfileService;
  protected additionalSubcategories: string[];
  protected budgetShadowAccounts: any;
  protected gdBudget: GenericDataManagement;
  protected gdIncome: GenericDataManagement;
  protected gdAssets: GenericDataManagement;
  protected gdAssetProtection: GenericDataManagement;
  protected gdLiabilities: GenericDataManagement;
  protected cProfile: CProfile;
  protected cIncome: CIncome;
  protected cBudget: CBudget;
  protected cAssets: CAssets;
  protected cAssetProtection: CAssetProtection;
  protected cLiabilities: CLiabilities;
  protected messages: string[] = [];
  protected origAccountNames = {};
  protected accountBeingEdited = null;
  protected accountBeingHovered = null;
  protected verifyModal = false;
  protected verifiedAccount: any;
  public spentLastMonthSum: number;
  protected infoModal = false;
  public showAddNewSubCategory = false;
  public isInSetup: boolean;
  public isDeletingAccount = false;

  constructor(protected dataModelService: DataModelService, protected router: Router, protected renderer: Renderer2) {
    super(dataModelService, router, renderer);
    this.setupClickOutsideTextInputHandler();
    this.profileService = new ProfileService(dataModelService);
    this.budgetShadowAccounts = this.profileService.getBudgetShadowAccounts();
  }

  public ngOnInit() {
    this.isInSetup = this.router.url.includes('setup/spending');
    this.isLoading = true;
    super.ngOnInit();
    this.setActualSpendingSums();
    this.mapTransactionsToAccounts();
    this.spentLastMonthSum = this.getLastMonthCategorySum();
    this.categorySum = this.getCategorySum();
    this.remainingSum = this.getRemainingSum();
    this.setAdditionalSubcategoriesData();
    // console.log("review?:" + this.review);
    this.getTotalActualSpending.emit();

    if (this.review) {
      this.isShowingDropdown = true;
    }
    this.isLoading = false;
  }

  public toggleDropdown(review = false, state = null) {
    super.toggleDropdown(review, state);
    if (state == null && this.isShowingDropdown) {
      this.didToggleDropdown.emit({ component: this });
    }
  }

  public ngAfterViewInit() {
    this.updateBudgetCategorySum.emit();
  }

  public toggleAddSubcategoryDropdown() {
    this.isShowingAddSubcategoryDropdown = !this.isShowingAddSubcategoryDropdown;
    if (this.isShowingAddSubcategoryDropdown) {
      this.setAdditionalSubcategoriesData();
    }
  }

  public _onClick(wizeFiCategory) {
    this.updateBudgetCategorySum.emit();
    this.onSubcategoryClick.emit(wizeFiCategory);
  }

  private setAdditionalSubcategoriesData() {
    if (this.categoryGroup === 'budget') {
      this.additionalSubcategories = categoryInfo.budget[this.categoryKey].accountNames.filter(
        name => !this.category.accounts.some(account => account.accountName.val === name)
      );
    }
  }

  public addNewSubcategory(accountName) {
    if (!accountName) {
      this.showAddNewSubCategory = true;
    } else {
      this.addCustomLabel(accountName);
    }
  }

  public addCustomLabel(accountName) {
    this.dataModelService.categoryManagement.createWizeFiAccount(this.categoryGroup, this.categoryKey, accountName);
    this.isShowingAddSubcategoryDropdown = false;
    this.dataModelService.dataManagement.storeinfo();
  }

  // Protected

  protected setActualSpendingSums() {
    if (this.router.url.includes('setup/create-cafr') || this.router.url.includes('setup/apply-cafr')) {
      return;
    } // don't want to overwrite last-month's actualMonthlyAmounts
    this.dataModelService.categoryManagement.assignActualMonthlyAmount(this.transactionsYearMonth);
  }

  private resetActualMonthlyAmounts() {
    this.dataModelService.visitAllWizeFiAccounts(this.dataModelService.dataModel.persistent.header.curplan, this._resetActualMonthlyAmount, {});
  }

  private _resetActualMonthlyAmount(plan, category, subcategory, acntndx, account, result) {
    if (category === 'budget' && account && account.hasOwnProperty('actualMonthlyAmount') && account.hasOwnProperty('monthlyAmount')) {
      account.actualMonthlyAmount.val = 0;
    }
  }

  private mapTransactionsToAccounts() {
    if (this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection) {
      const transactions = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection[this.transactionsYearMonth];
      if (transactions) {
        this.mapToRegularAccounts(transactions);
        this.mapToShadowAccounts(transactions);
      }
    }
  }

  private mapToRegularAccounts(transactions) {
    this.category.accounts.forEach(account => {
      if (account.wizeFiCategory && account.wizeFiCategory.val) {
        this.transactions[account.wizeFiCategory.val] = transactions.filter(transaction => transaction.wizeFiCategory === account.wizeFiCategory.val);
      }
    });
  }

  private mapToShadowAccounts(transactions) {
    this.budgetShadowAccounts.forEach(account => {
      let accountID;
      if (account.accountID) {
        accountID = account.accountID.val ? account.accountID.val : account.accountID;

        if (account.accountType && account.accountType.val) {
          const wizeFiCategory = `${account.category}_${account.accountType.val}_${accountID}`;
          this.transactions[wizeFiCategory] = transactions.filter(transaction => transaction.wizeFiCategory === wizeFiCategory);
          if (account.actualMonthlyAmount && account.actualMonthlyAmount.val) {
            account.actualMonthlyAmount.val = this.transactions[wizeFiCategory].reduce((total, transaction) => total + transaction.amount, 0);
          }
        }
      }
    });
  }

  public getLastMonthCategorySum(): number {
    let actualMonthlyAmountTotal = 0;
    let shadowAccountsTotal = 0;

    if (this.category && this.category.accounts) {
      actualMonthlyAmountTotal = this.category.accounts.reduce(
        (total, account) => total + (account && account.actualMonthlyAmount ? account.actualMonthlyAmount.val : 0),
        0
      );
    }
    if (this.budgetShadowAccounts) {
      shadowAccountsTotal = this.budgetShadowAccounts.reduce(
        (total, account) =>
          this.belongsToCurrentCategory(account)
            ? account && account.monthlyMinimum && account.monthlyMinimum.val
              ? total + account.monthlyMinimum.val
              : total + (account && account.monthlyAmount ? account.monthlyAmount.val : 0)
            : total,
        0
      );
    }

    return actualMonthlyAmountTotal + shadowAccountsTotal;
  }

  public getCategorySum(): number {
    let MonthlyAmountTotal = 0;
    let actualShadowAccountsTotal = 0;

    if (this.category && this.category.accounts) {
      MonthlyAmountTotal = this.category.accounts.reduce((total, account) => total + parseFloat(account.monthlyAmount.val), 0);
    }

    if (this.budgetShadowAccounts) {
      actualShadowAccountsTotal = this.budgetShadowAccounts.reduce(
        (total, account) =>
          this.belongsToCurrentCategory(account)
            ? account && account.monthlyMinimum.val
              ? total + account.monthlyMinimum.val
              : account.monthlyAmount
              ? total + account.monthlyAmount.val
              : total + 0
            : total,
        0
      );
    }
    return MonthlyAmountTotal + actualShadowAccountsTotal;
  }

  public getRemainingSum(): number {
    const MonthlyAmountTotal = this.category.accounts.reduce(
      (total, account) => total + parseFloat(account.monthlyAmount.val) - parseFloat(account.actualMonthlyAmount.val),
      0
    );
    const shadowAccountsTotal = this.budgetShadowAccounts.reduce(
      (total, account) =>
        this.belongsToCurrentCategory(account) ? total + parseFloat(account.monthlyMinimum.val) - parseFloat(account.actualMonthlyAmount.val) : total,
      0
    );
    return MonthlyAmountTotal + shadowAccountsTotal;
  }

  protected belongsToCurrentCategory(shadowAccount): boolean {
    let isAShadowAccount = false;
    const containsCategory =
      shadowAccount.budgetSubcategory &&
      shadowAccount.budgetSubcategory.val !== '' &&
      this.category.label.toLowerCase().includes(shadowAccount.budgetSubcategory.val.toLowerCase());
    const belongsToCategory =
      shadowAccount.budgetSubcategory && shadowAccount.budgetSubcategory.val.toLowerCase() === this.category.label.toLowerCase();

    if (containsCategory === true || belongsToCategory === true) {
      if (shadowAccount.shadowType !== 'liability' && shadowAccount.shadowType !== 'asset') {
        isAShadowAccount = true;
      }
    }

    return isAShadowAccount;
  }

  protected getProperVerifiedAmount(shadowAccount): number {
    const verifiedTotal =
      shadowAccount && shadowAccount.monthlyAmount && shadowAccount.monthlyAmount.val
        ? shadowAccount.monthlyAmount.val
        : shadowAccount.monthlyMinimum.val;
    return verifiedTotal;
  }

  protected deleteAccount(event, account) {
    event.stopPropagation();
    if (this.isDeletingAccount) {
      return;
    }

    setTimeout(() => {
      if (window.confirm('Are you sure you want to delete this subcategory?')) {
        const categoryManagement = this.dataModelService.categoryManagement;
        const wizeFiCategory = categoryManagement.makeWizeFiCategory(this.categoryGroup, this.categoryKey, account.accountName.val);
        categoryManagement.deleteWizeFiAccount(wizeFiCategory);
        this.categorySum = this.getCategorySum();
        this.remainingSum = this.getRemainingSum();
        this.onSubcategoryDelete.emit();
        this.isDeletingAccount = false;
      }
    }, 1000);

    this.isDeletingAccount = true;
  }
  /**
   *
   */
  public mapShadowAccountToDataModel(account) {
    return account.accountName.val;
  }

  protected setAccountBeingHovered(account) {
    this.accountBeingHovered = account;
  }

  protected unsetAccountBeingHovered() {
    this.accountBeingHovered = null;
  }

  protected setAccountBeingEdited(account) {
    this.accountBeingEdited = account;
    this.setOrigName(account); // save copy to revert back to in case of invalid name saved
  }

  protected unsetAccountBeingEdited() {
    if (this.isInvalidName()) {
      this.resetAccountName();
      this.accountBeingEdited = null;
    }
  }

  private isInvalidName() {
    let isValid = true;

    if (this.accountBeingEdited && this.accountBeingEdited.accountName && this.accountBeingEdited.accountName.val.trim() === '') {
      this.dataModelService.showMessage('error', 'Empty names are not allowed', 4000);
      isValid = false;
    }
    if (this.isDuplicateAccountName()) {
      this.dataModelService.showMessage('error', 'Duplicate names are not allowed', 4000);
      isValid = false;
    }

    return !isValid;
  }

  private resetAccountName() {
    const origName = this.getOrigName(this.accountBeingEdited);
    if (this.accountBeingEdited.hasOwnProperty('shadowType')) {
      this.onShadowAccountNameChange(origName);
    } else {
      this.onBudgetAccountNameChange(origName);
    }
  }

  protected isDuplicateAccountName() {
    if (this.category && this.category.accounts && this.accountBeingEdited && this.accountBeingEdited.accountName) {
      return this.category.accounts.filter(account => account.accountName.val === this.accountBeingEdited.accountName.val).length > 1;
    }
  }

  private setOrigName(account) {
    const key = (this.accountBeingEdited.wizeFiCategory && this.accountBeingEdited.wizeFiCategory.val) || this.accountBeingEdited.accountID.val;
    this.origAccountNames[key] = account.accountName.val;
  }

  private getOrigName(account) {
    const key = (account.wizeFiCategory && account.wizeFiCategory.val) || account.accountID.val;
    return this.origAccountNames[key];
  }

  protected onBudgetAccountNameChange = value => {
    if (this.accountBeingEdited && this.accountBeingEdited.accountName) {
      this.accountBeingEdited.accountName.val = value;
    }
  };

  protected onShadowAccountNameChange = value => {
    // need to update the shadow account that is in the persistent data-model,
    // not just the component's local shadow account
    const correspondingAccount = this.dataModelService.categoryManagement.getWizeFiAccountFromWizeFiCategory(
      this.accountBeingEdited.wizeFiCategory.val
    );
    correspondingAccount.accountName.val = value;
    if (this.accountBeingEdited && this.accountBeingEdited.accountName) {
      this.accountBeingEdited.accountName.val = value;
    }
  };

  // causes accountName input to be hidden when user clicks outside input box
  // TODO: toggle current editing textbox if user clicks to edit account from other subcategory
  private setupClickOutsideTextInputHandler() {
    this.renderer.listen('window', 'click', (event: Event) => {
      if (event.target && this.textInputRef && event.target !== this.textInputRef.nativeElement) {
        this.accountBeingEdited = null;
      }
    });
  }

  public toggleTypeShowing() {
    // overridden in extended class
  }

  public toggleVerifyModal(account) {
    if (!this.review) {
      this.verifyModal = !this.verifyModal;
      this.verifiedAccount = account;
      this.accountBeingEdited = account;
    }
  }

  public closeVerifyModal() {
    this.verifyModal = false;
  }

  public cancelVerifyModal() {
    this.closeVerifyModal();
  }

  public verfiyAccount(amount = 0) {
    console.log(amount);

    this.category.accounts.forEach(privateAccount => {
      if (privateAccount.accountID.val === this.verifiedAccount.accountID.val) {
        this.verifiedAccount.verified = true;
      }
    });

    this.category.accounts.forEach(account => {
      if (account.accountID.val === this.verifiedAccount.accountID.val) {
        if (isNaN(amount)) {
          account.monthlyAmount.val = 0;
        } else {
          account.monthlyAmount.val = amount;
        }
        this.categorySum = this.getCategorySum();
        this.remainingSum = this.getRemainingSum();
      }
    });
    this.dataModelService.dataManagement.storeinfo();
    this.setActualSpendingSums();
    this.updateBudgetCategorySum.emit();
    this.closeVerifyModal();
  }

  public toggleInfoModal() {
    this.infoModal = !this.infoModal;
  }

  public saveNewCustomLabel(customLabel) {
    this.addCustomLabel(customLabel);
    this.showAddNewSubCategory = false;
  }
}
