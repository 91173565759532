import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'app-report-bug',
  templateUrl: './report-bug.component.html',
  styleUrls: ['./report-bug.component.scss']
})
export class ReportBugComponent {
  public message: string;

  public constructor(public matDialogRef: MatDialogRef<ReportBugComponent>) {}

  public submitReport(): void {
    Sentry.captureMessage(this.message);
    this.matDialogRef.close();
  }
}
