import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DataModelService } from '../../../services/data-model/data-model.service';

@Component({
  selector: 'app-month-plan-selector',
  templateUrl: './month-plan-selector.component.html',
  styleUrls: ['./month-plan-selector.component.scss']
})
export class MonthPlanSelectorComponent {
  public currentDate = new Date();
  public selectedDate: Date;
  public monthPagination = 0;
  public shownDates: Date[] = [];
  public isFirstPage = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) startSelectedDate: Date,
    private matDialogRef: MatDialogRef<MonthPlanSelectorComponent>,
    private dataModelService: DataModelService
  ) {
    this.selectedDate = startSelectedDate;
    if (!this.selectedDate) {
      this.selectedDate = new Date();
    }
    this.monthPagination = this.calculatePaginationOfSelectedDate(this.selectedDate);
    this.shownDates = this.getDatesForPagination();
  }

  public navigateToPreviousMonths(): void {
    this.monthPagination = this.monthPagination - 6;
    this.shownDates = this.getDatesForPagination();
  }

  public navigateToNextMonths(): void {
    this.monthPagination = this.monthPagination + 6;
    this.shownDates = this.getDatesForPagination();
  }

  public getDatesForPagination(): Date[] {
    const dates = [];
    this.isFirstPage = false;

    for (let i = -5; i <= 0; i++) {
      const copyDate = new Date(this.currentDate);
      copyDate.setUTCMonth(copyDate.getUTCMonth() + i + this.monthPagination);
      if (!this.isDateAfterCurrentMonth(copyDate) && !this.isDateBeforeFirstPlan(copyDate)) {
        dates.push(copyDate);
      } else if (this.isDateBeforeFirstPlan(copyDate)) {
        this.isFirstPage = true;
      }
    }

    dates.sort((a, b) => a - b);
    return dates;
  }

  public async selectDate(date: Date) {
    const paddedMonth = date.getUTCMonth() + 1 < 10 ? '0' + (date.getUTCMonth() + 1).toString() : (date.getUTCMonth() + 1).toString();
    const plan = 'p' + date.getUTCFullYear() + paddedMonth;
    this.dataModelService.setCurPlan(plan);

    const planDatesInfo = this.dataModelService.categoryManagement.obtainPlanDatesInfo();
    await this.dataModelService.categoryManagement.loadPreviousPlan(plan, planDatesInfo);

    this.matDialogRef.close(date);
  }

  private isDateAfterCurrentMonth(dateToCompare: Date): boolean {
    return (
      dateToCompare.getUTCFullYear() > this.currentDate.getUTCFullYear() ||
      (dateToCompare.getUTCFullYear() === this.currentDate.getUTCFullYear() && dateToCompare.getUTCMonth() > this.currentDate.getUTCMonth())
    );
  }

  private isDateBeforeFirstPlan(dateToCompare: Date): boolean {
    const planDates: Date[] = this.dataModelService.dataModel.global.planList
      .filter(e => e != 'original')
      .map(plan => {
        const planDate = plan.replace('p', '');
        return new Date(planDate.substring(0, 4) + '-' + planDate.substring(4, 6) + '-01');
      });

    planDates.sort((a, b) => a.getTime() - b.getTime());

    return (
      planDates[0].getUTCFullYear() > dateToCompare.getUTCFullYear() ||
      (planDates[0].getUTCFullYear() === dateToCompare.getUTCFullYear() && planDates[0].getUTCMonth() > dateToCompare.getUTCMonth())
    );
  }

  private calculatePaginationOfSelectedDate(selectedDate: Date): number {
    const yearDiff = this.currentDate.getUTCFullYear() - selectedDate.getUTCFullYear();
    const monthDiff = this.currentDate.getUTCMonth() - selectedDate.getUTCMonth();
    return -Math.floor((yearDiff * 12 + monthDiff) / 6) * 6;
  }
}
