import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginComponent } from '../login.component';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent extends LoginComponent implements OnInit, OnDestroy {
  public ngOnInit() {
    document.getElementById('sidebar').style.display = 'none';
    document.getElementById('page-content').style.backgroundColor = 'rgb(47, 47, 47)';
    document.getElementById('page-content').style.boxShadow = 'none';
    document.getElementById('page-content').style.border = '0';
    this.loading = false;
    // Remove all body classes before adding new ones.
    while (this.body.classList.length > 0) {
      this.body.classList.remove(this.body.classList.item(0));
    }
    this.body.classList.add('login');
  }

  public ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    //console.log(document.getElementById("main-row"));
    document.getElementById('page-content').style.backgroundColor = '';
    document.getElementById('sidebar').style.display = '';
  }
}
