import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataModelService } from '../../../../services/data-model/data-model.service';

@Component({
  selector: 'app-all-accounts',
  templateUrl: './all-accounts.component.html',
  styleUrls: ['./all-accounts.component.scss']
})
export class AllAccountsComponent implements OnInit {
  public accounts: any[];
  public institutions: any[];
  public plaidInstitution: any;
  constructor(protected dataModelService: DataModelService, protected router: Router) {}

  public ngOnInit() {
    this.updatePlaidData();
  }
  protected getInstitutionAccounts(institution) {
    return this.accounts.filter(acct => acct.item_id === institution.item_id && acct.wizeFiCategory.indexOf('assets_') !== -1);
  }

  private updatePlaidData() {
    this.institutions = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions;
    this.accounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
  }
}
