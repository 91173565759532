import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { SetupFlowDataManagementService, SetupFlowScreen } from '../../../services/setup/setup-flow-data-management.service';
import { CurrencyConfirmationComponent } from './currency-confirmation/currency-confirmation.component';
import { SetupService } from '@app/services/setup.service';
import { NewAccountDialogComponent, NewAccountDialogResult } from '@app/components/new-account-dialog/new-account-dialog.component';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-connect-bank',
  templateUrl: './connect-bank.component.html',
  styleUrls: ['./connect-bank.component.scss']
})
export class ConnectBankComponent implements SetupFlowScreen, OnInit {
  constructor(
    public dataModelService: DataModelService,
    private router: Router,
    dataManagement: SetupFlowDataManagementService,
    private dialog: MatDialog,
    private setupService: SetupService
  ) {
    this.dataModelService.setCurPlan('original');
    dataManagement.currentSetupScreen = this;
  }

  public ngOnInit() {
    this.setupService.updateCurrentSetupStep(0);
    if (this.dataModelService.dataModel.persistent.settings.haveSelectedCurrencyCode === false) {
      this.dialog.open(CurrencyConfirmationComponent, { disableClose: true });
    }
  }

  public canSave(): boolean {
    // don't allow user to manual click side bar's "save and continue" button
    // instead, they should link an account, and navigate to next page
    return false;
  }

  public onSave(): void {
    this.router.navigate(['/setup/review-accounts']);
  }

  public showOnSaveErrorMessage() {
    this.dataModelService.showErrorMessage('Please connect an account before continuing', 20000);
  }

  public createFirstAccount() {
    this.dialog
      .open<NewAccountDialogComponent, any, NewAccountDialogResult>(NewAccountDialogComponent)
      .afterClosed()
      .pipe(filter(result => !!result))
      .subscribe(() => {
        this.router.navigate(['/setup/review-accounts']);
      });
  }
}
