import { Component } from '@angular/core';
import { AccountTypeService } from 'src/app/services/account-type/account-type.service';
import { AccountService } from 'src/app/services/account/account.service';
import { DataModelService } from '../../../../services/data-model/data-model.service';
import { AccountDetailsComponent } from '../account-details/account-details.component';

@Component({
  selector: 'app-residence-property-account-details',
  templateUrl: './residence-property-account-details.component.html',
  styleUrls: ['../account-details-template/account-details-template.component.scss']
})
export class ResidencePropertyAccountDetailsComponent extends AccountDetailsComponent {
  constructor(public dataModelService: DataModelService, accountTypeService: AccountTypeService, accountService: AccountService) {
    super(dataModelService, accountTypeService, accountService);
  }
  protected bal: number;
  public ngOnInit() {
    super.ngOnInit();
    this.bal = (this.wizeFiAccount && this.wizeFiAccount.accountValue && this.wizeFiAccount.accountValue.val) || this.plaidAccount.balance || 0;
    console.log(this.plaidAccount);
    console.log(this.wizeFiAccount);
    console.log(this.selectedSubtype);
    this.getIncomeAccountFromPlaidAccount(this.plaidAccount);
  }
  protected onGrowthRateChange(value: string) {
    this.wizeFiAccount.interestRate.val = parseFloat(value) || 0;
  }

  protected onEstimatedValueChange(value: string) {
    this.wizeFiAccount.accountValue.val = parseFloat(value) || 0;
    this.plaidAccount.balance = parseFloat(value) || 0;
  }

  protected onNetIncomeChanged(value: string) {
    const newValue = value.replace(/\,/g, '');
    console.log(newValue);
    this.newIncome = parseFloat(newValue) || 0;
  }

  public onNetIncomeFocusOut(value: string) {
    const newValue = value.replace(/\,/g, '');
    console.log(newValue);
    this.newIncome = parseFloat(newValue) || 0;
  }
}
