import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { environment } from '../../environments/environment';
import { pictureNotAvailableObjectKey } from '../services/data-model/data-model_0001.data';
import { Crop } from '../components/cropper/cropper.component';
import { DataModelService } from './data-model/data-model.service';

declare const AWS: any;
@Injectable()
export default class fileUploadService {
  private pictureNotAvailableURL = this.setPictureNotAvailableURL();
  public fileURL = this.pictureNotAvailableURL;
  private virtualStyleURL = this.setVirtualURL(environment.AWSRegion, environment.bucketName, 'DaveEland.jpg');
  private pathStyleURL = this.setPathURL(environment.AWSRegion, environment.bucketName, 'DaveEland.jpg');
  public wizeFiUserPictureURL = this.setWizeFiUserPictureURL();

  constructor(public dataModelService: DataModelService, private dialog: MatDialog) {}

  public uploadPicture(
    event?: any,
    imageFile?: any,
    confirmPicture?: boolean
  ) /*
    This routine uploads a WizeFi user picture to Amazon S3 and stores the reference to the picture in the WizeFi data model.

    The event parameter is sent by the HTML input file widget (this function is invoked in the HTML code).

    TODO -- consider alternative techniques for stepping through step 1 and step 2 other than using the confirm function.

    Note: the confirm function does not work as expected if attempting to execute this function multiple times.
    */ {
    const uploadPicture0 = async () => {
      console.log('\nupload picture'); //%//

      // step 1 -- select and display the selected picture file

      // set value of file object (which contains information about the user selected file)
      let file: any; // deal with Typescript data type issues
      let wantPicture: boolean;
      if (event !== null) {
        file = event.target.files[0];
      } else {
        file = imageFile;
      }
      console.log(file);
      // obtain fileURL for the memory resident file in order to display the selected picture
      let fileURL: any; // deal with Typescript data type issue
      fileURL = await this.getfileURL(file);
      this.fileURL = fileURL; // enable display of memory resident version of selected picture
      console.log('memory resident fileURL: ' + fileURL); //%//

      await this.dataModelService.sleep(200); // kludge to let screen content settle before doing confirm action
      if (!confirmPicture) {
        wantPicture = confirm('Do you wish to save the selected picture?');
      } else {
        wantPicture = confirmPicture;
      }
      if (wantPicture) {
        // step 2 -- delete current picture
        await this.deletePicture();
        let trimmedName = 'CroppedPhoto-';
        if (file && file.name) {
          trimmedName = file.name.trim().replace(/\s+/g, '');
        }
        console.log(trimmedName);
        // step 3 -- store the selected picture in Amazon S3 and in the WizeFi data model
        // obtain information to enable upload of the picture file data to Amazon S3
        const bucketName = environment.bucketName;
        const objectKey = trimmedName + '-' + this.dataModelService.dataModel.global.wizeFiID;
        console.log('object key:', objectKey);
        const fileData = await this.getfileData(file);
        console.log('fileData: ', fileData); //%//

        // upload picture file to Amazon S3 for storage
        let result: any; // deal with Typescript data type issue
        result = await this.upload(bucketName, objectKey, fileData);
        console.log('upload(' + bucketName + ',' + objectKey + ') result: ', result); //%//

        // store picture identification information in WizeFi data model
        this.dataModelService.dataModel.persistent.profile.userPictureObjectKey = objectKey;
        await this.dataModelService.dataManagement.storeinfo();

        // obtain wizeFiUserPictureURL for the user picture identified in the WizeFi data model
        this.wizeFiUserPictureURL = this.setWizeFiUserPictureURL(); // enable display of picture referenced in WizeFi data model
        console.log('wizeFiUserPictureURL: ' + this.wizeFiUserPictureURL); //%//
      }
      if (!confirmPicture) {
        const CurrentDialog = this.dialog.open(Crop, {
          width: '80%',
          data: { Image: file, ImagePath: fileURL, URL: this.wizeFiUserPictureURL },
          panelClass: 'custom-modalbox'
        });
        CurrentDialog.afterClosed().subscribe(result => {
          console.log(result);
          this.setWizeFiUserPictureURL();
        });
      }
    }; // uploadPicture0

    return uploadPicture0()
      .then(() => {
        console.log('finished uploadPicture');
      })
      .catch(err => {
        console.log('error in uploadPicture: ', err);
      });
  } // uploadPicture

  public deletePicture() /*
    This routine deletes a picture from Amazon S3 and from the WizeFi data model.

    The function is invoked upon clicking on a "Delete Picture" button (this function is invoked in the HTML code)
    */ {
    const deletePicture0 = async () => {
      console.log('\ndelete picture'); //%//

      const userPictureObjectKey = this.dataModelService.dataModel.persistent.profile.userPictureObjectKey;

      // note: only delete a picture that does not have the objectKey value of pictureNotAvailableObjectKey
      if (userPictureObjectKey !== pictureNotAvailableObjectKey) {
        // initialize
        const bucketName = environment.bucketName;
        const objectKey = userPictureObjectKey;

        // delete picture from Amazon S3
        const result = await this.deleteObject(bucketName, objectKey);
        console.log('deleteObject(' + bucketName + ',' + objectKey + ') -- result: ', result); //%//

        // adjust WizeFi data model to indicate a picture is not available
        this.dataModelService.dataModel.persistent.profile.userPictureObjectKey = pictureNotAvailableObjectKey;
        await this.dataModelService.dataManagement.storeinfo();

        // update wizeFiUserPictureURL for the user picture identified in the WizeFi data model
        this.wizeFiUserPictureURL = this.setWizeFiUserPictureURL(); // enable display of picture referenced in WizeFi data model
      }
    }; // deletePicture0

    deletePicture0()
      .then(() => {
        console.log('finished deletePicture');
      })
      .catch(err => {
        console.log('error in deletePicture: ', err);
      });
  } // deletePicture

  private setPictureNotAvailableURL() /*
    This routine sets the proper URL for the "picture not available" image.
    */ {
    const pictureNotAvailableURL = this.setVirtualURL(environment.AWSRegion, environment.bucketName, pictureNotAvailableObjectKey);
    return pictureNotAvailableURL;
  } // setPictureNotAvailableURL

  private setVirtualURL(
    region,
    bucketName,
    objectKey
  ) /*
    This routine returns a URL of an Amazon S3 object in the "virtual-hosted–style access" format.
    (This is the proper format to use.)
    */ {
    const virtualURL = 'https://' + bucketName + '.s3.' + region + '.amazonaws.com/' + objectKey;
    return virtualURL;
  } // setVirtualURL

  private setPathURL(
    region,
    bucketName,
    objectKey
  ) /*
    This routine returns a URL of an Amazon S3 object in the "path-style access" format.
    (This format has been deprecated.)
    */ {
    const pathURL = 'https://s3.' + region + '.amazonaws.com/' + bucketName + '/' + objectKey;
    return pathURL;
  } // setPathURL

  public setWizeFiUserPictureURL() /*
    This routine sets the proper URL for displaying for the current WizeFi user a picture in an HTML img widget.

    In Angular, this is utilized as illustrated below:

    this.wizeFiUserPictureURL = this.setWizeFiUserPictureURL();  // in the component .ts file code, set the URL local variable

    <img [src]="wizeFiUserPictureURL" width="100" height="100">  // in the HTML code, plant the URL value as the src value

    */ {
    const region = environment.AWSRegion;
    const bucketName = environment.bucketName;

    const objectKey = this.dataModelService.dataModel.persistent.profile.userPictureObjectKey;
    const wizeFiUserPictureURL = this.setVirtualURL(region, bucketName, objectKey);
    // console.log(wizeFiUserPictureURL);
    return wizeFiUserPictureURL;
  } // setWizeFiUserPictureURL

  private getfileURL(
    file: any
  ) /*
    This routine obtains a URL for a memory resident version of a file.

    The file parameter is derived from the event data provided by the HTML input file widget.
    */ {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e: any) {
        const fileURL = e.target.result;
        resolve(fileURL);
      };
      // TODO refine error handling
      reader.onabort = () => {
        reject('abort error occurred');
      };
      reader.addEventListener('error', () => {
        reject('event error occurred');
      });
    }); // return Promise
  } // getfileURL

  private getfileData(
    file: any
  ) /*
    This routine obtains the fileData for a memory resident version of a file.

    The file parameter is derived from the event data provided by the HTML input file widget.
    */ {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = function (e: any) {
        const fileData = new Uint8Array(e.target.result);
        resolve(fileData);
      };
      // TODO refine error handling
      reader.onabort = () => {
        reject('abort error occurred');
      };
      reader.addEventListener('error', () => {
        reject('event error occurred');
      });
    }); // return Promise
  } // getfileData

  private deleteObject(bucketName, objectKey) /*
    This JavaScript SDK function deletes an object from an Amazon S3 bucket.
    */ {
    return new Promise((resolve, reject) => {
      const params = {
        Bucket: bucketName,
        Key: objectKey
      };
      this.dataModelService.dataModel.global.s3.deleteObject(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    }); // return Promise
  } // deleteObject

  private upload(bucketName, objectKey, fileData) /*
    This JavaScript SDK function uploads an object to an Amazon S3 bucket.
    */ {
    return new Promise((resolve, reject) => {
      const params = {
        Body: fileData,
        Bucket: bucketName,
        Key: objectKey
      };
      this.dataModelService.dataModel.global.s3.upload(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    }); // return Promise
  } // upload
}
