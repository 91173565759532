import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-close-icon',
  templateUrl: './close-icon.component.html',
  styleUrls: ['./close-icon.component.scss']
})
export class CloseIconComponent implements OnInit {
  @Input() public size?: string;
  @Input() public color?: string;

  public privateSize = '100px';
  public privateColor = '#dce4ef';

  constructor() {}

  public ngOnInit() {
    this.privateSize = this.size ? this.size : this.privateSize;
    this.privateColor = this.color ? this.color : this.privateColor;
  }
}
