// category-management.class.ts

import * as _ from 'lodash';
import { IWizeFiCategory } from '../interfaces/iWizeFiCategory.interface';
import {
  accountTypes,
  categoryExcludeList,
  categoryInfo,
  specialWizefiCategory,
  subcategoryExcludeList
} from '../services/data-model/data-model_0001.data';
import { CValidityCheck } from '../utilities/validity-check.class';
declare let Plaid: any;

export class CategoryManagement {
  public assignWizeFiCategory;
  // variables used in rule sort process
  public attributesCount: any;
  public attributeString: any;
  public attributeValues: any;
  public attributeValuesString: any;
  public wizeFiCategoryPattern: any;
  public totalComparisons: number;
  public wantTrace: boolean;
  public ruleAttributeList: string[]; // list of transaction attribute names that can be used to define rules

  constructor(public dataModelService: any) {
    this.ruleAttributeList = ['merchantName', 'accountName', 'institutionName', 'category', 'amount'];
  } // constructor

  //////////////////////////////////////////////////////////////////////////
  // routines to manage WizeFiGenericCategories information
  //////////////////////////////////////////////////////////////////////////

  public putWizeFiGenericCategories(company, wizeFiGenericCategories) {
    return new Promise((resolve, reject) => {
      // define params to guide put operation
      const params = {
        TableName: 'WizeFiGenericCategories',
        Item: {
          company,
          wizeFiGenericCategories: JSON.stringify(wizeFiGenericCategories)
        }
      };

      // put info in DynamoDB table
      this.dataModelService.dataModel.global.docClient.put(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve('Data has been stored');
        }
      });
    }); // return Promise
  } // putWizeFiGenericCategories

  public getWizeFiGenericCategories(company) {
    return new Promise((resolve, reject) => {
      // define params to guide query operation
      const params = {
        TableName: 'WizeFiGenericCategories',
        Key: { company }
      };

      this.dataModelService.dataModel.global.docClient.get(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          if (!data.hasOwnProperty('Item')) {
            resolve(['none']); // return an array with only "none" in the list
          } else {
            const wizeFiGenericCategories = JSON.parse(data.Item.wizeFiGenericCategories);
            resolve(wizeFiGenericCategories);
          }
        }
      });
    }); // return Promise
  } // getWizeFiGenericCategories

  //////////////////////////////////////////////////////////////////////////
  // routines to manage WizeFiPlaidCategoryMap information
  //////////////////////////////////////////////////////////////////////////

  public putWizeFiPlaidCategoryMap(company, wizeFiPlaidCategoryMap) {
    return new Promise((resolve, reject) => {
      // define params to guide put operation
      const params = {
        TableName: 'WizeFiPlaidCategoryMap',
        Item: {
          company,
          wizeFiPlaidCategoryMap: JSON.stringify(wizeFiPlaidCategoryMap)
        }
      };

      // put info in DynamoDB table
      this.dataModelService.dataModel.global.docClient.put(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve('Data has been stored');
        }
      });
    }); // return Promise
  } // putWizeFiPlaidCategoryMap

  public getWizeFiPlaidCategoryMap(company): Promise<any> {
    return new Promise((resolve, reject) => {
      // define params to guide query operation
      const params = {
        TableName: 'WizeFiPlaidCategoryMap',
        Key: { company }
      };

      this.dataModelService.dataModel.global.docClient.get(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          if (!data.hasOwnProperty('Item')) {
            resolve({}); // return an empty object
          } else {
            const wizeFiPlaidCategoryMap = JSON.parse(data.Item.wizeFiPlaidCategoryMap);
            resolve(wizeFiPlaidCategoryMap);
          }
        }
      });
    }); // return Promise
  } // getWizeFiPlaidCategoryMap

  //////////////////////////////////////////////////////////////////////////
  // routines to manage WizeFiTransactionAttributePatterns information
  //////////////////////////////////////////////////////////////////////////

  public putWizeFiTransactionAttributePatterns(wizeFiID, wizeFiTransactionAttributePatterns) {
    return new Promise((resolve, reject) => {
      // define params to guide put operation
      const params = {
        TableName: 'WizeFiTransactionAttributePatterns',
        Item: {
          wizeFiID,
          wizeFiTransactionAttributePatterns: JSON.stringify(wizeFiTransactionAttributePatterns)
        }
      };

      // put info in DynamoDB table
      this.dataModelService.dataModel.global.docClient.put(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve('Data has been stored');
        }
      });
    }); // return Promise
  } // putWizeFiTransactionAttributePatterns

  public getWizeFiTransactionAttributePatterns(wizeFiID): Promise<any> {
    return new Promise((resolve, reject) => {
      // define params to guide query operation
      const params = {
        TableName: 'WizeFiTransactionAttributePatterns',
        Key: { wizeFiID }
      };

      this.dataModelService.dataModel.global.docClient.get(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          if (!data.hasOwnProperty('Item')) {
            resolve({}); // return an empty object
          } else {
            const wizeFiTransactionAttributePatterns = JSON.parse(data.Item.wizeFiTransactionAttributePatterns);
            resolve(wizeFiTransactionAttributePatterns);
          }
        }
      });
    }); // return Promise
  } // getWizeFiTransactionAttributePatterns

  public extractWizeFiCategoryNameParts(wizeFiCategoryName): IWizeFiCategory {
    // NOTE: The decodeWizeFiCategory function provides a more robust approach than what this function does.
    const parts = wizeFiCategoryName.split('_');
    const category = parts[0];
    const subcategory = parts.length > 1 ? parts[1] : '';
    const account = parts.length > 2 ? parts[2] : '';
    return { category, subcategory, account, wizeFiCategoryName };
  } // extractWizeFiCategoryNameParts

  public getWizeFiCategories(): IWizeFiCategory[] {
    const wizeFiCategoryList: any = [];
    const makeWizeFiCategoryName = (category, subcategory, account) => category + '_' + subcategory + '_' + account; // makeWizeFiCategoryName

    for (const category of Object.keys(this.dataModelService.dataModel.persistent.plans.original)) {
      if (categoryExcludeList.indexOf(category) === -1) {
        for (const subcategory of Object.keys(this.dataModelService.dataModel.persistent.plans.original[category])) {
          if (subcategoryExcludeList.indexOf(subcategory) === -1) {
            for (
              let acntndx = 0;
              acntndx < this.dataModelService.dataModel.persistent.plans.original[category][subcategory].accounts.length;
              acntndx++
            ) {
              const accounts = this.dataModelService.dataModel.persistent.plans.original[category][subcategory].accounts;
              const account = accounts[acntndx].accountName.val;
              const wizeFiCategory = makeWizeFiCategoryName(category, subcategory, account);
              wizeFiCategoryList.push(wizeFiCategory);
            } // for acntndx
          } // if include subcategory
        } // for subcategory
      } // if include category
    } // for category
    return wizeFiCategoryList.map(wizeFiCategoryName => this.extractWizeFiCategoryNameParts(wizeFiCategoryName));
  } // getWizeFiCategories

  public makeWizeFiCategoryName(category, subcategory, account) {
    return category + '_' + subcategory + '_' + account;
  } // makeWizeFiCategoryName

  // %//  /\  review whether to remove the above three functions and replace them with some of those below instead

  /*******************************************
  WizeFiCategory Management

  A wizeFiCategory uniquely identifies an account in WizeFi.  It could also be considered to be the unique identification of a "bin"
  in which the sum of various transaction amounts for a given month can be stored as an actualMonthlyAmount value in the appropriate bin.

  A wizeFiCategory has the format category_subcategory_account

  The category and subcategory values are simple strings that provide a name for a category or subcategory.

  The account value is more complicated -- an account can be uniquely defined within the accounts array by one of the following:

  acntndx -- the subscript of the account in the dataModelService.dataModel.persistent.plans[curplan][category][subcategory].accounts array
  accountName -- dataModelService.dataModel.persistent.plans[curplan][category][subcategory].accounts[acntndx].accountName.val
  accountID -- dataModelService.dataModel.persistent.plans[curplan][category][subcategory].accounts[acntndx].accountID.val

  The problem with acntndx and accountName is that these values can be changed over time:

  acntndx -- can change if an account is deleted from the accounts array
  accountName -- can change if the user changes the name of the account

  However, the accountID is assigned when the account is created, and is never changed.

  Hence, accountID is the proper choice of which of the above to use in defining a wizeFiCategory.

  So more specifically, a wizeFiCategory has the format category_subcategory_accountID

  In a broader perspective, one could consider the following to be variations of a wizeFiCategory format:

  type                 pattern                           example
  -------------------  --------------------------------  --------------------
  wizeFiCategory       category_subcategory_accountID    budget_housing_ID005
  wizeFiCategoryName   category_subcategory_accountName  budget_housing_Water
  wizeFiCategoryIndex  category_subcategory_acntndx      budget_housing_4

  The wizeFiCategoryName format would be useful when exposing a wizeFiCategory string to an end user (e.g. when the user selects a wizeFiCategory from a list,
    or views the wizeFiCategory assigned to a transaction).  So while the wizeFiCategory behind the scenes uses accountID, the end user would see accountName when viewing a wizeFiCategory.

  The functions below are for working with wizeFiCategories
  *******************************************/

  public getNewAccountID(accounts) {
    // determine next available accountID for this list of accounts
    let highestAccountNum = 0;
    for (const account of accounts) {
      if (account && account.hasOwnProperty('accountID')) {
        const accountNum = this.accountID2number(account.accountID.val);
        if (accountNum > highestAccountNum) {
          highestAccountNum = accountNum;
        }
      }
    }
    highestAccountNum++;
    let accountID = highestAccountNum.toString();
    while (accountID.length < 3) {
      accountID = '0' + accountID;
    }
    accountID = 'ID' + accountID;

    return accountID;
  } // getNewAccountID

  public makeWizeFiCategory(category, subcategory, account) {
    return category + '_' + subcategory + '_' + account;
  } // makeWizeFiCategory

  public decodeWizeFiCategory(wizeFiCategory, optionalSubType = null) {
    // console.log("wizefi Category:", wizeFiCategory);
    // initialize
    let wizeFiCategoryInfo: any;
    wizeFiCategoryInfo = {
      category: 'none',
      subcategory: 'none',
      accountID: 'none',
      acntndx: 0,
      accountName: 'none',
      subtype: optionalSubType
    };

    if (['none', 'unknown', 'ignore'].includes(wizeFiCategory)) {
      wizeFiCategoryInfo.category = wizeFiCategory;
    } else if (wizeFiCategory === undefined) {
      wizeFiCategoryInfo.category === 'unkown';
    } else {
      const matches = wizeFiCategory.match(/^([^_]+)_([^_]+)_(.+)$/);
      if (matches !== null) {
        const category = matches[1];
        const subcategory = matches[2];
        const account = matches[3];

        const curplan = this.dataModelService.dataModel.persistent.header.curplan;
        if (this.dataModelService.dataModel.persistent.plans[curplan][category][subcategory]) {
          const accounts = this.dataModelService.dataModel.persistent.plans[curplan][category][subcategory].accounts;

          // determine type of account designation
          let accountType;
          if (/^ID\d+$/.test(account)) {
            accountType = 'accountID';
          } else if (/^\d+$/.test(account)) {
            accountType = 'acntndx';
          } else {
            accountType = 'accountName';
          }

          // assign values to the wizeFiCategoryInfo object
          wizeFiCategoryInfo.category = category;
          wizeFiCategoryInfo.subcategory = subcategory;

          switch (accountType) {
            case 'accountID':
              wizeFiCategoryInfo.accountID = account;
              wizeFiCategoryInfo.acntndx = this.accountID2acntndx(account, accounts);
              wizeFiCategoryInfo.accountName = this.acntndx2accountName(wizeFiCategoryInfo.acntndx, accounts);
              break;
            case 'accountName':
              wizeFiCategoryInfo.accountName = account;
              wizeFiCategoryInfo.acntndx = this.accountName2acntndx(account, accounts);
              wizeFiCategoryInfo.accountID = this.acntndx2accountID(wizeFiCategoryInfo.acntndx, accounts);
              break;
            case 'acntndx':
              wizeFiCategoryInfo.acntndx = account;
              wizeFiCategoryInfo.accountID = this.acntndx2accountID(wizeFiCategoryInfo.acntndx, accounts);
              wizeFiCategoryInfo.accountName = this.acntndx2accountName(wizeFiCategoryInfo.acntndx, accounts);
              break;
          } // switch
        }
      }
    }
    return wizeFiCategoryInfo;
  } // decodeWizeFiCategory

  public accountID2number(accountID) {
    // TODO how deal with accountID with incorrect format
    return parseInt(accountID.substr(2), 10);
  } // accountID2number

  public accountID2acntndx(accountID, accounts) {
    let acntndx = accounts.length;
    while (--acntndx >= 0 && accounts[acntndx].accountID.val !== accountID) {}
    // TODO determine how to handle accountID is not in accounts (currently returns -1 if not found)
    return acntndx;
  } // accountID2acntndx

  public accountID2accountName(accountID, accounts) {
    let acntndx = accounts.length;
    while (--acntndx >= 0 && accounts[acntndx].accountID.val !== accountID) {}
    // TODO determine how to handle accountID is not in accounts (currently returns unknown if not found)
    if (acntndx === -1) {
      return 'unknown';
    } else {
      return accounts[acntndx].accountName.val;
    }
  } // accountID2accountName

  public accountName2acntndx(accountName, accounts) {
    let acntndx = accounts.length;
    while (--acntndx >= 0 && accounts[acntndx].accountName.val !== accountName) {}
    // TODO determine how to handle accountName is not in accounts (currently returns -1 if not found)
    return acntndx;
  } // accountName2acntndx

  public accountName2accountID(accountName, accounts) {
    let acntndx = accounts.length;
    while (--acntndx >= 0 && accounts[acntndx].accountName.val !== accountName) {}
    // TODO determine how to handle accountID is not in accounts (currently returns unknown if not found)
    if (acntndx === -1) {
      return 'unknown';
    } else {
      return accounts[acntndx].accountID.val;
    }
  } // accountName2accountID

  public acntndx2accountID(acntndx, accounts) {
    // TODO determine how to handle acntndx is not subscript in accounts
    if (acntndx < 0 || acntndx >= accounts.length) {
      return 'unknown';
    } else {
      return accounts[acntndx].accountID.val;
    }
  } // acntndx2accountID

  public acntndx2accountName(acntndx, accounts) {
    // TODO determine how to handle acntndx is not subscript in accounts
    if (acntndx < 0 || acntndx >= accounts.length) {
      return 'unknown';
    } else {
      return accounts[acntndx].accountName.val;
    }
  } // acntndx2accountName

  public reformatWizeFiCategory(format, wizeFiCategory) {
    let newWizeFiCategory;

    if (specialWizefiCategory.includes(wizeFiCategory) || !this.isValidWizeFiCategoryAccount(wizeFiCategory)) {
      newWizeFiCategory = wizeFiCategory;
    } else {
      const info = this.decodeWizeFiCategory(wizeFiCategory);

      switch (format) {
        case 'accountID':
          newWizeFiCategory = this.makeWizeFiCategory(info.category, info.subcategory, info.accountID);
          break;
        case 'accountName':
          newWizeFiCategory = this.makeWizeFiCategory(info.category, info.subcategory, info.accountName);
          break;
        case 'acntndx':
          newWizeFiCategory = this.makeWizeFiCategory(info.category, info.subcategory, info.acntndx);
          break;
      } // switch
    }
    return newWizeFiCategory;
  } // reformatWizeFiCategory

  public getWizeFiCategoryList() {
    const wizeFiCategoryList = [];
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;
    for (const category of Object.keys(this.dataModelService.dataModel.persistent.plans[curplan])) {
      if (categoryExcludeList.indexOf(category) === -1) {
        for (const subcategory of Object.keys(this.dataModelService.dataModel.persistent.plans[curplan][category])) {
          if (subcategoryExcludeList.indexOf(subcategory) === -1) {
            const accounts = this.dataModelService.dataModel.persistent.plans[curplan][category][subcategory].accounts;
            for (const account of accounts) {
              const accountID = account.accountID.val;
              const wizeFiCategoryName = this.makeWizeFiCategory(category, subcategory, accountID);
              wizeFiCategoryList.push(wizeFiCategoryName);
            } // for acntndx
          } // if include subcategory
        } // for subcategory
      } // if include category
    } // for category
    wizeFiCategoryList.sort();
    return wizeFiCategoryList;
  } // getWizeFiCategoryList

  public getFilteredWizeFiCategoryList(selectedCategory, selectedSubcategory, wizeFiCategoryList) {
    const filteredWizeFiCategoryList = [];
    for (const wizeFiCategory of wizeFiCategoryList) {
      const info = this.extractWizeFiCategoryNameParts(wizeFiCategory);
      const category = info.category;
      const subcategory = info.subcategory;
      if (
        (selectedCategory === 'any' || info.category === selectedCategory) &&
        (selectedSubcategory === 'any' || info.subcategory === selectedSubcategory)
      ) {
        filteredWizeFiCategoryList.push(wizeFiCategory);
      }
    }
    return filteredWizeFiCategoryList;
  } // getFilteredWizeFiCategoryList

  public getFilteredWizeFiCategoryLinkList(category, subcategory, wizeFiCategoryList) {
    const filteredWizeFiCategoryLinkList = ['none'];
    for (const wizeFiCategory of wizeFiCategoryList) {
      const info = this.extractWizeFiCategoryNameParts(wizeFiCategory);
      if (info.category === category && info.subcategory === subcategory) {
        filteredWizeFiCategoryLinkList.push(wizeFiCategory);
      }
    }
    return filteredWizeFiCategoryLinkList;
  } // getFilteredWizeFiCategoryLinkList

  public getCategoryList(wizeFiCategoryList) {
    const categoryList = ['any'];
    for (const wizeFiCategory of wizeFiCategoryList) {
      if (wizeFiCategory !== 'none' && wizeFiCategory !== 'unknown') {
        const info = this.extractWizeFiCategoryNameParts(wizeFiCategory);
        if (categoryList.indexOf(info.category) === -1) {
          categoryList.push(info.category);
        }
      }
    }
    return categoryList;
  } // getCategoryList

  public getCategoryLinkList(wizeFiCategoryList) {
    const categoryLinkList = [];
    for (const wizeFiCategory of wizeFiCategoryList) {
      if (wizeFiCategory !== 'none' && wizeFiCategory !== 'unknown') {
        const info = this.extractWizeFiCategoryNameParts(wizeFiCategory);
        if (categoryLinkList.indexOf(info.category) === -1) {
          categoryLinkList.push(info.category);
        }
      }
    }
    return categoryLinkList;
  } // getCategoryLinkList

  public getSubcategoryList(wizeFiCategoryList) {
    const subcategoryList = {} as any;
    subcategoryList.any = ['any'];
    for (const wizeFiCategory of wizeFiCategoryList) {
      if (wizeFiCategory !== 'none' && wizeFiCategory !== 'unknown') {
        const info = this.extractWizeFiCategoryNameParts(wizeFiCategory);
        if (!subcategoryList.hasOwnProperty(info.category)) {
          subcategoryList[info.category] = ['any'];
        }
        if (subcategoryList[info.category].indexOf(info.subcategory) === -1) {
          subcategoryList[info.category].push(info.subcategory);
        }
      }
    }
    return subcategoryList;
  } // getSubcategoryList

  public getSubcategoryLinkList(wizeFiCategoryList) {
    const subcategoryLinkList = {};
    for (const wizeFiCategory of wizeFiCategoryList) {
      if (wizeFiCategory !== 'none' && wizeFiCategory !== 'unknown') {
        const info = this.extractWizeFiCategoryNameParts(wizeFiCategory);
        if (!subcategoryLinkList.hasOwnProperty(info.category)) {
          subcategoryLinkList[info.category] = [];
        }
        if (subcategoryLinkList[info.category].indexOf(info.subcategory) === -1) {
          subcategoryLinkList[info.category].push(info.subcategory);
        }
      }
    }
    return subcategoryLinkList;
  } // getSubcategoryLinkList

  /////////////////////////////////////////////
  // new added functions
  /////////////////////////////////////////////

  public getGenericCategory2wizeFiCategory() {
    const genericCategory2wizeFiCategory = {};
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;

    for (const category of Object.keys(this.dataModelService.dataModel.persistent.plans[curplan])) {
      if (categoryExcludeList.indexOf(category) === -1) {
        for (const subcategory of Object.keys(this.dataModelService.dataModel.persistent.plans[curplan][category])) {
          if (subcategoryExcludeList.indexOf(subcategory) === -1) {
            for (const account of this.dataModelService.dataModel.persistent.plans[curplan][category][subcategory].accounts) {
              if (account.hasOwnProperty('genericCategory')) {
                const wizeFiCategory = this.dataModelService.categoryManagement.makeWizeFiCategory(category, subcategory, account.accountID.val);
                genericCategory2wizeFiCategory[account.genericCategory.val] = wizeFiCategory;
              }
            } // for acntndx
          } // if include subcategory
        } // for subcategory
      } // if include category
    } // for category
    return genericCategory2wizeFiCategory;
  } // getGenericCategory2wizeFiCategory

  public getWizeFiCategoryFromGenericCategory(genericCategory) {
    // initialize
    let wizeFiCategory = 'none';
    // TODO consider whether to do this only at the time of login to the app (to save time later)
    const genericCategory2wizeFiCategory = this.getGenericCategory2wizeFiCategory();

    if (genericCategory2wizeFiCategory.hasOwnProperty(genericCategory)) {
      wizeFiCategory = genericCategory2wizeFiCategory[genericCategory];
    }
    return wizeFiCategory;
  } // getWizeFiCategoryFromGenericCategory

  public assignWizeFiCategoryFromGenericCategory(monthDate) {
    const wizeFiPlaidCategoryMap = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidCategoryMap;
    const transactions = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection[monthDate];

    for (const transaction of transactions) {
      const plaidCategory = transaction.category;
      if (wizeFiPlaidCategoryMap.hasOwnProperty(plaidCategory)) {
        const genericCategory = wizeFiPlaidCategoryMap[plaidCategory];
        const wizeFiCategory = this.getWizeFiCategoryFromGenericCategory(genericCategory);
        if (transaction.wizeFiCategory === 'unknown' && wizeFiCategory !== 'none') {
          transaction.wizeFiCategory = wizeFiCategory;
        }
      }
    }
  } // assignWizeFiCategoryFromGenericCategory

  public sortKeys(keys) {
    return keys.sort();
  } // sortKeys

  ////////////////////////////////////////////////
  // rule (attribute pattern) routines
  ////////////////////////////////////////////////

  public makeAttributePatternString(attributePattern) {
    // console.log("makeAttributePatternString Attribute Pattern: ", attributePattern)
    let attributePatternString = '';
    if (attributePattern === undefined) {
      console.log('attribute pattern undefined');
    } else {
      for (const attribute of Object.keys(attributePattern).sort(this.attributeCompare.bind(this.dataModelService.categoryManagement))) {
        const prefix = attributePatternString === '' ? '' : '_';
        attributePatternString += prefix + attribute + ':' + attributePattern[attribute];
      }
    }

    return attributePatternString;
  } // makeAttributePatternString

  public makeRuleComparisonString(rule) {
    const ruleComparisonString = rule.wizeFiCategory + ' ' + this.makeAttributePatternString(rule.attributePattern);
    return ruleComparisonString;
  } // makeRuleComparisonString

  public haveDuplicateRule(rule1) {
    // initialize
    const wizeFiTransactionAttributePatterns = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionAttributePatterns;
    const v1 = this.makeRuleComparisonString(rule1);
    let haveDuplicateRule = false;
    if (wizeFiTransactionAttributePatterns) {
      for (const patternID of Object.keys(wizeFiTransactionAttributePatterns)) {
        const rule2 = wizeFiTransactionAttributePatterns[patternID];
        const v2 = this.makeRuleComparisonString(rule2);
        if (v1 === v2) {
          haveDuplicateRule = true;
          break; // terminate for loop if duplicate rule is encountered
        }
      }
    }
    // scan through all rules checking for a duplicate rule

    return haveDuplicateRule;
  } // haveDuplicateRule

  public async addDefaultRules(wizeFiPlaidAccount) {
    if (wizeFiPlaidAccount.isManual === true) {
      console.log('manual accounts dont get rules.');
      return;
    }
    // set defaultRuleMerchantNames
    const defaultRuleMerchantNames = {
      income: ['balance', 'deposit', 'transfer', 'intrst', 'interest', 'payment'],
      assets: ['balance', 'deposit', 'transfer', 'intrst', 'interest', 'payment', 'automatic', 'auto', 'pmt', 'autopay', 'crcardpmt', 'pymnt'],
      assetProtection: [
        'balance',
        'deposit',
        'transfer',
        'intrst',
        'interest',
        'payment',
        'automatic',
        'auto',
        'pmt',
        'autopay',
        'crcardpmt',
        'pymnt'
      ],
      liabilities: ['balance', 'deposit', 'transfer', 'intrst', 'interest', 'payment', 'automatic', 'auto', 'pmt', 'autopay', 'crcardpmt', 'pymnt'],
      budget: []
    };

    // set category
    // TODO deal with invalid wizeFiCategory
    const info = this.decodeWizeFiCategory(wizeFiPlaidAccount.wizeFiCategory);
    const category = info.category;
    if (CValidityCheck.isIterable(defaultRuleMerchantNames[category])) {
      console.log('defaultRuleMerchantNames[category] is itterable', defaultRuleMerchantNames[category]);
      // process all merchantNames present for the given category
      for (const merchantName of defaultRuleMerchantNames[category]) {
        // create a new rule
        const wizeFiTransactionAttributePattern = {
          patternID: 'dkxzj',
          isActive: true,
          isDefaultRule: true,
          source_id: wizeFiPlaidAccount.account_id, // account_id for default rules and transaction_id for user defined transaction rules
          wizeFiCategory: wizeFiPlaidAccount.wizeFiCategory,
          attributePattern: {
            merchantName,
            accountName: wizeFiPlaidAccount.accountName,
            institutionName: wizeFiPlaidAccount.institutionName
          }
        };

        // store the new rule in the data model
        await this.addAttributePattern(wizeFiTransactionAttributePattern);
      }
    } else {
      console.error('defaultRuleMerchantNames[category] is not itterable', defaultRuleMerchantNames[category]);
    }
  } // addDefaultRules

  public async addAttributePattern(wizeFiTransactionAttributePattern) {
    // TODO verify wizeFiCategory and attributePattern in wizeFiTransactionAttributePattern input parameter
    // initialize
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    const wizeFiTransactionAttributePatterns = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionAttributePatterns;

    if (!this.haveDuplicateRule(wizeFiTransactionAttributePattern)) {
      // set the patternID value
      const patternID = this.dataModelService.generateIDcode(5);
      wizeFiTransactionAttributePattern.patternID = patternID;

      // add the wizeFiTransactionAttributePattern to the wizeFiTransactionAttributePatterns object
      wizeFiTransactionAttributePatterns[patternID] = wizeFiTransactionAttributePattern;

      // store modified wizeFiTransactionAttributePatterns in DynamoDB
      await this.dataModelService.categoryManagement.putWizeFiTransactionAttributePatterns(wizeFiID, wizeFiTransactionAttributePatterns);
    }
  } // addAttributePattern

  public async deleteAttributePattern(patternID) {
    // initialize
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    const wizeFiTransactionAttributePatterns = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionAttributePatterns;

    // delete selected attribute pattern
    delete wizeFiTransactionAttributePatterns[patternID];

    // store modified wizeFiTransactionAttributePatterns in DynamoDB
    await this.dataModelService.categoryManagement.putWizeFiTransactionAttributePatterns(wizeFiID, wizeFiTransactionAttributePatterns);
  } // deleteAttributePattern

  public async modifyAttributePattern(wizeFiTransactionAttributePattern) {
    // initialize
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    const wizeFiTransactionAttributePatterns = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionAttributePatterns;
    const patternID = wizeFiTransactionAttributePattern.patternID;

    // put the modified attribute pattern into the data
    wizeFiTransactionAttributePatterns[patternID] = wizeFiTransactionAttributePattern;

    // store modified wizeFiTransactionAttributePatterns in DynamoDB
    await this.dataModelService.categoryManagement.putWizeFiTransactionAttributePatterns(wizeFiID, wizeFiTransactionAttributePatterns);
  } // modifyAttributePattern

  public checkForPatternMatch(transaction, wizeFiTransactionAttributePattern) {
    // If anything in the rule attribute set doesn't match then return false since it isn't 100% matching.
    try {
      for (const attribute of Object.keys(wizeFiTransactionAttributePattern.attributePattern)) {
        if (!wizeFiTransactionAttributePattern.attributePattern[attribute]) {
          continue;
        }
        if (!transaction[attribute] || !wizeFiTransactionAttributePattern.attributePattern[attribute]) {
          return false;
        }
        if (attribute != 'accountName') {
          if (
            !transaction[attribute] ||
            !transaction[attribute].toUpperCase().includes(wizeFiTransactionAttributePattern.attributePattern[attribute].toUpperCase())
          ) {
            return false;
          }
        } else {
          // matches should be comparing accountLabel but needs to compare accountName until a fix is made to how that works.
          const matches = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts.filter(
            e => e.wizeFiCategory == wizeFiTransactionAttributePattern.wizeFiCategory
          );
          if (matches.length == 0) {
            return false;
          }

          if (
            !transaction.accountName ||
            (transaction.accountName.toUpperCase() != wizeFiTransactionAttributePattern.attributePattern[attribute].toUpperCase() &&
              transaction.accountName.toUpperCase() != matches[0].accountLabel.toUpperCase())
          ) {
            return false;
          }
        }
      }
    } catch (e) {
      console.log(e);
      return false;
    }

    return true;
  } // checkForPatternMatch

  public async assignWizeFiCategoryFromTransactionPattern(monthDate) {
    const wizeFiPlaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    const activeWizeFiPlaidAccountIds: any = this.dataModelService.plaidManagement.setActiveWizeFiPlaidAccountIds(wizeFiPlaidAccounts);
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    let dateRange: any;
    dateRange = {};
    dateRange.wantMonthRange = true;
    dateRange.yearMonth = new Date().toISOString().substr(0, 7);
    if (!this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection) {
      await this.dataModelService.plaidManagement.getPlaidData();
      const title = this.dataModelService.dataManagement.getDraftTitle();
      await this.dataModelService.plaidManagement.getTransactions(wizeFiID, title, dateRange, activeWizeFiPlaidAccountIds);
    }

    if (!this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection) {
      return;
    }

    const transactions = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection[monthDate];

    const wizeFiTransactionAttributePatterns = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionAttributePatterns;

    // execute setPatternsInformation function to prepare for the patternID sort process
    this.setPatternsInformation();

    for (const transaction of transactions) {
      if (transaction.wizeFiCategory === 'unknown') {
        let haveMatch = false;
        for (const patternID of Object.keys(wizeFiTransactionAttributePatterns).sort(
          this.sortRuleCompare.bind(this.dataModelService.categoryManagement)
        )) {
          const wizeFiTransactionAttributePattern = wizeFiTransactionAttributePatterns[patternID];

          if (wizeFiTransactionAttributePattern.isActive) {
            haveMatch = this.checkForPatternMatch(transaction, wizeFiTransactionAttributePattern);
            if (haveMatch) {
              console.log('matched transaction', transaction);
              transaction.wizeFiCategory = wizeFiTransactionAttributePattern.wizeFiCategory;
              transaction.ruleApplied = true;
              break; // terminate this for loop
              // console.log("no match", transaction.merchantName)
            }
          }
        }
      } // if wizeFiCategory
    } // for transaction

    if (transactions && transactions.length > 0) {
      await this.dataModelService.dataManagement.putWizeFiTransactions(wizeFiID, monthDate, transactions);
    }
  } // assignWizeFiCategoryFromTransactionPattern

  public getRuleTransaction(patternID) {
    // initialize
    let transaction: any;
    transaction = null;
    const wizeFiTransactionAttributePatterns = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionAttributePatterns;
    const transaction_id = wizeFiTransactionAttributePatterns[patternID].source_id;
    const wizeFiTransactionsCollection = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection;

    // construct list of wizeFiTransactionsCollection dates in memory
    const dateList = [];
    for (const transactionYearMonth of Object.keys(wizeFiTransactionsCollection).sort()) {
      const wizeFiTransactions = wizeFiTransactionsCollection[transactionYearMonth];
      if (wizeFiTransactions.length > 0) {
        dateList.push(transactionYearMonth);
      }
    }

    // search through each month of transactions for desired transaction
    let i = dateList.length;
    let found = false;
    while (--i >= 0 && !found) {
      // search through each transaction in the current month for desired transaction
      const yearMonth = dateList[i];
      const transactions = wizeFiTransactionsCollection[yearMonth];
      let j = transactions.length;
      while (--j >= 0 && !found) {
        if (transactions[j].transaction_id === transaction_id) {
          found = true;
          transaction = transactions[j];
        }
      }
    }

    // TODO having not found it in memory, lookup transaction in RuleTransactions DynamoDB table
    if (!found) {
      // transaction = await ...  // check for not found in RuleTransactions
    }

    return transaction;
  } // getRuleTransaction

  public attributeCompare(a, b) {
    // initialize
    const aIndex = this.ruleAttributeList.indexOf(a);
    const bIndex = this.ruleAttributeList.indexOf(b);
    let result = 0;

    // set comparison result
    if (aIndex === -1) {
      result = 1;
    } else if (bIndex === -1) {
      result = -1;
    } else if (aIndex < bIndex) {
      result = -1;
    } else if (bIndex < aIndex) {
      result = 1;
    }

    return result;
  } // attributeCompare

  public setPatternsInformation() {
    // initialize
    const wizeFiTransactionAttributePatterns = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionAttributePatterns;
    this.attributesCount = {};
    this.attributeString = {};
    this.attributeValues = {};
    this.attributeValuesString = {};
    this.wizeFiCategoryPattern = {};
    this.totalComparisons = 0;
    if (wizeFiTransactionAttributePatterns === undefined) {
    } else {
      // process each patternID
      // console.log("Object.keys(wizeFiTransactionAttributePatterns): ", Object.keys(wizeFiTransactionAttributePatterns));  //%//
      for (const patternID of Object.keys(wizeFiTransactionAttributePatterns)) {
        const wizeFiTransactionAttributePattern = wizeFiTransactionAttributePatterns[patternID];
        // console.log("wizeFiTransactionAttributePattern.attributePattern:", wizeFiTransactionAttributePattern.attributePattern);
        if (wizeFiTransactionAttributePattern.attributePattern === undefined) {
          // console.log("wizeFiTransactionAttributePattern.attributePattern is undefined");
        } else {
          const attributeList = Object.keys(wizeFiTransactionAttributePattern.attributePattern);

          this.wizeFiCategoryPattern[patternID] = wizeFiTransactionAttributePattern.wizeFiCategory;
          this.attributesCount[patternID] = attributeList.length;
          this.attributeString[patternID] = attributeList.join();
          this.attributeValues[patternID] = {};
          this.attributeValuesString[patternID] = '';

          let firstTime = true;
          for (const attribute of Object.keys(wizeFiTransactionAttributePattern.attributePattern).sort(
            this.attributeCompare.bind(this.dataModelService.categoryManagement)
          )) {
            this.attributeValues[patternID][attribute] = wizeFiTransactionAttributePattern.attributePattern[attribute];

            const prefix = firstTime ? '' : ':';
            this.attributeValuesString[patternID] += prefix + wizeFiTransactionAttributePattern.attributePattern[attribute];
            firstTime = false;
          }
        }
      }
    }
  } // setPatternsInformation

  public sortRuleCompare(a, b) {
    const info = (funcA, funcB, step, funcResult) => funcA + '  ' + funcB + '  ' + step + ': ' + funcResult; // info

    // initialize
    let result = 0;
    // this.totalComparisons++;

    // determine result when number of attributes is different
    if (this.attributesCount[a] > this.attributesCount[b]) {
      result = -1;
      if (this.wantTrace) {
        console.log(info(a, b, 'A', result));
      }
    } else if (this.attributesCount[a] < this.attributesCount[b]) {
      result = 1;
      if (this.wantTrace) {
        console.log(info(a, b, 'B', result));
      }
    }

    // determine result when same number of attributes are involved
    if (result === 0) {
      // set result when different attributes are involved
      if (this.attributeString[a] !== this.attributeString[b]) {
        result = -1;
        if (this.wantTrace) {
          console.log(info(a, b, 'C', result));
        }
      }
    }

    // determine result when same attributes are involved
    if (result === 0) {
      if (this.attributeValues[a] === undefined || this.attributeValues[b] === undefined) {
        console.log('attribute values undefined', this.attributeValues[a], this.attributeValues[b]);
      } else {
        // set result based on whether one attribute value is a subset of the other
        for (const attribute of Object.keys(this.attributeValues[a]).sort(this.attributeCompare.bind(this.dataModelService.categoryManagement))) {
          //first check if the type is string, so that we can do an 'includes' check. solves TypeError: this.attributeValues[e][i].includes is not a function
          if (typeof this.attributeValues[a][attribute] === 'string' && typeof this.attributeValues[b][attribute] === 'string') {
            if (this.attributeValues[a][attribute] !== this.attributeValues[b][attribute]) {
              if (
                this.attributeValues[b][attribute] &&
                this.attributeValues[a][attribute] &&
                this.attributeValues[a][attribute].includes(this.attributeValues[b][attribute])
              ) {
                result = -1;
                if (this.wantTrace) {
                  console.log(info(a, b, 'D', result));
                }
              } else if (this.attributeValues[b][attribute] && this.attributeValues[b][attribute].includes(this.attributeValues[a][attribute])) {
                result = 1;
                if (this.wantTrace) {
                  console.log(info(a, b, 'E', result));
                }
              }
            }
          }
        }
        // set result based on all attribute values
        if (result === 0) {
          if (this.attributeValuesString[a] < this.attributeValuesString[b]) {
            result = -1;
            if (this.wantTrace) {
              console.log(info(a, b, 'F', result));
            }
          } else if (this.attributeValuesString[a] > this.attributeValuesString[b]) {
            result = 1;
            if (this.wantTrace) {
              console.log(info(a, b, 'G', result));
            }
          }
        }
      }
    }

    // determine result if not yet decided
    if (result === 0) {
      if (this.wizeFiCategoryPattern[a] === this.wizeFiCategoryPattern[b]) {
        result = -1;
        if (this.wantTrace) {
          console.log(info(a, b, 'H', result));
        }
      }
    }
    return result;
  } // sortRuleCompare

  public showRules() {
    const ruleAttributeList = ['merchantName', 'accountName', 'institutionName', 'category', 'amount'];

    const attributeCompare = (a, b) => {
      // initialize
      const aIndex = ruleAttributeList.indexOf(a);
      const bIndex = ruleAttributeList.indexOf(b);
      let result = 0;

      // set comparison result
      if (aIndex === -1) {
        result = 1;
      } else if (bIndex === -1) {
        result = -1;
      } else if (aIndex < bIndex) {
        result = -1;
      } else if (bIndex < aIndex) {
        result = 1;
      }

      return result;
    }; // attributeCompare

    // initialize
    const wizeFiTransactionAttributePatterns = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionAttributePatterns;

    // process each rule
    for (const patternID of Object.keys(wizeFiTransactionAttributePatterns)) {
      // initialize
      const wizeFiTransactionAttributePattern = wizeFiTransactionAttributePatterns[patternID];

      // set attributePatternInfo
      let attributePatternInfo = '';
      let firstTime = true;
      for (const attribute of Object.keys(wizeFiTransactionAttributePattern.attributePattern).sort(attributeCompare)) {
        const prefix = firstTime ? '' : ' ';
        attributePatternInfo += prefix + attribute + ':' + wizeFiTransactionAttributePattern.attributePattern[attribute];
        firstTime = false;
      }

      // display output
      console.log(
        patternID +
          '  ' +
          Object.keys(wizeFiTransactionAttributePattern.attributePattern).length +
          '  ' +
          wizeFiTransactionAttributePattern.wizeFiCategory +
          '  ' +
          attributePatternInfo
      );
    }
  } // showRules

  public showRuleSequence() {
    // initialize
    const wizeFiTransactionAttributePatterns = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionAttributePatterns;

    // console.log('before list: ', Object.keys(wizeFiTransactionAttributePatterns));
    // console.log('after  list: ', Object.keys(wizeFiTransactionAttributePatterns).sort(this.sortRuleCompare.bind(this.dataModelService.categoryManagement)));

    //
    for (const patternID of Object.keys(wizeFiTransactionAttributePatterns).sort(
      this.sortRuleCompare.bind(this.dataModelService.categoryManagement)
    )) {
      // initialize
      const wizeFiTransactionAttributePattern = wizeFiTransactionAttributePatterns[patternID];

      // set attributePatternInfo
      let attributePatternInfo = '';
      let firstTime = true;
      for (const attribute of Object.keys(wizeFiTransactionAttributePattern.attributePattern).sort(
        this.attributeCompare.bind(this.dataModelService.categoryManagement)
      )) {
        const prefix = firstTime ? '' : ' ';
        attributePatternInfo += prefix + attribute + ':' + wizeFiTransactionAttributePattern.attributePattern[attribute];
        firstTime = false;
      }

      // display output
      console.log(
        patternID +
          '  ' +
          Object.keys(wizeFiTransactionAttributePattern.attributePattern).length +
          '  ' +
          wizeFiTransactionAttributePattern.wizeFiCategory +
          '  ' +
          attributePatternInfo
      );
    }
    //
  } // showRuleSequence

  ////////////////////////////////////////////////
  // end rule (attribute pattern) routines
  ////////////////////////////////////////////////

  public assignGenericCategoryToWizeFiAccount(genericCategory, wizeFiCategory) {
    // TODO do validity check on genericCategory
    // TODO fix error upon attempt to use showMessage

    if (genericCategory === 'none' || genericCategory === 'undefined') {
      // this.dataModelService.showMessage("error", "genericCategory (" + genericCategory + ") is not an appropriate value", 10000);
      console.log('error -- genericCategory (' + genericCategory + ') is not an appropriate value');
    } else if (!this.isValidWizeFiCategoryAccount(wizeFiCategory)) {
      // this.dataModelService.showMessage("error -- wizeFiCategory (" + wizeFiCategory + ") does not identify a valid user WizeFi account", 10000);
      console.log('error -- wizeFiCategory (' + wizeFiCategory + ') does not identify a valid user WizeFi account');
    } else {
      const curplan = this.dataModelService.dataModel.persistent.header.curplan;
      const info = this.decodeWizeFiCategory(wizeFiCategory);
      const account = this.dataModelService.dataModel.persistent.plans[curplan][info.category][info.subcategory].accounts[info.acntndx];
      if (account.hasOwnProperty('genericCategory')) {
        account.genericCategory.val = genericCategory;
      }
    }
  } // assignGenericCategoryToWizeFiAccount

  public getWizeFiPlaidAccountAccount_id2wizeFiCategory() {
    const wizeFiPlaidAccountAccountId2wizeFiCategory = {};
    const wizeFiPlaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    if (wizeFiPlaidAccounts) {
      for (const wizeFiPlaidAccount of wizeFiPlaidAccounts) {
        const wizeFiCategory = wizeFiPlaidAccount.wizeFiCategory;
        if (wizeFiCategory !== 'none') {
          wizeFiPlaidAccountAccountId2wizeFiCategory[wizeFiPlaidAccount.account_id] = wizeFiCategory;
        }
      }
    }
    return wizeFiPlaidAccountAccountId2wizeFiCategory;
  } // getWizeFiPlaidAccountAccount_id2wizeFiCategory

  public getWizeFiCategory2amount(monthDate) {
    let wizeFiCategory, amount, info;
    const wizeFiCategory2amount = {};
    if (this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection) {
      const wizeFiPlaidAccountAccountId2wizeFiCategory = this.getWizeFiPlaidAccountAccount_id2wizeFiCategory();
      const transactions = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection[monthDate];
      if (transactions && wizeFiPlaidAccountAccountId2wizeFiCategory) {
        for (const transaction of transactions) {
          // handle wizeFiCategory assigned to this transaction
          wizeFiCategory = transaction.wizeFiCategory;
          amount = transaction.amount;
          info = this.extractWizeFiCategoryNameParts(wizeFiCategory);
          if (info.category === 'assets') {
            amount = -amount;
          }
          if (!wizeFiCategory2amount.hasOwnProperty(wizeFiCategory)) {
            wizeFiCategory2amount[wizeFiCategory] = 0;
          }
          wizeFiCategory2amount[wizeFiCategory] += amount;

          // Following section is commented out to avoid double counting transaction towards certain
          // accounts... For example, a transaction categorized as liabilities_creditCard_debt that came from
          // a credit card account, was presumably already accounted for in the previous section of code, and
          // the following section would double count the transaction...  May need later?
          //
          // handle wizeFiCategory in wizeFiPlaidAccount related to this transaction
          // if (wizeFiPlaidAccountAccount_id2wizeFiCategory.hasOwnProperty(transaction.account_id))
          // {
          //     wizeFiCategory = wizeFiPlaidAccountAccount_id2wizeFiCategory[transaction.account_id];
          //     let amount = transaction.amount;
          //     info = this.extractWizeFiCategoryNameParts(wizeFiCategory);
          //     if (info.category === "assets") {
          //         amount = -amount;
          //     }
          //     if (!wizeFiCategory2amount.hasOwnProperty(wizeFiCategory)) {
          //         wizeFiCategory2amount[wizeFiCategory] = 0;
          //     }
          //     wizeFiCategory2amount[wizeFiCategory] += amount;
          // }
        }
      }
    }

    return wizeFiCategory2amount;
  } // getWizeFiCategory2amount

  public assignActualMonthlyAmount(monthDate) {
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;
    const wizeFiCategory2amount = this.getWizeFiCategory2amount(monthDate);

    for (const category of Object.keys(this.dataModelService.dataModel.persistent.plans[curplan])) {
      if (categoryExcludeList.indexOf(category) === -1) {
        for (const subcategory of Object.keys(this.dataModelService.dataModel.persistent.plans[curplan][category])) {
          if (subcategoryExcludeList.indexOf(subcategory) === -1) {
            for (const account of this.dataModelService.dataModel.persistent.plans[curplan][category][subcategory].accounts) {
              if (account && account.hasOwnProperty('actualMonthlyAmount')) {
                const wizeFiCategory = this.makeWizeFiCategory(category, subcategory, account.accountID.val);
                // console.log(wizeFiCategory2amount[wizeFiCategory])
                if (wizeFiCategory2amount[wizeFiCategory]) {
                  account.actualMonthlyAmount.val = wizeFiCategory2amount[wizeFiCategory];
                } else {
                  account.actualMonthlyAmount.val = 0;
                }
              }
            } // for account
          } // if include subcategory
        } // for subcategory
      } // if include category
    } // for category
  } // assignActualMonthlyAmount

  public getSelectedTransactionList(wizeFiCategory, monthDate) {
    const transactionList = [];
    for (const transaction of this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection[monthDate]) {
      if (transaction.wizeFiCategory === wizeFiCategory) {
        transactionList.push(transaction);
      }
    }
    return transactionList;
  } // getSelectedTransactionList

  public getWizeFiCategoryParts(wizeFiCategory) {
    const parts = wizeFiCategory.split('_');
    const category = parts[0];
    const subcategory = parts.length > 1 ? parts[1] : '';
    const account = parts.length > 2 ? parts[2] : '';
    return { category, subcategory, account };
  } // getWizeFiCategoryParts

  public isValidWizeFiCategory(wizeFiCategory) {
    let isValid = true;
    const info = this.getWizeFiCategoryParts(wizeFiCategory);
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;
    const userPlan = this.dataModelService.dataModel.persistent.plans[curplan];
    // if (wizeFiCategory !== "unknown" && wizeFiCategory !== "none" && wizeFiCategory !== "ignore")
    if (!specialWizefiCategory.includes(wizeFiCategory)) {
      if (!userPlan.hasOwnProperty(info.category)) {
        isValid = false;
      } else if (!userPlan[info.category].hasOwnProperty(info.subcategory)) {
        isValid = false;
      } else {
        // determine type of account designation
        let accountType;
        if (/^ID\d+$/.test(info.account)) {
          accountType = 'accountID';
        } else if (/^\d+$/.test(info.account)) {
          accountType = 'acntndx';
        } else {
          accountType = 'accountName';
        }

        // check whether any account is a match for the accountType value
        let haveMatch = false;
        const accounts = this.dataModelService.dataModel.persistent.plans[curplan][info.category][info.subcategory].accounts;
        for (const account of accounts) {
          switch (accountType) {
            case 'accountID':
              if (account.accountID.val === info.account) {
                haveMatch = true;
              }
              break;
            case 'accountName':
              if (account.accountName.val === info.account) {
                haveMatch = true;
              }
              break;
            case 'acntndx':
              const acntndx = Number(info.account);
              if (acntndx >= 0 && acntndx < accounts.length) {
                haveMatch = true;
              }
              break;
          } // switch
        }
        if (!haveMatch) {
          isValid = false;
        }
      }
    }
    return isValid;
  } // isValidWizeFiCategory

  public isValidWizeFiCategoryAccount(wizeFiCategory) {
    let isValid = true;
    // if (!wizeFiCategory || wizeFiCategory === "unknown" || wizeFiCategory === "none" || wizeFiCategory === "ignore")
    if (!wizeFiCategory || specialWizefiCategory.includes(wizeFiCategory)) {
      isValid = false;
    } else {
      // initialize
      const curplan = this.dataModelService.dataModel.persistent.header.curplan;
      const userPlan = this.dataModelService.dataModel.persistent.plans[curplan];
      const info = this.getWizeFiCategoryParts(wizeFiCategory);

      if (!userPlan.hasOwnProperty(info.category)) {
        isValid = false;
      } else if (!userPlan[info.category].hasOwnProperty(info.subcategory)) {
        isValid = false;
      } else {
        // determine type of account designation
        let accountType;
        if (/^ID\d+$/.test(info.account)) {
          accountType = 'accountID';
        } else if (/^\d+$/.test(info.account)) {
          accountType = 'acntndx';
        } else {
          accountType = 'accountName';
        }

        // check whether any account is a match for the accountType value
        const accounts = this.dataModelService.dataModel.persistent.plans[curplan][info.category][info.subcategory].accounts;
        let haveMatch = false;
        for (const account of accounts) {
          switch (accountType) {
            case 'accountID':
              if (account.accountID.val === info.account) {
                haveMatch = true;
              }
              break;
            case 'accountName':
              if (account.accountName.val === info.account) {
                haveMatch = true;
              }
              break;
            case 'acntndx':
              const acntndx = Number(info.account);
              if (acntndx >= 0 && acntndx < accounts.length) {
                haveMatch = true;
              }
              break;
          } // switch
        }
        if (!haveMatch) {
          isValid = false;
        }
      }
    }
    return isValid;
  } // isValidWizeFiCategoryAccount

  public repairTransactionWizeFiCategoryValues(monthDate) {
    for (const transaction of this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection[monthDate]) {
      if (!this.isValidWizeFiCategory(transaction.wizeFiCategory)) {
        transaction.wizeFiCategory = 'unknown';
      }
    }
  } // repairTransactionWizeFiCategoryValues

  public getPlaidGenericCategory(plaidCategory) {
    // initialize //
    let genericCategory = 'undefined';
    const wizeFiPlaidCategoryMap = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidCategoryMap;

    // obtain genericCategory
    if (wizeFiPlaidCategoryMap.hasOwnProperty(plaidCategory)) {
      genericCategory = wizeFiPlaidCategoryMap[plaidCategory];
    }
    return genericCategory;
  } // getPlaidGenericCategory

  public getWizeFiGenericCategory(wizeFiCategory) {
    let genericCategory = 'undefined';

    if (wizeFiCategory !== 'unknown' && wizeFiCategory !== 'none' && this.dataModelService.categoryManagement.isValidWizeFiCategory(wizeFiCategory)) {
      const curplan = this.dataModelService.dataModel.persistent.header.curplan;
      const info = this.dataModelService.categoryManagement.decodeWizeFiCategory(wizeFiCategory);
      const account = this.dataModelService.dataModel.persistent.plans[curplan][info.category][info.subcategory].accounts[info.acntndx];
      genericCategory = account.genericCategory.val;
    }
    return genericCategory;
  } // getWizeFiGenericCategory

  public getAccount_id2wizeFiCategory() {
    const wizeFiPlaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    const accountId2wizeFiCategory = {};
    for (const wizeFiPlaidAccount of wizeFiPlaidAccounts) {
      accountId2wizeFiCategory[wizeFiPlaidAccount.account_id] = wizeFiPlaidAccount.wizeFiCategory;
    }
    return accountId2wizeFiCategory;
  } // getAccount_id2wizeFiCategory

  //////////////////////////////////////////////////////
  // routines to support transaction split
  //////////////////////////////////////////////////////

  public getInitialSplitTransactionInfo() {
    const splitTransactionInfo = {
      parent: {
        transaction: { merchantName: 'unknown', date: '1900-01-01', amount: 0 }
      },
      children: [
        {
          amount: 0,
          wizeFiCategory: 'unknown'
        },
        {
          amount: 0,
          wizeFiCategory: 'unknown'
        }
      ]
    };
    return splitTransactionInfo;
  } // getInitialSplitTransactionInfo

  public changeChildListLength(desiredChildCount, splitTransactionInfo) {
    while (splitTransactionInfo.children.length > desiredChildCount && desiredChildCount >= 2) {
      splitTransactionInfo.children.pop();
    }
    while (splitTransactionInfo.children.length < desiredChildCount) {
      splitTransactionInfo.children.push({ amount: 0, wizeFiCategory: 'unknown' });
    }
  } // changeChildListLength

  public childAmountsTotal(splitTransactionInfo) {
    let sum = 0;
    for (const child of splitTransactionInfo.children) {
      sum += Number(child.amount);
    }
    return sum;
  } // childAmountsTotal

  public childAmountsDifference(splitTransactionInfo) {
    const sum = this.childAmountsTotal(splitTransactionInfo);
    const difference = splitTransactionInfo.parent.transaction.amount - sum;
    return difference;
  } // childAmountsDifference

  public getFirstChildAmount(splitTransactionInfo) {
    // get sum of other child amounts
    let sum = 0;
    for (let i = 1; i < splitTransactionInfo.children.length; i++) {
      sum = sum + splitTransactionInfo.children[i].amount;
    }

    // compute first child amount
    const firstChildAmount = splitTransactionInfo.parent.transaction.amount - sum;
    return firstChildAmount;
  } // getFirstChildAmount

  public isReadyForCreate(splitTransactionInfo) {
    let isReady = true;
    for (let ndx = 0; ndx < splitTransactionInfo.children.length; ndx++) {
      const child = splitTransactionInfo.children[ndx];
      const amount = ndx > 0 ? child.amount : this.getFirstChildAmount(splitTransactionInfo);
      if (amount < 0.01 || child.wizeFiCategory === 'unknown') {
        isReady = false;
      }
    }
    return isReady;
  } // isReadyForCreate

  public createSplitTransactions(splitTransactionInfo, wizeFiTransactions) {
    const transort = (a, b) => {
      let result = 0;
      if (a.date == b.date) {
        result = a.transaction_id < b.transaction_id ? -1 : 1;
      } else {
        result = a.date < b.date ? -1 : 1;
      }
      return result;
    }; // transort
    console.log('createSplitTransactions -- splitTransactionInfo: ', splitTransactionInfo); // %//

    // initialize
    const parentTransaction = splitTransactionInfo.parent.transaction;

    if (parentTransaction.splitStatus != 0) {
      console.log('neither a parent nor a child transaction can be split'); // %//
      this.dataModelService.showMessage('error', 'neither a parent nor a child transaction can be split', 7000);
    } else {
      // make a list of all split transactions found for the parent transaction
      const ndxList = [];
      for (let ndx = 0; ndx < wizeFiTransactions.length; ndx++) {
        const wizeFiTransaction = wizeFiTransactions[ndx];

        if (wizeFiTransaction.transaction_id.indexOf(parentTransaction.transaction_id) != -1 && wizeFiTransaction.transaction_id.indexOf('_') != -1) {
          ndxList.push(ndx);
        }
      }
      ndxList.sort((a, b) => b - a); // sort into descending order (to later remove things toward the end of the array first)

      // remove any previous split transactions for this parent transaction
      for (const ndx of ndxList) {
        wizeFiTransactions.splice(ndx, 1);
      }

      // add proper values for parent transaction
      const parent = wizeFiTransactions.find(e => e.transaction_id == parentTransaction.transaction_id);
      if (parent) {
        parent.wizeFiCategory = 'none'; // the parent transaction does not store any amount in any WizeFi account
        parent.splitStatus = 1; // this marks this transaction as a parent
      }

      // create each child transaction of the parent
      for (let ndx = 0; ndx < splitTransactionInfo.children.length; ndx++) {
        const child = splitTransactionInfo.children[ndx];

        // build a child transaction
        const childTransaction = JSON.parse(JSON.stringify(parentTransaction)); // clone the parent transaction
        childTransaction.transaction_id = parentTransaction.transaction_id + '_' + (ndx + 1); // add suffix to transaction_id
        childTransaction.amount = child.amount;
        childTransaction.wizeFiCategory = child.wizeFiCategory;
        childTransaction.splitStatus = 2; // this marks this transaction as a child

        // add the new childTransaction to the wizeFiTransactions array
        wizeFiTransactions.push(childTransaction);
      }
      wizeFiTransactions.sort(transort); // %//
      console.log('createSplitTransactions -- wizeFiTransactions: ', wizeFiTransactions); // %//
    }
  } // createSplitTransactions

  public deleteSplitTransaction(splitTransactionInfo, wizeFiTransactions) {
    // initialize
    const parentTransaction = splitTransactionInfo.parent.transaction;

    if (parentTransaction.splitStatus !== 1) {
      console.log('must specify a parent transaction in order to delete a split transaction');
      this.dataModelService.showMessage('error', 'must specify a parent transaction in order to delete a split transaction', 7000);
    } else {
      // make a list of all split transactions found for the parent transaction
      const ndxList = [];
      for (let ndx = 0; ndx < wizeFiTransactions.length; ndx++) {
        const wizeFiTransaction = wizeFiTransactions[ndx];

        if (
          wizeFiTransaction.transaction_id.indexOf(parentTransaction.transaction_id) !== -1 &&
          wizeFiTransaction.transaction_id.indexOf('_') !== -1
        ) {
          ndxList.push(ndx);
        }
      }
      ndxList.sort((a, b) => b - a); // sort into descending order (to later remove things toward the end of the array first)

      // remove any previous split transactions for this parent transaction
      for (const ndx of ndxList) {
        wizeFiTransactions.splice(ndx, 1);
      }

      // add proper values for the former parent transaction
      parentTransaction.wizeFiCategory = 'unknown';
      parentTransaction.splitStatus = 0; // this marks this transaction as neither a parent nor a child transaction
    }
  } // deleteSplitTransaction

  public getWizeFiCategory2wizeFiPlaidAccount() {
    const wizeFiPlaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    const wizeFiCategory2wizeFiPlaidAccount = {};
    if (wizeFiPlaidAccounts) {
      for (const wizeFiPlaidAccount of wizeFiPlaidAccounts) {
        const wizeFiCategory = wizeFiPlaidAccount.wizeFiCategory;
        if (wizeFiCategory !== 'none') {
          wizeFiCategory2wizeFiPlaidAccount[wizeFiCategory] = wizeFiPlaidAccount;
        }
      }
    }
    return wizeFiCategory2wizeFiPlaidAccount;
  } // getWizeFiCategory2wizeFiPlaidAccount

  public getWizeFiCategory2wizeFiTransactionList(yearMonth) {
    let wizeFiTransactions = [];
    if (this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection.hasOwnProperty(yearMonth)) {
      wizeFiTransactions = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection[yearMonth];
    }
    const wizeFiCategory2wizeFiTransactionList = {};
    for (const wizeFiTransaction of wizeFiTransactions) {
      const wizeFiCategory = wizeFiTransaction.wizeFiCategory;
      if (wizeFiCategory != 'none' && wizeFiCategory != 'unknown') {
        if (!wizeFiCategory2wizeFiTransactionList.hasOwnProperty(wizeFiCategory)) {
          wizeFiCategory2wizeFiTransactionList[wizeFiCategory] = [];
        }
        wizeFiCategory2wizeFiTransactionList[wizeFiCategory].push(wizeFiTransaction);
      }
    }
    return wizeFiCategory2wizeFiTransactionList;
  } // getWizeFiCategory2wizeFiTransactionList

  public getSchemaData() {
    const schemaData = {};
    for (const category of Object.keys(categoryInfo)) {
      if (category !== 'assets2') {
        schemaData[category] = [];
        for (const subcategory of Object.keys(categoryInfo[category])) {
          if (['label', 'tooltip'].indexOf(subcategory) === -1) {
            schemaData[category].push(subcategory);
          }
        }
      }
    }
    return schemaData;
  } // getSchemaData

  public createWizeFiAccount(category, subcategory, accountName) {
    // initialize
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;

    // establish new subcategory if necessary
    // console.log("in here!! createwizefiacocunt");
    if (
      this.dataModelService.dataModel.persistent.plans[curplan][category] &&
      !this.dataModelService.dataModel.persistent.plans[curplan][category].hasOwnProperty(subcategory)
    ) {
      this.dataModelService.dataModel.persistent.plans[curplan][category][subcategory] = {};
      this.dataModelService.dataModel.persistent.plans[curplan][category][subcategory].accounts = [];
      this.dataModelService.dataModel.persistent.plans[curplan][category][subcategory].label = categoryInfo[category][subcategory].label;
    }

    // initialize new wizeFiAccount with the default account attributes
    const wizeFiAccount = this.getWizeFiAccountSchema(category, subcategory);
    const newAccountID = this.getNewAccountID(this.dataModelService.dataModel.persistent.plans[curplan][category][subcategory].accounts);
    const wizeFiCategory = this.makeWizeFiCategory(category, subcategory, newAccountID);

    // assign values to some of the account attributes
    wizeFiAccount.accountID.val = newAccountID;
    wizeFiAccount.wizeFiCategory.val = wizeFiCategory;
    wizeFiAccount.accountName.val = accountName;

    // place the new wizeFiAccount into the proper place in the user data model
    // console.log("in here!! push account")
    this.dataModelService.dataModel.persistent.plans[curplan][category][subcategory].accounts.push(wizeFiAccount);

    // TODO figure out when to save the data model to persistent storage in order to retain new information placed into the data model
    return wizeFiAccount;
  } // createWizeFiAccount

  public async processPlaidAccountLink(accountLinkInfo) {
    const setDefaultRules = wizeFiPlaidAccount => {
      const setDefaultRules0 = async wizeFiPlaidAccount => {
        console.log('creating default rules');
        await this.addDefaultRules(wizeFiPlaidAccount);
      };

      setDefaultRules0(wizeFiPlaidAccount).catch(err => {
        console.log('error in setDefaultRules: ', err);
      });
    };

    let wizeFiAccount: any;
    wizeFiAccount = {};

    // remember previous values
    const oldWizeFiCategory = accountLinkInfo.wizeFiPlaidAccount.wizeFiCategory;

    // set new values
    accountLinkInfo.wizeFiPlaidAccount.status = accountLinkInfo.accountStatus;
    accountLinkInfo.wizeFiPlaidAccount.isActive = accountLinkInfo.accountIsActive;

    // TODO convert number to string for accountLinkInfo.accountStatus (review whether number or string is used throughout the app)
    switch (accountLinkInfo.accountStatus) {
      case '0':
        accountLinkInfo.wizeFiPlaidAccount.wizeFiCategory = 'none';
        if (oldWizeFiCategory !== 'none') {
          this.deleteWizeFiAccount(oldWizeFiCategory);
        }
        break;
      case '1':
        if (accountLinkInfo.wizeFiCategory !== 'none') {
          // link to an already existing WizeFi account
          accountLinkInfo.wizeFiPlaidAccount.wizeFiCategory = accountLinkInfo.wizeFiCategory;
          wizeFiAccount = this.getWizeFiAccountFromWizeFiCategory(accountLinkInfo.wizeFiCategory);
        } else {
          // link to a new WizeFi account that is created
          wizeFiAccount = this.createWizeFiAccount(accountLinkInfo.category, accountLinkInfo.subcategory, accountLinkInfo.accountName);
          const newWizeFiCategory = this.dataModelService.categoryManagement.makeWizeFiCategory(
            accountLinkInfo.category,
            accountLinkInfo.subcategory,
            wizeFiAccount.accountID.val
          );
          accountLinkInfo.wizeFiPlaidAccount.wizeFiCategory = newWizeFiCategory;
        }
        break;
      case '2':
        accountLinkInfo.wizeFiPlaidAccount.wizeFiCategory = 'none';
        if (oldWizeFiCategory !== 'none') {
          this.deleteWizeFiAccount(oldWizeFiCategory);
        }
        break;
    } // switch

    // set default rules for the new wizeFiPlaidAccount that has been created
    setDefaultRules(accountLinkInfo.wizeFiPlaidAccount);

    // save changes in wizeFiPlaidAccounts to DynamoDB
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    const title = this.dataModelService.dataManagement.getDraftTitle();
    const plaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    await this.dataModelService.plaidManagement.storePlaidAccounts(wizeFiID, title, plaidAccounts);

    // save changes in user account in the data model to DynamoDB
    await this.dataModelService.dataManagement.storeinfo();

    return wizeFiAccount;
  } // processPlaidAccountLink

  public deleteWizeFiAccount(wizeFiCategory) {
    // initialize
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;
    const info = this.decodeWizeFiCategory(wizeFiCategory);

    // set wizeCategory to "none" for related Plaid account (if any)
    const wizeFiCategory2wizeFiPlaidAccount = this.getWizeFiCategory2wizeFiPlaidAccount();
    if (wizeFiCategory2wizeFiPlaidAccount.hasOwnProperty(wizeFiCategory)) {
      const wizeFiPlaidAccount = wizeFiCategory2wizeFiPlaidAccount[wizeFiCategory];
      wizeFiPlaidAccount.wizeFiCategory = 'none';
    }

    // update information in data model
    this.dataModelService.dataModel.persistent.plans[curplan][info.category][info.subcategory].accounts.splice(info.acntndx, 1);

    // repair any "orphan" wizeFiCategory values created by deleting this account
    this.repairWizeFiCategoryValues();

    // TODO determine whether this should be done here, or in some other point in the app flow (also consider the fact that storedata returns a promise)
    // save data changes to persistent storage (DynamoDB)
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    const title = this.dataModelService.dataManagement.getDraftTitle();
    const plaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    if (plaidAccounts) {
      this.dataModelService.plaidManagement
        .storePlaidAccounts(wizeFiID, title, plaidAccounts)
        .then(() => this.dataModelService.dataManagement.storeinfo())
        .catch(err => {
          console.log('error in deleteWizeFiAccount: ', err);
        });
    }
  } // deleteWizeFiAccount

  public repairWizeFiCategoryValues() {
    // initialize
    let wizeFiTransactions: any;
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;
    const curplanYearMonth =
      curplan !== 'original'
        ? this.dataModelService.setPlanDate(curplan)
        : this.dataModelService.dataModel.persistent.header.origTransactionsYearMonth;
    const wizeFiPlaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    const wizeFiTransactionsCollection = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection;
    wizeFiTransactions = [];
    if (wizeFiTransactionsCollection && wizeFiTransactionsCollection.hasOwnProperty(curplanYearMonth)) {
      wizeFiTransactions = wizeFiTransactionsCollection[curplanYearMonth];
    }

    // repair wizeFiCategory values in wizeFiPlaidAccounts
    if (wizeFiPlaidAccounts) {
      for (const wizeFiPlaidAccount of wizeFiPlaidAccounts) {
        if (!this.isValidWizeFiCategoryAccount(wizeFiPlaidAccount.wizeFiCategory)) {
          wizeFiPlaidAccount.wizeFiCategory = 'none';
        }
      }
    }

    // repair wizeFiCategory values in wizeFiTransactions
    if (wizeFiTransactions) {
      for (const wizeFiTransaction of wizeFiTransactions) {
        const wizeFiCategory = wizeFiTransaction.wizeFiCategory;
        // if (wizeFiCategory !== "unknown" && wizeFiCategory !="none" && wizeFiCategory !== "ignore" && !this.isValidWizeFiCategoryAccount(wizeFiCategory))
        if (!specialWizefiCategory.includes(wizeFiCategory) && !this.isValidWizeFiCategoryAccount(wizeFiCategory)) {
          wizeFiTransaction.wizeFiCategory = 'unknown';
        }
      }

      this.dataModelService.dataManagement
        .putWizeFiTransactions(wizeFiID, curplanYearMonth, wizeFiTransactions)
        .then(() => {
          console.log('WizeFi transaction data has been saved');
        })
        .catch(err => {
          console.log(err);
        });
    }
  } // repairWizeFiCategoryValues

  public obtainPlanDatesInfo() {
    const plans = this.dataModelService.dataModel.persistent.plans;
    let planDatesInfo: any;
    planDatesInfo = {};
    planDatesInfo.memoryList = [];
    planDatesInfo.persistentList = [];

    // populate memory data
    for (const plan of Object.keys(plans).sort()) {
      const planYearMonth = this.dataModelService.setPlanDate(plan);
      planDatesInfo.memoryList.push({ plan, planYearMonth });
    }

    // populate persistent data
    const planList = this.dataModelService.dataModel.global.planList;
    for (const plan of planList) {
      const planYearMonth = this.dataModelService.setPlanDate(plan);
      planDatesInfo.persistentList.push({ plan, planYearMonth });
    }
    return planDatesInfo;
  } // obtainPlanDatesInfo

  public async obtainTransactionDatesInfo() {
    // initialize
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    const wizeFiTransactionsCollection = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection;
    let transactionDatesInfo: any;
    transactionDatesInfo = {};
    transactionDatesInfo.memoryList = [];
    transactionDatesInfo.persistentList = [];

    // populate memory data
    for (const transactionYearMonth of Object.keys(wizeFiTransactionsCollection).sort()) {
      const wizeFiTransactions = wizeFiTransactionsCollection[transactionYearMonth];
      if (wizeFiTransactions.length > 0) {
        transactionDatesInfo.memoryList.push(transactionYearMonth);
      }
    }

    // populate persistent data
    transactionDatesInfo.persistentList = await this.dataModelService.plaidManagement.getWizeFiTransactionsDateList(wizeFiID);

    return transactionDatesInfo;
  } // obtainTransactionDatesInfo

  public getWizeFiAccountSchema(category, subcategory) {
    const accountType = categoryInfo[category][subcategory].accountTypes[0];
    const wizeFiAccountSchema = accountTypes[accountType];
    return _.cloneDeep(wizeFiAccountSchema);
  } // getWizeFiAccountSchema

  public getWizeFiAccountFromWizeFiCategory(wizeFiCategory) {
    let wizeFiAccount: any;
    wizeFiAccount = {};
    if (this.isValidWizeFiCategoryAccount(wizeFiCategory)) {
      const curplan = this.dataModelService.dataModel.persistent.header.curplan;
      const info = this.decodeWizeFiCategory(wizeFiCategory);
      wizeFiAccount = this.dataModelService.dataModel.persistent.plans[curplan][info.category][info.subcategory].accounts[info.acntndx];
    }
    return wizeFiAccount;
  } // getWizeFiAccountFromWizeFiCategory

  public getPlaid_account_id2wizeFiPlaidAccount() {
    const wizeFiPlaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    const plaidAccountId2wizeFiPlaidAccount = {};
    for (const wizeFiPlaidAccount of wizeFiPlaidAccounts) {
      plaidAccountId2wizeFiPlaidAccount[wizeFiPlaidAccount.account_id] = wizeFiPlaidAccount;
    }
    return plaidAccountId2wizeFiPlaidAccount;
  } // plaid_account_id2wizeFiPlaidAccount

  public getWizeFiCategory2wizeFiAccount(plan) {
    const visitWizeFiAccount = (funcPlan, category, subcategory, acntndx, account, funcResult) => {
      const wizeFiCategory = this.makeWizeFiCategory(category, subcategory, account.accountID.val);
      funcResult[wizeFiCategory] = account;
    }; // visitWizeFiAccount

    const result = {};
    this.dataModelService.visitAllWizeFiAccounts(plan, visitWizeFiAccount, result);
    return result;
  } // getWizeFiCategory2wizeFiAccount

  public getPreviousPlan(plan, planDatesInfo) {
    let planLoc, prevPlanLoc;

    planLoc = -1;
    prevPlanLoc = 0;
    while (++planLoc < planDatesInfo.persistentList.length && plan != planDatesInfo.persistentList[planLoc].plan) {}
    if (planLoc > 0) {
      prevPlanLoc = planLoc - 1;
    }
    const previousPlan = planDatesInfo.persistentList[prevPlanLoc].plan;
    return previousPlan;
  } // getPreviousPlan

  public async loadPreviousPlan(previousPlan, planDatesInfo) {
    if (!planDatesInfo.memoryList.find(item => item.plan == previousPlan)) {
      const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
      const planData = await this.dataModelService.dataManagement.getMonthPlan(wizeFiID, previousPlan);
      this.dataModelService.dataModel.persistent.plans[previousPlan] = JSON.parse(planData);
    } else {
      console.log('plan already in memory');
    }
  } // loadPreviousPlan

  public getWizeFiCategory2actualMonthlyAmount(plan) {
    const wizeFiCategory2actualMonthlyAmount = {};

    if (this.dataModelService.dataModel.persistent.plans[plan]) {
      for (const category of Object.keys(this.dataModelService.dataModel.persistent.plans[plan])) {
        if (!categoryExcludeList.includes(category)) {
          for (const subcategory of Object.keys(this.dataModelService.dataModel.persistent.plans[plan][category])) {
            if (!subcategoryExcludeList.includes(subcategory)) {
              for (const account of this.dataModelService.dataModel.persistent.plans[plan][category][subcategory].accounts) {
                // process the current account
                let actualMonthlyAmount = 0;
                if (account.hasOwnProperty('actualMonthlyAmount') && account.actualMonthlyAmount.hasOwnProperty('val')) {
                  actualMonthlyAmount = account.actualMonthlyAmount.val;
                }
                if (account.hasOwnProperty('wizeFiCategory') && account.wizeFiCategory.hasOwnProperty('val')) {
                  wizeFiCategory2actualMonthlyAmount[account.wizeFiCategory.val] = actualMonthlyAmount;
                }
              } // for acntndx
            } // if include subcategory
          } // for subcategory
        } // if include category
      } // for category
    }

    return wizeFiCategory2actualMonthlyAmount;
  } // getWizeFiCategory2actualMonthlyAmount

  public getWizeFiCategory2monthlyAmount(plan) {
    const wizeFiCategory2monthlyAmount = {};

    if (this.dataModelService.dataModel.persistent.plans[plan]) {
      for (const category of Object.keys(this.dataModelService.dataModel.persistent.plans[plan])) {
        if (!categoryExcludeList.includes(category)) {
          for (const subcategory of Object.keys(this.dataModelService.dataModel.persistent.plans[plan][category])) {
            if (!subcategoryExcludeList.includes(subcategory)) {
              for (const account of this.dataModelService.dataModel.persistent.plans[plan][category][subcategory].accounts) {
                // process the current account
                let monthlyAmount = 0;
                if (account.hasOwnProperty('monthlyAmount') && account.monthlyAmount.hasOwnProperty('val')) {
                  monthlyAmount = account.monthlyAmount.val;
                }
                if (account.hasOwnProperty('wizeFiCategory') && account.wizeFiCategory.hasOwnProperty('val')) {
                  wizeFiCategory2monthlyAmount[account.wizeFiCategory.val] = monthlyAmount;
                }
              } // for acntndx
            } // if include subcategory
          } // for subcategory
        } // if include category
      } // for category
    }

    return wizeFiCategory2monthlyAmount;
  } // getWizeFiCategory2actualMonthlyAmount

  public getWizeFiCategory2monthlyMinimumAmount(plan) {
    const WizeFiCategory2monthlyMinimumAmount = {};

    if (this.dataModelService.dataModel.persistent.plans[plan]) {
      for (const category of Object.keys(this.dataModelService.dataModel.persistent.plans[plan])) {
        if (!categoryExcludeList.includes(category)) {
          for (const subcategory of Object.keys(this.dataModelService.dataModel.persistent.plans[plan][category])) {
            if (!subcategoryExcludeList.includes(subcategory)) {
              for (const account of this.dataModelService.dataModel.persistent.plans[plan][category][subcategory].accounts) {
                // process the current account
                let monthlyMinimum = 0;
                if (account.hasOwnProperty('monthlyMinimum') && account.monthlyMinimum.hasOwnProperty('val')) {
                  monthlyMinimum = account.monthlyMinimum.val;
                }
                if (account.hasOwnProperty('wizeFiCategory') && account.wizeFiCategory.hasOwnProperty('val')) {
                  WizeFiCategory2monthlyMinimumAmount[account.wizeFiCategory.val] = monthlyMinimum;
                }
              } // for acntndx
            } // if include subcategory
          } // for subcategory
        } // if include category
      } // for category
    }

    return WizeFiCategory2monthlyMinimumAmount;
  } // getWizeFiCategory2actualMonthlyAmount

  public getActualMonthlyAccount(wizeFiCategory2actualMonthlyAmount, wizeFiCategory) {
    let actualMonthlyAmount = 0;
    if (wizeFiCategory2actualMonthlyAmount.hasOwnProperty(wizeFiCategory)) {
      actualMonthlyAmount = wizeFiCategory2actualMonthlyAmount[wizeFiCategory];
    }
    return actualMonthlyAmount;
  } // getActualMonthlyAccount

  public getMonthlyAccount(wizeFiCategory2monthlyAmount, wizeFiCategory) {
    let monthlyAmount: any = 0;
    if (wizeFiCategory2monthlyAmount.hasOwnProperty(wizeFiCategory)) {
      monthlyAmount = wizeFiCategory2monthlyAmount[wizeFiCategory];
    }
    return monthlyAmount;
  } // getMonthlyAccount

  public getMonthlyMinimumAccount(getWizeFiCategory2monthlyMinimumAmount, wizeFiCategory) {
    let monthlyMinimum = 0;
    if (getWizeFiCategory2monthlyMinimumAmount.hasOwnProperty(wizeFiCategory)) {
      monthlyMinimum = getWizeFiCategory2monthlyMinimumAmount[wizeFiCategory];
    }
    return monthlyMinimum;
  } // getMonthlyAccount
} // CategoryManagement
