import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { WizefiTransaction } from '../../../interfaces/wizefi-transaction';
import {
  IManualTransactionDialogData,
  ManualTransactionDetailsComponent
} from '../../setup-flow/manual-transaction-details/manual-transaction-details.component';
import { DialogDisplayDraftsComponent } from '@app/components/dialog-display-drafts/dialog-display-drafts.component';
import { AccountService } from '@app/services/account/account.service';

@Component({
  selector: 'app-account-transactions',
  templateUrl: './account-transactions.component.html',
  styleUrls: ['./account-transactions.component.scss']
})
export class AccountTransactionsComponent implements OnInit {
  @Input() public selectedAccount: any;
  @Input() public readonly = false;
  public isEditingTransaction = false;
  public isLoading = true;

  public transactionToEdit: WizefiTransaction;
  public transactionsByDate: WizefiTransaction[][]; // array of transaction arrays by date
  @Output() public showAccountModal: EventEmitter<any> = new EventEmitter<any>();
  protected headerMetric: any;
  protected firstMetric: any;
  protected secondMetric: any;
  protected metricPercentage: any;
  protected headerMetricLabel = 'Balance';
  protected firstMetricLabel = 'Credit Available';
  protected secondMetricLabel = 'Credit Limit';

  constructor(
    protected dataModelService: DataModelService,
    protected dialog: MatDialog,
    protected router: Router,
    public accountService: AccountService
  ) {}

  public ngOnInit() {
    this.isLoading = false;
    if (this.router.url.includes('edit-CAFR') || this.router.url.includes('setup')) {
      this.readonly = false;
    }
    if (this.router.url === '/accounts#all') {
      this.readonly = true;
    }
    this.transactionsByDate = [];
    this.setMetrics();
  }

  public editButtonClick() {
    if (this.readonly) {
      this.redirectToEditMode();
    } else {
      this.editAccount();
    }
  }

  protected editAccount() {
    this.showAccountModal.emit();
  }

  protected redirectToEditMode() {
    this.dialog.open(DialogDisplayDraftsComponent, { data: 'accounts' });
  }

  protected getBalance() {
    return this.selectedAccount ? this.selectedAccount.balance : 0;
  }

  protected getLimit() {
    const wizeFiAccount = this.dataModelService.categoryManagement.getWizeFiAccountFromWizeFiCategory(this.selectedAccount.wizeFiCategory);
    return wizeFiAccount && wizeFiAccount.accountLimit ? wizeFiAccount.accountLimit.val : 0;
  }

  protected getAvailable() {
    return this.getLimit() - this.getBalance();
  }

  protected getHeaderLabel() {
    this.setMetrics();
    return this.headerMetricLabel;
  }
  protected getHeaderMetric() {
    this.setMetrics();
    return this.headerMetric;
  }
  protected getFirstMetric() {
    this.setMetrics();
    return this.firstMetric;
  }
  protected getFirstMetricLabel() {
    this.setMetrics();
    return this.firstMetricLabel;
  }
  protected getSecondMetric() {
    this.setMetrics();
    return this.secondMetric;
  }
  protected getSecondMetricLabel() {
    this.setMetrics();
    return this.secondMetricLabel;
  }
  protected getMetricPercentage() {
    return this.metricPercentage;
  }

  protected setMetrics() {
    try {
      if (this.selectedAccount.wizeFiCategory.includes('emergencySavings') || this.selectedAccount.wizeFiCategory.includes('cashReserves')) {
        this.headerMetricLabel = 'Left To Earn';
        this.firstMetricLabel = 'Balance';
        this.secondMetricLabel = 'Target';

        this.headerMetric = this.secondMetric - (this.selectedAccount.balance ? this.selectedAccount.balance : 0);
        this.firstMetric = this.getBalance();
        this.secondMetric = this.selectedAccount.targetAmount ? this.selectedAccount.targetAmount.val : 0;
      } else if (this.selectedAccount.wizeFiCategory.includes('Loan')) {
        this.headerMetricLabel = 'Left To Pay';
        this.firstMetricLabel = 'Paid';
        this.secondMetricLabel = 'Starting Balance';

        this.headerMetric = this.getBalance();
        this.firstMetric = this.getAvailable();
        this.secondMetric = this.getLimit();
      } else if (this.selectedAccount.wizeFiCategory.includes('investments')) {
        this.headerMetricLabel = 'Balance';
        this.firstMetricLabel = null;
        this.secondMetricLabel = null;

        this.headerMetric = this.getBalance();
        this.firstMetric = null;
        this.secondMetric = null;
      } else {
        this.headerMetricLabel = 'Left To Pay';
        this.firstMetricLabel = 'Credit Available';
        this.secondMetricLabel = 'Credit Limit';

        this.headerMetric = this.getBalance();
        this.firstMetric = this.getAvailable();
        this.secondMetric = this.getLimit();
      }
      this.metricPercentage = 0;
      if (this.firstMetric && this.setMetrics) {
        this.metricPercentage = (this.firstMetric / this.secondMetric) * 100;
      }
    } catch (e) {}
  }

  protected getBalancePercentage() {
    if (this.selectedAccount.wizeFiCategory.includes('emergencySavings') || this.selectedAccount.wizeFiCategory.includes('cashReserves')) {
      return this.getBalance() <= 0 ? 0 : (this.getBalance() / this.getSecondMetric()) * 100;
    }
    return this.getBalance() <= 0 ? 0 : (this.getBalance() / this.getLimit()) * 100;
  }

  public setTransactionsByDate(account) {
    if (!account) {
      return;
    }
    const transactions = this.dataModelService.categoryManagement.getSelectedTransactionList(
      account.wizeFiCategory,
      this.dataModelService.dataModel.persistent.header.curplanYearMonth
    );
    this.transactionsByDate = this.mapByDate(transactions);
  }

  private mapByDate(allTransactions: WizefiTransaction[]): WizefiTransaction[][] {
    if (!allTransactions) {
      return [[]];
    }
    const uniqueDates = [...new Set(allTransactions.map(item => item.date))];
    const transactionsByDate = uniqueDates.map(date => allTransactions.filter(transaction => transaction.date === date));
    return transactionsByDate;
  }

  public editTransaction(transaction: WizefiTransaction) {
    this.isEditingTransaction = true;
    this.transactionToEdit = transaction;

    if (this.transactionToEdit.isManual) {
      this.dialog
        .open<ManualTransactionDetailsComponent, IManualTransactionDialogData>(ManualTransactionDetailsComponent, {
          data: { transaction: { ...this.transactionToEdit } }
        })
        .afterClosed()
        .subscribe(result => {
          if (result) {
            this.saveTransaction(this.transactionToEdit);
          } else {
            this.closeModal();
          }
        });
    }
  }

  public saveTransaction(transaction) {
    this.closeModal();
    this.ngOnInit(); // reload side component without the transaction that was just changed
  }

  public closeModal() {
    this.isEditingTransaction = false;
    this.transactionToEdit = undefined;
  }

  private reloadTransactionListItem(category) {
    //this.onRecategorizeTransaction.emit(category)
  }
}
