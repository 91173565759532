import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { categoryInfo } from '../data-model/data-model_0001.data';
import { DataModelService } from '../data-model/data-model.service';

@Injectable({
  providedIn: 'root'
})
export class AccountTypeService {
  private readonly UNKNOWN = 'unknown';
  private readonly NONE = 'none';

  constructor(private dataModelService: DataModelService) {}

  public getSubtypes(type: string): string[] {
    if (!type || type === this.UNKNOWN || type === this.NONE) {
      return [];
    }
    const categoryObj = categoryInfo[type];
    const defaultSubcats = _.values(categoryObj).filter(field => field.accountNames);
    const defaultAccountNames = _.flatten(defaultSubcats.map(subcat => subcat.accountNames));
    return _.uniq(defaultAccountNames);
  }

  public getDividedSubtypes(type: string): IDividedSubtypes {
    const sortFn = (a, b) => (a.includes('Other') ? 1 : b.includes('Other') ? -1 : a > b ? 1 : -1);
    const subtypes = this.getSubtypes(type).sort(sortFn);
    const productiveSubtypes = subtypes
      .filter(s => this.getDefaultProductivity(this.getSubcategoryFromSubtype(type, s)).val === 'Productive')
      .sort(sortFn);
    const limitedSubtypes = subtypes.filter(s => this.getDefaultProductivity(this.getSubcategoryFromSubtype(type, s)).val === 'Limited').sort(sortFn);
    const nonproductiveSubtypes = subtypes
      .filter(s => this.getDefaultProductivity(this.getSubcategoryFromSubtype(type, s)).val === 'Non-productive')
      .sort(sortFn);
    return {
      productiveSubtypes,
      limitedSubtypes,
      nonproductiveSubtypes,
      allSubtypes: subtypes
    };
  }

  public getDefaultProductivity(subtype: string) {
    let defaultProductivity = { label: 'Productivity', isRequired: true, val: 'Productive' };
    // setup logic for defaultProductivity.val being different based on selected subcategory
    if (!subtype || subtype === '') {
      defaultProductivity = { label: 'Productivity', isRequired: true, val: 'Productive' };
    } else {
      switch (subtype) {
        case 'emergencySavings':
        case 'cashReserves':
        case 'checking':
        case 'cash':
        case 'residenceProperty':
        case 'limitedPersonalProperty':
        case 'mortgageLimited':
        case 'otherLimited':
          defaultProductivity = { label: 'Productivity', isRequired: true, val: 'Limited' };
          break;
        case 'personalProperty':
        case 'autoLoan':
        case 'boatLoan':
        case 'creditCard':
        case 'educationalLoan':
        case 'otherNonProductive':
          defaultProductivity = { label: 'Productivity', isRequired: true, val: 'Non-productive' };
          break;
        case 'investments':
        case 'investmentProperty':
        case 'incomeProducingResidenceProperty':
        case 'other':
        default:
          defaultProductivity = { label: 'Productivity', isRequired: true, val: 'Productive' };
      }
    }
    return defaultProductivity;
  }

  public getSubcategoryFromSubtype(type: string, subtype: string): string {
    if (!type || type === this.UNKNOWN || type === this.NONE) {
      return this.UNKNOWN;
    }

    // setup user-specific category to search though
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;
    const userSpecificCategory = this.dataModelService.dataModel.persistent.plans[curplan][type]; // holds user specific account names
    const subcategoryFromUserPlan = this.backCalculateSubcategory(subtype, userSpecificCategory);

    // setup default category to search through
    const defaultCategory = categoryInfo[type]; // holds all default account names

    // if account belongs within a users existing data, return that, otherwise search through defaults
    return subcategoryFromUserPlan !== this.UNKNOWN ? subcategoryFromUserPlan : this.backCalculateSubcategory(subtype, defaultCategory);
  }

  private backCalculateSubcategory(subtype: string, category: object) {
    const possibleSubcats = Object.keys(category)
      .map(attributeName => ({ attributeName, ...category[attributeName] }))
      .filter(field => field.accountNames);

    // find the subcategory that has one of its accountNames matching the input subtype
    const theSubcat = possibleSubcats.find(subcat => subcat.accountNames.some(name => name === subtype));

    return (theSubcat && theSubcat.attributeName) || this.UNKNOWN;
  }
}

export interface IDividedSubtypes {
  productiveSubtypes: string[];
  limitedSubtypes: string[];
  nonproductiveSubtypes: string[];
  allSubtypes: string[];
}
