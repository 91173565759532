import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { ITransactionRule } from '../../interfaces/iTransactionRule.interface';
import { WizefiTransaction } from '../../interfaces/wizefi-transaction';
import { CategoryService } from '../category/category.service';
import { DataModelService } from '../data-model/data-model.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionRuleService {
  private updateTransactions$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor(private dataModelService: DataModelService, private categoryService: CategoryService) {}

  public getTransactionRules(): ITransactionRule[] {
    const rulesMap = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionAttributePatterns;
    if (rulesMap) {
      return Object.keys(rulesMap).map(key => rulesMap[key]);
    } else {
      return [];
    }
  }

  public createTransactionRule(rule: ITransactionRule): Observable<any> {
    const ruleCreationPromise = this.dataModelService.categoryManagement.addAttributePattern(rule);
    return from(ruleCreationPromise);
  }

  public changeTransactionRule(rule: ITransactionRule): Observable<any> {
    const ruleChangePromise = this.dataModelService.categoryManagement.modifyAttributePattern(rule);
    return from(ruleChangePromise);
  }

  public deleteTransactionRule(rule: ITransactionRule): Observable<any> {
    const ruleDeletionPromise = this.dataModelService.categoryManagement.deleteAttributePattern(rule.patternID);
    this.updateTransactions(true);
    return from(ruleDeletionPromise);
  }

  public getRuleTransaction(rule: ITransactionRule): Observable<WizefiTransaction> {
    return of(this.dataModelService.categoryManagement.getRuleTransaction(rule.patternID));
  }

  public getRuleDescription(rule: ITransactionRule): string | undefined {
    let builtRuleText = 'All future transactions ';
    if (rule.attributePattern.merchantName) {
      builtRuleText += `with [${rule.attributePattern.merchantName}] in the merchant name`;
    }
    if (rule.attributePattern.institutionName) {
      if (rule.attributePattern.merchantName) {
        builtRuleText += ', ';
      }
      builtRuleText += `from the [${rule.attributePattern.institutionName}] account`;
    }
    if (rule.attributePattern.amount !== undefined) {
      if (rule.attributePattern.merchantName || rule.attributePattern.institutionName) {
        builtRuleText += ', ';
      }
      builtRuleText += `in the amount of [${rule.attributePattern.amount}]`;
    }

    const type = this.categoryService.getTypeFromDbCategoryFormat(rule.wizeFiCategory);
    const category = this.categoryService.getCategoryFromDbCategoryFormat(rule.wizeFiCategory);
    const subcategory = this.categoryService.getSubcategoryFromDbCategoryFormat(rule.wizeFiCategory);

    if (type && category && subcategory) {
      builtRuleText += `, will be categorized to ${this.categoryService.getTypeLabel(type)}:${this.categoryService.getCategoryLabel(
        type,
        category
      )}:${this.categoryService.getSubcategoryLabel(type, category, subcategory)}.`;
      return builtRuleText;
    } else {
      return undefined;
    }
  }

  public runRulesForMonth(date: string): void {
    console.log('running rules');
    let monthYear;
    if (date) {
      monthYear = date.substring(0, 7);
    } else {
      monthYear = this.dataModelService.dataModel.persistent.header.curplanYearMonth;
    }
    this.dataModelService.categoryManagement.assignWizeFiCategoryFromTransactionPattern(monthYear);
  }

  // this way we can update the object on transaction screen after create or exclude a rule
  public updateTransactions(next) {
    this.updateTransactions$.next(next);
  }

  public getUpdateTransactions(): Observable<boolean> {
    return this.updateTransactions$.asObservable();
  }
}
