// @ts-nocheck
import { Component, OnInit } from '@angular/core';
import { DataModelService } from '../../../services/data-model/data-model.service';

declare const AWS: any;

@Component({
  selector: 'app-admin-manage-screens',
  templateUrl: './admin-manage-screens.component.html',
  styleUrls: ['./admin-manage-screens.component.scss']
})
export class AdminManageScreensComponent implements OnInit {
  public loading: any; // used to control "is working" visual on screen

  public allScreens: string[]; // list of all screens in WizeFi.
  public nonSecureScreens: string[]; // list of all screens that do not require secure access.  Derived from allScreens and secureScreens.
  public originalNonSecureScreens: string[];
  public newUserWizeFiID: string;
  public userIdentity: string;

  public data: any; // all persistent screen interface data -- includes secureScreens and authorizedUsers info.  Comes from DynamoDB.
  public originalData: any; // clone of "data" before interacting with screen

  public selectedNonSecureScreen: string;
  public selectedSecureScreen: string;
  public selectedUserWizeFiID: string;
  public dataIsLoaded: boolean;
  public headerWizeFiID: string;
  public wizeFiIDselectionMode: string;
  public filter: string;
  public filterValue: string;
  public searchUsers: any;
  public searchUser: any;
  protected replacer = (key, value) => (typeof value === 'function' ? value.name : value);

  constructor(public dataModelService: DataModelService) {}

  public ngOnInit() {
    this.loading = {};
    this.loading.isLoading = false;

    this.dataIsLoaded = false;
    this.headerWizeFiID = this.dataModelService.screenDataManagement.headerWizeFiID;

    this.selectedNonSecureScreen = 'select a screen';
    this.selectedSecureScreen = 'select a screen';
    this.selectedUserWizeFiID = 'select a user';
    this.searchUser = 'select a user';

    this.wizeFiIDselectionMode = 'Manual';
    this.filter = 'select a search filter';
    this.filterValue = '';
    this.searchUsers = [];
    this.newUserWizeFiID = '';
    this.userIdentity = 'unknown';

    this.initializeData()
      .then(() => {
        this.dataIsLoaded = true;
      })
      .catch(err => {
        console.log('error in initializeData:', err);
      });
  } // ngOnInit

  public async initializeData() {
    // retrieve data from the DynamoDB ScreenData table for the screen interface
    this.data = await this.dataModelService.screenDataManagement.fetchScreenInterfaceData();

    // set allScreens
    this.allScreens = this.dataModelService.nav.screenList;
    this.allScreens.sort();

    // set nonSecureScreens
    this.nonSecureScreens = JSON.parse(JSON.stringify(this.allScreens)); // clone a copy of all screens
    for (const secureScreen of this.data.headerData.info.secureScreens) {
      const loc = this.nonSecureScreens.indexOf(secureScreen);
      if (loc !== -1) {
        this.nonSecureScreens.splice(loc, 1);
      }
    }
    this.nonSecureScreens.sort();

    // make copy of original data before user screen interactions take place
    this.originalNonSecureScreens = JSON.parse(JSON.stringify(this.nonSecureScreens)); // clone the original data
    this.originalData = JSON.parse(JSON.stringify(this.data)); // clone the original data

    // %//  \/
    console.log('allScreens: ', this.allScreens);
    console.log('nonSecureScreens: ', this.nonSecureScreens);
    console.log('data: ', this.data);
    console.log('originalData: ', this.originalData);
    // %//  /\
  } // initializeData

  public objectKeys(obj) {
    return Object.keys(obj);
  } // objectKeys

  public addSecureScreen() {
    this.data.headerData.info.secureScreens.push(this.selectedNonSecureScreen);

    const loc = this.nonSecureScreens.indexOf(this.selectedNonSecureScreen);
    if (loc !== -1) {
      this.nonSecureScreens.splice(loc, 1);
    }

    this.nonSecureScreens.sort();
    this.data.headerData.info.secureScreens.sort();

    this.selectedNonSecureScreen = 'select a screen';
  } // addSecureScreen

  public removeSecureScreen() {
    this.nonSecureScreens.push(this.selectedSecureScreen);

    const loc = this.data.headerData.info.secureScreens.indexOf(this.selectedSecureScreen);
    if (loc !== -1) {
      this.data.headerData.info.secureScreens.splice(loc, 1);
    }

    this.nonSecureScreens.sort();
    this.data.headerData.info.secureScreens.sort();

    this.selectedSecureScreen = 'select a screen';
  } // removeSecureScreen

  public changeWizeFiIDselectionMode() {
    let radioButton: any;
    radioButton = document.getElementById('manual') as HTMLOptionElement;
    this.wizeFiIDselectionMode = radioButton.checked ? 'Manual' : 'Search';
    this.newUserWizeFiID = '';
    this.userIdentity = 'unknown';
    this.filter = 'select a search filter';
    this.filterValue = '';
    this.searchUsers = [];
  } // changeWizeFiIDselectionMode

  public newUserWizeFiIDChanged() {
    this.userIdentity = 'unknown';
  } // newUserWizeFiIDChanged

  public identifyUser() {
    this.dataModelService.screenDataManagement
      .identifyUser(this.newUserWizeFiID)
      .then(identity => {
        this.userIdentity = identity.toString();
      })
      .catch(err => {
        console.log('error in identifyUser: ', err);
      });
  } // identifyUser

  public filterChanged() {
    // reset values
    this.searchUser = 'select a user';
    this.newUserWizeFiID = '';
    this.userIdentity = 'unknown';
  } // filterChanged

  public filterValueChanged() {
    // reset values
    this.searchUser = 'select a user';
    this.newUserWizeFiID = '';
    this.userIdentity = 'unknown';
  } // filterValueChanged()

  public searchForWizeFiID() {
    const processData = () => {
      if (this.searchUsers.length === 0) {
        this.newUserWizeFiID = '';
        this.userIdentity = 'UNKNOWN';
        console.log('unknown user'); // %//
        this.dataModelService.showMessage('info', 'unknown user'); // %//
      }
      if (this.searchUsers.length === 1) {
        this.newUserWizeFiID = this.searchUsers[0].wizeFiID;
        this.userIdentity = this.searchUsers[0].identity;
      }
      if (this.searchUsers.length > 1) {
        // no special action requires here -- this case is processed elsewhere
      }
    }; // processData

    // reset values
    this.newUserWizeFiID = '';
    this.userIdentity = 'unknown';

    /*
        // no users found
        this.searchUsers = [];
        this.newUserWizeFiID = '';
        this.userIdentity = "UNKNOWN";
        */

    /*
        // one user found
        this.searchUsers = [{wizeFiID:'abcdefg', identity:'John Smith jsmith@gmail.com'}];
        this.newUserWizeFiID = this.searchUsers[0].wizeFiID;
        this.userIdentity = this.searchUsers[0].identity;
        */

    /*
        // multiple users found
        this.searchUsers =
        [
            {wizeFiID:'abcdefg', identity:'John Smith jsmith@gmail.com'},
            {wizeFiID:'bcdefgh', identity:'Mary Smith msmith@gmail.com'},
            {wizeFiID:'cdefghi', identity:'Bill Smith bsmith@gmail.com'}
        ];
        */
    this.loading.isLoading = true;

    this.dataModelService.screenDataManagement
      .fetchAllUserIdentity(this.filter, this.filterValue)
      .then(users => {
        this.searchUsers = users;
      })
      .then(processData)
      .catch(err => {
        console.log('error in searchForWizeFiID: ', err);
      })
      .then(() => {
        this.loading.isLoading = false;
      });
  } // searchForWizeFiID

  public searchUserChanged() {
    if (!this.searchUser.wizeFiID) {
      this.searchUser = 'select a user';
      this.newUserWizeFiID = '';
      this.userIdentity = 'unknown';
    } else {
      this.newUserWizeFiID = this.searchUser.wizeFiID;
      this.userIdentity = this.searchUser.identity;
    }
  } // searchUserChanged

  public addAuthorizedUser() {
    const screenCheck = {};
    for (const secureScreen of this.data.headerData.info.secureScreens) {
      screenCheck[secureScreen] = false;
    }

    this.data.authorizedUsers[this.newUserWizeFiID] = {
      info: {
        identity: this.userIdentity,
        authorizedScreens: []
      },
      screenCheck
    };

    this.searchUser = 'select a user';
    this.newUserWizeFiID = '';
    this.userIdentity = 'unknown';
  } // addAuthorizedUser

  public removeAuthorizedUser(wizeFiID) {
    delete this.data.authorizedUsers[wizeFiID];
    this.selectedUserWizeFiID = 'select a user';
  } // removeAuthorizedUser

  public clearCheckedValues(wizeFiID) {
    for (const secureScreen of Object.keys(this.data.authorizedUsers[wizeFiID].screenCheck)) {
      this.data.authorizedUsers[wizeFiID].screenCheck[secureScreen] = false;
    }
    this.updateAuthorizedScreens(wizeFiID);
  } // clearCheckedValues

  public setCheckedValues(wizeFiID) {
    for (const secureScreen of Object.keys(this.data.authorizedUsers[wizeFiID].screenCheck)) {
      this.data.authorizedUsers[wizeFiID].screenCheck[secureScreen] = true;
    }
    this.updateAuthorizedScreens(wizeFiID);
  } // setCheckedValues

  public restoreCheckedValues(wizeFiID) {
    if (this.originalData.authorizedUsers.hasOwnProperty(wizeFiID)) {
      this.data.authorizedUsers[wizeFiID].screenCheck = JSON.parse(JSON.stringify(this.originalData.authorizedUsers[wizeFiID].screenCheck));
    } else {
      for (const secureScreen of Object.keys(this.data.authorizedUsers[wizeFiID].screenCheck)) {
        this.data.authorizedUsers[wizeFiID].screenCheck[secureScreen] = false;
      }
    }
    this.updateAuthorizedScreens(wizeFiID);
  } // restoreCheckedValues

  public updateAuthorizedScreens(wizeFiID) {
    const authorizedScreens = [];
    for (const secureScreen of this.data.headerData.info.secureScreens) {
      if (this.data.authorizedUsers[wizeFiID].screenCheck[secureScreen]) {
        authorizedScreens.push(secureScreen);
      }
    }
    authorizedScreens.sort();
    this.data.authorizedUsers[wizeFiID].info.authorizedScreens = authorizedScreens;
  } // updateAuthorizedScreens

  public checkBoxChanged(wizeFiID, secureScreen) {
    const loc = this.data.authorizedUsers[wizeFiID].info.authorizedScreens.indexOf(secureScreen);
    if (this.data.authorizedUsers[wizeFiID].screenCheck[secureScreen]) {
      // add secureScreen to authorizedScreens if it is not already present
      if (loc === -1) {
        this.data.authorizedUsers[wizeFiID].info.authorizedScreens.push(secureScreen);
      }
    } else {
      // remove secureScreen from authorizedScreens if it is present
      if (loc !== -1) {
        this.data.authorizedUsers[wizeFiID].info.authorizedScreens.splice(loc, 1);
      }
    }
    this.data.authorizedUsers[wizeFiID].info.authorizedScreens.sort();
  } // checkBoxChanged

  public needStore() {
    return JSON.stringify(this.data) !== JSON.stringify(this.originalData);
  } // needStore

  public restoreAllData() {
    this.nonSecureScreens = JSON.parse(JSON.stringify(this.originalNonSecureScreens)); // create clone of original data to restore original values
    this.data = JSON.parse(JSON.stringify(this.originalData)); // create clone of original data to restore current data to original values
  } // restoreAllData

  public storeChangedData(mode) {
    const storeChangedData0 = async () => {
      let headerChanged, userDataChanged;

      // check whether header has changed
      headerChanged = JSON.stringify(this.data.headerData.info.secureScreens) !== JSON.stringify(this.originalData.headerData.info.secureScreens);
      if (headerChanged) {
        console.log('put wizeFiID: ' + this.headerWizeFiID + '  info: ', this.data.headerData.info);
        if (mode !== 'preview') {
          await this.dataModelService.screenDataManagement.storeScreenData(this.headerWizeFiID, this.data.headerData.info);
        }
      }

      // check for added authorized user
      for (const wizeFiID of Object.keys(this.data.authorizedUsers)) {
        userDataChanged = !this.originalData.authorizedUsers.hasOwnProperty(wizeFiID);
        if (userDataChanged) {
          console.log('put wizeFiID: ' + wizeFiID + '  info: ', this.data.authorizedUsers[wizeFiID].info);
          if (mode !== 'preview') {
            await this.dataModelService.screenDataManagement.storeScreenData(wizeFiID, this.data.authorizedUsers[wizeFiID].info);
          }
        }
      }

      // check for deleted authorized user
      for (const wizeFiID of Object.keys(this.originalData.authorizedUsers)) {
        userDataChanged = !this.data.authorizedUsers.hasOwnProperty(wizeFiID);
        if (userDataChanged) {
          console.log('delete wizeFiID: ' + wizeFiID + '  identity: ' + this.originalData.authorizedUsers[wizeFiID].info.identity);
          if (mode !== 'preview') {
            await this.dataModelService.screenDataManagement.deleteScreenData(wizeFiID);
          }
        }
      }

      // check for each user with changed data
      for (const wizeFiID of Object.keys(this.data.authorizedUsers)) {
        userDataChanged =
          this.data.authorizedUsers.hasOwnProperty(wizeFiID) &&
          this.originalData.authorizedUsers.hasOwnProperty(wizeFiID) &&
          JSON.stringify(this.data.authorizedUsers[wizeFiID].info.authorizedScreens) !==
            JSON.stringify(this.originalData.authorizedUsers[wizeFiID].info.authorizedScreens);

        if (userDataChanged) {
          console.log('put wizeFiID: ' + wizeFiID + '  info: ', this.data.authorizedUsers[wizeFiID].info);
          if (mode !== 'preview') {
            await this.dataModelService.screenDataManagement.storeScreenData(wizeFiID, this.data.authorizedUsers[wizeFiID].info);
          }
        }
      }
    }; // storeChangedData0

    console.log('============ the following data will be changed in DynamoDB ============');

    storeChangedData0()
      .then(() => {
        if (mode !== 'preview') {
          // adjust original data to reflect that data has been stored in DynamoDB
          this.originalNonSecureScreens = JSON.parse(JSON.stringify(this.nonSecureScreens)); // clone the original data
          this.originalData = JSON.parse(JSON.stringify(this.data)); // clone the original data
        }
      })
      .catch(err => {
        console.log('error in storeChangedData: ', err);
      });
  } // storeChangedData
} // AdminManageScreensComponent
