import * as moment from 'moment';
import { IGraphItem } from '../interfaces/iGraphItem.interface';
import { ISortInfo } from '../interfaces/iSortInfo.interface';
import { IYearMonth } from '../interfaces/iYearMonth.interface';
import { DataModelService } from '../services/data-model/data-model.service';
import { skipSubcatAttributes } from '../services/data-model/data-model_0001.data';

export class CafrManagement {
  public cafrInfo: any; // data structure that holds CAFR information
  public needCafrInfoUpdate = true; // flag to suppress automated invocation of getCafrInfo function
  public showDebug = false; // %//
  public showDebug2 = false; // %//  // trace details on handling actualCAFR
  public showDebug3 = false; // %//  // trace details on handling guidelineCAFR in step 4
  public showDebug4 = false; // %//  // trace details behind guidelineCAFR and actualCAFR values
  public showDebug5 = false; // %//  // trace details behind graph projection values
  public showDebug6 = false; // %//  // add CAFR detection info to CAFRscheduleDetails
  public showDebug7 = false; // %//  // add CAFR allocation info to CAFRscheduleDetails

  public privateDataProjectionInfo: any; // cached dataProjectionInfo
  public privateCafrDataGuidelineProjectionInfo: any; // cached Guideline cafrDataProjectionInfo
  public privateCafrDataActualProjectionInfo: any; // cached Actual cafrDataProjectionInfo

  // the following data is gathered when data for plotting graphs is prepared
  public CAFRscheduleDetails: any;
  public CAFRscheduleSummary: any;
  public CAFRcalculationDetails: any;
  public projectionsSummary: any;
  public goalDates: any;

  // before plan edit projection information
  public beforePlanEditCafrDataProjectionInfo: any;
  public dataBeforeEditing: any;

  // capture calculation data for CSV export
  public CAFRprojectionDetails: any;

  // information for labeling schedule data lines
  public monthLabel = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec'
  };

  constructor(public dataModelService: DataModelService) {
    this.CAFRprojectionDetails = [];
    this.initializeScheduleData();

    this.goalDates = {
      guideline: {
        step1: { date: null, target: 0 },
        step2: { date: null, target: 0 },
        step3: { date: null, target: 0 },
        wealthGradeA: {
          date: null,
          target: 0,
          netWorth: 0,
          adjustedNetWorth: 0
        },
        wealthGradeB: {
          date: null,
          target: 0,
          netWorth: 0,
          adjustedNetWorth: 0
        },
        wealthGradeC: {
          date: null,
          target: 0,
          netWorth: 0,
          adjustedNetWorth: 0
        },
        wealthGradeD: {
          date: null,
          target: 0,
          netWorth: 0,
          adjustedNetWorth: 0
        },
        wealthGradeF: {
          date: null,
          target: 0,
          dnetWorth: 0,
          adjustedNetWorth: 0
        },
        accountGoal: {}
      },
      actual: {
        step1: { date: null, target: 0 },
        step2: { date: null, target: 0 },
        step3: { date: null, target: 0 },
        wealthGradeA: {
          date: null,
          target: 0,
          netWorth: 0,
          adjustedNetWorth: 0
        },
        wealthGradeB: {
          date: null,
          target: 0,
          netWorth: 0,
          adjustedNetWorth: 0
        },
        wealthGradeC: {
          date: null,
          target: 0,
          netWorth: 0,
          adjustedNetWorth: 0
        },
        wealthGradeD: {
          date: null,
          target: 0,
          netWorth: 0,
          adjustedNetWorth: 0
        },
        wealthGradeF: {
          date: null,
          target: 0,
          netWorth: 0,
          adjustedNetWorth: 0
        },
        accountGoal: {}
      }
    };
    /*
    Note: accountGoal is an object with a qualifiedAccountName property entry for each nonproductive liability account which has been paid off.
    Each propery has an object value of the following form: {date:DATE}

    Notes:
    1. qualifiedAccountName has fhe following format: <subcat>-<accountName>
    2. date is that point when the liability was paid off (in the form of a JavaScript date object)

    For example:
    accountGoal =
    {
      'creditCard-VISA': {date:DATE},
      'creditCard-MasterCard': {date:DATE},
      'autoLoan-Toyota Loan': {date:DATE}
    }
    */
  } // constructor

  public initializeScheduleData() {
    // detail data for CAFR schedule display
    this.CAFRscheduleDetails = {};
    this.CAFRscheduleDetails.guideline = [];
    this.CAFRscheduleDetails.actual = [];

    // summary data for CAFR schedule display
    this.CAFRscheduleSummary = {};
    this.CAFRscheduleSummary.guideline = [];
    this.CAFRscheduleSummary.actual = [];

    // calculation details for CAFR for CSV export
    this.CAFRcalculationDetails = {};
    this.CAFRcalculationDetails.guideline = [];
    this.CAFRcalculationDetails.actual = [];

    // summary data for assets, liabilities, and net worth schedule display
    this.projectionsSummary = []; // at this time only "actual" (not "guideline" is used)
  } // initializeScheduleData

  public ISO2YYYYMM(ISOdate) {
    return ISOdate.substr(0, 4) + ISOdate.substr(5, 2);
  } // ISO2YYYYMM

  public setNeedCafrInfoUpdate(status: boolean): void {
    this.needCafrInfoUpdate = status;
  } // setNeedCafrInfoUpdate

  /**************************************************************************************
  // This section deals with guidelineCAFR and actualCAFR for the plan screen
  **************************************************************************************/

  public processRequiredCAFR(
    method: string,
    curYYYYMM: string,
    result: any,
    category: any,
    step: number,
    wantNonProductiveDebt: boolean = null
  ): void {
    let monthlyMinimum, monthlyAmount, targetAmount: number;
    let valG, accountValueG, remainingAmountG, leftOverAmountG: number;
    let valA, accountValueA, remainingAmountA, leftOverAmountA: number;
    let accountName;
    let wantSubcat, isNonProductiveDebt: boolean;
    let haveMonthlyAmount, haveMonthlyMinimum, haveBudgetSubcategory: boolean;

    for (const subcat of Object.keys(category)) {
      wantSubcat =
        (step === 1 && subcat === 'emergencySavings') ||
        step === 2 ||
        (step === 3 && subcat === 'cashReserves') ||
        (step === 4 && subcat !== 'emergencySavings' && subcat !== 'cashReserves');
      if (skipSubcatAttributes.indexOf(subcat) === -1 && wantSubcat) {
        if (!result[step].hasOwnProperty(category.attributeName)) {
          result[step][category.attributeName] = {};
        }
        if (!result[step][category.attributeName].hasOwnProperty(subcat)) {
          result[step][category.attributeName][subcat] = {
            guidelineCAFR: 0,
            actualCAFR: 0,
            accounts: {}
          };
        }

        for (const account of category[subcat].accounts) {
          isNonProductiveDebt = account.productivity && account.productivity.val === 'Non-productive';
          if (wantNonProductiveDebt === null || isNonProductiveDebt === wantNonProductiveDebt) {
            accountName = account.accountName.val;
            if (!result[step][category.attributeName][subcat].accounts.hasOwnProperty(accountName)) {
              result[step][category.attributeName][subcat].accounts[accountName] = { guidelineCAFR: 0, actualCAFR: 0 };
            }
            haveMonthlyAmount = account.hasOwnProperty('monthlyAmount');
            monthlyAmount = haveMonthlyAmount ? account.monthlyAmount.val : 0;

            haveMonthlyMinimum = account.hasOwnProperty('monthlyMinimum');
            monthlyMinimum = haveMonthlyMinimum ? account.monthlyMinimum.val : 0;
            accountValueG = 0;
            accountValueA = 0;
            if (account.hasOwnProperty('accountValue')) {
              accountValueG = account.accountValue.val;
              accountValueA = account.accountValue.val;
            }

            // get required data for CAFR allocation
            targetAmount = account.targetAmount ? account.targetAmount.val : undefined;
            if (category.attributeName === 'liabilities') {
              targetAmount = 0;
            }

            haveBudgetSubcategory = false;
            if (account.hasOwnProperty('budgetSubcategory')) {
              haveBudgetSubcategory = account.budgetSubcategory.val !== '';
            }

            // allocate guidelineCAFR
            valG = haveMonthlyMinimum && !haveBudgetSubcategory ? monthlyMinimum : 0;

            leftOverAmountG = 0;
            // roll over once savings target is met
            if (subcat === 'emergencySavings' || subcat === 'cashReserves') {
              const newAccountValueG = accountValueG + valG;
              if (newAccountValueG > targetAmount) {
                leftOverAmountG = newAccountValueG - targetAmount;
                valG = targetAmount - accountValueG;
              } else {
                remainingAmountG = targetAmount - accountValueG;
                if (remainingAmountG < valG) {
                  valG = remainingAmountG;
                  leftOverAmountG = valG - remainingAmountG;
                }
              }
            } else if (category.attributeName === 'liabilities') {
              const newAccountValueG = accountValueG - valG;
              if (newAccountValueG <= 0) {
                leftOverAmountG = valG - accountValueG;
                valG = accountValueG;
              }
            }
            result[step][category.attributeName][subcat].accounts[accountName].guidelineCAFR = valG;
            result.remainingGuidelineCAFR -= valG;

            // allocate actualCAFR
            if ((haveMonthlyAmount || haveMonthlyMinimum) && !haveBudgetSubcategory) {
              // handle non-shadow account
              valA = haveMonthlyAmount ? monthlyAmount : monthlyMinimum;
            } else {
              // handle shadow account
              if (haveMonthlyAmount) {
                valA = monthlyAmount - monthlyMinimum;
              } else {
                valA = 0;
              }
            }

            leftOverAmountA = 0;
            // roll over once savings target is met
            if (subcat === 'emergencySavings' || subcat === 'cashReserves') {
              // NOTE: Allow interest to grow account value in excess of target without triggering rollover detection
              // if valA>0 (i.e. monthly minimum or monthly amount is still set) assumes target not met and check for rollover
              if (valA > 0) {
                const newAccountValueA = accountValueA + valA;
                if (newAccountValueA > targetAmount) {
                  leftOverAmountA = newAccountValueA - targetAmount;
                  valA = targetAmount - accountValueA;
                } else {
                  remainingAmountA = targetAmount - accountValueA;
                  if (remainingAmountA < valA) {
                    valA = remainingAmountA;
                    leftOverAmountA = valA - remainingAmountA;
                  }
                }
              }
            } else if (category.attributeName === 'liabilities') {
              const newAccountValueA = accountValueA - valA;
              if (newAccountValueA <= 0) {
                leftOverAmountA = valA - accountValueA;
                valA = accountValueA;
              }
            }
            result[step][category.attributeName][subcat].accounts[accountName].actualCAFR = valA;
            result.remainingActualCAFR -= valA;

            // %//   \/
            if (this.showDebug6) {
              // update CAFR schedule information
              const d = isNonProductiveDebt ? 'Y' : 'N';

              if (method === 'guideline') {
                this.CAFRscheduleDetails.guideline.push({
                  curYYYYMM,
                  step,
                  CAFRamount: valG,
                  accountValue: accountValueG,
                  accountName: category.attributeName + '-' + accountName + '-' + d + '.guideline.processRequiredCAFR'
                });
              } else {
                this.CAFRscheduleDetails.actual.push({
                  curYYYYMM,
                  step,
                  CAFRamount: valA,
                  accountValue: accountValueA,
                  accountName: category.attributeName + '-' + accountName + '-' + d + '.actual.processMinimumCAFR'
                });
              }
            }
            // %//   /\

            // update CAFRcalculationDetails
            if (method === 'guideline') {
              this.CAFRcalculationDetails.guideline.push({
                curYYYYMM,
                step: '0-' + step,
                monthlyMinimum,
                monthlyAmount,
                haveBudgetSubcategory,
                CAFRamount: valG,
                accountValue: accountValueG,
                accountName: category.attributeName + '-' + accountName + '.guideline.processRequiredCAFR'
              });
            } else {
              this.CAFRcalculationDetails.actual.push({
                curYYYYMM,
                step: '0-' + step,
                monthlyMinimum,
                monthlyAmount,
                haveBudgetSubcategory,
                CAFRamount: valA,
                accountValue: accountValueA,
                accountName: category.attributeName + '-' + accountName + '.actual.processMinimumCAFR'
              });
            }

            // subcategory totals
            result[step][category.attributeName][subcat].guidelineCAFR += valG;
            result[step][category.attributeName][subcat].actualCAFR += valA;

            // category totals
            result[step][category.attributeName].guidelineCAFR += valG;
            result[step][category.attributeName].actualCAFR += valA;

            // step totals
            result[step].guidelineCAFR += valG;
            result[step].actualCAFR += valA;

            // %//  \/
            if (this.showDebug4) {
              console.log(
                step +
                  ',' +
                  category.attributeName +
                  ',' +
                  subcat +
                  ',' +
                  accountName +
                  '  valG: ' +
                  valG +
                  '  valA: ' +
                  valA +
                  '  mth: ' +
                  monthlyAmount +
                  '  min: ' +
                  monthlyMinimum +
                  '  shadow: ' +
                  haveBudgetSubcategory +
                  '  rgCAFR: ' +
                  result.remainingGuidelineCAFR +
                  '  raCAFR: ' +
                  result.remainingActualCAFR
              );
            }
            // %//  /\
          } // if
        } // for actndx
      } // if
    } // for subcat
  } // processRequiredCAFR

  public processSavingsAssets(method: string, curYYYYMM: string, result: any, assets: any, step: number, subcategory: string) {
    let accountName: string;
    let monthlyAmount, monthlyMinimum: number;
    let haveBudgetSubcategory, haveMonthlyAmount, haveMonthlyMinimum: boolean;
    let valA, valG: number;
    let CAFRamountA, accountValueA, remainingAmountA, currentAmountA, currentAmountG: number;
    let CAFRamountG, accountValueG, remainingAmountG: number;

    currentAmountA = this.dataModelService.getSubcategorySum(assets, subcategory, 'accountValue');
    currentAmountG = this.dataModelService.getSubcategorySum(assets, subcategory, 'accountValue');

    for (const subcat of Object.keys(assets)) {
      if (subcat === subcategory) {
        if (!result[step].assets.hasOwnProperty(subcat)) {
          result[step].assets[subcat] = {
            guidelineCAFR: 0,
            actualCAFR: 0,
            accounts: {}
          };
        }
        for (const account of assets[subcat].accounts) {
          // gather values necessary to do work
          accountName = account.accountName.val;
          if (!result[step].assets[subcat].accounts.hasOwnProperty(accountName)) {
            result[step].assets[subcat].accounts[accountName] = {
              guidelineCAFR: 0,
              actualCAFR: 0
            };
          }
          accountValueA = 0;
          accountValueG = 0;
          if (account.hasOwnProperty('accountValue')) {
            accountValueA = account.accountValue.val;
            accountValueG = account.accountValue.val;
          }
          haveBudgetSubcategory = false;
          if (account.hasOwnProperty('budgetSubcategory')) {
            haveBudgetSubcategory = account.budgetSubcategory.val !== '';
          }
          monthlyAmount = 0;
          haveMonthlyAmount = false;
          if (account.hasOwnProperty('monthlyAmount')) {
            monthlyAmount = account.monthlyAmount.val;
            haveMonthlyAmount = true;
          }
          monthlyMinimum = 0;
          haveMonthlyMinimum = false;
          if (account.hasOwnProperty('monthlyMinimum')) {
            monthlyMinimum = account.monthlyMinimum.val;
            haveMonthlyMinimum = true;
          }

          const targetAmount = account.targetAmount ? account.targetAmount.val : undefined;

          // allocate actualCAFR
          if (!haveBudgetSubcategory) {
            // handle non-shadow account
            if (haveMonthlyAmount) {
              accountValueA += monthlyAmount;
              currentAmountA += monthlyAmount;
            } else {
              accountValueA += monthlyMinimum;
              currentAmountA += monthlyMinimum;
            }
            accountValueG += monthlyMinimum;
          } else {
            // handle shadow account
            if (haveMonthlyAmount) {
              accountValueA += monthlyAmount - monthlyMinimum;
              currentAmountA += monthlyAmount - monthlyMinimum;
            }
          }

          // allocate guidelineCAFR
          CAFRamountG = 0;
          if (accountValueG >= 0 && result.remainingGuidelineCAFR > 0 && currentAmountG < targetAmount) {
            remainingAmountG = targetAmount - currentAmountG;
            CAFRamountG = remainingAmountG < result.remainingGuidelineCAFR ? remainingAmountG : result.remainingGuidelineCAFR;
          }
          valG = CAFRamountG;

          result[step].assets[subcat].accounts[accountName].guidelineCAFR += valG;
          result.remainingGuidelineCAFR -= valG;

          // allocate actualCAFR
          CAFRamountA = 0;
          if (accountValueA >= 0 && result.remainingActualCAFR > 0 && currentAmountA < targetAmount) {
            remainingAmountA = targetAmount - currentAmountA;
            CAFRamountA = remainingAmountA < result.remainingActualCAFR ? remainingAmountA : result.remainingActualCAFR;
          }
          valA = CAFRamountA;

          result[step].assets[subcat].accounts[accountName].actualCAFR += valA;
          result.remainingActualCAFR -= valA;

          // %//   \/
          if (this.showDebug6) {
            // update CAFR schedule information
            if (method === 'guideline') {
              this.CAFRscheduleDetails.guideline.push({
                curYYYYMM,
                step,
                CAFRamount: valG,
                accountValue: accountValueG,
                accountName: 'assets-' + accountName + '-processSavingsAssets'
              });
            } else {
              this.CAFRscheduleDetails.actual.push({
                curYYYYMM,
                step,
                CAFRamount: valA,
                accountValue: accountValueA,
                accountName: 'assets-' + accountName + '-processSavingsAssets'
              });
            }
          }
          // %//   /\

          // update CAFRcalculationDetails
          if (method === 'guideline') {
            this.CAFRcalculationDetails.guideline.push({
              curYYYYMM,
              step,
              monthlyMinimum,
              monthlyAmount,
              haveBudgetSubcategory,
              CAFRamount: valG,
              accountValue: accountValueG,
              accountName: 'assets-' + accountName + '-processSavingsAssets'
            });
          } else {
            this.CAFRcalculationDetails.actual.push({
              curYYYYMM,
              step,
              monthlyMinimum,
              monthlyAmount,
              haveBudgetSubcategory,
              CAFRamount: valA,
              accountValue: accountValueA,
              accountName: 'assets-' + accountName + '-processSavingsAssets'
            });
          }

          // subcategory totals
          result[step].assets[subcat].guidelineCAFR += valG;
          result[step].assets[subcat].actualCAFR += valA;

          // category totals
          result[step].assets.guidelineCAFR += valG;
          result[step].assets.actualCAFR += valA;

          // step totals
          result[step].guidelineCAFR += valG;
          result[step].actualCAFR += valA;

          // %//   \/
          if (this.showDebug2) {
            console.log(step + ',assets,' + subcat + ',' + accountName + ': guidelineCAFR: ' + valG);
            console.log(step + ',assets,' + subcat + ',' + accountName + ': actualCAFR: ' + valA);
          }
          // %//   /\
          // %//  \/
          if (this.showDebug4) {
            console.log(
              step +
                ',assets,' +
                subcat +
                ',' +
                accountName +
                '  guidelineCAFR: ' +
                valG +
                '  actVal: ' +
                accountValueG +
                '  rgCAFR: ' +
                result.remainingGuidelineCAFR
            );
            console.log(
              step +
                ',assets,' +
                subcat +
                ',' +
                accountName +
                '  actualCAFR: ' +
                valA +
                '  actVal: ' +
                accountValueA +
                '  raCAFR: ' +
                result.remainingActualCAFR
            );
          }
          // %//  /\
        } // for actndx
      } // if
    } // for subcat
  } // processSavingsAssets

  public compareAccountValue(item1, item2) {
    return item1.accountValue - item2.accountValue;
  } // compareAccountValue

  public buildSortItemList(liabilities: any): ISortInfo[] {
    let itemList: ISortInfo[];
    let item: ISortInfo;
    let accountName: string;
    let haveBudgetSubcategory, haveMonthlyAmount, haveMonthlyMinimum: boolean;
    let accountValue, monthlyAmount, monthlyMinimum: number;

    itemList = [];
    for (const subcat of Object.keys(liabilities)) {
      if (skipSubcatAttributes.indexOf(subcat) === -1) {
        for (let actndx = 0; actndx < liabilities[subcat].accounts.length; actndx++) {
          // obtain values that will be needed
          accountName = 'unknown';
          if (liabilities[subcat].accounts[actndx].productivity && liabilities[subcat].accounts[actndx].productivity.val === 'Non-productive') {
            accountName = liabilities[subcat].accounts[actndx].accountName.val;
          }
          accountValue = 0;
          if (liabilities[subcat].accounts[actndx].hasOwnProperty('accountValue')) {
            accountValue = liabilities[subcat].accounts[actndx].accountValue.val;
          }
          haveBudgetSubcategory = false;
          if (liabilities[subcat].accounts[actndx].hasOwnProperty('budgetSubcategory')) {
            haveBudgetSubcategory = liabilities[subcat].accounts[actndx].budgetSubcategory.val !== '';
          }
          monthlyAmount = 0;
          haveMonthlyAmount = false;
          if (liabilities[subcat].accounts[actndx].hasOwnProperty('monthlyAmount')) {
            monthlyAmount = liabilities[subcat].accounts[actndx].monthlyAmount.val;
            haveMonthlyAmount = true;
          }
          monthlyMinimum = 0;
          haveMonthlyMinimum = false;
          if (liabilities[subcat].accounts[actndx].hasOwnProperty('monthlyMinimum')) {
            monthlyMinimum = liabilities[subcat].accounts[actndx].monthlyMinimum.val;
            haveMonthlyMinimum = true;
          }

          // add item to list
          if (liabilities[subcat].accounts[actndx].productivity && liabilities[subcat].accounts[actndx].productivity.val === 'Non-productive') {
            item = {
              subcat,
              actndx,
              accountName,
              accountValue,
              haveBudgetSubcategory,
              haveMonthlyAmount,
              monthlyAmount,
              haveMonthlyMinimum,
              monthlyMinimum
            };
            itemList.push(item);
          }
        } // for actndx
      } // if
    } // for subcat

    // sort in ascending order by accountValue
    itemList.sort(this.compareAccountValue);

    return itemList;
  } // buildSortItemList

  public processNonProductiveDebt(method: string, curYYYYMM: string, result: any, liabilities: any, step: number) {
    let CAFRamount: number;
    let itemList: ISortInfo[];
    let vala, valg: number;
    let accountValueA, accountValueG: number;

    // construct list to guide sequence in which to process accounts
    itemList = this.buildSortItemList(liabilities);

    // plant itemList for subsequent processing of nonproductive liablilty accounts in ascending sorted order by account value
    result[step].sortedLiabilitiesList = itemList;

    // iterate through accounts from smallest to largest account value, independent of the subcategory an account is in
    for (const item of itemList) {
      // guarantee that data structure has a place to store the desired values
      if (!result[step].liabilities.hasOwnProperty(item.subcat)) {
        result[step].liabilities[item.subcat] = {
          guidelineCAFR: 0,
          actualCAFR: 0,
          accounts: {}
        };
      }
      if (!result[step].liabilities[item.subcat].accounts.hasOwnProperty(item.accountName)) {
        result[step].liabilities[item.subcat].accounts[item.accountName] = {
          guidelineCAFR: 0,
          actualCAFR: 0
        };
      }

      accountValueA = item.accountValue;
      accountValueG = item.accountValue;

      // allocate actualCAFR
      if (!item.haveBudgetSubcategory) {
        // handle non-shadow account
        if (item.haveMonthlyAmount) {
          accountValueA -= item.monthlyAmount;
        } else {
          accountValueA -= item.monthlyMinimum;
        }
        accountValueG -= item.monthlyMinimum;
      } else {
        // handle shadow account
        if (item.haveMonthlyAmount) {
          accountValueA -= item.monthlyAmount - item.monthlyMinimum;
        }
      }

      // allocate guidelineCAFR
      CAFRamount = 0;
      if (accountValueG > 0 && result.remainingGuidelineCAFR > 0) {
        CAFRamount = accountValueG < result.remainingGuidelineCAFR ? accountValueG : result.remainingGuidelineCAFR;
      }
      valg = CAFRamount;

      result[step].liabilities[item.subcat].accounts[item.accountName].guidelineCAFR += valg;
      result.remainingGuidelineCAFR -= valg;

      CAFRamount = 0;
      if (accountValueA > 0 && result.remainingActualCAFR > 0) {
        CAFRamount = accountValueA < result.remainingActualCAFR ? accountValueA : result.remainingActualCAFR;
      }
      vala = CAFRamount;

      result[step].liabilities[item.subcat].accounts[item.accountName].actualCAFR += vala;
      result.remainingActualCAFR -= vala;

      // %//   \/
      if (this.showDebug6) {
        // update CAFR schedule information
        if (method === 'guideline') {
          this.CAFRscheduleDetails.guideline.push({
            curYYYYMM,
            step,
            CAFRamount: valg,
            accountValue: item.accountValue,
            accountName: 'liabilities-' + item.accountName + '-processNonProductiveDebt'
          });
        } else {
          this.CAFRscheduleDetails.actual.push({
            curYYYYMM,
            step,
            CAFRamount: vala,
            accountValue: item.accountValue,
            accountName: 'liabilities-' + item.accountName + '-processNonProductiveDebt'
          });
        }
      }
      // %//   /\

      // update CAFRcalculationDetails
      if (method === 'guideline') {
        this.CAFRcalculationDetails.guideline.push({
          curYYYYMM,
          step,
          monthlyMinimum: item.monthlyMinimum,
          monthlyAmount: item.monthlyAmount,
          haveBudgetSubcategory: item.haveBudgetSubcategory,
          CAFRamount: valg,
          accountValue: item.accountValue,
          accountName: 'liabilities-' + item.accountName + '-processNonProductiveDebt'
        });
      } else {
        this.CAFRcalculationDetails.actual.push({
          curYYYYMM,
          step,
          monthlyMinimum: item.monthlyMinimum,
          monthlyAmount: item.monthlyAmount,
          haveBudgetSubcategory: item.haveBudgetSubcategory,
          CAFRamount: vala,
          accountValue: item.accountValue,
          accountName: 'liabilities-' + item.accountName + '-processNonProductiveDebt'
        });
      }

      // subcategory totals
      result[step].liabilities[item.subcat].guidelineCAFR += valg;
      result[step].liabilities[item.subcat].actualCAFR += vala;

      // category totals
      result[step].liabilities.guidelineCAFR += valg;
      result[step].liabilities.actualCAFR += vala;

      // step totals
      result[step].guidelineCAFR += valg;
      result[step].actualCAFR += vala;

      // %//  \/
      if (this.showDebug4) {
        console.log(
          step +
            ',liabilities,' +
            item.subcat +
            ',' +
            item.accountName +
            '  guidelineCAFR: ' +
            valg +
            '  actVal: ' +
            item.accountValue +
            '  rgCAFR: ' +
            result.remainingGuidelineCAFR
        );
        console.log(
          step +
            ',liabilities,' +
            item.subcat +
            ',' +
            item.accountName +
            '  actualCAFR: ' +
            vala +
            '  actVal: ' +
            item.accountValue +
            '  raCAFR: ' +
            result.remainingActualCAFR
        );
      }
      // %//  /\
    } // for item
  } // processNonProductiveDebt

  public getCafrInfo(
    method: string,
    curYYYYMM: string,
    income: any,
    budget: any,
    assets: any,
    assetProtection: any,
    liabilities: any,
    calcUnusedCAFR: boolean = false,
    unusedCAFR: number = 0,
    extraGuideLineCAFR: any
  ): any {
    let totalIncome, totalBudget: number;
    let result: any;
    let item: any; // %//

    // initalize data structure
    result = {};
    result[1] = {};
    result[1].guidelineCAFR = 0;
    result[1].actualCAFR = 0;
    result[1].assets = {};
    result[1].assets.guidelineCAFR = 0;
    result[1].assets.actualCAFR = 0;

    result[2] = {};
    result[2].guidelineCAFR = 0;
    result[2].actualCAFR = 0;
    result[2].liabilities = {};
    result[2].liabilities.guidelineCAFR = 0;
    result[2].liabilities.actualCAFR = 0;

    result[3] = {};
    result[3].guidelineCAFR = 0;
    result[3].actualCAFR = 0;
    result[3].assets = {};
    result[3].assets.guidelineCAFR = 0;
    result[3].assets.actualCAFR = 0;

    result[4] = {};
    result[4].guidelineCAFR = 0;
    result[4].actualCAFR = 0;
    result[4].assets = {};
    result[4].assets.guidelineCAFR = 0;
    result[4].assets.actualCAFR = 0;
    result[4].assetProtection = {};
    result[4].assetProtection.guidelineCAFR = 0;
    result[4].assetProtection.actualCAFR = 0;
    result[4].liabilities = {};
    result[4].liabilities.guidelineCAFR = 0;
    result[4].liabilities.actualCAFR = 0;

    totalIncome = this.dataModelService.getCategorySum(income, 'monthlyAmount', assets, liabilities, assetProtection);
    totalBudget = this.dataModelService.getCategorySum(budget, 'monthlyAmount', assets, liabilities, assetProtection);

    result.availableGuidelineCAFR = 0.2 * totalIncome + extraGuideLineCAFR.extra;
    result.availableActualCAFR = totalIncome - totalBudget - unusedCAFR;

    result.remainingGuidelineCAFR = result.availableGuidelineCAFR;
    result.remainingActualCAFR = result.availableActualCAFR;

    result.remainingGuidelineCAFRAfterMinimums = 0; // to be filled in later
    result.remainingActualCAFRAfterMinimums = 0; // to be filled in later

    //////////////////////////////////////////////////////////////////////
    // do initial CAFR allocation to handle required monthly amounts
    //////////////////////////////////////////////////////////////////////

    // %//  \/
    if (this.showDebug6 || this.showDebug7) {
      // update CAFR schedule information
      if (method === 'guideline') {
        item = {
          curYYYYMM,
          step: -100,
          CAFRamount: result.availableGuidelineCAFR,
          accountValue: 0,
          accountName: 'availableGuidelineCAFR-getCafrInfo'
        };
        this.CAFRscheduleDetails.guideline.push(item);
      } else {
        item = {
          curYYYYMM,
          step: -100,
          CAFRamount: result.remainingActualCAFR,
          accountValue: 0,
          accountName: 'availableActualCAFR-getCafrInfo'
        };
        this.CAFRscheduleDetails.actual.push(item);
      }
    }
    // %//  /\

    // update CAFRcalculationDetails
    if (method === 'guideline') {
      this.CAFRcalculationDetails.guideline.push({
        curYYYYMM,
        step: -100,
        monthlyMinimum: 0,
        monthlyAmount: 0,
        haveBudgetSubcategory: 'x',
        CAFRamount: result.availableGuidelineCAFR,
        accountValue: 0,
        accountName: 'availableGuidelineCAFR-getCafrInfo'
      });
    } else {
      this.CAFRcalculationDetails.actual.push({
        curYYYYMM,
        step: -100,
        monthlyMinimum: 0,
        monthlyAmount: 0,
        haveBudgetSubcategory: 'x',
        CAFRamount: result.remainingActualCAFR,
        accountValue: 0,
        accountName: 'availableActualCAFR-getCafrInfo'
      });
    }

    /*
    //%//  \/
    if (this.showDebug4)
    {
      console.log(' ');  console.log('start processRequiredCAFR');
    }
    //%//  /\
    */

    // console.log('raCAFR: ' + result.remainingActualCAFR + '  step1 gCAFR: ' + result[1].guidelineCAFR + '  step1 aCAFR: ' + result[1].actualCAFR);
    // allocate guidelineCAFR to cover monthlyMinimum amounts from assets, step 1
    if (this.showDebug4) {
      console.log(
        'before assets,1  rgCAFR: ' +
          result.remainingGuidelineCAFR +
          '  raCAFR: ' +
          result.remainingActualCAFR +
          '  step1 gCAFR: ' +
          result[1].guidelineCAFR +
          '  step1 aCAFR: ' +
          result[1].actualCAFR
      );
    } // %//
    this.processRequiredCAFR(method, curYYYYMM, result, assets, 1);
    if (this.showDebug4) {
      console.log(
        'after  assets,1  rgCAFR: ' +
          result.remainingGuidelineCAFR +
          '  raCAFR: ' +
          result.remainingActualCAFR +
          '  step1 gCAFR: ' +
          result[1].guidelineCAFR +
          '  step1 aCAFR: ' +
          result[1].actualCAFR
      );
    } // %//
    // console.log('raCAFR: ' + result.remainingActualCAFR + '  step1 gCAFR: ' + result[1].guidelineCAFR + '  step1 aCAFR: ' + result[1].actualCAFR);

    // allocate guidelineCAFR to cover monthlyMinimum amounts from liabilities (non productive debt)
    if (this.showDebug4) {
      console.log(
        'before liabilities,2  rgCAFR: ' +
          result.remainingGuidelineCAFR +
          '  raCAFR: ' +
          result.remainingActualCAFR +
          '  step2 gCAFR: ' +
          result[2].guidelineCAFR +
          '  step2 aCAFR: ' +
          result[2].actualCAFR
      );
    } // %//
    this.processRequiredCAFR(method, curYYYYMM, result, liabilities, 2, true);
    if (this.showDebug4) {
      console.log(
        'after  liabilities,2  rgCAFR: ' +
          result.remainingGuidelineCAFR +
          '  raCAFR: ' +
          result.remainingActualCAFR +
          '  step2 gCAFR: ' +
          result[2].guidelineCAFR +
          '  step2 aCAFR: ' +
          result[2].actualCAFR
      );
    } // %//

    // allocate guidelineCAFR to cover monthlyMinimum amounts from assets, step 3
    if (this.showDebug4) {
      console.log(
        'before assets,3  rgCAFR: ' +
          result.remainingGuidelineCAFR +
          '  raCAFR: ' +
          result.remainingActualCAFR +
          '  step3 gCAFR: ' +
          result[3].guidelineCAFR +
          '  step3 aCAFR: ' +
          result[3].actualCAFR
      );
    } // %//
    this.processRequiredCAFR(method, curYYYYMM, result, assets, 3);
    if (this.showDebug4) {
      console.log(
        'after  assets,3  rgCAFR: ' +
          result.remainingGuidelineCAFR +
          '  raCAFR: ' +
          result.remainingActualCAFR +
          '  step3 gCAFR: ' +
          result[3].guidelineCAFR +
          '  step3 aCAFR: ' +
          result[3].actualCAFR
      );
    } // %//

    // allocate guidelineCAFR to cover monthlyMinimum amounts from assetProtection
    if (this.showDebug4) {
      console.log(
        'before assetProtection,4  rgCAFR: ' +
          result.remainingGuidelineCAFR +
          '  raCAFR: ' +
          result.remainingActualCAFR +
          '  step4 gCAFR: ' +
          result[4].guidelineCAFR +
          '  step4 aCAFR: ' +
          result[4].actualCAFR
      );
    } // %//
    this.processRequiredCAFR(method, curYYYYMM, result, assetProtection, 4);
    if (this.showDebug4) {
      console.log(
        'after  assetProtection,4  rgCAFR: ' +
          result.remainingGuidelineCAFR +
          '  raCAFR: ' +
          result.remainingActualCAFR +
          '  step4 gCAFR: ' +
          result[4].guidelineCAFR +
          '  step4 aCAFR: ' +
          result[4].actualCAFR
      );
    } // %//

    // allocate guidelineCAFR to cover monthlyMinimum amounts from liabilities (productive debt)
    if (this.showDebug4) {
      console.log(
        'before liabilities,4  rgCAFR: ' +
          result.remainingGuidelineCAFR +
          '  raCAFR: ' +
          result.remainingActualCAFR +
          '  step4 gCAFR: ' +
          result[4].guidelineCAFR +
          '  step4 aCAFR: ' +
          result[4].actualCAFR
      );
    } // %//
    this.processRequiredCAFR(method, curYYYYMM, result, liabilities, 4, false);
    if (this.showDebug4) {
      console.log(
        'after  liabilities,4  rgCAFR: ' +
          result.remainingGuidelineCAFR +
          '  raCAFR: ' +
          result.remainingActualCAFR +
          '  step4 gCAFR: ' +
          result[4].guidelineCAFR +
          '  step4 aCAFR: ' +
          result[4].actualCAFR
      );
    } // %//

    // allocate guidelineCAFR to cover monthlyMinimum amounts from assets, step4
    if (this.showDebug4) {
      console.log(
        'before assets,4  rgCAFR: ' +
          result.remainingGuidelineCAFR +
          '  raCAFR: ' +
          result.remainingActualCAFR +
          '  step4 gCAFR: ' +
          result[4].guidelineCAFR +
          '  step4 aCAFR: ' +
          result[4].actualCAFR
      );
    } // %//
    this.processRequiredCAFR(method, curYYYYMM, result, assets, 4);
    if (this.showDebug4) {
      console.log(
        'after  assets,4  rgCAFR: ' +
          result.remainingGuidelineCAFR +
          '  raCAFR: ' +
          result.remainingActualCAFR +
          '  step4 gCAFR: ' +
          result[4].guidelineCAFR +
          '  step4 aCAFR: ' +
          result[4].actualCAFR
      );
    } // %//

    result.remainingGuidelineCAFRAfterMinimums = result.remainingGuidelineCAFR;

    if (calcUnusedCAFR === true) {
      result.unusedCAFR = result.remainingActualCAFR;
      result.remainingActualCAFR = 0;
    } else {
      result.unusedCAFR = unusedCAFR;
    }
    result.remainingActualCAFRAfterMinimums = result.remainingActualCAFR;

    // %//  \/
    if (this.showDebug6 || this.showDebug7) {
      // update CAFR schedule information
      item = {
        curYYYYMM,
        step: -1,
        CAFRamount: result.remainingGuidelineCAFR,
        accountValue: 0,
        accountName: 'remainingGuidelineCAFR-getCafrInfo'
      };
      this.CAFRscheduleDetails.guideline.push(item);
      item = {
        curYYYYMM,
        step: -1,
        CAFRamount: result.remainingActualCAFR,
        accountValue: 0,
        accountName: 'remainingActualCAFR-getCafrInfo'
      };
      this.CAFRscheduleDetails.actual.push(item);
    }
    // %//  /\

    // update CAFRcalculationDetails
    if (method === 'guideline') {
      this.CAFRcalculationDetails.guideline.push({
        curYYYYMM,
        step: -1,
        monthlyMinimum: 0,
        monthlyAmount: 0,
        haveBudgetSubcategory: 'x',
        CAFRamount: result.availableGuidelineCAFR,
        accountValue: 0,
        accountName: 'availableGuidelineCAFR-getCafrInfo'
      });
    } else {
      this.CAFRcalculationDetails.actual.push({
        curYYYYMM,
        step: -1,
        monthlyMinimum: 0,
        monthlyAmount: 0,
        haveBudgetSubcategory: 'x',
        CAFRamount: result.remainingActualCAFR,
        accountValue: 0,
        accountName: 'availableActualCAFR-getCafrInfo'
      });
    }

    /////////////////////////////////////////////////////////////////
    // allocate remaining CAFR according to the four step plan
    /////////////////////////////////////////////////////////////////

    ////////////////////
    // Step 1
    ////////////////////

    // %//  \/
    if (this.showDebug4) {
      console.log(' ');
      console.log('start step processing');
    }
    // %//  /\

    // let repeat = true;
    // let pass = 0;
    // while(repeat && ++pass < 5) {
    // allocate remainingGuidelineCAFR to emergencySavings assets
    if (this.showDebug4) {
      console.log(
        'before step 1  rgCAFR: ' +
          result.remainingGuidelineCAFR +
          '  raCAFR: ' +
          result.remainingActualCAFR +
          '  step1 gCAFR: ' +
          result[1].guidelineCAFR
      );
    } // %//
    this.processSavingsAssets(method, curYYYYMM, result, assets, 1, 'emergencySavings');
    if (this.showDebug4) {
      console.log(
        'after  step 1  rgCAFR: ' +
          result.remainingGuidelineCAFR +
          '  raCAFR: ' +
          result.remainingActualCAFR +
          '  step1 gCAFR: ' +
          result[1].guidelineCAFR
      );
    } // %//

    ////////////////////
    // Step 2
    ////////////////////

    // allocate remainingGuidelineCAFR to liabilities (non productive debt)
    if (this.showDebug4) {
      console.log(
        'before step 2  rgCAFR: ' +
          result.remainingGuidelineCAFR +
          '  raCAFR: ' +
          result.remainingActualCAFR +
          '  step2 gCAFR: ' +
          result[2].guidelineCAFR
      );
    } // %//
    this.processNonProductiveDebt(method, curYYYYMM, result, liabilities, 2);
    if (this.showDebug4) {
      console.log(
        'after  step 2  rgCAFR: ' +
          result.remainingGuidelineCAFR +
          '  raCAFR: ' +
          result.remainingActualCAFR +
          '  step2 gCAFR: ' +
          result[2].guidelineCAFR
      );
    } // %//

    ////////////////////
    // Step 3
    ////////////////////

    // allocate remainingGuidelineCAFR to cashReserves assets
    if (this.showDebug4) {
      console.log(
        'before step 3  rgCAFR: ' +
          result.remainingGuidelineCAFR +
          '  raCAFR: ' +
          result.remainingActualCAFR +
          '  step3 gCAFR: ' +
          result[3].guidelineCAFR
      );
    } // %//
    this.processSavingsAssets(method, curYYYYMM, result, assets, 3, 'cashReserves');
    if (this.showDebug4) {
      console.log(
        'after step 3   rgCAFR: ' +
          result.remainingGuidelineCAFR +
          '  raCAFR: ' +
          result.remainingActualCAFR +
          '  step3 gCAFR: ' +
          result[3].guidelineCAFR
      );
    } // %//

    // if(result.remainingActualCAFR <= 0 && result.remainingGuidelineCAFR <= 0) repeat = false;
    // }

    ////////////////////
    // Step 4
    ////////////////////

    // allocate remainingGuidelineCAFR to "bin" that grows at profile.investmentReturnRate
    if (this.showDebug4) {
      console.log(
        'before step 4  gCAFR: ' +
          result.remainingGuidelineCAFR +
          '  aCAFR: ' +
          result.remainingActualCAFR +
          '  step4 gCAFR: ' +
          result[4].guidelineCAFR
      );
    } // %//

    // console.log('getCafrInfo -- result: ', result);  //%//
    if (result.remainingGuidelineCAFR > 0) {
      result[4].guidelineCAFR = result.remainingGuidelineCAFR;
      result.remainingGuidelineCAFR = 0;
    } else {
      result[4].guidelineCAFR = 0;
    }
    if (result.remainingActualCAFR > 0) {
      result[4].actualCAFR += result.remainingActualCAFR;
      result.remainingActualCAFR = 0;
    } else {
      // result[4].actualCAFR = 0;
    }

    // %//  \/
    // update CAFR schedule information
    if (this.showDebug6) {
      if (method === 'guideline') {
        this.CAFRscheduleDetails.guideline.push({
          curYYYYMM,
          step: 4,
          CAFRamount: result.remainingGuidelineCAFR,
          accountValue: -1,
          accountName: 'step4-getCafrInfo (4)'
        });
      } else {
        this.CAFRscheduleDetails.actual.push({
          curYYYYMM,
          step: 4,
          CAFRamount: result.remainingActualCAFR,
          accountValue: -1,
          accountName: 'step4-getCafrInfo (4)'
        });
      }
    }
    // %//  /\

    // update CAFRcalculationDetails
    if (method === 'guideline') {
      this.CAFRcalculationDetails.guideline.push({
        curYYYYMM,
        step: 4,
        monthlyMinimum: 0,
        monthlyAmount: 0,
        haveBudgetSubcategory: 'x',
        CAFRamount: result.remainingGuidelineCAFR,
        accountValue: -1,
        accountName: 'step4-getCafrInfo (4)'
      });
    } else {
      this.CAFRcalculationDetails.actual.push({
        curYYYYMM,
        step: 4,
        monthlyMinimum: 0,
        monthlyAmount: 0,
        haveBudgetSubcategory: 'x',
        CAFRamount: result.remainingActualCAFR,
        accountValue: -1,
        accountName: 'step4-getCafrInfo (4)'
      });
    }

    if (this.showDebug4) {
      console.log(
        'after  step 4  gCAFR: ' +
          result.remainingGuidelineCAFR +
          '  aCAFR: ' +
          result.remainingActualCAFR +
          '  step4 gCAFR: ' +
          result[4].guidelineCAFR
      );
    } // %//

    if (calcUnusedCAFR === true) {
      result.remainingActualCAFR = result.unusedCAFR;
    }

    // %//  \/
    if (this.showDebug6 || this.showDebug7) {
      // update CAFR schedule information
      if (method === 'guideline') {
        item = {
          curYYYYMM,
          step: 100,
          CAFRamount: result.remainingGuidelineCAFR,
          accountValue: 0,
          accountName: 'remainingGuidelineCAFR-getCafrInfo'
        };
        this.CAFRscheduleDetails.guideline.push(item);
      } else {
        item = {
          curYYYYMM,
          step: 100,
          CAFRamount: result.remainingActualCAFR,
          accountValue: 0,
          accountName: 'remainingActualCAFR-getCafrInfo'
        };
        this.CAFRscheduleDetails.actual.push(item);
      }
    }
    // %//  /\

    // update CAFRcalculationDetails
    if (method === 'guideline') {
      this.CAFRcalculationDetails.guideline.push({
        curYYYYMM,
        step: 100,
        monthlyMinimum: 0,
        monthlyAmount: 0,
        haveBudgetSubcategory: 'x',
        CAFRamount: result.remainingGuidelineCAFR,
        accountValue: 0,
        accountName: 'remainingGuidelineCAFR-getCafrInfo'
      });
    } else {
      this.CAFRcalculationDetails.actual.push({
        curYYYYMM,
        step: 100,
        monthlyMinimum: 0,
        monthlyAmount: 0,
        haveBudgetSubcategory: 'x',
        CAFRamount: result.remainingActualCAFR,
        accountValue: 0,
        accountName: 'remainingActualCAFR-getCafrInfo'
      });
    }

    return result;
  } // getCafrInfo

  /*
    The following function returns CAFR information.  The function serves as an interface between the underlying cafrInfo data structure and the calling environment of an HTML page.

    getCAFR(method:string, categories:any, whichData:string, step:number, category:string, subcat:string, accountName:string): number

    @categories =
    {
    income: gdIncome.category,
    budget: gdBudget.category,
    assets: gdAssets.category,
    assetProtection: gdAssetProtection.category,
    liabilities: gdLiabilities.category
    }

    @whichData
  ==========
    availableGuidelineCAFR
    availableActualCAFR
    remainingGuidelineCAFR
    remainingActualCAFR
    getCAFR(method,categories,whichData)

  guidelineCAFR
  actualCAFR
    getCAFR(method,categories,whichData,step)
    getCAFR(method,categories,whichData,step,category)
    getCAFR(method,categories,whichData,step,category,subcat)
    getCAFR(method,categories,whichData,step,category,subcat,accountName)

  Note: the getCAFR entries above illustrate the function parameters to use for each "whichData" value

  @step: identifies which step in the four step plan holds the relevant data
  @subcat: identifies the subcategory under the given category
  @accountName: identifies name of account under the given subcategory
  @method: 'guideline' or 'actual'; applies guidelineCAFR or actualCAFR
  */

  public getCAFR(
    method: string,
    categories: any,
    whichData: string,
    step: number = null,
    category: string = null,
    subcat: string = null,
    accountName: string = null
  ): number {
    // initialize
    let result;
    const unusedCAFR = 0;
    const calcUnusedCAFR = true;

    // TODO do the following just once rather than for each successive function invocation.
    // This will involve finding a way to invoke the getCafrInfo function prior to executing a
    // sequence of getCAFR calls (e.g. in the HTML context).  A cursory examination of Angular 2
    // hooks did not reveal a clear way to do this.  If we could rely on the fact that the HTML is
    // is processed sequentially from top to bottom, here is a sketch of how to do this:
    // 1) prior to encountering first getCAFR call in HTML, set flag indicating that function invocation is required
    // 2) when function is invoked, set flag indicating further function invocation is NOT required
    // 3) after encountering last getCAFR call in HTML, set flag indicating that function invocation is required

    // populate the CAFR data structure
    if (this.needCafrInfoUpdate) {
      const extraGuidelineCAFR = { extra: 0 };
      const curDate = new Date().toISOString();
      const curYearMonth = {
        year: Number(curDate.substr(0, 4)),
        month: Number(curDate.substr(5, 2)) - 1 // note: 0 <= month <= 11 to accomodate JavaScript conventions
      };
      this.cafrInfo = this.getCafrInfo(
        method,
        this.yearMonth2YYYYMM(curYearMonth),
        categories.income,
        categories.budget,
        categories.assets,
        categories.assetProtection,
        categories.liabilities,
        calcUnusedCAFR,
        unusedCAFR,
        extraGuidelineCAFR
      );
    }

    let nullCount = 0;
    if (accountName === null) {
      nullCount++;
    }
    if (subcat === null) {
      nullCount++;
    }
    if (category === null) {
      nullCount++;
    }
    if (step === null) {
      nullCount++;
    }

    switch (nullCount) {
      case 0:
        result = this.cafrInfo[step][category][subcat].accounts[accountName][whichData];
        break;
      case 1:
        result = this.cafrInfo[step][category][subcat][whichData];
        break;
      case 2:
        result = this.cafrInfo[step][category][whichData];
        break;
      case 3:
        result = this.cafrInfo[step][whichData];
        break;
      case 4:
        result = this.cafrInfo[whichData];
        break;
    } // switch
    return result;
  } // getCAFR

  /**************************************************************************************
  // This section deals with data projections for generating graphs
  **************************************************************************************/

  public nextYearMonth(date: IYearMonth): void {
    if (date.month < 11) {
      date.month = date.month + 1;
      date.year = date.year;
    } else {
      date.month = 0;
      date.year = date.year + 1;
    }
  } // nextYearMonth

  public yearMonth2YYYYMM(date: IYearMonth): string {
    const yearstr = date.year.toString();
    let mthstr = (date.month + 1).toString();
    if (mthstr.length < 2) {
      mthstr = '0' + mthstr;
    }
    return yearstr + mthstr;
  } // yearMonth2YYYYMM

  public getDataProjectionInfo(numMonths: number, assets: any, assetProtection: any, liabilities: any): any {
    // initialize
    let interestRate: number;
    let monthlyAmount: number;
    let monthlyMinimum: number;
    let newAccountValue: number;
    let interest: number;
    let accountName: string;
    let plan: string;
    let employerContribution: number;
    let accountValue: number;
    let prevAccountValue: number;
    let startYearMonth, curYearMonth: IYearMonth;
    let item: IGraphItem;
    let haveMonthlyAmount: boolean;
    let val: number;
    const xDates: any = [];

    // set the starting YearMonth
    plan = this.dataModelService.dataModel.persistent.header.curplan;
    if (plan === 'original') {
      // ISO date format: YYYY-MM-DDTHH:mm:ss.sssZ
      const dateCreated = this.dataModelService.dataModel.persistent.header.dateCreated;
      startYearMonth = {
        year: Number(dateCreated.substr(0, 4)),
        month: Number(dateCreated.substr(5, 2)) - 1 // note: 0 <= month <= 11 to accomodate JavaScript conventions
      };
    } else {
      // plan format: pYYYYMM
      startYearMonth = {
        year: Number(plan.substr(1, 4)),
        month: Number(plan.substr(5, 2)) - 1
      };
    }

    // %//   \/
    if (this.showDebug5) {
      console.log(' ');
      console.log('getDataProjectionInfo');
    }
    // %//   /\

    const dataProjectionInfo = {
      assets: {
        details: {},
        summary: { projection: [] },
        interest: 0
      },
      productiveDebt: {
        details: {},
        summary: { projection: [] },
        interest: 0
      },
      nonproductiveDebt: {
        details: {},
        summary: { projection: [] },
        interest: 0
      },
      allDebt: {
        summary: { projection: [] },
        interest: 0
      },
      netWorth: {
        summary: { projection: [] },
        interest: 0
      }
    };

    // initialize date
    curYearMonth = JSON.parse(JSON.stringify(startYearMonth));
    xDates[0] = new Date(curYearMonth.year, curYearMonth.month).toISOString();
    for (let i = 1; i < numMonths; i++) {
      // put value into array
      this.nextYearMonth(curYearMonth);
      xDates[i] = new Date(curYearMonth.year, curYearMonth.month).toISOString();
    }

    /////////////////////////////////
    // set assets data
    /////////////////////////////////
    for (const subcat of Object.keys(assets)) {
      if (skipSubcatAttributes.indexOf(subcat) === -1) {
        for (const account of assets[subcat].accounts) {
          // extract asset data
          accountName = account.accountName.val;
          interestRate = 0;
          if (account.hasOwnProperty('interestRate')) {
            interestRate = account.interestRate.val;
          }
          monthlyAmount = 0;
          haveMonthlyAmount = false;
          if (account.hasOwnProperty('monthlyAmount') && !isNaN(account.monthlyAmount.val)) {
            monthlyAmount = account.monthlyAmount.val;
            haveMonthlyAmount = true;
          }
          monthlyMinimum = 0;
          if (account.hasOwnProperty('monthlyMinimum')) {
            monthlyMinimum = account.monthlyMinimum.val;
          }
          employerContribution = 0;
          if (account.hasOwnProperty('employerContribution')) {
            employerContribution = account.employerContribution.val;
          }
          accountValue = 0;
          if (account.hasOwnProperty('accountValue')) {
            accountValue = account.accountValue.val;
          }

          // establish object to hold information for each account
          dataProjectionInfo.assets.details[accountName] = {
            sumInterest: 0,
            interestRate,
            monthlyAmount,
            employerContribution,
            projection: []
          };

          // initialize date
          curYearMonth = JSON.parse(JSON.stringify(startYearMonth));

          // compute projection for this account
          // %//   \/
          if (this.showDebug5) {
            console.log('assets,' + subcat + ',' + accountName + '  nVal: ' + Math.floor(accountValue));
          }
          // %//   /\
          item = {
            x: new Date(curYearMonth.year, curYearMonth.month).toISOString(),
            y: accountValue
          };
          dataProjectionInfo.assets.details[accountName].projection.push(item);
          if (this.showDebug5) {
            console.log(' ');
          } // %//
          for (let i = 1; i < numMonths; i++) {
            // compute new account value
            prevAccountValue = dataProjectionInfo.assets.details[accountName].projection[i - 1].y;
            interest = prevAccountValue * ((0.01 * interestRate) / 12);
            val = haveMonthlyAmount ? monthlyAmount : monthlyMinimum;
            newAccountValue = prevAccountValue + interest + val + employerContribution;

            // %//   \/
            if (this.showDebug5) {
              console.log(
                'assets,' +
                  subcat +
                  ',' +
                  accountName +
                  '  nVal: ' +
                  Math.floor(newAccountValue) +
                  '  oVal: ' +
                  Math.floor(prevAccountValue) +
                  '  mVal: ' +
                  Math.floor(val) +
                  '  eAmt: ' +
                  Math.floor(employerContribution) +
                  '  int: ' +
                  Math.floor(interest) +
                  '  intRate: ' +
                  interestRate
              );
            }
            // %//   /\

            // put value into array
            this.nextYearMonth(curYearMonth);
            item = {
              x: new Date(curYearMonth.year, curYearMonth.month).toISOString(),
              y: newAccountValue
            };
            dataProjectionInfo.assets.details[accountName].projection.push(item);
            dataProjectionInfo.assets.details[accountName].sumInterest += interest;
            dataProjectionInfo.assets.interest += interest;
          }
        } // for actndx
      } // if
    } // for subcat of assets

    /////////////////////////////////
    // set asset protection data
    /////////////////////////////////
    for (const subcat of Object.keys(assetProtection)) {
      if (skipSubcatAttributes.indexOf(subcat) === -1) {
        for (const account of assetProtection[subcat].accounts) {
          // extract assetProtection data
          accountName = account.accountName.val;
          interestRate = 0;
          if (account.hasOwnProperty('interestRate')) {
            interestRate = account.interestRate.val;
          }
          monthlyAmount = 0;
          if (account.hasOwnProperty('monthlyAmount')) {
            monthlyAmount = account.monthlyAmount.val;
          }
          employerContribution = 0;
          if (account.hasOwnProperty('employerContribution')) {
            employerContribution = account.employerContribution.val;
          }
          accountValue = 0;
          if (account.hasOwnProperty('accountValue')) {
            accountValue = account.accountValue.val;
          }

          // establish object to hold information for each account
          dataProjectionInfo.assets.details[accountName] = {
            sumInterest: 0,
            interestRate,
            monthlyAmount,
            employerContribution,
            projection: []
          };

          // initialize date
          curYearMonth = JSON.parse(JSON.stringify(startYearMonth));

          // compute projection for this account
          item = {
            x: new Date(curYearMonth.year, curYearMonth.month).toISOString(),
            y: accountValue
          };
          dataProjectionInfo.assets.details[accountName].projection.push(item);
          for (let i = 1; i < numMonths; i++) {
            // compute new account value
            prevAccountValue = dataProjectionInfo.assets.details[accountName].projection[i - 1].y;
            interest = prevAccountValue * ((0.01 * interestRate) / 12);
            newAccountValue = prevAccountValue + interest + monthlyAmount + employerContribution;

            // put value into array
            this.nextYearMonth(curYearMonth);
            item = {
              x: new Date(curYearMonth.year, curYearMonth.month).toISOString(),
              y: newAccountValue
            };
            dataProjectionInfo.assets.details[accountName].projection.push(item);
            dataProjectionInfo.assets.details[accountName].sumInterest += interest;
            dataProjectionInfo.assets.interest += interest;
          }
        } // for actndx
      } // if
    } // for subcat of assetProtection

    // compute sum of data for all assets and assetProtection accounts
    for (let i = 0; i < numMonths; i++) {
      dataProjectionInfo.assets.summary.projection.push({
        x: xDates[i],
        y: 0
      });
      dataProjectionInfo.assets.summary.projection[i].y = 0;
      for (const funcAccountName of Object.keys(dataProjectionInfo.assets.details)) {
        dataProjectionInfo.assets.summary.projection[i].y += dataProjectionInfo.assets.details[funcAccountName].projection[i].y;
      }
    }

    /////////////////////////////////
    // set productiveDebt data
    /////////////////////////////////
    for (const subcat of Object.keys(liabilities)) {
      if (skipSubcatAttributes.indexOf(subcat) === -1) {
        for (const account of liabilities[subcat].accounts) {
          if ((account.productivity && account.productivity.val === 'Productive') || account.productivity.val === 'Limited') {
            // extract asset data
            accountName = account.accountName.val;
            interestRate = 0;
            if (account.hasOwnProperty('interestRate')) {
              interestRate = account.interestRate.val;
            }
            monthlyAmount = 0;
            haveMonthlyAmount = false;
            if (account.hasOwnProperty('monthlyAmount')) {
              monthlyAmount = account.monthlyAmount.val;
              haveMonthlyAmount = true;
            }
            monthlyMinimum = 0;
            if (account.hasOwnProperty('monthlyMinimum')) {
              monthlyMinimum = account.monthlyMinimum.val;
            }
            accountValue = 0;
            if (account.hasOwnProperty('accountValue')) {
              accountValue = account.accountValue.val;
            }

            // establish object to hold information for each account
            dataProjectionInfo.productiveDebt.details[accountName] = {
              sumInterest: 0,
              interestRate,
              monthlyAmount,
              employerContribution,
              projection: []
            };

            // initialize date
            curYearMonth = JSON.parse(JSON.stringify(startYearMonth));

            // compute projection for this account
            item = {
              x: new Date(curYearMonth.year, curYearMonth.month).toISOString(),
              y: accountValue
            };
            dataProjectionInfo.productiveDebt.details[accountName].projection.push(item);
            for (let i = 1; i < numMonths; i++) {
              // compute new account value
              prevAccountValue = dataProjectionInfo.productiveDebt.details[accountName].projection[i - 1].y;
              interest = prevAccountValue * ((0.01 * interestRate) / 12);
              val = haveMonthlyAmount ? monthlyAmount : monthlyMinimum;
              newAccountValue = prevAccountValue + interest - val;

              if (newAccountValue < 0) {
                newAccountValue = 0;
              }

              // put value into array
              this.nextYearMonth(curYearMonth);
              item = {
                x: new Date(curYearMonth.year, curYearMonth.month).toISOString(),
                y: newAccountValue
              };
              dataProjectionInfo.productiveDebt.details[accountName].projection.push(item);
              dataProjectionInfo.productiveDebt.details[accountName].sumInterest += interest;
              dataProjectionInfo.productiveDebt.interest += interest;
            }
          } // if
        } // for actndx
      } // if
    } // for subcat of liabilities

    // compute sum of data for all accounts
    for (let i = 0; i < numMonths; i++) {
      dataProjectionInfo.productiveDebt.summary.projection.push({
        x: xDates[i],
        y: 0
      });
      dataProjectionInfo.productiveDebt.summary.projection[i].y = 0;
      for (const funcAccountName of Object.keys(dataProjectionInfo.productiveDebt.details)) {
        dataProjectionInfo.productiveDebt.summary.projection[i].y += dataProjectionInfo.productiveDebt.details[funcAccountName].projection[i].y;
      }
    }

    /////////////////////////////////
    // nonproductiveDebt data
    /////////////////////////////////
    for (const subcat of Object.keys(liabilities)) {
      if (skipSubcatAttributes.indexOf(subcat) === -1) {
        for (const account of liabilities[subcat].accounts) {
          if (account.productivity && account.productivity.val === 'Non-productive') {
            // extract asset data
            accountName = account.accountName.val;
            interestRate = 0;
            if (account.hasOwnProperty('interestRate')) {
              interestRate = account.interestRate.val;
            }
            monthlyAmount = 0;
            haveMonthlyAmount = false;
            if (account.hasOwnProperty('monthlyAmount')) {
              monthlyAmount = account.monthlyAmount.val;
              haveMonthlyAmount = true;
            }
            monthlyMinimum = 0;
            if (account.hasOwnProperty('monthlyMinimum')) {
              monthlyMinimum = account.monthlyMinimum.val;
            }
            accountValue = 0;
            if (account.hasOwnProperty('accountValue')) {
              accountValue = account.accountValue.val;
            }

            // establish object to hold information for each account
            dataProjectionInfo.nonproductiveDebt.details[accountName] = {
              sumInterest: 0,
              interestRate,
              monthlyAmount,
              employerContribution,
              projection: []
            };

            // initialize date
            curYearMonth = JSON.parse(JSON.stringify(startYearMonth));

            // compute projection for this account
            item = {
              x: new Date(curYearMonth.year, curYearMonth.month).toISOString(),
              y: accountValue
            };
            dataProjectionInfo.nonproductiveDebt.details[accountName].projection.push(item);
            for (let i = 1; i < numMonths; i++) {
              // compute new account value
              prevAccountValue = dataProjectionInfo.nonproductiveDebt.details[accountName].projection[i - 1].y;
              interest = prevAccountValue * ((0.01 * interestRate) / 12);
              val = haveMonthlyAmount ? monthlyAmount : monthlyMinimum;
              newAccountValue = prevAccountValue + interest - val;

              if (newAccountValue < 0) {
                newAccountValue = 0;
              }

              // put value into array
              this.nextYearMonth(curYearMonth);
              item = {
                x: new Date(curYearMonth.year, curYearMonth.month).toISOString(),
                y: newAccountValue
              };
              dataProjectionInfo.nonproductiveDebt.details[accountName].projection.push(item);
              dataProjectionInfo.nonproductiveDebt.details[accountName].sumInterest += interest;
              dataProjectionInfo.nonproductiveDebt.interest += interest;
            }
          } // if
        } // for actndx
      } // if
    } // for subcat of liabilities

    // compute sum of data for all accounts
    for (let i = 0; i < numMonths; i++) {
      dataProjectionInfo.nonproductiveDebt.summary.projection.push({
        x: xDates[i],
        y: 0
      });
      dataProjectionInfo.nonproductiveDebt.summary.projection[i].y = 0;
      for (const funcAccountName of Object.keys(dataProjectionInfo.nonproductiveDebt.details)) {
        dataProjectionInfo.nonproductiveDebt.summary.projection[i].y += dataProjectionInfo.nonproductiveDebt.details[funcAccountName].projection[i].y;
      }
    }

    /////////////////////////////////
    // set allDebt data
    /////////////////////////////////
    for (let i = 0; i < numMonths; i++) {
      item = {
        x: dataProjectionInfo.productiveDebt.summary.projection[i].x,
        y: dataProjectionInfo.productiveDebt.summary.projection[i].y + dataProjectionInfo.nonproductiveDebt.summary.projection[i].y
      };
      dataProjectionInfo.allDebt.summary.projection.push(item);
    }

    /////////////////////////////////
    // set netWorth data
    /////////////////////////////////
    for (let i = 0; i < numMonths; i++) {
      item = {
        x: dataProjectionInfo.assets.summary.projection[i].x,
        y: dataProjectionInfo.assets.summary.projection[i].y - dataProjectionInfo.allDebt.summary.projection[i].y
      };
      dataProjectionInfo.netWorth.summary.projection.push(item);
    }

    dataProjectionInfo.allDebt.interest = dataProjectionInfo.nonproductiveDebt.interest + dataProjectionInfo.productiveDebt.interest;

    return dataProjectionInfo;
  } // getDataProjectionInfo

  public getDataProjection(whichData: string, numMonths: number, assets: any, assetProtection: any, liabilities: any): any {
    // initialize
    let result = [];
    // TODO do the following just once rather than for each successive function invocation
    if (!this.privateDataProjectionInfo) {
      this.privateDataProjectionInfo = this.getDataProjectionInfo(numMonths, assets, assetProtection, liabilities);
    }

    if (['assets', 'productiveDebt', 'nonproductiveDebt', 'allDebt', 'netWorth'].indexOf(whichData) !== -1) {
      result = this.privateDataProjectionInfo[whichData];
    }

    return result;
  } // getDataProjection

  public getProjectionSum(category: any, field: string = 'accountValue', wantNonProductive): number {
    let sum: number;
    let returnValue: number;

    sum = 0;
    for (const subcat of Object.keys(category)) {
      if (skipSubcatAttributes.indexOf(subcat) === -1) {
        for (const account of category[subcat].accounts) {
          const isNonProductive = account.productivity && account.productivity.val === 'Non-productive';

          if (isNonProductive === wantNonProductive) {
            if (isNonProductive === true) {
              //console.log(account);
            }
            returnValue = 0;
            if (account.hasOwnProperty(field)) {
              if (account[field].val && !isNaN(account[field].val)) {
                returnValue = account[field].val;
              } else {
                if (!isNaN(account[field])) {
                  returnValue = account[field];
                }
              }
            }
            // add value to sum
            sum += returnValue;
          }
        }
      } // if
    } // for subcat

    return sum;
  } // getProjectionSum

  public postData(
    curYearMonth: IYearMonth,
    method: string,
    curPlan: any,
    cafrDataProjectionInfo: any,
    step4CAFRsum: { sum: number },
    prevStep4CAFR: number
  ): void {
    let item: IGraphItem;
    let productiveAssetsSum: number;
    let nonproductiveAssetsSum: number;
    let productiveDebtSum: number;
    let nonproductiveDebtSum: number;
    let x: string;
    let interestRate, monthlyAmount, monthlyMinimum, employerContribution, accountValue: number;
    let interest, guidelineCAFR, actualCAFR: number;
    let isNonProductiveAssets, haveBudgetSubcategory: boolean;
    // set x to date value for all items of data to be added
    x = new Date(curYearMonth.year, curYearMonth.month).toISOString();
    // console.log(curPlan);
    // collect the necessary data
    productiveAssetsSum = this.getProjectionSum(curPlan.assets, 'accountValue', false);
    nonproductiveAssetsSum = this.getProjectionSum(curPlan.assets, 'accountValue', true);
    productiveDebtSum = this.getProjectionSum(curPlan.liabilities, 'accountValue', false);
    nonproductiveDebtSum = this.getProjectionSum(curPlan.liabilities, 'accountValue', true);

    // handle step 4 CAFR
    interestRate = this.dataModelService.dataModel.persistent.profile.investmentReturnRate;
    interest = step4CAFRsum.sum * ((0.01 * interestRate) / 12);
    step4CAFRsum.sum += interest + prevStep4CAFR;
    productiveAssetsSum += step4CAFRsum.sum;
    // %//   \/
    if (this.showDebug3) {
      console.log(curYearMonth.year + ',' + (curYearMonth.month + 1) + ',step4CAFRsum: ' + step4CAFRsum.sum + '  cur: ' + prevStep4CAFR);
    }
    // %//   /\

    // %//   \/
    if (this.showDebug7) {
      // update CAFR schedule information
      const curYYYYMM = this.yearMonth2YYYYMM(curYearMonth);
      const funcItem = {
        curYYYYMM,
        step: 4,
        CAFRamount: prevStep4CAFR,
        accountValue: step4CAFRsum.sum,
        accountName: 'postData-step4CAFRsum'
      };
      if (method === 'guideline') {
        this.CAFRscheduleDetails.guideline.push(funcItem);
      } else {
        this.CAFRscheduleDetails.actual.push(funcItem);
      }
    }
    // %//   /\

    const projectionItem = {
      curYearMonth: x,
      accountName: 'Step 4 CAFR',
      interestRate,
      monthlyMinimum: 0,
      monthlyAmount: 0,
      employerContribution: 0,
      accountValue: step4CAFRsum.sum,
      interest,
      guidelineCAFR: prevStep4CAFR,
      actualCAFR: prevStep4CAFR
    };
    // establish object to hold information for each account
    if (!cafrDataProjectionInfo.assets.details.hasOwnProperty('step4CAFR')) {
      cafrDataProjectionInfo.assets.details.step4CAFR = { projection: [] };
    }
    cafrDataProjectionInfo.assets.details.step4CAFR.projection.push(projectionItem);

    // post summary results into data structure
    item = { x, y: productiveAssetsSum };
    cafrDataProjectionInfo.productiveAssets.summary.projection.push(item);

    item = { x, y: nonproductiveAssetsSum };
    cafrDataProjectionInfo.nonproductiveAssets.summary.projection.push(item);

    item = { x, y: productiveAssetsSum + nonproductiveAssetsSum };
    cafrDataProjectionInfo.assets.summary.projection.push(item);

    item = { x, y: productiveDebtSum };
    cafrDataProjectionInfo.productiveDebt.summary.projection.push(item);

    item = { x, y: nonproductiveDebtSum };
    cafrDataProjectionInfo.nonproductiveDebt.summary.projection.push(item);

    item = { x, y: productiveDebtSum + nonproductiveDebtSum };
    cafrDataProjectionInfo.allDebt.summary.projection.push(item);

    const netWorth = productiveAssetsSum + nonproductiveAssetsSum - (productiveDebtSum + nonproductiveDebtSum);
    item = { x, y: netWorth };
    cafrDataProjectionInfo.netWorth.summary.projection.push(item);

    const adjustedNetWorth = productiveAssetsSum - (productiveDebtSum + nonproductiveDebtSum);
    item = { x, y: adjustedNetWorth };
    cafrDataProjectionInfo.adjustedNetWorth.summary.projection.push(item);

    // update goal dates
    this.updateGoalDates(
      method,
      curYearMonth,
      netWorth,
      adjustedNetWorth,
      curPlan.income,
      curPlan.assets,
      curPlan.liabilities,
      curPlan.assetProtection
    );

    // post detail results into data structure

    // post assets detail results into data structure
    const assets = curPlan.assets;
    for (const subcat of Object.keys(assets)) {
      if (skipSubcatAttributes.indexOf(subcat) === -1) {
        for (const account of assets[subcat].accounts) {
          // extract asset data
          let accountName = 'Unknown';
          if (account.hasOwnProperty('accountName')) {
            accountName = account.accountName.val || 'Unknown';
          }
          interestRate = 0;
          if (account.hasOwnProperty('interestRate')) {
            interestRate = account.interestRate.val || 0;
          }
          monthlyAmount = 0;
          if (account.hasOwnProperty('monthlyAmount')) {
            monthlyAmount = account.monthlyAmount.val || 0;
          }
          monthlyMinimum = 0;
          if (account.hasOwnProperty('monthlyMinimum')) {
            monthlyMinimum = account.monthlyMinimum.val || 0;
          }
          employerContribution = 0;
          if (account.hasOwnProperty('employerContribution')) {
            employerContribution = account.employerContribution.val || 0;
          }
          accountValue = 0;
          if (account.hasOwnProperty('accountValue')) {
            accountValue = account.accountValue.val || 0;
          }
          interest = 0;
          if (account.hasOwnProperty('interest')) {
            interest = account.interest.val || 0;
          }
          guidelineCAFR = 0;
          if (account.hasOwnProperty('guidelineCAFR')) {
            guidelineCAFR = account.guidelineCAFR.val || 0;
          }
          actualCAFR = 0;
          if (account.hasOwnProperty('actualCAFR')) {
            actualCAFR = account.actualCAFR.val || 0;
          }
          isNonProductiveAssets = false;
          if (typeof account === 'object') {
            isNonProductiveAssets = account.productivity && account.productivity.val === 'Non-productive';
          }
          haveBudgetSubcategory = false;
          if (account.hasOwnProperty('budgetSubcategory')) {
            haveBudgetSubcategory = account.budgetSubcategory.val !== '';
          }

          const funcItem = {
            curYearMonth: x,
            accountName,
            interestRate,
            monthlyMinimum,
            monthlyAmount,
            employerContribution,
            accountValue,
            interest,
            guidelineCAFR,
            actualCAFR,
            haveBudgetSubcategory
          };

          const item2 = {
            curYearMonth: x.substr(0, 4) + x.substr(5, 2),
            category: 'assets',
            accountName,
            interestRate,
            monthlyMinimum,
            monthlyAmount,
            employerContribution,
            accountValue,
            interest,
            guidelineCAFR,
            actualCAFR,
            haveBudgetSubcategory
          };
          this.CAFRprojectionDetails.push(item2);

          // establish object to hold information for each account
          if (isNonProductiveAssets) {
            if (!cafrDataProjectionInfo.nonproductiveAssets.details.hasOwnProperty(accountName)) {
              cafrDataProjectionInfo.nonproductiveAssets.details[accountName] = { projection: [] };
            }
            cafrDataProjectionInfo.nonproductiveAssets.details[accountName].projection.push(funcItem);
          } else {
            if (!cafrDataProjectionInfo.productiveAssets.details.hasOwnProperty(accountName)) {
              cafrDataProjectionInfo.productiveAssets.details[accountName] = {
                projection: []
              };
            }
            cafrDataProjectionInfo.productiveAssets.details[accountName].projection.push(funcItem);
          }
        } // for actndx
      } // if
    } // for subcat of assets

    // post asset protection detail results into data structure
    const assetProtection = curPlan.assetProtection;
    for (const subcat of Object.keys(assetProtection)) {
      if (skipSubcatAttributes.indexOf(subcat) === -1) {
        for (const account of assetProtection[subcat].accounts) {
          // extract asset data
          let accountName = 'Unknown';
          if (account.hasOwnProperty('accountName')) {
            accountName = account.accountName.val;
          }
          interestRate = 0;
          if (account.hasOwnProperty('interestRate')) {
            interestRate = account.interestRate.val;
          }
          monthlyAmount = 0;
          if (account.hasOwnProperty('monthlyAmount')) {
            monthlyAmount = account.monthlyAmount.val;
          }
          monthlyMinimum = 0;
          if (account.hasOwnProperty('monthlyMinimum')) {
            monthlyMinimum = account.monthlyMinimum.val;
          }
          employerContribution = 0;
          if (account.hasOwnProperty('employerContribution')) {
            employerContribution = account.employerContribution.val;
          }
          accountValue = 0;
          if (account.hasOwnProperty('accountValue')) {
            accountValue = account.accountValue.val;
          }
          interest = 0;
          if (account.hasOwnProperty('interest')) {
            interest = account.interest.val;
          }
          guidelineCAFR = 0;
          if (account.hasOwnProperty('guidelineCAFR')) {
            guidelineCAFR = account.guidelineCAFR.val;
          }
          actualCAFR = 0;
          if (account.hasOwnProperty('actualCAFR')) {
            actualCAFR = account.actualCAFR.val;
          }
          haveBudgetSubcategory = false;
          if (account.hasOwnProperty('budgetSubcategory')) {
            haveBudgetSubcategory = account.budgetSubcategory.val !== '';
          }

          const funcItem = {
            curYearMonth: x,
            accountName,
            interestRate,
            monthlyMinimum,
            monthlyAmount,
            employerContribution,
            accountValue,
            interest,
            guidelineCAFR,
            actualCAFR,
            haveBudgetSubcategory
          };

          const item2 = {
            curYearMonth: x.substr(0, 4) + x.substr(5, 2),
            category: 'assetProtection',
            accountName,
            interestRate,
            monthlyMinimum,
            monthlyAmount,
            employerContribution,
            accountValue,
            interest,
            guidelineCAFR,
            actualCAFR,
            haveBudgetSubcategory
          };
          this.CAFRprojectionDetails.push(item2);

          // TODO figure out the stuff below
          /*  this is not used?
          // establish object to hold information for each account

          // this is version in use
          if (!cafrDataProjectionInfo.assets.details.hasOwnProperty(accountName)) {
            cafrDataProjectionInfo.assets.details[accountName] = { projection: [] };
          }
          cafrDataProjectionInfo.assets.details[accountName].projection.push(item);

          // this should be the correct version?
          if (!cafrDataProjectionInfo.assetProtection.details.hasOwnProperty(accountName)) {
            cafrDataProjectionInfo.assetProtection.details[accountName] = { projection: [] };
          }
          cafrDataProjectionInfo.assetProtection.details[accountName].projection.push(item);
          */
        } // for actndx
      } // if
    } // for subcat of assetProtection

    // post liabilities detail results into data structure
    const liabilities = curPlan.liabilities;
    for (const subcat of Object.keys(liabilities)) {
      if (skipSubcatAttributes.indexOf(subcat) === -1) {
        for (const account of liabilities[subcat].accounts) {
          // extract asset data
          let accountName = 'Unknown';
          if (account.hasOwnProperty('accountName')) {
            accountName = account.accountName.val;
          }
          interestRate = 0;
          if (account.hasOwnProperty('interestRate')) {
            interestRate = account.interestRate.val;
          }
          monthlyAmount = 0;
          if (account.hasOwnProperty('monthlyAmount')) {
            monthlyAmount = account.monthlyAmount.val;
          }
          monthlyMinimum = 0;
          if (account.hasOwnProperty('monthlyMinimum')) {
            monthlyMinimum = account.monthlyMinimum.val;
          }
          employerContribution = 0;
          if (account.hasOwnProperty('employerContribution')) {
            employerContribution = account.employerContribution.val;
          }
          accountValue = 0;
          if (account.hasOwnProperty('accountValue')) {
            accountValue = account.accountValue.val;
          }
          interest = 0;
          if (account.hasOwnProperty('interest')) {
            interest = account.interest.val;
          }
          guidelineCAFR = 0;
          if (account.hasOwnProperty('guidelineCAFR')) {
            guidelineCAFR = account.guidelineCAFR.val;
          }
          actualCAFR = 0;
          if (account.hasOwnProperty('actualCAFR')) {
            actualCAFR = account.actualCAFR.val;
          }
          haveBudgetSubcategory = false;
          if (account.hasOwnProperty('budgetSubcategory')) {
            haveBudgetSubcategory = account.budgetSubcategory.val !== '';
          }

          const funcItem = {
            curYearMonth: x,
            accountName,
            interestRate,
            monthlyMinimum,
            monthlyAmount,
            employerContribution,
            accountValue,
            interest,
            guidelineCAFR,
            actualCAFR,
            haveBudgetSubcategory
          };

          const item2 = {
            curYearMonth: x.substr(0, 4) + x.substr(5, 2),
            category: 'liabilities',
            accountName,
            interestRate,
            monthlyMinimum,
            monthlyAmount,
            employerContribution,
            accountValue,
            interest,
            guidelineCAFR,
            actualCAFR,
            haveBudgetSubcategory
          };
          this.CAFRprojectionDetails.push(item2);

          // establish object to hold information for each account
          if (account.productivity && account.productivity.val === 'Non-productive') {
            if (!cafrDataProjectionInfo.nonproductiveDebt.details.hasOwnProperty(accountName)) {
              cafrDataProjectionInfo.nonproductiveDebt.details[accountName] = {
                projection: []
              };
            }
            cafrDataProjectionInfo.nonproductiveDebt.details[accountName].projection.push(funcItem);
          } else {
            if (!cafrDataProjectionInfo.productiveDebt.details.hasOwnProperty(accountName)) {
              cafrDataProjectionInfo.productiveDebt.details[accountName] = {
                projection: []
              };
            }
            cafrDataProjectionInfo.productiveDebt.details[accountName].projection.push(funcItem);
          }
        } // for actndx
      } // if
    } // for subcat of productiveDebt
  } // postData

  public addInterest(category: any): void {
    let accountName: string;
    let accountValue: number;
    let interest: number;
    let interestRate: number;
    let haveAccountValue, haveInterestRate: boolean;

    for (const subcat of Object.keys(category)) {
      if (skipSubcatAttributes.indexOf(subcat) === -1) {
        for (let actndx = 0; actndx < category[subcat].accounts.length; actndx++) {
          // gather values necessary to do work
          accountName = 'unknown';
          if (category[subcat].accounts[actndx].hasOwnProperty('accountName')) {
            accountName = category[subcat].accounts[actndx].accountName.val;
          }

          // initialize a new field to capture the interested paid
          if (
            !category.hasOwnProperty(subcat) ||
            !category[subcat].hasOwnProperty('accounts') ||
            !category[subcat].accounts.hasOwnProperty(actndx) ||
            !category[subcat].accounts[actndx].hasOwnProperty('interest') ||
            !category[subcat].accounts[actndx].interest.hasOwnProperty('val')
          ) {
            category[subcat].accounts[actndx].interest = { val: 0 };
          }

          // gather values necessary to do work
          accountValue = 0;
          haveAccountValue = false;
          if (category[subcat].accounts[actndx].hasOwnProperty('accountValue')) {
            accountValue = category[subcat].accounts[actndx].accountValue.val;
            haveAccountValue = true;
          }
          interestRate = 0;
          haveInterestRate = false;
          if (category[subcat].accounts[actndx].hasOwnProperty('interestRate')) {
            interestRate = category[subcat].accounts[actndx].interestRate.val;
            haveInterestRate = true;
          }

          // adjust account value
          if (haveAccountValue && haveInterestRate) {
            interest = accountValue * ((0.01 * interestRate) / 12);
            if (category[subcat].accounts[actndx].accountValue) {
              category[subcat].accounts[actndx].accountValue.val = accountValue + interest;
              category[subcat].accounts[actndx].interest.val = interest;
            }
          }
        } // for actndx
      } // if
    } // for subcat
  } // addInterest

  public adjustFieldValues(curYearMonth: IYearMonth, cafrInfo: any, category: any, method: string, extraGuideLineCAFR: any): void {
    let accountName: string;
    let monthlyAmount: number;
    let monthlyMinimum: number;
    let accountValue: number;
    let employerContribution: number;
    let interestRate: number;
    let interest: number;
    let haveMonthlyAmount = false;
    let haveMonthlyMinimum = false;
    let haveBudgetSubcategory = false;
    let haveAccountValue = false;
    let step: number;
    let newMonthlyAmount: number;
    let newAccountValue: number;
    const wantZeroMonthlyAmount = false;
    const wantZeroMonthlyMinimum = false;
    let isNonProductiveDebt: boolean;
    let expirationDate: string;
    let haveExpirationDate: boolean;
    let targetAmount, currentAmount: number;

    for (const subcat of Object.keys(category)) {
      if (skipSubcatAttributes.indexOf(subcat) === -1) {
        for (let actndx = 0; actndx < category[subcat].accounts.length; actndx++) {
          // get target amount
          targetAmount = category[subcat].accounts[actndx].targetAmount ? category[subcat].accounts[actndx].targetAmount.val : 0;

          // gather values necessary to do work
          accountName = 'unknown';
          if (category[subcat].accounts[actndx].hasOwnProperty('accountName')) {
            accountName = category[subcat].accounts[actndx].accountName.val;
          }
          isNonProductiveDebt =
            category[subcat].accounts[actndx].productivity && category[subcat].accounts[actndx].productivity.val === 'Non-productive';
          monthlyAmount = 0;
          haveMonthlyAmount = false;
          if (category[subcat].accounts[actndx].hasOwnProperty('monthlyAmount')) {
            monthlyAmount = category[subcat].accounts[actndx].monthlyAmount.val;
            haveMonthlyAmount = true;
          }
          monthlyMinimum = 0;
          haveMonthlyMinimum = false;
          if (category[subcat].accounts[actndx].hasOwnProperty('monthlyMinimum')) {
            monthlyMinimum = category[subcat].accounts[actndx].monthlyMinimum.val;
            haveMonthlyMinimum = true;
          }
          employerContribution = 0;
          if (category[subcat].accounts[actndx].hasOwnProperty('employerContribution')) {
            employerContribution = category[subcat].accounts[actndx].employerContribution.val;
          }
          interestRate = 0;
          if (category[subcat].accounts[actndx].hasOwnProperty('interestRate')) {
            interestRate = category[subcat].accounts[actndx].interestRate.val;
          }
          accountValue = 0;
          haveAccountValue = false;
          if (category[subcat].accounts[actndx].hasOwnProperty('accountValue')) {
            accountValue = category[subcat].accounts[actndx].accountValue.val;
            haveAccountValue = true;
          }
          haveExpirationDate = false;
          if (category[subcat].accounts[actndx].hasOwnProperty('expirationDate')) {
            expirationDate = category[subcat].accounts[actndx].expirationDate.val;
            haveExpirationDate = true;
          }
          haveBudgetSubcategory = false;
          if (category[subcat].accounts[actndx].hasOwnProperty('budgetSubcategory')) {
            haveBudgetSubcategory = category[subcat].accounts[actndx].budgetSubcategory.val !== '';
          }

          // assume here that step will be assigned a value in the following code
          if (category.attributeName === 'assets') {
            if (subcat === 'emergencySavings') {
              step = 1;
            } else if (subcat === 'cashReserves') {
              step = 3;
            } else {
              step = 4;
            }
          }
          if (category.attributeName === 'assetProtection') {
            step = 4;
          }
          if (category.attributeName === 'liabilities') {
            if (isNonProductiveDebt) {
              step = 2;
            } else {
              step = 4;
            }
          }

          // adjust account value
          if (haveAccountValue) {
            interest = accountValue * ((0.01 * interestRate) / 12);
            if (category.attributeName === 'assets' || category.attributeName === 'assetProtection') {
              // handle asset
              newAccountValue = accountValue + interest + employerContribution;

              if (
                !cafrInfo.hasOwnProperty(step) ||
                !cafrInfo[step].hasOwnProperty(category.attributeName) ||
                !cafrInfo[step][category.attributeName].hasOwnProperty(subcat) ||
                !cafrInfo[step][category.attributeName][subcat].hasOwnProperty('accounts') ||
                !cafrInfo[step][category.attributeName][subcat].accounts.hasOwnProperty(accountName)
              ) {
                // TODO evaluate the need for this message when code and data is stable
                console.log(
                  'In adjustFieldValues, guidelineCAFR does not exist for ' + step + ',' + category.attributeName + ',' + subcat + ',' + accountName
                );
              } else {
                // set monthlyAmount to match guideline CAFR
                if (method === 'guideline') {
                  newAccountValue = newAccountValue + cafrInfo[step][category.attributeName][subcat].accounts[accountName].guidelineCAFR;
                  // record guidelineCAFR
                  if (
                    !category.hasOwnProperty(subcat) ||
                    !category[subcat].hasOwnProperty('accounts') ||
                    !category[subcat].accounts.hasOwnProperty(actndx) ||
                    !category[subcat].accounts[actndx].hasOwnProperty('guidelineCAFR') ||
                    !category[subcat].accounts[actndx].guidelineCAFR.hasOwnProperty('val')
                  ) {
                    category[subcat].accounts[actndx].guidelineCAFR = {
                      val: cafrInfo[step][category.attributeName][subcat].accounts[accountName].guidelineCAFR
                    };
                  } else {
                    category[subcat].accounts[actndx].guidelineCAFR.val =
                      cafrInfo[step][category.attributeName][subcat].accounts[accountName].guidelineCAFR;
                  }
                } else {
                  // only apply CAFR above the monthlyAmount, otherwise CAFR will be double-counted
                  newAccountValue = newAccountValue + cafrInfo[step][category.attributeName][subcat].accounts[accountName].actualCAFR;

                  // record actualCAFR
                  if (
                    !category.hasOwnProperty(subcat) ||
                    !category[subcat].hasOwnProperty('accounts') ||
                    !category[subcat].accounts.hasOwnProperty(actndx) ||
                    !category[subcat].accounts[actndx].hasOwnProperty('actualCAFR') ||
                    !category[subcat].accounts[actndx].actualCAFR.hasOwnProperty('val')
                  ) {
                    category[subcat].accounts[actndx].actualCAFR = {
                      val: cafrInfo[step][category.attributeName][subcat].accounts[accountName].actualCAFR
                    };
                  } else {
                    category[subcat].accounts[actndx].actualCAFR.val =
                      cafrInfo[step][category.attributeName][subcat].accounts[accountName].actualCAFR;
                  }
                }
              }

              // set account value
              if (
                !category.hasOwnProperty(subcat) ||
                !category[subcat].hasOwnProperty('accounts') ||
                !category[subcat].accounts.hasOwnProperty(actndx) ||
                !category[subcat].accounts[actndx].hasOwnProperty('accountValue') ||
                !category[subcat].accounts[actndx].accountValue.hasOwnProperty('val')
              ) {
                if (!isNaN(category[subcat].accounts[actndx].accountValue)) {
                  // @TODO - what should we do in this case?!
                  // console.log("account value", category[subcat].accounts[actndx].accountValue)
                } else {
                  // TODO evaluate the need for this message when code and data is stable
                  /* console.log(
                    "In adjustFieldValues, accountValue.val does not exist for " +
                    category.attributeName +
                    "," +
                    subcat +
                    "," +
                    actndx +
                    "(" +
                    accountName +
                    ")"
                  ); */
                }
              } else {
                // set accountValue based on above algorithm
                category[subcat].accounts[actndx].accountValue.val = newAccountValue;
              }

              // if subcategory total >= target, clear minimum and monthly
              currentAmount = this.dataModelService.getSubcategorySum(category, subcat, 'accountValue');
              if (targetAmount > 0 && currentAmount >= targetAmount) {
                // set monthlyMinimum to 0
                if (
                  !category.hasOwnProperty(subcat) ||
                  !category[subcat].hasOwnProperty('accounts') ||
                  !category[subcat].accounts.hasOwnProperty(actndx) ||
                  !category[subcat].accounts[actndx].hasOwnProperty('monthlyMinimum') ||
                  !category[subcat].accounts[actndx].monthlyMinimum.hasOwnProperty('val')
                ) {
                  // TODO evaluate the need for this message when code and data is stable
                  // console.log('In adjustFieldValues, monthlyMinimum.val does not exist for ' +
                  // 	category.attributeName + ',' + subcat + ',' + actndx + '(' + accountName + ')');
                } else {
                  if (haveBudgetSubcategory && method === 'guideline') {
                    extraGuideLineCAFR.extra += category[subcat].accounts[actndx].monthlyMinimum.val;
                  }
                  category[subcat].accounts[actndx].monthlyMinimum.val = 0;
                }

                // set monthlyAmount to 0
                if (
                  !category.hasOwnProperty(subcat) ||
                  !category[subcat].hasOwnProperty('accounts') ||
                  !category[subcat].accounts.hasOwnProperty(actndx) ||
                  !category[subcat].accounts[actndx].hasOwnProperty('monthlyAmount') ||
                  !category[subcat].accounts[actndx].monthlyAmount.hasOwnProperty('val')
                ) {
                  // TODO evaluate the need for this message when code and data is stable
                  // console.log('In adjustFieldValues, monthlyAmount.val does not exist for ' +
                  // 	category.attributeName + ',' + subcat + ',' + actndx + '(' + accountName + ')');
                } else {
                  category[subcat].accounts[actndx].monthlyAmount.val = 0;
                }
              }
            } else {
              // handle liability
              newMonthlyAmount = 0;
              if (
                !cafrInfo.hasOwnProperty(step) ||
                !cafrInfo[step].hasOwnProperty(category.attributeName) ||
                !cafrInfo[step][category.attributeName].hasOwnProperty(subcat) ||
                !cafrInfo[step][category.attributeName][subcat].hasOwnProperty('accounts') ||
                !cafrInfo[step][category.attributeName][subcat].accounts.hasOwnProperty(accountName)
              ) {
                // TODO evaluate the need for this message when code and data is stable
                console.log(
                  'In adjustFieldValues, guidelineCAFR does not exist for ' + step + ',' + category.attributeName + ',' + subcat + ',' + accountName
                );
              } else {
                // set monthlyAmount to match guideline CAFR
                if (method === 'guideline') {
                  newMonthlyAmount = cafrInfo[step][category.attributeName][subcat].accounts[accountName].guidelineCAFR;
                } else {
                  // only apply CAFR above the monthlyAmount, otherwise CAFR will be double-counted
                  newMonthlyAmount = cafrInfo[step][category.attributeName][subcat].accounts[accountName].actualCAFR;
                }
              }

              // add monthlyMinimum which comes out of the "budget"
              if (haveBudgetSubcategory && haveMonthlyMinimum) {
                newMonthlyAmount += category[subcat].accounts[actndx].monthlyMinimum.val;
              }

              if (
                !category.hasOwnProperty(subcat) ||
                !category[subcat].hasOwnProperty('accounts') ||
                !category[subcat].accounts.hasOwnProperty(actndx) ||
                !category[subcat].accounts[actndx].hasOwnProperty('monthlyAmount') ||
                !category[subcat].accounts[actndx].monthlyAmount.hasOwnProperty('val')
              ) {
                // TODO evaluate the need for this message when code and data is stable
                console.log(
                  'In adjustFieldValues, monthlyAmount.val does not exist for ' +
                    category.attributeName +
                    ',' +
                    subcat +
                    ',' +
                    actndx +
                    '(' +
                    accountName +
                    ')'
                );
              } else {
                category[subcat].accounts[actndx].monthlyAmount.val = newMonthlyAmount;
              }

              if (method === 'guideline') {
                // record guidelineCAFR
                if (
                  !category.hasOwnProperty(subcat) ||
                  !category[subcat].hasOwnProperty('accounts') ||
                  !category[subcat].accounts.hasOwnProperty(actndx) ||
                  !category[subcat].accounts[actndx].hasOwnProperty('guidelineCAFR') ||
                  !category[subcat].accounts[actndx].guidelineCAFR.hasOwnProperty('val')
                ) {
                  category[subcat].accounts[actndx].guidelineCAFR = {
                    val: cafrInfo[step][category.attributeName][subcat].accounts[accountName].guidelineCAFR
                  };
                } else {
                  category[subcat].accounts[actndx].guidelineCAFR.val =
                    cafrInfo[step][category.attributeName][subcat].accounts[accountName].guidelineCAFR;
                }
              } else {
                // record actualCAFR
                if (
                  !category.hasOwnProperty(subcat) ||
                  !category[subcat].hasOwnProperty('accounts') ||
                  !category[subcat].accounts.hasOwnProperty(actndx) ||
                  !category[subcat].accounts[actndx].hasOwnProperty('actualCAFR') ||
                  !category[subcat].accounts[actndx].actualCAFR.hasOwnProperty('val')
                ) {
                  category[subcat].accounts[actndx].actualCAFR = {
                    val: cafrInfo[step][category.attributeName][subcat].accounts[accountName].actualCAFR
                  };
                } else {
                  category[subcat].accounts[actndx].actualCAFR.val = cafrInfo[step][category.attributeName][subcat].accounts[accountName].actualCAFR;
                }
              }
              newAccountValue = accountValue + interest - category[subcat].accounts[actndx].monthlyAmount.val;
              if (newAccountValue <= 0) {
                // set accountValue to 0, cannot go negative
                newAccountValue = 0;

                // set monthlyMinimum to 0
                if (
                  !category.hasOwnProperty(subcat) ||
                  !category[subcat].hasOwnProperty('accounts') ||
                  !category[subcat].accounts.hasOwnProperty(actndx) ||
                  !category[subcat].accounts[actndx].hasOwnProperty('monthlyMinimum') ||
                  !category[subcat].accounts[actndx].monthlyMinimum.hasOwnProperty('val')
                ) {
                  // TODO evaluate the need for this message when code and data is stable
                  console.log(
                    'In adjustFieldValues, monthlyMinimum.val does not exist for ' +
                      category.attributeName +
                      ',' +
                      subcat +
                      ',' +
                      actndx +
                      '(' +
                      accountName +
                      ')'
                  );
                } else {
                  if (haveBudgetSubcategory && method === 'guideline') {
                    extraGuideLineCAFR.extra += category[subcat].accounts[actndx].monthlyMinimum.val;
                  }
                  category[subcat].accounts[actndx].monthlyMinimum.val = 0;
                }

                // set monthlyAmount to 0
                if (
                  !category.hasOwnProperty(subcat) ||
                  !category[subcat].hasOwnProperty('accounts') ||
                  !category[subcat].accounts.hasOwnProperty(actndx) ||
                  !category[subcat].accounts[actndx].hasOwnProperty('monthlyAmount') ||
                  !category[subcat].accounts[actndx].monthlyAmount.hasOwnProperty('val')
                ) {
                  // TODO evaluate the need for this message when code and data is stable
                  console.log(
                    'In adjustFieldValues, monthlyAmount.val does not exist for ' +
                      category.attributeName +
                      ',' +
                      subcat +
                      ',' +
                      actndx +
                      '(' +
                      accountName +
                      ')'
                  );
                } else {
                  category[subcat].accounts[actndx].monthlyAmount.val = 0;
                }
              }
              // set account value
              if (
                !category.hasOwnProperty(subcat) ||
                !category[subcat].hasOwnProperty('accounts') ||
                !category[subcat].accounts.hasOwnProperty(actndx) ||
                !category[subcat].accounts[actndx].hasOwnProperty('accountValue') ||
                !category[subcat].accounts[actndx].accountValue.hasOwnProperty('val')
              ) {
                // TODO evaluate the need for this message when code and data is stable
                console.log(
                  'In adjustFieldValues, accountValue.val does not exist for ' +
                    category.attributeName +
                    ',' +
                    subcat +
                    ',' +
                    actndx +
                    '(' +
                    accountName +
                    ')'
                );
              } else {
                // set accountValue based on above algorithm
                category[subcat].accounts[actndx].accountValue.val = newAccountValue;
              }
            }
            // %//   \/
            if (this.showDebug7) {
              // update CAFR schedule information
              if (method === 'guideline') {
                const item = {
                  curYYYYMM: this.yearMonth2YYYYMM(curYearMonth),
                  step,
                  CAFRamount: cafrInfo[step][category.attributeName][subcat].accounts[accountName].guidelineCAFR,
                  accountValue: newAccountValue,
                  accountName: category.attributeName + '-' + accountName + '-adjustFieldValues'
                };
                this.CAFRscheduleDetails.guideline.push(item);
              } else {
                const item = {
                  curYYYYMM: this.yearMonth2YYYYMM(curYearMonth),
                  step,
                  CAFRamount: cafrInfo[step][category.attributeName][subcat].accounts[accountName].actualCAFR,
                  accountValue: newAccountValue,
                  accountName: category.attributeName + '-' + accountName + '-adjustFieldValues'
                };
                this.CAFRscheduleDetails.actual.push(item);
              }
            }
            // %//   /\
          }
          try {
            // record interest value
            if (
              !category.hasOwnProperty(subcat) ||
              !category[subcat].hasOwnProperty('accounts') ||
              !category[subcat].accounts.hasOwnProperty(actndx) ||
              !category[subcat].accounts[actndx].hasOwnProperty('interest') ||
              !category[subcat].accounts[actndx].interest.hasOwnProperty('val')
            ) {
              category[subcat].accounts[actndx].interest = { val: interest };
            } else {
              category[subcat].accounts[actndx].interest.val = interest;
            }

            // initialize a new field to capture the calculated sum of all interested paid
            if (
              !category.hasOwnProperty(subcat) ||
              !category[subcat].hasOwnProperty('accounts') ||
              !category[subcat].accounts.hasOwnProperty(actndx) ||
              !category[subcat].accounts[actndx].hasOwnProperty('sumInterest') ||
              !category[subcat].accounts[actndx].sumInterest.hasOwnProperty('val')
            ) {
              category[subcat].accounts[actndx].sumInterest = { val: interest };
            } else {
              category[subcat].accounts[actndx].sumInterest.val += interest;
            }
          } catch (e) {
            console.log((e as Error).message); // conversion to Error type
          }
        } // for actndx
      } // if want this subcat
    } // for subcat
  } // adjustFieldValues

  // function to handle number of iterations to perform
  public needMoreData(terminationCriteria, month, cafrDataProjectionInfo) {
    let wantMoreData = false;
    if (terminationCriteria.terminationType === 'monthCount') {
      if (month <= terminationCriteria.terminationValue) {
        wantMoreData = true;
      }
    } else {
      const maxMonths = 1200; // assume at most 100 years of data projection is attempted
      const lastIndex = cafrDataProjectionInfo.adjustedNetWorth.summary.projection.length - 1;
      const adjustedNetValue = cafrDataProjectionInfo.adjustedNetWorth.summary.projection[lastIndex].y;
      if (month <= maxMonths && adjustedNetValue < terminationCriteria.terminationValue) {
        wantMoreData = true;
      }
    }
    return wantMoreData;
  } // needMoreData

  public setBeforePlanEditCafrDataProjectionInfo(terminationCriteria: any, method: string): any {
    this.beforePlanEditCafrDataProjectionInfo = this.getCafrDataProjectionInfo(terminationCriteria, method);
  }

  public getCafrDataProjectionInfo(terminationCriteria: any, method: string, plan: string = null): any {
    // initialize
    let curYearMonth: IYearMonth; // used to identify date of data item in projection data
    let curplanIdentifier: string; // identifies current plan being used from the data model
    let curPlan: any; // object that contains all categories of information for current plan being processed
    let prevPlan: any; // object that contains all categories of information for previous plan being processed
    let prevStep4CAFR: number;
    let step4CAFRsum: { sum: number }; // this is an object so value can be returned through a function parameter
    let unusedCAFR: number; // amount of actual cafr that has not been allocated to the WizeFi plan
    let calcUnusedCAFR = false; // flag to designate when to calculate unusedCAFR

    // initialize the data structures that hold schedule data
    this.initializeScheduleData();

    const cafrDataProjectionInfo = {
      productiveAssets: {
        details: {},
        summary: { projection: [] },
        interest: 0
      },
      nonproductiveAssets: {
        details: {},
        summary: { projection: [] },
        interest: 0
      },
      assets: {
        details: {},
        summary: { projection: [] },
        interest: 0
      },
      productiveDebt: {
        details: {},
        summary: { projection: [] },
        interest: 0
      },
      nonproductiveDebt: {
        details: {},
        summary: { projection: [] },
        interest: 0
      },
      allDebt: {
        details: {},
        summary: { projection: [] },
        interest: 0
      },
      netWorth: {
        summary: { projection: [] },
        interest: 0
      },
      adjustedNetWorth: {
        summary: { projection: [] },
        interest: 0
      }
    };

    // set the starting YearMonth
    curplanIdentifier = this.dataModelService.dataModel.persistent.header.curplan;
    if (plan) {
      curplanIdentifier = plan;
    }
    if (curplanIdentifier === 'original') {
      const dateCreated = this.dataModelService.dataModel.persistent.header.dateCreated;
      curYearMonth = {
        year: Number(dateCreated.substr(0, 4)),
        month: Number(dateCreated.substr(5, 2)) - 1 // note: 0 <= month <= 11 to accomodate JavaScript conventions
      };
    } else {
      // plan format: pYYYYMM
      curYearMonth = {
        year: Number(curplanIdentifier.substr(1, 4)),
        month: Number(curplanIdentifier.substr(5, 2)) - 1
      };
    }

    // set curplan to the plan that is in effect when this function is invoked (use clone to protect original data)
    // NOTE: it is assumed that current screen data model has been copied into application data model
    curPlan = JSON.parse(JSON.stringify(this.dataModelService.dataModel.persistent.plans[curplanIdentifier]));

    // %//   \/
    // set initial values in CAFRscheduleDetails
    this.captureInitialAccountStatus(
      method,
      curYearMonth,
      curPlan.income,
      curPlan.budget,
      curPlan.assets,
      curPlan.assetProtection,
      curPlan.liabilities
    );
    // %//   /\

    // %//   \/
    if (this.showDebug5) {
      console.log(' ');
      console.log('getCafrDataProjectionInfo');
    }
    // %//   /\

    // initialize goalDates
    this.initializeGoalDates(method, curYearMonth, curPlan);

    // post graph data derived from curPlan (post data into first month of cafrDataProjectionInfo)
    step4CAFRsum = { sum: 0 };
    prevStep4CAFR = 0;
    unusedCAFR = 0;
    this.postData(curYearMonth, method, curPlan, cafrDataProjectionInfo, step4CAFRsum, prevStep4CAFR);

    // generate data for each subsequent month
    const extraGuideLineCAFR = { extra: 0 }; // use object to enable return value from function
    let month = 2;
    while (this.needMoreData(terminationCriteria, month, cafrDataProjectionInfo)) {
      // advance to next plan to process
      prevPlan = curPlan;
      curPlan = JSON.parse(JSON.stringify(prevPlan)); // clone prevPlan

      // advance to next YearMonth
      this.nextYearMonth(curYearMonth);

      // add interest amounts to accountValue for the previous plan
      // Note: in general, this has the side effect of reducing the guidelineCAFR a bit due to the increased debt from interest
      this.addInterest(prevPlan.assets);
      this.addInterest(prevPlan.liabilities);

      calcUnusedCAFR = month === 2 ? true : false;

      // generate guidelineCAFR from the previous plan
      const cafrInfo = this.getCafrInfo(
        method,
        this.yearMonth2YYYYMM(curYearMonth),
        prevPlan.income,
        prevPlan.budget,
        prevPlan.assets,
        prevPlan.assetProtection,
        prevPlan.liabilities,
        calcUnusedCAFR,
        unusedCAFR,
        extraGuideLineCAFR
      );
      if (method === 'guideline') {
        prevStep4CAFR = cafrInfo[4].guidelineCAFR;
      } else {
        prevStep4CAFR =
          cafrInfo[4].actualCAFR - (cafrInfo[4].assets.actualCAFR + cafrInfo[4].assetProtection.actualCAFR - cafrInfo[4].liabilities.actualCAFR);
        unusedCAFR = cafrInfo.unusedCAFR;
      }

      // modify curPlan information based on CAFR information from prevPlan,
      // and compute modified accountValues for curPlan
      if (this.showDebug5) {
        console.log(' ');
      } // %//
      this.adjustFieldValues(curYearMonth, cafrInfo, curPlan.assets, method, extraGuideLineCAFR);
      this.adjustFieldValues(curYearMonth, cafrInfo, curPlan.assetProtection, method, extraGuideLineCAFR);
      this.adjustFieldValues(curYearMonth, cafrInfo, curPlan.liabilities, method, extraGuideLineCAFR);

      // post graph data derived from curplan (post data into current month of cafrDataProjectionInfo)
      this.postData(curYearMonth, method, curPlan, cafrDataProjectionInfo, step4CAFRsum, prevStep4CAFR);

      // advance to next month
      month++;
    } // while needMoreData

    cafrDataProjectionInfo.nonproductiveDebt.interest = this.getProjectionSum(curPlan.liabilities, 'sumInterest', true);
    cafrDataProjectionInfo.productiveDebt.interest = this.getProjectionSum(curPlan.liabilities, 'sumInterest', false);
    cafrDataProjectionInfo.allDebt.interest = cafrDataProjectionInfo.nonproductiveDebt.interest + cafrDataProjectionInfo.productiveDebt.interest;

    // create data for subsequent processing
    this.CAFRscheduleSummary = this.createCAFRscheduleSummary(method, this.CAFRscheduleDetails);
    if (method === 'actual') {
      this.projectionsSummary = this.createProjectionsSummary(cafrDataProjectionInfo);
    }

    if (method === 'guideline') {
      this.CAFRscheduleDetails.guideline.sort(this.compareScheduleItems);
    } else {
      this.CAFRscheduleDetails.actual.sort(this.compareScheduleItems);
    }

    // show goal dates
    // console.log('goalDates: ', this.goalDates);  //%//

    return cafrDataProjectionInfo;
  } // getCafrDataProjectionInfo

  public getCafrDataProjection(whichData: string, numMonths: number, method: string, ignoreCache: boolean = false): any {
    console.log('run getCafrDataProjection');
    // initialize
    let result = [];
    let dataProjectionInfo: any;

    console.log('ignoreCache', ignoreCache);

    // do the following just once rather than for each successive call to the function
    if (method === 'guideline') {
      if (!this.privateCafrDataGuidelineProjectionInfo || ignoreCache === true) {
        this.privateCafrDataGuidelineProjectionInfo = this.getCafrDataProjectionInfo(
          { terminationType: 'monthCount', terminationValue: numMonths },
          method
        );
      }
      dataProjectionInfo = this.privateCafrDataGuidelineProjectionInfo;
    } else {
      if (!this.privateCafrDataActualProjectionInfo || ignoreCache === true) {
        this.privateCafrDataActualProjectionInfo = this.getCafrDataProjectionInfo(
          { terminationType: 'monthCount', terminationValue: numMonths },
          method
        );
      }
      dataProjectionInfo = this.privateCafrDataActualProjectionInfo;
    }

    if (['assets', 'productiveDebt', 'nonproductiveDebt', 'allDebt', 'netWorth'].indexOf(whichData) !== -1) {
      console.log('projection data', dataProjectionInfo[whichData]);
      result = dataProjectionInfo[whichData]; // .summary.projection
    } else {
      console.log('failed projection data', dataProjectionInfo[whichData]);
    }

    // show goalDates info (for debug)  //%//
    console.log('goalDates: ', this.goalDates); // %//

    return result;
  } // getCafrDataProjection

  /////////////////////////////////////////////////////////
  // routines to handle CAFR schedule information
  /////////////////////////////////////////////////////////

  public changeDate(ISOdate, years, months, days) {
    const m = moment(ISOdate, ['YYYY', 'YYYY-MM', 'YYYY-MM-DD']);
    /*  const m = moment.utc(
      moment(ISOdate, ["YYYY", "YYYY-MM", "YYYY-MM-DD"]).format()
    ); // identify expected input formats */
    m.add({ years, months, days });
    return m.format();
  } // changeDate

  public captureInitialAccountStatus(
    method: string,
    curYearMonth: IYearMonth,
    income: any,
    budget: any,
    assets: any,
    assetProtection: any,
    liabilities: any
  ): void {
    let category: any;
    let accountName: string;
    let accountValue: number;
    let item: any;
    const curYYYYMM = this.yearMonth2YYYYMM(curYearMonth);

    const totalIncome = this.dataModelService.getCategorySum(income, 'monthlyAmount', assets, liabilities, assetProtection);
    const totalBudget = this.dataModelService.getCategorySum(budget, 'monthlyAmount', assets, liabilities, assetProtection);
    const availableGuidelineCAFR = 0.2 * totalIncome;
    const availableActualCAFR = totalIncome - totalBudget;

    // %//  \/
    if (this.showDebug6 || this.showDebug7) {
      // update CAFR schedule information
      if (method === 'guideline') {
        item = {
          curYYYYMM,
          step: -1,
          CAFRamount: availableGuidelineCAFR,
          accountValue: 0,
          accountName: 'availableGuidelineCAFR-captureInitialAccountStatus'
        };
        this.CAFRscheduleDetails.guideline.push(item);
      } else {
        item = {
          curYYYYMM,
          step: -1,
          CAFRamount: availableActualCAFR,
          accountValue: 0,
          accountName: 'availableActualCAFR-captureInitialAccountStatus'
        };
        this.CAFRscheduleDetails.actual.push(item);
      }
    }
    // %//  /\

    // update CAFRcalculationDetails
    if (method === 'guideline') {
      this.CAFRcalculationDetails.guideline.push({
        curYYYYMM,
        step: -1,
        monthlyMinimum: 0,
        monthlyAmount: 0,
        haveBudgetSubcategory: 'x',
        CAFRamount: availableGuidelineCAFR,
        accountValue: 0,
        accountName: 'availableGuidelineCAFR-captureInitialAccountStatus'
      });
    } else {
      this.CAFRcalculationDetails.actual.push({
        curYYYYMM,
        step: -1,
        monthlyMinimum: 0,
        monthlyAmount: 0,
        haveBudgetSubcategory: 'x',
        CAFRamount: availableActualCAFR,
        accountValue: 0,
        accountName: 'availableActualCAFR-captureInitialAccountStatus'
      });
    }

    // iterate over categories (assets,assetProtection,liabilities)
    for (let catnum = 1; catnum <= 3; catnum++) {
      switch (catnum) {
        case 1:
          category = assets;
          break;
        case 2:
          category = assetProtection;
          break;
        case 3:
          category = liabilities;
          break;
      } // switch

      for (const subcat of Object.keys(category)) {
        if (skipSubcatAttributes.indexOf(subcat) === -1) {
          for (const account of category[subcat].accounts) {
            // gather values necessary to do work
            accountName = 'unknown';
            if (account.hasOwnProperty('accountName')) {
              accountName = account.accountName.val;
            }
            accountValue = 0;
            if (account.hasOwnProperty('accountValue')) {
              accountValue = account.accountValue.val;
            }

            // %//  \/
            if (this.showDebug6 || this.showDebug7) {
              // update CAFR schedule information
              const funcItem = {
                curYYYYMM,
                step: 0,
                CAFRamount: 0,
                accountValue,
                accountName: category.attributeName + '-' + accountName + '-captureInitialAccountStatus'
              };
              this.CAFRscheduleDetails.guideline.push(funcItem);
              this.CAFRscheduleDetails.actual.push(funcItem);
            }
            // %//  /\

            // update CAFRcalculationDetails
            const funcItem2 = {
              curYYYYMM,
              step: 0,
              monthlyMinimum: 0,
              monthlyAmount: 0,
              haveBudgetSubcategory: 'x',
              CAFRamount: 0,
              accountValue,
              accountName: category.attributeName + '-' + accountName + '-captureInitialAccountStatus'
            };
            this.CAFRcalculationDetails.guideline.push(funcItem2);
            this.CAFRcalculationDetails.actual.push(funcItem2);
          } // for actndx
        } // if want this subcat
      } // for subcat
    } // for category
  } // captureInitialAccountStatus

  public compareScheduleItems(v1, v2) {
    const result = v1.curYYYYMM < v2.curYYYYMM || (v1.curYYYYMM === v2.curYYYYMM && v1.step < v2.step);
    return result ? -1 : 1;
  } // compareScheduleItems

  public createCAFRscheduleSummary(method, CAFRscheduleDetails) {
    let CAFRscheduleSummary, ndx, monthCount, totalMonths;
    let step, CAFRamount, CAFRstepsSum, curYYYYMM, item, yrNum;

    // initialize
    CAFRscheduleDetails.actual.sort(this.compareScheduleItems);
    CAFRscheduleSummary = [];

    // process first 12 months as monthly summary
    ndx = 0;
    monthCount = 0;
    totalMonths = 12;
    while (ndx < CAFRscheduleDetails.actual.length && monthCount < totalMonths) {
      // initialize
      CAFRstepsSum = [0, 0, 0, 0, 0];

      // process current month
      curYYYYMM = CAFRscheduleDetails.actual[ndx].curYYYYMM; // current month being processed
      while (ndx < CAFRscheduleDetails.actual.length && CAFRscheduleDetails.actual[ndx].curYYYYMM === curYYYYMM) {
        // set values
        step = CAFRscheduleDetails.actual[ndx].step;
        CAFRamount = CAFRscheduleDetails.actual[ndx].CAFRamount;

        // update CAFR sum
        if (1 <= step && step <= 4) {
          CAFRstepsSum[step] += CAFRamount;
        }

        // advance to next CAFRscheduleDetails item
        ndx++;
      } // while processing current month

      // add item to CAFRscheduleSummary
      item = {
        label: this.monthLabel[curYYYYMM.substr(4, 2)],
        step1CAFR: CAFRstepsSum[1],
        step2CAFR: CAFRstepsSum[2],
        step3CAFR: CAFRstepsSum[3],
        step4CAFR: CAFRstepsSum[4]
      };
      CAFRscheduleSummary.push(item);

      // advance to next month
      monthCount++;
    } // while processing first 12 months

    // process remaining months as yearly summary
    yrNum = 2;
    while (ndx < CAFRscheduleDetails.actual.length) {
      // initialize
      CAFRstepsSum = [0, 0, 0, 0, 0];
      curYYYYMM = CAFRscheduleDetails.actual[ndx].curYYYYMM; // current month being processed

      // determine date of next year after the current one to be processed
      // (note: ISO dates are used in this process in order to use the changeDate function)
      const startISOdate = new Date(curYYYYMM.substr(0, 4) + '-' + curYYYYMM.substr(4, 2));
      const endISOdate = this.changeDate(startISOdate, 1, 0, 0);
      const nextYearYYYYMM = endISOdate.substr(0, 4) + endISOdate.substr(5, 2);

      // process current "fiscal" year
      while (ndx < CAFRscheduleDetails.actual.length && CAFRscheduleDetails.actual[ndx].curYYYYMM < nextYearYYYYMM) {
        // set values
        step = CAFRscheduleDetails.actual[ndx].step;
        CAFRamount = CAFRscheduleDetails.actual[ndx].CAFRamount;

        // update CAFR sum
        if (1 <= step && step <= 4) {
          CAFRstepsSum[step] += CAFRamount;
        }

        // advance to next CAFRscheduleDetails item
        ndx++;
      } // while processing current year

      // add item to CAFRscheduleSummary
      item = {
        label: 'yr ' + yrNum,
        step1CAFR: Math.round(CAFRstepsSum[1]),
        step2CAFR: Math.round(CAFRstepsSum[2]),
        step3CAFR: Math.round(CAFRstepsSum[3]),
        step4CAFR: Math.round(CAFRstepsSum[4])
      };
      CAFRscheduleSummary.push(item);

      // advance to next "fiscal" year
      yrNum++;
    } // while processing remaining months

    return CAFRscheduleSummary;
  } // createCAFRscheduleSummary

  public getCAFRscheduleSummary() {
    return this.CAFRscheduleSummary;
  } // getCAFRscheduleSummary

  public createProjectionsSummary(cafrDataProjectionInfo) {
    let projectionsSummary, ndx, incr, count, yrNum, length, curYYYYMM, label, item;

    // initialize
    projectionsSummary = [];
    ndx = 0;
    incr = 1;
    count = 0;
    yrNum = 2;

    // process the data
    length = cafrDataProjectionInfo.assets.summary.projection.length;
    while (ndx < length) {
      // add item to projectionsSummary
      count++;
      curYYYYMM = this.ISO2YYYYMM(cafrDataProjectionInfo.assets.summary.projection[ndx].x); // current month being processed
      label = count <= 12 ? this.monthLabel[curYYYYMM.substr(4, 2)] : 'yr ' + yrNum;
      item = {
        label,
        assets: Math.round(cafrDataProjectionInfo.assets.summary.projection[ndx].y),
        liabilities: Math.round(cafrDataProjectionInfo.allDebt.summary.projection[ndx].y),
        netWorth: Math.round(cafrDataProjectionInfo.netWorth.summary.projection[ndx].y)
      };
      projectionsSummary.push(item);

      // advance to next month to process
      if (count > 12) {
        yrNum++;
      }
      incr = count < 12 ? 1 : 12;
      if (ndx < length - 1 && ndx + incr > length - 1) {
        incr = length - 1 - ndx;
      } // adjust incr to catch data for last partial last year
      ndx = ndx + incr;
    } // while have more items to process

    return projectionsSummary;
  } // createProjectionsSummary

  public getProjectionsSummary() {
    return this.projectionsSummary;
  } // getProjectionsSummary

  /////////////////////////////////////////////////////////
  // routines to handle goal dates information
  /////////////////////////////////////////////////////////

  public initializeGoalDates(method: string, curYearMonth: IYearMonth, plan: any) {
    const targetAmountSum = (prev, cur) => prev + (cur.targetAmount ? cur.targetAmount.val : 0);
    const et = plan.assets.emergencySavings.accounts.reduce(targetAmountSum, 0);
    const ct = plan.assets.cashReserves.accounts.reduce(targetAmountSum, 0);
    const sd = new Date(curYearMonth.year, curYearMonth.month);

    this.goalDates[method].step1 = { date: null, target: et }; // month when step 1 was finished
    this.goalDates[method].step2 = { date: null, target: 0 }; // month when step 2 was finished
    this.goalDates[method].step3 = { date: null, target: ct }; // month when step 3 was finished
    this.goalDates[method].wealthGradeA = { date: null, target: 20 }; // month when wealth grade A was reached
    this.goalDates[method].wealthGradeB = { date: null, target: 10 }; // month when wealth grade B was reached
    this.goalDates[method].wealthGradeC = { date: null, target: 0 }; // month when wealth grade C was reached
    this.goalDates[method].wealthGradeD = { date: null, target: -4 }; // month when wealth grade D was reached
    this.goalDates[method].wealthGradeF = { date: sd, target: 0 }; // month when wealth grade F was reached

    this.goalDates[method].accountGoal = {}; // month when account was paid up
  } // initializeGoalDates

  public savingsTotal(assets, wantSubcat) {
    let sum, accountValue;

    sum = 0;
    for (const subcat of Object.keys(assets)) {
      if (skipSubcatAttributes.indexOf(subcat) === -1 && subcat === wantSubcat) {
        for (const account of assets[subcat].accounts) {
          // gather value necessary to do work
          accountValue = 0;
          if (account.hasOwnProperty('accountValue')) {
            accountValue = account.accountValue.val;
          }

          // process value as appropriate
          if (typeof accountValue === 'number') {
            sum += accountValue;
          } else if (typeof accountValue === 'string' && !isNaN(+accountValue)) {
            sum += Number(accountValue);
          }
        } // for actndx
      } // if want this subcat
    } // for subcat

    return sum;
  } // savingsTotal

  public nonProductiveDebtTotal(liabilities) {
    let sum, accountValue;

    sum = 0;
    for (const subcat of Object.keys(liabilities)) {
      if (skipSubcatAttributes.indexOf(subcat) === -1) {
        for (const account of liabilities[subcat].accounts) {
          // gather value necessary to do work
          accountValue = 0;
          if (account.hasOwnProperty('accountValue')) {
            accountValue = account.accountValue.val;
          }

          if (account.productivity && account.productivity.val === 'Non-productive') {
            sum += accountValue;
            // ADD LIMITED DEBT to nonProductiveDebtTotal
          } else if (account.productivity && account.productivity.val === 'Limited') {
            sum += accountValue;
          }
        } // for actndx
      } // if want this subcat
    } // for subcat

    return sum;
  } // debtTotal

  public updateGoalDates(
    method: string,
    curYearMonth: IYearMonth,
    netWorth: number,
    adjustedNetWorth: number,
    income: any,
    assets: any,
    liabilities: any,
    assetProtection: any
  ) {
    // initialize
    const curYYYYMM = this.yearMonth2YYYYMM(curYearMonth);
    const totalIncome = this.dataModelService.getCategorySum(income, 'monthlyAmount', assets, liabilities, assetProtection);

    // set step date values
    /**
     * limited debt is now part of the debt total from nonProductiveDebtTotal()?
     */
    const emergencySavingsTotal = this.savingsTotal(assets, 'emergencySavings');
    const nonproductiveDebtTotal = this.nonProductiveDebtTotal(liabilities);
    const cashReservesTotal = this.savingsTotal(assets, 'cashReserves');
    if (this.goalDates[method].step1.date === null && emergencySavingsTotal >= this.goalDates.guideline.step1.target) {
      this.goalDates[method].step1.date = new Date(curYearMonth.year, curYearMonth.month);
    }
    if (this.goalDates[method].step2.date === null && nonproductiveDebtTotal <= this.goalDates.guideline.step2.target) {
      this.goalDates[method].step2.date = new Date(curYearMonth.year, curYearMonth.month);
    }
    if (this.goalDates[method].step3.date === null && cashReservesTotal >= this.goalDates.guideline.step3.target) {
      this.goalDates[method].step3.date = new Date(curYearMonth.year, curYearMonth.month);
    }

    // set wealth grade date values
    const annualNetIncome = 12 * totalIncome;
    const wealthGradeIndex = adjustedNetWorth / annualNetIncome;
    if (this.goalDates[method].wealthGradeD.date === null && wealthGradeIndex >= this.goalDates[method].wealthGradeD.target) {
      this.goalDates[method].wealthGradeD.date = new Date(curYearMonth.year, curYearMonth.month);
      this.goalDates[method].wealthGradeD.netWorth = Math.floor(netWorth);
      this.goalDates[method].wealthGradeD.adjustedNetWorth = Math.floor(adjustedNetWorth);
    }
    if (this.goalDates[method].wealthGradeC.date === null && wealthGradeIndex >= this.goalDates[method].wealthGradeC.target) {
      this.goalDates[method].wealthGradeC.date = new Date(curYearMonth.year, curYearMonth.month);
      this.goalDates[method].wealthGradeC.netWorth = Math.floor(netWorth);
      this.goalDates[method].wealthGradeC.adjustedNetWorth = Math.floor(adjustedNetWorth);
    }
    if (this.goalDates[method].wealthGradeB.date === null && wealthGradeIndex >= this.goalDates[method].wealthGradeB.target) {
      this.goalDates[method].wealthGradeB.date = new Date(curYearMonth.year, curYearMonth.month);
      this.goalDates[method].wealthGradeB.netWorth = Math.floor(netWorth);
      this.goalDates[method].wealthGradeB.adjustedNetWorth = Math.floor(adjustedNetWorth);
    }
    if (this.goalDates[method].wealthGradeA.date === null && wealthGradeIndex >= this.goalDates[method].wealthGradeA.target) {
      this.goalDates[method].wealthGradeA.date = new Date(curYearMonth.year, curYearMonth.month);
      this.goalDates[method].wealthGradeA.netWorth = Math.floor(netWorth);
      this.goalDates[method].wealthGradeA.adjustedNetWorth = Math.floor(adjustedNetWorth);
    }

    // set accountGoal date values
    for (const subcat of Object.keys(liabilities)) {
      if (skipSubcatAttributes.indexOf(subcat) === -1) {
        for (const account of liabilities[subcat].accounts) {
          // obtain values that will be needed
          let accountName = 'unknown';
          if (account.productivity && account.productivity.val === 'Non-productive') {
            accountName = account.accountName.val;
          }
          let accountValue = 0;
          let haveAccountValue = false;
          if (account.hasOwnProperty('accountValue')) {
            accountValue = account.accountValue.val;
            haveAccountValue = true;
          }
          const isNonProductiveDebt = account.productivity && account.productivity.val === 'Non-productive';

          // set values as appropriate
          const qualifiedAccountName = subcat + '-' + accountName;
          if (isNonProductiveDebt && haveAccountValue && accountValue <= 0) {
            // add new property only if it is not already present
            if (!this.goalDates[method].accountGoal.hasOwnProperty(qualifiedAccountName)) {
              this.goalDates[method].accountGoal[qualifiedAccountName] = {
                date: new Date(curYearMonth.year, curYearMonth.month)
              };
            }
          }
        } // for actndx
      } // if want this subcat
    } // for subcat
  } // updateGoalDates

  public getGoalDates() {
    return this.goalDates;
  } // getGoalDates
} // class CafrManagement
