import { DataModelService } from '../services/data-model/data-model.service';
import { accountTypes, possibleFieldNames } from '../services/data-model/data-model_0001.data';

export class VersionManagement {
  constructor(public dataModelService: DataModelService) {} // constructor

  /**********************************************************
   * handle non plan data updates (data that is not in a plan)
   **********************************************************/

  public updateNonPlanVersion1(nonPlan: any): any {
    // make clone of input data so as to leave original input intact (subsequent changes will pe placed into this cloned version)
    const newNonPlan = JSON.parse(JSON.stringify(nonPlan));

    // update version
    newNonPlan.persistentDataVersion = 2;

    // write code here that transforms previous version of data to new version
    newNonPlan.profile.newAttribute = 25; // example for testing  //%//

    return newNonPlan;
  } // updateNonPlanVersion1

  public updateNonPlanVersion2(nonPlan: any): any {
    // make clone of input data so as to leave original input intact (subsequent changes will pe placed into this cloned version)
    const newNonPlan = JSON.parse(JSON.stringify(nonPlan));

    // update version
    newNonPlan.persistentDataVersion = 3;

    // write code here that transforms previous version of data to new version
    newNonPlan.header.newAttribute2 = 250; // example for testing  //%//

    return newNonPlan;
  } // updateNonPlanVersion2

  public updateNonPlanVersion(nonPlan: any): any {
    // make clone of input data so as to leave original input intact (subsequent changes will pe placed into this cloned version)
    let newNonPlan = JSON.parse(JSON.stringify(nonPlan));

    // update data until the original input values have been updated to the current system version
    let count = 0;
    const maxCount = 1000;
    while (++count < maxCount && newNonPlan.persistentDataVersion !== this.dataModelService.dataVersion) {
      // update the data to the next version
      switch (newNonPlan.persistentDataVersion) {
        case 1:
          newNonPlan = this.updateNonPlanVersion1(newNonPlan);
          break;
        case 2:
          newNonPlan = this.updateNonPlanVersion2(newNonPlan);
          break;
        default:
          throw new Error('updateNonPlanVersion routine cannot handle version: ' + newNonPlan.persistentDataVersion);
      } // switch
    }
    if (count === maxCount) {
      throw new Error('updateNonPlanVersion routine did not succeed after ' + maxCount + ' attempts');
    }

    // return modified version of nonPlan data
    return newNonPlan;
  } // updateNonPlanVersion

  /**********************************************************
   * handle plan data updates
   **********************************************************/

  public updatePlanVersion1(plan: any): any {
    // make clone of input data so as to leave original input intact (subsequent changes will pe placed into this cloned version)
    const newPlan = JSON.parse(JSON.stringify(plan));

    // update version
    newPlan.planDataVersion = 2;

    // write code here that transforms previous version of data to new version
    newPlan.income.newAttribute = 25; // example for testing  //%//

    return newPlan;
  } // updatePlanVersion1

  public updatePlanVersion2(plan: any): any {
    // make clone of input data so as to leave original input intact (subsequent changes will pe placed into this cloned version)
    const newPlan = JSON.parse(JSON.stringify(plan));

    // update version
    newPlan.planDataVersion = 3;

    // write code here that transforms previous version of data to new version
    newPlan.assets.newAttribute2 = 250; // example for testing  //%//

    return newPlan;
  } // updatePlanVersion2

  public updatePlanVersion(plan: any) {
    // make clone of input data so as to leave original input intact (subsequent changes will pe placed into this cloned version)
    let newPlan = JSON.parse(JSON.stringify(plan));

    // update data until the original input values have been updated to the current system version
    let count = 0;
    const maxCount = 1000;
    while (++count < maxCount && newPlan.planDataVersion !== this.dataModelService.dataVersion) {
      // update the data to the next version
      switch (newPlan.planDataVersion) {
        case 1:
          newPlan = this.updatePlanVersion1(newPlan);
          break;
        case 2:
          newPlan = this.updatePlanVersion2(newPlan);
          break;
        default:
          throw new Error('updatePlanVersion routine cannot handle version: ' + newPlan.planDataVersion);
      } // switch
    }
    if (count === maxCount) {
      throw new Error('updatePlanVersion routine did not succeed after ' + maxCount + ' attempts');
    }

    // return modified version of nonPlan data
    return newPlan;
  } // updatePlanVersion
} // class VersionManagement
