import { Component, OnInit } from '@angular/core';
import { DataModelService } from '../../../services/data-model/data-model.service';

@Component({
  selector: 'app-admin-manage-suggestions',
  templateUrl: './admin-manage-suggestions.component.html',
  styleUrls: ['./admin-manage-suggestions.component.scss']
})
export class AdminManageSuggestionsComponent implements OnInit {
  public suggestionData: any;
  public checkboxStatus: any;
  public selectedSuggestionID: string;
  public wantAddSuggestion: boolean;
  public newSuggestionTitle: string;
  public newSuggestionDescription: string;

  constructor(public dataModelService: DataModelService) {}

  public ngOnInit() {
    this.suggestionData = this.dataModelService.suggestionManagement.suggestionData;
    this.selectedSuggestionID = 'none';
    this.wantAddSuggestion = false;
    this.newSuggestionTitle = '';
    this.newSuggestionDescription = '';

    this.checkboxStatus = {};
    for (const suggestionID of Object.keys(this.suggestionData)) {
      this.checkboxStatus[suggestionID] = false;
    }
  } // ngOnInit

  public objectKeys(obj) {
    return Object.keys(obj);
  } // objectKeys

  public sortedKeys(obj) {
    const sortPattern = (a, b) => {
      let result = 0;
      const d = this.suggestionData;
      if (d[a].votes.length > d[b].votes.length) {
        result = -1;
      } else if (d[a].votes.length < d[b].votes.length) {
        result = 1;
      } else {
        if (d[a].priority < d[b].priority) {
          result = -1;
        } else if (d[a].priority > d[b].priority) {
          result = 1;
        } else {
          if (d[a].timestamp < d[b].timestamp) {
            result = -1;
          } else if (d[a].timestamp > d[b].timestamp) {
            result = 1;
          }
        }
      }
      return result;
    }; // sortPattern

    const sortedKeys = Object.keys(obj);
    sortedKeys.sort(sortPattern);
    return sortedKeys;
  } // sortedKeys

  public checkboxStatusChange(suggestionID) {
    // set selectedSuggestionID
    this.selectedSuggestionID = 'none';
    if (this.checkboxStatus[suggestionID]) {
      this.selectedSuggestionID = suggestionID;
    }

    // update checkboxStatus
    for (const keySuggestionID of Object.keys(this.checkboxStatus)) {
      this.checkboxStatus[keySuggestionID] = keySuggestionID === this.selectedSuggestionID ? true : false;
    }

    if (this.selectedSuggestionID !== 'none') {
      this.wantAddSuggestion = false;
    }
  } // checkboxStatusChange

  public addNewSuggestion() {
    console.log('addNewSuggestion'); // %//
    this.wantAddSuggestion = true;
  } // addNewSuggestion

  public cancelAddSuggestion() {
    console.log('cancelAddSuggestion'); // %//
    this.newSuggestionTitle = '';
    this.newSuggestionDescription = '';
    this.wantAddSuggestion = false;
  } // cancelAddSuggestion

  public addSuggestion() {
    const addSuggestion0 = async () => {
      console.log('addSuggestion'); // %//
      console.log('newSuggestionTitle: ' + this.newSuggestionTitle); // %//
      console.log('newSuggestionDescription: ' + this.newSuggestionDescription); // %//
      await this.dataModelService.suggestionManagement.addSuggestion(this.newSuggestionTitle, this.newSuggestionDescription);

      this.newSuggestionTitle = '';
      this.newSuggestionDescription = '';
      this.wantAddSuggestion = false;
    }; // addSuggestion0

    addSuggestion0().catch(err => {
      console.log('error in addSuggestion: ', err);
    });
  } // addSuggestion

  public deleteSuggestion(suggestionID) {
    console.log('deleteSuggestion'); // %//
    const deleteSuggestion0 = async funcSuggestionID => {
      await this.dataModelService.suggestionManagement.deleteSuggestion(funcSuggestionID);
    }; // deleteSuggestion0

    this.selectedSuggestionID = 'none';
    deleteSuggestion0(suggestionID).catch(err => {
      console.log('error in deleteSuggestion: ', err);
    });
  } // delteSuggestion

  public addVote(suggestionID) {
    console.log('addVote'); // %//
    const addVote0 = async funcSuggestionID => {
      await this.dataModelService.suggestionManagement.addVote(funcSuggestionID);
    }; // addVote0

    addVote0(suggestionID).catch(err => {
      console.log('error in addVote: ', err);
    });
  } // addVote
} // class AdminManageSuggestionsComponent
