import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-suggest-feature',
  templateUrl: './suggest-feature.component.html',
  styleUrls: ['./suggest-feature.component.scss']
})
export class SuggestFeatureComponent {
  public newSuggestion: ISuggestion = { alreadyVoted: false, votes: 0, feature: '' };

  public suggestions: ISuggestion[] = [
    { alreadyVoted: true, votes: 6, feature: 'WizeFi Community' },
    { alreadyVoted: false, votes: 5, feature: 'Resource Hub (articles, tips, etc)' },
    { alreadyVoted: false, votes: 5, feature: 'Credit Score' },
    { alreadyVoted: false, votes: 5, feature: 'Bill Pay' },
    { alreadyVoted: false, votes: 5, feature: 'Connect with Professionals' },
    { alreadyVoted: false, votes: 5, feature: 'Payment Transfers' },
    { alreadyVoted: false, votes: 5, feature: 'Debit Card' },
    { alreadyVoted: false, votes: 5, feature: 'Credit Card' },
    { alreadyVoted: false, votes: 5, feature: 'Loans' },
    { alreadyVoted: false, votes: 5, feature: 'Investment Platform' },
    { alreadyVoted: false, votes: 5, feature: 'Anonymously Compare to Peers' },
    { alreadyVoted: false, votes: 5, feature: 'Daily Trivia/Financial Literacy' }
  ];

  public constructor(public matDialogRef: MatDialogRef<SuggestFeatureComponent>) {}

  public addSuggestion(): void {
    this.newSuggestion.votes = 1;
    this.newSuggestion.alreadyVoted = true;
    this.suggestions.push({ ...this.newSuggestion });
    this.newSuggestion = { alreadyVoted: false, votes: 0, feature: '' };
  }

  public toggleVote(suggestion: ISuggestion): void {
    suggestion.alreadyVoted = !suggestion.alreadyVoted;

    if (suggestion.alreadyVoted) {
      suggestion.votes++;
    } else {
      suggestion.votes--;
    }
  }
}

interface ISuggestion {
  alreadyVoted: boolean;
  votes: number;
  feature: string;
}
