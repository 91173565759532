import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IAccount } from '../../../interfaces/iAccount.interface';
import { DataModelService } from '../../../services/data-model/data-model.service';

@Component({
  selector: 'app-add-income-source',
  templateUrl: './add-income-source.component.html',
  styleUrls: ['./add-income-source.component.scss']
})
export class AddIncomeSourceComponent implements OnInit {
  @Output() public onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onClose: EventEmitter<any> = new EventEmitter<any>();
  public income: any;
  public incomeSourceName = '';
  public nameIsInvalid = true; // empty field is invalid

  constructor(protected dataModelService: DataModelService, protected router: Router) {}

  public ngOnInit() {
    this.income = this.dataModelService.getdata('income');
  }

  public onIncomeSourceChange(name: string) {
    this.nameIsInvalid = this.isInvalidAccountName(name);
    this.incomeSourceName = name;
  }

  public onAdd() {
    if (this.isInvalidAccountName(this.incomeSourceName)) {
      this.dataModelService.showMessage('error', 'Invalid name', 5000);
    } else {
      this.onSubmit.emit(this.incomeSourceName);
      const newAccount = this.createNewAccount();
      console.log('new account', newAccount);
      this.updateDataModel(newAccount);
    }
  }

  private createNewAccount(): IAccount {
    const newAccountID = this.dataModelService.categoryManagement.getNewAccountID(this.income.income.accounts);
    const incomeAccountSchema = this.dataModelService.categoryManagement.getWizeFiAccountSchema('income', 'income');

    return {
      ...incomeAccountSchema,
      accountID: { label: 'Account ID', isRequired: true, val: newAccountID },
      accountName: { label: 'Account Name', isRequired: true, val: this.incomeSourceName }
    };
  }

  // Private Functions

  private updateDataModel(newAccount: IAccount) {
    this.income.income.accounts.push(newAccount);
    this.dataModelService.putdata('income', this.income);
  }

  private isInvalidAccountName(accountName: string): boolean {
    // checking whitespace
    if (accountName.trim() === '') {
      return true;
    }
    // checking duplicate entries
    return this.income.income.accounts.some(existingAccount => accountName === existingAccount.accountName.val);
  }
}
