import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { IVerifyResult } from '../../interfaces/iVerifyResults.interface';
import { DataModelService } from '../../services/data-model/data-model.service';
import { CProfile } from '../../utilities/profile.class';
import { UrlManagement } from '../../utilities/url-management.class';
import { CSettings } from './settings.class';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  // persistent data
  public cSettings: CSettings;
  public cProfile: CProfile;

  // transient data
  public messages: string[] = [];
  public changes = false;
  protected fullName: string;
  protected url = new UrlManagement();

  constructor(public dataModelService: DataModelService, protected router: Router) {}

  public ngOnInit() {
    this.cSettings = new CSettings(this.dataModelService.getdata('settings'));
    this.cProfile = new CProfile(this.dataModelService.getdata('profile'));
    //console.log(this.cProfile.profile);
    console.log(this.cSettings);
    this.fullName = this.cProfile.profile.nameFirst + ' ' + this.cProfile.profile.nameLast;
  }

  public verify() {
    this.messages = [];
    const result: IVerifyResult = this.cSettings.verifyAllDataValues();
    if (result.hadError) {
      // report errors on screen
      this.messages = result.messages;
    }
  }

  public verifyProfileValues(): IVerifyResult {
    const result: IVerifyResult = { hadError: false, messages: [] };

    // TODO add validity checks here to handle all profile values used on the settings screen

    // %//  \/
    // the following is utilized to facilitate debugging of messages passed through a promise chain scenario in a "deactivate" guard situation
    if (this.cProfile.profile.retirementAge <= 5) {
      result.hadError = true;
      result.messages = result.messages.concat('Must have retirementAge > 5');
    }
    // %//  /\

    return result;
  } // verifyProfileValues

  // update data model
  public update() {
    return new Promise((resolve, reject) => {
      // do not need putdata validity check here (handled prior to call on update)
      this.dataModelService.putdata('profile', this.cProfile.profile);
      this.dataModelService.putdata('settings', this.cSettings.settings);

      this.dataModelService.dataManagement
        .storeinfo()
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    }); // return Promise
  } // update

  public save() {
    let hadError = false;
    let messages: string[] = [];
    let result: IVerifyResult;

    result = this.cSettings.verifyAllDataValues();
    if (result.hadError) {
      hadError = true;
      messages = messages.concat(result.messages);
    }

    result = this.cProfile.verifyProfile();
    if (result.hadError) {
      hadError = true;
      messages = messages.concat(result.messages);
    }

    // report results
    if (hadError) {
      // report errors and take no further action
      this.dataModelService.showMessages('error', messages, 7000);
    } else {
      // update both application data model and persistent data model
      this.update()
        .then(() => {
          this.dataModelService.showMessage('success', 'Saved', 1500);
        })
        .catch(err => {
          // report error and take no further action
          console.log('Unable to save data: ', err); // %//
          this.dataModelService.showMessage('error', 'Unable to save data', 7000);
        });
    }
  } // save

  public change($event) {
    // console.log('change', $event);
    this.changes = true;
  }

  public convertLogin() {
    this.router.navigateByUrl('/register?c=1');
  }

  @HostListener('window:beforeunload', ['$event']) // @HostListener allows us to also guard against browser refresh, close, etc.
  public unload($event): any {
    if (this.changes === true) {
      $event.returnValue = 'Are you sure you want to leave? Any unsaved changes will be lost.';
    }
  }

  public back() {
    this.router.navigate(['./dashboard', {}]);
  }

  public logout() {
    if (window.confirm('Are you sure you want to log out?')) {
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigateByUrl('/login');
    }
  }
}
