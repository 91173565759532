import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnInit {
  @Input() public id: string;
  @Input() public placeholder?: string;
  @Input() public name?: string;
  @Input() public variant?: string;
  @Input() public center?: boolean;
  @Input() public alignment?: string;
  @Input() public fontWeight?: string;
  @Input() public fontFamily?: string;
  @Input() public readOnly?: boolean = false;
  @Input() public numberSize?: string;
  @Input() public numberPrefix?: string;
  @Input() public numberSuffix?: string;
  @Input() public defaultValue?: string;

  @Output() public inputChange?: EventEmitter<any> = new EventEmitter<any>();
  @Output() public inputModelChange?: EventEmitter<any> = new EventEmitter<any>();
  @Output() public inputKeyUp?: EventEmitter<any> = new EventEmitter<any>();
  @Output() public inputFocusIn?: EventEmitter<any> = new EventEmitter<any>();
  @Output() public inputFocusOut?: EventEmitter<any> = new EventEmitter<any>();

  public value: string;
  public isActive = false;
  public mask = createNumberMask({
    prefix: '',
    suffix: '',
    allowDecimal: true // This will put the dollar sign at the end, with a space.
  });

  constructor() {}

  public ngOnInit() {
    if (this.defaultValue) {
      // console.log('this.value', this.value)
      this.value = this.defaultValue;
    } else if (this.variant === 'number') {
      this.value = '0';
    }
  }

  public setContainerClasses() {
    return {
      'text-input__container': true,
      'text-input__container--search': this.variant === 'search',
      'text-input__container--search-active': this.variant === 'search' && this.isActive === true,
      'text-input__container--search-inactive': this.variant === 'search' && this.isActive === false,
      'text-input__container--number': this.variant === 'number',
      'text-input__container--number-lg': this.numberSize === 'lg',
      'text-input__container--number-sm': this.numberSize === 'sm',
      'text-input__container--number-smlr': this.numberSize === 'smlr'
    };
  }

  public setInputClasses() {
    return {
      'text-input__field': true,
      'text-input__field--number': this.variant === 'number',
      'text-input__field--search-active': this.variant === 'search' && this.isActive === true,
      'text-input__field--search-inactive': this.variant === 'search' && this.isActive === false,
      'text-input__field--center': this.center === true,
      'text-input__field--right': this.alignment === 'right',
      'text-input__field--bold': this.fontWeight === 'bold',
      'text-input__field--bolder': this.fontFamily === 'SharpSans-Bold'
    };
  }

  public setPrefixClasses() {
    return this.variant === 'search'
      ? {
          'text-input__search-icon': true,
          'text-input__search-icon--active': this.isActive === true,
          'text-input__search-icon--inactive': this.isActive === false
        }
      : {};
  }

  public setSuffixClasses() {
    return this.variant === 'search'
      ? {
          'text-input__search-suffix': true,
          'text-input__search-suffix--active': this.isActive === true,
          'text-input__search-suffix--inactive': this.isActive === false
        }
      : {};
  }

  public _onClearSearch() {
    this.value = '';
    this.isActive = false;
  }

  public _onChange() {
    this.inputChange.emit(this.value);
  }

  public _onModelChange(event) {
    if (this.variant === 'number') {
      this.value = event;
      this.inputModelChange.emit(this.removeFormat(this.value));
      return;
    }
    this.inputModelChange.emit(this.value);
  }

  public _onKeyUp() {
    this.inputKeyUp.emit(this.value);
  }

  public _onFocusIn() {
    /*if (this.value === "0" && this.variant === "number") {
      this.value = "";
    }*/
    this.isActive = true;
    this.inputFocusIn.emit(this.value);
  }

  public _onFocusOut() {
    /*if (this.value === "" && this.variant === "number") {
      this.value = "0";
    }*/
    this.isActive = this.value !== undefined && this.value.length > 0;
    this.inputFocusOut.emit(this.value);
  }

  removeFormat(value) {
    return String(value).replace(/[, ]+/g, '').trim();
  }
}
