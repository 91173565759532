import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IGraphItem } from '../../../interfaces/iGraphItem.interface';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { DebtProjectionChartComponent } from '../../charts/debt-projection-chart/debt-projection-chart.component';
import { ProjectionChartComponent } from '../../charts/projection-chart/projection-chart.component';
import { NetWorthProjectionComponent } from '../net-worth-projection/net-worth-projection.component';

@Component({
  selector: 'app-debt-payoff-projections',
  templateUrl: './debt-payoff-projections.component.html',
  styleUrls: ['./debt-payoff-projections.component.scss']
})
export class DebtPayoffProjectionsComponent extends NetWorthProjectionComponent {
  @ViewChild(DebtProjectionChartComponent, { static: false })
  public chart: ProjectionChartComponent;
  public datasets: Array<{ data: IGraphItem[]; color: string }>;
  public isLoading = true;
  public math = Math;

  constructor(protected dataModelService: DataModelService) {
    super(dataModelService);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.initializeData();
    this.isLoading = false;
  }

  private initializeData() {
    console.log('original debt data', this.originalDebtData);
    console.log('current debt data', this.currentDebtData);
    console.log('guideline debt data', this.wizeFiDebtData);
    this.datasets = [
      { data: this.wizeFiDebtData, color: this.purple },
      { data: this.currentDebtData, color: this.lightBlue },
      { data: this.originalDebtData, color: this.black }
    ];
  }
}
