import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { SetupFlowDataManagementService } from '../../../services/setup/setup-flow-data-management.service';
import { SetupFlowHeaderComponent } from '../../header/setup-flow-header/setup-flow-header.component';
import { WaitForCoach } from '../setup-review/wait-for-coach/wait.component';

const baseSetupPath = '/setup';

@Component({
  selector: 'app-setup-step-menu',
  templateUrl: './setup-step-menu.component.html',
  styleUrls: ['./setup-step-menu.component.scss']
})
export class SetupStepMenuComponent extends SetupFlowHeaderComponent {
  public setupSteps: Array<{ stepName: string; stepState: string }>;
  public currentStep: number;

  protected hasLinkedInstitution;
  private hasUncategorizedTransactions;
  private hasPendingPlaidAccounts;
  private hasCompletedFirstHalfOfSetup;
  protected name;

  protected yearMonth = moment(new Date()).subtract(1, 'month').toISOString().substr(0, 7);

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected dataManagement: SetupFlowDataManagementService,
    protected dataModelService: DataModelService,
    private dialog: MatDialog
  ) {
    super();
  }

  public ngOnInit(predeterminedStep?) {
    this.currentStep = predeterminedStep ? predeterminedStep : this.getStep();
    super.ngOnInit();
    this.setConditionalNavigationHelpers();
    this.attachStateToSetupSteps();
    this.getName();
  }

  private setConditionalNavigationHelpers() {
    const plaidData = this.dataModelService.dataModel.global.plaidData;

    this.hasCompletedFirstHalfOfSetup = () => _.values(this.dataModelService.dataModel.persistent.plans).length > 1;

    this.hasLinkedInstitution = () => plaidData.wizeFiPlaidInstitutions.length > 0;

    this.hasPendingPlaidAccounts = () => plaidData.wizeFiPlaidAccounts.some(account => account.status === '0' || account.status === 0);

    this.hasUncategorizedTransactions = () =>
      plaidData.wizeFiTransactionsCollection[this.yearMonth] &&
      plaidData.wizeFiTransactionsCollection[this.yearMonth].some(transaction => transaction.wizeFiCategory === 'unknown');
  }

  // attaches the state of each item in the menu for controlling which items have checkmarks,
  // which item is in progress, which items are not yet complete, and which items are locked
  private attachStateToSetupSteps() {
    this.setupSteps = this.stepNames.map((stepName, index) => {
      const stepState = this.getStepState(index + 1);
      return { stepName, stepState };
    });
  }

  private getStepState(step: number): string {
    let state: string;
    if (step < this.currentStep) {
      state = 'complete';
    } else if (step === this.currentStep) {
      state = 'inProgress';
    } else {
      state = 'incomplete';
    }

    return this.isLocked(step) ? 'locked' : state;
  }

  private isLocked(step: number): boolean {
    return (this.hasCompletedFirstHalfOfSetup() && step <= 6) || (!this.hasCompletedFirstHalfOfSetup() && step > 6) ? true : false;
  }

  public async goToStep(step) {
    /*  console.log(step);
     console.log(this.canGoToStep(step));
     console.log(this.dataManagement.currentScreenCanSave()); */
    if (!this.canGoToStep(step)) {
      return;
    }
    // we want the user to be able to go back if there is an error
    if (this.currentStep > step) {
      const nextPath = this.getURLPath(step);
      this.router.navigate([nextPath]);
      this.ngOnInit(step);
      if (step < 5) {
        this.dataModelService.setCurPlan('original');
      }
    } else {
      if (this.dataManagement.currentScreenCanSave() || (this.router.url === '/setup/settings' && this.currentStep === step)) {
        await this.dataManagement.saveData();
        const nextPath = this.getURLPath(step);
        this.router.navigate([nextPath]);
        this.ngOnInit(step);
        if (step < 5) {
          this.dataModelService.setCurPlan('original');
        }
      } else {
        this.dataManagement.showCurrentScreenErrorMessage();
      }
    }
  }

  // Determines which steps a user can navigate to by clicking on a step in the side bar menu.
  private canGoToStep(step): boolean {
    if (this.isLocked(step)) {
      return false;
    }
    if (step > 1 && this.hasPendingPlaidAccounts()) {
      this.dataModelService.showMessage('error', 'Please activate all pending accounts before continuing', 20000);
      return false;
    }
    if (step === 5 && this.hasUncategorizedTransactions()) {
      // this.dataModelService.showMessage("error","Please categorize all transactions before continuing")
      // return false
    }
    return true;
  }

  public async onSaveAndContinue(canPass?) {
    if (this.currentStep === 5 && !canPass) {
      const CurrentDialog = this.dialog.open(WaitForCoach);
      CurrentDialog.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
        if (result === true) {
          this.onSaveAndContinue(true);
        }
      });
    } else {
      if (this.dataManagement.currentScreenCanSave()) {
        await this.dataManagement.saveData();
        const nextPath = this.getURLPath(this.currentStep + 1);
        this.router.navigate([nextPath]);
        this.ngOnInit(this.currentStep + 1); // rerender the sidebar with the next step highlighted
      } else {
        this.dataManagement.showCurrentScreenErrorMessage();
      }
    }
  }

  public async onBack() {
    const prevStep = this.currentStep - 1;
    if (!this.canGoToStep(prevStep)) {
      return;
    }
    if (this.dataManagement.currentScreenCanSave()) {
      await this.dataManagement.saveData();
    } else {
      this.dataModelService.showMessage('error', 'Unable to save your changes.', 5000);
    }
    const previousPath = this.getURLPath(prevStep);
    this.router.navigate([previousPath]);
    this.ngOnInit(this.currentStep - 1); // rerender the sidebar with the next step highlighted
  }

  private getURLPath(step: number): string {
    if (step > this.setupSteps.length) {
      return '/plan';
    } else if (step < 2) {
      return this.getPathBasedOnIfUserHasLinkedPlaid();
    } else {
      const pathName = this.allStepPaths[step - 1][0][0]; // get first screen of first substep of next step
      return `${baseSetupPath}/${pathName}`;
    }
  }

  private getPathBasedOnIfUserHasLinkedPlaid(): string {
    return this.hasLinkedInstitution ? `${baseSetupPath}/review-accounts` : `${baseSetupPath}/connect-bank`;
  }

  protected switchRoute(route: string) {
    return this.router.navigateByUrl(route);
  }

  protected getName(): string {
    const profile = this.dataModelService.dataModel.persistent.profile;
    this.name = profile.nameFirst + ' ' + profile.nameLast;
    return this.name;
  }
}
