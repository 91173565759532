import { Component, OnInit } from '@angular/core';
import { UrlManagement } from '../../../utilities/url-management.class';

@Component({
  selector: 'app-budget-page-submenu',
  templateUrl: './budget-page-submenu.component.html',
  styleUrls: ['./budget-page-submenu.component.scss']
})
export class BudgetPageSubmenuComponent implements OnInit {
  protected url = new UrlManagement();

  constructor() {}

  public ngOnInit() {}
}
