import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import 'hammerjs';
import { AppModule } from './app/modules/app.module';
import { DataModelService } from './app/services/data-model/data-model.service';
import { environment } from './environments/environment';
let dataModelService;
let didPopPlaid = false;

Sentry.init({
  dsn: 'https://38ef71e00b5c463680fbdea8609de487@o447954.ingest.sentry.io/5428548',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', 'beta.wizefi.com', 'demo.wizefi.com'],
      routingInstrumentation: Sentry.routingInstrumentation
    })
  ],
  tracesSampleRate: 1.0,
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    console.error('error event', event);
    if (event.exception) {
      if (event.exception.values) {
        event.exception.values.forEach(val => {
          // plaid rate limit issues!
          if ((val.value && val.value.includes('RATE_LIMIT_EXCEEDED')) || val.value.includes('ACCOUNTS_LIMIT')) {
            dataModelService.showMessage('info', 'Plaid has timed out, please wait 10 minutes and login again');
            return event;
          }
          // expired user account - they need to login again
          if (val.value && val.value.includes('CredentialsError')) {
            dataModelService.showMessage('info', 'Session has expired, please reload WizeFi.');
            return event;
          }
          // plaid disconnected
          if (val.value && val.value.includes('ITEM_LOGIN_REQUIRED')) {
            if (!didPopPlaid && window.confirm('Plaid has lost connection to one or more of your banks, please login again')) {
              didPopPlaid = true;
              const title = dataModelService.dataManagement.getDraftTitle();
              return dataModelService.plaidManagement.loadUpdatePlaidLink(title);
            } else {
              return event;
            }
          }

          // Institution is down in plaid?
          if (val.value && val.value.includes('INSTITUTION_DOWN')) {
            return event;
          }
          // plaid request failed - this is our fault.
          if ((val.value && val.value.includes('MISSING_FIELDS')) || val.value.includes('INVALID_FIELD')) {
            return event;
          } else {
            // user error, just show the report dialog.
            return Sentry.showReportDialog({ eventId: event.event_id });
          }
        });
      }
    }
    return event;
  }
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(ref => {
    dataModelService = ref.injector.get(DataModelService);
  })
  .catch(err => {
    console.log(err);
  });
