import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DataModelService } from '@app/services/data-model/data-model.service';
import { UrlManagement } from '../../utilities/url-management.class';

@Component({
  selector: 'app-edit-cafr-submenu',
  templateUrl: './edit-cafr-submenu.component.html',
  styleUrls: ['./edit-cafr-submenu.component.scss']
})
export class EditCafrSubmenuComponent {
  @Output() public switchTab: EventEmitter<void> = new EventEmitter<void>();
  @Output() public cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() public confirm: EventEmitter<any> = new EventEmitter<any>();
  public url = new UrlManagement();

  constructor(public router: Router, public dataModelService: DataModelService) {}

  public saveAndSwitchHash(path: string) {
    this.switchTab.emit();
    this.url.switchHash(path);
  }
}
