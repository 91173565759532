import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-default-info-dialog',
  templateUrl: './default-info-dialog.component.html',
  styleUrls: ['./default-info-dialog.component.scss']
})
export class DefaultInfoDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: IDefaultInfoDialogData, public dialogRef: MatDialogRef<DefaultInfoDialogComponent>) {
    //console.log(data);
  }
}

export interface IDefaultInfoDialogData {
  header?: string;
  body?: string;
}
