import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { DataModelService } from '../services/data-model/data-model.service';

declare const AWS: any;

@Injectable()
export class ScreenLoginGuard implements CanActivate {
  constructor(private router: Router, private dataModelService: DataModelService) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.dataModelService.dataModel.global.isLoggedIn || state.url === '/login') {
      return true;
    } else {
      if (!window.sessionStorage.getItem('logins') || !window.sessionStorage.getItem('wizeFiID')) {
        this.router.navigateByUrl('/login');
        return false;
      } else {
        // console.log("ScreenLoginGuard -- relogin");  // %//
        if (window.sessionStorage.getItem('reloginUrl') !== '/plan/edit-CAFR') {
          window.sessionStorage.setItem('reloginUrl', state.url);
        }
        this.router.navigateByUrl('/relogin');
        return false;
      }
    }
  } // canActivate
}
