import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import * as Sentry from '@sentry/angular';

@Component({
  selector: 'app-wait',
  templateUrl: './wait.component.html',
  styleUrls: ['./wait.component.scss']
})
export class WaitForCoach {
  public message: string;

  public constructor(public matDialogRef: MatDialogRef<WaitForCoach>) {}

  public submitReport(): void {
    Sentry.captureMessage(this.message);
    this.matDialogRef.close();
  }
}
