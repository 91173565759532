import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PlaidAccount } from '../../interfaces/plaid-account';
import { DataModelService } from '../data-model/data-model.service';

@Injectable({ providedIn: 'root' })
export class AccountService {
  constructor(private dataModelService: DataModelService, private router: Router) {}

  public isInAccountsReadOnlyPage(): boolean {
    return this.router.url.startsWith('/accounts');
  }

  public async addManualInstitutionIfNeeded() {
    const manualInstitution = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions.find(ins => ins.isManual);

    if (!manualInstitution) {
      const institution = {
        access_token: this.generateIDcode(6),
        item_id: this.generateIDcode(5),
        institution_id: this.generateIDcode(4),
        institutionName: 'Manual Accounts',
        error: 'none',
        isManual: true
      };
      await this.dataModelService.plaidManagement.addManualInstitution(institution);
      await this.dataModelService.plaidManagement.getPlaidData();
    }
  }

  public createManualAccount(): PlaidAccount {
    const institution = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions.find(ins => ins.isManual === true);
    if (!institution) {
      return;
    }

    const account = {
      account_id: this.generateIDcode(5),
      accountName: 'Manual Account',
      accountType: 'manual',
      accountSubtype: 'manual',
      mask: '0000',
      balance: 0,
      item_id: institution.item_id,
      institution_id: institution.institution_id,
      institutionName: institution.institutionName,
      isActive: false,
      isManual: true
    };

    this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts.push(account);
    return account;
  }

  public getAllAccounts(): PlaidAccount[] {
    return this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
  }

  public getAccountNameFromId(accountId: string): string | undefined {
    const account = (this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts as PlaidAccount[]).find(i => i.account_id === accountId);
    return account ? account.accountName : undefined;
  }

  public translateDepricatedAccountName(subtype, accountType) {
    /*
    Pension > Other Productive Investment
    Primary Residence > Non-Income-Producing Real Estate
    Secondary Residence > Non-Income Producing Real Estate
    Vacation Home > Non-Income Producing Real Estate
    Rental Property > Income Producing Real Estate
    Other Investment Property > Other Nonproductive Investment
    Artwork > Art
    Bedding/Drapes/Linen > Furniture
    Miscellaneous Item > Other Nonproductive Investment
    Primary Residence > Mortgage For Non-Income Producing Real Estate
    Secondary Residence > Mortgage For Non-Income Producing Real Estate
    Other Residence > Mortgage For Non-Income Producing Real Estate
    Other Liability > Other Nonproductive debt
  */
    switch (subtype) {
      case 'Pension':
        return 'Other Productive Investment';
      case 'Primary Residence': {
        if (accountType === 'liability') {
          return 'Mortgage For Non-Income Producing Real Estate';
        } else {
          return 'Non-Income-Producing Real Estate';
        }
      }
      case 'Secondary Residence': {
        if (accountType === 'liability') {
          return 'Mortgage For Non-Income Producing Real Estate';
        } else {
          return 'Non-Income-Producing Real Estate';
        }
      }
      case 'Vacation Home': {
        return 'Non-Income Producing Real Estate';
      }
      case 'Rental Property': {
        return 'Income Producing Real Estate';
      }
      case 'Other Investment Property': {
        return 'Other Nonproductive Investment';
      }
      case 'Artwork': {
        return 'Art';
      }
      case 'Bedding/Drapes/Linen': {
        return 'Furniture';
      }
      case 'Miscellaneous Item': {
        return 'Other Nonproductive Investment';
      }
      case 'Other Residence': {
        return 'Mortgage For Non-Income Producing Real Estate';
      }
      case 'Other Liability': {
        return 'Other Nonproductive debt';
      }
      default:
        return subtype;
    }
  }

  public async getParentAffiliateInfo(activatedRoute) {
    let affiliateAlias = '';
    if (activatedRoute.snapshot.queryParamMap.get('a')) {
      affiliateAlias = activatedRoute.snapshot.queryParamMap.get('a');
    } else {
      affiliateAlias = window.localStorage.getItem('a');
    }

    console.log(affiliateAlias);
    let wizeFiDataItem;
    wizeFiDataItem = await this.dataModelService.dataManagement.getAliasWizeFiDataItem(affiliateAlias).catch(err => {
      console.error(err);
    });
    return wizeFiDataItem;
  }

  private generateIDcode(length): string {
    const letters = 'abcdefghijklmnopqrstuvwxyz';
    let IDcode = '';

    for (let i = 1; i <= length; i++) {
      IDcode += letters[Math.floor(Math.random() * 26)];
    }

    return IDcode;
  }
}
