import { Component } from '@angular/core';
import { SelectAccountsComponent } from '../select-accounts/select-accounts.component';
import { PlaidAccount } from '@app/interfaces/plaid-account';

@Component({
  selector: 'app-edit-manual-institution',
  templateUrl: './edit-manual-institution.component.html',
  styleUrls: ['./edit-manual-institution.component.scss']
})
export class EditManualInstitutionComponent extends SelectAccountsComponent {
  private localInstitutionName: string;

  public ngOnInit() {
    super.ngOnInit();
    this.localInstitutionName = (' ' + this.institution.institutionName).slice(1);
  }

  protected _onDeleteInstitution() {
    this.deleteAccountsUnderInstitution();
    this.deleteInstitution();
    super._onSave();
  }

  private deleteAccountsUnderInstitution() {
    this.localAccounts.forEach(account => this.deleteAccount(account));
  }

  private async deleteInstitution() {
    try {
      const itemId = this.institution.item_id;
      const globalInstitutions = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions;
      const index = globalInstitutions.findIndex(ins => ins.item_id === this.institution.item_id);
      if (index !== -1) {
        globalInstitutions.splice(index, 1);
      }

      await this.dataModelService.plaidManagement.deleteManualInstitution(itemId);
      await this.dataModelService.dataManagement.storeinfo();
    } catch (err) {
      console.error(err);
    }
  }

  protected onInstituionNameChange(value: string) {
    this.localInstitutionName = value;
  }

  public async deleteAccount(account: PlaidAccount): Promise<void> {
    const index = this.localAccounts.findIndex(acct => account.account_id === acct.account_id);
    if (index !== -1) {
      this.localAccounts.splice(index, 1);
    }
    const dataModelIdx = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts.findIndex(a => a.account_id === account.account_id);
    this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts.splice(dataModelIdx, 1);

    this._onSave();
    if (!this.dataModelService.dataManagement.isDraft()) {
      await this.dataModelService.dataManagement.fetchinfo();
      await this.dataModelService.plaidManagement.getPlaidData();
    }
  }

  public async _onSave() {
    const globalInstitutions = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions;
    const index = globalInstitutions.findIndex(ins => ins.item_id === this.institution.item_id);
    globalInstitutions[index].institutionName = this.localInstitutionName;
    super._onSave();
  }
}
