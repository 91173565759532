import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { AddNewPlaidInstitutionResult } from '@app/interfaces/add-new-plaid-institution-result';
import { PlaidAccount } from '@app/interfaces/plaid-account';
import { AccountService } from '@app/services/account/account.service';
import { DataModelService } from '@app/services/data-model/data-model.service';
import { AccountTypeSelectorComponent, IAccountTypeSelectorData } from '../setup-flow/accounts/account-type-selector/account-type-selector.component';
import { CountryPickerComponent } from '../setup-flow/connect-bank/country-picker/country-picker.component';

@Component({
  selector: 'app-new-account-dialog',
  templateUrl: './new-account-dialog.component.html',
  styleUrls: ['./new-account-dialog.component.scss']
})
export class NewAccountDialogComponent {
  constructor(
    public dataModelService: DataModelService,
    private dialog: MatDialog,
    private accountService: AccountService,
    private dialogRef: MatDialogRef<NewAccountDialogComponent, NewAccountDialogResult>
  ) {}

  public async addPlaidInstitution() {
    const dialogResult = await this.dialog.open(CountryPickerComponent, { minWidth: '400px', width: '400px' }).afterClosed().toPromise();
    if (!dialogResult) {
      this.dataModelService.showErrorMessage('Unable to set user country code preferences.', 5000);
      return;
    }

    try {
      const title = this.dataModelService.dataManagement.getDraftTitle();
      const plaidLinkData = await this.dataModelService.plaidManagement.loadPlaidLink(title);
      if (plaidLinkData) {
        this.dataModelService.showMessage('info', 'link with ' + plaidLinkData.newWizeFiPlaidInstitution.institutionName + ' established.', 5000);
      }
      this.dialogRef.close(plaidLinkData);
    } catch (e) {
      this.dataModelService.showErrorMessage('Plaid link process failed');
      this.dialogRef.close(undefined);
    }
  }

  public async addManualAccount() {
    await this.accountService.addManualInstitutionIfNeeded();
    const newAccount = this.accountService.createManualAccount();
    this.showManualAccountDetailsScreen(newAccount);
  }

  private showManualAccountDetailsScreen(newAccount: PlaidAccount) {
    this.dialog
      .open<AccountTypeSelectorComponent, IAccountTypeSelectorData>(AccountTypeSelectorComponent, {
        width: '728px',
        data: {
          isManual: true,
          showManualAccountChooser: true,
          plaidAccount: newAccount
        }
      })
      .afterClosed()
      .subscribe(() => {
        this.saveManualAccountDetails();
        this.dialogRef.close(newAccount);
      });
  }

  private async saveManualAccountDetails() {
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    const title = this.dataModelService.dataManagement.getDraftTitle();
    const plaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    await this.dataModelService.plaidManagement.storePlaidAccounts(wizeFiID, title, plaidAccounts);
  }
}

export type NewAccountDialogResult = undefined | AddNewPlaidInstitutionResult | PlaidAccount;
