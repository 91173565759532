import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataModelService } from '../../../services/data-model/data-model.service';

@Component({
  selector: 'app-retirement-income',
  templateUrl: './retirement-income.component.html',
  styleUrls: ['./retirement-income.component.scss']
})
export class RetirementIncomeComponent implements OnInit {
  public retirementAge: number = this.dataModelSerivce.dataModel.persistent.profile.retirementAge;
  public socialSecurity = 0;
  public pension = 0;
  public isUpdating = false;

  constructor(protected dataModelSerivce: DataModelService, protected router: Router) {}

  public ngOnInit() {}

  public onNext() {
    this.isUpdating = true;
    // socialSecurityPension has been deleted
    // this.dataModelSerivce.dataModel.persistent.profile.socialSecurityPension = this.socialSecurity + this.pension;
    this.dataModelSerivce.dataManagement
      .storeinfo()
      .then(() => this.router.navigate(['/inflation-rate']))
      .catch(err => console.error('Error updating SS and pension: ', err))
      .finally(() => (this.isUpdating = false));
  }

  public onSocialSecurityChanged(value) {
    this.socialSecurity = parseFloat(value);
  }

  public onPensionChanged(value) {
    this.pension = parseFloat(value);
  }
}
