import { Component, OnInit } from '@angular/core';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { categoryExcludeList, subcategoryExcludeList } from '../../../services/data-model/data-model_0001.data';

declare let window: any; // resolve error from TypeScript type checking

@Component({
  selector: 'app-admin-test-yodlee',
  templateUrl: './admin-test-yodlee.component.html',
  styleUrls: ['./admin-test-yodlee.component.css']
})
export class AdminTestYodleeComponent implements OnInit {
  public loading: any; // used to control "is working" visual on screen

  public yodleeUserList: any;
  public yodleeUserName: string;

  public dateRange: any;

  public wantFastLink: boolean;
  public isOpen: boolean;
  public flowChoice: string;
  public flowChoiceList: string[];
  public providerId: string;
  public providerIdList: string[];
  public providerAccountId: string;
  public providerAccountIdList: string[];

  public wantRegisterUser: boolean;
  public newUserName: string;
  public newUserEmail: string;

  public wantDeleteUser: boolean;

  public wantTransactionCategories: boolean;
  public categoryAttributes: any;
  public categoryList: any;
  public categoryLookup: any;

  public wantUserInfo: boolean;
  public userInfoAttributes: any;
  public userInfo: any;

  public wantUserAccounts: boolean;
  public accountAttributes: any;
  public accountList: any;
  public accountLookup: any;

  public wantUserTransactions: boolean;
  public transactionAttributes: any;
  public transactionList: any;

  public wantUserTransactionDetail: boolean;
  public transactionDetailAttributes: any;
  public transactionDetailList: any;
  public filterAttribute: string;
  public filterAttributeList: any;
  public filterValue: string;
  public filterValues: any;

  public wantTransactionAmounts: boolean;
  public transactionAmountAttributes: any;
  public transactionAmountList: any;
  public transactionAmountLookup: any;

  public wantWizeFiCategories: boolean;
  public wizeFiCategoryList: any;
  public wizeFiSubcategoryList: any;
  public wizeFiAccountList: any;
  public persistentAttributesList: any;
  public persistentExcludeAttributeList: any;
  public itemExcludeAttributeList: any;
  public persistentItemsAttributeList: any;
  public plan: string;

  constructor(public dataModelService: DataModelService) {}

  public ngOnInit() {
    this.loading = {};
    this.loading.isLoading = false;

    // set list of users for current wizeFiID
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    this.dataModelService.yodleeManagement.setWizeFiID(wizeFiID); // initialize YodleeManagementClass for proper API access
    this.yodleeUserList = this.dataModelService.yodleeManagement.getYodleeUserList(wizeFiID);
    this.yodleeUserName = this.yodleeUserList.length === 1 ? this.yodleeUserList[0] : this.yodleeUserList[1];

    this.dateRange = {};
    this.dateRange.wantMonthRange = true;
    this.dateRange.yearMonth = new Date().toISOString().substr(0, 7); // YYYY-MM  (current month)
    this.updateDates();

    this.wantFastLink = false;
    this.isOpen = false;
    this.flowChoice = '';
    this.flowChoiceList = ['', 'add', 'edit', 'refresh'];
    this.providerId = '';
    this.providerIdList = [];
    this.providerAccountId = '';
    this.providerAccountIdList = [];

    this.wantRegisterUser = false;
    this.newUserName = 'unknown';
    this.newUserEmail = 'unknown';

    this.wantDeleteUser = false;

    this.wantTransactionCategories = false;
    this.categoryAttributes = [];
    this.categoryList = [];
    this.categoryLookup = {};

    this.wantUserInfo = false;
    this.userInfoAttributes = [];
    this.userInfo = {};

    this.wantUserAccounts = false;
    this.accountAttributes = [];
    this.accountList = [];
    this.accountLookup = {};

    this.wantUserTransactions = false;
    this.transactionAttributes = [];
    this.transactionList = [];

    this.wantUserTransactionDetail = false;
    this.transactionDetailAttributes = [];
    this.transactionDetailList = [];
    this.filterAttribute = 'none';
    this.filterAttributeList = ['none'];
    this.filterValue = '';
    this.filterValues = {};
    this.filterValues.none = [''];

    this.wantTransactionAmounts = false;
    this.transactionAmountAttributes = [];
    this.transactionAmountList = [];
    this.transactionAmountLookup = {};

    this.wantWizeFiCategories = false;

    // set exclusion lists
    this.persistentExcludeAttributeList = ['persistentDataVersion', 'plans'];
    this.itemExcludeAttributeList = [
      'dateSubscriptionCreated',
      'subscriptionFee',
      'subscriptionID',
      'subscriptionAccountStatus',
      'subscriptionEmail',
      'subscriptionPaidThrough',
      'paymentRef',
      'picture'
    ];

    // set other data
    this.plan = this.dataModelService.dataModel.persistent.header.curplan;
    this.setPersistentAttributeLists();
    this.setPlanLists();
  } // ngOnInit

  public objectKeys(obj) {
    // if (obj === undefined) return [];  //%//  kludge to handle an undefined object
    return Object.keys(obj);
  } // objectKeys

  public updateDates() {
    this.dateRange.fromDate = this.dataModelService.changeDate(this.dateRange.yearMonth, 0, 0, 0).substr(0, 10); // YYYY-MM-DD  (first day of this month)
    this.dateRange.toDate = this.dataModelService.changeDate(this.dateRange.yearMonth, 0, 1, 0).substr(0, 10); // YYYY-MM-DD  (first day of next month)
    this.dateRange.toDate = this.dataModelService.changeDate(this.dateRange.toDate, 0, 0, -1).substr(0, 10); // YYYY-MM-DD  (last day of this month)
  } // updateDates

  public setPlanLists() {
    this.wizeFiCategoryList = [];
    this.wizeFiSubcategoryList = {};
    this.wizeFiAccountList = {};
    for (const category of Object.keys(this.dataModelService.dataModel.persistent.plans[this.plan])) {
      if (categoryExcludeList.indexOf(category) === -1) {
        this.wizeFiCategoryList.push(category);
        this.wizeFiSubcategoryList[category] = [];
        this.wizeFiAccountList[category] = {};
        for (const subcategory of Object.keys(this.dataModelService.dataModel.persistent.plans[this.plan][category])) {
          if (subcategoryExcludeList.indexOf(subcategory) === -1) {
            this.wizeFiSubcategoryList[category].push(subcategory);
            this.wizeFiAccountList[category][subcategory] = [];
            for (
              let acntndx = 0;
              acntndx < this.dataModelService.dataModel.persistent.plans[this.plan][category][subcategory].accounts.length;
              acntndx++
            ) {
              this.wizeFiAccountList[category][subcategory].push(acntndx);
            } // for acntndx
          } // if include subcategory
        } // for subcategory
      } // if include category
    } // for category
  } // setPlanLists

  public setPersistentAttributeLists() {
    this.persistentAttributesList = [];
    this.persistentItemsAttributeList = {};
    for (const persistentAttribute of Object.keys(this.dataModelService.dataModel.persistent)) {
      if (this.persistentExcludeAttributeList.indexOf(persistentAttribute) === -1) {
        this.persistentAttributesList.push(persistentAttribute);
        this.persistentItemsAttributeList[persistentAttribute] = [];
        for (const itemAttribute of Object.keys(this.dataModelService.dataModel.persistent[persistentAttribute])) {
          if (this.itemExcludeAttributeList.indexOf(itemAttribute) === -1) {
            this.persistentItemsAttributeList[persistentAttribute].push(itemAttribute);
          }
        } // for itemAttribute
      }
    } // for persistentAttribute
  } // setPersistentAttributeLists

  public openFastLink() {
    this.isOpen = true;

    let params = {};
    if (this.flowChoice === 'add' && this.providerId !== '') {
      params = { flow: this.flowChoice, providerId: this.providerId };
    }
    if ((this.flowChoice === 'edit' || this.flowChoice === 'refresh') && this.providerAccountId !== '') {
      params = { flow: this.flowChoice, providerAccountId: this.providerAccountId };
    }

    this.dataModelService.yodleeManagement
      .createUserToken(this.yodleeUserName)
      .then(userToken => {
        window.fastlink.open(
          {
            fastLinkURL: 'https://node.sandbox.yodlee.com/authenticate/restserver',
            jwtToken: 'Bearer ' + userToken,
            params,
            onSuccess: data => {
              console.log('onSuccess: ', data);
            },
            onError: data => {
              console.log('onError: ', data);
            },
            onExit: data => {
              console.log('onExit: ', data);
            },
            onEvent: data => {
              console.log('onEvents: ', data);
            }
          },
          'container-fastlink'
        );
      })
      .catch(err => {
        console.log('error in createUserToken: ', err);
      });
  } // openFastLink

  public closeFastLink() {
    this.isOpen = false;
    window.fastlink.close();
  } // closeFastLink

  public registerYodleeUser() {
    this.loading.isLoading = true;

    this.dataModelService.yodleeManagement
      .registerUser(this.newUserName, this.newUserEmail)
      .then(result => {
        console.log(result);
      })
      .catch(err => {
        console.log('error in registerUser: ', err);
      })
      .then(() => {
        this.loading.isLoading = false;
      });
  } // registerYodleeUser

  public deleteYodleeUser() {
    this.loading.isLoading = true;

    this.dataModelService.yodleeManagement
      .deleteUser(this.yodleeUserName)
      .then(result => {
        console.log(result);
      })
      .catch(err => {
        console.log('error in deleteUser: ', err);
      })
      .then(() => {
        this.loading.isLoading = false;
      });
  } // deleteYodleeUser

  public loadTransactionCategories() {
    const processResult = transactionCategories => {
      this.categoryAttributes =
        transactionCategories.categoryList === 0 ? (this.categoryAttributes = []) : Object.keys(transactionCategories.categoryList[0]);
      this.categoryList = transactionCategories.categoryList;
      this.categoryLookup = transactionCategories.categoryLookup;

      console.log('categoryAttributes: ', this.categoryAttributes); // %//
      console.log('categoryList: ', this.categoryList); // %//
      console.log('categoryLookup: ', this.categoryLookup); // %//
    }; // processResult

    this.loading.isLoading = true;

    this.dataModelService.yodleeManagement
      .getTransactionCategories(this.yodleeUserName)
      .then(processResult)
      .catch(err => {
        console.log('error in loadTransactionCategories: ', err);
      })
      .then(() => {
        this.loading.isLoading = false;
      });
  } // loadTransactionCategories

  public loadUserInfo() {
    const processResult = userInfo => {
      const userInfoAdjusted = {
        id: userInfo.id,
        loginName: userInfo.loginName,
        nameFirst: userInfo.name.first,
        nameLast: userInfo.name.last,
        roleType: userInfo.roleType
      };
      this.userInfo = userInfoAdjusted;
      this.userInfoAttributes = Object.keys(this.userInfo);

      console.log('userInfoAttributes: ', this.userInfoAttributes); // %//
      console.log('userInfo: ', this.userInfo); // %//
    }; // processResult

    this.loading.isLoading = true;

    this.dataModelService.yodleeManagement
      .getUserInfo(this.yodleeUserName)
      .then(processResult)
      .catch(err => {
        console.log('error in loadUserInfo: ', err);
      })
      .then(() => {
        this.loading.isLoading = false;
      });
  } // loadUserInfo

  public loadUserAccounts() {
    const processResult = userAccounts => {
      this.accountAttributes = userAccounts.accountList.length === 0 ? [] : Object.keys(userAccounts.accountList[0]);
      this.accountList = userAccounts.accountList;
      this.accountLookup = userAccounts.accountLookup;

      console.log('accountAttributes: ', this.accountAttributes); // %//
      console.log('accountList: ', this.accountList); // %//
      console.log('accountLookup: ', this.accountLookup); // %//
    }; // processResult

    this.loading.isLoading = true;

    this.dataModelService.yodleeManagement
      .getUserAccounts(this.yodleeUserName)
      .then(processResult)
      .catch(err => {
        console.log('error in loadUserAccounts: ', err);
      })
      .then(() => {
        this.loading.isLoading = false;
      });
  } // loadUserAccounts

  public loadUserTransactions() {
    const processResult = userTransactions => {
      this.transactionAttributes = userTransactions.length === 0 ? [] : Object.keys(userTransactions[0]);
      this.transactionList = userTransactions;

      // %//   \/
      console.log('transactionAttributes: ', this.transactionAttributes);
      console.log('transactions: ', this.transactionList);
      const transactionCount = this.transactionList.length;
      const bytesPerTransaction = Math.ceil(JSON.stringify(this.transactionList).length / transactionCount);
      const transactionsPerItem = Math.floor((400 * 1024) / bytesPerTransaction);
      console.log(
        'transactionList -- transactionCount:' +
          transactionCount +
          '  bytesPerTransaction:' +
          bytesPerTransaction +
          '  transactionsPerItem:' +
          transactionsPerItem
      );
      // %//   /\
    }; // processResult

    this.loading.isLoading = true;

    this.dataModelService.yodleeManagement
      .getUserTransactions(this.yodleeUserName, this.dateRange)
      .then(processResult)
      .catch(err => {
        console.log('error in loadUserTransactions: ', err);
      })
      .then(() => {
        this.loading.isLoading = false;
      });
  } // loadUserTransactions

  public wantTransaction(transaction) {
    return this.filterAttribute === 'none' || transaction[this.filterAttribute] === this.filterValue;
  } // wantTransaction

  public loadUserTransactionDetail() {
    const processGetTransactionCategoriesResult = transactionCategories => {
      this.categoryLookup = transactionCategories.categoryLookup;
      console.log('categoryLookup: ', this.categoryLookup); // %//
      return Promise.resolve();
    }; // processGetTransactionCategoriesResult

    const processGetUserAccountsResult = userAccounts => {
      this.accountLookup = userAccounts.accountLookup;
      console.log('accountLookup: ', this.accountLookup); // %//
      return Promise.resolve();
    }; // processGetUserAccountsResult

    const processGetUserTransactionsResult = userTransactions => {
      // create userTransactionDetail which contains additional object attributes added to userTransactions objects
      const transactionDetailList = [];
      for (const transaction of userTransactions) {
        // start with item containing all attributes of the transaction object
        const item = transaction;

        // add additional properties to the item
        item.highLevelCategoryName = this.categoryLookup[transaction.categoryId].highLevelCategoryName;
        item.accountType = this.accountLookup[transaction.accountId].accountType;
        item.providerName = this.accountLookup[transaction.accountId].providerName;
        item.accountName = this.accountLookup[transaction.accountId].accountName;

        // add this new item to the userTransactionDetail array
        transactionDetailList.push(item);
      }
      this.transactionDetailList = transactionDetailList;
      this.transactionDetailAttributes = this.transactionDetailList.length === 0 ? [] : Object.keys(this.transactionDetailList[0]);

      // set data to manage filter selection
      // this.filterAttributeList = ['none'];
      this.filterAttributeList = this.filterAttributeList.concat(this.transactionDetailAttributes);
      for (const transaction of userTransactions) {
        for (const attribute of this.filterAttributeList) {
          if (attribute !== 'none') {
            if (!this.filterValues.hasOwnProperty(attribute)) {
              this.filterValues[attribute] = [];
            }
            if (this.filterValues[attribute].indexOf(transaction[attribute]) === -1) {
              this.filterValues[attribute].push(transaction[attribute]);
            }
          }
        }
      }
      for (const key of Object.keys(this.filterValues)) {
        this.filterValues[key].sort();
      }

      console.log('transactionDetailAttributes: ', this.transactionDetailAttributes); // %//
      console.log('transactionDetailList: ', this.transactionDetailList); // %//
      console.log('size of transactionDetailList: ' + this.transactionDetailList.length + '  ' + JSON.stringify(this.transactionDetailList).length); // %//
      console.log('filterAttributeList: ', this.filterAttributeList); // %//
      console.log('filterValues: ', this.filterValues); // %//

      // %//   \/
      console.log('transactionDetailAttributes: ', this.transactionDetailAttributes);
      console.log('transactionDetailList: ', this.transactionDetailList);
      console.log('filterAttributeList: ', this.filterAttributeList);
      console.log('filterValues: ', this.filterValues);
      const transactionCount = this.transactionDetailList.length;
      const bytesPerTransaction = Math.ceil(JSON.stringify(this.transactionDetailList).length / transactionCount);
      const transactionsPerItem = Math.floor((400 * 1024) / bytesPerTransaction);
      console.log(
        'transactionDetailList -- transactionCount:' +
          transactionCount +
          '  bytesPerTransaction:' +
          bytesPerTransaction +
          '  transactionsPerItem:' +
          transactionsPerItem
      );
      // %//   /\

      return Promise.resolve();
    }; // processGetUserTransactionsResult

    this.loading.isLoading = true;

    this.dataModelService.yodleeManagement
      .getTransactionCategories(this.yodleeUserName)
      .then(processGetTransactionCategoriesResult)
      .then(() => this.dataModelService.yodleeManagement.getUserAccounts(this.yodleeUserName))
      .then(processGetUserAccountsResult)
      .then(() => this.dataModelService.yodleeManagement.getUserTransactions(this.yodleeUserName, this.dateRange))
      .then(processGetUserTransactionsResult)
      .catch(err => {
        console.log('error in loadUserTransactionDetail: ', err);
      })
      .then(() => {
        this.loading.isLoading = false;
      });
  } // loadUserTransactionDetail

  public loadTransactionAmounts() {
    const processGetUserTransactionsResult = userTransactions => {
      // compute sum of all amounts for each categoryId
      const transactionAmountLookup = {};
      for (const transaction of userTransactions) {
        if (!transactionAmountLookup.hasOwnProperty(transaction.categoryId)) {
          transactionAmountLookup[transaction.categoryId] = { amount: 0, categoryId: transaction.categoryId, category: transaction.category };
        }
        transactionAmountLookup[transaction.categoryId].amount += transaction.amount;
      }

      // place results in an array
      const transactionAmountList = [];
      for (const key of Object.keys(transactionAmountLookup)) {
        const item = transactionAmountLookup[key];
        item.amount = Number(item.amount.toFixed(2)); // round value to two decimal points
        transactionAmountList.push(item);
      }
      this.transactionAmountLookup = transactionAmountLookup;
      this.transactionAmountList = transactionAmountList;
      this.transactionAmountAttributes = this.transactionAmountList.length === 0 ? [] : Object.keys(this.transactionAmountList[0]);

      console.log('transactionAmountAttributes: ', this.transactionAmountAttributes); // %//
      console.log('transactionAmountList: ', this.transactionAmountList); // %//
      console.log('transactionAmountLookup: ', this.transactionAmountLookup); // %//
      return Promise.resolve();
    }; // processGetUserTransactionsResult

    this.loading.isLoading = true;

    this.dataModelService.yodleeManagement
      .getUserTransactions(this.yodleeUserName, this.dateRange)
      .then(processGetUserTransactionsResult)
      .catch(err => {
        console.log('error in loadTransactionAmounts: ', err);
      })
      .then(() => {
        this.loading.isLoading = false;
      });
  } // loadTransactionAmounts
} // class AdminTestYodleeComponent
