import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { SetupFlowDataManagementService, SetupFlowScreen } from '../../../services/setup/setup-flow-data-management.service';
import { SetupService } from '@app/services/setup.service';
import { NewAccountDialogComponent, NewAccountDialogResult } from '@app/components/new-account-dialog/new-account-dialog.component';
import { filter } from 'rxjs/operators';
import { PlaidAccount } from '@app/interfaces/plaid-account';
import { TransactionsCollection } from '@app/interfaces/plaid-data';

@Component({
  selector: 'app-review-accounts',
  templateUrl: './review-accounts.component.html',
  styleUrls: ['./review-accounts.component.scss']
})
export class ReviewAccountsComponent implements OnInit, SetupFlowScreen {
  public loading = true;
  private transactionsYearMonth: string;

  constructor(
    public dataModelService: DataModelService,
    dataManagement: SetupFlowDataManagementService,
    private dialog: MatDialog,
    private setupService: SetupService
  ) {
    dataManagement.currentSetupScreen = this;
  }

  public async ngOnInit() {
    this.setTransactionsYearMonth();
    window.sessionStorage.setItem('reloginUrl', '/review-accounts');
    this.setupService.updateCurrentSetupStep(1);
    await this.dataModelService.dataManagement.storeinfo();
    await this.dataModelService.plaidManagement.getPlaidData();
    await this.checkForOrphanAccounts();
    this.loading = false;
  }

  private async checkForOrphanAccounts() {
    const accounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    // eslint-disable-next-line no-shadow
    accounts.forEach((acct, index, accounts) => {
      if (acct && acct.institution_id) {
        const found = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions.some(
          (inst: { institution_id: any }) => inst.institution_id === acct.institution_id
        );
        if (!found) {
          acct.status = 2;
          accounts.splice(index, 1);
        }
      }
    });
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    const title = this.dataModelService.dataManagement.getDraftTitle();
    await this.dataModelService.plaidManagement.storePlaidAccounts(wizeFiID, title, accounts);
  }

  // SetupFlowScreen data saving methods

  public canSave(): boolean {
    const wizeFiPlaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    const hasAccountsPendingActivation = wizeFiPlaidAccounts.some(account => account.status === '0' || account.status === 0);
    return !hasAccountsPendingActivation;
  }

  public async onSave() {
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    const title = this.dataModelService.dataManagement.getDraftTitle();
    const plaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    await this.dataModelService.plaidManagement.storePlaidAccounts(wizeFiID, title, plaidAccounts);

    await this.updateWizeFiTransactions();
  }

  public showOnSaveErrorMessage() {
    this.dataModelService.showMessage('error', 'Please activate all pending accounts before continuing', 20000);
  }

  // Component methods

  protected getInstitutions() {
    return this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions;
  }

  /**
   * Load transactions from plaid after the user has gone through the setup of linking accounts and
   * before the user gets to the spending screen.
   *
   * @TODO might want to load entire previous year of transactions? for now it is just the prev month
   */
  private async updateWizeFiTransactions() {
    const transactionsCollection = await this.getUpdatedWizeFiTransactions();
    await this.saveUpdatedWizeFiTransactions(transactionsCollection);
    this.updateOrigTransactionsYearMonthIfNeeded();
  }

  private async getUpdatedWizeFiTransactions() {
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    const wizeFiPlaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    if (wizeFiPlaidAccounts && wizeFiPlaidAccounts !== undefined) {
      const activeWizeFiPlaidAccountIds = this.dataModelService.plaidManagement.setActiveWizeFiPlaidAccountIds(wizeFiPlaidAccounts);
      const title = this.dataModelService.dataManagement.getDraftTitle();
      const transactionInfo = await this.dataModelService.plaidManagement.getTransactions(
        wizeFiID,
        title,
        { wantMonthRange: true, yearMonth: this.transactionsYearMonth },
        activeWizeFiPlaidAccountIds
      );
      const wizeFiTransactionsCollection = await this.dataModelService.dataManagement.getwizeFiTransactionsCollection(
        wizeFiID,
        this.transactionsYearMonth,
        this.transactionsYearMonth
      );
      this.removeTransactionsFromInactiveAccounts(wizeFiPlaidAccounts, wizeFiTransactionsCollection);
      this.addTransactionsFromNewlyActivatedAccounts(wizeFiPlaidAccounts, wizeFiTransactionsCollection, transactionInfo);

      return wizeFiTransactionsCollection;
    }
  }

  private removeTransactionsFromInactiveAccounts(wizeFiPlaidAccounts: any, wizeFiTransactionsCollection: any) {
    if (wizeFiTransactionsCollection && wizeFiTransactionsCollection[this.transactionsYearMonth]) {
      wizeFiTransactionsCollection[this.transactionsYearMonth] = wizeFiTransactionsCollection[this.transactionsYearMonth].filter(
        (transaction: { account_id: any }) => {
          const plaidAccount = (wizeFiPlaidAccounts as any[]).find(account => transaction.account_id === account.account_id);
          return plaidAccount && plaidAccount.isActive && plaidAccount.status === '1';
        }
      );
    }
  }

  private addTransactionsFromNewlyActivatedAccounts(
    wizeFiPlaidAccounts: PlaidAccount[],
    wizeFiTransactionsCollection: TransactionsCollection,
    transactionInfo: { transactions: any }
  ) {
    for (const transaction of transactionInfo.transactions) {
      if (wizeFiTransactionsCollection && this.isNewPlaidTransaction(transaction, wizeFiTransactionsCollection[this.transactionsYearMonth])) {
        delete transaction.category_id;
        transaction.isManual = false;
        transaction.wizeFiCategory = 'unknown';
        transaction.splitStatus = 0;
        const plaidAccount = wizeFiPlaidAccounts.find(account => transaction.account_id === account.account_id);
        if (wizeFiTransactionsCollection && plaidAccount && plaidAccount.isActive && plaidAccount.status === '1') {
          wizeFiTransactionsCollection[this.transactionsYearMonth].push(transaction);
        }
      }
    }
  }

  private isNewPlaidTransaction(transaction: { transaction_id: any }, wizeFiTransactions: string | any[]) {
    let isNew = true;
    let i = -1;
    while (++i < wizeFiTransactions.length && isNew) {
      if (transaction.transaction_id === wizeFiTransactions[i].transaction_id) {
        isNew = false;
      }
    }
    return isNew;
  }

  private async saveUpdatedWizeFiTransactions(transactionCollection: any) {
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    await this.dataModelService.dataManagement.putWizeFiTransactions(
      wizeFiID,
      this.transactionsYearMonth,
      transactionCollection[this.transactionsYearMonth]
    );
    await this.dataModelService.plaidManagement.getPlaidData();
  }

  private updateOrigTransactionsYearMonthIfNeeded() {
    const header = this.dataModelService.dataModel.persistent.header;
    if (header.origTransactionsYearMonth === '') {
      header.origTransactionsYearMonth = this.transactionsYearMonth;
    }
  }

  private setTransactionsYearMonth() {
    const header = this.dataModelService.dataModel.persistent.header;
    if (header.curplan === 'original') {
      const origTransactionsYearMonth = header.origTransactionsYearMonth;
      const lastMonth = moment(new Date()).subtract(1, 'month').toISOString().substr(0, 7);
      this.transactionsYearMonth = origTransactionsYearMonth || lastMonth;
    } else {
      this.transactionsYearMonth = header.curplanYearMonth;
    }
  }

  public openNewAccountDialog() {
    this.dialog
      .open<NewAccountDialogComponent, any, NewAccountDialogResult>(NewAccountDialogComponent)
      .afterClosed()
      .pipe(filter(result => !!result))
      .subscribe(() => {});
  }
}
