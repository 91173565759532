import { CanDeactivate } from '@angular/router';
import { IVerifyResult } from '../../interfaces/iVerifyResults.interface';
import { SettingsComponent } from './settings.component';
export class SettingsDeactivateGuard implements CanDeactivate<SettingsComponent> {
  /*
  // version from Jeff  //%//
  canDeactivate(settingsComponent: SettingsComponent):Promise<boolean> {
    // update application data model and proceed to next screen
    return new Promise((resolve,reject) => {
      // process results
      let result:IVerifyResult = settingsComponent.cSettings.verifyAllDataValues();
      if (result.hadError) {
        // report errors and remain on current screen
        settingsComponent.messages = result.messages;
        console.log('error', 'Unable to update data');
        reject(false);
      }
      else{
        try
        {
          settingsComponent.update().catch((err) => {throw err;}).then(() => {
          resolve(true);
          });
        }
        catch (ex)
        {
          console.log(ex);  //%//
          console.log('error', 'Unable to update data');
          reject(false);
        }
      }
    });
  } // canDeactivate
  */

  public canDeactivate(settingsComponent: SettingsComponent): Promise<boolean> {
    // update application data model and proceed to next screen
    return new Promise((resolve, reject) => {
      let hadError = false;
      let messages: string[] = [];
      let result: IVerifyResult;

      // process results
      result = settingsComponent.cSettings.verifyAllDataValues();
      if (result.hadError) {
        hadError = true;
        messages = messages.concat(result.messages);
      }

      result = settingsComponent.verifyProfileValues();
      if (result.hadError) {
        hadError = true;
        messages = messages.concat(result.messages);
      }

      if (hadError) {
        // report errors and remain on current screen
        settingsComponent.dataModelService.showMessages('error', messages, 7000);
        resolve(false); // what happens if we use reject here?  (get uncaught promise error later?)
      } else {
        settingsComponent
          .update()
          .then(() => {
            resolve(true);
          })
          .catch(err => {
            // report errors and remain on current screen
            //console.log("error -- Unable to update data");  // %//
            //console.log(err);                               // %//
            settingsComponent.dataModelService.showMessage('error', 'Unable to update data', 7000);
            resolve(false); // what happens if we use reject here?  (get uncaught promise error later?)
          });
      }
    }); // return promise
  } // canDeactivate
} // class SettingsDeactivateGuard
