import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { CAssetProtection } from '../../../utilities/assets/asset-protection.class';
import { CAssets } from '../../../utilities/assets/assets.class';
import { CBudget } from '../../../utilities/budget/budget.class';
import { GenericDataManagement } from '../../../utilities/generic-data-management.class';
import { CIncome } from '../../../utilities/income/income.class';
import { CLiabilities } from '../../../utilities/liabilities/liabilities.class';
import { CProfile } from '../../../utilities/profile.class';
import { shadowAccounts } from '../../../utilities/shadow-accounts.util';
import { FourStepPlanCategoryComponent } from '../four-step-plan-category/four-step-plan-category.component';
@Component({
  selector: 'app-apply-cash-difference',
  templateUrl: './apply-cash-difference.component.html',
  styleUrls: ['./apply-cash-difference.component.scss']
})
export class ApplyCashDifferenceComponent implements OnInit {
  @ViewChildren('fourStepPlanItem')
  public fourStepPlanCategoryComponents: QueryList<FourStepPlanCategoryComponent>;
  public isLoading = true;
  public guidelineCafr: number;
  public totalLastMonthSpending: number;
  public totalAppliedSpending: number;
  public totalPlannedSpending: number;
  public totalCafrUsed: number;
  public cafrCreated: number;
  public cafrInfo: any;
  private cafrManagement;
  public showGuidelines = true;
  protected budgetShadowAccounts: any;
  protected incomeShadowAccounts: any;
  protected gdBudget: GenericDataManagement;
  protected gdIncome: GenericDataManagement;
  protected gdAssets: GenericDataManagement;
  protected gdAssetProtection: GenericDataManagement;
  protected gdLiabilities: GenericDataManagement;
  protected cProfile: CProfile;
  protected cIncome: CIncome;
  protected cBudget: CBudget;
  protected cAssets: CAssets;
  protected cAssetProtection: CAssetProtection;
  protected cLiabilities: CLiabilities;
  protected messages: string[] = [];
  public inSetup = this.isInSetup();

  private allCategories: any;

  //public plan;
  public previousPlan;
  public planDates;
  public wizeFiCategory2MonthlyAmount;

  constructor(protected dataModelService: DataModelService, protected router: Router) {
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;
    const plan = this.dataModelService.dataModel.persistent.plans[curplan];
    this.cafrManagement = this.dataModelService.cafrManagement;
    this.allCategories = {
      income: plan.income,
      budget: plan.budget,
      assets: plan.assets,
      assetProtection: plan.assetProtection,
      liabilities: plan.liabilities
    };
    // this.plan = this.dataModelService.dataModel.persistent.header.curplan;
    this.planDates = this.dataModelService.categoryManagement.obtainPlanDatesInfo();
    this.previousPlan = this.dataModelService.categoryManagement.getPreviousPlan(curplan, this.planDates);
  }

  public ngOnInit() {
    this.loadPreviousPlanIntoMemory();
    this.wizeFiCategory2MonthlyAmount = this.dataModelService.categoryManagement.getWizeFiCategory2monthlyAmount(this.previousPlan);
    this.cafrInfo = this.getCafrInfo();
    setTimeout(() => {
      this.setTotalGuidelineCafr();
      this.setTotalLastMonthSpending();
      this.setTotalAppliedSpending();
      this.setTotalPlannedSpending();
      this.setTotalCAFRSum();
      this.setCafrCreated();
    });
    this.isLoading = false;
    this.cProfile = new CProfile(this.dataModelService.getdata('profile'));

    this.cAssets = new CAssets(this.dataModelService.getdata('assets'));
    this.gdAssets = new GenericDataManagement(this.dataModelService, this.cAssets.assets, this.messages);

    this.cAssetProtection = new CAssetProtection(this.dataModelService.getdata('assetProtection'));
    this.gdAssetProtection = new GenericDataManagement(this.dataModelService, this.cAssetProtection.assetProtection, this.messages);

    this.cBudget = new CBudget(this.dataModelService.getdata('budget'));
    this.gdBudget = new GenericDataManagement(this.dataModelService, this.cBudget.budget, this.messages);

    this.cIncome = new CIncome(this.dataModelService.getdata('income'));
    this.gdIncome = new GenericDataManagement(this.dataModelService, this.cIncome.income, this.messages);

    this.cLiabilities = new CLiabilities(this.dataModelService.getdata('liabilities'));
    this.gdLiabilities = new GenericDataManagement(this.dataModelService, this.cLiabilities.liabilities, this.messages);

    this.incomeShadowAccounts = shadowAccounts(
      'incomeShadowAccounts',
      'monthlyIncome',
      this.gdBudget,
      this.gdIncome,
      this.gdAssets,
      this.gdAssetProtection,
      this.gdLiabilities,
      this.cProfile,
      this.cIncome,
      this.cBudget,
      this.cAssets,
      this.cAssetProtection,
      this.cLiabilities
    );
    this.budgetShadowAccounts = shadowAccounts(
      'budgetShadowAccounts',
      'monthlyMinimum',
      this.gdBudget,
      this.gdIncome,
      this.gdAssets,
      this.gdAssetProtection,
      this.gdLiabilities,
      this.cProfile,
      this.cIncome,
      this.cBudget,
      this.cAssets,
      this.cAssetProtection,
      this.cLiabilities
    );

    /* console.log(this.incomeShadowAccounts);
    console.log(this.budgetShadowAccounts); */
  }

  private async loadPreviousPlanIntoMemory() {
    return await this.dataModelService.categoryManagement.loadPreviousPlan(this.previousPlan, this.planDates).catch(err => {
      /*  console.log(err);
      console.log(this.dataModelService.dataModel.persistent.plans); */
    });
  }

  private getCafrInfo() {
    const extraGuidelineCAFR = { extra: 0 };
    const curDate = new Date().toISOString();
    const curYearMonth = {
      year: Number(curDate.substr(0, 4)),
      month: Number(curDate.substr(5, 2)) - 1 // note: 0 <= month <= 11 to accomodate JavaScript conventions
    };
    return this.cafrManagement.getCafrInfo(
      'guideline',
      this.cafrManagement.yearMonth2YYYYMM(curYearMonth),
      this.allCategories.income,
      this.allCategories.budget,
      this.allCategories.assets,
      this.allCategories.assetProtection,
      this.allCategories.liabilities,
      true,
      0,
      extraGuidelineCAFR
    );
  }

  public ngAfterViewInit() {}

  public onSave(): boolean {
    if (this.cafrCreated < this.totalCafrUsed) {
      return false;
    } else {
      return true;
    }
  }

  private setTotalGuidelineCafr() {
    if (this.fourStepPlanCategoryComponents) {
      this.guidelineCafr = this.fourStepPlanCategoryComponents.reduce(
        (totalPlanned, stepComponent) => (totalPlanned += stepComponent.guidelineSum !== undefined ? stepComponent.guidelineSum : 0),
        0
      );
    } else {
      return 0;
    }
  }

  private setTotalLastMonthSpending() {
    const getLastMonthStepSpending = stepComponent => {
      if (stepComponent && stepComponent.wizeFiAccounts) {
        return stepComponent.wizeFiAccounts.reduce((stepTotal, account) => (stepTotal += this.getLastMonthsMonthlyAmount(account) || 0), 0);
      } else {
        return 0;
      }
    };
    if (this.fourStepPlanCategoryComponents) {
      this.totalLastMonthSpending = this.fourStepPlanCategoryComponents.reduce(
        (totalLastMonth, stepComponent) => (totalLastMonth += getLastMonthStepSpending(stepComponent)),
        0
      );
    } else {
      return 0;
    }
  }

  private setTotalAppliedSpending() {
    const getAppliedStepSpending = stepComponent => {
      if (stepComponent && stepComponent.wizeFiAccounts) {
        return stepComponent.wizeFiAccounts.reduce(
          (stepTotal, account) =>
            /*           console.log(stepComponent);
             */ (stepTotal += account.actualMonthlyAmount.val),
          0
        );
      } else {
        return 0;
      }
    };
    if (this.fourStepPlanCategoryComponents) {
      this.totalAppliedSpending = this.fourStepPlanCategoryComponents.reduce(
        (totalPlanned, stepComponent) => (totalPlanned += getAppliedStepSpending(stepComponent)),
        0
      );
    } else {
      return 0;
    }
  }

  private getLastMonthsMonthlyAmount(account) {
    const category = account.wizeFiCategory.val;
    let value = 0;
    for (const cat in this.wizeFiCategory2MonthlyAmount) {
      if (cat === category) {
        value = this.dataModelService.categoryManagement.getMonthlyAccount(this.wizeFiCategory2MonthlyAmount, category);
        if (this.isBasedOnExtraYouPayValue(account)) {
          value -= this.dataModelService.categoryManagement.getMonthlyMinimumAccount(this.wizeFiCategory2MonthlyAmount, category);
        }
      }
    }
    return value;
  }

  private isBasedOnExtraYouPayValue(account): boolean {
    return account.budgetSubcategory && account.budgetSubcategory.val !== '';
  }

  protected updateTotals() {
    this.setTotalPlannedSpending();
    this.setTotalCAFRSum();
  }

  protected setTotalPlannedSpending() {
    if (this.fourStepPlanCategoryComponents) {
      this.totalPlannedSpending = this.fourStepPlanCategoryComponents.reduce(
        (totalPlanned, stepComponent) => (totalPlanned += stepComponent.plannedSum !== undefined ? stepComponent.plannedSum : 0),
        0
      );
    } else {
      return 0;
    }
  }

  protected setTotalCAFRSum() {
    if (this.fourStepPlanCategoryComponents) {
      this.totalCafrUsed = this.fourStepPlanCategoryComponents.reduce(
        (totalPlanned, stepComponent) => (totalPlanned += stepComponent.cafrSum !== undefined ? stepComponent.cafrSum : 0),
        0
      );
    } else {
      return 0;
    }
  }

  private getValueToAccumulate(account) {
    if (account) {
      const monthlyAmount = account.monthlyAmount && account.monthlyAmount.val ? account.monthlyAmount.val : 0;
      const monthlyMinimum = account.monthlyMinimum && account.monthlyMinimum.val ? account.monthlyMinimum.val : 0;
      const extraPaid = monthlyAmount - monthlyMinimum;
      return this.isBasedOnExtraYouPayValue(account) ? extraPaid : monthlyAmount;
    } else {
      return 0;
    }
  }

  private setCafrCreated() {
    this.cafrCreated = this.dataModelService.cafrManagement.getCAFR('actual', this.allCategories, 'availableActualCAFR');
  }

  protected isInSetup() {
    const header = this.dataModelService.dataModel.persistent.header;
    return !header.dateOrigPlanCompleted || this.router.url.includes('setup/apply-cafr');
  }

  protected isInEditMode() {
    return this.router.url.includes('plan/edit-CAFR');
  }
}
