import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  @Input() public currentProgress: number;
  @Input() public targetValue: number;

  public constructor() {}

  public ngOnInit() {}

  public getBarWidthPercentage() {
    const percentComplete = (this.currentProgress / this.targetValue) * 100;
    return percentComplete > 100 ? 100 : percentComplete < 0 ? 0 : percentComplete;
  }
}
