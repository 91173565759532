import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { DataModelService } from '../../services/data-model/data-model.service';
import { DataStorage } from '../../utilities/data-storage.class';
declare const gtag: any;
declare const ga: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title = 'WizeFi';
  public debug = false;
  public consoleMethods: string[] = ['error'];
  public loading = this.dataModelService.loginManagement.loading;

  constructor(
    protected router: Router,
    private activatedRoute: ActivatedRoute,
    private dataModelService: DataModelService,
    private dataStorage: DataStorage
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url !== '/relogin') {
          this.dataModelService.AnalyticsTrackPage();
          this.dataModelService.AnalyticsTrackEvent('go to page', {
            label: 'go to page',
            category: 'navigation',
            value: event.urlAfterRedirects
          });
        }

        let parentAffiliateAlias = '';
        if (this.dataModelService.dataModel.persistent.header.parentAffiliateID && this.dataModelService.affiliateManagement.tree) {
          const parentAffiliate = this.dataModelService.affiliateManagement.tree[this.dataModelService.dataModel.persistent.header.parentAffiliateID];
          if (parentAffiliate) {
            parentAffiliateAlias = parentAffiliate.affiliateAlias;
          }
        }

        // Intercom - upsert user data
        (window as any).Intercom('update', {
          app_id: environment.IntercomID,
          email: this.dataModelService.dataModel.persistent.profile.email,
          user_id: this.dataModelService.dataModel.global.wizeFiID,
          name: this.dataModelService.dataModel.persistent.profile.nameFirst + ' ' + this.dataModelService.dataModel.persistent.profile.nameLast,
          affiliate_id: this.dataModelService.dataModel.affiliateID,
          affiliate_alias: this.dataModelService.dataModel.affiliateAlias,
          parent_affiliate_id: this.dataModelService.dataModel.persistent.header.parentAffiliateID,
          parent_affiliate_alias: parentAffiliateAlias,
          schema_v: this.dataModelService.dataModel.topLevelVersion,
          schema_persistent_v: this.dataModelService.dataModel.persistent.persistentDataVersion,
          account_status: this.dataModelService.dataModel.persistent.header.wizeFiAccountStatus,
          // TODO review when the braintreeData becomes available, and whether is is present in the lines below
          subscribe_date: '', // data not present at the time this is invoked: this.dataModelService.dataModel.global.braintreeData.subscriptionInfo.createdAt,  //%//
          subscription_id: '', // data not present  this.dataModelService.dataModel.global.braintreeData.subscriptionInfo.subscriptionID,  //%//
          subscription_fee: '', // data not present  this.dataModelService.dataModel.global.braintreeData.subscriptionInfo.price,  //%//
          subscription_status: '', // data not present  this.dataModelService.dataModel.global.braintreeData.subscriptionInfo.status,  //%//
          subscription_paid_through: '', // data not present  this.dataModelService.dataModel.global.braintreeData.subscriptionInfo.paidThroughDate,  //%//
          payment_ref: '', // data not present  this.dataModelService.dataModel.global.braintreeData.subscriptionInfo.last4,  //%//
          payout_status: this.dataModelService.dataModel.persistent.header.payoutAccountStatus,
          payout_email: this.dataModelService.dataModel.persistent.header.payoutEmail,
          current_plan: this.dataModelService.dataModel.persistent.header.curplan,
          setup_complete_date: this.dataModelService.dataModel.persistent.header.dateProfileCompleted,

          name_first: this.dataModelService.dataModel.persistent.profile.nameFirst,
          name_last: this.dataModelService.dataModel.persistent.profile.nameLast,
          occupation: this.dataModelService.dataModel.persistent.profile.occupation,
          zip: this.dataModelService.dataModel.persistent.profile.zipCode,
          birth_date: this.dataModelService.dataModel.persistent.profile.birthDate,
          retirement_age: this.dataModelService.dataModel.persistent.profile.retirementAge,
          emergency_savings_target: this.dataModelService.dataModel.persistent.profile.emergencySavingsTargetAmount,
          cash_reserves_target: this.dataModelService.dataModel.persistent.profile.cashReservesTargetAmount,
          financial_freedom_target: this.dataModelService.dataModel.persistent.profile.financialFreedomTargetAmount,
          custom_launcher_selector: '.support-trigger'
        });
        (window as any).Intercom('onUnreadCountChange', unreadCount => {
          const btn = document.getElementsByClassName('support-message-counter');
          const toggle = document.getElementsByClassName('toggle-with-counter');
          if (btn.length > 0) {
            if (unreadCount > 0) {
              (btn[0] as any).classList.remove('hide');
              (btn[0] as any).innerText = unreadCount;

              if (toggle.length > 0) {
                (toggle[0] as any).classList.add('hide');
              }
            } else {
              (btn[0] as any).classList.add('hide');
              if (toggle.length > 0) {
                (toggle[0] as any).classList.remove('hide');
              }
            }
          }
        });
      }
    });
  }

  public ngOnInit() {
    // refresh survival tactic without the store.
    const logins = JSON.parse(window.sessionStorage.getItem('logins'));
    if (this.loading) {
      this.loading.isLoading = false;
    }
    if (logins) {
      this.dataModelService.analyticsIdentifyUser(this.dataModelService.dataModel.global.wizeFiID);
    }
    if (environment.production === false) {
      this.dataModelService.AnalyticsTrackEvent('environment', {
        label: 'environment',
        category: 'environment',
        value: 'develop'
      });
      // @TODO: determine if the environment is production or not before setting this.
      this.consoleMethods = ['log', 'info', 'warn', 'error'];
    } else {
      this.dataModelService.AnalyticsTrackEvent('environment', {
        label: 'environment',
        category: 'environment',
        value: 'production'
      });
    }
    // console.log("app.component -- ngOnInit"); // %//
    console = this.customConsole(this.consoleMethods, 'WizeFi');
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      // console.log('queryParams',params);
      this.dataModelService.dataModel.global.debug = false;
      if (parseInt(params.debug, 2) === 1) {
        this.debug = true;
        this.dataModelService.dataModel.global.debug = true;
      }
      this.dataModelService.dataModel.global.screen = '';
      if (params.hasOwnProperty('screen')) {
        this.dataModelService.dataModel.global.screen = params.screen;
      }
      this.dataModelService.dataModel.global.debugID = '';
      if (params.hasOwnProperty('debugID')) {
        this.dataModelService.dataModel.global.debugID = params.debugID;
      }
    });
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  } // ngOnInit

  public customConsole(methodsEnabled: any[], prefix: string): any {
    const debug: any = {};
    for (const m in console) {
      if (typeof console[m] === 'function') {
        if (methodsEnabled.length > 1 && methodsEnabled.indexOf(m) > -1) {
          debug[m] = prefix ? console[m].bind(window.console, prefix + ':') : console[m].bind(window.console);
        } else {
          // void function
          debug[m] = () => {};
        }
      }
    }
    return debug;
  }

  public ngOnDestroy() {}
} // class AppComponent
