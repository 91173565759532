import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-user-start',
  templateUrl: './new-user-start.component.html',
  styleUrls: ['./new-user-start.component.scss']
})
export class NewUserStartComponent implements OnInit {
  constructor() {}

  public ngOnInit() {}
}
