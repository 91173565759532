import { IVerifyResult } from '../interfaces/iVerifyResults.interface';

export class CValidityCheck {
  public static checkInteger(object: any, item: string, result: IVerifyResult) {
    const str = String(object[item]);
    if (!str.match(/^[0-9]+$/)) {
      result.hadError = true;
      result.messages.push('Must enter whole number value for ' + item);
    }
  } // checkInteger

  public static checkInteger2(object: any, lev1: string, lev2: string, result: IVerifyResult) {
    const str = String(object[lev1][lev2]);
    if (!str.match(/^[0-9]+$/)) {
      result.hadError = true;
      result.messages.push('Must enter whole number value for ' + lev1 + '.' + lev2);
    }
  } // checkInteger2

  public static checkInteger3(object: any, lev1: string, lev2: string, lev3: string, result: IVerifyResult) {
    const str = String(object[lev1][lev2][lev3]);
    if (!str.match(/^[0-9]+$/)) {
      result.hadError = true;
      result.messages.push('Must enter whole number value for ' + lev1 + '.' + lev2 + '.' + lev3);
    }
  } // checkInteger3

  public static checkInteger4(category: any, subcat: string, actndx: number, field: string, result: IVerifyResult) {
    const str = String(category[subcat].accounts[actndx][field].val);
    if (!str.match(/^[0-9]+$/)) {
      result.hadError = true;
      result.messages.push(
        'Must enter whole number value for ' +
          category[subcat].label +
          '->' +
          category[subcat].accounts[actndx].accountName.val +
          '->' +
          category[subcat].accounts[actndx][field].label
      );
    }
  } // checkInteger4

  public static checkAttributeNameValidity(attributeName: string, val: string, messages: string[]): boolean {
    let hadError = false;
    const attrbiuteNamePattern = /^([a-zA-Z][a-zA-Z0-9_]*)$/;
    if (!val.match(attrbiuteNamePattern)) {
      hadError = true;
      messages.push('Attribute name for ' + attributeName + ' must start with a letter, and contain only letters, digits, or underscore');
    }
    return hadError;
  } // checkAttributeNameValidity

  public static isIterable(obj) {
    // checks for null and undefined
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
  }

  constructor() {}
}
