import { Component, OnInit } from '@angular/core';
import { DataModelService } from 'src/app/services/data-model/data-model.service';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-country-picker',
  templateUrl: './country-picker.component.html',
  styleUrls: ['./country-picker.component.scss']
})
export class CountryPickerComponent implements OnInit {
  public availableCountries: Array<{ countryCode: string; countryName: string }>;
  public selectedCountryCodes: string[];

  constructor(private dataModelService: DataModelService, public dialogRef: MatDialogRef<CountryPickerComponent>) {}

  public ngOnInit() {
    // get all available countries: this.dataModelService.ancillaryDataManagement.plaidCountryCodeItems.sort((a, b) => this.sortFunction(a, b));
    this.availableCountries = this.dataModelService.ancillaryDataManagement.plaidCountryCodeItems.sort((a, b) => this.sortFunction(a, b));
    // filter out EU Countries for now...
    this.availableCountries = this.dataModelService.ancillaryDataManagement.plaidCountryCodeItems.filter(
      country => country.countryCode === 'US' || country.countryCode === 'CA'
    );
    this.selectedCountryCodes = [...this.dataModelService.dataModel.persistent.settings.userCountryCodes];
  }

  public toggleSelection(newValue: boolean, countryCode: string): void {
    if (newValue) {
      this.selectedCountryCodes.push(countryCode);
    } else {
      this.selectedCountryCodes.splice(
        this.selectedCountryCodes.findIndex(i => i === countryCode),
        1
      );
    }
  }

  public confirmSelection(): void {
    this.dataModelService.dataModel.persistent.settings.userCountryCodes = this.selectedCountryCodes;
    this.dialogRef.close(true);
  }

  private sortFunction(a: any, b: any): number {
    if (a.countryCode === 'US') {
      return -1;
    } else if (b.countryCode === 'US') {
      return 1;
    } else {
      return a.countryCode - b.countryCode;
    }
  }
}
