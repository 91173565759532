import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IGraphItem } from '../../../interfaces/iGraphItem.interface';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { NetWorthProjectionComponent } from '../net-worth-projection/net-worth-projection.component';

@Component({
  selector: 'app-new-plan-projections',
  templateUrl: './new-plan-projections.component.html',
  styleUrls: ['./new-plan-projections.component.scss']
})
export class NewPlanProjectionsComponent extends NetWorthProjectionComponent {
  public datasets: Array<{ data: IGraphItem[]; color: string }>;
  public isLoading = true;

  constructor(public dataModelService: DataModelService, public router: Router) {
    super(dataModelService);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.initializeData();
    this.isLoading = false;
  }

  private initializeData() {
    this.datasets = [
      { data: this.originalFFData, color: this.black },
      { data: this.currentFFData, color: this.lightBlue },
      { data: this.wizeFiFFData, color: this.purple }
    ];
  }
}
