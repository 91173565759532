import { Component, OnInit } from '@angular/core';
import { MenuComponent } from '../menu.component';

@Component({
  selector: 'app-hamburger-button',
  templateUrl: './hamburger.component.html',
  styleUrls: ['./hamburger.component.scss']
})
export class HamburgerButton extends MenuComponent implements OnInit {
  constructor() {
    super();
  }

  public body = document.getElementsByTagName('body')[0];
  public display = true;

  public ngOnInit() {
    this.display = !this.body.classList.contains('login');
  }
}
