import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { INoncePayload } from '../../../interfaces/iNoncePayload.interface';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { CProfile } from '../../../utilities/profile.class';

declare const braintree: any;

@Component({
  selector: 'app-subscription-signup',
  templateUrl: './subscription-signup.component.html',
  styleUrls: ['./subscription-signup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubscriptionSignupComponent implements OnInit {
  @Output() private loading = new EventEmitter<boolean>();

  public isLoading = false;
  private braintreeWidgetInstance: any;
  public cProfile: CProfile;

  constructor(private dataModelService: DataModelService) {}

  public ngOnInit() {
    this.cProfile = new CProfile(this.dataModelService.getdata('profile'));
    this.establishPaymentControl();
  }

  public register(): void {
    if (this.braintreeWidgetInstance) {
      this.isLoading = true;
      this.loading.emit(this.isLoading);

      this.braintreeWidgetInstance.requestPaymentMethod((requestPaymentMethodErr, noncePayload) => {
        if (requestPaymentMethodErr) {
          console.log('Error in requestPaymentMethod: ', requestPaymentMethodErr);
          this.dataModelService.showMessage('error', 'Error in establishing payment method widget');
          this.isLoading = false;
          this.loading.emit(this.isLoading);
        } else {
          this.establishSubscription(noncePayload);
        }
      });
    }
  }

  public establishPaymentControl() {
    // Sample credit card number: 4111111111111111
    this.dataModelService.braintreeManagement
      .generateClientToken()
      .then(token => this.establishPaymentMethodWidget(token))
      .catch(err => {
        console.error('Error in establishing payment method widget', err);
        this.dataModelService.showMessage('error', 'Error in establishing payment method widget');
      });
  }

  private establishPaymentMethodWidget(clientToken: string) {
    braintree.dropin.create({ authorization: clientToken, container: '#braintree-widget-container' }, (createErr, instance) => {
      if (createErr) {
        console.error('Error in dropin.create: ' + createErr);
      } else {
        this.braintreeWidgetInstance = instance;
      }
    });
  }

  private establishSubscription(noncePayload): void {
    if (!this.validateProfile()) {
      this.isLoading = false;
      this.loading.emit(this.isLoading);
      return;
    }

    this.dataModelService.putdata('profile', this.cProfile.profile);
    this.dataModelService.dataManagement
      .storeinfo()
      .then(() => this.makeSubscription(noncePayload))
      .then(r => this.dataModelService.updateBraintreeData(r)) // refresh Braintree data after subscription change
      .then(r => this.finishSubscription(r))
      .catch(err => {
        console.error(err);
        this.isLoading = false;
        this.dataModelService.showMessage('error', 'Subscription failed: ' + err, 10000);
      });
  }

  private makeSubscription(noncePayload: INoncePayload): Promise<any> {
    this.isLoading = true;
    let newSubscriptionInfo;
    if (typeof this.dataModelService.dataModel.persistent.profile == 'undefined') {
      newSubscriptionInfo = {
        wizeFiID: this.dataModelService.dataModel.global.wizeFiID,
        firstName: '',
        lastName: '',
        // email for receiving notices about any subscription issues @TODO offer user input for setting distinct emaill specifically for their subscription account?
        email: window.localStorage.getItem('email'),
        noncePayload,
        planId: 'WizeFiPlan',
        trialPeriod: true,
        trialDuration: 30,
        trialDurationUnit: 'day'
      };
    } else {
      newSubscriptionInfo = {
        wizeFiID: this.dataModelService.dataModel.global.wizeFiID,
        firstName: this.dataModelService.dataModel.persistent.profile.nameFirst,
        lastName: this.dataModelService.dataModel.persistent.profile.nameLast,
        // email for receiving notices about any subscription issues @TODO offer user input for setting distinct emaill specifically for their subscription account?
        email: window.localStorage.getItem('email'),
        noncePayload,
        planId: 'WizeFiPlan',
        trialPeriod: true,
        trialDuration: 30,
        trialDurationUnit: 'day'
      };
    }
    return this.dataModelService.braintreeManagement.establishSubscription(newSubscriptionInfo);
  }

  private finishSubscription(payloadOut: any): void {
    if (payloadOut.errorMessage) {
      this.dataModelService.showMessage('error', payloadOut.errorMessage);
    } else {
      if (this.dataModelService.dataModel.persistent.header.dateProfileCompleted === '') {
        this.dataModelService.showMessage('info', 'Subscription has been registered');
      } else {
        this.dataModelService.showMessage('success', 'Congratulations! Your subscription is now active.');
      }
      const body = document.getElementsByTagName('body')[0];
      document.getElementById('sidebar').style.display = 'block';
      body.classList.remove('register');
      this.dataModelService.loginManagement.redirect(true);
    }
    this.dataModelService.loginManagement.redirect(true);
    this.isLoading = false;
    this.loading.emit(this.isLoading);
  }

  private validateProfile(): boolean {
    const messages = [];
    if (!this.cProfile.profile.nameFirst) {
      messages.push('Please enter a value for First Name');
    }

    if (!this.cProfile.profile.nameLast) {
      messages.push('Please enter a value for Last Name');
    }

    if (messages.length > 0) {
      this.dataModelService.showMessages('error', messages, 5000);
      return false;
    }
    return true;
  }
}
