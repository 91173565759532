import { Component, OnInit } from '@angular/core';
import { IPropertyItem } from '../../../interfaces/iPropertyItem.interface';
import { DataModelService } from '../../../services/data-model/data-model.service';

@Component({
  selector: 'app-admin-debug-user-data',
  templateUrl: './admin-debug-user-data.component.html',
  styleUrls: ['./admin-debug-user-data.component.css']
})
export class AdminDebugUserDataComponent implements OnInit {
  public propertyList: IPropertyItem[];
  public searchProperty: string;
  public searchPropertyValue: string;
  public wizeFiUserList: IPropertyItem[];
  public wizeFiID: string;

  public userName: string;
  public email: string;
  public affiliateID: string;
  public affiliateAlias: string;
  public dateCreated: string;
  public dateUpdated: string;

  constructor(private dataModelService: DataModelService) {}

  public ngOnInit() {
    this.propertyList = [
      { value: 'nameLast', text: 'nameLast' },
      { value: 'nameFirst', text: 'nameFirst' },
      { value: 'email', text: 'email' },
      { value: 'affiliateID', text: 'affiliateID' },
      { value: 'affiliateAlias', text: 'affiliateAlias' },
      { value: 'dateCreated', text: 'dateCreated [YYYY-MM-DD_YYYY-MM-DD]' },
      { value: 'dateUpdated', text: 'dateUpdated [YYYY-MM-DD_YYYY-MM-DD]' }
    ];
    this.searchProperty = 'nameLast';
    this.searchPropertyValue = '';
    this.wizeFiID = '';
  } // ngOnInit

  public onSearchPropertyChange(event): void {
    this.searchProperty = event.target.value;
    this.searchPropertyValue = '';
    this.wizeFiID = '';
  } // onSearchPropertyChange

  public onWizeFiIDChange(event): void {
    this.wizeFiID = event.target.value;
  } // onWizeFiIDChange

  public rpad = (str, len) => {
    while (str.length < len) {
      str = str + ' ';
    }
    return str;
  }; // rpad

  public findWizeFiUser() {
    const processResult = result => {
      let tmp: IPropertyItem[];
      tmp = [];
      for (const wizeFiUserItem of result) {
        // TODO deal with the issue that in HTML, extra spaces are ignored, and hence rpad has no effect
        const str =
          this.rpad(wizeFiUserItem.wizeFiID, 18) +
          '  ' +
          this.rpad(wizeFiUserItem.nameLast, 25) +
          '  ' +
          this.rpad(wizeFiUserItem.nameFirst, 20) +
          '  ' +
          this.rpad(wizeFiUserItem.email, 40) +
          '  ' +
          wizeFiUserItem.affiliateID +
          '  ' +
          this.rpad(wizeFiUserItem.affiliateAlias, 20) +
          '  ' +
          wizeFiUserItem.dateCreated +
          '  ' +
          wizeFiUserItem.dateUpdated;
        tmp.push({ value: wizeFiUserItem.wizeFiID, text: str });
      }
      this.wizeFiUserList = tmp;
      this.wizeFiID = '';
      if (this.wizeFiUserList.length > 0) {
        this.wizeFiID = this.wizeFiUserList[0].value;
      }
    }; // processResult

    const parms = { property: this.searchProperty, propertyValue: this.searchPropertyValue };
    document.body.style.cursor = 'progress';
    document.getElementById('btn').style.cursor = 'progress';

    this.dataModelService
      .findWizeFiUser(parms)
      .then(processResult)
      .catch(err => {
        console.log(err);
      }) // TODO use WizeFi message to show this result
      .then(() => {
        document.body.style.cursor = 'default';
        document.getElementById('btn').style.cursor = 'default';
      });
  } // findWizeFiUser

  public loadUserData() {
    const processData = () => {
      // set some data to display on the screen to verify user data has been properly loaded
      this.userName =
        this.dataModelService.dataModel.persistent.profile.nameFirst + ' ' + this.dataModelService.dataModel.persistent.profile.nameLast;
      this.email = this.dataModelService.dataModel.persistent.profile.email;
      this.affiliateID = this.dataModelService.dataModel.affiliateID;
      this.affiliateAlias = this.dataModelService.dataModel.affiliateAlias;
      this.dateCreated = this.dataModelService.dataModel.persistent.header.dateCreated;
      this.dateUpdated = this.dataModelService.dataModel.persistent.header.dateUpdated;
    }; // processData

    this.dataModelService.dataModel.global.wizeFiID = this.wizeFiID;

    this.dataModelService.dataManagement
      .fetchinfo()
      .then(() => this.dataModelService.draftService.loadDraftsInfo())
      .then(() => this.dataModelService.getBraintreeData())
      .then(() => this.dataModelService.plaidManagement.getPlaidData())
      .then(processData)
      .catch(err => {
        console.error(err);
      }); // TODO use WizeFi message to show this result
  } // loadUserData
} // AdminDebugUserDataComponent
