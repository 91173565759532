import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CategoryService } from '../../../services/category/category.service';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { ITransactionsFilter } from './i-transactions-filter';

@Component({
  selector: 'app-transactions-filter',
  templateUrl: './transactions-filter.component.html',
  styleUrls: ['./transactions-filter.component.scss']
})
export class TransactionsFilterComponent {
  public accounts: any[] = [];
  public types: string[] = [];
  public categories: string[] = [];
  public subCategories: string[] = [];

  public selectedType?: string;
  public selectedAccount?: any;
  public selectedCategory?: string;
  public selectedSubCategory?: string;
  public selectedDate?: Date;

  constructor(
    public dialogRef: MatDialogRef<TransactionsFilterComponent>,
    public categoryService: CategoryService,
    private dataModelService: DataModelService,
    @Inject(MAT_DIALOG_DATA) data: ITransactionsFilter
  ) {
    this.selectedDate = data.date;
    this.selectedAccount = data.account;
    this.selectedType = data.type;
    this.selectedCategory = data.category;
    this.selectedSubCategory = data.subcategory;

    this.calculateAvailableDropdownOptions();
  }

  public selectAccount(selectedAccount: any): void {
    this.selectedAccount = selectedAccount;
  }

  public selectType(type: string): void {
    if (type !== this.selectedType) {
      this.selectedType = type;
      this.selectedCategory = undefined;
      this.selectedSubCategory = undefined;
      this.calculateAvailableDropdownOptions();
    }
  }

  public selectCategory(category: string): void {
    if (category !== this.selectedCategory) {
      this.selectedCategory = category;
      this.selectedSubCategory = undefined;
      this.calculateAvailableDropdownOptions();
    }
  }

  public selectSubCategory(subCategory: string): void {
    this.selectedSubCategory = subCategory;
  }

  public clearAllFilters(): void {
    this.selectedType = undefined;
    this.selectedAccount = undefined;
    this.selectedCategory = undefined;
    this.selectedSubCategory = undefined;
    this.selectedDate = undefined;
    this.calculateAvailableDropdownOptions();
  }

  public submitFilters(): void {
    const result: ITransactionsFilter = {
      account: this.selectedAccount,
      type: this.selectedType,
      category: this.selectedCategory,
      subcategory: this.categoryService.getSubcategoryLabel(this.selectedType, this.selectedCategory, this.selectedSubCategory),
      date: this.selectedDate
    };

    this.dialogRef.close(result);
  }

  private calculateAvailableDropdownOptions(): void {
    this.accounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    this.types = this.categoryService.getTypes();
    this.categories = this.categoryService.getCategories(this.selectedType);
    this.subCategories = this.categoryService.getSubcategories(this.selectedType, this.selectedCategory);
  }
}
