// admin-test-plaid.component.ts

import { Component, OnInit } from '@angular/core';
import * as plaid from 'plaid';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { possibleFieldNames } from '../../../services/data-model/data-model_0001.data';

// const plaid = require('plaid');
import { IFilterValues } from '../../../interfaces/iFilterValues.interface';

declare let window: any; // resolve error from TypeScript type checking
declare let Plaid: any; // data object from link-initialize.js script in index.html

@Component({
  selector: 'app-admin-test-plaid',
  templateUrl: './admin-test-plaid.component.html',
  styleUrls: ['./admin-test-plaid.component.css']
})
export class AdminTestPlaidComponent implements OnInit {
  public loading: any; // used to control "is working" visual on screen
  public dateRange: any;

  // Create Plaid Link
  public wantPlaidLink: boolean;
  public plaidLink: any;

  // Update Plaid Link
  public wantUpdatePlaidLink: boolean;

  // Plaid Categories
  public wantPlaidCategories: boolean;
  public plaidCategoryAttributes: any;
  public plaidCategoryList: any;
  public plaidCategoryLookup: any;

  // Institutions
  public wantInstitutions: boolean;
  public haveInstitutions: boolean;
  public institutionAttributes: any;
  public wizeFiPlaidInstitutions: any;
  public institutionsTableIndex: number;
  public manualInstitutionNames: any;
  public wantAddInstitution: boolean;
  public newInstitutionName: string;

  // Accounts
  public wantAccounts: boolean;
  public haveAccounts: boolean;
  public accountAttributes: any;
  public wizeFiPlaidAccounts: any;
  public accountsTableIndex: number;
  public wantAddManualAccount: boolean;
  public plaidAccountTypes: any;
  public newAccountName: string;
  public newMaskValue: string;
  public selectedInstitutionName: string;
  public selectedAccountType: string;
  public selectedAccountSubtype: string;
  public manualAccountNames: any;
  public wantManageAssignmentOfWizeFiCategory: boolean;
  public selectedPlaidAccount: any;
  public selectedPlaidAccountWizeFiCategory: string;

  // Plaid Transactions
  public wantPlaidTransactions: boolean;
  public transactionAttributes: any;
  public transactionList: any;

  //////////////////////////////////////////////
  // WizeFi Categories
  //////////////////////////////////////////////
  public wantWizeFiTransactions: boolean;

  // WizeFi Transactions
  public haveWizeFiTransaction: boolean;
  public wizeFiTransactionAttributes: any;
  public wizeFiTransactionsCollection: any;
  public wizeFiTransactions: any;
  public wizeFiTransactionsTableIndex: number;
  public wizeFiTransactionsTableCellIndex: number;
  public attributePatternsCellIndexList: number[];
  public filterValues: any;
  public selectedFilterValue: any;
  public wantAddManualWizeFiTransaction: boolean;
  // selectedInstitutionName: string;  // defined above under Accounts
  public selectedAccountName: string;
  public newAmount: number;
  public newDate: string;
  public newMerchantName: string;
  public selectedWizeFiCategory: string;
  public wizeFiCategorySelectList: any;
  public format: string;

  // Transaction Attribute Patterns
  public wantTransactionPatterns: boolean;
  public wantProcessAttributePattern: boolean;
  public wizeFiTransactionAttributePatterns: any;
  public attributePattern2wizeFiCategory: any;
  public transactionPatternsTableIndex: number;
  public ruleCheckbox: any;
  public ruleAttributeCheckbox: any;
  public selectedPatternID: string;
  public ruleTransaction: any;
  public patternHeader: string;
  public attributePatternID: string;
  public patternAction: string;
  public isActiveRule: boolean;

  // New Attribute Pattern
  public newAttributePattern: string; // deprecated (review when safe to delete)
  public newWizeFiCategory: string; // deprecated (review when safe to delete)

  public selectedTransaction: any; // transaction object currently selected on screen
  public patternWizeFiCategory: string;

  public ruleAttributeList: string[];
  public wantAttribute: any;
  public adjustedAttribute: any;
  public merchantName: string;
  public accountName: string;
  public institutionName: string;
  public plaidCategory: string;
  public amount: string;

  public merchantNameAdjusted: string;
  public accountNameAdjusted: string;
  public institutionNameAdjusted: string;
  public plaidCategoryAdjusted: string;
  public amountAdjusted: string;

  public wantMerchantName: boolean;
  public wantAccountName: boolean;
  public wantInstitutionName: boolean;
  public wantPlaidCategory: boolean;
  public wantAmount: boolean;

  // Split Transaction
  public wantManageSplitTransaction: boolean;
  public splitTransactionInfo: any;
  public splitCount: number;

  // select wizeFiCategory for split transaction
  public categoryList: any;
  public selectedCategory: string;
  public subcategoryList: any;
  public selectedSubcategory: string;
  public fullWizeFiCategoryList: any;
  public filteredWizeFiCategoryList: any;
  public selectedWizeFiDategory: string;
  public firstChildAmount: number;
  public isReadyForCreate: boolean;

  // Assign Generic Category
  public wantManageGenericCategory: boolean;

  // Edit Attribute Pattern
  public wantEditTransactionPattern: boolean;
  public editedPatternComponents: any;
  public newAttributeValues: any;
  public testValue: string; // %//
  public testAdjustedValue: string; // %//

  // WizeFi Categories
  public wizeFiCategories: any;
  public wizeFiCategoriesTableIndex: number;
  public persistentExcludeAttributeList: any;
  public itemExcludeAttributeList: any;
  public categoryExcludeList: any;
  public subcategoryExcludeList: any;
  public persistentAttributesList: any;
  public persistentItemsAttributeList: any;
  public plan: string; // this value identifies which WizeFi plan data is being utilized
  public selectedPlan: string;
  public persistentPlanList: any;

  // Generic Categories
  public wizeFiGenericCategories: any;
  public generic2wizefi: any; // map from generic category to WizeFi category
  public wantAddCategory: boolean;
  public newGenericCategory: string;
  public genericCategoriesTableIndex: number;

  // Plaid Categories
  public wizeFiPlaidCategoryMap: any; // TODO change name to plaid2generic?
  public plaid2generic: any; // map from plaid category to generic category
  public wizeFiPlaidCategoryList: any;
  public plaidCategoriesMapTableIndex: number;

  //////////////////////////////////////////////
  // end of Assign WizeFi Categories
  //////////////////////////////////////////////

  // Transaction Sum Amounts
  public wantTransactionSumAmounts: boolean;
  public transactionAmountAttributes: any;
  public transactionAmountList: any;
  public transactionAmountLookup: any;

  // plan and transaction dates
  public wantPlanAndTransactionDates: boolean;
  public planDates: any;
  public transactionDates: any;

  // Account Linking
  public wantAccountLinking: boolean;
  public wizeFiPlaidAccountsLink: any;
  public wizeFiPlaidAccountIndex: number;
  public accountLinkInfo: any;
  public categoryLinkList: any;
  public subcategoryLinkList: any;
  public fullWizeFiCategoryLinkList: any;
  public filteredWizeFiCategoryLinkList: any;
  public schemaData: any;

  // Select Currency Code
  public wantSelectCurrency: boolean;
  public currencyCodeItems: any;
  public currencyCode2currencyCodeItem: any;
  public countryName2currencyCodeItem: any;
  public currencyCode2exchangeRate: any;
  public currencyConversion: any;
  public currencyCheckboxStatus: any;
  public userCurrencyCode: string;
  public oldUserCurrencyCode: string;

  // Select Countries
  public wantSelectCountries: boolean;
  public userCountryCodes: string[];
  public oldUserCountryCodes: string[];
  public countryCodeItems: any;
  public countryCode2countryCodeItem: any;
  public countryName2countryCodeItem: any;
  public countryCheckboxStatus: any;

  // Multiple Institution Instances
  public wantMultipleInstitutionInstances: boolean;
  public testInstitutionName: string;
  public oldAccountNamesStrings: string[];
  public oldInstanceCount: number;
  public newAccountNamesString: string;

  // Delete Institution
  public wantDeleteInstitution: boolean;
  public selectedInstitution: any;

  constructor(public dataModelService: DataModelService) {}

  public ngOnInit() {
    this.loading = {};
    this.loading.isLoading = false;

    this.dateRange = {};
    this.dateRange.wantMonthRange = true;
    this.dateRange.yearMonth = new Date().toISOString().substr(0, 7); // YYYY-MM  (current month)
    this.updateDates();

    // Plaid Link
    this.wantPlaidLink = false;

    // Plaid Categories
    this.wantPlaidCategories = false;
    this.plaidCategoryAttributes = [];
    this.plaidCategoryList = [];
    this.plaidCategoryLookup = {};

    // Institutions
    this.wantInstitutions = false;
    this.haveInstitutions = false;
    this.institutionAttributes = [];
    this.wizeFiPlaidInstitutions = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions;
    this.setInstitutionsInfo(this.wizeFiPlaidInstitutions);

    this.institutionsTableIndex = -1;
    this.wantAddInstitution = false;
    this.newInstitutionName = '';

    // Accounts
    this.wantAccounts = false;
    this.haveAccounts = false;
    this.accountAttributes = [];
    this.wizeFiPlaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    this.setAccountsInfo(this.wizeFiPlaidAccounts);
    this.accountsTableIndex = -1;
    this.wantAddManualAccount = false;
    this.newAccountName = '';
    this.newMaskValue = '';
    this.selectedInstitutionName = '';
    this.selectedAccountType = '';
    this.selectedAccountSubtype = '';
    this.plaidAccountTypes = this.setPlaidAccountTypes();
    this.manualAccountNames = {};
    this.wantManageAssignmentOfWizeFiCategory = false;
    this.selectedPlaidAccount = null;
    this.selectedPlaidAccountWizeFiCategory = 'none';

    // Transactions
    this.wantPlaidTransactions = false;
    this.transactionAttributes = [];
    this.transactionList = [];

    //////////////////////////////////////////////
    // Assign WizeFi Categories
    //////////////////////////////////////////////
    this.wantWizeFiTransactions = false;

    // WizeFiTransactions
    this.haveWizeFiTransaction = false;
    this.wizeFiTransactionAttributes = [];
    this.wizeFiTransactionsCollection = {};
    this.wizeFiTransactions = [];
    this.wizeFiTransactionsTableIndex = -1;
    this.wizeFiTransactionsTableCellIndex = -1;
    this.attributePatternsCellIndexList = [];
    this.filterValues = {}; // filterValues[<attribute>] = [<filterValue>, <filterValue>, ..., <filterValue>]
    this.selectedFilterValue = {}; // selectedFilterValue[<attribute>] = <filterValue>
    this.wantAddManualWizeFiTransaction = false;
    this.format = 'accountName';

    this.manualInstitutionNames = []; // defined under institutions
    this.selectedInstitutionName = '';
    this.selectedAccountName = '';
    this.newAmount = 0;
    this.newDate = '';
    this.newMerchantName = '';
    this.selectedWizeFiCategory = ''; // defined below under New Attribute Pattern
    this.wizeFiCategorySelectList = [];

    // Transaction Attribute Patterns
    this.wantTransactionPatterns = false;
    this.wantProcessAttributePattern = false;
    this.wizeFiTransactionAttributePatterns = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionAttributePatterns;
    this.attributePattern2wizeFiCategory = {};
    this.transactionPatternsTableIndex = -1;
    this.ruleCheckbox = {};
    this.selectedPatternID = 'none';
    this.ruleAttributeCheckbox = {};
    this.ruleAttributeList = this.dataModelService.categoryManagement.ruleAttributeList;
    this.patternAction = '';
    this.patternHeader = '';
    this.attributePatternID = 'aaaaa';
    this.ruleTransaction = null;
    this.wantAttribute = {};
    this.adjustedAttribute = {};
    for (const attribute of this.ruleAttributeList) {
      this.wantAttribute[attribute] = false;
      this.adjustedAttribute[attribute] = '';
    }

    // Split Transaction
    this.wantManageSplitTransaction = false;
    this.splitTransactionInfo = this.getInitialSplitTransactionInfo();
    this.splitCount = 2;
    this.firstChildAmount = 0;
    this.isReadyForCreate = false;

    // select wizeFiCategory for split transaction
    this.fullWizeFiCategoryList = this.dataModelService.categoryManagement.getWizeFiCategoryList();
    this.filteredWizeFiCategoryList = this.fullWizeFiCategoryList;
    this.selectedWizeFiCategory = 'none';

    this.categoryList = this.dataModelService.categoryManagement.getCategoryList(this.fullWizeFiCategoryList);
    this.selectedCategory = 'any';

    this.subcategoryList = this.dataModelService.categoryManagement.getSubcategoryList(this.fullWizeFiCategoryList);
    this.selectedSubcategory = 'any';

    // Edit Generic Category
    this.wantWizeFiTransactions = false;

    // New Attribute Pattern
    this.newAttributePattern = '';
    this.newWizeFiCategory = '';
    this.testValue = 'test value'; // %//
    this.testAdjustedValue = 'test adjusted value'; // %//
    this.selectedTransaction = null;
    this.patternWizeFiCategory = ''; // %/ still need this?
    this.isActiveRule = true;

    this.merchantName = 'test merchant name';
    this.accountName = 'test account name';
    this.institutionName = 'test institutionName name';
    this.plaidCategory = 'test plaidCategory value';
    this.amount = 'test amount';

    this.merchantNameAdjusted = '';
    this.accountNameAdjusted = '';
    this.institutionNameAdjusted = '';
    this.plaidCategoryAdjusted = '';
    this.amountAdjusted = '';

    this.wantMerchantName = false;
    this.wantAccountName = false;
    this.wantInstitutionName = false;
    this.wantPlaidCategory = false;
    this.wantAmount = false;

    // Assign Generic Category
    this.wantManageGenericCategory = false;

    // Edit Attribute Pattern
    this.wantEditTransactionPattern = false;
    this.editedPatternComponents = [];
    this.newAttributeValues = [];

    // WizeFi Categories
    this.wizeFiCategories = [];
    this.wizeFiCategoriesTableIndex = -1;
    this.persistentExcludeAttributeList = ['persistentDataVersion', 'plans'];
    this.itemExcludeAttributeList = [
      'dateSubscriptionCreated',
      'subscriptionFee',
      'subscriptionID',
      'subscriptionAccountStatus',
      'subscriptionEmail',
      'subscriptionPaidThrough',
      'paymentRef',
      'picture'
    ];
    this.categoryExcludeList = ['planDataVersion', 'assets2'];
    this.subcategoryExcludeList = ['attributeName', 'label', 'tooltip'];
    this.setPersistentAttributeLists();

    this.plan = this.dataModelService.dataModel.persistent.header.curplan;
    this.selectedPlan = this.plan;
    this.planDates = this.dataModelService.categoryManagement.obtainPlanDatesInfo();
    this.persistentPlanList = [];
    for (const item of this.planDates.persistentList) {
      this.persistentPlanList.push(item.plan);
    }

    // Generic Categories
    this.genericCategoriesTableIndex = -1;
    this.newGenericCategory = 'none';
    this.wantAddCategory = false;
    this.generic2wizefi = {};

    // Plaid Categories
    this.wizeFiPlaidCategoryMap = {};
    this.wizeFiPlaidCategoryList = [];
    this.plaid2generic = {};
    this.plaidCategoriesMapTableIndex = -1;

    //////////////////////////////////////////////
    // end of Assign WizeFi Categories
    //////////////////////////////////////////////

    // Transaction Sum Amounts
    this.wantTransactionSumAmounts = false;
    this.transactionAmountAttributes = [];
    this.transactionAmountList = [];
    this.transactionAmountLookup = {};

    // plan and transaction dates
    this.wantPlanAndTransactionDates = false;
    this.planDates = {};
    this.transactionDates = {};

    // Account Linking
    this.accountLinkInfo = {
      wizeFiPlaidAccount: {},
      accountStatus: '0',
      accountIsActive: true,
      category: 'income',
      subcategory: 'income',
      accountName: 'unknown name',
      wizeFiCategory: 'none'
    };
    this.wantAccountLinking = false;
    this.wizeFiPlaidAccountIndex = -1;
    this.wizeFiPlaidAccountsLink = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    this.fullWizeFiCategoryLinkList = this.dataModelService.categoryManagement.getWizeFiCategoryList();
    this.fullWizeFiCategoryLinkList.unshift('none');
    this.filteredWizeFiCategoryLinkList = this.dataModelService.categoryManagement.getFilteredWizeFiCategoryLinkList(
      this.accountLinkInfo.category,
      this.accountLinkInfo.subcategory,
      this.fullWizeFiCategoryLinkList
    );
    this.categoryLinkList = this.dataModelService.categoryManagement.getCategoryLinkList(this.fullWizeFiCategoryList);
    this.subcategoryLinkList = this.dataModelService.categoryManagement.getSubcategoryLinkList(this.fullWizeFiCategoryLinkList);

    // Delete Institution
    this.wantDeleteInstitution = false;
    this.selectedInstitution = 'select institution';

    // Select Currency Code
    this.wantSelectCurrency = false;
    this.userCurrencyCode = this.getUserCurrencyCode();
    this.oldUserCurrencyCode = this.userCurrencyCode;
    this.currencyCodeItems = this.dataModelService.ancillaryDataManagement.currencyCodeItems;
    this.currencyCode2currencyCodeItem = this.dataModelService.ancillaryDataManagement.currencyCode2currencyCodeItem;
    this.countryName2currencyCodeItem = this.dataModelService.ancillaryDataManagement.countryName2currencyCodeItem;
    this.currencyCode2exchangeRate = this.dataModelService.ancillaryDataManagement.currencyCode2exchangeRate;
    this.currencyConversion = this.dataModelService.ancillaryDataManagement.currencyConversion;
    this.currencyCheckboxStatus = this.getCurrencyCheckboxStatus(this.userCurrencyCode, this.currencyCode2currencyCodeItem);

    // Select Countries
    this.wantSelectCountries = false;
    this.userCountryCodes = this.getUserCountryCodes();
    this.oldUserCountryCodes = JSON.parse(JSON.stringify(this.userCountryCodes));
    this.countryCodeItems = this.dataModelService.ancillaryDataManagement.countryCodeItems;
    this.countryCode2countryCodeItem = this.dataModelService.ancillaryDataManagement.countryCode2countryCodeItem;
    this.countryName2countryCodeItem = this.dataModelService.ancillaryDataManagement.countryName2countryCodeItem;
    this.countryCheckboxStatus = this.getCountryCheckboxStatus(this.userCountryCodes, this.countryCodeItems);

    // Multiple Institution Instances
    this.wantMultipleInstitutionInstances = false;
    this.testInstitutionName = 'Chase';
    this.oldAccountNamesStrings = ['Account11, Account12, Account13', 'Account21, Account22, Account23, Account24', 'Account31, Account32'];
    this.oldInstanceCount = this.oldAccountNamesStrings.length;
    this.newAccountNamesString = 'Account41, Account42, Account43';

    this.schemaData = this.dataModelService.categoryManagement.getSchemaData();
  } // ngOnInit

  ///////////////////////////////////////
  // utility routines
  ///////////////////////////////////////

  public sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  } // sleep

  public objectKeys(obj) {
    return Object.keys(obj).sort();
  } // objectKeys

  public ruleSort(wizeFiTransactionAttributePatterns) {
    const patternCompare = (a, b) => {
      let result = 0;

      const ra = wizeFiTransactionAttributePatterns[a]; // rule for patternID a
      const rb = wizeFiTransactionAttributePatterns[b]; // rule for patternID b

      if (ra.isDefaultRule && !rb.isDefaultRule) {
        result = 1;
      } else if (!ra.isDefaultRule && rb.isDefaultRule) {
        result = -1;
      } else {
        const sa = this.dataModelService.categoryManagement.makeRuleComparisonString(ra); // comparison string for a
        const sb = this.dataModelService.categoryManagement.makeRuleComparisonString(rb); // comparison string for b
        if (sa < sb) {
          result = -1;
        } else if (sa > sb) {
          result = 1;
        }
      }
      return result;
    }; // patternCompare

    const patternList = [];
    for (const patternID of Object.keys(wizeFiTransactionAttributePatterns).sort(patternCompare)) {
      patternList.push(patternID);
    }
    return patternList;
  } // ruleSort

  public changeCategoryDisplayFormat() {
    let radioButtonAccountID: any;
    let radioButtonAccountName: any;
    let radioButtonAccountIndex: any;
    let radioButtonNone: any;

    radioButtonAccountID = document.getElementById('accountID') as HTMLOptionElement;
    radioButtonAccountName = document.getElementById('accountName') as HTMLOptionElement;
    radioButtonAccountIndex = document.getElementById('accountIndex') as HTMLOptionElement;
    radioButtonNone = document.getElementById('none') as HTMLOptionElement;

    this.format = 'unknown';
    if (radioButtonAccountID.checked) {
      this.format = 'accountID';
    }
    if (radioButtonAccountName.checked) {
      this.format = 'accountName';
    }
    if (radioButtonAccountIndex.checked) {
      this.format = 'acntndx';
    }
    if (radioButtonNone.checked) {
      this.format = 'none';
    }
  } // changeCategoryDisplayFormat

  public dcat(format, wizeFiCategory) {
    return this.dataModelService.categoryManagement.reformatWizeFiCategory(format, wizeFiCategory);
  } // dcat

  public datr(attributePattern) {
    return this.dataModelService.categoryManagement.makeAttributePatternString(attributePattern);
  } // datr

  public datb(boolval) {
    return boolval ? 'Y' : 'N';
  } // datb

  public updateDates() {
    this.dateRange.start_date = this.dataModelService.changeDate(this.dateRange.yearMonth, 0, 0, 0).substr(0, 10); // YYYY-MM-DD  (first day of this month)
    this.dateRange.end_date = this.dataModelService.changeDate(this.dateRange.yearMonth, 0, 1, 0).substr(0, 10); // YYYY-MM-DD  (first day of next month)
    this.dateRange.end_date = this.dataModelService.changeDate(this.dateRange.end_date, 0, 0, -1).substr(0, 10); // YYYY-MM-DD  (last day of this month)
  } // updateDates

  public lookupAcntndx(category, subcategory, accountName) {
    const accounts = this.dataModelService.dataModel.persistent.plans[this.plan][category][subcategory].accounts;
    let acntndx = accounts.length;
    while (--acntndx >= 0 && accountName !== accounts[acntndx].accountName.val) {}
    return acntndx;
  } // lookupAcntndx

  public makePlaidCategoryName(categoryObject) {
    return categoryObject.hierarchy.join('_');
  } // makePlaidCategoryName

  public makeWizeFiCategoryName(category, subcategory, account) {
    return category + '_' + subcategory + '_' + account;
  } // makeWizeFiCategoryName

  public addClassToTableCell(cellElement) {
    cellElement.setAttribute('class', 'selectedCol');
  } // addClassToTableCell

  public removeClassFromTableCell(cellElement) {
    cellElement.removeAttribute('class');
  } // removeClassFromTableCell

  public generateIDcode(length): string {
    /*
        This routine provides a randomly generated string of lower case letters.  The length parameter identifies how many letters to have in the string.

        The output of this routine can be used to represent things like an ID value for manual institutions, accounts, and transactions.
        */
    const letters = 'abcdefghijklmnopqrstuvwxyz';
    let IDcode = '';

    for (let i = 1; i <= length; i++) {
      IDcode += letters[Math.floor(Math.random() * 26)];
    }

    return IDcode;
  } // generateIDcode

  public setAttributes(objectList) {
    let result = [];
    if (objectList.length > 0) {
      result = Object.keys(objectList[0]);
    }
    return result;
  } // setAttributes

  public setPlaidAccountTypes() {
    const plaidAccountTypes = {
      credit: ['credit card', 'paypal'],
      depository: ['cd', 'checking', 'savings', 'money market', 'paypal', 'prepaid'],
      loan: ['auto', 'commercial', 'construction', 'consumer', 'home', 'home equity', 'loan', 'mortgage', 'overdraft', 'line of credit', 'student'],
      other: ['cash management', 'keogh', 'mutual fund', 'prepaid', 'recurring', 'rewards', 'safe deposit', 'sarsep', 'other'],
      investment: [
        '401a',
        '401k',
        '403B',
        '457b',
        '529',
        'brokerage',
        'cash isa',
        'education savings account',
        'gic',
        'health reimbursement arrangement',
        'hsa',
        'isa',
        'ira',
        'lif',
        'lira',
        'lrif',
        'lrsp',
        'non-loadplaidlink taxable brokerage account',
        'other',
        'prif',
        'rdsp',
        'resp',
        'rlif',
        'rrif',
        'pension',
        'profit sharing plan',
        'retirement',
        'roth',
        'roth 401k',
        'rrsp',
        'sep ira',
        'simple ira',
        'sipp',
        'stock plan',
        'thrift savings plan',
        'tfsa',
        'ugma',
        'utma',
        'variable annuity'
      ]
    };
    return plaidAccountTypes;
  } // setPlaidAccountTypes

  /////////////////////////////////////////////////////
  // routines to support Create Plaid Link feature
  /////////////////////////////////////////////////////

  public loadPlaidLink() {
    this.loading.isLoading = true;
    const title = this.dataModelService.dataManagement.getDraftTitle();
    this.dataModelService.plaidManagement
      .loadPlaidLink(title)
      .then(result => {
        // the code in this location is executed after all of the loadPlaidLink processing has been completed
        console.log('>>>>>>>> after plaidManagement.loadPlaidLink -- update memory resident data'); //%//
        console.log('result: ', result); //%//
        // TODO figure out how to handle result === null  (new institution was not added because it would be a duplicate)
        // update memory resident data that was changed by Lambda function
        this.wizeFiPlaidInstitutions = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions;
        this.wizeFiPlaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
      })
      .catch(err => {
        console.log('error in loadPlaidLink: ', err);
      })
      .finally(() => {
        this.loading.isLoading = false;
      });
  } // loadPlaidLink

  /////////////////////////////////////////////////////
  // routines to support Update Plaid Link feature
  /////////////////////////////////////////////////////

  public loadUpdatePlaidLink() {
    this.loading.isLoading = true;
    const title = this.dataModelService.dataManagement.getDraftTitle();
    this.dataModelService.plaidManagement
      .loadUpdatePlaidLink(title)
      .then(tokenCount => {
        if (tokenCount > 1) {
          console.log('There are more institutions that need to update their credentials.  Please repeat this process again.');
          this.dataModelService.showMessage(
            'info',
            'There are more institutions that need to update their credentials.  Please repeat this process again.',
            30000
          );
        }
      })
      .catch(err => {
        console.log('error in loadUpdatePlaidLink: ', err);
      })
      .finally(() => {
        this.loading.isLoading = false;
      });
  } // loadUpdatePlaidLink

  ///////////////////////////////////////////////
  // routines to support Categories feature
  ///////////////////////////////////////////////

  public loadCategories() {
    const processResult = plaidCategoryList => {
      this.plaidCategoryList = plaidCategoryList;
      this.plaidCategoryAttributes = this.setAttributes(this.plaidCategoryList);

      console.log('plaidCategoryAttributes: ', this.plaidCategoryAttributes); // %//
      console.log('plaidCategoryList: ', this.plaidCategoryList); // %//
    }; // processResult

    this.loading.isLoading = true;

    this.dataModelService.plaidManagement
      .getCategories()
      .then(processResult)
      .catch(err => {
        console.log('error in loadCategories: ', err);
      })
      .finally(() => {
        this.loading.isLoading = false;
      });
  } // loadCategories

  ///////////////////////////////////////////////
  // routines to support Institutions feature
  ///////////////////////////////////////////////

  public institutionsCompare(i1, i2) {
    let result = 0;
    if (i1.isManual < i2.isManual) {
      result = -1;
    } else if (i1.isManual > i2.isManual) {
      result = 1;
    } else if (i1.institutionName < i2.institutionName) {
      result = -1;
    } else if (i1.institutionName > i2.institutionName) {
      result = 1;
    }
    return result;
  } // institutionsCompare

  public setManualInstitutionNames(wizeFiPlaidInstitutions) {
    const manualInstitutionNames = [];
    for (const institution of wizeFiPlaidInstitutions) {
      if (institution.isManual) {
        manualInstitutionNames.push(institution.institutionName);
      }
    }
    return manualInstitutionNames;
  } // setManualInstitutionNames

  public setInstitutionsInfo(wizeFiPlaidInstitutions) {
    wizeFiPlaidInstitutions.sort(this.institutionsCompare);
    this.haveInstitutions = wizeFiPlaidInstitutions.length > 0 ? true : false;
    this.institutionAttributes = this.setAttributes(wizeFiPlaidInstitutions);
    this.manualInstitutionNames = this.setManualInstitutionNames(wizeFiPlaidInstitutions);

    console.log('institutionAttributes: ', this.institutionAttributes); // %//
    console.log('wizeFiPlaidInstitutions: ', this.wizeFiPlaidInstitutions); // %//
  } // setInstitutionsInfo

  public loadInstitutions() {
    const processResult = wizeFiPlaidInstitutions => {
      this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions = wizeFiPlaidInstitutions;
      this.wizeFiPlaidInstitutions = wizeFiPlaidInstitutions;
      this.setInstitutionsInfo(wizeFiPlaidInstitutions);
    }; // processResult

    this.loading.isLoading = true;

    this.dataModelService.plaidManagement
      .getWizeFiPlaidInstitutions(this.dataModelService.dataModel.global.wizeFiID)
      .then(processResult)
      .catch(err => {
        console.log('error in loadInstitutions: ', err);
      })
      .then(() => {
        this.loading.isLoading = false;
      });
  } // loadInstitutions

  public updateErrorStatus() {
    const updateErrorStatus0 = async () => {
      // update error status in persistent storage
      const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
      const title = this.dataModelService.dataManagement.getDraftTitle();
      await this.dataModelService.plaidManagement.updateInstitutionErrorStatus(wizeFiID, title);

      // refresh in memory from persistent storage the updated error status information
      await this.loadInstitutions();
    }; // updateErrorStatus0

    this.loading.isLoading = true;

    updateErrorStatus0()
      .catch(err => {
        console.log('error in updateErrorStatus: ', err);
      })
      .then(() => {
        this.loading.isLoading = false;
      });
  } // updateErrorStatus

  public addManualInstitution() {
    this.wantAddInstitution = true;
  } // addManualInstitution

  public addInstitutionCancel() {
    this.wantAddInstitution = false;
  } // addInstitutionCancel

  public addInstitutionOK() {
    const addInstitutionOK0 = async () => {
      const institution = {
        access_token: this.generateIDcode(6),
        item_id: this.generateIDcode(5),
        institution_id: this.generateIDcode(4),
        institutionName: this.newInstitutionName,
        error: 'none',
        isManual: true
      };
      await this.dataModelService.plaidManagement.addManualInstitution(institution);

      // refresh screen data with new persistent storage data
      this.loadInstitutions();

      // reset for next add operation
      this.newInstitutionName = '';
      this.wantAddInstitution = false;
    }; // addInstitutionOK

    this.loading.isLoading = true;

    addInstitutionOK0()
      .catch(err => {
        console.log('error in addInstitutionOK', err);
      })
      .then(() => {
        this.loading.isLoading = false;
      });
  } // addInstitutionOK()

  public clearInstitutionSelection() {
    this.institutionsTableIndex = -1;
  } // clearInstitutionSelection

  public setInstitutionsTableIndex(index) {
    this.institutionsTableIndex = index;
  } //

  ///////////////////////////////////////////////
  // routines to support Accounts feature
  ///////////////////////////////////////////////

  public accountsCompare(a1, a2) {
    let result = 0;
    if (a1.isManual < a2.isManual) {
      result = -1;
    } else if (a1.isManual > a2.isManual) {
      result = 1;
    } else if (a1.institutionName < a2.institutionName) {
      result = -1;
    } else if (a1.institutionName > a2.institutionName) {
      result = 1;
    } else if (a1.accountName < a2.accountName) {
      result = -1;
    } else if (a1.accountName < a2.accountName) {
      result = 1;
    }
    return result;
  } // accountsCompare

  public setManualAccountNames(wizeFiPlaidAccounts) {
    const manualAccountNames = {};
    for (const account of wizeFiPlaidAccounts) {
      if (account.isManual) {
        if (!manualAccountNames.hasOwnProperty(account.institutionName)) {
          manualAccountNames[account.institutionName] = [];
        }
        manualAccountNames[account.institutionName].push(account.accountName);
      }
    }
    return manualAccountNames;
  } // setManualAccountNames

  public setAccountsInfo(wizeFiPlaidAccounts) {
    wizeFiPlaidAccounts.sort(this.accountsCompare);
    this.accountAttributes = this.setAttributes(wizeFiPlaidAccounts);

    // remove account_id and item_id from display of data (it is still in the underlying data structure)
    this.accountAttributes = this.accountAttributes.filter(attr => attr !== 'account_id' && attr !== 'item_id' && attr !== 'institution_id');

    this.manualAccountNames = this.setManualAccountNames(wizeFiPlaidAccounts);
    this.haveAccounts = wizeFiPlaidAccounts.length > 0 ? true : false;

    // %//   \/
    console.log('accountAttributes: ', this.accountAttributes);
    console.log('wizeFiPlaidAccounts: ', this.wizeFiPlaidAccounts);
    // %//   /\
  } // setAccountsInfo

  public loadAccounts() {
    const processResult = wizeFiPlaidAccounts => {
      this.wizeFiPlaidAccounts = wizeFiPlaidAccounts;
      this.setAccountsInfo(wizeFiPlaidAccounts);
    }; // processResult

    this.loading.isLoading = true;

    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    const title = this.dataModelService.dataManagement.getDraftTitle();

    this.dataModelService.plaidManagement
      .fetchPlaidAccounts(wizeFiID, title)
      .then(processResult)
      .catch(err => {
        console.log('error in loadAccounts: ', err);
      })
      .then(() => {
        this.loading.isLoading = false;
      });
  } // loadAccounts

  public updateAccounts() {
    const updateAccounts0 = async () => {
      const isNewPlaidAccount = (account, wizeFiPlaidAccounts) => {
        let isNew = true;
        let i = -1;
        while (++i < wizeFiPlaidAccounts.length && isNew) {
          if (account.account_id === wizeFiPlaidAccounts[i].account_id) {
            isNew = false;
          }
        }

        return isNew;
      }; // isNewPlaidAccount

      // retrieve accounts
      const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
      const title = this.dataModelService.dataManagement.getDraftTitle();
      const accounts: any = await this.dataModelService.plaidManagement.getAccounts(wizeFiID, title);
      console.log('==== Plaid accounts ==== ', accounts);

      // add to WizeFi accounts any Plaid accounts that are new
      for (const account of accounts.accountList) {
        if (isNewPlaidAccount(account, this.wizeFiPlaidAccounts)) {
          // add additional attributes to account
          account.status = 0;
          account.isActive = true;
          account.isManual = false;
          account.wizeFiCategory = 'none';

          // add this account to the wizeFiPlaidAccounts array
          this.wizeFiPlaidAccounts.push(account);
        }
      }
      this.wizeFiPlaidAccounts.sort(this.accountsCompare);
      this.accountAttributes = this.setAttributes(this.wizeFiPlaidAccounts);
    }; // updateAccounts0

    const processError = err => {
      console.log('error in updateAccounts: ', err);
      if (err.hasOwnProperty('errorMessage') && err.errorMessage === 'ITEM_LOGIN_REQUIRED') {
        console.log('You need to use the Update Plaid Link feature to restore access to a financial institution'); // %//
      }
    };

    this.loading.isLoading = true;

    updateAccounts0()
      .catch(processError)
      .then(() => {
        this.loading.isLoading = false;
      });
  } // updateAccounts

  public addManualAccount() {
    this.wantAddManualAccount = true;
  } // addManualAccount

  public addManualAccountCancel() {
    this.wantAddManualAccount = false;
  } // addManualAccountCancel

  public addManualAccountOK() {
    const lookup = institutionName => {
      let result: any;
      result = {};
      for (const institutionIter of this.wizeFiPlaidInstitutions) {
        if (institutionIter.institutionName === institutionName) {
          result = institutionIter;
        }
      }
      return result;
    }; // lookup

    const institution = lookup(this.selectedInstitutionName);
    const account = {
      account_id: this.generateIDcode(5),
      accountName: this.newAccountName,
      accountType: this.selectedAccountType,
      accountSubtype: this.selectedAccountSubtype,
      mask: this.newMaskValue,
      balance: 0,
      item_id: institution.item_id,
      institution_id: institution.institution_id,
      institutionName: institution.institutionName,
      isActive: true,
      isManual: true
    };
    this.wizeFiPlaidAccounts.push(account);

    // reset for next add operation
    this.newAccountName = '';
    this.newMaskValue = '';
    this.selectedInstitutionName = '';
    this.selectedAccountType = '';
    this.selectedAccountSubtype = '';
    this.wantAddManualAccount = false;
  } // addManualAccountOK

  public deleteAccount() {
    if (!this.wizeFiPlaidAccounts[this.accountsTableIndex].isManual) {
      this.dataModelService.showMessage('info', 'Only manual accounts can be deleted', 7000);
    } else {
      // remove the selected account
      this.wizeFiPlaidAccounts.splice(this.accountsTableIndex, 1);
    }

    // clear which row is selected on the screen
    this.accountsTableIndex = -1;
  } // deleteAccount

  public manageAssignmentOfWizeFiCategory() {
    this.wantManageAssignmentOfWizeFiCategory = true;
    this.wizeFiCategorySelectList = this.dataModelService.categoryManagement.getWizeFiCategoryList();
    this.wizeFiCategorySelectList.unshift('unknown'); // put "unknown" at beginning of list
  } // manageAssignmentOfWizeFiCategory

  public assignWizeFiCategoryToPlaidAccount() {
    this.selectedPlaidAccount.wizeFiCategory = this.selectedPlaidAccountWizeFiCategory;
  } // assignWizeFiCategoryToPlaidAccount

  public wantManageAssignmentOfWizeFiCategoryExit() {
    this.wantManageAssignmentOfWizeFiCategory = false;
    this.accountsTableIndex = -1;
    this.selectedPlaidAccountWizeFiCategory = 'none';
  } // wantManageAssignmentOfWizeFiCategoryExit

  public saveWizeFiPlaidAccounts() {
    this.dataModelService.plaidManagement
      .storePlaidAccounts(this.dataModelService.dataModel.global.wizeFiID, '', this.wizeFiPlaidAccounts)
      .then(() => {})
      .catch(err => console.error('error in saveWizeFiPlaidAccounts: ', err));
  } // saveWizeFiPlaidAccounts

  public clearAccountSelection() {
    this.accountsTableIndex = -1;
  } // clearAccountSelection

  public setAccountsTableIndex(index) {
    this.accountsTableIndex = index;
    this.selectedPlaidAccount = this.wizeFiPlaidAccounts[this.accountsTableIndex];
  } // setAccountsTableIndex

  public toggleInstitutionIsActive(rowIndex, colIndex) {
    if (this.institutionAttributes[colIndex] === 'isActive') {
      this.wizeFiPlaidInstitutions[rowIndex].isActive = !this.wizeFiPlaidInstitutions[rowIndex].isActive;

      // get isActive change into persistent storage
      const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
      const item_id = this.wizeFiPlaidInstitutions[rowIndex].item_id;
      const isActive = this.wizeFiPlaidInstitutions[rowIndex].isActive;

      this.loading.isLoading = true;
      this.dataModelService.plaidManagement
        .setInstitutionIsActive(wizeFiID, item_id, isActive)
        .catch(err => {
          console.log('error in setInstitutionIsActive: ', err);
        })
        .then(() => {
          this.loading.isLoading = false;
        });
    }
  } // toggleInstitutionIsActive

  public toggleAccountIsActive(rowIndex, colIndex) {
    if (this.accountAttributes[colIndex] === 'isActive') {
      this.wizeFiPlaidAccounts[rowIndex].isActive = !this.wizeFiPlaidAccounts[rowIndex].isActive;
    }
  } // toggleAccountIsActive

  ///////////////////////////////////////////////
  // routines to support Transactions feature
  ///////////////////////////////////////////////

  public loadTransactions() {
    const loadTransaction0 = async () => {
      // initialize
      const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
      const title = this.dataModelService.dataManagement.getDraftTitle();

      // TODO consider whether to manage wizeFiPlaidAccounts and activeWizeFiPlaidAccountIds in app global context
      const plaidAccounts = await this.dataModelService.plaidManagement.fetchPlaidAccounts(wizeFiID, title);
      const activeWizeFiPlaidAccountIds = this.dataModelService.plaidManagement.setActiveWizeFiPlaidAccountIds(plaidAccounts);

      // retrieve and process the transactions
      const transactionInfo: any = await this.dataModelService.plaidManagement.getTransactions(
        wizeFiID,
        title,
        this.dateRange,
        activeWizeFiPlaidAccountIds
      );

      this.transactionList = transactionInfo.transactions;
      this.transactionAttributes = this.setAttributes(this.transactionList);

      // %//   \/
      console.log('transactionAttributes: ', this.transactionAttributes);
      console.log('transactions: ', this.transactionList);
      const transactionCount = this.transactionList.length;
      const bytesPerTransaction = Math.ceil(JSON.stringify(this.transactionList).length / transactionCount);
      const transactionsPerItem = Math.floor((400 * 1024) / bytesPerTransaction);
      console.log(
        'transactionList -- transactionCount:' +
          transactionCount +
          '  bytesPerTransaction:' +
          bytesPerTransaction +
          '  transactionsPerItem:' +
          transactionsPerItem
      );
      // %//   /\
    }; // loadTransaction0

    const processError = err => {
      console.log('error in loadTransactions: ', err);
      if (err.hasOwnProperty('errorMessage') && err.errorMessage === 'ITEM_LOGIN_REQUIRED') {
        console.log('You need to use the Update Plaid Link feature to restore access to a financial institution'); // %//
      }
    }; // processError

    loadTransaction0()
      .catch(processError)
      .then(() => {
        this.loading.isLoading = false;
      });
  } // loadTransactions

  ///////////////////////////////////////////////////////////
  // routines to support WizeFi Transactions feature
  ///////////////////////////////////////////////////////////

  public wizeFiTransactionsCompare(t1, t2) {
    let result = 0;
    if (t1.date < t2.date) {
      result = 1;
    } else if (t1.date > t2.date) {
      result = -1;
    } else if (t1.institutionName < t2.institutionName) {
      result = -1;
    } else if (t1.institutionName > t2.institutionName) {
      result = 1;
    } else if (t1.accountName < t2.accountName) {
      result = -1;
    } else if (t1.accountName < t2.accountName) {
      result = 1;
    }
    return result;
  } // wizeFiTransactionsCompare

  public setFilterValues(wizeFiTransactionAttributes, wizeFiTransactions) {
    const filterValues: IFilterValues = {
      merchantName: [],
      attribute: null
    };
    // enable filter on merchantName to show all check transactions
    filterValues.merchantName.push('Check#');

    // set all filter values for each attribute
    for (const attribute of wizeFiTransactionAttributes) {
      for (const transaction of wizeFiTransactions) {
        const filterValue = transaction[attribute];

        if (!filterValues.hasOwnProperty(attribute)) {
          filterValues[attribute] = [];
        }
        if (filterValues[attribute].indexOf(filterValue) === -1) {
          filterValues[attribute].push(filterValue);
        }
      }
    }

    // sort the filterAttribute values
    for (const attribute of wizeFiTransactionAttributes) {
      filterValues[attribute].sort();
    }

    return filterValues;
  } // setFilterValues

  public initializeTransactions() {
    this.wizeFiTransactionsCollection = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection;

    this.setTransactionsInfo(this.wizeFiTransactionsCollection).catch(err => {
      console.log('error in initializeTransactions: ', err);
    });
  } // initializeTransactions

  public async setTransactionsInfo(wizeFiTransactionsCollection) {
    this.wizeFiTransactions = wizeFiTransactionsCollection[this.dateRange.yearMonth];
    this.wizeFiTransactions.sort(this.wizeFiTransactionsCompare);
    this.wizeFiTransactionAttributes = this.setAttributes(this.wizeFiTransactions);

    // remove transaction_id and account_id from display of data (it is still in the underlying data structure)
    this.wizeFiTransactionAttributes = this.wizeFiTransactionAttributes.filter(attr => attr !== 'transaction_id' && attr !== 'account_id');

    // set filterValues
    this.filterValues = this.setFilterValues(this.wizeFiTransactionAttributes, this.wizeFiTransactions);

    // initialize the selectedFilterValue for all attributes
    this.selectedFilterValue = {};
    for (const attribute of this.wizeFiTransactionAttributes) {
      this.selectedFilterValue[attribute] = 'filter';
    }

    // populate list of WizeFi categories for use in creating a new manual transaction
    this.wizeFiCategorySelectList = this.dataModelService.categoryManagement.getWizeFiCategoryList();
    this.wizeFiCategorySelectList.unshift('unknown'); // put "unknown" at beginning of list

    this.haveWizeFiTransaction = this.wizeFiTransactions.length > 0 ? true : false;

    // set layout
    await this.dataModelService.sleep(200); // kludge to let screen content settle
    this.setScreenLayout();

    // %//   \/
    console.log('wizeFiTransactionAttributes: ', this.wizeFiTransactionAttributes);
    console.log('wizeFiTransactions: ', this.wizeFiTransactions);
    console.log('wizeFiCategorySelectList: ', this.wizeFiCategorySelectList);
    const transactionCount = this.wizeFiTransactionsCollection[this.dateRange.yearMonth].length;
    const bytesPerTransaction = Math.ceil(JSON.stringify(this.wizeFiTransactionsCollection[this.dateRange.yearMonth]).length / transactionCount);
    const transactionsPerItem = Math.floor((400 * 1024) / bytesPerTransaction);
    console.log(
      'wizeFiTransactions -- transactionCount:' +
        transactionCount +
        '  bytesPerTransaction:' +
        bytesPerTransaction +
        '  transactionsPerItem:' +
        transactionsPerItem
    );
    // %//   /\
  } // setTransactionsInfo

  public loadWizeFiTransactions() {
    const processResult = async wizeFiTransactionsCollection => {
      this.wizeFiTransactionsCollection = wizeFiTransactionsCollection;
      this.setTransactionsInfo(wizeFiTransactionsCollection);
    }; // processResult

    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    const startDate = this.dateRange.yearMonth;
    const endDate = startDate;

    if (!this.dateRange.wantMonthRange) {
      console.log('Must not specify range of dates for this operation (select a single month YYYY-MM)');
    } else {
      this.loading.isLoading = true;

      this.dataModelService.dataManagement
        .getwizeFiTransactionsCollection(wizeFiID, startDate, endDate)
        .then(processResult)
        .catch(err => {
          console.log('error in loadWizeFiTransactions: ', err);
        })
        .then(() => {
          this.loading.isLoading = false;
        });
    }
  } // loadWizeFiTransactions

  public updateWizeFiTransactions() {
    const processError = err => {
      console.log('error in updateWizeFiTransactions: ', err);
      if (err.hasOwnProperty('errorMessage') && err.errorMessage === 'ITEM_LOGIN_REQUIRED') {
        console.log('You need to use the Update Plaid Link feature to restore access to a financial institution'); // %//
        this.dataModelService.showMessage(
          'error',
          'You need to use the Update Plaid Link feature to restore access to a financial institution',
          10000
        );
      }
    }; // processError

    const updateWizeFiTransactions0 = async () => {
      // update list of transactions for the selected yearMonth
      this.wizeFiTransactions = await this.dataModelService.plaidManagement.updateWizeFiTransactions(this.dateRange.yearMonth);

      // process the updated data
      this.wizeFiTransactions.sort(this.wizeFiTransactionsCompare);
      this.wizeFiTransactionAttributes = this.setAttributes(this.wizeFiTransactions);

      // remove transaction_id from display of data (it is still in the underlying data structure)
      this.wizeFiTransactionAttributes = this.wizeFiTransactionAttributes.filter(attr => attr !== 'transaction_id');

      // set filterValues
      this.filterValues = this.setFilterValues(this.wizeFiTransactionAttributes, this.wizeFiTransactions);

      // initialize the selectedFilterValue for all attributes
      for (const attribute of this.wizeFiTransactionAttributes) {
        this.selectedFilterValue[attribute] = 'filter';
      }

      // set layout
      await this.dataModelService.sleep(200); // kludge to let screen content settle
      this.setScreenLayout();
    }; // updateWizeFiTransactions0

    if (!this.dateRange.wantMonthRange) {
      console.log('Must not specify range of dates for this operation -- select a single month (YYYY-MM)');
    } else {
      this.loading.isLoading = true;

      updateWizeFiTransactions0()
        .catch(processError)
        .finally(() => {
          this.loading.isLoading = false;
        });
    }
  } // updateWizeFiTransactions

  public setScreenLayout() {
    // initialize
    const wizeFiTransactionsHeaderTableElement = document.getElementById('wizeFiTransactionsHeaderTable') as HTMLTableElement;
    const wizeFiTransactionsTableElement = document.getElementById('wizeFiTransactionsTable') as HTMLTableElement;

    // adjust column widths to match
    for (let i = 0; i < wizeFiTransactionsHeaderTableElement.rows[0].cells.length; i++) {
      const wizeFiTransactionsHeaderCellElement = wizeFiTransactionsHeaderTableElement.rows[0].cells[i] as HTMLTableCellElement;
      const wizeFiTransactionsCellElement = wizeFiTransactionsTableElement.rows[0].cells[i] as HTMLTableCellElement;

      const maxWidth = Math.max(Number(wizeFiTransactionsHeaderCellElement.offsetWidth), Number(wizeFiTransactionsCellElement.offsetWidth)) + 4;

      wizeFiTransactionsHeaderCellElement.style.width = maxWidth + 'px';
      wizeFiTransactionsCellElement.style.width = maxWidth + 'px';
    }
  } // setScreenLayout

  public filterData() {
    const filterData0 = async () => {
      await this.dataModelService.sleep(200); // kludge to let screen content settle
      this.setScreenLayout();
    };

    filterData0().catch(err => {
      console.log('error in filterData: ', err);
    });
  } // filterData

  public isSelectedWizeFiTransaction(transaction) {
    const haveMatch = (attribute, attributeValue, selectedValue) => {
      let isMatch = false;
      if (attribute === 'merchantName' && attributeValue.indexOf('Check#') !== -1 && selectedValue === 'Check#') {
        isMatch = true;
      } else if (attribute === 'amount') {
        attributeValue = Math.abs(attributeValue);
        selectedValue = Math.abs(selectedValue);
        isMatch = attributeValue === selectedValue;
      } else {
        isMatch = attributeValue === selectedValue;
      }
      return isMatch;
    }; // haveMatch

    let isSelected = true;
    let i = this.wizeFiTransactionAttributes.length;
    while (--i >= 0 && isSelected) {
      const attribute = this.wizeFiTransactionAttributes[i];
      let attributeValue = transaction[attribute];

      if (typeof attributeValue === 'boolean') {
        attributeValue = attributeValue.toString();
      }
      if (!(this.selectedFilterValue[attribute] === 'filter' || haveMatch(attribute, attributeValue, this.selectedFilterValue[attribute]))) {
        isSelected = false;
      }
    }
    return isSelected;
  } // isSelectedWizeFiTransaction

  public addManualWizeFiTransaction() {
    this.wantAddManualWizeFiTransaction = true;
  } // addManualWizeFiTransaction

  public addManualWizeFiTransactionCancel() {
    this.wantAddManualWizeFiTransaction = false;
  } // addWizeFiTransactionCancel

  public addManualWizeFiTransactionOK() {
    const transaction = {
      transaction_id: this.generateIDcode(5),
      amount: this.newAmount,
      date: this.newDate,
      category: 'Not Relevant',
      merchantName: this.newMerchantName,
      accountName: this.selectedAccountName,
      institutionName: this.selectedInstitutionName,
      isManual: true,
      wizeFiCategory: this.selectedWizeFiCategory
    };
    this.wizeFiTransactionsCollection[this.dateRange.yearMonth].push(transaction);

    // reset for next add operation
    this.selectedInstitutionName = '';
    this.selectedAccountName = '';
    this.newAmount = 0;
    this.newDate = '';
    this.newMerchantName = '';
    this.selectedWizeFiCategory = '';
    this.wantAddManualWizeFiTransaction = false;
  } // addManualWizeFiTransactionOK

  public deleteWizeFiTransaction() {
    if (!this.wizeFiTransactionsCollection[this.dateRange.yearMonth][this.wizeFiTransactionsTableIndex].isManual) {
      this.dataModelService.showMessage('info', 'Only manual transactions can be deleted', 7000);
    } else {
      // remove the selected transaction
      this.wizeFiTransactionsCollection[this.dateRange.yearMonth].splice(this.wizeFiTransactionsTableIndex, 1);
    }

    // clear which row is selected on the screen
    this.wizeFiTransactionsTableIndex = -1;
  } // deleteWizeFiTransaction

  public patternAssignCategories() {
    this.dataModelService.categoryManagement.assignWizeFiCategoryFromTransactionPattern(this.dateRange.yearMonth);

    // refresh data
    this.wizeFiTransactions = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection[this.dateRange.yearMonth];
  } // patternAssignCategories

  public genericAssignCategories() {
    this.plaid2generic = this.wizeFiPlaidCategoryMap; // kludge until wizeFiPlaidCategoryMap is renamed?

    // scan through all transactions looking for a genericCategory that links Plaid categories in transactions to WizeFi categories
    // for (let i = 0; i < this.wizeFiTransactionsCollection[this.dateRange.yearMonth].length; i++) {
    for (const transactionCollection of this.wizeFiTransactionsCollection[this.dateRange.yearMonth]) {
      const transaction = transactionCollection;
      const plaidCategory = transaction.category;
      const wizeFiCategory = transaction.wizeFiCategory;

      // include only transactions that have an 'unknown' wizeFiCategory and that have a plaid2generic mapping for the plaidCategory
      if (wizeFiCategory === 'unknown' && this.plaid2generic.hasOwnProperty(plaidCategory)) {
        const genericCategory = this.plaid2generic[plaidCategory];
        if (genericCategory !== 'none' && this.generic2wizefi.hasOwnProperty(genericCategory)) {
          // assign to the WizeFi transaction the WizeFi category that has been found
          const newWizeFiCategory = this.generic2wizefi[genericCategory];
          transactionCollection.wizeFiCategory = newWizeFiCategory;
        }
      }
    }
  } // genericAssignCategories

  public assignWizeFiCategoryToTransaction() {
    // set wizeFiCategory components
    const category = this.wizeFiCategories[this.wizeFiCategoriesTableIndex].category;
    const subcategory = this.wizeFiCategories[this.wizeFiCategoriesTableIndex].subcategory;
    const accountID = this.wizeFiCategories[this.wizeFiCategoriesTableIndex].accountID;
    const accountName = this.wizeFiCategories[this.wizeFiCategoriesTableIndex].accountName;

    // set name of selected category
    let wizeFiCategory = category;
    if (wizeFiCategory !== 'ignore' && wizeFiCategory !== 'unknown') {
      wizeFiCategory += '_' + subcategory;
      wizeFiCategory += '_' + accountID;
    }

    // place the selected category into the transaction data
    this.wizeFiTransactionsCollection[this.dateRange.yearMonth][this.wizeFiTransactionsTableIndex].wizeFiCategory = wizeFiCategory;

    // clear the table row selections
    this.wizeFiTransactionsTableIndex = -1;
    this.wizeFiCategoriesTableIndex = -1;
  } // assignWizeFiCategoryToTransaction

  public saveWizeFiTransactions() {
    if (!this.dateRange.wantMonthRange) {
      console.log('Must not specify range of dates for this operation (select a single month YYYY-MM)');
    } else {
      const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
      const monthDate = this.dateRange.yearMonth;

      this.dataModelService.dataManagement
        .putWizeFiTransactions(wizeFiID, monthDate, this.wizeFiTransactionsCollection[monthDate])
        .then(() => {
          console.log('WizeFi transaction data has been saved');
        })
        .catch(err => {
          console.log(err);
        });
    }
  } // saveWizeFiTransactions

  public loadwizeFiTransactionsCollection() {
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    const startDate = '2019-04';
    const endDate = '2019-08';

    this.dataModelService.dataManagement
      .getwizeFiTransactionsCollection(wizeFiID, startDate, endDate)
      .then(wizeFiTransactionsCollection => {
        console.log('wizeFiTransactionsCollection: ', wizeFiTransactionsCollection);
      })
      .catch(err => {
        console.log(err);
      });
  } // loadwizeFiTransactionsCollection

  public setWizeFiTransactionsTableIndex(index) {
    // suppress this action if working on adding a new transaction pattern
    if (!this.wantTransactionPatterns) {
      this.wizeFiTransactionsTableIndex = index;
    }

    // set transaction that is selected on the screen
    this.selectedTransaction = this.wizeFiTransactionsCollection[this.dateRange.yearMonth][this.wizeFiTransactionsTableIndex];
    this.splitTransactionInfo.parent.transaction = this.selectedTransaction;
  } // setWizeFiTransactionsTableIndex

  public clearWizeFiTransactionSelection() {
    // Note: unselecting the selected row terminates the feature to manage Transaction Attribute Patterns
    if (this.wizeFiTransactionsTableIndex !== -1) {
      // clear all color coding from pattern selections
      for (let i = 0; i < this.wizeFiTransactionAttributes.length; i++) {
        const tableElement = document.getElementById('wizeFiTransactionsTable') as HTMLTableElement;
        const cellElement = tableElement.rows[this.wizeFiTransactionsTableIndex].cells[i] as HTMLTableCellElement;
        this.removeClassFromTableCell(cellElement);
      }
    }
    this.wizeFiTransactionsTableIndex = -1;
    this.wantTransactionPatterns = false; // exit from Transaction Attribute Patterns feature
    this.wantManageSplitTransaction = false; // exit from Split Transaction feature
  } // clearWizeFiTransactionSelection

  ///////////////////////////////////////////////////////////
  // routines to support Category Information feature
  ///////////////////////////////////////////////////////////

  public setAttributePattern2wizeFiCategory(wizeFiTransactionAttributePatterns) {
    const attributePattern2wizeFiCategory = {};
    for (const pattern of wizeFiTransactionAttributePatterns) {
      attributePattern2wizeFiCategory[pattern.attributePattern] = pattern.wizeFiCategory;
    }
    return attributePattern2wizeFiCategory;
  } // setAttributePattern2wizeFiCategory

  public setWizeFiPlaidCategoryList(wizeFiPlaidCategoryMap) {
    const wizeFiPlaidCategoryList = [];
    for (const plaidCategory in wizeFiPlaidCategoryMap) {
      if (wizeFiPlaidCategoryMap.hasOwnProperty(plaidCategory)) {
        wizeFiPlaidCategoryList.push({ plaidCategory, genericCategory: wizeFiPlaidCategoryMap[plaidCategory] });
      }
    }
    return wizeFiPlaidCategoryList;
  } // setWizeFiPlaidCategoryList

  public loadWizeFiCategories() {
    this.generic2wizefi = {};
    this.wizeFiCategories = [];
    for (const category of Object.keys(this.dataModelService.dataModel.persistent.plans[this.plan])) {
      if (this.categoryExcludeList.indexOf(category) === -1) {
        for (const subcategory of Object.keys(this.dataModelService.dataModel.persistent.plans[this.plan][category])) {
          if (this.subcategoryExcludeList.indexOf(subcategory) === -1) {
            for (
              let acntndx = 0;
              acntndx < this.dataModelService.dataModel.persistent.plans[this.plan][category][subcategory].accounts.length;
              acntndx++
            ) {
              const accounts = this.dataModelService.dataModel.persistent.plans[this.plan][category][subcategory].accounts;
              const accountID = accounts[acntndx].accountID.val;
              const accountName = accounts[acntndx].accountName.val;

              let actualMonthlyAmount = 0;
              if (accounts[acntndx].hasOwnProperty('actualMonthlyAmount')) {
                actualMonthlyAmount = accounts[acntndx].actualMonthlyAmount.val;
              }

              let genericCategory = 'none';
              if (accounts[acntndx].hasOwnProperty('genericCategory')) {
                genericCategory = accounts[acntndx].genericCategory.val;
              }

              let monthlyMinimum = '';
              if (accounts[acntndx].hasOwnProperty('monthlyMinimum')) {
                monthlyMinimum = accounts[acntndx].monthlyMinimum.val;
              }

              let monthlyAmount = '';
              if (accounts[acntndx].hasOwnProperty('monthlyAmount')) {
                monthlyAmount = accounts[acntndx].monthlyAmount.val;
              }

              const wizeFiCategory = accounts[acntndx].wizeFiCategory.val;

              const item = {
                category,
                subcategory,
                accountID,
                accountName,
                actualMonthlyAmount,
                genericCategory,
                monthlyMinimum,
                monthlyAmount,
                wizeFiCategory
              };
              this.wizeFiCategories.push(item);

              if (genericCategory !== 'none') {
                this.generic2wizefi[genericCategory] = this.makeWizeFiCategoryName(category, subcategory, accountID);
              }
            } // for acntndx
          } // if include subcategory
        } // for subcategory
      } // if include category
    } // for category
    this.wizeFiCategories.sort((a, b) => a.wizeFiCategory.localeCompare(b.wizeFiCategory));
    this.wizeFiCategories.unshift({ category: 'unknown' });
    this.wizeFiCategories.unshift({ category: 'ignore' });
    console.log('wizeFiCategories: ', this.wizeFiCategories); // %//
  } // loadWizeFiCategories

  public loadCategoryInformation() {
    const loadWizeFiTransactionPatterns = async () => {
      const patternCompare = (p1, p2) => (p1.attributePattern === p2.attributePattern ? 0 : p1.attributePattern > p2.attributePattern ? 1 : -1); // patternCompare

      // retrieve wizeFiTransactionAttributePatterns data
      const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
      // do the following in ngOnInit
      // this.wizeFiTransactionAttributePatterns = await this.dataModelService.categoryManagement.getWizeFiTransactionAttributePatterns(wizeFiID);
      this.wizeFiTransactionAttributePatterns.sort(patternCompare);

      // create lookup map
      this.attributePattern2wizeFiCategory = this.setAttributePattern2wizeFiCategory(this.wizeFiTransactionAttributePatterns);

      // %//  \/
      console.log('WizeFi Transaction Patterns loaded');
      console.log('this.wizeFiTransactionAttributePatterns: ', this.wizeFiTransactionAttributePatterns);
      console.log('attributePattern2wizeFiCategory: ', this.attributePattern2wizeFiCategory);
      // %//  /\
    }; // loadWizeFiTransactionPatterns

    const loadWizeFiCategories = () => {
      this.loadWizeFiCategories();
      return Promise.resolve();
    }; // loadWizeFiCategories

    const processWizeFiGenericCategories = wizeFiGenericCategories => {
      this.wizeFiGenericCategories = wizeFiGenericCategories;

      // %//  \/
      console.log('Generic categories loaded');
      console.log('wizeFiGenericCategories: ', this.wizeFiGenericCategories);
      // %//  /\

      return Promise.resolve();
    }; // processWizeFiGenericCategories

    const processWizeFiPlaidCategoryMap = async wizeFiPlaidCategoryMap => {
      const isEmptyObject = obj => Object.keys(obj).length === 0 && obj.constructor === Object; // isEmptyObject

      const initializeWizeFiPlaidCategoryMap = async () => {
        this.loading.isLoading = true;
        const plaidCategoryList: any = await this.dataModelService.plaidManagement.getCategories();
        this.loading.isLoading = false;

        const funcWizeFiPlaidCategoryMap: any = {};
        for (const item of plaidCategoryList) {
          funcWizeFiPlaidCategoryMap[item.categoryName] = 'none';
        }
        console.log('plaidCategoryList: ', plaidCategoryList); // %//
        console.log('wizeFiPlaidCategoryMap: ', funcWizeFiPlaidCategoryMap); // %//

        return funcWizeFiPlaidCategoryMap;
      }; // initializeWizeFiPlaidCategoryMap

      // if wizeFiPlaidCategoryMap is not present in DynamoDB then initialize a new value
      if (isEmptyObject(wizeFiPlaidCategoryMap)) {
        wizeFiPlaidCategoryMap = await initializeWizeFiPlaidCategoryMap();
      }
      this.wizeFiPlaidCategoryMap = wizeFiPlaidCategoryMap;

      // TODO determine whether the following list is necessary, or whether the HTML can utilize wizeFiPlaidCategoryMap to iterate through items
      this.wizeFiPlaidCategoryList = this.setWizeFiPlaidCategoryList(wizeFiPlaidCategoryMap);

      // %//  \/
      console.log('Plaid categories loaded');
      console.log('wizeFiPlaidCategoryMap: ', this.wizeFiPlaidCategoryMap);
      console.log('wizeFiPlaidCategoryList: ', this.wizeFiPlaidCategoryList);
      // %//  /\

      return Promise.resolve();
    }; // processWizeFiPlaidCategoryMap

    const company = 'WizeFi';

    /*
        loadWizeFiTransactionPatterns()
        .then(loadWizeFiCategories)
        .then(() => this.dataModelService.categoryManagement.getWizeFiGenericCategories(company))
        .then(processWizeFiGenericCategories)
        .then(() => this.dataModelService.categoryManagement.getWizeFiPlaidCategoryMap(company))
        .then(processWizeFiPlaidCategoryMap)
        .catch((err) => {console.log(err); });
        */

    loadWizeFiCategories()
      .then(() => this.dataModelService.categoryManagement.getWizeFiGenericCategories(company))
      .then(processWizeFiGenericCategories)
      .then(() => this.dataModelService.categoryManagement.getWizeFiPlaidCategoryMap(company))
      .then(processWizeFiPlaidCategoryMap)
      .catch(err => {
        console.log(err);
      });
  } // loadCategoryInformation

  ///////////////////////////////////////////////
  // routines to support WizeFi Categories
  ///////////////////////////////////////////////

  public setWizeFiCategoriesTableIndex(index) {
    this.wizeFiCategoriesTableIndex = index;
  } // setWizeFiCategoriesTableIndex

  public clearWizeFiCategorySelection() {
    this.wizeFiCategoriesTableIndex = -1;
  } // clearWizeFiCategorySelection

  public deleteWizeFiAccount() {
    const wizeFiCategoryItem = this.wizeFiCategories[this.wizeFiCategoriesTableIndex];
    const category = wizeFiCategoryItem.category;
    const subcategory = wizeFiCategoryItem.subcategory;
    const accountID = wizeFiCategoryItem.accountID;
    const wizeFiCategory = this.dataModelService.categoryManagement.makeWizeFiCategory(category, subcategory, accountID);

    // delete the WizeFi account
    this.dataModelService.categoryManagement.deleteWizeFiAccount(wizeFiCategory);

    // load fresh data on screen after persistent storage is updated
    // use setTimeout as a kludge until it is decided whether to make deleteWizeFiAccount return a promise
    setTimeout(() => {
      this.loadCategoryInformation();
    }, 500);

    // turn off which row is selected
    this.wizeFiCategoriesTableIndex = -1;
  } // deleteWizeFiAccount

  public saveGenericCategorySettings() {
    const saveGenericCategorySetting0 = async () => {
      // set values in memory resident data model
      for (const wizeFiCategory of this.wizeFiCategories) {
        if (wizeFiCategory.category !== 'ignore' && wizeFiCategory.category !== 'unknown' && wizeFiCategory.genericCategory !== 'none') {
          const curplan = this.dataModelService.dataModel.persistent.header.curplan;
          const category = wizeFiCategory.category;
          const subcategory = wizeFiCategory.subcategory;
          const accountName = wizeFiCategory.accountName;
          const acntndx = this.lookupAcntndx(category, subcategory, accountName);
          const accounts = this.dataModelService.dataModel.persistent.plans[curplan][category][subcategory].accounts;
          if (!accounts[acntndx].hasOwnProperty('genericCategory')) {
            accounts[acntndx].genericCategory = { label: 'Generic Category', isRequired: true, val: 'none' };
          }
          accounts[acntndx].genericCategory.val = wizeFiCategory.genericCategory;
        }
      } // for

      // set values in persistent memory
      await this.dataModelService.dataManagement.storeinfo();
      // console.log("after storedata");  //%//
    }; // saveGenericCategorySetting0

    this.loading.isLoading = true;

    saveGenericCategorySetting0()
      .catch(err => {
        console.log('error in saveGenericCategorySettings: ', err);
      })
      .then(() => {
        this.loading.isLoading = false;
      });
  } // saveGenericCategorySettings

  public setPersistentAttributeLists() {
    this.persistentAttributesList = [];
    this.persistentItemsAttributeList = {};
    for (const persistentAttribute of Object.keys(this.dataModelService.dataModel.persistent)) {
      if (this.persistentExcludeAttributeList.indexOf(persistentAttribute) === -1) {
        this.persistentAttributesList.push(persistentAttribute);
        this.persistentItemsAttributeList[persistentAttribute] = [];
        for (const itemAttribute of Object.keys(this.dataModelService.dataModel.persistent[persistentAttribute])) {
          if (this.itemExcludeAttributeList.indexOf(itemAttribute) === -1) {
            this.persistentItemsAttributeList[persistentAttribute].push(itemAttribute);
          }
        } // for itemAttribute
      }
    } // for persistentAttribute
  } // setPersistentAttributeLists

  public changePlan() {
    const changePlan0 = async () => {
      // update WizeFiCategories data
      await this.dataModelService.dataManagement.changeCurrentPlan(this.selectedPlan);
      this.plan = this.dataModelService.dataModel.persistent.header.curplan;
      this.loadWizeFiCategories();

      // update wizeFiPlaidTransactions data
      this.dateRange.wantMonthRange = true;
      this.dateRange.yearMonth = this.dataModelService.dataModel.persistent.header.curplanYearMonth;
      this.loadWizeFiTransactions();
    }; // changePlan0

    changePlan0().catch(err => {
      console.log('error in changePlan: ', err);
    });
  } // changePlan

  ///////////////////////////////////////////////
  // routines to support Generic Categories
  ///////////////////////////////////////////////

  public addGenericCategory() {
    this.wantAddCategory = true;
  } // addGenericCategory

  public addGenericCategoryCancel() {
    this.wantAddCategory = false;
    this.newGenericCategory = '';
  } // addGenericCategoryCancel

  public addGenericCategoryOK() {
    const cmp = (c1, c2) => {
      let result = 0;
      if (c1 === 'none') {
        result = -1;
      } else if (c2 === 'none') {
        result = 1;
      } else if (c1 < c2) {
        result = -1;
      } else if (c1 > c2) {
        result = 1;
      }
      return result;
    }; // cmp

    if (this.wizeFiGenericCategories.indexOf(this.newGenericCategory) !== -1) {
      this.dataModelService.showMessage('error', 'Generic category ' + this.newGenericCategory + ' is already in the list', 4000);
    } else {
      this.wizeFiGenericCategories.push(this.newGenericCategory);
      this.wizeFiGenericCategories.sort(cmp);
      this.wantAddCategory = false;
      this.newGenericCategory = '';
    }
  } // addGenericCategoryOK

  public removeGenericCategory() {
    if (this.wizeFiGenericCategories[this.genericCategoriesTableIndex] === 'none') {
      this.dataModelService.showMessage('error', 'Generic category "none" cannot be removed', 4000);
    } else {
      this.wizeFiGenericCategories.splice(this.genericCategoriesTableIndex, 1);
      this.genericCategoriesTableIndex = -1;
    }
  } // removeGenericCategory

  public saveGenericCategories() {
    const company = 'WizeFi';

    this.dataModelService.categoryManagement
      .putWizeFiGenericCategories(company, this.wizeFiGenericCategories)
      .then(() => {
        console.log('wizeFiGenericCategories data has been saved');
      })
      .catch(err => {
        console.log(err);
      });
  } // saveGenericCategories

  public setGenericCategoriesTableIndex(index) {
    this.genericCategoriesTableIndex = index;
  } // setGenericCategoriesTableIndex

  public clearGenericCategorySelection() {
    this.genericCategoriesTableIndex = -1;
  } // clearGenericCategorySelection

  ///////////////////////////////////////////////
  // routines to support Plaid Categories
  ///////////////////////////////////////////////

  public saveWizeFiPlaidCategoryMap() {
    if (!this.dateRange.wantMonthRange) {
      console.log('Must not specify range of dates for this operation (select a single month YYYY-MM)');
    } else {
      const company = 'WizeFi';

      this.dataModelService.categoryManagement
        .putWizeFiPlaidCategoryMap(company, this.wizeFiPlaidCategoryMap)
        .then(() => {
          console.log('wizeFiPlaidCategoryMap data has been saved');
        })
        .catch(err => {
          console.log(err);
        });
    }
  } // saveWizeFiPlaidCategoryMap

  public setPlaidCategoriesMapTableIndex(index) {
    this.plaidCategoriesMapTableIndex = index;
  } // setPlaidCategoriesMapTableIndex

  public clearPlaidCategoriesMapSelection() {
    this.plaidCategoriesMapTableIndex = -1;
  } // clearPlaidCategoriesMapSelection

  public assignGenericCategoryToWizeFiCategory() {
    // initialize
    const genericCategory = this.wizeFiGenericCategories[this.genericCategoriesTableIndex];
    const category = this.wizeFiCategories[this.wizeFiCategoriesTableIndex].category;
    const subcategory = this.wizeFiCategories[this.wizeFiCategoriesTableIndex].subcategory;
    const accountID = this.wizeFiCategories[this.wizeFiCategoriesTableIndex].accountID;
    const accountName = this.wizeFiCategories[this.wizeFiCategoriesTableIndex].accountName;
    const acntndx = this.lookupAcntndx(category, subcategory, accountName);
    const accounts = this.dataModelService.dataModel.persistent.plans[this.plan][category][subcategory].accounts;

    // update data structure to show result on screen
    this.wizeFiCategories[this.wizeFiCategoriesTableIndex].genericCategory = genericCategory;

    // update data structure to map generic category to WizeFi category
    if (genericCategory !== 'none') {
      this.generic2wizefi[genericCategory] = this.makeWizeFiCategoryName(category, subcategory, accountID);
    }

    // update data in memory resident data model
    if (acntndx === -1) {
      console.log('account:' + accountName + ' does not exist under category:' + category + ' subcategory:' + subcategory);
    } else {
      if (!accounts[acntndx].hasOwnProperty('genericCategory')) {
        // make a clone of the genericCategory object to resolve duplicates that otherwise appear due to by-reference assignment of objects
        accounts[acntndx].genericCategory = JSON.parse(JSON.stringify(possibleFieldNames.genericCategory));
      }
      accounts[acntndx].genericCategory.val = genericCategory;
    }
  } // assignGenericCategoryToWizeFiCategory

  public assignGenericCategoryToPlaidCategory() {
    // initialize
    const genericCategory = this.wizeFiGenericCategories[this.genericCategoriesTableIndex];
    const plaidCategoryName = this.wizeFiPlaidCategoryList[this.plaidCategoriesMapTableIndex].plaidCategory;

    // update wizeFiPlaidCategoryList data structure to show result on screen
    this.wizeFiPlaidCategoryList[this.plaidCategoriesMapTableIndex].genericCategory = genericCategory;

    // update wizeFiPlaidCategoryMap data structure to enable persistent storage update
    this.wizeFiPlaidCategoryMap[plaidCategoryName] = genericCategory;
  } // assignGenericCategoryToPlaidCategory

  ///////////////////////////////////////////////////////////
  // routines to support WizeFi Transactions feature
  ///////////////////////////////////////////////////////////

  public manageTransactionPatterns() {
    this.wantTransactionPatterns = true;
    this.transactionPatternsTableIndex = -1;
    this.attributePatternsCellIndexList = [];
    this.newAttributePattern = '';
    this.newWizeFiCategory = this.wizeFiTransactionsCollection[this.dateRange.yearMonth][this.wizeFiTransactionsTableIndex].wizeFiCategory;

    this.ruleCheckbox = {};
    for (const patternID of Object.keys(this.wizeFiTransactionAttributePatterns)) {
      this.ruleCheckbox[patternID] = false;
    }

    // this.ruleAttributeCheckbox = {};

    this.merchantNameAdjusted = this.selectedTransaction.merchantName;
    this.accountNameAdjusted = this.selectedTransaction.accountName;
    this.institutionNameAdjusted = this.selectedTransaction.institutionName;
    this.plaidCategoryAdjusted = this.selectedTransaction.plaidCategory;
    this.amountAdjusted = this.selectedTransaction.amount;
    this.selectedWizeFiCategory = 'unknown';
  } // manageTransactionPatterns

  public setWizeFiTransactionsTableCellIndex(index) {
    // respond to change in table cell index only if working on adding a new transaction pattern
    if (this.wantTransactionPatterns) {
      // initialize
      const selectedAttribute = this.wizeFiTransactionAttributes[index];
      const selectedWizeFiCategory = this.wizeFiTransactionsCollection[this.dateRange.yearMonth][this.wizeFiTransactionsTableIndex].wizeFiCategory;

      // alert user if attempt is made to make a pattern with wizeFiCategory = 'unknown'
      if (selectedWizeFiCategory === 'unknown') {
        this.dataModelService.showMessage(
          'error',
          'A pattern cannot be made for a transaction where wizeFiCategory is unknown.' +
            '  Utillize the "Manual Assign Category" button to assign a wizeFiCategory to a transaction.',
          60000
        ); // TODO review duration of message on screen
      }

      // exclude date, wizeFiCategory, and unknown wizeFiCategory from further consideration
      if (selectedAttribute !== 'date' && selectedAttribute !== 'wizeFiCategory' && selectedWizeFiCategory !== 'unknown') {
        if (this.attributePatternsCellIndexList.indexOf(index) === -1) {
          // add new item to pattern
          this.attributePatternsCellIndexList.push(index);
          this.attributePatternsCellIndexList.sort();
        } else {
          // remove item from pattern
          const loc = this.attributePatternsCellIndexList.indexOf(index);
          this.attributePatternsCellIndexList.splice(loc, 1);
        }

        // set newAttributePattern
        let count = 0;
        this.newAttributePattern = '';
        for (let i = 0; i < this.wizeFiTransactionAttributes.length; i++) {
          if (this.attributePatternsCellIndexList.indexOf(i) !== -1) {
            const attribute = this.wizeFiTransactionAttributes[i];
            if (count > 0) {
              this.newAttributePattern += ':';
            }
            this.newAttributePattern += attribute + ':';
            this.newAttributePattern += this.wizeFiTransactionsCollection[this.dateRange.yearMonth][this.wizeFiTransactionsTableIndex][attribute];
            count++;
          }
        }

        // update background color of table cells for selected attributes
        this.wizeFiTransactionsTableCellIndex = index;
        for (let i = 0; i < this.wizeFiTransactionAttributes.length; i++) {
          const tableElement = document.getElementById('wizeFiTransactionsTable') as HTMLTableElement;
          const cellElement = tableElement.rows[this.wizeFiTransactionsTableIndex].cells[i] as HTMLTableCellElement;
          if (this.attributePatternsCellIndexList.indexOf(i) === -1) {
            this.removeClassFromTableCell(cellElement);
          } else {
            this.addClassToTableCell(cellElement);
          }
        }

        console.log('attributePatternsCellIndexList: ', this.attributePatternsCellIndexList); // %//
      }
    } // if wantTransactionPatterns
  } // setWizeFiTransactionsTableCellIndex

  public ruleCheckboxChange(patternID) {
    // set selectedPatternID
    this.selectedPatternID = 'none';
    if (this.ruleCheckbox[patternID]) {
      this.selectedPatternID = patternID;
    }

    // update ruleCheckbox
    for (const funcPatternID of Object.keys(this.ruleCheckbox)) {
      this.ruleCheckbox[funcPatternID] = funcPatternID === this.selectedPatternID ? true : false;
    }

    // force user to identify action to perform if check box value is changed
    this.wantProcessAttributePattern = false;
  } // ruleCheckboxChange

  /////////////////////////////////////////////////////////////////////
  // routines to support Assign Generic Category feature
  /////////////////////////////////////////////////////////////////////

  public assignGenericCategoryToWizeFiAccountPrepare() {
    // populate function parameters using user screen interaction data
    const transaction = this.selectedTransaction;
    const genericCategory = this.getPlaidGenericCategory(transaction.category);
    const wizeFiCategory = transaction.wizeFiCategory;

    // call the function to do the actual work
    this.dataModelService.categoryManagement.assignGenericCategoryToWizeFiAccount(genericCategory, wizeFiCategory);
  } // assignGenericCategoryToWizeFiAccountPrepare

  public manageGenericCategory() {
    this.wantManageGenericCategory = true;
  } // manageGenericCategory

  public manageGenericCategoryExit() {
    this.wantManageGenericCategory = false;
  } // manageGenericCategoryExit

  public getPlaidGenericCategory(plaidCategory) {
    return this.dataModelService.categoryManagement.getPlaidGenericCategory(plaidCategory);
  } // getPlaidGenericCategory

  public getWizeFiGenericCategory(wizeFiCategory) {
    return this.dataModelService.categoryManagement.getWizeFiGenericCategory(wizeFiCategory);
  } // getWizeFiGenericCategory

  /////////////////////////////////////////////////////////////////////
  // routines to support Transaction Attribute Patterns feature
  /////////////////////////////////////////////////////////////////////

  public async processAttributePattern(action) {
    const processAttributePattern0 = async funcAction => {
      let hadEdit = false;

      if (funcAction === 'add') {
        this.patternAction = 'add';
        this.patternHeader = 'Add New Attribute Pattern';
        this.ruleTransaction = this.selectedTransaction;
        this.attributePatternID = 'aaaaa'; // TODO use this.dataModelService.generateIDcode(5);
        this.selectedWizeFiCategory = this.ruleTransaction.wizeFiCategory;
      } else {
        const wizeFiTransactionAttributePattern = this.wizeFiTransactionAttributePatterns[this.selectedPatternID];
        if (wizeFiTransactionAttributePattern.isDefaultRule) {
          console.log('The edit option is not presently implemented for a default rule'); //%//
          this.dataModelService.showMessage('info', 'The edit option is not presently implemented for a default rule', 7000);
        } else {
          hadEdit = true;
          this.patternAction = 'edit';
          this.patternHeader = 'Edit Existing Attribute Pattern';
          this.ruleTransaction = await this.dataModelService.categoryManagement.getRuleTransaction(this.selectedPatternID);
          this.attributePatternID = this.selectedPatternID;
          this.isActiveRule = wizeFiTransactionAttributePattern.isActive;
          this.selectedWizeFiCategory = wizeFiTransactionAttributePattern.wizeFiCategory;
        }
      }
      // console.log("ruleTransaction: ", this.ruleTransaction);  // %//

      // set up default values
      this.wantAttribute = {};
      for (const attribute of this.ruleAttributeList) {
        this.wantAttribute[attribute] = false;
      }

      this.adjustedAttribute = {};
      for (const attribute of this.ruleAttributeList) {
        this.adjustedAttribute[attribute] = this.ruleTransaction[attribute];
      }

      // adjust default values
      if (funcAction === 'edit' && hadEdit) {
        const wizeFiTransactionAttributePattern = this.wizeFiTransactionAttributePatterns[this.selectedPatternID];
        const attributePattern = wizeFiTransactionAttributePattern.attributePattern;
        for (const attribute of Object.keys(attributePattern)) {
          this.wantAttribute[attribute] = true;
          this.adjustedAttribute[attribute] = attributePattern[attribute];
        }
      }

      // activite HTML code to process attribute pattern
      this.wantProcessAttributePattern = true;
    }; // processAttributePattern0

    processAttributePattern0(action).catch(err => {
      console.log('error in processAttributePattern:', err);
    });
  } // processAttributePattern

  public processAttributePatternPrepare() {
    console.log('processAttributePatternPrepare -- patternAction: ' + this.patternAction); // %//

    // construct a transaction attribute pattern object based on user screen input
    let attributePattern: any;
    attributePattern = {};
    for (const attribute of this.ruleAttributeList) {
      if (this.wantAttribute[attribute]) {
        attributePattern[attribute] = this.adjustedAttribute[attribute];
      }
    }
    console.log('attributePattern: ', attributePattern); // *//

    if (Object.keys(attributePattern).length < 1) {
      console.log('Must select at lease one attribute in order to build a pattern');
      this.dataModelService.showMessage('info', 'Must select at lease one attribute in order to build a pattern', 7000);
    } else {
      // construct a wizeFiTransactionAttributePattern
      const transaction = this.selectedTransaction;

      let wizeFiTransactionAttributePattern: any;
      wizeFiTransactionAttributePattern = {};
      wizeFiTransactionAttributePattern.patternID = this.attributePatternID;
      wizeFiTransactionAttributePattern.isActive = this.isActiveRule;
      wizeFiTransactionAttributePattern.isDefaultRule = false;
      wizeFiTransactionAttributePattern.wizeFiCategory = this.selectedWizeFiCategory;
      wizeFiTransactionAttributePattern.source_id = this.ruleTransaction.transaction_id;
      wizeFiTransactionAttributePattern.attributePattern = attributePattern;
      console.log('wizeFiTransactionAttributePattern: ', wizeFiTransactionAttributePattern); // *//

      // do the final work in the following function
      if (this.patternAction === 'add') {
        this.dataModelService.categoryManagement.addAttributePattern(wizeFiTransactionAttributePattern).catch(err => {
          console.log('error in addAttributePattern: ', err);
        });
      } else {
        this.dataModelService.categoryManagement.modifyAttributePattern(wizeFiTransactionAttributePattern).catch(err => {
          console.log('error in modifyAttributePattern: ', err);
        });
      }
    }
    this.wantProcessAttributePattern = false;
  } // processAttributePatternPrepare

  public processAttributePatternExit() {
    this.wantProcessAttributePattern = false;
  } // processAttributePatternExit

  public deleteAttributePattern() {
    this.dataModelService.categoryManagement
      .deleteAttributePattern(this.selectedPatternID)
      .then(() => {
        // reset all ruleCheckbox values to false
        for (const patternID of Object.keys(this.ruleCheckbox)) {
          this.ruleCheckbox[patternID] = false;
        }
        this.selectedPatternID = 'none';
      })
      .catch(err => {
        console.log('error in deleteAttributePattern:', err);
      });
  } // deleteAttributePattern

  public transactionPatternExit() {
    this.wantTransactionPatterns = false;
  } // transactionPatternExit

  public setTransactionPatternsTableIndex(index) {
    this.transactionPatternsTableIndex = index;
  } // setTransactionPatternsTableIndex

  public clearTransactionPatternsSelection() {
    this.transactionPatternsTableIndex = -1;
  } // clearTransactionPatternsSelection

  //////////////////////////////////////////////////////////////
  // routines to support Split Transaction feature
  //////////////////////////////////////////////////////////////

  public manageSplitTransaction() {
    this.wantManageSplitTransaction = true;
    this.firstChildAmount = this.splitTransactionInfo.parent.transaction.amount;
    this.splitCount = 2;
  } // manageSplitTransaction

  public createSplitTransactions() {
    const wizeFiTransactions = this.wizeFiTransactionsCollection[this.dateRange.yearMonth];

    // constract proper format for data in splitTransactionInfo
    const splitTransactionInfo2 = JSON.parse(JSON.stringify(this.splitTransactionInfo)); // clone the data
    splitTransactionInfo2.children[0].amount = this.firstChildAmount; // set firstChildAmount in proper place
    splitTransactionInfo2.parent.transaction = this.splitTransactionInfo.parent.transaction; // set reference to parent transaction

    this.dataModelService.categoryManagement.createSplitTransactions(splitTransactionInfo2, wizeFiTransactions);
    this.wizeFiTransactionsCollection[this.dateRange.yearMonth].sort(this.wizeFiTransactionsCompare);

    // store the modified data in persistent storage
    // TODO determine whether this should be done here, or some place else in the WizeFi flow
    this.saveWizeFiTransactions();
  } // createSplitTransactions

  public deleteSplitTransaction() {
    const wizeFiTransactions = this.wizeFiTransactionsCollection[this.dateRange.yearMonth];
    this.dataModelService.categoryManagement.deleteSplitTransaction(this.splitTransactionInfo, wizeFiTransactions);

    // store the modified data in persistent storage
    // TODO determine whether this should be done here, or some place else in the WizeFi flow
    this.saveWizeFiTransactions();
  } // deleteSplitTransaction

  public createSplitTransactionsExit() {
    this.wantManageSplitTransaction = false;
  } // createSplitTransactionsExit

  public onSplitCountChange() {
    this.dataModelService.categoryManagement.changeChildListLength(this.splitCount, this.splitTransactionInfo);
    this.firstChildAmount = this.dataModelService.categoryManagement.getFirstChildAmount(this.splitTransactionInfo);
    // const splitTransactionInfo2 = JSON.parse(JSON.stringify(this.splitTransactionInfo));  // clone the data
    // splitTransactionInfo2.children[0].amount = this.firstChildAmount;
    this.isReadyForCreate = this.dataModelService.categoryManagement.isReadyForCreate(this.splitTransactionInfo);
  } // onSplitCountChange

  public onChildAmountChange() {
    this.firstChildAmount = this.dataModelService.categoryManagement.getFirstChildAmount(this.splitTransactionInfo);
    // const splitTransactionInfo2 = JSON.parse(JSON.stringify(this.splitTransactionInfo));  // clone the data
    // splitTransactionInfo2.children[0].amount = this.firstChildAmount;
    this.isReadyForCreate = this.dataModelService.categoryManagement.isReadyForCreate(this.splitTransactionInfo);
  } // onChildAmountChange

  public getInitialSplitTransactionInfo() {
    return this.dataModelService.categoryManagement.getInitialSplitTransactionInfo();
  } // getInitialSplitTransactionInfo

  public childAmountsTotal() {
    return this.dataModelService.categoryManagement.childAmountsTotal(this.splitTransactionInfo);
  } // childAmountsTotal

  public childAmountsDifference() {
    return this.dataModelService.categoryManagement.childAmountsDifference(this.splitTransactionInfo);
  } // childAmountsDifference

  ///////////////////////////////////////////////////////////////////////
  // routines to support select wizeFiCategory for split transaction
  ///////////////////////////////////////////////////////////////////////

  public changeSelectedCategory() {
    this.selectedSubcategory = 'any';
    this.filteredWizeFiCategoryList = this.dataModelService.categoryManagement.getFilteredWizeFiCategoryList(
      this.selectedCategory,
      this.selectedSubcategory,
      this.fullWizeFiCategoryList
    );
  } // changeSelectedCategory

  public changeSelectedSubcategory() {
    this.filteredWizeFiCategoryList = this.dataModelService.categoryManagement.getFilteredWizeFiCategoryList(
      this.selectedCategory,
      this.selectedSubcategory,
      this.fullWizeFiCategoryList
    );
  } // changeSelectedSubcategory

  //////////////////////////////////////////////////////////////
  // routines to support plan and transaction dates
  //////////////////////////////////////////////////////////////

  public async obtainPlanAndTransactionDates() {
    // obtainPlanDatesInfo
    this.planDates = this.dataModelService.categoryManagement.obtainPlanDatesInfo();
    console.log('planDates: ', this.planDates); // %//

    // obtainTransactionDatesInfo
    this.transactionDates = await this.dataModelService.categoryManagement.obtainTransactionDatesInfo();
    console.log('transactionDates: ', this.transactionDates); // %//
  } // obtainPlanAndTransactionDates

  //////////////////////////////////////////////////////////////
  // routines to support Transaction Sum Amounts feature
  //////////////////////////////////////////////////////////////

  public setTransactionAmountLookup(wizeFiTransactionsCollection, yearMonth) {
    const transactionAmountLookup = {};
    for (const transaction of wizeFiTransactionsCollection[yearMonth]) {
      if (!transactionAmountLookup.hasOwnProperty(transaction.wizeFiCategory)) {
        transactionAmountLookup[transaction.wizeFiCategory] = { amount: 0, wizeFiCategory: transaction.wizeFiCategory };
      }
      transactionAmountLookup[transaction.wizeFiCategory].amount += transaction.amount;
    }
    return transactionAmountLookup;
  } // setTransactionAmountLookup

  public setTransactionAmountList(transactionAmountLookup) {
    const transactionAmountList = [];
    for (const key of Object.keys(transactionAmountLookup)) {
      const item = transactionAmountLookup[key];
      item.amount = Number(item.amount.toFixed(2)); // round value to two decimal points
      transactionAmountList.push(item);
    }
    return transactionAmountList;
  } // setTransactionAmountList

  public loadTransactionSumAmounts() {
    // TODO replace the use of this function with assignActualMonthlyAmount in CategoryManagement class
    // compute sum of all amounts for each wizeFiCategory
    // const transactionAmountLookup = this.setTransactionAmountLookup(this.wizeFiTransactionsCollection, this.dateRange.yearMonth);
    const wizeFiTransactionsCollection = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection;
    const transactionAmountLookup = this.setTransactionAmountLookup(wizeFiTransactionsCollection, this.dateRange.yearMonth);

    // place results in an array
    const transactionAmountList = this.setTransactionAmountList(transactionAmountLookup);

    this.transactionAmountLookup = transactionAmountLookup;
    this.transactionAmountList = transactionAmountList;
    this.transactionAmountAttributes = this.setAttributes(this.transactionAmountList);

    // %//   \/
    console.log('transactionAmountLookup: ', this.transactionAmountLookup);
    console.log('transactionAmountAttributes: ', this.transactionAmountAttributes);
    console.log('transactionAmountList: ', this.transactionAmountList);
    // %//   /\
  } // loadTransactionSumAmounts

  public saveActualMonthlyAmount() {
    const saveActualMonthlyAmount0 = async () => {
      // set values in memory resident data model
      for (const item of this.transactionAmountList) {
        let wizeFiCategoryInfo: any;
        wizeFiCategoryInfo = this.dataModelService.categoryManagement.decodeWizeFiCategory(item.wizeFiCategory);
        if (wizeFiCategoryInfo.category !== 'ignore' && wizeFiCategoryInfo.category !== 'unknown') {
          const curplan = this.dataModelService.dataModel.persistent.header.curplan;
          const category = wizeFiCategoryInfo.category;
          const subcategory = wizeFiCategoryInfo.subcategory;
          const account = wizeFiCategoryInfo.accountName;
          const accounts = this.dataModelService.dataModel.persistent.plans[curplan][category][subcategory].accounts;
          const acntndx = wizeFiCategoryInfo.acntndx;
          if (acntndx !== -1) {
            if (!accounts[acntndx].hasOwnProperty('actualMonthlyAmount')) {
              accounts[acntndx].actualMonthlyAmount = {};
            }
            accounts[acntndx].actualMonthlyAmount.val = item.amount;
          }
        }
      } // for

      // set values in persistent memory
      await this.dataModelService.dataManagement.storeinfo();
    }; // saveActualMonthlyAmount0

    this.loading.isLoading = true;

    saveActualMonthlyAmount0()
      .catch(err => {
        console.log('error in saveActualMonthlyAmount: ', err);
      })
      .then(() => {
        this.loading.isLoading = false;
      });
  } // saveActualMonthlyAmount

  ///////////////////////////////////////////////////////////////////////
  // routines to support Select Currency Code
  ///////////////////////////////////////////////////////////////////////

  public getCurrencyCheckboxStatus(userCurrencyCode, currencyCode2currencyCodeItem) {
    const currencyCheckboxStatus = {};
    for (const currencyCode of Object.keys(currencyCode2currencyCodeItem)) {
      currencyCheckboxStatus[currencyCode] = currencyCode === userCurrencyCode ? true : false;
    }
    return currencyCheckboxStatus;
  } // getCurrencyCheckboxStatus

  public processCurrencyCheckboxStatus(currencyCode) {
    if (this.currencyCheckboxStatus[currencyCode]) {
      // set userCurrencyCode value
      this.userCurrencyCode = this.currencyCode2currencyCodeItem[currencyCode].currencyCode;

      // reset currencyCheckboxStatus
      this.currencyCheckboxStatus = this.getCurrencyCheckboxStatus(this.userCurrencyCode, this.currencyCode2currencyCodeItem);
    }
  } // processCurrencyCheckboxStatus

  public exitSelectCurrency() {
    console.log('Exit select currency code'); // %//
    console.log('oldUserCurrencyCode: ' + this.oldUserCurrencyCode); // %//
    console.log('userCurrencyCode: ' + this.userCurrencyCode); // %//
    if (this.userCurrencyCode !== this.oldUserCurrencyCode) {
      this.oldUserCurrencyCode = this.userCurrencyCode;
      this.dataModelService.dataModel.persistent.settings.currencyCode = this.userCurrencyCode;
      // note: await is not used below because memory resident data is already updated,
      // and 100 millisecond delay in DynamoDB update will not affect anything downstream
      this.dataModelService.dataManagement.storeinfo();
      console.log('userCurrencyCode has been updated in DynamoDB'); // %//
    }
    this.wantSelectCurrency = false;
  } // exitSelectCurrency

  ///////////////////////////////////////////////////////////////////////
  // routines to support Select Countries
  ///////////////////////////////////////////////////////////////////////

  public getUserCurrencyCode() {
    let userCurrencyCode = this.dataModelService.dataModel.persistent.settings.currencyCode;
    if (userCurrencyCode === 'undefined') {
      userCurrencyCode = 'USD';
    }
    return userCurrencyCode;
  } // getUserCurrencyCode

  public getUserCountryCodes() {
    let userCountryCodes: any;

    userCountryCodes = !this.dataModelService.dataModel.persistent.settings.hasOwnProperty('userCountryCodes')
      ? ['US']
      : this.dataModelService.dataModel.persistent.settings.userCountryCodes;

    return userCountryCodes;
  } // getUserCountryCodes

  public getCountryCheckboxStatus(userCountryCodes, countryCodeItems) {
    const countryCheckboxStatus = [];
    for (const countryCodeItem of countryCodeItems) {
      countryCheckboxStatus.push(userCountryCodes.indexOf(countryCodeItem.countryCode) !== -1 ? true : false);
    }
    return countryCheckboxStatus;
  } // getCountryCheckboxStatus

  public processCountryCheckboxStatus(i) {
    const countryCodeCompare = (a, b) => {
      const aCountryName = this.countryCode2countryCodeItem[a].countryName;
      const bCountryName = this.countryCode2countryCodeItem[b].countryName;

      const result = aCountryName === bCountryName ? 0 : aCountryName > bCountryName ? 1 : -1;

      return result;
    }; // countryCodeCompare

    if (this.countryCheckboxStatus[i]) {
      // add new countryCode to current selected countries
      this.userCountryCodes.push(this.countryCodeItems[i].countryCode);
      this.userCountryCodes.sort(countryCodeCompare);
    } else {
      // remove country from current selected countries
      console.log('i: ' + i + '  countryCode: ' + this.countryCodeItems[i].countryCode); // %//
      let ndx = this.userCountryCodes.length;
      while (--ndx >= 0 && this.userCountryCodes[ndx] !== this.countryCodeItems[i].countryCode) {
        console.log('ndx: ' + ndx + '  countryCode: ' + this.userCountryCodes[ndx]); // %//
      }
      if (ndx >= 0) {
        this.userCountryCodes.splice(ndx, 1);
      }
    }
  } // processCountryCheckboxStatus

  public continueLinkProcessing() {
    console.log('continueLinkProcessing'); // %//
    console.log('oldUserCountryCodes: ', this.oldUserCountryCodes); // %//
    console.log('userCountryCodes: ', this.userCountryCodes); // %//
    if (JSON.stringify(this.userCountryCodes) !== JSON.stringify(this.oldUserCountryCodes)) {
      this.oldUserCountryCodes = JSON.parse(JSON.stringify(this.userCountryCodes));
      this.dataModelService.dataModel.persistent.settings.userCountryCodes = this.userCountryCodes;
      // note: await is not used below because memory resident data is already updated,
      // and any time delay in DynamoDB update will not affect anything downstream
      this.dataModelService.dataManagement.storeinfo();
      console.log('userCountryCodes have been updated in DynamoDB'); // %//
    }
    this.wantSelectCountries = false;
  } // continueLinkProcessing

  ///////////////////////////////////////////////////////////////////////
  // routines to support Multiple Institution Instances
  ///////////////////////////////////////////////////////////////////////

  public cancelLinkInstance() {
    console.log('cancelLinkInstance'); // %//
    this.wantMultipleInstitutionInstances = false;
  } // cancelLinkInstance

  public continueLinkInstance() {
    console.log('continueLinkInstance'); // %//
    this.wantMultipleInstitutionInstances = false;
  } // continueLinkInstance

  ///////////////////////////////////////////////////////////////////////
  // routines to support account linking (Plaid to WizeFi account)
  ///////////////////////////////////////////////////////////////////////

  public changePlaidAccount() {
    this.accountLinkInfo.wizeFiPlaidAccount = this.wizeFiPlaidAccountsLink[this.wizeFiPlaidAccountIndex];
  } // changePlaidAccount

  public changeCategory() {
    this.accountLinkInfo.subcategory = this.subcategoryLinkList[this.accountLinkInfo.category][0];
    this.filteredWizeFiCategoryLinkList = this.dataModelService.categoryManagement.getFilteredWizeFiCategoryLinkList(
      this.accountLinkInfo.category,
      this.accountLinkInfo.subcategory,
      this.fullWizeFiCategoryLinkList
    );
    this.accountLinkInfo.wizeFiCategory = 'none';
  } // changeCategory

  public changeSelectedSubcategoryLink() {
    this.filteredWizeFiCategoryLinkList = this.dataModelService.categoryManagement.getFilteredWizeFiCategoryLinkList(
      this.accountLinkInfo.category,
      this.accountLinkInfo.subcategory,
      this.fullWizeFiCategoryLinkList
    );
    this.accountLinkInfo.wizeFiCategory = 'none';
  } // changeSelectedSubcategoryLink

  public plaidAccountDisplayInfo(wizeFiPlaidAccount) {
    const displayInfo =
      wizeFiPlaidAccount.institutionName +
      ' --- ' +
      wizeFiPlaidAccount.accountName +
      ' --- ' +
      wizeFiPlaidAccount.accountType +
      ' --- ' +
      wizeFiPlaidAccount.accountSubtype +
      ' --- ' +
      wizeFiPlaidAccount.status +
      ' --- ' +
      wizeFiPlaidAccount.isActive +
      ' --- ' +
      wizeFiPlaidAccount.wizeFiCategory;
    return displayInfo;
  } // plaidAccountDisplayInfo

  public processPlaidAccountLink() {
    console.log('processPlaidAccountLink'); // %//
    console.log('accountLinkInfo: ', this.accountLinkInfo); // %//
    const wizeFiAccount = this.dataModelService.categoryManagement.processPlaidAccountLink(this.accountLinkInfo);
    console.log('result from processPlaidAccountLink -- wizeFiAccount: ', wizeFiAccount); // %//

    // %//  \/
    // save data to DynamoDB at this point for things later to work correctly
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    const wizeFiPlaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    this.dataModelService.plaidManagement
      .storePlaidAccounts(wizeFiID, '', wizeFiPlaidAccounts)
      .then(() => this.dataModelService.dataManagement.storeinfo())
      .catch(err => {
        console.log('error in processPlaidAccountLink in admin-test-plaid: ', err);
      });
    // %//  /\
  } // processPlaidAccountLink

  ///////////////////////////////////////////////////////////////////////
  // routines to support delete institution
  ///////////////////////////////////////////////////////////////////////

  public deleteInstitution(wizeFiPlaidInstitution) {
    const deleteInstitution0 = async funcWizeFiPlaidInstitution => {
      // initialize
      let needStoreWizeFiData = false;
      const deleteItemId = funcWizeFiPlaidInstitution.item_id;

      // process wizeFiPlaidAccounts to be deleted (move backwards through list to facilitate deletions from array)
      let i = this.wizeFiPlaidAccounts.length;
      while (--i >= 0) {
        const wizeFiPlaidAccount = this.wizeFiPlaidAccounts[i];
        if (wizeFiPlaidAccount.item_id === deleteItemId) {
          // delete any wizeFiAccount that is linked to this wizeFiPlaidAccount
          const wizeFiCategory = wizeFiPlaidAccount.wizeFiCategory;
          if (this.dataModelService.categoryManagement.isValidWizeFiCategoryAccount(wizeFiCategory)) {
            needStoreWizeFiData = true;
            const info = this.dataModelService.categoryManagement.decodeWizeFiCategory(wizeFiCategory);
            const curplan = this.dataModelService.dataModel.persistent.header.curplan;
            const category = info.category;
            const subcategory = info.subcategory;
            const accounts = this.dataModelService.dataModel.persistent.plans[curplan][category][subcategory].accounts;
            accounts.splice(info.acntndx, 1);
          }

          // delete the wizeFiPlaidAccount
          this.wizeFiPlaidAccounts.splice(i, 1);
        }
      }
      if (needStoreWizeFiData) {
        await this.dataModelService.dataManagement.storeinfo();
      }
      // DynamoDB update of WizeFiPlaidAccounts here
      const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
      await this.dataModelService.plaidManagement.storePlaidAccounts(wizeFiID, '', this.wizeFiPlaidAccounts);

      // delete the selected wizeFiPlaidInstitution
      const title = this.dataModelService.dataManagement.getDraftTitle();
      const itemIdCount = await this.dataModelService.dataManagement.getItemIdCount(wizeFiID, deleteItemId);
      await this.dataModelService.plaidManagement.deletePlaidInstitution(wizeFiID, title, deleteItemId, itemIdCount);

      // update memory resident data that was changed by Lambda function
      await this.dataModelService.dataManagement.fetchinfo();
      await this.dataModelService.plaidManagement.getPlaidData();
      this.wizeFiPlaidInstitutions = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions;
    }; // deleteInstitution0

    deleteInstitution0(wizeFiPlaidInstitution).catch(err => {
      console.log('error in deleteInstitution: ', err);
    });
  } // deleteInstitution

  ///////////////////////////////////
} // class AdminTestPlaidComponent
