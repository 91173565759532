import { Component, OnInit } from '@angular/core';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { wizeFiPlaidAccountSchema } from './../../../services/data-model/data-model_0001.data';

@Component({
  selector: 'app-financial-freedom-details',
  templateUrl: './financial-freedom-details.component.html',
  styleUrls: ['./financial-freedom-details.component.scss']
})
export class FinancialFreedomDetailsComponent implements OnInit {
  public isLoading = true;
  public isUpdating = false;
  public emergencySavings = 0;
  public generalSavings = 0;
  public retirementIncome = 0;
  public retirementAge = 0;
  public monthlySocialSecurity = 0;
  public socialSecurityAge = 0;
  public monthlyPension = 0;
  public pensionAge = 0;
  public inflationRate = 0;
  public investmentReturnRate = 0;
  public accounts;
  public blockSave: any;
  public infoModal: boolean;
  public GSModal: boolean;
  private DEFAULT_GS_TARGET_AMOUNT = 15000;
  private DEFAULT_ES_TARGET_AMOUNT = 2000;
  constructor(protected dataModelService: DataModelService) {}

  public async ngOnInit() {
    await this.dataModelService.plaidManagement.getPlaidData();
    this.accounts = await this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    const profileData = await this.dataModelService.dataModel.persistent.profile;
    await this.setInputTextfieldValues(profileData);
    this.isLoading = false;
    this.blockSave = document.querySelector('button#savecontinuebtn');
    if (this.blockSave) {
      this.blockSave.disabled = true;
      if (this.retirementIncome) {
        this.blockSave.disabled = false;
      }
    }
  }

  private setInputTextfieldValues(profileData) {
    this.emergencySavings = this.setInitialEmergencySavingsTarget(profileData);
    this.generalSavings = this.setInitialGeneralSavingsTarget(profileData);
    this.retirementIncome = profileData.monthlyRetirementIncome;
    this.retirementAge = profileData.retirementAge;
    this.monthlySocialSecurity = profileData.monthlySocialSecurity;
    this.socialSecurityAge = profileData.socialSecurityDrawAge;
    this.monthlyPension = profileData.monthlyPension;
    this.pensionAge = profileData.pensionDrawAge;
    this.inflationRate = profileData.inflationRate;
    this.investmentReturnRate = profileData.investmentReturnRate;
  }

  private setInitialEmergencySavingsTarget(profileData) {
    const ESAccounts = [];
    let targetAmount;
    this.accounts.forEach(element => {
      if (element && element.targetAmount && element.wizeFiCategory.includes('emergencySavings')) {
        ESAccounts.push(element);
      }
    });
    console.log(ESAccounts);
    console.log(ESAccounts.reduce((a, b) => a + (b.targetAmount ? b.targetAmount.val : 0), 0));
    const targetAmountFromAccounts = ESAccounts.reduce((a, b) => a + (b.targetAmount ? b.targetAmount.val : 0), 0);
    if (targetAmountFromAccounts === 0) {
      targetAmount = this.DEFAULT_ES_TARGET_AMOUNT;
    } else {
      targetAmount = targetAmountFromAccounts;
    }
    return targetAmount;
  }

  private setInitialGeneralSavingsTarget(profileData) {
    const GSAccounts = [];
    let targetAmount;
    this.accounts.forEach(element => {
      if (element && element.targetAmount && element.wizeFiCategory.includes('cashReserves')) {
        GSAccounts.push(element);
      }
    });
    console.log('DEFAULT_GS_TARGET_AMOUNT', this.DEFAULT_GS_TARGET_AMOUNT);
    console.log('GS Accounts', GSAccounts);
    const targetAmountFromAccounts = GSAccounts.reduce((a, b) => a + (b.targetAmount ? b.targetAmount.val : 0), 0);
    if (targetAmountFromAccounts === 0) {
      targetAmount = this.DEFAULT_GS_TARGET_AMOUNT;
    } else {
      targetAmount = targetAmountFromAccounts;
    }
    return targetAmount;
  }

  public onEmergencySavingsChange(value: string) {
    this.emergencySavings = parseFloat(value) || 0;
  }

  public onGeneralSavingsChange(value: string) {
    this.generalSavings = parseFloat(value) || 0;
  }

  public onRetirementIncomeChange(value: string) {
    if (value.length > 0) {
      if (this.blockSave) {
        this.blockSave.disabled = false;
      }
      this.retirementIncome = parseFloat(value) || 0;
    } else {
      if (this.blockSave) {
        this.blockSave.disabled = true;
      }
    }
  }

  public onRetirementAgeChange(value: string) {
    this.retirementAge = parseInt(value, 10) || 0;
  }

  public onSocialSecurityIncomeChange(value: string) {
    this.monthlySocialSecurity = parseFloat(value) || 0;
  }

  public onSocialSecurityAgeChange(value: string) {
    this.socialSecurityAge = parseInt(value, 10) || 0;
  }

  public onPensionIncomeChange(value: string) {
    this.monthlyPension = parseFloat(value) || 0;
  }

  public onPensionAgeChange(value: string) {
    this.pensionAge = parseInt(value, 10) || 0;
  }

  public onInflationRateChange(value: string) {
    this.inflationRate = parseFloat(value) || 0;
  }

  public onInvestmentReturnRateChange(value: string) {
    this.investmentReturnRate = parseFloat(value) || 0;
  }

  public canSave(): boolean {
    return this.retirementIncome > 0 && this.inflationRate > 0 && this.investmentReturnRate > 0;
  }

  public toggleInfoModal(type) {
    if (type === 'ES') {
      this.infoModal = !this.infoModal;
    } else if (type === 'GS') {
      this.GSModal = !this.GSModal;
    }
  }

  public async onSave() {
    this.isUpdating = true;
    const profileData = this.dataModelService.dataModel.persistent.profile;
    profileData.emergencySavingsTargetAmount = this.emergencySavings;
    profileData.cashReservesTargetAmount = this.generalSavings;
    profileData.monthlyRetirementIncome = this.retirementIncome;
    profileData.financialFreedomTargetAmount = this.retirementIncome * 12 * 25;
    profileData.retirementAge = this.retirementAge;
    profileData.monthlySocialSecurity = this.monthlySocialSecurity;
    profileData.socialSecurityDrawAge = this.socialSecurityAge;
    profileData.monthlyPension = this.monthlyPension;
    profileData.pensionDrawAge = this.pensionAge;
    profileData.inflationRate = this.inflationRate;
    profileData.investmentReturnRate = this.investmentReturnRate;
    this.isUpdating = false;
  }
}
