import { IVerifyResult } from '../interfaces/iVerifyResults.interface';
import { DataModelService } from '../services/data-model/data-model.service';
import { accountTypes, categoryInfo, possibleFieldNames, skipSubcatAttributes } from '../services/data-model/data-model_0001.data';
import { ItemManagement } from '../utilities/item-management.class';
import { CValidityCheck } from '../utilities/validity-check.class'; // %//

export class GenericDataManagement {
  // this data controls visibility of components in HTML
  public showAllAccounts: boolean;
  public showAllFields: boolean;
  public wantHiddenFields: boolean;

  public areAccountsVisible: any;
  public areFieldsVisible: any;

  // this data supports presentation of data on the screen
  public currentSubcategories: any = [];
  public currentAccounts: any = {};
  public currentFields: any = {};

  constructor(public dataModelService: DataModelService, public category: any, private messages: string[]) {
    this.currentSubcategories = this.getSubcategories(this.category);
    this.currentAccounts = this.getAccounts(this.category);
    this.currentFields = this.getFields(this.category);

    const initialStatus = false; // default initial status of visibility

    this.showAllAccounts = initialStatus;
    this.showAllFields = initialStatus;
    this.wantHiddenFields = false;

    this.areAccountsVisible = this.createAreAccountsVisible(initialStatus);
    this.areFieldsVisible = this.createAreFieldsVisible(initialStatus);
  } // constructor

  public isNumber(val): boolean {
    // @TODO this could be problematic...
    // however, it appears that when a text input is set to empty, the value is '' (i.e. not null)
    // checkboxes are always either true/false, and never null
    return typeof val === 'number' || val === null; // when a number field has string data it is converted to null
  }

  // @TODO there may be a better way to distinguish field type
  public isString(val, attr): boolean {
    return (
      typeof val === 'string' && attr !== 'budgetSubcategory' && attr !== 'incomeSubcategory' && attr !== 'maturityDate' && attr !== 'expirationDate'
    );
  }

  // @TODO there may be a better way to distinguish field type
  public isSelect(attr): boolean {
    return (attr as string) === 'budgetSubcategory' || (attr as string) === 'incomeSubcategory';
  }

  // @TODO there may be a better way to distinguish field type
  public isDate(attr): boolean {
    return (attr as string) === 'maturityDate' || (attr as string) === 'expirationDate';
  }

  public isBoolean(val): boolean {
    return typeof val === 'boolean';
  }

  public isCurrency(key): boolean {
    return (
      key === 'monthlyAmount' ||
      key === 'monthlyMinimum' ||
      key === 'creditLimit' ||
      key === 'accountLimit' ||
      key === 'accountValue' ||
      key === 'employerContribution' ||
      key === 'coverageAmount' ||
      key === 'monthlyIncome'
    );
  }

  public isRate(key): boolean {
    return key === 'interestRate';
  }

  public isHiddenField(key): boolean {
    return (
      key !== 'accountType' &&
      // && key !== 'accountName'
      key !== 'isRequired' &&
      key !== 'isEditable' &&
      key !== 'incomeSubcategory' &&
      key !== 'budgetSubcategory' &&
      key !== 'isNonProductiveDebt' &&
      key !== 'isNonProductiveAsset' &&
      key !== 'tooltip' &&
      key !== 'shadowType' &&
      key !== 'modal' &&
      key !== 'k' &&
      key !== 'subcat'
    );
  }

  public getActndx(category, subcat, accountName): number {
    let actndx = -1;
    if (category.hasOwnProperty(subcat)) {
      actndx = category[subcat].accounts.length;
      while (--actndx >= 0 && category[subcat].accounts[actndx].accountName.val !== accountName) {}
    }
    return actndx;
  } // getActndx

  public getSubcategories(category: any): any {
    const result = [];
    for (const subcat of Object.keys(category)) {
      if (skipSubcatAttributes.indexOf(subcat) === -1) {
        result.push({ subcat, label: category[subcat].label });
      }
    }
    return result;
  } // getSubcategories

  public getCategoryInfoSubcatList(category: any): any {
    const result = [];
    for (const subcat of Object.keys(categoryInfo[category.attributeName])) {
      if (skipSubcatAttributes.indexOf(subcat) === -1) {
        result.push({
          subcat,
          label: categoryInfo[category.attributeName][subcat].label
        });
      }
    }
    return result;
  } // getCategoryInfoSubcatList

  public getAccounts(category: any): any {
    const result = {};
    for (const subcat of Object.keys(category)) {
      if (skipSubcatAttributes.indexOf(subcat) === -1) {
        result[subcat] = [];
        for (const account of category[subcat].accounts) {
          if (account && account.accountName) {
            result[subcat].push(account.accountName.val);
          }
        }
      }
    }
    return result;
  } // getAccounts

  public getCategoryInfoAccountsList(category, subcat) {
    const result = [];
    for (const sc of Object.keys(categoryInfo[category.attributeName])) {
      if (skipSubcatAttributes.indexOf(sc) === -1) {
        if (sc === subcat && categoryInfo[category.attributeName][sc].hasOwnProperty('accountNames')) {
          result.push(categoryInfo[category.attributeName][sc].accountNames);
        }
      }
    }
    return result;
  } // getCategoryInfoAccountsList

  /*
    // Replace this version of getFields with the one shown below in order to control the order in which the properties of an account object are displayed.
    getFields(category:any): any
    // This routine returns an object that contains for each subcat an array containing a list of objects that contain field attributes and labels.
    {
        let result = {};
        for (let subcat of Object.keys(this.category))
        {
            if (skipSubcatAttributes.indexOf(subcat) === -1)
            {
                result[subcat] = [];
                for (let actndx = 0; actndx < category[subcat].accounts.length; actndx++)
                {
                    result[subcat].push([]);
                    for (let field of Object.keys(category[subcat].accounts[actndx]))
                    {
                        if (this.wantHiddenFields || (field !== 'accountName' && field !== 'accountType' && field !== 'isRequired' && field !== 'isEditable'&&
                        field !== 'tooltip')) result[subcat][actndx].push({field:field, label:category[subcat].accounts[actndx][field].label});
                    }
                }
            }
        }
        return result;
    }   // getFields
    */

  public getFields(category: any): any {
    function getOrderedPropertyList(account) {
      let haveMonthlyMinimum = false;
      let haveMonthlyAmount = false;
      const propertyList = [];
      if (account) {
        const list = Object.keys(account);
        for (const property of list) {
          if (property === 'monthlyMinimum') {
            haveMonthlyMinimum = true;
          } else if (property === 'monthlyAmount') {
            haveMonthlyAmount = true;
          } else {
            propertyList.push(property);
          }
        }
      }
      if (haveMonthlyMinimum) {
        propertyList.push('monthlyMinimum');
      }
      if (haveMonthlyAmount) {
        propertyList.push('monthlyAmount');
      }
      return propertyList;
    } // getOrderedPropertyList

    const result = {};
    for (const subcat of Object.keys(this.category)) {
      if (skipSubcatAttributes.indexOf(subcat) === -1) {
        result[subcat] = [];
        for (let actndx = 0; actndx < category[subcat].accounts.length; actndx++) {
          result[subcat].push([]);
          for (const field of getOrderedPropertyList(category[subcat].accounts[actndx])) {
            if (
              this.wantHiddenFields ||
              (field !== 'accountName' && field !== 'accountType' && field !== 'isRequired' && field !== 'isEditable' && field !== 'tooltip')
            ) {
              result[subcat][actndx].push({
                field,
                label: category[subcat].accounts[actndx][field].label
              });
            }
          }
        }
      }
    }
    return result;
  } // getFields

  public getAccountsList(category: any, subcat: string): string[] {
    let result = [];
    const accountsInfo = this.getAccounts(category);
    if (accountsInfo.hasOwnProperty(subcat)) {
      result = accountsInfo[subcat];
    }
    return result;
  } // getAccountsList

  public getFieldsList(category: any, subcat: string, accountName: string): any {
    let result = [];
    const actndx = this.getActndx(category, subcat, accountName);
    if (actndx !== -1) {
      const fieldsInfo = this.getFields(category);
      if (fieldsInfo.hasOwnProperty(subcat) && 0 <= actndx && actndx < fieldsInfo[subcat].length) {
        result = fieldsInfo[subcat][actndx];
      }
    }
    return result;
  } // getFieldsList

  public getPossibleFieldList(): any {
    const result = [];
    for (const field of Object.keys(possibleFieldNames)) {
      result.push({ field, label: possibleFieldNames[field].label });
    }
    return result;
  } // getCategoryInfoFieldList

  public updateFieldsVisibility(): void {
    for (const subcat of Object.keys(this.areFieldsVisible)) {
      for (let i = 0; i < this.areFieldsVisible[subcat].length; i++) {
        this.areFieldsVisible[subcat][i] = this.showAllFields;
      }
    }
  } // updateFieldsVisibility

  public createAreAccountsVisible(status: boolean): any {
    const result = {};
    for (const subcat of Object.keys(this.category)) {
      if (skipSubcatAttributes.indexOf(subcat) === -1) {
        result[subcat] = status;
      }
    }
    return result;
  } // createAreAccountsVisible

  public updateAccountsVisibility() {
    for (const subcat of Object.keys(this.areAccountsVisible)) {
      this.areAccountsVisible[subcat] = this.showAllAccounts;
    }
  } // updateAccountsVisibility

  public toggleAccountVisibility(subcat: string): void {
    // collapse all other subcats
    for (const k of Object.keys(this.areAccountsVisible)) {
      if (k === subcat) {
        continue;
      } // don't toggle the clicked subcat, we will do this later
      this.areAccountsVisible[k] = false;
    }
    // show clicked subcat
    this.areAccountsVisible[subcat] = !this.areAccountsVisible[subcat];
  } // toggleAccountVisibility

  public isAccountVisible(subcat: string): boolean {
    return this.areAccountsVisible[subcat];
  } // isAccountVisible

  public createAreFieldsVisible(status: boolean): any {
    const result = {};
    for (const subcat of Object.keys(this.category)) {
      if (skipSubcatAttributes.indexOf(subcat) === -1) {
        result[subcat] = [];
        for (const account of this.category[subcat].accounts) {
          result[subcat].push(status);
        }
      }
    }
    return result;
  } // createAreFieldsVisible

  public toggleFieldVisibility(subcat: string, actndx: number): void {
    // collapse all other accounts
    for (const k of Object.keys(this.areFieldsVisible[subcat])) {
      if (subcat + k === subcat + actndx) {
        continue;
      } // don't toggle the clicked account, we will do this later
      this.areFieldsVisible[subcat][k] = false;
    }
    // show clicked account's fields
    this.areFieldsVisible[subcat][actndx] = !this.areFieldsVisible[subcat][actndx];
  } // toggleFieldVisibility

  public isFieldVisible(subcat: string, actndx: number): boolean {
    return this.areFieldsVisible[subcat][actndx];
  } // isFieldVisible

  public updateHiddenFieldsVisibility(): void {
    this.currentFields = this.getFields(this.category);
  } // updateHiddenFieldsVisibility

  public dataType(val: any): any {
    return typeof val === 'number' || (typeof val === 'string' && !isNaN(+val)) ? 'number' : 'string';
  } // dataType

  public getSubcategorySum(subcat: string, field: string): number {
    let sum, val: number;
    let monthlyAmount, monthlyMinimum, fieldVal: number;
    let haveMonthlyAmount: boolean;

    // add in data from this category
    sum = 0;
    if (typeof this.category[subcat] === 'undefined') {
      // console.log('undefined', subcat);
      return 0;
    }
    for (const account of this.category[subcat].accounts) {
      // gather values necessary to do work
      monthlyAmount = 0;
      haveMonthlyAmount = false;
      if (account.hasOwnProperty('monthlyAmount')) {
        monthlyAmount = account.monthlyAmount.val;
        haveMonthlyAmount = true;
      }
      monthlyMinimum = 0;
      if (account.hasOwnProperty('monthlyMinimum')) {
        monthlyMinimum = account.monthlyMinimum.val;
      }
      fieldVal = 0;
      if (account.hasOwnProperty(field)) {
        fieldVal = account[field].val;
      }

      // process value as appropriate
      val = field === 'monthlyAmount' ? (haveMonthlyAmount ? monthlyAmount : monthlyMinimum) : fieldVal;

      if (typeof val === 'number') {
        sum += val;
      } else if (typeof val === 'string' && !isNaN(+val)) {
        sum += Number(val);
      }
    } // for actndx
    return sum;
  } // getSubcategorySum

  public getCategorySum(field: string): number {
    let sum, val: number;
    let monthlyAmount, monthlyMinimum, fieldVal: number;
    let haveMonthlyAmount: boolean;

    sum = 0;
    for (const subcat of Object.keys(this.category)) {
      if (skipSubcatAttributes.indexOf(subcat) === -1) {
        for (const account of this.category[subcat].accounts) {
          // gather values necessary to do work
          monthlyAmount = 0;
          haveMonthlyAmount = false;
          if (account.hasOwnProperty('monthlyAmount')) {
            monthlyAmount = account.monthlyAmount.val;
            haveMonthlyAmount = true;
          }
          monthlyMinimum = 0;
          if (account.hasOwnProperty('monthlyMinimum')) {
            monthlyMinimum = account.monthlyMinimum.val;
          }
          fieldVal = 0;
          if (account.hasOwnProperty(field)) {
            fieldVal = account[field].val;
          }

          // process value as appropriate
          val = field === 'monthlyAmount' ? (haveMonthlyAmount ? monthlyAmount : monthlyMinimum) : fieldVal;
          if (typeof val === 'number') {
            sum += val;
          } else if (typeof val === 'string' && !isNaN(+val)) {
            sum += Number(val);
          }
        } // foractndx
      } // if
    } // for subcat

    return sum;
  } // getCategorySum

  public verifyAllDataValues(): IVerifyResult {
    // initialize
    const result: IVerifyResult = { hadError: false, messages: [] };

    // is integer
    // for (let subcat of Object.keys(this.category))
    // {
    //     if (skipSubcatAttributes.indexOf(subcat) === -1)
    //     {
    //         for (let actndx = 0; actndx < this.category[subcat].accounts.length; actndx++)
    //         {
    //             if (typeof this.category[subcat].accounts[actndx] === 'object' && this.category[subcat].accounts[actndx].hasOwnProperty('monthlyAmount'))
    //             {
    //                 CValidityCheck.checkInteger4(this.category,subcat,actndx,'monthlyAmount',result);
    //             }
    //         }
    //     }
    // }

    // here is code to validate account fields
    for (const subcat of Object.keys(this.category)) {
      if (skipSubcatAttributes.indexOf(subcat) === -1) {
        const accountNameList = ItemManagement.getSubcategoryAccountList(this.category, subcat);
        let i = accountNameList.length;
        while (--i >= 0 && !result.hadError) {
          // here is code to catch duplicate account names
          const accountName = accountNameList[i];
          if (ItemManagement.occuranceCount(accountName, accountNameList) > 1) {
            result.hadError = true;
            result.messages.push('In subcategory ' + this.category[subcat].label + ' have duplicate account name: ' + accountName);
          }

          // here is code to catch blank or null required fields
          for (const field of Object.keys(this.category[subcat].accounts[i])) {
            if (field === 'accountName' || field === 'accountType' || field === 'isRequired' || field === 'isEditable' || field === 'tooltip') {
              continue;
            }

            if (
              this.category[subcat].accounts[i][field].hasOwnProperty('isRequired') &&
              this.category[subcat].accounts[i][field].isRequired === true &&
              this.category[subcat].accounts[i][field].hasOwnProperty('val') &&
              (this.category[subcat].accounts[i][field].val === '' || this.category[subcat].accounts[i][field].val === null)
            ) {
              result.hadError = true;
              result.messages.push('In ' + accountName + ', ' + this.category[subcat].accounts[i][field].label + ' must not be blank.');
            }
          }

          // if monthly amount is less than monthly minimum
          if (
            this.category[subcat].accounts[i].hasOwnProperty('monthlyMinimum') &&
            this.category[subcat].accounts[i].hasOwnProperty('monthlyAmount') &&
            this.category[subcat].accounts[i].monthlyAmount.val < this.category[subcat].accounts[i].monthlyMinimum.val
          ) {
            result.hadError = true;
            result.messages.push(accountName + ' monthly amount you pay cannot be less than the monthly minimum.');
          }
          // if monthly amount exceeds balance due
          if (
            this.category.attributeName === 'liabilities' &&
            this.category[subcat].accounts[i].hasOwnProperty('monthlyAmount') &&
            this.category[subcat].accounts[i].hasOwnProperty('accountValue') &&
            this.category[subcat].accounts[i].monthlyAmount.val > this.category[subcat].accounts[i].accountValue.val
          ) {
            result.hadError = true;
            result.messages.push(accountName + ' monthly amount exceeds the balance due.');
          }
        }
      }
    }

    return result;
  } // verifyAllDataValues
} // class GenericDataManagement
