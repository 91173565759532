import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { SetupFlowDataManagementService, SetupFlowScreen } from '../../../services/setup/setup-flow-data-management.service';
import { SetupService } from '@app/services/setup.service';

@Component({
  selector: 'app-setup-review',
  templateUrl: './setup-review.component.html',
  styleUrls: ['./setup-review.component.scss']
})
export class SetupReviewComponent implements SetupFlowScreen, OnInit {
  public income;
  public incomeAccounts;
  public categoryKeys: string[];
  public budget;
  protected transactionsYearMonth: string;
  protected origTransactionsYearMonth = moment(new Date()).subtract(1, 'month').toISOString().substr(0, 7);

  constructor(
    protected dataManagement: SetupFlowDataManagementService,
    protected dataModelService: DataModelService,
    protected router: Router,
    private setupService: SetupService
  ) {
    this.dataManagement.currentSetupScreen = this;
  }

  public canSave() {
    return true;
  }

  public onSave() {}

  public showOnSaveErrorMessage(): void {}

  public async ngOnInit() {
    console.error(this.getInstitutions());
    this.setTransactionsYearMonth();
    this.income = this.dataModelService.getdata('income');
    this.setupService.updateCurrentSetupStep(5);
    this.incomeAccounts = this.income.income.accounts;
    await this.loadCategories();
  }
  public async loadCategories() {
    this.budget = await this.dataModelService.getdata('budget');
    this.categoryKeys = await this.getBudgetCategories();
  }
  private getBudgetCategories(): string[] {
    return Object.keys(this.budget).filter(key => key !== 'attributeName' && key !== 'label');
  }

  private setTransactionsYearMonth() {
    this.transactionsYearMonth = this.origTransactionsYearMonth;
  }

  protected getInstitutions() {
    return this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions;
  }

  protected redirect(location) {
    this.router.navigate([location]);
  }
}
