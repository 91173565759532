import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

const PLUS_ICON_SRC = '../../../assets/plus_button_blue.svg';
const PLUS_ICON_LG_SRC = '../../../assets/plus_button_blue_large.svg';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input() public block?: boolean;
  @Input() public disabled?: boolean;
  @Input() public dark?: boolean;
  @Input() public link?: string;
  @Input() public color?: string;
  @Input() public size?: string;
  @Input() public variant?: string;
  @Input() public iconSrc?: string;
  @Input() public destructive?: boolean;
  @Input() public isLoading: boolean;
  @Output() public onClick: EventEmitter<any> = new EventEmitter<any>();

  public defaultIconSrc: string;
  public plusIconSrc = PLUS_ICON_SRC;
  public plusIconLargeSrc = PLUS_ICON_LG_SRC;

  constructor(protected router: Router) {
    this.defaultIconSrc = this.iconSrc ? this.iconSrc : PLUS_ICON_SRC;
  }

  public ngOnInit() {
    if (this.variant === 'add' && (this.dark || this.disabled || this.block)) {
      throw new Error('Use of @Inputs dark, disabled, isLoading, and block are not allowed when using the `add` variant of a button');
    }
    this.setDefaultClasses();
  }

  public setDefaultClasses() {
    return {
      button: true,
      'button--cta': this.color === 'primary',
      'button--cta--destructive': this.destructive === true,
      'button--block': this.block === true,
      'button--disabled': this.disabled || this.isLoading,
      'button--dark-bg': this.dark,
      'button--loading': this.isLoading
    };
  }

  public setCategoryButtonClasses() {
    return {
      button: true,
      'category-button--active': !this.disabled,
      'button--disabled': this.disabled || this.isLoading,
      transparent: this.color === 'inherit',
      gray: this.color === 'gray',
      'muted-gray': this.color === 'muted-gray',
      red: this.color === 'red',
      'yellow-alt': this.color === 'yellow-alt',
      sm: true,
      'button--block-cat': this.block === true
    };
  }

  public setViewTransactionsButtonClasses() {
    return {
      button: true,
      'view-transactions-button': true,
      'button--disabled': this.disabled || this.isLoading
    };
  }

  public setAddContainerClasses() {
    return {
      'add-button-lg': this.size === 'lg',
      'add-button-sm': this.size !== 'lg'
    };
  }

  public _onClick() {
    if (!this.disabled && !this.isLoading) {
      this.onClick.emit();

      if (this.link) {
        this.router.navigate([this.link]);
      }
    }
  }
}
