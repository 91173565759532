import { Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { DataModelService } from '../../../services/data-model/data-model.service';
import {
  AccountTypeSelectorComponent,
  IAccountTypeSelectorData
} from '../../setup-flow/accounts/account-type-selector/account-type-selector.component';
import { BudgetCategoryComponent } from '../../setup-flow/budget-category/budget-category.component';

@Component({
  selector: 'app-budget-page-four-step-plan',
  templateUrl: './budget-page-four-step-plan.component.html',
  styleUrls: ['./budget-page-four-step-plan.component.scss']
})
export class BudgetPageFourStepPlanComponent extends BudgetCategoryComponent {
  @Output() public onValueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onAccountSaved: EventEmitter<any> = new EventEmitter<any>();
  @Output() public selectAccount: EventEmitter<any> = new EventEmitter<any>();
  @Output() public didToggleDropdown: EventEmitter<any> = new EventEmitter<any>();
  @Input() public displayOnly: boolean;
  @Input() public stepNumber: number;
  @Input() public stepLabels: string[];
  @Input() public cafrInfo: any;
  @Input() public showGuildinesCol: boolean;
  @Input() public wizeFiCategory2MonthlyAmount: any;
  public currentStepCafrInfo: any;
  public wizeFiAccounts: any[]; // holds accounts in the same form as the persistent data-model
  public cafrAccounts: any[]; // holds accounts with guideline and actual cafr values
  public guidelineSum = 0;
  public lastMonthSum = 0;
  public plannedSum = 0;
  public appliedSum = 0;

  private savedMonthlyAmount: number;

  constructor(protected dataModelService: DataModelService, protected router: Router, protected renderer: Renderer2, protected dialog: MatDialog) {
    super(dataModelService, router, renderer);
  }

  public ngOnInit() {
    this.currentStepCafrInfo = this.getCurrentStepInfo();
    this.setAccountsForCurrentStep();
    this.sortDebtAccountsInStep();
    this.guidelineSum = this.getGuidelineSum();
    this.lastMonthSum = this.getLastMonthSum();
    this.plannedSum = this.getPlannedSum();
    this.appliedSum = this.getAppliedSum();
    // this.logCafr();
  }

  public toggleDropdown(review = false, state = null) {
    super.toggleDropdown(review, state);
    if (state == null && this.isShowingDropdown) {
      this.didToggleDropdown.emit({ component: this });
    }
  }

  protected sortDebtAccountsInStep() {
    if (this.stepNumber === 2) {
      /* console.log('wizefi accounts', this.wizeFiAccounts);
      console.log('cafr accounts',this.cafrAccounts); */
      let accounts = this.wizeFiAccounts;
      accounts.sort((a: any, b: any): any => a.accountValue.val - a.monthlyAmount.val - (b.accountValue.val - b.monthlyAmount.val));
      console.log('sorted', accounts);
      return (accounts = this.wizeFiAccounts);
    }
  }

  protected isInEditMode() {
    return this.router.url.includes('plan/edit-CAFR');
  }

  private getCurrentStepInfo() {
    return this.cafrInfo[this.stepNumber];
  }

  private setAccountsForCurrentStep() {
    const cafrAccounts: any[] = [];
    const wizeFiAccounts: any[] = [];
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;

    // for each property in this step of cafrInfo
    _.forEach(this.currentStepCafrInfo, (categoryObject, categoryKey) => {
      // if property is an object, it will be a category object containing subcategories
      if (typeof categoryObject === 'object') {
        // for each property in this category
        _.forEach(categoryObject, (subcatObject, subcatKey) => {
          // if property is an object, it will be a subcategory object containing some accounts (which may be empty)
          if (typeof subcatObject === 'object') {
            // add cafr information containing guideline and actual values to the cafrAccounts array
            const accountNames = _.keys(subcatObject.accounts);
            // for each account name in this category, subcategory of the cafrInfo
            accountNames.forEach(name => {
              const accounts = this.dataModelService.dataModel.persistent.plans[curplan][categoryKey][subcatKey].accounts || [];
              const accountIndex = this.dataModelService.categoryManagement.accountName2acntndx(name, accounts);
              const wizeFiAccount = accounts[accountIndex];
              if (wizeFiAccount) {
                // add the wizeFi account to the wizeFiAccounts array
                if (wizeFiAccount.hasOwnProperty('monthlyAmount')) {
                  wizeFiAccounts.push(wizeFiAccount);
                  cafrAccounts.push(subcatObject.accounts[name]);
                }
              }
            });
          }
        });
      }
    });
    this.cafrAccounts = cafrAccounts;
    this.wizeFiAccounts = wizeFiAccounts;
  }

  public onTextfieldChange(value: number, account) {
    this.isBasedOnExtraYouPayValue(account) ? this.handleExtraYouPayChange(+value, account) : this.handleMonthlyAmountChange(+value, account);
    this.plannedSum = this.getPlannedSum();
    this.onValueChange.emit();
  }

  private handleExtraYouPayChange(extraYouPay: number, account) {
    account.monthlyAmount.val = account.monthlyMinimum.val + extraYouPay || 0;
  }

  private handleMonthlyAmountChange(monthlyAmount: number, account) {
    account.monthlyAmount.val = monthlyAmount || 0;
  }

  protected handleMonthlyAmountErrors(value, account) {
    if (this.isBasedOnExtraYouPayValue(account)) {
      return;
    }
    if (!account.monthlyMinimum || value >= account.monthlyMinimum.val) {
      return;
    }
    this.showMonthlyAmountError();
    this.resetMonthlyAmount(account);
    this.getPlannedSum();
  }

  public showMonthlyAmountError(): void {
    this.dataModelService.showMessage('error', 'Monthly amount you pay cannot be less than the monthly minimum.', 3000);
  }

  protected saveMonthlyAmount(account) {
    this.savedMonthlyAmount = account.monthlyAmount.val;
  }

  private resetMonthlyAmount(account) {
    account.monthlyAmount.val = this.savedMonthlyAmount;
    this.textInputComponent.value = `${this.savedMonthlyAmount}`;
    this.savedMonthlyAmount = null;
  }

  // totals for step

  private getGuidelineSum(): number {
    if (this.stepNumber === 4) {
      return this.cafrInfo[4].guidelineCAFR;
    } else {
      return this.cafrAccounts.reduce((total, account) => (total += account.guidelineCAFR), 0);
    }
  }

  private getLastMonthSum(): number {
    return this.wizeFiAccounts.reduce((total, account) => (total += this.getLastMonthsMonthlyAmount(account)), 0);
  }

  private getPlannedSum(): number {
    return this.wizeFiAccounts.reduce((total, account) => {
      if (account.accountType.val !== 'permInsurance') {
        return (total += this.getPlannedSumPerCategory(account));
      } else {
        return total;
      }
    }, 0);
  }
  private getAppliedSum(): number {
    return this.wizeFiAccounts.reduce((total, account) => total + this.getAppliedAmount(account), 0);
  }

  // account specific values

  protected getAccountGuidelineCAFR(accountIndex): number {
    return this.cafrAccounts[accountIndex].guidelineCAFR;
  }

  protected getAccountActualSpending(accountIndex): number {
    return this.wizeFiAccounts[accountIndex].actualMonthlyAmount.val || 0;
  }

  protected getPlannedSumPerCategory(account) {
    return this.isBasedOnExtraYouPayValue(account) ? account.monthlyAmount.val - account.monthlyMinimum.val : account.monthlyAmount.val;
  }

  protected getLastMonthsMonthlyAmount(account) {
    const category = account.wizeFiCategory.val;
    let value = 0;
    for (const cat in this.wizeFiCategory2MonthlyAmount) {
      if (cat === category) {
        value = this.dataModelService.categoryManagement.getMonthlyAccount(this.wizeFiCategory2MonthlyAmount, category);
        if (this.isBasedOnExtraYouPayValue(account)) {
          value -= this.dataModelService.categoryManagement.getMonthlyMinimumAccount(this.wizeFiCategory2MonthlyAmount, category);
        }
      }
    }
    return value;
  }

  protected getMonthlyAmount(account) {
    return this.isBasedOnExtraYouPayValue(account) ? account.monthlyAmount.val - account.monthlyMinimum.val : account.monthlyAmount.val;
  }

  protected getAppliedAmount(account) {
    return -1 * account.actualMonthlyAmount.val;
  }

  private isBasedOnExtraYouPayValue(account): boolean {
    return account.budgetSubcategory && account.budgetSubcategory.val !== '';
  }

  protected showAccountTransactions(wizeFiAccount) {
    const wizeFiCategory = wizeFiAccount.wizeFiCategory.val;
    this.selectAccount.emit({ account: this.getPlaidAccount(wizeFiCategory), stepNumber: this.stepNumber });
  }

  private getPlaidAccount(wizeFiCategory) {
    const categoryManagement = this.dataModelService.categoryManagement;
    const plaidAccountsDict = categoryManagement.getWizeFiCategory2wizeFiPlaidAccount();
    return plaidAccountsDict[wizeFiCategory];
  }

  protected isDuplicateAccountName() {
    if (this.wizeFiAccounts && this.accountBeingEdited && this.accountBeingEdited.accountName) {
      return this.wizeFiAccounts.filter(account => account.accountName.val === this.accountBeingEdited.accountName.val).length > 1;
    }
  }

  private logCafr() {
    console.log('cafrInfo:', this.cafrInfo);
  }

  protected getAccountName(wizeFiAccount) {
    const wizeFiCategory = wizeFiAccount.wizeFiCategory.val;
    const plaidAccount = this.getPlaidAccount(wizeFiCategory);
    return plaidAccount ? plaidAccount.accountName : wizeFiAccount.accountName.val;
  }
}
