import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { DataModelService } from 'src/app/services/data-model/data-model.service';

@Component({
  selector: 'app-budget-page-income',
  templateUrl: './budget-page-income.component.html',
  styleUrls: ['./budget-page-income.component.scss']
})
export class BudgetPageIncomeComponent implements OnInit {
  public income;
  public incomeAccounts;

  constructor(public router: Router, protected dataModelService: DataModelService, private dialog: MatDialog) {}

  public ngOnInit() {
    this.income = this.dataModelService.getdata('income');
    this.incomeAccounts = this.income.income.accounts;
  }
}
