import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataModelService } from 'src/app/services/data-model/data-model.service';
import { UrlManagement } from '../../utilities/url-management.class';

@Component({
  selector: 'app-review-projections-submenu',
  templateUrl: './review-projections-submenu.component.html',
  styleUrls: ['./review-projections-submenu.component.scss']
})
export class ReviewProjectionsSubmenuComponent {
  public url = new UrlManagement();

  constructor(public dataModelService: DataModelService, public router: Router) {}

  public async promoteDraftAndExit() {
    await this.dataModelService.draftService.promoteDraft();
    this.router.navigate(['/plan'], { state: { showModal: true } });
  }

  public async storeDataAndExit() {
    const isEditingCurrentPlan = !this.dataModelService.draftService.isEditingDrafts();

    if (isEditingCurrentPlan) {
      // If editing current plan we're actually editing a temporary draft. So when saving it, instead we promote this draft.
      await this.dataModelService.draftService.promoteDraft();
    } else {
      await this.dataModelService.dataManagement.storeinfo();
    }

    await this.dataModelService.dataManagement.fetchdata();

    this.router.navigate(['/plan'], { state: { showModal: isEditingCurrentPlan } });
  }

  public async onCancelChanges() {
    await this.dataModelService.dataManagement.fetchdata();

    if (this.router.url === '/plan/edit-CAFR#accounts') {
      return this.router.navigate(['/accounts'], { fragment: 'all' });
    }
    if (this.router.url === '/plan/edit-CAFR#profile') {
      return this.router.navigate(['/settings']);
    }
    if (this.router.url === '/plan/edit-CAFR#income') {
      return this.router.navigate(['/plan'], { fragment: 'income' });
    }
    if (this.router.url === '/plan/edit-CAFR#expenses') {
      return this.router.navigate(['/plan'], { fragment: 'expenses' });
    }
    if (this.router.url === '/plan/edit-CAFR#four-step-plan') {
      return this.router.navigate(['/plan'], { fragment: 'plan' });
    }
    this.router.navigate(['/plan']);
  }
}
