import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
  @Input() public size: string;
  @Input() public color: string;

  public privateSize = '200px';
  public privateColor = '#9b59B6';

  constructor() {}

  public ngOnInit() {
    this.privateSize = this.size ? this.size : this.privateSize;

    if (this.color === 'primary') {
      this.privateColor = '#327ACE';
    } else if (this.color === 'white') {
      this.privateColor = '#FFFFFF';
    }
  }
}
