import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { SetupFlowDataManagementService } from 'src/app/services/setup/setup-flow-data-management.service';
import fileUploadService from 'src/app/services/fileupload.service';
import { DataModelService } from '../../services/data-model/data-model.service';
import { WizefiTransaction } from '../../interfaces/wizefi-transaction';
import { ProfileService } from './../../services/profile/profile.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  protected profileService: ProfileService;
  protected profile = false;
  protected loading = false;
  protected name = '';
  public selectedMonth = new Date();
  protected UncategorizedTransactions = 0;
  protected expandDetails = false;
  protected ffpercentage;
  protected ffgoal;
  protected ffactual;
  protected fforiginal;
  protected accountsArray;
  protected totalTransactionCount;
  protected uncategorizedCount;
  protected categorizedTransactionCount;
  protected connectedccountsCount;
  protected totalAccountsCount;
  protected connectedAccountsCount;
  protected productiveAssetsTotal;
  protected productiveAssetsPercentage;
  protected CurrentemergencySavingsTotal;
  protected worthDifference;
  protected worthDifferenceAge;
  protected verifiedSpendingSum;
  protected plannedSpendingSum;
  protected ffMonthPercent;
  protected wizeFiPicture: any;

  protected currentDebt;
  protected debtPercentage;

  public static readonly MAXIMUM_AGE = 999;
  public readonly MAXIMUM_AGE = DashboardComponent.MAXIMUM_AGE;

  constructor(
    protected dataModelService: DataModelService,
    protected router: Router,
    protected dataManagement: SetupFlowDataManagementService,
    protected dialog: MatDialog,
    protected profilePictureService: fileUploadService
  ) {
    this.profileService = new ProfileService(dataModelService);
    this.setWealthDifference();
  }

  public async ngOnInit() {
    this.wizeFiPicture = this.profilePictureService.setWizeFiUserPictureURL();
    if (!this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts) {
      await this.dataModelService.plaidManagement.getPlaidData();
    }
    this.accountsArray = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    this.profile = this.dataModelService.dataModel.persistent.profile.nameFirst !== '';
    this.name = this.dataModelService.dataModel.persistent.profile.nameFirst || '';
    this.getAccounts();
    this.getTransactions();
    this.getCurrentProductiveAssetsBalance();
    this.getFFPercentage();
    this.getPercentages();
    this.setPlannedSpendingSum();
    this.setVerifiedSpendingSum();
    this.setFFMonthPercent();

    return 0;
  }

  public async imageInputChange(fileInputEvent: any) {
    await this.profilePictureService.uploadPicture(fileInputEvent).then(() => {
      this.wizeFiPicture = this.profilePictureService.setWizeFiUserPictureURL();
    });
    // this.dataModelService.dataModel.persistent.profile.picture = fileInputEvent.target.files[0];
    // this.dataModelService.putdata("profile", this.dataModelService.dataModel.persistent.profile);
  }

  public imageInputHover(fileHoverEvent: any, value) {}

  getProjectionDataForGraphs() {
    return this.dataModelService.cafrManagement.getCafrDataProjectionInfo(
      {
        terminationType: 'financialFreedomTargetAmount',
        terminationValue: this.ffgoal
      },
      'actual'
    );
  }

  getProjectionDataForGraphsOriginal() {
    return this.dataModelService.cafrManagement.getCafrDataProjectionInfo(
      {
        terminationType: 'financialFreedomTargetAmount',
        terminationValue: this.ffgoal
      },
      'actual',
      'original'
    );
  }

  protected getTransactions() {
    this.totalTransactionCount = this.getSelectedMonthTransactions().length;
    this.categorizedTransactionCount = this.getCategorizedTransactionsCount();
    this.uncategorizedCount = this.getSelectedMonthTransactions().filter(transaction => transaction.wizeFiCategory.indexOf('unknown') !== -1).length;
  }

  protected getAccounts() {
    this.totalAccountsCount = this.accountsArray.filter(account => account.status === '1' || account.status === '0' || account.status === 0).length;
    this.connectedAccountsCount = this.accountsArray.filter(account => account.status === '1').length;
  }

  private getCategorizedTransactionsCount() {
    const transactions = this.getSelectedMonthTransactions();
    return transactions.filter(transaction => transaction.wizeFiCategory.indexOf('unknown') === -1 && transaction.splitStatus !== 1).length;
  }

  private getSelectedMonthTransactions(): WizefiTransaction[] {
    return this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection[this.formatDateToTransactionsMapKey()];
  }

  private formatDateToTransactionsMapKey(): string {
    return this.selectedMonth.toISOString().substr(0, 7);
  }

  protected onExpandDetails() {
    this.expandDetails = !this.expandDetails;
  }

  protected getPercentages() {
    this.getDebtPercentage();
    this.getProductiveAssetsPercentage();
  }

  protected getFFPercentage() {
    const ffgraph = document.getElementById('ffbar');
    this.ffgoal = this.dataModelService.dataModel.persistent.profile.financialFreedomTargetAmount;

    const originalDataProjInfo = this.getProjectionDataForGraphsOriginal();
    this.fforiginal = originalDataProjInfo.netWorth.summary.projection[0].y;

    const actualDataProjInfo = this.getProjectionDataForGraphs();
    this.ffactual = actualDataProjInfo.netWorth.summary.projection[0].y;

    this.worthDifference = this.ffactual - this.fforiginal;

    this.ffpercentage = ((this.ffactual / this.ffgoal) * 100).toFixed(2);
    ffgraph.style.width = this.ffpercentage + '%';
    if (this.ffpercentage >= 100) {
      ffgraph.classList.add('full-bar');
    }
  }

  protected setVerifiedSpendingSum() {
    this.verifiedSpendingSum = this.plannedSpendingSum - this.profileService.getRemainingSpendingSum();
  }

  protected setPlannedSpendingSum() {
    this.plannedSpendingSum = this.profileService.getPlannedSpendingSum();
  }

  protected setFFMonthPercent() {
    this.ffMonthPercent = (this.verifiedSpendingSum / this.plannedSpendingSum) * 100;
  }

  protected getDebtPercentage() {
    const dTgraph = document.getElementById('dtbar');

    const originalDataProjInfo = this.getProjectionDataForGraphsOriginal();
    const allDebt = originalDataProjInfo.allDebt.summary.projection[0].y;

    const actualDataProjInfo = this.getProjectionDataForGraphs();
    this.currentDebt = actualDataProjInfo.allDebt.summary.projection[0].y;

    if (this.currentDebt !== 0) {
      this.debtPercentage = 1 - (this.currentDebt / allDebt) * 100;
    } else if (allDebt === 0 && this.currentDebt === 0) {
      this.debtPercentage = 100.0;
    } else {
      this.debtPercentage = 0;
    }
    dTgraph.style.width = this.debtPercentage + '%';
    if (this.debtPercentage >= 100) {
      dTgraph.classList.add('full-bar');
    }

    dTgraph.style.width = this.debtPercentage + '%';
    if (this.debtPercentage >= 100) {
      dTgraph.classList.add('full-bar');
    }
  }

  protected getCurrentProductiveAssetsBalance() {
    const actualDataProjInfo = this.getProjectionDataForGraphs();
    this.productiveAssetsTotal = actualDataProjInfo.productiveAssets.summary.projection[0].y;
  }

  protected getProductiveAssetsPercentage() {
    this.productiveAssetsPercentage = ((this.productiveAssetsTotal / this.ffgoal) * 100).toFixed(2);

    const graph = document.getElementById('nvbar');
    graph.style.width = this.productiveAssetsPercentage + '%';
    if (this.productiveAssetsPercentage >= 100) {
      graph.classList.add('full-bar');
    }
  }

  protected setWealthDifference() {
    this.worthDifferenceAge = this.profileService.getWorthDifferenceAge();
  }

  protected getYears(age: number, abs: boolean = false): number | string {
    let value = 0;
    if (age === this.MAXIMUM_AGE) {
      return this.MAXIMUM_AGE;
    } else if (isNaN(age)) {
      return 0;
    } else {
      value = Math.floor(age);
      if (this.getMonths(age, false, true) === 12) {
        value += 1;
      }
      return abs ? Math.round(Math.abs(value)) : Math.round(value);
    }
  }

  protected getMonths(age: number, abs: boolean = false, skipCheck = false): number | string {
    let value;

    if (isNaN(age)) {
      return 0;
    } else {
      value = Math.round((age % 1) * 12);
      if (!skipCheck && value === 12) {
        return 0;
      }
    }
    return abs ? Math.abs(value) : value;
  }
}
