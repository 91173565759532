import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { PlaidAccount } from '@app/interfaces/plaid-account';
import { PlaidInstitution } from '@app/interfaces/plaid-institution';

@Component({
  selector: 'app-select-accounts',
  templateUrl: './select-accounts.component.html',
  styleUrls: ['./select-accounts.component.scss']
})
export class SelectAccountsComponent implements OnInit {
  @Input() public institution: PlaidInstitution;
  @Input() public accounts?: PlaidAccount[];
  @Output() public onConfirm: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onCancel: EventEmitter<any> = new EventEmitter<any>();
  public localAccounts: PlaidAccount[] = [];

  constructor(protected dataModelService: DataModelService) {}

  public ngOnInit() {
    this.accounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    this.localAccounts = this.accounts.filter(acct => acct.institution_id === this.institution.institution_id);
  }

  public setAccountActiveClasses(account) {
    return {
      inactive: account.status === 2 || account.status === '2'
    };
  }

  public onSelectAccount(account) {
    if (account.status !== '2') {
      account.status = '2'; // not tracked
      account.isActive = false;
    } else {
      // if wizeFiCategory not set, require activation
      if (account.wizeFiCategory === 'none') {
        account.status = '0';
        account.isActive = false;
      } else {
        account.status = '1';
        account.isActive = true;
      }
    }
  }

  public _onCancel() {
    this.onCancel.emit();
  }

  public async _onSave() {
    const allAccounts = await this.mergeNewAccountsIntoAllAccounts();
    await this.storePlaidAccounts(allAccounts);
    this.onConfirm.emit();
  }

  private async mergeNewAccountsIntoAllAccounts() {
    const globalAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    const accountBelongsToOtherInstitution = account => account.item_id !== this.institution.item_id;

    const accountsAfterRemovingDeletedAccounts = globalAccounts.filter(
      globalAccount => accountBelongsToOtherInstitution(globalAccount) || this.belongsToCurrentInstitution(globalAccount) // has not been deleted from local accounts
    );

    return accountsAfterRemovingDeletedAccounts.map(globalAccount => {
      const account = this.getUpdatedPlaidAccount(globalAccount);
      return this.belongsToCurrentInstitution(globalAccount) ? account : globalAccount;
    });
  }

  private belongsToCurrentInstitution(globalAccount) {
    return this.localAccounts.find(localAccount => globalAccount.account_id === localAccount.account_id);
  }

  private getUpdatedPlaidAccount(globalAccount) {
    const account = this.localAccounts.find(localAccount => globalAccount.account_id === localAccount.account_id) || globalAccount;

    if (status === '1' && account.wizeFiCategory === 'none') {
      account.status = '0';
    }

    if (account.wizeFiCategory === 'none') {
      const accountLinkInfo = {
        wizeFiPlaidAccount: globalAccount,
        accountStatus: account.status,
        accountIsActive: account.isActive,
        category: 'none', // from this screen, an account should never be linked to a wizeFiAccount
        subcategory: 'none',
        accountName: 'none',
        wizeFiCategory: account.wizeFiCategory
      };

      // TODO determine why result of this function is not used
      // TODO determine why is this invoked in the first place (note comment above about "never be linked to a wizeFiAccount")
      // Removed for now but still needs investigation for why present
      // await this.dataModelService.categoryManagement.processPlaidAccountLink(accountLinkInfo);
    }

    // TODO determine whether account has been properly changed
    return account;
  }

  // rewrite of this function below by Dave
  /*
  private async storePlaidAccounts(allAccounts) {
    this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts = allAccounts;
    if (!this.dataModelService.dataManagement.isDraft()) {
      this.dataModelService.plaidManagement.storeWizeFiPlaidAccounts(this.dataModelService.dataModel.global.wizeFiID, allAccounts)
        .then(() => this.onConfirm.emit(this.localAccounts))
        .catch(err => console.error("Error storing WizeFi accounts", err));
    }
    else {
      await this.dataModelService.dataManagement.storeinfo()
      this.onConfirm.emit(this.localAccounts)
    }
  }
  */

  private async storePlaidAccounts(allAccounts) {
    // store plaidAccounts data in DynamoDB
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    const title = this.dataModelService.dataManagement.getDraftTitle();
    await this.dataModelService.plaidManagement.storePlaidAccounts(wizeFiID, title, allAccounts);

    // update value of global wizeFiPlaidAccounts
    this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts = allAccounts;

    // store "user data" or "draft data"
    await this.dataModelService.dataManagement.storeinfo();

    // handle onConfirm
    this.onConfirm.emit(this.localAccounts);
  }

  protected formatAccString(name: string, mask: string) {
    return name.length >= 20 ? `${name.substr(0, 20)}...${mask}` : `${name} ${mask}`;
  }

  protected getAccountName(plaidAccount) {
    const categoryManagement = this.dataModelService.categoryManagement;
    return plaidAccount.wizeFiCategory && categoryManagement.isValidWizeFiCategoryAccount(plaidAccount.wizeFiCategory)
      ? categoryManagement.decodeWizeFiCategory(plaidAccount.wizeFiCategory).accountName
      : plaidAccount.accountName;
  }

  protected async removeInstitution(institution) {
    if (window.confirm('Do you really want to remove ' + institution.institutionName + ' and all its accounts?')) {
      const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
      const title = this.dataModelService.dataManagement.getDraftTitle();
      const itemId = institution.item_id;
      const itemIdCount = await this.dataModelService.dataManagement.getItemIdCount(wizeFiID, itemId);
      const result = await this.dataModelService.plaidManagement.deletePlaidInstitution(wizeFiID, title, itemId, itemIdCount);

      await this.dataModelService.plaidManagement.getPlaidData();

      // fix problem with behavior of getPlaidData  (fixed by Dave)
      this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions = result.wizeFiPlaidInstitutions;
      this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts = result.wizeFiPlaidAccounts;

      this.accounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;

      this._onSave();
    }
  }

  protected async loadUpdatePlaidLink() {
    const title = this.dataModelService.dataManagement.getDraftTitle();
    await this.dataModelService.plaidManagement.loadUpdatePlaidLink(title);
  }
}
