import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IAccount } from '../../../interfaces/iAccount.interface';
import { DataModelService } from '../../../services/data-model/data-model.service';

@Component({
  selector: 'app-monthly-income',
  templateUrl: './monthly-income.component.html',
  styleUrls: ['./monthly-income.component.scss']
})
export class MonthlyIncomeComponent implements OnInit {
  @Output() public onChange: EventEmitter<any> = new EventEmitter<any>();
  public income: any;
  public incomeAccounts: IAccount[];
  public netMonthlyIncome = 0;
  public originalIncome: number;
  public isLoading = false;
  public isUpdating = false;
  public isAddingAccount = false;

  constructor(protected dataModelService: DataModelService, protected router: Router) {}

  public ngOnInit() {
    this.income = this.dataModelService.getdata('income');
    this.incomeAccounts = this.income.income.accounts;
    console.log(this.incomeAccounts);

    this.removeNullAccounts();
    this.addMonthlySalaryAccountIfNeeded();
    this.updateNetMonthlyIncome();
  }

  public removeNullAccounts() {
    this.incomeAccounts = this.income.income.accounts;
    this.incomeAccounts.forEach(acct => {
      if (acct === null || acct === undefined) {
        this.removeIncomeSource(acct);
      }
    });
  }

  public onIncomeChange(value, account) {
    const currentAccount = this.incomeAccounts.find(acct => acct.accountID.val === account.accountID.val);
    currentAccount.monthlyAmount.val = parseFloat(value) || 0;
    this.updateNetMonthlyIncome();
  }

  public onNewAccountNameChange(value, account) {
    const currentAccount = this.incomeAccounts.find(acct => acct.accountID.val === account.accountID.val);
    currentAccount.accountName.val = value;
  }

  public onSave() {
    this.isUpdating = true;
    this.updateDataModel();
  }

  public removeIncomeSource(account) {
    console.log(account);
    this.incomeAccounts.forEach((item, index, object) => {
      if (item && item !== null && account !== null) {
        if (item.accountID.val === account.accountID.val) {
          this.isUpdating = true;
          console.log('account found...removing');
          this.incomeAccounts.splice(index, 1);
          this.updateDataModel();
        }
      } else {
        this.isUpdating = true;
        console.log('null account found...removing');
        this.incomeAccounts.splice(index, 1);
        this.updateDataModel();
      }
    });
  }

  public addIncomeSource() {
    this.isAddingAccount = true;
  }

  public finishAddingIncomeSource(accountName: string) {
    this.createNewAccount(accountName);
    this.closeModal();
  }

  public closeModal() {
    this.isAddingAccount = false;
  }

  // Private Functions

  private updateNetMonthlyIncome() {
    this.netMonthlyIncome = this.income.income.accounts.reduce(
      (total, account) => (total += account && account.monthlyAmount ? account.monthlyAmount.val : 0),
      0
    );
    this.onChange.emit(this.netMonthlyIncome);
  }

  private addMonthlySalaryAccountIfNeeded() {
    const isNeeded = false;
    if (this.incomeAccounts.length === 0) {
      this.incomeAccounts.push(this.createNewAccount('Monthly Salary'));
      this.updateDataModel();
    }
  }

  private updateDataModel() {
    this.income.income.accounts = this.incomeAccounts;
    this.dataModelService.putdata('income', this.income);
  }

  protected createNewAccount(accountName: string): any {
    const newAccountID = this.dataModelService.categoryManagement.getNewAccountID(this.incomeAccounts);
    const wizeFiCategory = this.dataModelService.categoryManagement.makeWizeFiCategory('income', 'income', newAccountID);

    const newAccount = {
      accountID: { label: 'Account ID', isRequired: true, val: newAccountID },
      accountName: { label: 'Account Name', isRequired: true, val: accountName || '' },
      accountType: { label: 'Account Type', isRequired: true, val: 'income' },
      genericCategory: { label: 'Generic Category', isRequired: true, val: 'none' },
      isRequired: { label: 'isRequired', isRequired: true, val: true },
      monthlyAmount: { label: 'Monthly Amount', isRequired: true, val: 0 },
      actualMonthlyAmount: { label: 'Actual Monthly Amount', isRequired: true, val: 0 },
      wizeFiCategory: { label: 'WizeFi Category', isRequired: true, val: wizeFiCategory }
    };
    this.incomeAccounts.push(newAccount);
  }
}
