import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { DialogDisplayDraftsComponent } from '@app/components/dialog-display-drafts/dialog-display-drafts.component';
import { PlaidAccount } from '@app/interfaces/plaid-account';
import { PlaidInstitution } from '@app/interfaces/plaid-institution';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountService } from '@app/services/account/account.service';

@Component({
  selector: 'app-institution-accounts',
  templateUrl: './institution-accounts.component.html',
  styleUrls: ['./institution-accounts.component.scss']
})
export class InstitutionAccountsComponent implements OnInit, OnDestroy {
  @Input() public plaidInstitution: PlaidInstitution;
  @Input() public disableEditMode = false;
  @Input() public review = false;
  @Input() public accountsChanged$: Observable<void>;
  @Output() public selectAccount: EventEmitter<PlaidAccount> = new EventEmitter<PlaidAccount>();
  public isEditingInstitution = false;
  public accounts: PlaidAccount[];

  public loading = true;

  private onDestroy$ = new Subject<void>();

  constructor(private dataModelService: DataModelService, private dialog: MatDialog, public accountService: AccountService) {}

  public ngOnInit() {
    this.getAccounts();
    this.loading = false;
    if (this.accountsChanged$) {
      this.accountsChanged$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        this.getAccounts();
      });
    }
  }

  public ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public selectAccountPassthrough(event: PlaidAccount) {
    this.selectAccount.emit(event);
  }

  public onEditLinkedAccounts() {
    if (this.shouldNavigateToEditPlan()) {
      this.dialog.open(DialogDisplayDraftsComponent, { data: 'accounts' });
    } else {
      this.isEditingInstitution = true;
    }
  }

  private shouldNavigateToEditPlan(): boolean {
    return this.accountService.isInAccountsReadOnlyPage();
  }

  public onSaveInstitution() {
    this.getAccounts();
    this.isEditingInstitution = false;
  }

  private getAccounts() {
    let accounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    accounts = (accounts && accounts.filter(acct => acct.item_id && this.plaidInstitution && acct.item_id === this.plaidInstitution.item_id)) || [];

    this.accounts = accounts;
    return accounts;
  }

  public onCancel() {
    this.isEditingInstitution = false;
  }
}
