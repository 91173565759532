// suggestion-management.class.ts

import { DataModelService } from '../services/data-model/data-model.service';

export class SuggestionManagement {
  public suggestionData: any;

  constructor(private dataModelService: DataModelService) {
    this.suggestionData = {};
  } // constructor

  ////////////////////////////////////////////////////
  // routines to access DynamoDB data
  ////////////////////////////////////////////////////

  public fetch() {
    return new Promise((resolve, reject) => {
      // define params to guide get operation
      const params = {
        TableName: 'UserSuggestions',
        Key: { WizeFi: 'WizeFi' }
      };

      // fetch data
      this.dataModelService.dataModel.global.docClient.get(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          let suggestionData = {};
          if (data.hasOwnProperty('Item') && data.Item.hasOwnProperty('suggestionData')) {
            suggestionData = JSON.parse(data.Item.suggestionData);
          }
          resolve(suggestionData);
        }
      }); // docClient.get
    }); // return Promise
  } // fetch

  public store(suggestionData) {
    return new Promise((resolve, reject) => {
      // define params to guide put operation
      const params = {
        TableName: 'UserSuggestions',
        Item: { WizeFi: 'WizeFi', suggestionData: JSON.stringify(suggestionData) }
      };

      // put info in DynamoDB table
      this.dataModelService.dataModel.global.docClient.put(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve('Data has been stored');
        }
      });
    }); // return Promise
  } // store

  public getUserInfo(wizeFiID) {
    return new Promise((resolve, reject) => {
      // define params to guide get operation
      const params = {
        TableName: 'WizeFiData',
        Key: { wizeFiID }
      };

      // fetch data
      this.dataModelService.dataModel.global.docClient.get(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const userInfo = {
            wizeFiID,
            nameFirst: 'unknown',
            nameLast: 'unknown',
            email: 'unknown'
          };
          if (data.hasOwnProperty('Item')) {
            const item = data.Item;
            item.persistent = JSON.parse(item.persistent);

            userInfo.nameFirst = item.persistent.profile.nameFirst;
            userInfo.nameLast = item.persistent.profile.nameLast;
            userInfo.email = item.persistent.profile.email;
          }
          resolve(userInfo);
        }
      }); // docClient.get
    }); // return Promise
  } // getUserInfo

  ////////////////////////////////////////////////////
  // utility routines
  ////////////////////////////////////////////////////

  public async loadSuggestionData(): Promise<any> {
    this.suggestionData = await this.fetch();
  } // loadSuggestionData

  public getVoteItem() {
    const voteItem = {
      wizeFiID: this.dataModelService.dataModel.global.wizeFiID,
      nameFirst: this.dataModelService.dataModel.persistent.profile.nameFirst,
      nameLast: this.dataModelService.dataModel.persistent.profile.nameLast,
      email: this.dataModelService.dataModel.persistent.profile.email
    };
    return voteItem;
  } // getVoteItem

  public async addSuggestion(suggestionTitle, suggestionDescription) {
    // add new suggestion to suggestionData
    const suggestionID = this.dataModelService.generateIDcode(5);
    this.suggestionData[suggestionID] = {
      timestamp: new Date().toISOString(),
      suggestionTitle,
      suggestionDescription,
      priority: 0,
      votes: [this.getVoteItem()]
    };

    // update data in DynamoDB
    await this.store(this.suggestionData);
  } // addSuggestion

  public async deleteSuggestion(suggestionID) {
    // remove suggestion from suggestionData
    delete this.suggestionData[suggestionID];

    // update data in DynamoDB
    await this.store(this.suggestionData);
  } // deleteSuggestion

  public async addVote(suggestionID) {
    const getHaveItem = () => {
      let haveItem = false;
      let i = -1;
      while (++i < this.suggestionData[suggestionID].votes.length && !haveItem) {
        if (this.suggestionData[suggestionID].votes[i].wizeFiID === this.dataModelService.dataModel.global.wizeFiID) {
          haveItem = true;
        }
      }
      return haveItem;
    }; // getHaveItem

    if (this.suggestionData.hasOwnProperty(suggestionID)) {
      if (!getHaveItem()) {
        this.suggestionData[suggestionID].votes.push(this.getVoteItem());

        // update data in DynamoDB
        await this.store(this.suggestionData);
      }
    }
  } // addVote

  public async getUserInfoList(suggestionID, suggestionData) {
    const userInfoList = [];
    if (suggestionData.hasOwnProperty(suggestionID)) {
      for (const wizeFiID of suggestionData[suggestionID].votes) {
        const userInfo = await this.getUserInfo(wizeFiID);
        userInfoList.push(userInfo);
      }
    }

    return userInfoList;
  } // getUserInfoList
} // class SuggestionManagement
