import { ErrorHandler, Injectable, Injector } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { LogMessageService } from '../services/log-message/log-message.service';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  public logMessageService: LogMessageService;

  constructor(private injector: Injector) {}
  // constructor (private dataModelService: DataModelService) { }

  public handleError(error) {
    let msg, stack;

    /* console.log("here is the custom error handler");
    console.log("error: ", error);
    console.log("msg", msg);
    console.log("stack", stack);
 */
    this.logMessageService = this.injector.get(LogMessageService);

    if (error.hasOwnProperty('rejection')) {
      if (error.rejection === null && error.message && error.stack) {
        msg = error.message;
        stack = error.stack;
      } else {
        msg = error.rejection;
      }
    } else {
      console.error('error', error);
      msg = error;
    }

    Sentry.captureException(error);

    // rethrow the error (otherwise it gets swallowed)
    // TODO determine whether to rethrow the error
    /* if (error) {
      console.log(stack);
      Sentry.showReportDialog({
        logErrors: true,
        showDialog: true,
        dialogOptions: {
          user: {
            email: "",
            name: ""
          }
          title: "string",
          subtitle: "sub",
          subtitle2: "string",
          labelName: "string",
          labelEmail: "string",
          labelComments: "string",
          labelClose: "string",
          labelSubmit: "string",
          errorGeneric: "string",
          errorFormEntry: "string",
          successMessage: "string"
          Callback after reportDialog showed up
        }
      });
    } else {
      this.logMessageService.reportError(msg, stack);
    } */
  } // handleError

  public plaidError(err) {
    console.log(err);
  }
} // class CustomErrorHandler
