import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() title = '';
  @Input() footerText = '';
  @Input() footerButtonUrl = '';
  @Input() footerFragment = '';
  @Input() doesNeedAction = false;
  @Input() black = false;
  @Input() noCheck = false;

  constructor(protected router: Router) {}

  protected goToUrl() {
    if (this.footerFragment && this.footerFragment != '') {
      return this.router.navigate([`/${this.footerButtonUrl}`], { fragment: this.footerFragment });
    }
    return this.router.navigate([`/${this.footerButtonUrl}`]);
  }
  ngOnInit() {}
}
