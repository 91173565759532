import { Component, OnInit, Input, Output, EventEmitter, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { DataModelService } from 'src/app/services/data-model/data-model.service';
import { AccountTransactionsComponent } from '../../cards/account-transactions/account-transactions.component';
import { ApplyCashDifferenceComponent } from '../../setup-flow/apply-cash-difference/apply-cash-difference.component';
import {
  AccountTypeSelectorComponent,
  IAccountTypeSelectorData
} from '../../setup-flow/accounts/account-type-selector/account-type-selector.component';
import { BudgetPageFourStepPlanComponent } from '../budget-page-four-step-plan/budget-page-four-step-plan.component';

@Component({
  selector: 'app-budget-page-plan',
  templateUrl: './budget-page-plan.component.html',
  styleUrls: ['./budget-page-plan.component.scss']
})
export class BudgetPagePlanComponent extends ApplyCashDifferenceComponent {
  @Input() public wizeFiCategory2MonthlyAmount: any;
  @ViewChild('accounttransactions', { static: false }) public accountTransactionsComponent: AccountTransactionsComponent;
  @ViewChildren('fourStepPlanItem') public budgetPageFourStepPlanComponents: QueryList<BudgetPageFourStepPlanComponent>;
  @Output() public onValueChange: EventEmitter<any> = new EventEmitter<any>();
  public selectedAccount: any;
  public selectedStepNumber: number;
  public stepLabels = [
    '', // no step "0"
    'Emergency Savings',
    'Debt',
    'General Savings',
    'Investments'
  ];

  constructor(protected dialog: MatDialog, protected dataModelService: DataModelService, protected router: Router) {
    super(dataModelService, router);
  }

  public selectAccount(event) {
    this.selectedAccount = event.account;
    this.accountTransactionsComponent.setTransactionsByDate(this.selectedAccount);
    this.selectedStepNumber = event.stepNumber;
  }

  public closeAllOtherAccordians(event) {
    this.budgetPageFourStepPlanComponents.map(e => {
      if (event.component != e) {
        e.toggleDropdown(false, false);
      }
    });
  }

  private getPlaidAccount(wizeFiCategory) {
    const categoryManagement = this.dataModelService.categoryManagement;
    const plaidAccountsDict = categoryManagement.getWizeFiCategory2wizeFiPlaidAccount();
    return plaidAccountsDict[wizeFiCategory];
  }

  protected showAccountModal() {
    const plaidAccount = this.getPlaidAccount(this.selectedAccount.wizeFiCategory);

    this.dialog
      .open<AccountTypeSelectorComponent, IAccountTypeSelectorData>(AccountTypeSelectorComponent, {
        data: {
          plaidAccount
        }
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.ngOnInit();
          this.onValueChange.emit();
        }
      });
  }
}
