import { Component, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import Cropper from 'cropperjs';
import { CropperComponent } from 'angular-cropperjs';
import fileUploadService from 'src/app/services/fileupload.service';

@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss']
})
export class Crop {
  @Input() public Image: any;
  @ViewChild('angularCropper', { static: true }) public angularCropper: CropperComponent;
  public message: string;
  public cropperOptions;
  public cropURL: any;
  public cropFile: any;
  public wizeFiPicture: any;
  public constructor(
    public profilePictureService: fileUploadService,
    public matDialogRef: MatDialogRef<Crop>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  public ngOnInit() {
    this.cropperOptions = {
      center: true,
      guides: true,
      viewMode: 1,
      scalable: true,
      zoomable: true,
      movable: true,
      checkCrossOrigin: true,
      autoCrop: true,
      cropmove: this.cropMoved.bind(this),
      zoom: e => {},
      crop: e => {},
      cropstart: e => {},
      cropend: e => {
        console.log(e);
      },
      ready: e => {}
    };
  }

  cropMoved(data) {
    this.angularCropper.cropper.getCroppedCanvas().toBlob(
      blob => {
        this.cropFile = blob;
      },
      'image/png',
      1
    );
  }

  async save() {
    // console.log(this.cropFile);
    await this.profilePictureService
      .uploadPicture(null, this.cropFile, true)
      .then(() => {
        console.log('image uploaded');
      })
      .then(() => {
        this.profilePictureService.setWizeFiUserPictureURL();
      })
      .then(() => {
        window.location.reload();
      });
  }
}
