import { Component, OnInit, ViewChild } from '@angular/core';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { SetupFlowDataManagementService, SetupFlowScreen } from '../../../services/setup/setup-flow-data-management.service';
import { ApplyCashDifferenceComponent } from '../apply-cash-difference/apply-cash-difference.component';
import { SetupService } from '@app/services/setup.service';

@Component({
  selector: 'app-apply-cafr-screen',
  templateUrl: './apply-cafr-screen.component.html',
  styleUrls: ['./apply-cafr-screen.component.scss']
})
export class ApplyCafrScreenComponent implements SetupFlowScreen, OnInit {
  @ViewChild(ApplyCashDifferenceComponent, { static: false })
  public applyCashDifference: ApplyCashDifferenceComponent;

  constructor(
    protected dataModelService: DataModelService,
    protected dataManagement: SetupFlowDataManagementService,
    private setupService: SetupService
  ) {
    this.dataManagement.currentSetupScreen = this;
  }

  public async ngOnInit() {
    this.setupService.updateCurrentSetupStep(8);
  }

  public canSave() {
    return this.applyCashDifference.cafrCreated >= this.applyCashDifference.totalPlannedSpending;
  }

  public onSave() {
    this.applyCashDifference.onSave();
  }

  public showOnSaveErrorMessage(): void {
    this.dataModelService.showMessage('error', 'You have applied more CAFR than is available. Please adjust values accordingly.', 3000);
  }
}
