import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule, MatDialogModule, MatIconModule, MatRadioModule, MatSlideToggleModule, MatTooltipModule } from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserModule } from '@angular/platform-browser';
import { TruncateEllipsisPipe } from '@app/pipes/truncate-ellipsis.pipe';
import { ChartsModule } from 'ng2-charts';
import { AppComponent } from '../components/app/app.component';
import { BudgetPageFourStepPlanComponent } from '../components/budget-page/budget-page-four-step-plan/budget-page-four-step-plan.component';
import { DebtProjectionChartComponent } from '../components/charts/debt-projection-chart/debt-projection-chart.component';
import { NetWorthProjectionChartComponent } from '../components/charts/net-worth-projection-chart/net-worth-projection-chart.component';
import { ProjectionChartComponent } from '../components/charts/projection-chart/projection-chart.component';
import { DialogHeaderActionComponent } from '../components/dialog-header-action/dialog-header-action.component';
import { NewUserStartComponent } from '../components/new-user-start/new-user-start.component';
import { SettingsDeactivateGuard } from '../components/settings/settings-deactivate.guard';
import { AccountDetailsTemplateComponent } from '../components/setup-flow/accounts/account-details-template/account-details-template.component';
import { AccountDetailsComponent } from '../components/setup-flow/accounts/account-details/account-details.component';
import { AccountTypeSelectorComponent } from '../components/setup-flow/accounts/account-type-selector/account-type-selector.component';
import { AutoInsuranceAccountDetailsComponent } from '../components/setup-flow/accounts/auto-insurance-account-details/auto-insurance-account-details.component';
import { CheckingAccountDetailsComponent } from '../components/setup-flow/accounts/checking-account-details/checking-account-details.component';
import { CreditCardAccountDetailsComponent } from '../components/setup-flow/accounts/credit-card-account-details/credit-card-account-details.component';
import { EmergencySavingsAccountDetailsComponent } from '../components/setup-flow/accounts/emergency-savings-account-details/emergency-savings-account-details.component';
import { InvestmentAccountDetailsComponent } from '../components/setup-flow/accounts/investment-account-details/investment-account-details.component';
import { InvestmentPropertyAccountDetailsComponent } from '../components/setup-flow/accounts/investment-property-account-details/investment-property-account-details.component';
import { ManualAccountChooserComponent } from '../components/setup-flow/accounts/manual-account-chooser/manual-account-chooser.component';
import { MortgageAccountDetailsComponent } from '../components/setup-flow/accounts/mortgage-account-details/mortgage-account-details.component';
import { PermInsuranceAccountDetailsComponent } from '../components/setup-flow/accounts/perm-insurance-account-details/perm-insurance-account-details.component';
import { PersonalPropertyAccountDetailsComponent } from '../components/setup-flow/accounts/personal-property-account-details/personal-property-account-details.component';
import { ResidencePropertyAccountDetailsComponent } from '../components/setup-flow/accounts/residence-property-account-details/residence-property-account-details.component';
import { TermInsuranceAccountDetailsComponent } from '../components/setup-flow/accounts/term-insurance-account-details/term-insurance-account-details.component';
import { AddIncomeSourceComponent } from '../components/setup-flow/add-income-source/add-income-source.component';
import { ApplyCafrScreenComponent } from '../components/setup-flow/apply-cafr-screen/apply-cafr-screen.component';
import { ApplyCashDifferenceComponent } from '../components/setup-flow/apply-cash-difference/apply-cash-difference.component';
import { BudgetCategoryComponent } from '../components/setup-flow/budget-category/budget-category.component';
import { CafrCreatedComponent } from '../components/setup-flow/cafr-created/cafr-created.component';
import { CafrDetailsComponent } from '../components/setup-flow/cafr-details/cafr-details.component';
import { CafrLeftToApplyComponent } from '../components/setup-flow/cafr-left-to-apply/cafr-left-to-apply.component';
import { CafrScreenComponent } from '../components/setup-flow/cafr-screen/cafr-screen.component';
import { CategoryListItemComponent } from '../components/setup-flow/category-list-item/category-list-item.component';
import { CategoryTransactionsComponent } from '../components/setup-flow/category-transactions/category-transactions.component';
import { ConnectBankComponent } from '../components/setup-flow/connect-bank/connect-bank.component';
import { CountryPickerComponent } from '../components/setup-flow/connect-bank/country-picker/country-picker.component';
import { CurrencyConfirmationComponent } from '../components/setup-flow/connect-bank/currency-confirmation/currency-confirmation.component';
import { CreateCafrScreenComponent } from '../components/setup-flow/create-cafr-screen/create-cafr-screen.component';
import { CurrentExpensesCategoryComponent } from '../components/setup-flow/current-expenses-category/current-expenses-category.component';
import { CurrentFFAComponent } from '../components/setup-flow/current-ffa/current-ffa.component';
import { DebtPayoffProjectionsComponent } from '../components/setup-flow/debt-payoff-projections/debt-payoff-projections.component';
import { DeductionsDisplayComponent } from '../components/setup-flow/deductions/deductions-display/deductions-display.component';
import { DeductionsComponent } from '../components/setup-flow/deductions/deductions.component';
import { EditManualInstitutionComponent } from '../components/setup-flow/edit-manual-institution/edit-manual-institution.component';
import { EditTransactionCategoryComponent } from '../components/setup-flow/edit-transaction-category/edit-transaction-category.component';
import { FinancialFreedomDetailsComponent } from '../components/setup-flow/financial-freedom-details/financial-freedom-details.component';
import { FinishScreenComponent } from '../components/setup-flow/finish-screen/finish-screen.component';
import { FourStepPlanCategoryComponent } from '../components/setup-flow/four-step-plan-category/four-step-plan-category.component';
import { FourStepPlanComponent } from '../components/setup-flow/four-step-plan/four-step-plan.component';
import { GoalsScreenComponent } from '../components/setup-flow/goals-screen/goals-screen.component';
import { GoalsComponent } from '../components/setup-flow/goals-screen/goals/goals.component';
import { IncomeScreenComponent } from '../components/setup-flow/income-screen/income-screen.component';
import { IncomeComponent } from '../components/setup-flow/income-screen/income/income.component';
import { InstitutionAccountComponent } from '../components/setup-flow/institution-accounts/institution-account/institution-account.component';
import { InstitutionAccountsComponent } from '../components/setup-flow/institution-accounts/institution-accounts.component';
import { ManualTransactionDetailsComponent } from '../components/setup-flow/manual-transaction-details/manual-transaction-details.component';
import { MonthlyIncomeDisplayComponent } from '../components/setup-flow/monthly-income/monthly-income-display/monthly-income-display.component';
import { MonthlyIncomeComponent } from '../components/setup-flow/monthly-income/monthly-income.component';
import { NetIncomeComponent } from '../components/setup-flow/net-income/net-income.component';
import { NetWorthProjectionComponent } from '../components/setup-flow/net-worth-projection/net-worth-projection.component';
import { NewPlanProjectionsComponent } from '../components/setup-flow/new-plan-projections/new-plan-projections.component';
import { OtherDeductionsComponent } from '../components/setup-flow/other-deductions/other-deductions.component';
import { ProfileLightComponent } from '../components/setup-flow/profile-light/profile-light.component';
import { ProjectionsScreenComponent } from '../components/setup-flow/projections-screen/projections-screen.component';
import { ReallocateSpendingCategoryComponent } from '../components/setup-flow/reallocate-spending-category/reallocate-spending-category.component';
import { ReallocateSpendingComponent } from '../components/setup-flow/reallocate-spending/reallocate-spending.component';
import { RetirementAgeComponent } from '../components/setup-flow/retirement-age/retirement-age.component';
import { RetirementGoalComponent } from '../components/setup-flow/retirement-goal/retirement-goal.component';
import { RetirementIncomeComponent } from '../components/setup-flow/retirement-income/retirement-income.component';
import { ReviewAccountsComponent } from '../components/setup-flow/review-accounts/review-accounts.component';
import { SelectAccountsComponent } from '../components/setup-flow/select-accounts/select-accounts.component';
import { SetupReviewComponent } from '../components/setup-flow/setup-review/setup-review.component';
import { WaitForCoach } from '../components/setup-flow/setup-review/wait-for-coach/wait.component';
import { SetupSettingsComponent } from '../components/setup-flow/setup-settings/setup-settings.component';
import { SpendingScreenComponent } from '../components/setup-flow/spending-screen/spending-screen.component';
import { SpendingComponent } from '../components/setup-flow/spending-screen/spending/spending.component';
import { TransactionListItemComponent } from '../components/setup-flow/transaction-list-item/transaction-list-item.component';
import { UncategorizedTransactionsComponent } from '../components/setup-flow/uncategorized-transactions/uncategorized-transactions.component';
import { VerifySpendingCompleteComponent } from '../components/setup-flow/verify-spending-complete/verify-spending-complete.component';
import { VerifySpendingComponent } from '../components/setup-flow/verify-spending/verify-spending.component';
import { WizefiFFAComponent } from '../components/setup-flow/wizefi-ffa/wizefi-ffa.component';
import { WizefiMeterComponent } from '../components/setup-flow/wizefi-meter/wizefi-meter.component';
import { DataModelService } from '../services/data-model/data-model.service';
import { LogMessageService } from '../services/log-message/log-message.service';
import { SetupFlowDataManagementService } from '../services/setup/setup-flow-data-management.service';
import { SharedModule } from '../shared/shared.module';
import { CustomErrorHandler } from '../utilities/custom-error-handler.class';
import { ScreenLoginGuard } from '../utilities/screen-login.guard';
import { SecureScreenGuard } from '../utilities/secure-screen.guard';
import { AppRoutingModule } from './routing.module';
import { StructureModule } from './structure.module';

@NgModule({
  declarations: [
    NewUserStartComponent,
    ProjectionChartComponent,
    ConnectBankComponent,
    ReviewAccountsComponent,
    AccountDetailsComponent,
    AccountDetailsTemplateComponent,
    ManualAccountChooserComponent,
    AccountTypeSelectorComponent,
    InvestmentAccountDetailsComponent,
    InvestmentPropertyAccountDetailsComponent,
    EmergencySavingsAccountDetailsComponent,
    ResidencePropertyAccountDetailsComponent,
    CheckingAccountDetailsComponent,
    CreditCardAccountDetailsComponent,
    MortgageAccountDetailsComponent,
    PermInsuranceAccountDetailsComponent,
    TermInsuranceAccountDetailsComponent,
    AutoInsuranceAccountDetailsComponent,
    PersonalPropertyAccountDetailsComponent,
    InstitutionAccountsComponent,
    InstitutionAccountComponent,
    SelectAccountsComponent,
    EditManualInstitutionComponent,
    GoalsScreenComponent,
    GoalsComponent,
    FinancialFreedomDetailsComponent,
    ProfileLightComponent,
    RetirementAgeComponent,
    RetirementGoalComponent,
    RetirementIncomeComponent,
    IncomeScreenComponent,
    IncomeComponent,
    MonthlyIncomeComponent,
    MonthlyIncomeDisplayComponent,
    AddIncomeSourceComponent,
    DeductionsComponent,
    DeductionsDisplayComponent,
    OtherDeductionsComponent,
    NetIncomeComponent,
    SpendingScreenComponent,
    SpendingComponent,
    VerifySpendingComponent,
    CategoryListItemComponent,
    BudgetCategoryComponent,
    CurrentExpensesCategoryComponent,
    CategoryTransactionsComponent,
    TransactionListItemComponent,
    EditTransactionCategoryComponent,
    UncategorizedTransactionsComponent,
    VerifySpendingCompleteComponent,
    ProjectionsScreenComponent,
    NetWorthProjectionComponent,
    CurrentFFAComponent,
    WizefiFFAComponent,
    ProjectionChartComponent,
    NetWorthProjectionChartComponent,
    DebtProjectionChartComponent,
    WizefiMeterComponent,
    CreateCafrScreenComponent,
    ApplyCafrScreenComponent,
    CafrScreenComponent,
    CafrCreatedComponent,
    CafrLeftToApplyComponent,
    ReallocateSpendingComponent,
    ReallocateSpendingCategoryComponent,
    CafrDetailsComponent,
    FourStepPlanComponent,
    ApplyCashDifferenceComponent,
    FinishScreenComponent,
    DebtPayoffProjectionsComponent,
    FourStepPlanCategoryComponent,
    BudgetPageFourStepPlanComponent,
    NewPlanProjectionsComponent,
    SetupReviewComponent,
    SetupSettingsComponent,
    ManualTransactionDetailsComponent,
    CurrencyConfirmationComponent,
    CountryPickerComponent,
    DialogHeaderActionComponent,
    WaitForCoach,
    TruncateEllipsisPipe
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    ChartsModule,
    StructureModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatDialogModule,
    MatButtonModule,
    MatRadioModule,
    MatIconModule,
    MatTooltipModule,
    SharedModule
  ],
  exports: [
    NetIncomeComponent,
    InstitutionAccountsComponent,
    AccountTypeSelectorComponent,
    MonthlyIncomeComponent,
    MonthlyIncomeDisplayComponent,
    DeductionsDisplayComponent,
    GoalsComponent,
    IncomeComponent,
    SpendingComponent,
    CurrentExpensesCategoryComponent,
    ReallocateSpendingComponent,
    ReallocateSpendingCategoryComponent,
    ApplyCashDifferenceComponent,
    FourStepPlanCategoryComponent,
    BudgetPageFourStepPlanComponent,
    NewPlanProjectionsComponent,
    NewPlanProjectionsComponent,
    DebtPayoffProjectionsComponent,
    ProjectionChartComponent,
    NetWorthProjectionChartComponent,
    DebtProjectionChartComponent,
    EditTransactionCategoryComponent,
    SetupReviewComponent,
    SetupSettingsComponent,
    ManualTransactionDetailsComponent,
    WaitForCoach
  ],
  providers: [
    DataModelService,
    SetupFlowDataManagementService,
    LogMessageService,
    SettingsDeactivateGuard,
    ScreenLoginGuard,
    SecureScreenGuard,
    { provide: ErrorHandler, useClass: CustomErrorHandler } // this leads to error: Cannot instantiate cyclic dependency
  ],
  entryComponents: [
    AccountTypeSelectorComponent,
    ManualTransactionDetailsComponent,
    CurrencyConfirmationComponent,
    CountryPickerComponent,
    WaitForCoach
  ],
  bootstrap: [AppComponent]
})
export class SetupModule {}
