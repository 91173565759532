import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { SetupFlowDataManagementService, SetupFlowScreen } from '../../../services/setup/setup-flow-data-management.service';
import { MonthlyIncomeComponent } from '../monthly-income/monthly-income.component';
import { OtherDeductionsComponent } from '../other-deductions/other-deductions.component';
import { SetupService } from '@app/services/setup.service';

@Component({
  selector: 'app-income-screen',
  templateUrl: './income-screen.component.html',
  styleUrls: ['./income-screen.component.scss']
})
export class IncomeScreenComponent implements SetupFlowScreen, OnInit {
  @ViewChild(MonthlyIncomeComponent, { static: false })
  public monthlyIncome: MonthlyIncomeComponent;
  @ViewChild(OtherDeductionsComponent, { static: false })
  public otherDeductions: OtherDeductionsComponent;
  public grossMonthlyIncome: number;
  public deductionsTotal: number;

  constructor(
    protected dataModelService: DataModelService,
    protected dataManagement: SetupFlowDataManagementService,
    private setupService: SetupService
  ) {
    dataManagement.currentSetupScreen = this;
  }

  public async ngOnInit() {
    this.setupService.updateCurrentSetupStep(3);
  }

  public canSave(): boolean {
    return true;
  }

  public onSave() {
    this.monthlyIncome.onSave();
  }

  public showOnSaveErrorMessage(): void {
    // no possible saving errors on this page
  }
}
