import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataModelService } from '../../../services/data-model/data-model.service';

@Component({
  selector: 'app-retirement-age',
  templateUrl: './retirement-age.component.html',
  styleUrls: ['./retirement-age.component.scss']
})
export class RetirementAgeComponent implements OnInit {
  public retirementAge = this.dataModelService.dataModel.persistent.profile.retirementAge;
  public isLoading = true;
  public isUpdating = false;

  constructor(protected dataModelService: DataModelService, protected router: Router) {}

  public ngOnInit() {
    this.dataModelService.dataManagement
      .fetchinfo()
      .then(() => (this.retirementAge = this.dataModelService.dataModel.persistent.profile.retirementAge))
      .catch(err => console.error('Error fetching data:', err))
      .finally(() => (this.isLoading = false));
  }

  public onAgeChange(value) {
    this.retirementAge = parseInt(value, 10);
  }

  public onNext() {
    // @TODO: Add validation to reasonable retirement age ranges
    this.isUpdating = true;
    this.dataModelService.dataModel.persistent.profile.retirementAge = this.retirementAge;
    this.dataModelService.dataManagement
      .storeinfo()
      .then(() => {
        this.router.navigate(['/retirement-goal']);
      })
      .catch(err => console.error('Error updating data model: ', err))
      .finally(() => (this.isUpdating = false));
  }
}
