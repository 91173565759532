import { DataModelService } from '../services/data-model/data-model.service';

export class YodleeManagement {
  public wizeFiID: string;

  constructor(public dataModelService: DataModelService) {}

  public invokeManageYodlee(action: string, actionParms: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let payload, params;

      // set params to guide function invocation
      payload = {
        action,
        actionParms,
        wizeFiID: this.wizeFiID // for managing Sandbox environment  //%//
      };
      params = {
        FunctionName: 'manageYodlee',
        Payload: JSON.stringify(payload)
      };

      // invoke lambda function to process data
      this.dataModelService.dataModel.global.lambda.invoke(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          if (!data.hasOwnProperty('Payload')) {
            reject('Data does not contain Payload attribute');
          } else {
            payload = JSON.parse(data.Payload);
            if (payload.hasOwnProperty('errorMessage')) {
              reject(payload.errorMessage);
            } else if (payload.hasOwnProperty('errorType')) {
              reject(payload.errorType);
            } else {
              resolve(payload);
            }
          }
        }
      }); // lambda invoke
    }); // return Promise
  } // invokeManageYodlee

  public setWizeFiID(wizeFiID) {
    this.wizeFiID = wizeFiID;
  } // setWizeFiID

  public getYodleeUserList(wizeFiID) {
    let yodleeUserList = [wizeFiID];

    // add Sandbox test users (if any)
    switch (wizeFiID) {
      // Dave
      case '10213363587114053': // demo
      case '10215006983557937': // prod
        yodleeUserList = yodleeUserList.concat([
          'sbMemaMOAscXyJqAAu1',
          'sbMemaMOAscXyJqAAu2',
          'sbMemaMOAscXyJqAAu3',
          'sbMemaMOAscXyJqAAu4',
          'sbMemaMOAscXyJqAAu5'
        ]);
        break;

      // Jeff
      case 'rpakayj': // demo
      case 'ylrjxfe': // prod
        yodleeUserList = yodleeUserList.concat([
          'sbMemPTdwZcMQuBMMP1',
          'sbMemPTdwZcMQuBMMP2',
          'sbMemPTdwZcMQuBMMP3',
          'sbMemPTdwZcMQuBMMP4',
          'sbMemPTdwZcMQuBMMP5'
        ]);
        break;

      // Greg
      case '10100507229934732': // prod
        yodleeUserList = yodleeUserList.concat([]);
        break;

      // Dalton
      case 'jvrjbpl': // demo
        yodleeUserList = yodleeUserList.concat([
          'sbMemNbHjxkTLhZbZe1',
          'sbMemNbHjxkTLhZbZe2',
          'sbMemNbHjxkTLhZbZe3',
          'sbMemNbHjxkTLhZbZe4',
          'sbMemNbHjxkTLhZbZe5'
        ]);
        break;
    } // switch

    return yodleeUserList;
  } // getYodleeUserList

  public createCobrandToken() {
    const action = 'createCobrandToken';
    const actionParms = {};
    return this.invokeManageYodlee(action, actionParms);
  } // createCobrandToken

  public createUserToken(userName) {
    const action = 'createUserToken';
    const actionParms = { userName };
    return this.invokeManageYodlee(action, actionParms);
  } // createUserToken

  public registerUser(userName, email) {
    const action = 'registerUser';
    const actionParms = { userParam: { user: { loginName: userName, email } } };
    return this.invokeManageYodlee(action, actionParms);
  } // registerUser

  public deleteUser(userName) {
    const action = 'deleteUser';
    const actionParms = { userName };
    return this.invokeManageYodlee(action, actionParms);
  } // deleteUser

  public getTransactionCategories(userName) {
    const action = 'getTransactionCategories';
    const actionParms = { userName };
    return this.invokeManageYodlee(action, actionParms);
  } // getTransactionCategories

  public getUserInfo(userName) {
    const action = 'getUserInfo';
    const actionParms = { userName };
    return this.invokeManageYodlee(action, actionParms);
  } // getUserInfo

  public getUserAccounts(userName) {
    const action = 'getUserAccounts';
    const actionParms = { userName };
    return this.invokeManageYodlee(action, actionParms);
  } // getUserAccounts

  public getUserTransactions(userName, dateRange) {
    const action = 'getUserTransactions';
    const actionParms = { userName, dateRange };
    return this.invokeManageYodlee(action, actionParms);
  } // getUserTransactions
} // class YodleeManagement
