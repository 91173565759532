import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { TransactionRuleService } from 'src/app/services/transaction-rule/transaction-rule.service';
import { CAssetProtection } from 'src/app/utilities/assets/asset-protection.class';
import { CAssets } from 'src/app/utilities/assets/assets.class';
import { CBudget } from 'src/app/utilities/budget/budget.class';
import { GenericDataManagement } from 'src/app/utilities/generic-data-management.class';
import { CIncome } from 'src/app/utilities/income/income.class';
import { CLiabilities } from 'src/app/utilities/liabilities/liabilities.class';
import { CProfile } from 'src/app/utilities/profile.class';
import { shadowAccounts } from 'src/app/utilities/shadow-accounts.util';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { ReallocateSpendingCategoryComponent } from '../reallocate-spending-category/reallocate-spending-category.component';
import { VerifySpendingComponent } from '../verify-spending/verify-spending.component';

@Component({
  selector: 'app-reallocate-spending',
  templateUrl: './reallocate-spending.component.html',
  styleUrls: ['./reallocate-spending.component.scss']
})
export class ReallocateSpendingComponent extends VerifySpendingComponent {
  @ViewChildren('listItem')
  public reallocateSpendingCategoryComponents: QueryList<ReallocateSpendingCategoryComponent>;
  public guidelineSum = 0;
  public guidelineCafr;
  public totalIncome;
  public plannedSpending = 0;
  public lastMonthSpending;
  public showGuideline = true;
  //public plan;

  public previousPlan;
  public planDates;
  public wizeFiCategory2MonthlyAmount;
  budgetShadowAccounts: any;
  cProfile: any;
  cAssets: any;
  gdAssets: any;
  messages: any;
  cAssetProtection: any;
  gdAssetProtection: any;
  cBudget: any;
  gdBudget: any;
  cIncome: any;
  gdIncome: any;
  cLiabilities: any;
  gdLiabilities: any;
  incomeShadowAccounts: any;

  constructor(
    protected dataModelService: DataModelService,
    protected router: Router,
    private changeDetector: ChangeDetectorRef,
    transactionRuleService: TransactionRuleService
  ) {
    super(dataModelService, router, transactionRuleService);
    // this.plan = this.dataModelService.dataModel.persistent.header.curplan;
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;
    const plan = this.dataModelService.dataModel.persistent.plans[curplan];
    this.planDates = this.dataModelService.categoryManagement.obtainPlanDatesInfo();
    this.previousPlan = this.dataModelService.categoryManagement.getPreviousPlan(curplan, this.planDates);
  }

  public async ngOnInit() {
    await super.ngOnInit();
    this.wizeFiCategory2MonthlyAmount = this.dataModelService.categoryManagement.getWizeFiCategory2monthlyAmount(this.previousPlan);
    await this.setTotalIncome();
    await this.setGuidelineCafr();
    await this.initializeShadowAccountData();
    await this.setTotalLastMonthSpending();
    this.setPlannedSpending();
  }

  public closeAllOtherAccordians(event) {
    this.reallocateSpendingCategoryComponents.map(e => {
      if (event.component != e) {
        e.toggleDropdown(false, false);
      }
    });
  }

  public onSave() {}

  private setTotalIncome() {
    const income = this.dataModelService.getdata('income');
    const totalSum = this.getMonthlyAmountTotals(income);
    this.totalIncome = totalSum;
  }

  private setGuidelineCafr() {
    this.guidelineCafr = this.totalIncome * 0.2;
  }

  private getMonthlyAmountTotals(category: any): number {
    const subcatsWithAccounts = _.values(category).filter(obj => obj.accounts !== undefined);
    const allExpenseAccounts = _.flatten(subcatsWithAccounts.map(obj => obj.accounts));
    const totalSum = allExpenseAccounts.reduce((totalIncome, account) => (totalIncome += account ? account.monthlyAmount.val : 0), 0);
    return totalSum;
  }

  protected addToTotals({ guidelineSum, planSum, lastMonthSum }) {
    this.plannedSpending += parseFloat(planSum);
    this.guidelineSum += parseFloat(guidelineSum);
    this.changeDetector.detectChanges();
  }

  protected updateTotals({ guidelineSum, planSum, lastMonthSum }) {
    this.setPlannedSpending();
    this.guidelineSum += parseFloat(guidelineSum);
    this.changeDetector.detectChanges();
  }

  protected setPlannedSpending() {
    this.plannedSpending = this.reallocateSpendingCategoryComponents.reduce((totalPlanned, stepComponent) => totalPlanned + stepComponent.planSum, 0);
  }

  protected setTotalLastMonthSpending() {
    let total = 0;
    for (const category in this.wizeFiCategory2MonthlyAmount) {
      if (category.indexOf('budget_') == 0) {
        if (typeof this.dataModelService.categoryManagement.getMonthlyAccount(this.wizeFiCategory2MonthlyAmount, category) === 'string') {
          total += parseFloat(this.dataModelService.categoryManagement.getMonthlyAccount(this.wizeFiCategory2MonthlyAmount, category));
        } else {
          total += this.dataModelService.categoryManagement.getMonthlyAccount(this.wizeFiCategory2MonthlyAmount, category);
        }
      }
    }

    let shadowAccountsTotal = 0;
    this.budgetShadowAccounts.map(shadowAccount => {
      if (
        this.reallocateSpendingCategoryComponents.filter(stepComponent => this.belongsToCategory(shadowAccount, stepComponent.category)).length == 0
      ) {
        return;
      }

      shadowAccountsTotal += this.getLastMonthActualMonthlyAmount(shadowAccount);
    });

    console.log(shadowAccountsTotal);
    this.lastMonthSpending = total + shadowAccountsTotal;
    return total + shadowAccountsTotal;
  }

  protected getLastMonthActualMonthlyAmount(shadowAccount) {
    const wizeFiCategory = shadowAccount.wizeFiCategory.val;
    return this.dataModelService.categoryManagement.getActualMonthlyAccount(this.wizeFiCategory2MonthlyAmount, wizeFiCategory);
  }

  private belongsToCategory(shadowAccount, category): boolean {
    let isAShadowAccount = false;
    const containsCategory =
      shadowAccount.budgetSubcategory &&
      shadowAccount.budgetSubcategory.val !== '' &&
      category.label.toLowerCase().includes(shadowAccount.budgetSubcategory.val.toLowerCase());

    const belongsToCategory = shadowAccount.budgetSubcategory && shadowAccount.budgetSubcategory.val.toLowerCase() === category.label.toLowerCase();

    if (containsCategory === true || belongsToCategory === true) {
      if (shadowAccount.shadowType !== 'liability' && shadowAccount.shadowType !== 'asset' && shadowAccount.shadowType !== 'assetProtection') {
        isAShadowAccount = true;
      }
    }

    return isAShadowAccount;
  }

  private initializeShadowAccountData() {
    this.cProfile = new CProfile(this.dataModelService.getdata('profile'));

    this.cAssets = new CAssets(this.dataModelService.getdata('assets'));
    this.gdAssets = new GenericDataManagement(this.dataModelService, this.cAssets.assets, this.messages);

    this.cAssetProtection = new CAssetProtection(this.dataModelService.getdata('assetProtection'));
    this.gdAssetProtection = new GenericDataManagement(this.dataModelService, this.cAssetProtection.assetProtection, this.messages);

    this.cBudget = new CBudget(this.dataModelService.getdata('budget'));
    this.gdBudget = new GenericDataManagement(this.dataModelService, this.cBudget.budget, this.messages);

    this.cIncome = new CIncome(this.dataModelService.getdata('income'));
    this.gdIncome = new GenericDataManagement(this.dataModelService, this.cIncome.income, this.messages);

    this.cLiabilities = new CLiabilities(this.dataModelService.getdata('liabilities'));
    this.gdLiabilities = new GenericDataManagement(this.dataModelService, this.cLiabilities.liabilities, this.messages);

    this.incomeShadowAccounts = shadowAccounts(
      'incomeShadowAccounts',
      'monthlyIncome',
      this.gdBudget,
      this.gdIncome,
      this.gdAssets,
      this.gdAssetProtection,
      this.gdLiabilities,
      this.cProfile,
      this.cIncome,
      this.cBudget,
      this.cAssets,
      this.cAssetProtection,
      this.cLiabilities
    );
    this.budgetShadowAccounts = shadowAccounts(
      'budgetShadowAccounts',
      'monthlyMinimum',
      this.gdBudget,
      this.gdIncome,
      this.gdAssets,
      this.gdAssetProtection,
      this.gdLiabilities,
      this.cProfile,
      this.cIncome,
      this.cBudget,
      this.cAssets,
      this.cAssetProtection,
      this.cLiabilities
    );
    /* console.error(this.incomeShadowAccounts);
    console.error(this.budgetShadowAccounts); */
  }
}
