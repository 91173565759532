import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IDividedSubtypes } from 'src/app/services/account-type/account-type.service';
// eslint-disable-next-line import/no-extraneous-dependencies
import { TextInputComponent } from '../../../../components/text-input/text-input.component';
import { DataModelService } from './../../../../services/data-model/data-model.service';

@Component({
  selector: 'app-account-details-template',
  templateUrl: './account-details-template.component.html',
  styleUrls: ['./account-details-template.component.scss']
})
export class AccountDetailsTemplateComponent implements OnInit {
  @ViewChild('nameField', { static: true })
  public nameField: TextInputComponent;
  public showAccountTypeMenu = false;
  public accountType: string;
  public UNCATEGORIZED = 'Uncategorized';
  public forceHideSelector = false;

  @Input() public isPostSetup;
  @Input() public hasChangedSchema;
  @Input() public plaidAccount;
  @Input() public selectedSubtype;
  @Input() public selectedSubcategory;
  @Input() public selectedCategory;
  @Input() public selectedType;
  @Input() public wizeFiAccount;
  @Input() public isActive;
  @Input() public accountStatus;
  @Input() public accountName;
  @Input() public newWizeFiCategory;
  @Input() public categories;
  @Input() public subcategories;
  @Input() public types;
  @Input() public subtypes: IDividedSubtypes;
  @Input() public defaultProductivity;
  @Input() public selectedProductivity;

  @Output() public setIsActive: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onNameChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onTypeChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onSubtypeChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onProductivityChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onSave: EventEmitter<any> = new EventEmitter<any>();

  constructor(protected DataModelService: DataModelService) {}
  public ngOnInit() {
    this.setProductivity('Productive', false);
  }

  public onSaveChanges() {
    this.onSave.emit();
  }

  public nameDidChange(name) {
    this.accountName = name;
    this.onNameChange.emit(name);
  }

  public setProductivity(productivity, changing) {
    if (this.selectedProductivity === '' || changing !== false) {
      this.selectedProductivity = productivity;
    }
  }
}
