import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataModelService } from '../../services/data-model/data-model.service';
import { NewPlanProjectionsComponent } from '../setup-flow/new-plan-projections/new-plan-projections.component';

@Component({
  selector: 'app-updated-projections',
  templateUrl: './updated-projections.component.html',
  styleUrls: ['./updated-projections.component.scss']
})
export class UpdatedProjectionsComponent extends NewPlanProjectionsComponent implements OnInit {
  @Input() public dataBeforeEditingPlan: { data: any; age: number; wealth?: number; income?: number };
  @Input() public hasReceivedDataFromBeforeEditing = false;
  public beforeEditingFFAge: number;
  public beforeEditingFFDate: number;
  public beforeEditingFFWealth: number = this.ffWealth;
  public beforeEditingIncome: number;

  protected hasReachedTimeout = false;

  constructor(public dataModelService: DataModelService, public router: Router) {
    super(dataModelService, router);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.addDataBeforeEditingPlanIfAvailable();
    this.startTimeoutClock();
  }

  public isNegative(number) {
    return Math.sign(number);
  }

  public async addDataBeforeEditingPlanIfAvailable() {
    if (this.dataBeforeEditingPlan) {
      this.beforeEditingFFAge = this.dataBeforeEditingPlan.age;
      this.beforeEditingFFWealth = this.dataBeforeEditingPlan.wealth;
      this.beforeEditingIncome = this.dataBeforeEditingPlan.income;

      if (this.ffWealth !== this.beforeEditingFFWealth) {
        const actualDataProjInfo = await this.dataModelService.cafrManagement.getCafrDataProjectionInfo(
          {
            terminationType: 'financialFreedomTargetAmount',
            terminationValue: this.ffWealth
          },
          'actual'
        );

        // net worth
        const FFData = actualDataProjInfo.netWorth.summary.projection;
        const FFDate = FFData.slice(-1)[0].x;
        const FFAge = this.dateDiff(this.dataModelService.dataModel.persistent.profile.birthDate, FFDate);

        // debt
        const debtData = actualDataProjInfo.allDebt.summary.projection;
        const debtZeroCrossDatapoint = debtData.find(item => item.y === 0);

        this.datasets.push({
          data: FFData,
          color: this.darkBlue
        });
      } else {
        this.datasets.push({
          data: this.dataBeforeEditingPlan.data,
          color: this.darkBlue
        });
      }

      this.datasets.sort((a, b) => b.data.length - a.data.length || 0);
    }
  }

  protected startTimeoutClock() {
    setTimeout(() => {
      this.hasReachedTimeout = true;
    }, 1000);
  }
}
