import { Component, EventEmitter, Output } from '@angular/core';
import { DataModelService } from '../../../services/data-model/data-model.service';

@Component({
  selector: 'app-create-account-signup',
  templateUrl: './create-account-signup.component.html',
  styleUrls: ['./create-account-signup.component.scss']
})
export class CreateAccountSignupComponent {
  @Output() public loading = new EventEmitter<boolean>();
  public isLoading = false;
  public email = '';
  public password: string;
  public username: string;

  constructor(public dataModelService: DataModelService) {}

  public signup(): void {
    this.isLoading = true;
    this.loading.emit(true);
    this.dataModelService.loginManagement
      .checkDuplicateAccount(this.email)
      .then(() => {
        if (window.localStorage.getItem('a')) {
          const affiliate = window.localStorage.getItem('a');
          window.localStorage.clear();
          window.sessionStorage.clear();
          window.localStorage.setItem('a', affiliate);
        } else {
          window.localStorage.clear();
          window.sessionStorage.clear();
        }
        window.localStorage.setItem('email', this.email);
      })
      .then(() => this.dataModelService.loginManagement.setAffilliate(true)) // generate affiliateID
      .then(() => {
        window.localStorage.setItem('username', this.dataModelService.dataModel.affiliateID);
        return this.dataModelService.loginManagement.registerUser(
          this.dataModelService.dataModel.affiliateID,
          this.email,
          this.password,
          this.dataModelService.dataModel.affiliateID
        );
      })
      .then(() => this.dataModelService.loginManagement.validateEmailRedirect(this.dataModelService.dataModel.affiliateID))
      .catch(err => {
        this.isLoading = false;
        this.loading.emit(false);
        this.dataModelService.loginManagement.handleError(err);
      });
  }
}
