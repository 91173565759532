import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  constructor() {}
  public body = document.getElementsByTagName('body')[0];

  public toggleMenu = () => {
    if (document.getElementById('menu')) {
      if (document.getElementById('menu').classList.contains('menu-open')) {
        this.forceCloseMenu();
      } else {
        this.forceOpenMenu();
      }
    } else {
      //console.log("no menu loaded");
    }
  };

  public getMenuStatus = () => {
    document.getElementById('menu');
  };

  public forceOpenMenu = () => {
    document.getElementById('menu').classList.add('menu-open');
    document.getElementById('menu').classList.remove('menu-closed');
    document.getElementById('app').style.overflowY = 'hidden';
  };

  public forceCloseMenu = () => {
    document.getElementById('menu').classList.add('menu-closed');
    document.getElementById('menu').classList.remove('menu-open');
    document.getElementById('app').style.overflowY = 'scroll';
  };

  public ngOnInit() {}
}
