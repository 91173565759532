// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  mode: 'staging',
  FacebookAppID: '197964914044798',
  AWSRegion: 'us-east-1',
  AWSUserPoolId: 'us-east-1_NH6fdYNWJ',
  AWSClientId: '191cg04cc7457b9q56j3snelr9',
  AWSLoginType: 'cognito-idp.us-east-1.amazonaws.com/us-east-1_NH6fdYNWJ',
  AWSIdentityPoolId: 'us-east-1:edb6b2de-0d69-46d0-9b69-9524d02dc06e',
  IntercomID: 'elib46tc',
  bucketName: 'stagingpictures2', // bucket in Amazon S3

  ga: {
    appName: 'WizeFi',
    version: 1,
    gaId: 'UA-100074821-2'
  },

  braintree: {
    merchantId: '8vtd2pv4wpk6b85x',
    publicKey: 'zbwj3dvxdhrq8gd7',
    privateKey: '75b1a464b9e35433a8d8dd5896e058d5'
  },

  mysql: {
    host: 'localhost',
    user: 'wizefi_adm',
    password: 'Hg6R1^TGMl1X2^2O5x2W',
    database: 'PAYOUTS'
  }
};
