import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { IGraphItem } from '../../../interfaces/iGraphItem.interface';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { NetWorthProjectionComponent } from '../net-worth-projection/net-worth-projection.component';

@Component({
  selector: 'app-wizefi-ffa',
  templateUrl: './wizefi-ffa.component.html',
  styleUrls: ['./wizefi-ffa.component.scss']
})
export class WizefiFFAComponent extends NetWorthProjectionComponent {
  public datasets: Array<{ data: IGraphItem[]; color: string }>;
  public isLoading = true;
  private newPlanName: string;
  private copyOfOrigPlan;

  constructor(protected dataModelService: DataModelService, protected router: Router) {
    super(dataModelService);
  }

  public async ngOnInit() {
    this.saveCopyOfOrigPlan();
    this.copyActual2PlannedForOrigPlan();
    super.ngOnInit();
    this.setChartDatasets();
    await this.createNewCurrentPlan();
    this.copyPlanned2ActualForNewPlan(); // needed because actualMonthlyAmounts are not copied over on data-management's changeCurrentPlan function
    this.applySavedPlannedAmountsToCurrentPlan();
    this.setupPrerequisiteData();
    this.initWizeFiProjectionData();
    this.initCurrentProjectionData();
    this.initOriginalProjectionData();
    this.calculateNetWorthAges();
    this.calculateDebtAges();
    this.truncateDebtData();
    this.truncateNetWorthData();
    this.logNetWorthData();
    this.isLoading = false;
  }

  private saveCopyOfOrigPlan() {
    this.copyOfOrigPlan = _.cloneDeep(this.dataModelService.dataModel.persistent.plans.original);
  }

  /**
   * Copies all of the actualMonthlyAmount values for the original plan into monthlyAmount values.
   * This allows us to use the correct projection functions in the cafr-management class to show
   * original projections based on actual spending habits.
   */
  private copyActual2PlannedForOrigPlan() {
    this.dataModelService.visitAllWizeFiAccounts('original', this.copyActualToPlanned, {});
  }

  private setChartDatasets() {
    this.datasets = [
      { data: this.wizeFiFFData, color: this.purple },
      { data: this.originalFFData, color: this.black }
    ];
  }

  private copyActualToPlanned(plan, category, subcategory, acntndx, account, result) {
    if (account && account.hasOwnProperty('actualMonthlyAmount') && category === 'budget' && account.hasOwnProperty('monthlyAmount')) {
      //account.monthlyAmount.val = account.actualMonthlyAmount.val;
    }
  }

  /**
   * Creates a new plan that will be used for generating the "current" plan projections throughout
   * the rest of the setup flow.
   */
  private async createNewCurrentPlan() {
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    this.newPlanName = this.dataModelService.dataManagement.makePlanName(year, month);
    await this.dataModelService.dataManagement.changeCurrentPlan(this.newPlanName);
    this.dataModelService.setCurPlan(this.newPlanName);
  }

  private copyPlanned2ActualForNewPlan() {
    this.dataModelService.visitAllWizeFiAccounts(this.newPlanName, this.copyPlannedToActual, {});
  }

  private copyPlannedToActual(plan, category, subcategory, acntndx, account, result) {
    if (account && account.hasOwnProperty('actualMonthlyAmount') && category === 'budget' && account.hasOwnProperty('monthlyAmount')) {
      account.actualMonthlyAmount.val = account.monthlyAmount.val;
      /* console.log("copying planned to actual", [
        account.monthlyAmount.val,
        category,
        subcategory,
        account
      ]); */
    }
  }

  private applySavedPlannedAmountsToCurrentPlan() {
    this.dataModelService.visitAllWizeFiAccounts(this.newPlanName, this.applySavedMonthlyAmountAndMonthlyMinimum, {});
  }

  private applySavedMonthlyAmountAndMonthlyMinimum = (plan, category, subcategory, acntndx, account, result) => {
    if (account && account.hasOwnProperty('monthlyAmount')) {
      const acct = this.copyOfOrigPlan[category][subcategory].accounts[acntndx];
      if (acct) {
        const savedMonthlyAmount = acct.monthlyAmount;
        account.monthlyAmount = savedMonthlyAmount;
      }
    }
    if (account && account.hasOwnProperty('monthlyMinimum')) {
      const acct = this.copyOfOrigPlan[category][subcategory].accounts[acntndx];
      if (acct) {
        const savedMonthlyMinimum = acct.monthlyMinimum;
        account.monthlyMinimum = savedMonthlyMinimum;
      }
    }
  };
}
