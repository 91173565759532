import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DataModelService } from '../../../services/data-model/data-model.service';

@Component({
  selector: 'app-account-section',
  templateUrl: './account-section.component.html',
  styleUrls: ['./account-section.component.scss']
})
export class AccountSectionComponent implements OnInit {
  @Output() public onValueChange: EventEmitter<any> = new EventEmitter<any>();
  // @Input() public accounts: any[];
  constructor(protected dataModelService: DataModelService, protected router: Router) {}

  public async ngOnInit() {
    //console.log("init");
    // await this.getInstitutions();
    // await this.updatePlaidData();
    // console.log(this.accounts);
    // console.log(this.institutions);
    // const title = dataManagement.getDraftTitle();
    // await this.dataModelService.plaidManagement.updateAllBalances(this.dataModelService.dataModel.global.wizeFiID, title);
  }

  public checkhash(hashString) {
    if (window.location.hash === hashString) {
      //console.log(hashString);
      return true;
    }
  }
}
