import { Component, OnInit } from '@angular/core';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { ProjectionChartComponent } from '../projection-chart/projection-chart.component';

@Component({
  selector: 'app-debt-projection-chart',
  templateUrl: '../projection-chart/projection-chart.component.html',
  styleUrls: ['../projection-chart/projection-chart.component.scss']
})
export class DebtProjectionChartComponent extends ProjectionChartComponent {
  constructor(dataModelService: DataModelService) {
    super(dataModelService);
  }

  public ngOnInit() {
    this.setMaxInputData();
    this.setChartDurationInMonths();
    this.addXAxisLabels();
    this.addDottedLineAtMaxIfNeeded();
    this.tuneFinalDataPoints();
    this.addDatasetsToChart();
    this.setLineChartOptions();
    // console.log("DEBT CHART DATA:", this.lineChartData);
  }
}
