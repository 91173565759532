import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';

import { DataModelService } from '../../services/data-model/data-model.service';
import { SetupService } from '@app/services/setup.service';

@Component({
  selector: 'app-relogin',
  templateUrl: './relogin.component.html',
  styleUrls: ['./relogin.component.css']
})
export class ReloginComponent implements OnInit {
  constructor(private router: Router, private dataModelService: DataModelService, private setupService: SetupService) {}

  protected isLoading: boolean;
  public messages: any;

  protected setupIntercom() {
    return (window as any).Intercom('boot', {
      app_id: environment.IntercomID,
      user_id: this.dataModelService.dataModel.global.wizeFiID,
      email: this.dataModelService.dataModel.persistent.profile.email,
      name: this.dataModelService.dataModel.persistent.profile.nameFirst + ' ' + this.dataModelService.dataModel.persistent.profile.nameLast
    });
  } // setupIntercom

  public moveToNextScreen(url) {
    // TODO determine whether checking below for braintreeData.subscriptionInfo.status === 'Active' should instead be one of the following:
    // braintreeData.haveWizeFiPlanSubscription
    // braintreeData.isActive  (status === 'Active' or paidThroughDate >= curdate [canceled subscription is still in effect until beyond paidThroughDate])
    // braintreeData.isActive || braintreeData.isInTrialPeriod
    // NOTE: where should we redirect someone with braintreeData.subscriptionInfo.status === 'Past Due'?
    if (this.dataModelService.dataModel.global.braintreeData.subscriptionInfo.status === 'Active') {
      return this.router.navigateByUrl(url);
    } else {
      if (this.dataModelService.dataModel.persistent.header.dateProfileCompleted === '') {
        return this.router.navigateByUrl(url);
      } else {
        this.dataModelService.showErrorMessage('Your subscription is no longer active.', 7000);
        return this.router.navigateByUrl('/subscription');
      }
    }
  }

  public async setOperatingEnvironment() {
    this.isLoading = true;

    try {
      let url: string;

      if (window.sessionStorage.getItem('reloginUrl') === null || window.sessionStorage.getItem('logins') === null) {
        url = '/login';
      } else {
        // set url
        url = window.sessionStorage.getItem('reloginUrl');

        // obtain login credentials
        const logins = JSON.parse(window.sessionStorage.getItem('logins'));
        await this.dataModelService.loginManagement.establishAWSobjects(logins);

        // obtain all necessary data
        this.messages.push('fetch WizeFi data');
        await this.dataModelService.dataManagement.fetchinfo();

        // obtain draftsInfo data
        this.messages.push('fetch draftsInfo data');
        const draftsInfo = this.dataModelService.draftService.loadDraftsInfo();

        this.messages.push('fetch Braintree data');
        const braintreeData = this.dataModelService.getBraintreeData();

        this.messages.push('fetch Plaid data');
        const plaidData = this.dataModelService.plaidManagement.getPlaidData();

        this.messages.push('initialize affiliate tree');
        const affiliateInitialization = this.dataModelService.initializeAffiliateManagement();

        this.messages.push('initialize AncillaryData');
        const ancillaryData = this.dataModelService.ancillaryDataManagement.loadAncillaryData();
        this.messages.push('initialize SuggestionData');
        const suggestionData = this.dataModelService.suggestionManagement.loadSuggestionData();

        // Intercom Init (must wait on this until data is present)
        const intercom = this.setupIntercom();

        await Promise.all([draftsInfo, braintreeData, plaidData, affiliateInitialization, ancillaryData, suggestionData, intercom]);
      }

      if (!this.setupService.hasCompletedSetup()) {
        await this.dataModelService.loginManagement.redirect();
        return;
      }
      // advance to proper screen
      this.moveToNextScreen(url);
    } catch (e) {
      this.router.navigateByUrl('/login');
    }

    this.isLoading = false;
  }

  public ngOnInit() {
    this.isLoading = false;
    this.messages = [];

    // attempt to reestablish a previous session
    this.dataModelService.dataModel.global.isLoggedIn = true;

    // get information from sessionStorage
    this.dataModelService.dataModel.global.wizeFiID = window.sessionStorage.getItem('wizeFiID');
    this.dataModelService.dataModel.global.isFBLogin = window.sessionStorage.getItem('isFBLogin') === '1' ? true : false;

    this.setOperatingEnvironment();
  }
}
