import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IHeader } from '../../../interfaces/iHeader.interface';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { CProfile } from '../../../utilities/profile.class';
import { CSettings } from '../settings.class';

declare const braintree: any;
@Component({
  selector: 'app-settings-details',
  templateUrl: './settings-details.component.html',
  styleUrls: ['./settings-details.component.scss']
})
export class SettingsDetailsComponent implements OnInit {
  public cSettings: CSettings;
  public cProfile: CProfile;
  public headerData: IHeader;
  public addNew = false;
  public messages: string[] = [];
  public isSubscribed: boolean;
  public screenState: string;
  public alias: string;
  public fee: number;
  public referral: string;
  public referralAID: string;
  public paymentDate: any;
  public paymentMethod: string;
  public paymentMethods: any;
  public payoutEmail: string;
  public braintreeData;

  public nameFirst: string;
  public nameLast: string;
  public email: string;
  public password: string;
  protected oldPassword: string;
  public confirmedPassword: string;
  public updateEmail = false;
  public updatePassword = false;
  public updateSubscriptionFlag = false;
  public cardNumber;
  public expDate;

  public isUpdatingSubscription = false;

  private braintreeWidgetInstance: {
    requestPaymentMethod: (arg0: (requestPaymentMethodErr: any, noncePayload: any) => void) => void;
  };

  private wizeFiID = this.dataModelService.dataModel.global.wizeFiID;

  constructor(public dataModelService: DataModelService, protected router: Router) {}

  public ngOnInit() {
    this.braintreeData = this.dataModelService.dataModel.global.braintreeData;
    this.headerData = this.dataModelService.dataModel.persistent.header;
    this.cSettings = new CSettings(this.dataModelService.getdata('settings'));
    this.cProfile = new CProfile(this.dataModelService.getdata('profile'));
    this.listPaymentMethods();
  }

  public setScreenState(action = null) {
    switch (action) {
      case 'updatePaymentMethod':
        this.screenState = 'update';
        this.addNew = false;
        break;
      case 'init':
      default:
        this.screenState = this.isSubscribed ? 'subscribed' : 'unsubscribed';
        this.addNew = false;
        break;
    }
  }

  public async establishPaymentControl(container: string) {
    // sample credit card number: 4111111111111111
    try {
      const clientToken = await this.dataModelService.braintreeManagement.generateClientToken();
      await this.createBrainTreeWidget(clientToken, container);
    } catch (err) {
      console.error('Error in establishing payment method widget', err);
      this.dataModelService.showErrorMessage('Error in establishing payment method widget', 20000);
    }
  }

  private async createBrainTreeWidget(clientToken: string, container: string) {
    const instance = await braintree.dropin.create({ authorization: clientToken, container });
    this.braintreeWidgetInstance = instance;
  }

  public saveBraintreeSubscription() {
    this.braintreeWidgetInstance.requestPaymentMethod(async (requestPaymentMethodErr: any, noncePayload: any) => {
      if (requestPaymentMethodErr) {
        return;
      }
      try {
        this.isUpdatingSubscription = true;
        await this.updateOrEstablishSubscription(noncePayload);
      } catch (err) {
        console.error(err);
        this.dataModelService.showErrorMessage('Update payment method failed.', 20000);
      }
      this.isUpdatingSubscription = false;
    });
  }

  private async updateOrEstablishSubscription(noncePayload: any) {
    if (this.dataModelService.dataModel.global.braintreeData.subscriptionInfo.subscriptionID) {
      await this.updatePaymentMethod(noncePayload);
      await this.updateSubscription();
      this.dataModelService.showMessage('success', 'Payment method updated', 20000);
      this.ngOnInit();
      this.closeSubscriptionEdit();
    } else {
      this.establishSubscription(noncePayload);
    }
  }

  public establishSubscription(noncePayload: { nonce: any }) {
    const makeSubscription = (funcNoncePayload: any) => {
      const newSubscriptionInfo: any = {
        wizeFiID: this.wizeFiID,
        firstName: this.dataModelService.dataModel.persistent.profile.nameFirst,
        lastName: this.dataModelService.dataModel.persistent.profile.nameLast,
        // email for receiving notices about any subscription issues @TODO offer user input for setting distinct emaill specifically for their subscription account?
        email: this.dataModelService.dataModel.persistent.profile.email,
        noncePayload: funcNoncePayload,
        planId: 'WizeFiPlan'
      };

      // 'aaaaaaa' = WizeFi i.e. there was no affiliate referral
      if (this.enableTrial()) {
        newSubscriptionInfo.trialPeriod = true;
        newSubscriptionInfo.trialDuration = 30;
        newSubscriptionInfo.trialDurationUnit = 'day';
      }

      return this.dataModelService.braintreeManagement.establishSubscription(newSubscriptionInfo);
    }; // makeSubscription

    const finish = (payloadOut: { errorMessage: string }): void => {
      if (payloadOut.errorMessage) {
        this.dataModelService.showMessage('error', payloadOut.errorMessage, 20000);
      } else {
        if (this.dataModelService.dataModel.persistent.header.dateProfileCompleted === '') {
          const body = document.getElementsByTagName('body')[0];
          body.classList.remove('nav-main-disabled'); // clear nav override
          this.router.navigate(['/setup/connect-bank', {}]);
          this.dataModelService.showMessage('info', 'Subscription has been registered', 20000);
        } else {
          this.dataModelService.showMessage('success', 'Congratulations! Your subscription is now active.', 20000);
          this.ngOnInit();
        }
      }
    };

    if (!this.validateProfile()) {
      return false;
    }

    this.dataModelService.putdata('profile', this.cProfile.profile);
    this.dataModelService.dataManagement
      .storeinfo()
      .then(r => makeSubscription(noncePayload))
      .then(r => this.dataModelService.updateBraintreeData(r)) // refresh Braintree data after subscription change
      .then(finish)
      .catch(err => {
        console.error(err);
        this.dataModelService.showErrorMessage('Subscription failed: ' + err, 10000);
      });
  }

  public cancelSubscription() {
    const processSubscriptionCancel = () => {
      this.dataModelService.showMessage('success', 'Subscription has been canceled', 10000);
      this.ngOnInit();
    }; // processSubscriptionCancel

    const processSubscriptionCancelError = (err: any) => {
      console.error('error in deleting subscription: ', err);
      this.dataModelService.showMessage('info', 'Error in canceling subscription', 20000);
    }; // processSubscriptionCancelError

    if (this.dataModelService.dataModel.global.braintreeData.subscriptionInfo.subscriptionID === '') {
      this.dataModelService.showErrorMessage('There is no subscription in place to be canceled', 20000);
    } else {
      if (confirm('Are you sure you want to cancel your subscription and suspend all affiliate earnings?')) {
        this.dataModelService.braintreeManagement
          .cancelSubscription(this.dataModelService.dataModel.global.braintreeData.subscriptionInfo.subscriptionID)
          .then(r => this.dataModelService.updateBraintreeData(r)) // refresh Braintree data after subscription change
          .then(processSubscriptionCancel)
          .catch(processSubscriptionCancelError);
      }
    }
  }

  public async updateSubscription() {
    const p = {
      subscriptionID: this.dataModelService.dataModel.global.braintreeData.subscriptionInfo.subscriptionID,
      parms: {
        paymentMethodToken: this.paymentMethod
      }
    };
    const brainTreeUpdateResult = await this.dataModelService.braintreeManagement.updateSubscription(p);
    await this.dataModelService.updateBraintreeData(brainTreeUpdateResult);
  }

  public async updatePaymentMethod(noncePayload: { nonce: any }) {
    const p = {
      paymentMethodToken: this.paymentMethod,
      parms: {
        paymentMethodNonce: noncePayload.nonce
      }
    };
    const paymentMethodResult = await this.dataModelService.braintreeManagement.updatePaymentMethod(p);
    const updatedBrainTreeData = (await this.dataModelService.updateBraintreeData(paymentMethodResult)) as any;
    if (updatedBrainTreeData && updatedBrainTreeData.paymentMethod) {
      this.paymentMethod = updatedBrainTreeData.paymentMethod.token;
    }
  }

  public saveAffiliateDetail() {
    return new Promise((resolve, reject) => {
      // do not need putdata validity check here (handled prior to call on update)
      this.dataModelService.dataModel.persistent.header.payoutEmail = this.payoutEmail;
      this.dataModelService.putdata('header', this.dataModelService.dataModel.persistent.header);

      this.dataModelService.dataManagement
        .storeinfo()
        .then(() => {
          resolve();
          this.router.navigateByUrl('/settings#account');
        })
        .catch(err => {
          console.error('Unable to save affiliate information: ', err);
          this.dataModelService.showMessage('error', 'Unable to save affiliate information', 7000);
          reject(err);
        });
    });
  }

  public async listPaymentMethods() {
    this.setScreenState('updatePaymentMethod');

    try {
      const subscriptionsResult = await this.dataModelService.braintreeManagement.listCustomerSubscriptions(this.wizeFiID, true);
      const brainTreeResult = await this.dataModelService.updateBraintreeData(subscriptionsResult); // refresh Braintree data after subscription change
      this.paymentMethod = brainTreeResult[0].paymentMethodToken;
      this.paymentMethods = await this.dataModelService.braintreeManagement.listCustomerPaymentMethods(this.wizeFiID);
    } catch (err) {
      console.error(err);
    }
  }

  public enableTrial() {
    return (
      !this.dataModelService.dataModel.global.braintreeData.haveBraintreeAccount &&
      this.dataModelService.dataModel.persistent.header.parentAffiliateID !== 'aaaaaaa'
    );
  }

  public validateProfile() {
    const messages = [];
    if (!this.cProfile.profile.nameFirst) {
      messages.push('Please enter a value for First Name');
    }

    if (!this.cProfile.profile.nameLast) {
      messages.push('Please enter a value for Last Name');
    }

    // TODO consider whether to use more complex pattern: [-.\w]+@([\w-]+\.)+[\w-]{2,20}, or research yet more complete patterns
    if (!this.cProfile.profile.email.match(/^.+@.+\..+$/)) {
      messages.push('Please enter a valid Email');
    }

    if (messages.length > 0) {
      this.dataModelService.showMessages('error', messages, 5000);
      return false;
    }
    return true;
  }

  public togglePasswordEdit() {
    this.updatePassword = !this.updatePassword;
  }

  public toggleEmailEdit() {
    this.updateEmail = !this.updateEmail;
  }

  public toggleSubscriptionEdit() {
    this.updateSubscriptionFlag = !this.updateSubscriptionFlag;
    if (this.updateSubscriptionFlag) {
      this.establishPaymentControl('#braintree-widget');
    }
  }

  public closeEmailEdit() {
    this.updateEmail = false;
  }

  public closePasswordEdit() {
    this.updatePassword = false;
  }

  public closeSubscriptionEdit() {
    this.updateSubscriptionFlag = false;
  }

  public async saveEmail() {
    await this.dataModelService.loginManagement
      .setUserEmail(this.email)
      .then(succ => {
        this.dataModelService.showMessage('success', 'Your email has changed successfully.', 5000);
      })
      .then(() => {
        this.cProfile.profile.email = this.email;
        this.validateProfile();
        this.dataModelService.putdata('profile', this.cProfile.profile);
        this.dataModelService.dataManagement.storeinfo();
      })
      .then(() => {
        this.closeEmailEdit();
      })
      .catch(err => {
        this.dataModelService.showMessage('error', err, 5000);
      });
    await this.dataModelService.dataManagement.fetchinfo();
    await this.dataModelService.plaidManagement.getPlaidData();
  }

  public checkPassword() {
    if (!this.password || !this.confirmedPassword) {
      this.dataModelService.showMessage('error', 'please enter a new password', 2000);
    } else if (this.password === this.confirmedPassword) {
      return true;
    } else {
      this.dataModelService.showMessage('error', 'your passwords do not match', 2000);
      return false;
    }
  }
  public async savePassword() {
    const checked = this.checkPassword();
    if (checked === true) {
      await this.dataModelService.loginManagement
        .changePassword(this.oldPassword, this.password)
        .then(succ => {
          this.dataModelService.showMessage('success', 'Your password has changed successfully.', 5000);
          this.closePasswordEdit();
        })
        .catch(err => {
          this.dataModelService.showMessage('error', err, 5000);
        });
    } else {
      this.dataModelService.showMessage('error', 'Your password failed to change.', 5000);
    }
    await this.dataModelService.dataManagement.fetchinfo();
    await this.dataModelService.plaidManagement.getPlaidData();
  }
}
