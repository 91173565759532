import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-dropdown',
  templateUrl: './info-dropdown.component.html',
  styleUrls: ['./info-dropdown.component.scss']
})
export class InfoDropdownComponent implements OnInit {
  constructor() {}

  public ngOnInit() {}
}
