import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { CProfile } from '../../../utilities/profile.class';
import { CSettings } from '../settings.class';
import { MatDialog } from '@angular/material';
import { DialogDisplayDraftsComponent } from '@app/components/dialog-display-drafts/dialog-display-drafts.component';

@Component({
  selector: 'app-settings-profile',
  templateUrl: './settings-profile.component.html',
  styleUrls: ['./settings-profile.component.scss']
})
export class SettingsProfileComponent implements OnInit {
  public cSettings: CSettings;
  public cProfile: CProfile;
  private DEFAULT_GS_TARGET_AMOUNT = 15000;
  private DEFAULT_ES_TARGET_AMOUNT = 2000;
  public accounts;
  public hostname = window.location.hostname;
  protected gsTarget;
  protected esTarget;
  constructor(public dataModelService: DataModelService, protected router: Router, private dialog: MatDialog) {}

  public ngOnInit() {
    this.accounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    this.cSettings = new CSettings(this.dataModelService.getdata('settings'));
    this.cProfile = new CProfile(this.dataModelService.getdata('profile'));
    this.gsTarget = this.setInitialGeneralSavingsTarget(this.dataModelService.dataModel.persistent.profile);
    this.esTarget = this.setInitialEmergencySavingsTarget(this.dataModelService.dataModel.persistent.profile);
  }

  protected openProfileEditor() {
    this.dialog.open(DialogDisplayDraftsComponent, { data: 'profile' });
  }

  private setInitialEmergencySavingsTarget(profileData) {
    const ESAccounts = [];
    let targetAmount;
    if (this.accounts) {
      this.accounts.forEach(element => {
        if (element && element.targetAmount && element.wizeFiCategory.includes('emergencySavings')) {
          ESAccounts.push(element);
        }
      });
    }
    const targetAmountFromAccounts = ESAccounts.reduce((a, b) => a + (b.targetAmount ? b.targetAmount.val : 0), 0);
    if (targetAmountFromAccounts === 0) {
      targetAmount = this.DEFAULT_ES_TARGET_AMOUNT;
    } else {
      targetAmount = targetAmountFromAccounts;
    }
    return targetAmount;
  }

  private setInitialGeneralSavingsTarget(profileData) {
    const GSAccounts = [];
    let targetAmount;
    if (this.accounts) {
      this.accounts.forEach(element => {
        if (element && element.targetAmount && element.wizeFiCategory.includes('cashReserves')) {
          GSAccounts.push(element);
        }
      });
    }
    const targetAmountFromAccounts = GSAccounts.reduce((a, b) => a + (b.targetAmount ? b.targetAmount.val : 0), 0);
    if (targetAmountFromAccounts === 0) {
      targetAmount = this.DEFAULT_GS_TARGET_AMOUNT;
    } else {
      targetAmount = targetAmountFromAccounts;
    }
    return targetAmount;
  }
}
