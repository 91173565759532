import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { DataModelService } from '../services/data-model/data-model.service';

declare const AWS: any;

@Injectable()
export class SecureScreenGuard implements CanActivate {
  constructor(private router: Router, private dataModelService: DataModelService) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const canActivate0 = async () => {
        let canProceed = false;

        if (this.dataModelService.dataModel.global.isLoggedIn || state.url === '/login') {
          canProceed = true;

          const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
          const secureScreen = state.url.substr(1); // note: need to remove leading slash from state.url
          canProceed = await this.dataModelService.screenDataManagement.hasScreenAccess(wizeFiID, secureScreen);

          if (!canProceed) {
            console.log('not authorized to view screen: ' + secureScreen); // %//
            // the following message is failing to appear
            this.dataModelService.showMessage('error', 'not authorized to view screen: ' + secureScreen, 10000);
          }
        } else if (!window.sessionStorage.getItem('logins') || !window.sessionStorage.getItem('wizeFiID')) {
          this.router.navigateByUrl('/login');
          canProceed = false;
        } else {
          window.sessionStorage.setItem('reloginUrl', state.url);
          this.router.navigateByUrl('/relogin');
          canProceed = false;
        }

        return canProceed;
      }; // let canActivate0

      canActivate0()
        .then(canProceed => {
          resolve(canProceed);
        })
        .catch(err => {
          console.log('error in SecureScreenGuard.canActivate: ', err);
          reject(err);
        });
    }); // return Promise
  } // canActivate
} // class SecureScreenGuard
