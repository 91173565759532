import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataModelService } from '../../../../services/data-model/data-model.service';
@Component({
  selector: 'app-debt-accounts',
  templateUrl: './debt-accounts.component.html',
  styleUrls: ['./debt-accounts.component.scss']
})
export class DebtAccountsComponent implements OnInit {
  public accounts: any[];
  public institutions: any[];
  public plaidInstitution: any;
  constructor(protected dataModelService: DataModelService, protected router: Router) {}
  public async ngOnInit() {
    //console.log("oninit");
    await this.updatePlaidData();
  }

  private updatePlaidData() {
    this.institutions = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions;
    this.accounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
  }

  protected getInstitutionAccounts(institution) {
    return this.accounts.filter(acct => acct.item_id === institution.item_id && acct.wizeFiCategory.indexOf('liabilities_') !== -1);
  }
}
