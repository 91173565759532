import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataModelService } from '../../../services/data-model/data-model.service';

@Component({
  selector: 'app-four-step-plan',
  templateUrl: './four-step-plan.component.html',
  styleUrls: ['./four-step-plan.component.scss']
})
export class FourStepPlanComponent implements OnInit {
  constructor(protected dataModelService: DataModelService, protected router: Router) {}

  public ngOnInit() {}

  public onNext() {
    this.router.navigate(['/apply-cash-difference']);
  }
}
