import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

import { MatDialogModule, MatNativeDateModule } from '@angular/material';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ToastyModule } from 'ng2-toasty';
import { AppComponent } from '../components/app/app.component';
import { NotFoundComponent } from '../components/not-found/not-found.component';
import { ReloginComponent } from '../components/relogin/relogin.component';
import { ReportBugComponent } from '../components/settings/change-log/report-bug/report-bug.component';
import { SuggestFeatureComponent } from '../components/settings/change-log/suggest-feature/suggest-feature.component';
import { SettingsDeactivateGuard } from '../components/settings/settings-deactivate.guard';
import { FloorPipe } from '../pipes/pipe-floor.class'; // Math.floor
import { ValuesPipe } from '../pipes/pipe-values.class'; // add keys to objects for ngFor iteration
import { AccountService } from '../services/account/account.service';
import { DataModelService } from '../services/data-model/data-model.service';
import { LogMessageService } from '../services/log-message/log-message.service';
import { CustomErrorHandler } from '../utilities/custom-error-handler.class';
import { DataStorage } from '../utilities/data-storage.class';
import { NgIfMediaQuery } from '../utilities/media-query.directive';
import { ScreenLoginGuard } from '../utilities/screen-login.guard';
import { SecureScreenGuard } from '../utilities/secure-screen.guard';
import { SharedModule } from '../shared/shared.module';
import { GoComponent } from './../components/go/go.component';
import { AdminModule } from './admin.module';
import { DashboardModule } from './dashboard.module';
import { AppRoutingModule } from './routing.module';
import { SetupModule } from './setup.module';
import { SignupModule } from './signup.module';
import { StructureModule } from './structure.module';
import { NewAccountDialogComponent } from '@app/components/new-account-dialog/new-account-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    ReloginComponent,
    ValuesPipe,
    FloorPipe,
    NgIfMediaQuery,
    NotFoundComponent,
    GoComponent,
    // example components
    SuggestFeatureComponent,
    ReportBugComponent,
    NewAccountDialogComponent
  ],
  imports: [
    BrowserModule,
    // ChartsModule,
    // Instrumentation must be imported after importing StoreModule (config is optional)
    StoreDevtoolsModule.instrument(),
    CurrencyMaskModule,
    FormsModule,
    AppRoutingModule,
    ChartsModule,
    ToastyModule.forRoot(),
    SignupModule,
    DashboardModule,
    SetupModule,
    AdminModule,
    StructureModule,
    NoopAnimationsModule,
    MatNativeDateModule,
    MatDialogModule,
    SharedModule
  ],
  exports: [],
  providers: [
    DataModelService,
    DataStorage,
    LogMessageService,
    SettingsDeactivateGuard,
    ScreenLoginGuard,
    SecureScreenGuard,
    AccountService,
    ThemeService,
    // { provide: ErrorHandler, useClass: CustomErrorHandler }, // this leads to error: Cannot instantiate cyclic dependency
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler
    }
  ],
  entryComponents: [SuggestFeatureComponent, ReportBugComponent, NewAccountDialogComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
