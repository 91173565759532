/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AwsDynamoDbService {
  public dynamodbClient: any;

  public constructor() {}

  put(table: string, item: any): Promise<DefaultDynamodbResult> {
    return this.dynamodbClient
      .put({
        TableName: table,
        Item: item
      })
      .promise();
  }

  get<T = any>(table: string, key: string | Record<string, string>): Promise<GetResult<T>> {
    return this.dynamodbClient
      .get({
        TableName: table,
        Key: key
      })
      .promise();
  }

  query<T = any>(
    table: string,
    keyConditionExpression: string,
    expressionAttributeNames: Record<string, string>,
    expressionAttributeValues: Record<string, string>
  ): Promise<QueryResult<T>> {
    return this.dynamodbClient
      .query({
        TableName: table,
        KeyConditionExpression: keyConditionExpression,
        ExpressionAttributeNames: expressionAttributeNames,
        ExpressionAttributeValues: expressionAttributeValues
      })
      .promise();
  }

  delete(table: string, key: string | Record<string, string>): Promise<DefaultDynamodbResult> {
    return this.dynamodbClient
      .delete({
        TableName: table,
        Key: key
      })
      .promise();
  }
}

export interface GetResult<T> {
  Item: T;
  ConsumedCapacity: ConsumedCapacityInformation;
}

export interface QueryResult<T> {
  Items: T;
  Count: number;
  ScannedCount: number;
  LastEvaluatedKey: any;
  ConsumedCapacity: ConsumedCapacityInformation;
}

export interface ConsumedCapacityInformation {
  TableName: string;
  CapacityUnits: number;
  ReadCapacityUnits: number;
  WriteCapacityUnits: number;
  Table: CapacityUnitsInformation;
  LocalSecondaryIndexes: CapacityUnitsInformation;
  GlobalSecondaryIndexes: CapacityUnitsInformation;
}

export interface CapacityUnitsInformation {
  CapacityUnits: number;
  ReadCapacityUnits: number;
  WriteCapacityUnits: number;
}

export interface DefaultDynamodbResult {
  Attributes: any;
  ConsumedCapacity: ConsumedCapacityInformation;
  ItemCollectionMetrics: {
    ItemCollectionKey: any;
    SizeEstimateRangeGB: number[];
  };
}
