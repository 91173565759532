import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SetupFlowDataManagementService } from 'src/app/services/setup/setup-flow-data-management.service';
import { DataModelService } from '../../services/data-model/data-model.service';
import { AccountsPageComponent } from '../accounts-page/accounts-page.component';
import { ApplyCashDifferenceComponent } from '../setup-flow/apply-cash-difference/apply-cash-difference.component';
import { GoalsComponent } from '../setup-flow/goals-screen/goals/goals.component';
import { IncomeComponent } from '../setup-flow/income-screen/income/income.component';
import { ReallocateSpendingComponent } from '../setup-flow/reallocate-spending/reallocate-spending.component';

@Component({
  selector: 'app-edit-cafr',
  templateUrl: './edit-cafr.component.html',
  styleUrls: ['./edit-cafr.component.scss']
})
export class EditCAFRComponent implements OnInit, OnDestroy {
  @ViewChild('accounts', { static: false }) public accounts: AccountsPageComponent;
  @ViewChild('income', { static: false }) public income: IncomeComponent;
  @ViewChild('expenses', { static: false }) public expenses: ReallocateSpendingComponent;
  @ViewChild('fourStepPlan', { static: false }) public fourStepPlan: ApplyCashDifferenceComponent;
  @ViewChild('profile', { static: false }) public profile: GoalsComponent;
  public curplan = this.dataModelService.dataModel.persistent.header.curplan;

  constructor(protected dataModelService: DataModelService, protected router: Router, protected dataManagement: SetupFlowDataManagementService) {}

  public ngOnInit() {
    this.setCustomStylingForEditScreens();
    this.dataModelService.cafrManagement.setBeforePlanEditCafrDataProjectionInfo(
      {
        terminationType: 'financialFreedomTargetAmount',
        terminationValue: this.dataModelService.dataModel.persistent.profile.financialFreedomTargetAmount
      },
      'actual'
    );
  }

  private setCustomStylingForEditScreens() {
    document.getElementById('page-content').style.padding = '0';
    document.getElementsByClassName('page-content')[0].className += ' edit-page-content';
    document.getElementById('page-content').style['overflow-x'] = 'hidden';
    document.getElementById('sidebar').style.display = 'none';
  }

  protected onSave(): boolean {
    switch (window.location.hash) {
      case '#accounts':
        break;
      case '#income':
        this.income.onSave();
        break;
      case '#expenses':
        this.expenses.onSave();
        break;
      case '#four-step-plan':
        const canSave = this.fourStepPlan.onSave();
        if (!canSave) {
          return false;
        }
        break;
      case '#profile':
        this.profile.onSave();
        break;
      default:
        this.income.onSave();
        break;
    }
    return true;
  }

  public ngOnDestroy(): void {
    this.removeCustomStylingForEditScreens();
  }

  private removeCustomStylingForEditScreens() {
    document.getElementById('page-content').style.backgroundColor = '';
    document.getElementById('page-content').style['overflow-x'] = '';
    document.getElementById('sidebar').style.display = '';
    document.getElementsByClassName('page-content')[0].classList.remove('edit-page-content');
  }

  public async onCancelChanges() {
    await this.dataModelService.dataManagement.fetchdata();

    if (this.router.url === '/plan/edit-CAFR#accounts') {
      return this.router.navigate(['/accounts'], { fragment: 'all' });
    }
    if (this.router.url === '/plan/edit-CAFR#profile') {
      return this.router.navigate(['/settings']);
    }
    if (this.router.url === '/plan/edit-CAFR#income') {
      return this.router.navigate(['/plan'], { fragment: 'income' });
    }
    if (this.router.url === '/plan/edit-CAFR#expenses') {
      return this.router.navigate(['/plan'], { fragment: 'expenses' });
    }
    if (this.router.url === '/plan/edit-CAFR#four-step-plan') {
      return this.router.navigate(['/plan'], { fragment: 'plan' });
    }
    this.router.navigate(['/plan']);
  }

  public save() {
    const canGo = this.onSave();
    if (!canGo) {
      this.dataModelService.showErrorMessage('You have applied more CAFR than is available. Please adjust values accordingly.', 3000);
    } else {
      this.router.navigate(['/plan/review-projections']);
    }
  }
}
