import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { PlaidInstitution } from '../../interfaces/plaid-institution';
import { ITransactionRule } from '../../interfaces/iTransactionRule.interface';
import { WizefiTransaction } from '../../interfaces/wizefi-transaction';
import { AccountService } from '../../services/account/account.service';
import { CategoryService } from '../../services/category/category.service';
import { DataModelService } from '../../services/data-model/data-model.service';
import { TransactionRuleService } from '../../services/transaction-rule/transaction-rule.service';

@Component({
  selector: 'app-transaction-rule-dialog',
  templateUrl: './transaction-rule-dialog.component.html',
  styleUrls: ['./transaction-rule-dialog.component.scss']
})
export class TransactionRuleDialogComponent {
  public isNewRule: boolean;
  public institutions: PlaidInstitution[];
  public isMerchantSelected = false;
  public isInstitutionSelected = false;
  public isAmountSelected = false;
  public selectedType: string;
  public selectedCategory: string;
  public selectedSubcategory: string;
  selectedSubcategoryLabel = undefined;
  showCreateNewSubCategory = false;
  createNewSubCategoryForIndex = null;
  splitTransactionInfo;
  accountLabels = [];
  accounts;
  selectedAccount;

  constructor(
    public dialogRef: MatDialogRef<TransactionRuleDialogComponent, ITransactionRule | boolean>,
    @Inject(DOCUMENT) private document: Document,
    @Inject(MAT_DIALOG_DATA) public data: ITransactionRuleDialogData,
    public accountService: AccountService,
    public categoryService: CategoryService,
    private transactionRuleService: TransactionRuleService,
    private dataModelService: DataModelService
  ) {
    this.isNewRule = this.data.rule === undefined;

    this.isMerchantSelected = this.data.rule && this.data.rule.attributePattern.merchantName !== undefined;
    this.isInstitutionSelected = this.data.rule && this.data.rule.attributePattern.institutionName !== undefined;

    this.isAmountSelected = this.data.rule && this.data.rule.attributePattern.amount !== undefined;
    this.data.rule = this.data.rule || {
      wizeFiCategory: this.data.transaction.wizeFiCategory,
      source_id: this.data.transaction.transaction_id,
      isActive: true,
      isDefaultRule: false,
      attributePattern: {
        merchantName: this.data.transaction.merchantName,
        institutionName: this.data.transaction.institutionName,
        amount: this.data.transaction.amount
      }
    };

    if (!this.data.transaction) {
      this.transactionRuleService.getRuleTransaction(this.data.rule).subscribe(transaction => (this.data.transaction = transaction));
    }

    this.selectedType = this.categoryService.getTypeFromDbCategoryFormat(this.data.rule.wizeFiCategory);
    this.selectedType = this.selectedType === undefined ? 'budget' : this.selectedType;
    if (data.selectedType) {
      this.selectedType = data.selectedType;
    }

    if (data.selectedSubcategory) {
      this.selectedCategory = data.selectedSubcategory;
    } else {
      this.selectedCategory = this.categoryService.getCategoryFromDbCategoryFormat(this.data.rule.wizeFiCategory);
    }

    if (data.selectedAccount) {
      this.selectedSubcategory = data.selectedAccount;
    } else {
      this.selectedSubcategory = this.categoryService.getSubcategoryFromDbCategoryFormat(this.data.rule.wizeFiCategory);
    }

    this.institutions = dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions;

    const account = Object.keys(dataModelService.dataModel.global.plaidData.wizeFiTransactionAttributePatterns).map(
      account => dataModelService.dataModel.global.plaidData.wizeFiTransactionAttributePatterns[account].attributePattern.accountName
    );

    this.accounts = account.filter((v, i) => account.indexOf(v) === i);
    if (this.selectedCategory === undefined) {
      this.selectCategory(this.categoryService.getCategoryLabel(this.selectedType, this.selectedCategory));
    }
    this.selectInstitutionByName(this.data.rule.attributePattern.institutionName);
    console.log(this.isNewRule);
  }

  public toggleRule() {
    this.data.rule.isActive = !this.data.rule.isActive;
  }

  public getCategoryData(categorization, request) {
    const type = this.categoryService.getTypeFromDbCategoryFormat(categorization);
    const category = this.categoryService.getCategoryFromDbCategoryFormat(categorization);
    const subcategory = this.categoryService.getSubcategoryFromDbCategoryFormat(categorization);
    if (request === 'type') {
      return this.categoryService.getTypeLabel(type);
    }
    if (request === 'category') {
      return this.categoryService.getCategoryLabel(type, category);
    }
    if (request === 'subcategory') {
      return this.categoryService.getSubcategoryLabel(type, category, subcategory, true);
    }
  }

  public copyMerchantToClipboard(merchantName: string): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = merchantName;
    this.document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    this.document.execCommand('copy');
    this.document.body.removeChild(selBox);
  }

  public selectType(type: string): void {
    if (type !== this.selectedType) {
      this.selectedType = type;
      this.selectedCategory = undefined;
      this.selectedSubcategory = undefined;
      this.selectedSubcategoryLabel = undefined;
    }
  }

  public selectCategory(category: string): void {
    if (category !== this.selectedCategory) {
      this.selectedCategory = category;
      this.selectedSubcategory = undefined;
      this.selectedSubcategoryLabel = undefined;
    }
  }

  public selectInstitutionByName(institutionName: string) {
    if (institutionName !== 'Manual Accounts') {
      const accounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
      const mappedAccounts = [];
      accounts.map(account => {
        if (account.institutionName === institutionName && account.isManual === false && account.status === '1') {
          mappedAccounts.push(account.accountName);
        }
      });
      this.accounts = mappedAccounts;
    } else {
      const manualAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
      const filteredAccounts = manualAccounts.filter(account => account.isManual === true);
      const filteredAccountNames = [];
      filteredAccounts.map(account => {
        filteredAccountNames.push(account.accountName);
      });
      this.accounts = filteredAccountNames;
    }
  }

  public async selectInstitution(institution: PlaidInstitution) {
    this.data.rule.attributePattern.institutionName = institution.institutionName;
    if (institution.institutionName !== 'Manual Accounts') {
      const accounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
      const mappedAccounts = [];
      accounts.map(account => {
        if (account.institutionName === institution.institutionName && account.isManual === false && account.status === '1') {
          mappedAccounts.push(account.accountName);
        }
      });
      this.accounts = mappedAccounts;
    } else {
      const manualAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
      const filteredAccounts = manualAccounts.filter(account => account.isManual === true);
      const filteredAccountNames = [];
      filteredAccounts.map(account => {
        filteredAccountNames.push(account.accountName);
      });
      this.accounts = filteredAccountNames;
    }
  }

  public selectAccount(account: any): void {
    this.data.rule.attributePattern.accountName = account;
  }

  public calculateRuleText(): string {
    if (!this.isInstitutionSelected && !this.isAmountSelected && !this.isMerchantSelected) {
      return 'Select at least one box in step 1 to create a rule.';
    } else if (
      this.selectedType === undefined ||
      this.selectedCategory === undefined ||
      this.selectedSubcategory === undefined ||
      this.selectedSubcategoryLabel === undefined
    ) {
      return 'Select the type, category and subcategory in step 2.';
    } else {
      const rule = this.cloneRuleWithProperties();
      return this.transactionRuleService.getRuleDescription(rule);
    }
  }

  public deleteRule() {
    this.transactionRuleService.deleteTransactionRule(this.data.rule).subscribe(() => {
      this.dialogRef.close(this.data.rule);
    });
  }

  public setRuleActivity() {
    this.data.rule.isActive = !this.data.rule.isActive;
  }

  public saveRule(): void {
    if (this.isRuleComplete()) {
      const rule = this.cloneRuleWithProperties();

      if (this.isNewRule) {
        this.transactionRuleService.createTransactionRule(rule);
      } else {
        this.transactionRuleService.changeTransactionRule(rule);
      }
      this.dialogRef.close(rule);
      this.transactionRuleService.updateTransactions(true);
    } else {
      this.dataModelService.showMessage('error', 'You must select at least one criteria in step 1 and complete step 2', 20000);
    }
  }

  private isRuleComplete(): boolean {
    if (
      (!this.isInstitutionSelected && !this.isAmountSelected && !this.isMerchantSelected) ||
      this.selectedType === undefined ||
      this.selectedCategory === undefined ||
      this.selectedSubcategory === undefined
    ) {
      return false;
    }
    return true;
  }

  private cloneRuleWithProperties(): ITransactionRule {
    const rule = { ...this.data.rule };
    rule.attributePattern = {
      institutionName: this.isInstitutionSelected ? rule.attributePattern.institutionName : undefined,
      amount: this.isAmountSelected ? rule.attributePattern.amount : undefined,
      merchantName: this.isMerchantSelected ? rule.attributePattern.merchantName : undefined
    };
    rule.wizeFiCategory = `${this.selectedType}_${this.selectedCategory}_${this.selectedSubcategory}`;
    return rule;
  }

  /**
   * Save a new subcategory (account)
   *
   * @param subCategoryName
   */
  public saveNewSubCategory(subCategoryName) {
    let cat = this.selectedType;
    let subcat = this.selectedCategory;

    if (this.createNewSubCategoryForIndex !== null) {
      cat = this.splitTransactionInfo.children[this.createNewSubCategoryForIndex].type;
      subcat = this.splitTransactionInfo.children[this.createNewSubCategoryForIndex].cat;
    }

    // Get the current plan
    const plan = this.dataModelService.dataModel.persistent.header.curplan;
    // Get the wizefi account schema
    const account = this.dataModelService.categoryManagement.getWizeFiAccountSchema(cat, subcat);
    // Get all the accounts for this category/sub category and add the new account
    const accounts = this.dataModelService.dataModel.persistent.plans[plan][cat][subcat].accounts;
    const accountID = this.dataModelService.categoryManagement.getNewAccountID(accounts);
    const wizeFiCategory = this.dataModelService.categoryManagement.makeWizeFiCategory(cat, subcat, accountID);

    account.accountID.val = accountID;
    account.accountName.val = subCategoryName;
    account.wizeFiCategory.val = wizeFiCategory;
    accounts.push(account);

    this.dataModelService.dataManagement
      .storeinfo()
      .then(() => {
        if (this.createNewSubCategoryForIndex !== null) {
          this.splitTransactionInfo.children[this.createNewSubCategoryForIndex].accountLabels.push(subCategoryName);
          this.splitTransactionInfo.children[this.createNewSubCategoryForIndex].subcat = subCategoryName;
        } else {
          this.accountLabels.push(subCategoryName);
          this.selectedCategory = subcat;
          this.setSelectedSubcategory(subCategoryName, true);
          // this.categoryService.getSubcategories(this.selectedType, this.selectedCategory, this.subcategory);
          // this.selectedSubcategoryLabel = this.categoryService.getSubcategoryLabel(this.selectedType, this.selectedCategory, this.selectedSubcategory);
        }
        // Need to reset wizeFiCategories so other dropdowns will have new subcategory
        // this.labelManagement.resetWizeFiCategories();
      })
      .catch(err => {
        console.log(err);
        this.dataModelService.showMessage('error', 'Error saving new sub-category: ' + err, 10000);
      })
      .finally(() => {
        this.showCreateNewSubCategory = false;
      });
  }

  setSelectedSubcategory(subcategory, justString = false) {
    if (justString) {
      this.selectedSubcategoryLabel = subcategory;
    } else {
      this.selectedSubcategoryLabel = this.categoryService.getSubcategoryLabel(this.selectedType, this.selectedCategory, subcategory);
    }
  }
}

/**
 * Input data to transactions rule dialog. If this is a new rule, a transaction must be provided. Otherwise, the transaction will be
 * inferred from the source_id field in the provided rule.
 */
export interface ITransactionRuleDialogData {
  rule?: ITransactionRule;
  transaction?: WizefiTransaction;
  selectedSubcategory?: string;
  selectedAccount?: string;
  selectedType?: string;
}
