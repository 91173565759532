/* import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IHeader } from "../../../interfaces/iHeader.interface";
import { DataModelService } from "../../../services/data-model/data-model.service";
import {
  SetupFlowDataManagementService,
  SetupFlowScreen
} from "../../../services/setup/setup-flow-data-management.service";
import { CProfile } from "../../../utilities/profile.class";
import { CSettings } from "../../settings/settings.class"; */

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { UrlManagement } from 'src/app/utilities/url-management.class';
import { IHeader } from '../../../interfaces/iHeader.interface';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { CProfile } from '../../../utilities/profile.class';
import { ReportBugComponent } from '../../settings/change-log/report-bug/report-bug.component';
import { SuggestFeatureComponent } from '../../settings/change-log/suggest-feature/suggest-feature.component';
import { CSettings } from '../../settings/settings.class';

declare const braintree: any;

@Component({
  selector: 'app-setup-settings',
  templateUrl: './setup-settings.component.html',
  styleUrls: ['./setup-settings.component.scss']
})
export class SetupSettingsComponent implements OnInit {
  public cSettings: CSettings;
  public cProfile: CProfile;
  public HeaderData: IHeader;
  public addNew = false;
  public messages: string[] = [];
  public isSubscribed: boolean;
  public screenState: string;
  public AID: string;
  public alias: string;
  public fee: number;
  public referral: string;
  public referralAID: string;
  public paymentDate: any;
  public paymentMethod: any;
  public paymentMethods: any;
  public payoutEmail: string;
  public subscriptionID: string;
  public isLoading = false;

  public nameFirst: string;
  public nameLast: string;
  public email: string;

  public updateEmail = false;
  public updatePassword = false;
  public updateSubscriptionFlag = false;

  private wizeFiID: string;

  protected url = new UrlManagement();

  constructor(protected dataModelService: DataModelService, protected router: Router, private dialog: MatDialog) {}

  public ngOnInit() {
    this.HeaderData = this.dataModelService.dataModel.persistent.header;
    this.cSettings = new CSettings(this.dataModelService.getdata('settings'));
    this.cProfile = new CProfile(this.dataModelService.getdata('profile'));
    console.log(this.cProfile);
    console.log(this.HeaderData);
  }

  public setScreenState(action = null) {
    switch (action) {
      case 'updatePaymentMethod':
        this.screenState = 'update';
        this.addNew = false;
        break;
      case 'init':
      default:
        this.screenState = this.isSubscribed ? 'subscribed' : 'unsubscribed';
        this.addNew = false;
        break;
    }
  }

  public establishPaymentControl(customerId = null, container = '#dropin-container') {
    // sample credit card number: 4111111111111111

    const establishPaymentMethodWidget = (clientToken: any) =>
      new Promise((resolve, reject) => {
        const button = document.querySelector('#submit-button');

        braintree.dropin.create(
          { authorization: clientToken, container },
          (
            createErr: string,
            instance: {
              requestPaymentMethod: (arg0: (requestPaymentMethodErr: any, noncePayload: any) => void) => void;
            }
          ) => {
            if (createErr) {
              reject('Error in dropin.create: ' + createErr);
            } else {
              button.addEventListener('click', () => {
                instance.requestPaymentMethod((requestPaymentMethodErr: any, noncePayload: any) => {
                  if (requestPaymentMethodErr) {
                    console.log('Error in requestPaymentMethod: ', requestPaymentMethodErr);
                    this.dataModelService.showMessage('error', 'Error in establishing payment method widget', 20000);
                  } else {
                    if (this.subscriptionID) {
                      this.addPaymentMethod(noncePayload);
                    } else {
                      this.establishSubscription(noncePayload);
                    }
                  }
                });
                resolve();
              });
            }
            this.isLoading = false;
          }
        );
      }); // return new Promise // establishPaymentMethodWidget
    this.isLoading = true;
    this.dataModelService.braintreeManagement
      .generateClientToken(this.wizeFiID)
      .then(establishPaymentMethodWidget)
      .catch(err => {
        this.isLoading = false;
        console.log('Error in establishing payment method widget', err);
        this.dataModelService.showMessage('error', 'Error in establishing payment method widget', 20000);
      });
  } // establishPaymentControl

  public establishSubscription(noncePayload: any) {
    const makeSubscription = (funcNoncePayload: any) => {
      this.isLoading = true;

      let newSubscriptionInfo: any; // workaround to deal with type compatability issues in Typescript
      newSubscriptionInfo = {
        wizeFiID: this.wizeFiID,
        firstName: this.dataModelService.dataModel.persistent.profile.nameFirst,
        lastName: this.dataModelService.dataModel.persistent.profile.nameLast,
        // email for receiving notices about any subscription issues @TODO offer user input for setting distinct emaill specifically for their subscription account?
        email: this.dataModelService.dataModel.persistent.profile.email,
        noncePayload: funcNoncePayload,
        planId: 'WizeFiPlan'
      };

      // 'aaaaaaa' = WizeFi i.e. there was no affiliate referral
      if (this.enableTrial()) {
        newSubscriptionInfo.trialPeriod = true;
        newSubscriptionInfo.trialDuration = 30;
        newSubscriptionInfo.trialDurationUnit = 'day';
      }

      return this.dataModelService.braintreeManagement.establishSubscription(newSubscriptionInfo);
    }; // makeSubscription

    const finish = (payloadOut: { errorMessage: string }): void => {
      if (payloadOut.errorMessage) {
        this.dataModelService.showMessage('error', payloadOut.errorMessage, 20000);
      } else {
        if (this.dataModelService.dataModel.persistent.header.dateProfileCompleted === '') {
          const body = document.getElementsByTagName('body')[0];
          body.classList.remove('nav-main-disabled'); // clear nav override
          this.router.navigate(['/setup/connect-bank', {}]);
          this.dataModelService.showMessage('info', 'Subscription has been registered', 20000);
        } else {
          this.dataModelService.showMessage('success', 'Congratulations! Your subscription is now active.', 20000);
          this.ngOnInit();
        }
      }
      this.isLoading = false;
    }; // finish

    if (!this.validateProfile()) {
      return false;
    }

    this.dataModelService.putdata('profile', this.cProfile.profile);
    this.dataModelService.dataManagement
      .storeinfo()
      .then(r => makeSubscription(noncePayload))
      .then(r => this.dataModelService.updateBraintreeData(r)) // refresh Braintree data after subscription change
      .then(finish)
      .catch(err => {
        console.log(err);
        this.isLoading = false;
        this.dataModelService.showMessage('error', 'Subscription failed: ' + err, 10000);
      });
  } // establishSubscription

  // TODO refresh Braintree data whenever subscription information is changed

  public cancelSubscription() {
    const processSubscriptionCancel = () => {
      this.dataModelService.showMessage('success', 'Subscription has been canceled', 10000);
      this.ngOnInit();
    }; // processSubscriptionCancel

    const processSubscriptionCancelError = (err: any) => {
      console.log('error in deleting subscription: ', err);
      this.dataModelService.showMessage('info', 'Error in canceling subscription', 20000);
    }; // processSubscriptionCancelError

    if (this.dataModelService.dataModel.global.braintreeData.subscriptionInfo.subscriptionID === '') {
      this.dataModelService.showMessage('error', 'There is no subscription in place to be canceled', 20000);
    } else {
      if (confirm('Are you sure you want to cancel your subscription and suspend all affiliate earnings?')) {
        this.dataModelService.braintreeManagement
          .cancelSubscription(this.dataModelService.dataModel.global.braintreeData.subscriptionInfo.subscriptionID)
          .then(r => this.dataModelService.updateBraintreeData(r)) // refresh Braintree data after subscription change
          .then(processSubscriptionCancel)
          .catch(processSubscriptionCancelError);
      }
    }
  } // cancelSubscription

  public updateSubscription(paymentMethodToken = null) {
    const p = {
      subscriptionID: this.subscriptionID,
      parms: {
        paymentMethodToken: paymentMethodToken ? paymentMethodToken : this.paymentMethod
      }
    };
    this.isLoading = true;
    this.dataModelService.braintreeManagement
      .updateSubscription(p)
      .then(r => this.dataModelService.updateBraintreeData(r)) // refresh Braintree data after subscription change
      .then(r => {
        console.log('updateSubscription', r);
        this.ngOnInit();
        this.dataModelService.showMessage('success', 'Payment method updated', 20000);
        this.isLoading = false;
      })
      .catch(err => {
        console.log(err);
        this.dataModelService.showMessage('error', 'Payment method change failed.', 20000);
        this.isLoading = false;
      });
  } // updateSubscription

  public addPaymentMethod(noncePayload: { nonce: any }) {
    console.log('addPaymentMethod', noncePayload);
    const p = {
      customerId: this.wizeFiID,
      paymentMethodNonce: noncePayload.nonce
    };
    this.dataModelService.braintreeManagement
      .createPaymentMethod(p)
      .then(r => this.dataModelService.updateBraintreeData(r)) // refresh Braintree data after subscription change
      .then((r: any) => {
        console.log('createPaymentMethod', r);
        if (r && r.paymentMethod) {
          this.paymentMethod = r.paymentMethod.token;
        }
        this.updateSubscription();
      })
      .catch(err => {
        console.log(err);
        this.dataModelService.showMessage('error', 'Add payment method failed.', 20000);
      });
  }

  public removePaymentMethod(paymentMethodToken: any) {
    this.dataModelService.braintreeManagement
      .deletePaymentMethod(paymentMethodToken) // paymentMethodToken comes from info in item 1 above
      .then(r => this.dataModelService.updateBraintreeData(r)) // refresh Braintree data after subscription change
      .then(r => {
        console.log('Success! Payment method removed!', r);
        this.dataModelService.showMessage('success', 'Payment method removed', 20000);
        this.ngOnInit();
      })
      .catch(err => {
        console.log(err);
      });
  }

  public saveAffiliateDetail() {
    return new Promise((resolve, reject) => {
      // do not need putdata validity check here (handled prior to call on update)
      this.dataModelService.dataModel.persistent.header.payoutEmail = this.payoutEmail;
      this.dataModelService.putdata('header', this.dataModelService.dataModel.persistent.header);

      this.dataModelService.dataManagement
        .storeinfo()
        .then(() => {
          resolve();
          this.router.navigateByUrl('/settings#account');
        })
        .catch(err => {
          console.log('Unable to save affiliate information: ', err); // %//
          this.dataModelService.showMessage('error', 'Unable to save affiliate information', 7000);
          reject(err);
        });
    }); // return Promise
  } // saveAffiliateDetail()

  public listPaymentMethods() {
    console.log('listPaymentMethods');
    this.isLoading = true;
    this.setScreenState('updatePaymentMethod');
    this.dataModelService.braintreeManagement
      .listCustomerSubscriptions(this.wizeFiID, true)
      .then(r => this.dataModelService.updateBraintreeData(r)) // refresh Braintree data after subscription change
      .then(r => {
        console.log('listCustomerSubscriptions: ', r);
        this.paymentMethod = r[0].paymentMethodToken;
        return this.dataModelService.braintreeManagement.listCustomerPaymentMethods(this.wizeFiID);
      })
      .then(r => {
        console.log('listCustomerPaymentMethods: ', r);
        this.paymentMethods = r;
        this.isLoading = false;
      })
      .catch(err => {
        console.log(err);
        this.isLoading = false;
      });
  }

  public addMethodForm() {
    this.addNew = true;
    this.establishPaymentControl(this.wizeFiID, '#update-dropin-container');
  }

  public scrollTo(tag: string) {
    try {
      document.querySelector(tag).scrollIntoView();
    } catch (e) {}
  }

  public enableTrial() {
    return (
      !this.dataModelService.dataModel.global.braintreeData.haveBraintreeAccount &&
      this.dataModelService.dataModel.persistent.header.parentAffiliateID !== 'aaaaaaa'
    );
  }

  public validateProfile() {
    const messages = [];
    if (!this.cProfile.profile.nameFirst) {
      messages.push('Please enter a value for First Name');
    }

    if (!this.cProfile.profile.nameLast) {
      messages.push('Please enter a value for Last Name');
    }

    // TODO consider whether to use more complex pattern: [-.\w]+@([\w-]+\.)+[\w-]{2,20}, or research yet more complete patterns
    if (!this.cProfile.profile.email.match(/^.+@.+\..+$/)) {
      messages.push('Please enter a valid Email');
    }

    if (messages.length > 0) {
      this.dataModelService.showMessages('error', messages, 5000);
      return false;
    }
    return true;
  }

  public togglePasswordEdit() {
    this.updatePassword = !this.updatePassword;
  }

  public toggleEmailEdit() {
    this.updateEmail = !this.updateEmail;
  }

  public toggleSubscriptionEdit() {
    this.updateSubscriptionFlag = !this.updateSubscriptionFlag;
  }

  public closeEmailEdit() {
    this.updateEmail = false;
  }

  public closePasswordEdit() {
    this.updatePassword = false;
  }

  public closeSubscriptionEdit() {
    this.updateSubscriptionFlag = false;
  }

  public saveEmail() {
    console.log(this.email);
    this.cProfile.profile.email = this.email;
    this.validateProfile();
    this.dataModelService.putdata('profile', this.cProfile.profile);
    this.dataModelService.dataManagement.storeinfo();
    this.closeEmailEdit();
  }

  public openReportBugDialog() {
    this.dialog.open(ReportBugComponent);
  }

  public openFeatureSuggestionDialog(): void {
    this.dialog.open(SuggestFeatureComponent);
  }

  public logout() {
    if (window.confirm('Are you sure you want to log out?')) {
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigateByUrl('/login');
    }
  }
}
