import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { DataModelService } from '../data-model/data-model.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  constructor(private dataModelService: DataModelService) {}

  public getTypes(): string[] {
    const wizefiCategories = this.dataModelService.categoryManagement.getWizeFiCategories();

    const wizeFiCategoryNames = wizefiCategories.map(category => category.wizeFiCategoryName);
    const types = this.dataModelService.categoryManagement.getCategoryList(wizeFiCategoryNames).filter(label => label !== 'any');

    return types.filter(type => this.isValidType(type));
  }

  public getCategories(currentType: string): string[] {
    const wizefiCategories = this.dataModelService.categoryManagement.getWizeFiCategories();
    const wizeFiCategoryNames = wizefiCategories.map(category => category.wizeFiCategoryName);

    const categoryGroups = _.values(this.dataModelService.categoryManagement.getSubcategoryList(wizeFiCategoryNames));
    return (_.flatten(categoryGroups) as string[]).filter(label => label !== 'any').filter(category => this.isValidCategory(currentType, category));
  }

  public getSubcategories(currentType: string, currentCategory: string): string[] {
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;
    const plan = this.dataModelService.dataModel.persistent.plans[curplan];

    const categories = plan[currentType];
    if (categories === undefined) {
      return [];
    }

    const subcategories = categories[currentCategory];
    if (subcategories === undefined) {
      return [];
    }

    return subcategories.accounts.map(i => i.accountID.val);
  }

  public getSubcategoryLabel(type: string, category: string, subcategory: string, getAccountCustomLabels: boolean = false): string {
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;
    const plan = this.dataModelService.dataModel.persistent.plans[curplan];
    let planCategoryData: any = [];
    if (plan[type]) {
      planCategoryData = plan[type][category];
    }

    let label;
    if (getAccountCustomLabels && planCategoryData) {
      const connectedUserAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
      if (planCategoryData.accounts) {
        planCategoryData.accounts.forEach(wizeFiSubcategory => {
          for (const cat in connectedUserAccounts) {
            if (wizeFiSubcategory.wizeFiCategory.val === connectedUserAccounts[cat].wizeFiCategory) {
              return (label = connectedUserAccounts[cat].accountName);
            }
          }
        });
      }
    }

    if (label) {
      return label;
    }

    return (
      plan[type] &&
      plan[type][category] &&
      plan[type][category].accounts &&
      plan[type][category].accounts.find(i => i.accountID.val === subcategory) &&
      plan[type][category].accounts.find(i => i.accountID.val === subcategory).accountName.val
    );
  }

  public isValidType(type: string): boolean {
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;
    const plan = this.dataModelService.dataModel.persistent.plans[curplan];

    return plan[type].label !== undefined;
  }

  public isValidCategory(type: string, category: string): boolean {
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;
    const plan = this.dataModelService.dataModel.persistent.plans[curplan];

    return plan[type] !== undefined && plan[type][category] !== undefined && plan[type][category].label !== undefined;
  }

  public getTypeLabel(type: string): string {
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;
    const plan = this.dataModelService.dataModel.persistent.plans[curplan];
    let label = '';
    if (plan[type] && plan[type].label && plan[type].label === 'Liability') {
      label = 'Debt';
    } else {
      if (plan[type]) {
        label = plan[type].label;
      }
    }
    return label;
  }

  public getCategoryLabel(type: string, category: string): string {
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;
    const plan = this.dataModelService.dataModel.persistent.plans[curplan];

    return plan[type] && plan[type][category] && plan[type][category].label;
  }

  public getTypeFromDbCategoryFormat(dbCategory: string | undefined): string | undefined {
    return this.splitDbCategoryAndGetIndex(dbCategory, 0);
  }

  public getCategoryFromDbCategoryFormat(dbCategory: string | undefined): string | undefined {
    return this.splitDbCategoryAndGetIndex(dbCategory, 1);
  }

  public getSubcategoryFromDbCategoryFormat(dbCategory: string | undefined): string | undefined {
    return this.splitDbCategoryAndGetIndex(dbCategory, 2);
  }

  private splitDbCategoryAndGetIndex(dbCategory: string | undefined, index: number): string | undefined {
    if (dbCategory === undefined) {
      return undefined;
    }

    const parts = dbCategory.split('_');
    if (parts.length === 3) {
      return parts[index];
    } else {
      return undefined;
    }
  }
}
