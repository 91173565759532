import { Draft, DraftType } from '@app/interfaces/draft';
import { PlaidManagement } from '@app/utilities/plaid-management.class';
import { AwsDynamoDbService } from './aws-dynamo-db.service';
import { DataModelService } from './data-model/data-model.service';

export class DraftService {
  public constructor(private dataModelService: DataModelService, private awsService: AwsDynamoDbService, private plaidManagement: PlaidManagement) {}

  public isEditingDrafts(): boolean {
    const editableDraftTypes: DraftType[] = ['open', 'versionHistory'];
    return editableDraftTypes.includes(this.dataModelService.dataModel.draftType);
  }

  public async promoteDraft() {
    // transform the draft data in the dataModel in memory into regular user data in the dataModel in memory
    const dataModel = this.dataModelService.dataModel;
    const plaidManagement = this.dataModelService.plaidManagement;
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;

    // remember the title of the draft to be promoted
    const promotionTitle = dataModel.title;

    // be sure the latest version of the draft to be promoted is in DynamoDB (for later recall in the steps below)
    await this.storeDraft();

    // bring into the dataModel the user data that was in effect at the time of the WizeFi login
    await this.dataModelService.dataManagement.fetchUserData(wizeFiID);

    // create a versionHistory draft to remember the state of the user data
    await this.createPreviousVersionDraft();

    await this.fetchDraft(promotionTitle);
    // remove properties unique to a draft
    delete dataModel.title;
    delete dataModel.description;
    delete dataModel.draftType;

    // set the proper dateCreated value
    dataModel.persistent.header.dateCreated = dataModel.persistent.header.originalDateCreated;

    // save data in WizeFiData and WizeFiDataPlans
    await this.dataModelService.dataManagement.storedata();

    // save plaidInstitutions and plaidAccounts information for this new user data in WizeFiPlaidInstitutions and WizeFiPlaidAccounts
    await plaidManagement.copyPlaidData(wizeFiID, promotionTitle, '');
  }

  private async createPreviousVersionDraft() {
    await this.createDraft(`Previous Plan - ${new Date().toLocaleString()}`, ``, 'versionHistory');
  }

  public async createDraft(title: string, description: string, draftType: DraftType) {
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    const dataModel = this.dataModelService.dataModel;
    const sourceTitle = this.dataModelService.dataManagement.getDraftTitle();

    // set the proper dateCreated and dateUpdated value
    dataModel.persistent.header.dateCreated = new Date().toISOString();
    dataModel.persistent.header.dateUpdated = dataModel.persistent.header.dateCreated;

    const draftsInfo = await this.fetchDraftsInfo();
    const containsDraft = draftsInfo.filter(e => e.title.includes(title));
    if (!dataModel.hasOwnProperty('title') || containsDraft.length === 0) {
      // process dataModel that is not a draft

      // remove spurious plans (only original and curplan are utilized in a draft)
      for (const plan of Object.keys(dataModel.persistent.plans)) {
        if (plan !== 'original' && plan !== dataModel.persistent.header.curplan) {
          delete dataModel.persistent.plans[plan];
        }
      }

      // set properties required by a draft
      dataModel.title = title;
      dataModel.description = description;
      dataModel.draftType = draftType;
    } else {
      // process dataModel that is a draft
      dataModel.title = title;
      dataModel.description = dataModel.description; // use current description until user changes it
    }
    const destinationTitle = dataModel.title;

    // save draft to DynamoDB WizeFiDrafts
    await this.storeDraft();

    // capture plaidInstitutions and plaidAccounts information for this new draft
    await this.dataModelService.plaidManagement.copyPlaidData(wizeFiID, sourceTitle, destinationTitle);
  }

  public async storeDraft() {
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    const dataModel = this.dataModelService.dataModel;

    // set date of last update
    dataModel.persistent.header.dateUpdated = new Date().toISOString();

    // define item to be stored
    const item = {
      wizeFiID: dataModel.global.wizeFiID,
      title: dataModel.title,
      description: dataModel.description,
      draftType: dataModel.draftType,
      affiliateID: dataModel.affiliateID,
      affiliateAlias: dataModel.affiliateAlias,
      persistent: JSON.stringify(dataModel.persistent),
      topLevelVersion: dataModel.topLevelVersion
    };

    await this.awsService.put('WizeFiDrafts', item);

    // update draftsInfo information
    const planInfo = this.dataModelService.dataManagement.fetchPlanInfo();
    const draftInfo: Draft = {
      title: dataModel.title,
      description: dataModel.description,
      draftType: dataModel.draftType,
      planName: planInfo.planName,
      dateCreated: dataModel.persistent.header.dateCreated,
      dateUpdated: dataModel.persistent.header.dateUpdated
    };
    this.addDraftInfo(draftInfo); // add draftInfo to the global draftsInfo array
    await this.storeDraftsInfo(wizeFiID, this.dataModelService.dataModel.global.draftsInfo);
  }

  private addDraftInfo(draftInfo: Draft) {
    const draftsInfo = this.dataModelService.dataModel.global.draftsInfo;
    const index = draftsInfo.findIndex(i => i.title === draftInfo.title);
    if (index !== -1) {
      // replace existing object with updated version
      draftsInfo.splice(index, 1, draftInfo);
    } else {
      draftsInfo.push(draftInfo);
    }
  }

  public async fetchDraft(title: string): Promise<void> {
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    const result = await this.awsService.query(
      'WizeFiDrafts',
      '#wizeFiID = :wizeFiID_val and #title = :title_val',
      { '#wizeFiID': 'wizeFiID', '#title': 'title' },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      { ':wizeFiID_val': this.dataModelService.dataModel.global.wizeFiID, ':title_val': title }
    );
    if (result.Items.length === 0) {
      throw new Error('Draft does not exist: ' + title);
    }
    const item = result.Items[0];

    item.persistent = JSON.parse(item.persistent);

    // plant WizeFiDraft information in dataModel
    this.dataModelService.dataModel.title = item.title;
    this.dataModelService.dataModel.description = item.description;
    this.dataModelService.dataModel.draftType = item.draftType;
    this.dataModelService.dataModel.affiliateID = item.affiliateID;
    this.dataModelService.dataModel.affiliateAlias = item.affiliateAlias;
    this.dataModelService.dataModel.persistent = item.persistent;
    this.dataModelService.dataModel.topLevelVersion = item.topLevelVersion;

    /*
    // set draft planName to WizeFi curplan obtained at login time
    const planList = Object.keys(dataModel.persistent.plans).sort();
    const lastndx = planList.length - 1;
    const oldName = planList[lastndx];
    const newName = this.dataModelService.dataModel.global.loginCurplan; ?  (would need to add loginCurplan to dataModel)
    this.dataModelService.changeObjectPropertyName(dataModel.persistent.plans, oldName, newName);

    Instead of using the above, for now we will utilize the fact that the usual way to set curplan is shown below:

    const curplan = this.dataModelService.dataModel.persistent.header.curplan;

    and dataModel.persistent.header.curplan is present in the draft that is in the dataModel
    */

    // plant information into the global wizeFiPlaidInstitutions and wizeFiPlaidAccounts data
    this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions = await this.dataModelService.plaidManagement.getPlaidInstitutions(
      wizeFiID,
      title
    );
    this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts = await this.dataModelService.plaidManagement.fetchPlaidAccounts(
      wizeFiID,
      title
    );
  }

  public async storeDraftsInfo(wizeFiID: string, draftsInfo: Draft[]) {
    return this.awsService.put('WizeFiDraftsInfo', { wizeFiID, draftsInfo: JSON.stringify(draftsInfo) });
  }

  public async deleteDraft(title: string) {
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;

    // delete all Plaid institutions that are unique to this draft
    const plaidInstitutions = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions;
    const institutionsCounts = await this.dataModelService.dataManagement.getInstitutionsCounts(wizeFiID);
    for (const plaidInstitution of plaidInstitutions) {
      const itemId = plaidInstitution.item_id;
      let itemIdCount = 0;
      if (institutionsCounts.itemIdCount.hasOwnProperty(itemId)) {
        itemIdCount = institutionsCounts.itemIdCount[itemId];
      }

      if (itemIdCount === 1) {
        await this.plaidManagement.deletePlaidInstitution(wizeFiID, title, itemId, itemIdCount);
      }
    }

    // delete draft from WizeFiDrafts
    await this.awsService.delete('WizeFiDrafts', { wizeFiID, title });

    // delete information from DraftPlaidInstitutions and DraftPlaidAccounts
    this.deleteDraftPlaidInstitutions(wizeFiID, title);
    this.deleteDraftPlaidAccounts(wizeFiID, title);

    // update draftsInfo information
    this.deleteDraftInfo(title); // remove draftInfo with title from global draftsInifo

    await this.storeDraftsInfo(wizeFiID, this.dataModelService.dataModel.global.draftsInfo);

    // handle the proper final steps depending on whether the deleted draft is the one in the dataModel in memory
    const residentTitle = this.getDraftTitle();
    if (residentTitle === title) {
      // move the user data into the dataModel
      await this.dataModelService.dataManagement.fetchdata();

      // set the global data to the proper values
      this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions = await this.plaidManagement.getWizeFiPlaidInstitutions(wizeFiID);
      this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts = await this.plaidManagement.fetchPlaidAccounts(wizeFiID, residentTitle);
    }
  }

  private async deleteDraftPlaidInstitutions(wizeFiID: string, title: string) {
    return this.awsService.delete('DraftPlaidInstitutions', { wizeFiID, title });
  }

  private async deleteDraftPlaidAccounts(wizeFiID: string, title: string) {
    return this.awsService.delete('DraftPlaidAccounts', { wizeFiID, title });
  }

  private deleteDraftInfo(title: string) {
    const draftsInfo = this.dataModelService.dataModel.global.draftsInfo;
    const index = draftsInfo.findIndex(d => d.title === title);
    if (index !== -1) {
      // remove object from array
      draftsInfo.splice(index, 1);
    }
  }

  /**
   * This routine will return the title of the draft if there is a draft in the dataModel.  Otherwise it will return an empty string "" (indicating that there is not a draft in the dataModel).
   */
  public getDraftTitle() {
    return this.dataModelService.dataModel.hasOwnProperty('title') ? this.dataModelService.dataModel.title : '';
  }

  /**
   *This routine will set the global variable that holds draftsInfo. This action is performed when the global data is set within the WizeFi app (e.g. login and relogin).
   */
  public async loadDraftsInfo() {
    this.dataModelService.dataModel.global.draftsInfo = await this.fetchDraftsInfo();
  }

  public async fetchDraftsInfo(): Promise<Draft[]> {
    const data = await this.awsService.get('WizeFiDraftsInfo', { wizeFiID: this.dataModelService.dataModel.global.wizeFiID });

    let draftsInfo = [];
    if (data && data.Item && data.Item.draftsInfo) {
      draftsInfo = JSON.parse(data.Item.draftsInfo);
    }
    return draftsInfo;
  }
}
