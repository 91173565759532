import { Component, OnInit } from '@angular/core';
import { ProjectionChartComponent } from '../projection-chart/projection-chart.component';

@Component({
  selector: 'app-net-worth-projection-chart',
  templateUrl: '../projection-chart/projection-chart.component.html',
  styleUrls: ['../projection-chart/projection-chart.component.scss']
})
export class NetWorthProjectionChartComponent extends ProjectionChartComponent {
  public ngOnInit() {
    this.setMaxInputData();
    this.setChartDurationInMonths();
    this.addDottedLineAtMaxIfNeeded();
    this.tuneFinalDataPoints();
    this.addXAxisLabels();
    this.addDatasetsToChart();
    this.setLineChartOptions();
    // console.log("Net Worth CHART DATA:", this.lineChartData);
  }

  protected tuneFinalDataPoints() {
    // (overrides)
    this.sortDatasets();
    this.applyCommonDatasetEndingValue();
    this.extendDatasetsToFullChartDuration();
    this.setupEndpoints();
  }

  // sorts datasets so that data with longest time to reach a net worth goal is pushed to a lower zindex
  protected sortDatasets() {
    if (this.inputDatasets !== undefined) {
      this.inputDatasets.sort((a, b) => b.data.length - a.data.length || 0);
    }
  }

  // forces each of the datasets to end at the same y value
  protected applyCommonDatasetEndingValue() {
    if (this.inputDatasets !== undefined) {
      this.inputDatasets.forEach(dataset => {
        // accounts for the fact that we filter this data to take one month out of every year
        if (dataset.data.length === this.chartDurationInMonths) {
          dataset.data.forEach(point => {
            if (point.y > this.maxInputData) {
              point.y = this.maxInputData;
            }
          });
        } else {
          const finalDatapoint = dataset.data[dataset.data.length - 1];
          if (finalDatapoint.y > this.maxInputData) {
            dataset.data[dataset.data.length - 1] = { x: finalDatapoint.x, y: this.maxInputData };
          }
        }
      });
    }
  }

  // projects each dataset to the full chart duration, creating the horizontal line at a financial
  // freedom target amount
  protected extendDatasetsToFullChartDuration() {
    if (this.inputDatasets !== undefined) {
      this.inputDatasets.forEach(dataset => {
        const finalDatapoint = dataset.data[dataset.data.length - 1];
        const extensionLength = this.chartDurationInMonths - dataset.data.length;
        const extension = Array(extensionLength).fill({
          x: new Date(),
          y: (finalDatapoint && finalDatapoint.y) || this.maxInputData
        });
        dataset.data = dataset.data.concat(extension);
      });
    }
  }

  // sets up endpoints at max chart value
  protected setupEndpoints() {
    if (this.inputDatasets !== undefined) {
      this.inputDatasets.forEach(dataset => {
        let hasFoundEarliestMax = false;
        const endpointData = dataset.data.map((point, index) => {
          if (!hasFoundEarliestMax && point.y >= this.maxInputData) {
            hasFoundEarliestMax = true;
            return point;
          }

          return { x: new Date(), y: null };
        });

        this.endpointDatasets.push({
          data: endpointData,
          color: dataset.color
        });
      });
    }
  }
}
