import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IGraphItem } from '../../../interfaces/iGraphItem.interface';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { NetWorthProjectionComponent } from '../net-worth-projection/net-worth-projection.component';

const orange = '#dd7040';

@Component({
  selector: 'app-current-ffa',
  templateUrl: './current-ffa.component.html',
  styleUrls: ['./current-ffa.component.scss']
})
export class CurrentFFAComponent extends NetWorthProjectionComponent {
  public datasets: Array<{ data: IGraphItem[]; color: string }>;
  public isLoading = true;

  constructor(protected dataModelService: DataModelService, protected router: Router) {
    super(dataModelService);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.datasets = [{ data: this.originalFFData, color: orange }];

    this.isLoading = false;
  }

  public onNext() {
    this.router.navigate(['/wizefi-FFA']);
  }
}
