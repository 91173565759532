import { Component, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { WizefiTransaction } from '../../../interfaces/wizefi-transaction';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { BudgetCategoryComponent } from '../budget-category/budget-category.component';

@Component({
  selector: 'app-category-transactions',
  templateUrl: './category-transactions.component.html',
  styleUrls: ['./category-transactions.component.scss']
})
export class CategoryTransactionsComponent extends BudgetCategoryComponent {
  @Input() public accountID: string; // todo: change name to accountID
  @Input() public readonly = false;
  @Output() public onRecategorizeTransaction: EventEmitter<any> = new EventEmitter<any>();
  @Output() public didEditTransaction: EventEmitter<any> = new EventEmitter<any>();
  public categoryTransactionsByDate: WizefiTransaction[][]; // array of transaction arrays by date
  public isLoading = true;
  public spentAmount: number;
  public plannedAmount: number;
  public remainingAmount: number;
  public spentPercentage: number;
  public isInSetup: boolean;

  constructor(
    protected dataModelService: DataModelService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected renderer: Renderer2,
    protected dialog: MatDialog
  ) {
    super(dataModelService, router, renderer);
  }

  public editTransaction(transaction: WizefiTransaction) {
    this.didEditTransaction.emit({ transaction });
  }

  public async ngOnInit() {
    super.ngOnInit();
    this.setCategoryTransactionsByDate();
    const header = this.dataModelService.dataModel.persistent.header;
    this.isLoading = false;
    this.isInSetup = !header.dateOrigPlanCompleted || this.router.url.includes('setup/spending');

    // Get account associated with account id
    const accIndex = this.dataModelService.categoryManagement.accountID2acntndx(this.accountID, this.category.accounts);
    const account = this.category.accounts[accIndex];

    this.spentAmount = 0;
    this.plannedAmount = 0;

    if (account) {
      this.spentAmount = account.actualMonthlyAmount ? account.actualMonthlyAmount.val : 0;
      this.plannedAmount = account.monthlyAmount ? account.monthlyAmount.val : 0;
      const shadowAccountsTotal = this.belongsToCurrentCategory(account) ? this.getActualMonthlyAmount(account) : 0;
      if (shadowAccountsTotal) {
        this.plannedAmount += shadowAccountsTotal;
      }
    }
    this.remainingAmount = this.plannedAmount - this.spentAmount;
    this.spentPercentage = this.spentAmount <= 0 ? 0 : (this.spentAmount / this.plannedAmount) * 100;
  }

  /* Method copied from budget-category */
  protected belongsToCurrentCategory(shadowAccount): boolean {
    let isAShadowAccount = false;
    const containsCategory =
      shadowAccount.budgetSubcategory &&
      shadowAccount.budgetSubcategory.val !== '' &&
      this.category.label.toLowerCase().includes(shadowAccount.budgetSubcategory.val.toLowerCase());
    const belongsToCategory =
      shadowAccount.budgetSubcategory && shadowAccount.budgetSubcategory.val.toLowerCase() === this.category.label.toLowerCase();

    if (containsCategory === true || belongsToCategory === true) {
      if (shadowAccount.shadowType !== 'liability' && shadowAccount.shadowType !== 'asset') {
        isAShadowAccount = true;
      }
    }

    return isAShadowAccount;
  }

  /* Method copied from reallocate-spending-category */
  protected getActualMonthlyAmount(shadowAccount) {
    const wizeFiCategory = shadowAccount.wizeFiCategory.val;
    const wizeFiAccount = this.dataModelService.categoryManagement.getWizeFiAccountFromWizeFiCategory(wizeFiCategory);
    if (wizeFiAccount.monthlyAmount && wizeFiAccount.monthlyAmount.val > 0) {
      return wizeFiAccount.monthlyAmount.val;
    } else if (wizeFiAccount.monthlyMinimum && wizeFiAccount.monthlyMinimum.val > 0) {
      return wizeFiAccount.monthlyMinimum.val;
    } else {
      return 0;
    }
  }

  protected getSubcategoryLabel(): string {
    return this.category.label;
  }

  protected getAccountLabel(): string {
    return this.dataModelService.categoryManagement.accountID2accountName(this.accountID, this.category.accounts);
  }

  private setCategoryTransactionsByDate() {
    const wizeFiCategory = `${this.categoryGroup}_${this.categoryKey}_${this.accountID}`;
    this.categoryTransactionsByDate = this.mapByDate(this.transactions[wizeFiCategory]);
  }

  private mapByDate(allTransactions: WizefiTransaction[]): WizefiTransaction[][] {
    if (!allTransactions) {
      return [[]];
    }
    const uniqueDates = [...new Set(allTransactions.map(item => item.date))];
    const transactionsByDate = uniqueDates.map(date => allTransactions.filter(transaction => transaction.date === date));
    return transactionsByDate;
  }
}
