import { Component } from '@angular/core';
import { DataModelService } from 'src/app/services/data-model/data-model.service';
import { AccountTypeService } from 'src/app/services/account-type/account-type.service';
import { AccountService } from 'src/app/services/account/account.service';
import { AccountDetailsComponent } from '../account-details/account-details.component';

@Component({
  selector: 'app-credit-card-account-details',
  templateUrl: './credit-card-account-details.component.html',
  styleUrls: ['../account-details-template/account-details-template.component.scss']
})
export class CreditCardAccountDetailsComponent extends AccountDetailsComponent {
  protected extraYouPay: number;
  protected bal: number;

  constructor(public dataModelService: DataModelService, accountTypeService: AccountTypeService, accountService: AccountService) {
    super(dataModelService, accountTypeService, accountService);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.extraYouPay = this.getExtraYouPay();
    this.bal = (this.wizeFiAccount && this.wizeFiAccount.accountValue && this.wizeFiAccount.accountValue.val) || this.plaidAccount.balance || 0;
  }

  protected onInterestRateChange(value: string) {
    this.wizeFiAccount.interestRate.val = parseFloat(value);
  }

  protected onCreditLimitChange(value: string) {
    this.wizeFiAccount.accountLimit.val = parseFloat(value) || 0;
  }

  protected onMinimumPaymentChange(value: string) {
    this.wizeFiAccount.monthlyMinimum.val = parseFloat(value) || 0;
    this.updateTotalYouPay();
  }

  protected onExtraYouPayChange(value: string) {
    this.extraYouPay = parseFloat(value) || 0;
    this.updateTotalYouPay();
  }

  protected getExtraYouPay(): number {
    return (this.wizeFiAccount && this.wizeFiAccount.monthlyAmount.val - this.wizeFiAccount.monthlyMinimum.val) || 0;
  }

  protected updateTotalYouPay() {
    this.wizeFiAccount.monthlyAmount.val = this.wizeFiAccount.monthlyMinimum.val + this.extraYouPay;
  }

  protected getTotalYouPay(): number {
    return (this.wizeFiAccount && this.wizeFiAccount.monthlyAmount.val) || this.wizeFiAccount.monthlyMinimum.val + this.extraYouPay;
  }

  public onBalanceChange(value: string) {
    if (this.wizeFiAccount && this.wizeFiAccount.accountValue) {
      this.wizeFiAccount.accountValue.val = parseFloat(value) || 0;
    }
    this.plaidAccount.balance = parseFloat(value) || 0;
    this.bal = parseFloat(value) || 0;
  }
}
