import { DataModelService } from '../services/data-model/data-model.service';
import { accountTypes, categoryInfo, possibleFieldNames, skipSubcatAttributes } from '../services/data-model/data-model_0001.data';
import { LogMessageService } from '../services/log-message/log-message.service';
import { GenericDataManagement } from './generic-data-management.class';
import { SubscriptionManagement } from './subscription-management.class';
import { VersionManagement } from './version-management.class';

declare const AWS: any;

const logMessage = import('../../../js_modules/logMessage.js');

export class ItemManagement {
  constructor(
    public dataModelService: DataModelService,
    public logMessageService: LogMessageService,
    private gd: GenericDataManagement,
    private messages: string[]
  ) {
    this.selectedAction = 'Delete';
    this.selectedItem = 'Subcategory';

    // update subcategory list
    this.subcatList = this.getUpdateSubcategoryList(this.gd.category, this.selectedItem, this.selectedAction);
    this.selectedSubcategory = Array.isArray(this.subcatList) && this.subcatList.length > 0 ? this.subcatList[0].subcat : 'unknown';

    // update account list
    this.accountList = this.getUpdateAccountList(this.gd.category, this.selectedItem, this.selectedAction, this.selectedSubcategory);
    this.selectedAccount = Array.isArray(this.accountList) && this.accountList.length > 0 ? this.accountList[0] : 'unknown';

    // update field selection list
    this.fieldSelectionList = this.getFieldSelectionList();
    this.selectedFieldSelection = this.fieldSelectionList[0];

    // update field list
    this.fieldList = this.getUpdateFieldList(
      this.gd.category,
      this.selectedItem,
      this.selectedAction,
      this.selectedSubcategory,
      this.selectedAccount
    );
    this.selectedField = Array.isArray(this.fieldList) && this.fieldList.length > 0 ? this.fieldList[0].field : 'unknown';

    this.docClient = new AWS.DynamoDB.DocumentClient();
  } // constructor
  public selectedAction: string;
  public selectedItem: string;

  // the following data consists of JavaScript attribute names
  public subcatList: any = [];
  public selectedSubcategory: string;
  public customSubcategory = '';

  // the following data consists of accountName values
  public accountList: string[] = [];
  public selectedAccount: string;
  public customAccount = '';

  // the following data consists of strings that identify field selection options
  public fieldSelectionList: string[] = [];
  public selectedFieldSelection = '';

  // the following data consists of JavaScript attribute names
  public fieldList: any = [];
  public selectedField: string;
  public customField = '';

  public docClient: any;

  public static getSubcategoryAccountList(category: any, subcat: string): string[] {
    const accountNameList: string[] = [];
    if (!category[subcat] || !category[subcat].accounts) {
      return [];
    }
    for (const account of category[subcat].accounts) {
      if (account.hasOwnProperty('accountName')) {
        accountNameList.push(account.accountName.val);
      }
    }
    return accountNameList;
  } // getSubcategoryAccountList

  public static occuranceCount(accountName: string, accountNameList: string[]): number {
    let count = 0;
    for (const accountNameLoop of accountNameList) {
      if (accountName === accountNameLoop) {
        count++;
      }
    }
    return count;
  } // occuranceCount

  public static makeUniqueAccountName(accountName: string, category: any, subcat: string): string {
    // initialize
    let count = 1;
    const baseAccountName = accountName.split('---')[0];
    const accountNameList = ItemManagement.getSubcategoryAccountList(category, subcat);

    // modify name until it is unique
    while (ItemManagement.occuranceCount(accountName, accountNameList) > 0) {
      accountName = baseAccountName + '---' + ++count;
    }
    return accountName;
  } // makeUniqueAccountName

  public static doAction(
    gd: GenericDataManagement,
    item: string,
    action: string,
    subcat: string,
    actndx: number = 0,
    accountName: string = '',
    fieldSelection: string = '',
    field: string = ''
    /*
        Note the following conventions:

        fieldSelection   field
        --------------   -----------------------------
        'Account type'   contains an account type name
        'Field name'     contains a field name
        */
  ): void {
    if (item === 'Subcategory') {
      if (action === 'Add') {
        // add subcategory properties
        gd.category[subcat] = {};
        gd.category[subcat].label = categoryInfo[gd.category.attributeName][subcat].label;
        gd.category[subcat].accounts = [];

        // add account properties
        gd.category[subcat].accounts.push({});
        actndx = 0;
        gd.category[subcat].accounts[actndx].accountName = { label: 'Account Name', val: accountName, isRequired: true };
        gd.category[subcat].accounts[actndx].accountType = { label: 'Account Type', val: field, isRequired: true };

        // add field properties
        for (const field2 in accountTypes[field]) {
          if (accountTypes[field].hasOwnProperty(field2)) {
            gd.category[subcat].accounts[actndx][field2] = JSON.parse(JSON.stringify(accountTypes[field][field2]));
          }
        }
        /*
                gd.category[subcat].accounts.push({});  //%//
                gd.category[subcat].accounts[actndx] = {};  //%//
                if (field !== 'none') gd.category[subcat].accounts[actndx][field] = {};  //%//
                */
      }

      if (action === 'Delete') {
        delete gd.category[subcat];
      }
    } // Subcategory

    if (item === 'Account') {
      if (action === 'Add') {
        // initialize
        accountName = ItemManagement.makeUniqueAccountName(accountName, gd.category, subcat);
        actndx = gd.category[subcat].accounts.length; // this will be value needed after push done below

        // add account properties
        gd.category[subcat].accounts.push({});
        gd.category[subcat].accounts[actndx].accountName = { label: 'Account Name', val: accountName, isRequired: true };
        gd.category[subcat].accounts[actndx].accountType = { label: 'Account Type', val: field, isRequired: true };

        // add field properties
        for (const field2 in accountTypes[field]) {
          if (accountTypes[field].hasOwnProperty(field2)) {
            gd.category[subcat].accounts[actndx][field2] = JSON.parse(JSON.stringify(accountTypes[field][field2]));
          }
        }
      }

      if (action === 'Delete') {
        gd.category[subcat].accounts.splice(actndx, 1);

        if (gd.category.attributeName !== 'budget' && gd.category[subcat].accounts.length <= 0) {
          this.doAction(gd, 'Subcategory', 'Delete', subcat);
        }
      }
    } // Account

    if (item === 'Field') {
      if (action === 'Add') {
        if (actndx !== -1) {
          if (fieldSelection === 'Field name') {
            gd.category[subcat].accounts[actndx][field] = possibleFieldNames[field];
          }
          if (fieldSelection === 'Account type') {
            for (const field2 in accountTypes[field]) {
              if (accountTypes[field].hasOwnProperty(field2)) {
                gd.category[subcat].accounts[actndx][field2] = JSON.parse(JSON.stringify(accountTypes[field][field2]));
              }
            }
          }
        }
      }

      if (action === 'Delete') {
        delete gd.category[subcat].accounts[actndx][field];
      }
    } // Field
  } // doAction

  public onActionChange(): void {
    // update subcategory list
    this.subcatList = this.getUpdateSubcategoryList(this.gd.category, this.selectedItem, this.selectedAction);
    if (Array.isArray(this.subcatList) && this.subcatList.length > 0) {
      this.selectedSubcategory = this.subcatList[0].subcat;
    }
    this.customSubcategory = '';

    // update account list
    this.accountList = this.getUpdateAccountList(this.gd.category, this.selectedItem, this.selectedAction, this.selectedSubcategory);
    if (Array.isArray(this.accountList) && this.accountList.length > 0) {
      this.selectedAccount = this.accountList[0];
    }
    this.customAccount = '';

    // update field selection list
    this.fieldSelectionList = this.getFieldSelectionList();
    this.selectedFieldSelection = this.fieldSelectionList[0];

    // update field list
    if (this.selectedAction === 'Add') {
      this.selectedFieldSelection = 'Account type';
    }
    this.fieldList = this.getUpdateFieldList(
      this.gd.category,
      this.selectedItem,
      this.selectedAction,
      this.selectedSubcategory,
      this.selectedAccount
    );
    if (Array.isArray(this.fieldList) && this.fieldList.length > 0) {
      this.selectedField = this.fieldList[0].field;
    }
    this.customField = '';
  } // onActionChange

  public onItemChange(): void {
    // update subcategory list
    this.subcatList = this.getUpdateSubcategoryList(this.gd.category, this.selectedItem, this.selectedAction);
    if (Array.isArray(this.subcatList) && this.subcatList.length > 0) {
      this.selectedSubcategory = this.subcatList[0].subcat;
    }
    this.customSubcategory = '';

    // update account list
    this.accountList = this.getUpdateAccountList(this.gd.category, this.selectedItem, this.selectedAction, this.selectedSubcategory);
    if (Array.isArray(this.accountList) && this.accountList.length > 0) {
      this.selectedAccount = this.accountList[0];
    }
    this.customAccount = '';

    // update field selection list
    this.fieldSelectionList = this.getFieldSelectionList();
    this.selectedFieldSelection = this.fieldSelectionList[0];

    // update field list
    if (this.selectedAction === 'Add') {
      this.selectedFieldSelection = 'Account type';
    }
    this.fieldList = this.getUpdateFieldList(
      this.gd.category,
      this.selectedItem,
      this.selectedAction,
      this.selectedSubcategory,
      this.selectedAccount
    );
    if (Array.isArray(this.fieldList) && this.fieldList.length > 0) {
      this.selectedField = this.fieldList[0].field;
    }
    this.customField = '';
  } // onItemChange

  public onSubcategoryChange(): void {
    // update account list
    this.accountList = this.getUpdateAccountList(this.gd.category, this.selectedItem, this.selectedAction, this.selectedSubcategory);
    if (Array.isArray(this.accountList) && this.accountList.length > 0) {
      this.selectedAccount = this.accountList[0];
    }

    // update field selection list
    this.fieldSelectionList = this.getFieldSelectionList();
    this.selectedFieldSelection = this.fieldSelectionList[0];

    // update field list
    if (this.selectedAction === 'Add') {
      this.selectedFieldSelection = 'Account type';
    }
    this.fieldList = this.getUpdateFieldList(
      this.gd.category,
      this.selectedItem,
      this.selectedAction,
      this.selectedSubcategory,
      this.selectedAccount
    );
    if (Array.isArray(this.fieldList) && this.fieldList.length > 0) {
      this.selectedField = this.fieldList[0].field;
    }
  } // onSubcategoryChange

  public onAccountChange(): void {
    // update field selection list
    this.fieldSelectionList = this.getFieldSelectionList();
    this.selectedFieldSelection = this.fieldSelectionList[0];

    // update field list
    this.fieldList = this.getUpdateFieldList(
      this.gd.category,
      this.selectedItem,
      this.selectedAction,
      this.selectedSubcategory,
      this.selectedAccount
    );
    if (Array.isArray(this.fieldList) && this.fieldList.length > 0) {
      this.selectedField = this.fieldList[0].field;
    }
  } // onAccountChange

  public onFieldSelectionChange() {
    /*
        // update field selection list
        this.fieldSelectionList = this.getFieldSelectionList();
        this.selectedFieldSelection = this.fieldSelectionList[0];
        */

    // update field list
    this.fieldList = this.getUpdateFieldList(
      this.gd.category,
      this.selectedItem,
      this.selectedAction,
      this.selectedSubcategory,
      this.selectedAccount
    );
    if (Array.isArray(this.fieldList) && this.fieldList.length > 0) {
      this.selectedField = this.fieldList[0].field;
    }
  } // onFieldSelectionChange

  public onFieldChange(): void {
    // no action required
  } // onFieldChange

  public subcategoryListContains(subcatList: any, subcat: string): boolean {
    let result = false;
    let i = subcatList.length;
    while (--i >= 0 && !result) {
      result = subcatList[i].subcat === subcat;
    }
    return result;
  } // subcategoryListContains

  public fieldListContains(fieldList: any, field: string): boolean {
    let result = false;
    let i = fieldList.length;
    while (--i >= 0 && !result) {
      result = fieldList[i].field === field;
    }
    return result;
  } // fieldListContains

  public getUpdateSubcategoryList(category: any, item: string, action: string): any {
    const currentSubcatList: any = this.gd.getSubcategories(category);
    const possibleSubcatList: any = this.gd.getCategoryInfoSubcatList(category);
    let result: any = [];

    // build list of possible subcategories that are not in current subcategories
    const addableSubcatList: any = [];
    for (const subcatobj of possibleSubcatList) {
      if (!this.subcategoryListContains(currentSubcatList, subcatobj.subcat)) {
        addableSubcatList.push(subcatobj);
      }
    }
    // addableSubcatList.push({subcat:'custom', label:'custom'});

    if (item === 'Subcategory') {
      if (action === 'Add') {
        result = addableSubcatList;
      }

      if (action === 'Delete') {
        result = currentSubcatList;
      }
    }

    if (item === 'Account') {
      if (action === 'Add') {
        result = currentSubcatList;
      }

      if (action === 'Delete') {
        result = currentSubcatList;
      }
    }

    if (item === 'Field') {
      if (action === 'Add') {
        result = currentSubcatList;
      }

      if (action === 'Delete') {
        result = currentSubcatList;
      }
    }

    return result;
  } // getUpdateSubcategoryList

  public getUpdateAccountList(category: any, item: string, action: string, subcat: string): string[] {
    const currentAccountList: string[] = this.gd.getAccountsList(category, subcat);
    const possibleAccountList: string[] = this.gd.getCategoryInfoAccountsList(category, subcat);
    let result: string[] = [];

    // build list of possible accounts that are not in current accounts
    const addableAccountList: string[] = [];
    if (subcat !== 'custom') {
      for (const accountName of possibleAccountList) {
        if (currentAccountList.indexOf(accountName) === -1) {
          addableAccountList.push(accountName);
        }
      }
    }
    // addableAccountList.push('custom');

    if (item === 'Subcategory') {
      if (action === 'Add') {
        result = addableAccountList;
      }

      if (action === 'Delete') {
        result = [];
      }
    }

    if (item === 'Account') {
      if (action === 'Add') {
        // result = addableAccountList;
        console.log('getUpdateAccountList -- subcat: ' + subcat); // %//
        result = categoryInfo[this.gd.category.attributeName][subcat].accountNames;
      }

      if (action === 'Delete') {
        result = currentAccountList;
      }
    }

    if (item === 'Field') {
      if (action === 'Add') {
        result = currentAccountList;
      }

      if (action === 'Delete') {
        result = currentAccountList;
      }
    }

    return result;
  } // getUpdateAccountList

  public getFieldSelectionList(): string[] {
    const result = [];
    result.push('Account type');
    if (this.selectedItem === 'Field' && this.selectedAction === 'Add') {
      result.push('Field name');
    }
    return result;
  } // getFieldSelectionList

  public getUpdateFieldList(category: any, item: string, action: string, subcat: string, accountName: string): any {
    const currentFieldList: any = this.gd.getFieldsList(category, subcat, accountName);
    const possibleFieldList: any = this.gd.getPossibleFieldList();
    let result: any[] = [];
    let accountType: string;

    // build list of possible fields that are not in current fields
    const addableFieldList: any = [];
    if (subcat !== 'custom' && accountName !== 'custom') {
      for (const fieldobj of possibleFieldList) {
        if (!this.fieldListContains(currentFieldList, fieldobj.field)) {
          addableFieldList.push(fieldobj);
        }
      }
    }
    // addableFieldList.push({field:'custom', label:'custom'});

    if (item === 'Subcategory') {
      if (action === 'Add') {
        // result = addableFieldList;
        // for now assume at most one account type can be associated with a subcategory
        accountType = categoryInfo[this.gd.category.attributeName][subcat].accountTypes[0];
        result.push({ field: accountType, label: accountType });
        this.selectedFieldSelection = 'Account type';
        this.selectedField = accountType;
      }
      if (action === 'Delete') {
        result = [];
      }
    }

    if (item === 'Account') {
      if (action === 'Add') {
        // result = addableFieldList;
        // for now assume at most one account type can be associated with a subcategory
        accountType = categoryInfo[this.gd.category.attributeName][subcat].accountTypes[0];
        result.push({ field: accountType, label: accountType });
        this.selectedFieldSelection = 'Account type';
        this.selectedField = accountType;
      }

      if (action === 'Delete') {
        result = [];
      }
    }

    if (item === 'Field') {
      if (action === 'Add') {
        // result = addableFieldList;
        if (this.selectedFieldSelection === 'Account type') {
          for (const funcAccountType in accountTypes) {
            if (accountTypes.hasOwnProperty(funcAccountType)) {
              result.push({ field: funcAccountType, label: funcAccountType });
            }
          }
        }
        if (this.selectedFieldSelection === 'Field name') {
          for (const field in possibleFieldNames) {
            if (possibleFieldNames.hasOwnProperty(field)) {
              result.push({ field, label: possibleFieldNames[field].label });
            }
          }
        }
      }

      if (action === 'Delete') {
        result = currentFieldList;
      }
    }

    return result;
  } // getUpdateFieldList

  public performAction(): void {
    let wantRefresh = true;
    const hadError = false;
    const item: string = this.selectedItem;
    const action: string = this.selectedAction;
    const subcat: string = this.selectedSubcategory;
    const accountName: string = this.selectedAccount;
    const fieldSelection: string = this.selectedFieldSelection;
    const field: string = this.selectedField;
    const actndx: number = this.gd.getActndx(this.gd.category, subcat, accountName);

    if (item === 'Subcategory') {
      if (action === 'Add') {
        /*
                if (subcat === 'custom') subcat = this.customSubcategory
                if (account === 'custom') account = this.customAccount;
                if (field === 'custom') field = this.customField;

                if (CValidityCheck.checkAttributeNameValidity('subcat', subcat, this.messages)) hadError = true;
                if (CValidityCheck.checkAttributeNameValidity('account', account, this.messages)) hadError = true;
                if (CValidityCheck.checkAttributeNameValidity('field', field, this.messages)) hadError = true;
                */
      }

      if (action === 'Delete') {
        if (!confirm('Do you intend to delete the subcategory:\n' + this.gd.category[subcat].label)) {
          wantRefresh = false;
        }
      }
    } // Subcategory

    if (item === 'Account') {
      if (action === 'Add') {
        /*
                if (account === 'custom') account = this.customAccount;
                if (field === 'custom') field = this.customField;

                if (CValidityCheck.checkAttributeNameValidity('account', account, this.messages)) hadError = true;
                if (CValidityCheck.checkAttributeNameValidity('field', field, this.messages)) hadError = true;
                */
      }

      if (action === 'Delete') {
        if (
          actndx !== -1 &&
          this.gd.category[subcat].accounts[actndx].hasOwnProperty('isRequired') &&
          this.gd.category[subcat].accounts[actndx].isRequired.val
        ) {
          this.messages.push(accountName + ' is required and cannot be deleted');
          wantRefresh = false;
        } else {
          if (!confirm('Do you intend to delete the account:\n' + this.gd.category[subcat].label + '->' + accountName)) {
            wantRefresh = false;
          }
        }
      }
    } // Account

    if (item === 'Field') {
      if (action === 'Add') {
      }

      if (action === 'Delete') {
        if (
          actndx !== -1 &&
          this.gd.category[subcat].accounts[actndx][field].hasOwnProperty('isRequired') &&
          this.gd.category[subcat].accounts[actndx].isRequired
        ) {
          this.messages.push(this.gd.category[subcat].accounts[actndx][field].label + ' is required and cannot be deleted');
          wantRefresh = false;
        } else {
          if (
            !confirm(
              'Do you intend to delete the field:\n' +
                this.gd.category[subcat].label +
                '->' +
                accountName +
                '->' +
                this.gd.category[subcat].accounts[actndx][field].label
            )
          ) {
            wantRefresh = false;
          }
        }
      }
    } // Field

    // update screen
    if (wantRefresh && !hadError) {
      // make changes to the screen data model behind the scenes
      ItemManagement.doAction(this.gd, item, action, subcat, actndx, accountName, fieldSelection, field);

      // make changes to the application data model (avoid doing this if possible)
      // this.component.update();

      // update screen to reflect changes in the data model
      this.gd.areAccountsVisible = this.gd.createAreAccountsVisible(this.gd.showAllAccounts);
      this.gd.areFieldsVisible = this.gd.createAreFieldsVisible(this.gd.showAllFields);
      this.gd.currentSubcategories = this.gd.getSubcategories(this.gd.category);
      this.gd.currentAccounts = this.gd.getAccounts(this.gd.category);
      this.gd.currentFields = this.gd.getFields(this.gd.category);

      /////////////////////////////////////////////////
      // updates for item management feature
      /////////////////////////////////////////////////

      // update subcategory list
      this.subcatList = this.getUpdateSubcategoryList(this.gd.category, this.selectedItem, this.selectedAction);
      if (Array.isArray(this.subcatList) && this.subcatList.length > 0) {
        this.selectedSubcategory = this.subcatList[0].subcat;
      }
      this.customSubcategory = '';

      // update account list
      this.accountList = this.getUpdateAccountList(this.gd.category, this.selectedItem, this.selectedAction, this.selectedSubcategory);
      if (Array.isArray(this.accountList) && this.accountList.length > 0) {
        this.selectedAccount = this.accountList[0];
      }
      this.customAccount = '';

      // update field list
      this.fieldList = this.getUpdateFieldList(
        this.gd.category,
        this.selectedItem,
        this.selectedAction,
        this.selectedSubcategory,
        this.selectedAccount
      );
      if (Array.isArray(this.fieldList) && this.fieldList.length > 0) {
        this.selectedField = this.fieldList[0].field;
      }
      this.customField = '';
    }
  } // performAction

  public addCategoryShadowData(category: any, destSubcategory: string, field: string): number {
    const method = 'guideline';
    let sum = 0;
    for (const subcat of Object.keys(category)) {
      if (skipSubcatAttributes.indexOf(subcat) === -1) {
        for (const account of category[subcat].accounts) {
          if (typeof account === 'object' && account.hasOwnProperty(destSubcategory) && account.hasOwnProperty(field)) {
            console.log('account: ' + account.accountName.val + '   ' + field + ': ' + account[field].val + '   ' + account[destSubcategory].val);
            const val = account[field].val;
            if (typeof val === 'number') {
              sum = sum + val;
            } else if (typeof val === 'string' && !isNaN(+val)) {
              sum = sum + Number(val);
            }
          }
        }
      }
    }
    return sum;
  } // addCategoryShadowData

  public addSubcategoryShadowData(category: any, destSubcategory: string, selectedSubcategory: string, field: string): number {
    let sum = 0;
    for (const subcat of Object.keys(category)) {
      if (skipSubcatAttributes.indexOf(subcat) === -1) {
        for (const account of category[subcat].accounts) {
          if (typeof account === 'object' && account.hasOwnProperty(destSubcategory) && account.hasOwnProperty(field)) {
            if (selectedSubcategory === '' || account[destSubcategory].val === selectedSubcategory) {
              console.log('account: ' + account.accountName.val + '   ' + field + ': ' + account[field].val);
              const val = account[field].val;
              if (typeof val === 'number') {
                sum = sum + val;
              } else if (typeof val === 'string' && !isNaN(+val)) {
                sum = sum + Number(val);
              }
            }
          }
        }
      }
    }
    return sum;
  } // addSubcategoryShadowData

  public runCafrTests(dataModelService: any): void {
    const method = 'guideline';

    console.log('test results');
    console.log('dataModelService.dataModel:');
    console.log(dataModelService.dataModel);

    // initialize data required in subsequent tests
    const plan = dataModelService.dataModel.persistent.header.curplan;
    console.log('plan: ' + plan);

    const income = dataModelService.dataModel.persistent.plans[plan].income;
    console.log('income: ', income);

    const budget = dataModelService.dataModel.persistent.plans[plan].budget;
    console.log('budget: ', budget);

    const assets = dataModelService.dataModel.persistent.plans[plan].assets;
    console.log('assets: ', assets);

    const assetProtection = dataModelService.dataModel.persistent.plans[plan].assetProtection;
    console.log('assetProtection: ', assetProtection);

    const liabilities = dataModelService.dataModel.persistent.plans[plan].liabilities;
    console.log('liabilities: ', liabilities);

    // parameters for function invocation
    const categories = {
      income,
      budget,
      assets,
      assetProtection,
      liabilities
    };

    /*
        // CAFR information managed in DataModelService class (outdated version of CAFR management)
        console.log(' ');
        console.log('dataModelService version of CAFR');
        let result = dataModelService.getStepCAFR(income,budget,assets,assetProtection,liabilities);
        console.log('getStepCAFR:');
        console.log(result);

        let method = 'guideline';
        // getCAFR(method,categories, whichData, step, category, subcat, account);
        console.log('availableGuidelineCAFR: ' + dataModelService.getCAFR(method,categories,'availableGuidelineCAFR'));
        console.log('remainingGuidelineCAFR: ' + dataModelService.getCAFR(method,categories,'remainingGuidelineCAFR'));
        console.log('availableActualCAFR: ' + dataModelService.getCAFR(method,categories,'availableActualCAFR'));
        console.log('remainingActualCAFR: ' + dataModelService.getCAFR(method,categories,'remainingActualCAFR'));
        console.log('guidelineCAFR,1: ' + dataModelService.getCAFR(method,categories,'guidelineCAFR',1));
        console.log('guidelineCAFR,1,assets: ' + dataModelService.getCAFR(method,categories,'guidelineCAFR',1,'assets'));
        console.log('guidelineCAFR,1,assets,emergencySavings: ' + dataModelService.getCAFR(method,categories,'guidelineCAFR',1,'assets','emergencySavings'));
        console.log('guidelineCAFR,1,assets,emergencySavings,Emergency Savings: ' + dataModelService.getCAFR(method,categories,'guidelineCAFR',1,'assets','emergencySavings','Emergency Savings'));
        console.log('guidelineCAFR,2,liabilities,creditCard,Credit Card: ' + dataModelService.getCAFR(method,categories,'guidelineCAFR',2,'liabilities','creditCard','Credit Card'));
        console.log('guidelineCAFR,3,assets,cashReserves,General Savings: ' + dataModelService.getCAFR(method,categories,'guidelineCAFR',3,'assets','cashReserves','General Savings'));
        console.log('guidelineCAFR,4,assetProtection,termInsurance,Term Insurance: ' + dataModelService.getCAFR(method,categories,'guidelineCAFR',4,'assetProtection',
        'termInsurance','Term Life Insurance'));
        */

    // CAFR information managed in CafrManagement class (latest version of CAFR management)
    // display the cafrInfo object that contains all CAFR information
    console.log(' ');
    console.log('dataModelService.cafrManagement version of CAFR');
    const curYYYYMM = new Date().toISOString().substr(0, 7).slice(4, 1);

    dataModelService.cafrManagement.setNeedCafrInfoUpdate(true);
    const cafrInfoGuideline = dataModelService.cafrManagement.getCafrInfo(
      'guideline',
      curYYYYMM,
      income,
      budget,
      assets,
      assetProtection,
      liabilities
    );
    console.log('cafrInfoGuideline: ', cafrInfoGuideline);
    // dataModelService.cafrManagement.setNeedCafrInfoUpdate(false);
    console.log('getCAFR guideline step 4: ' + dataModelService.cafrManagement.getCAFR('guideline', categories, 'guidelineCAFR', 4));
    console.log('getCAFR guideline step 4 assets: ' + dataModelService.cafrManagement.getCAFR('guideline', categories, 'guidelineCAFR', 4, 'assets'));

    console.log(' ');
    dataModelService.cafrManagement.setNeedCafrInfoUpdate(true);
    const cafrInfoActual = dataModelService.cafrManagement.getCafrInfo('actual', curYYYYMM, income, budget, assets, assetProtection, liabilities);
    console.log('cafrInfoActual: ', cafrInfoActual);
    dataModelService.cafrManagement.setNeedCafrInfoUpdate(false);
    console.log('getCAFR actual step 4: ' + dataModelService.cafrManagement.getCAFR('actual', categories, 'actualCAFR', 4));
    console.log('getCAFR actual step 4 assets: ' + dataModelService.cafrManagement.getCAFR('actual', categories, 'actualCAFR', 4, 'assets'));

    /*
        // suppress subsequent automated calls to getCafrInfo (do not invoke getCafrInfo on every call to getCAFR)
        dataModelService.cafrManagement.cafrInfo = cafrInfo;  // plant carInfo from this context into carInfo class
        dataModelService.cafrManagement.setNeedCafrInfoUpdate(false);
        */

    /*
        // test various getCAFR calls for CafrManagement class

        // getCAFR(method, categories, whichData, step, category, subcat, account);
        console.log('availableGuidelineCAFR: ' +
            dataModelService.cafrManagement.getCAFR('actual',categories,'availableGuidelineCAFR'));
        console.log('remainingGuidelineCAFR: ' +
            dataModelService.cafrManagement.getCAFR('guideline',categories,'remainingGuidelineCAFR'));
        console.log('availableActualCAFR: ' +
            dataModelService.cafrManagement.getCAFR('actual',categories,'availableActualCAFR'));
        console.log('remainingActualCAFR: ' +
            dataModelService.cafrManagement.getCAFR('actual',categories,'remainingActualCAFR'));
        console.log('guidelineCAFR,1: ' +
            dataModelService.cafrManagement.getCAFR('guideline',categories,'guidelineCAFR',1));
        console.log('guidelineCAFR,1,assets: ' +
            dataModelService.cafrManagement.getCAFR('guideline',categories,'guidelineCAFR',1,'assets'));
        console.log('guidelineCAFR,1,assets,emergencySavings: ' +
            dataModelService.cafrManagement.getCAFR('guideline',categories,'guidelineCAFR',1,'assets','emergencySavings'));
        console.log('guidelineCAFR,1,assets,emergencySavings,Emergency Savings: ' +
            dataModelService.cafrManagement.getCAFR('guideline',categories,'guidelineCAFR',1,'assets','emergencySavings','Emergency Savings'));
        console.log('guidelineCAFR,2,liabilities,creditCard,Credit Card: ' +
            dataModelService.cafrManagement.getCAFR('guideline',categories,'guidelineCAFR',2,'liabilities','creditCard','Credit Card'));
        console.log('guidelineCAFR,3,assets,cashReserves,General Savings: ' +
            dataModelService.cafrManagement.getCAFR('guideline',categories,'guidelineCAFR',3,'assets','cashReserves','General Savings'));
        console.log('guidelineCAFR,4,assetProtection,termInsurance,Term Life Insurance: ' +
            dataModelService.cafrManagement.getCAFR('guideline',categories,'guidelineCAFR',4,'assetProtection','termInsurance','Term Life Insurance'));
        */

    /*
        // displan plan screen data
        console.log(' ');
        console.log('start plan screen data **********************************************************************');

        let totalIncome = dataModelService.getCategorySum(income, 'monthlyAmount', assets, liabilities, assetProtection);
        console.log('Total income: ' + totalIncome);

        let totalBudget = dataModelService.getCategorySum(budget, 'monthlyAmount', assets, liabilities, assetProtection);
        console.log('Total budget: ' + totalBudget);

        console.log('actualCafr:   ' + (totalIncome - totalBudget) + '   (income - budget)');

        let vsum = 0;
        let v = dataModelService.getSubcategorySum(budget,'giving','monthlyAmount',assets,liabilities,assetProtection);
        vsum += v;
        console.log('Giving:                      ' + v);

        v = dataModelService.getSubcategorySum(budget,'housing','monthlyAmount',assets,liabilities,assetProtection);
        vsum += v;
        console.log('Housing:                     ' + v);

        v = dataModelService.getSubcategorySum(budget,'transportation','monthlyAmount',assets,liabilities,assetProtection);
        vsum += v;
        console.log('Transportation:              ' + v);

        v = dataModelService.getSubcategorySum(budget,'food','monthlyAmount',assets,liabilities,assetProtection);
        vsum += v;
        console.log('Food:                        ' + v);

        v = dataModelService.getSubcategorySum(budget,'health','monthlyAmount',assets,liabilities,assetProtection);
        vsum += v;
        console.log('Health/Inisurance:           ' + v);

        v = dataModelService.getSubcategorySum(budget,'clothing','monthlyAmount',assets,liabilities,assetProtection);
        vsum += v;
        console.log('Clothing:                    ' + v);

        v = dataModelService.getSubcategorySum(budget,'entertainment','monthlyAmount',assets,liabilities,assetProtection)
        vsum += v;
        console.log('Entertainment/Miscellaneous: ' + v);

        console.log('                      TOTAL: ' + vsum + '   (sum of budget dataModelService.getSubcategorySum values above)');
        v = dataModelService.getCategorySum(budget, 'monthlyAmount', assets, liabilities, assetProtection);
        console.log('                      TOTAL: ' + v + '   (dataModelService.getCategorySum)');
        console.log('                       DIFF:  ' + (v - vsum));

        console.log(' ');
        console.log('CAFR Available: ' +
            '  guideline: ' + dataModelService.cafrManagement.getCAFR('guideline',categories,'availableGuidelineCAFR') +
            '  actual: '    + dataModelService.cafrManagement.getCAFR('actual',categories,'availableActualCAFR'));
        console.log('Available guidelineCAFR after subtraction of monthlyMinimum: ' + cafrInfo.remainingGuidelineCAFRAfterMinimums);
        console.log('step 1' +
            '  guidelineCAFR: ' + dataModelService.cafrManagement.getCAFR('guideline',categories,'guidelineCAFR',1) +
            '  actualCAFR: '    + dataModelService.cafrManagement.getCAFR('actual',categories,'actualCAFR',1));
        console.log('step 2' +
            '  guidelineCAFR: ' + dataModelService.cafrManagement.getCAFR('guideline',categories,'guidelineCAFR',2) +
            '  actualCAFR: '    + dataModelService.cafrManagement.getCAFR('actual',categories,'actualCAFR',2));
        console.log('step 3' +
            '  guidelineCAFR: ' + dataModelService.cafrManagement.getCAFR('guideline',categories,'guidelineCAFR',3) +
            '  actualCAFR: '    + dataModelService.cafrManagement.getCAFR('actual',categories,'actualCAFR',3));
        console.log('step 4' +
            '  guidelineCAFR: ' + dataModelService.cafrManagement.getCAFR('guideline',categories,'guidelineCAFR',4) +
            '  actualCAFR: '    + dataModelService.cafrManagement.getCAFR('actual',categories,'actualCAFR',4));
        console.log('CAFR Remaining: ' +
            '  guideline: ' + dataModelService.cafrManagement.getCAFR('guideline',categories,'remainingGuidelineCAFR') +
            '  actual: '    + dataModelService.cafrManagement.getCAFR('actual',categories,'remainingActualCAFR'));
        console.log('end plan screen data **********************************************************************');
        */

    /* test projection information (for generating graphs)
        console.log(' ');
        let dataProjectionInfo = dataModelService.cafrManagement.getDataProjectionInfo(8,assets,assetProtection,liabilities);
        console.log('dataProjectionInfo:');
        console.log(dataProjectionInfo);

        let cafrDataProjectionInfo = dataModelService.cafrManagement.getCafrDataProjectionInfo({terminationType:"monthCount", terminationValue:8},'guideline');
        console.log('cafrDataProjectionInfo: ');
        console.log(cafrDataProjectionInfo);

        console.log(' ');
        console.log('Sample asset projection values');
        console.log('Date      No CAFR    With CAFR');
        for (let i = 0; i < 8; i++)
        {
            console.log(cafrDataProjectionInfo.assets.summary.projection[i].x.substr(0,7) + '   ' +
                Math.floor(dataProjectionInfo.assets.summary.projection[i].y) + '     ' +
                Math.floor(cafrDataProjectionInfo.assets.summary.projection[i].y));
        }
        console.log('Sample productive debt projection values');
        console.log('Date      No CAFR    With CAFR');
        for (let i = 0; i < 8; i++)
        {
            console.log(cafrDataProjectionInfo.assets.summary.projection[i].x.substr(0,7) + '   ' +
                Math.floor(dataProjectionInfo.productiveDebt.summary.projection[i].y) + '     ' +
                Math.floor(cafrDataProjectionInfo.productiveDebt.summary.projection[i].y));
        }
        console.log('Sample nonproductive debt projection values');
        console.log('Date      No CAFR    With CAFR');
        for (let i = 0; i < 8; i++)
        {
            console.log(cafrDataProjectionInfo.assets.summary.projection[i].x.substr(0,7) + '   ' +
                Math.floor(dataProjectionInfo.nonproductiveDebt.summary.projection[i].y) + '     ' +
                Math.floor(cafrDataProjectionInfo.nonproductiveDebt.summary.projection[i].y));
        }
        */

    /*
        console.log('computeDataProjections(7,assets,liabilities)');
        console.log(dataModelService.computeDataProjections(7,assets,liabilities));

        console.log('getDataProjection,assets,7');
        console.log(dataModelService.getDataProjection('assets',7,assets,liabilities));

        console.log('getDataProjection,productiveDebt,7');
        console.log(dataModelService.getDataProjection('productiveDebt',7,assets,liabilities));

        console.log('getDataProjection,nonproductiveDebt,7');
        console.log(dataModelService.getDataProjection('nonproductiveDebt',7,assets,liabilities));

        console.log('getDataProjection,allDebt,7');
        console.log(dataModelService.getDataProjection('allDebt',7,assets,liabilities));

        console.log('getDataProjection,netWorth,7');
        console.log(dataModelService.getDataProjection('netWorth',7,assets,liabilities));

        let field = 'monthlyIncome';

        // add in data from shadow fields included from assets
        console.log('assets shadow data');
        sum = this.addCategoryShadowData(assets,'incomeSubcategory','monthlyIncome');
        console.log('assets sum: ' + sum);

        // add in data from shadow fields included from liabilities
        console.log('liabilities shadow data');
        sum = this.addCategoryShadowData(liabilities,'budgetSubcategory','monthlyMinimum');
        console.log('liabilities sum: ' + sum);

        // data from shadow fields included from assets with target of income subcategory of income
        console.log('shadow fields included from assets under income subcategory of income');
        sum = this.addSubcategoryShadowData(assets,'incomeSubcategory','income','monthlyIncome');
        console.log('income category sum: ' + sum);

        // data from shadow fields included from liabilities with target of entertainment subcategory of budget
        console.log('shadow fields included from liabilities with target of entertainment subcategory of budget');
        sum = this.addSubcategoryShadowData(liabilities,'budgetSubcategory','entertainment','monthlyMinimum');
        console.log('income category sum: ' + sum);

        // data from shadow fields included from liabilities with target of transportation subcategory of budget
        console.log('shadow fields included from liabilities with target of transportation subcategory of budget');
        sum = this.addSubcategoryShadowData(liabilities,'budgetSubcategory','transportation','monthlyMinimum');
        console.log('income category sum: ' + sum);

        // data from shadow fields included from liabilities with target of '' subcategory of budget (to get total for entire category )
        console.log('shadow fields included from liabilities with target of "" subcategory of budget');
        sum = this.addSubcategoryShadowData(liabilities,'budgetSubcategory','','monthlyMinimum');
        console.log('total category sum: ' + sum);
        */
  } // runCafrTests

  /*
    // test input for putItem
    invokeParms =
    {
        tableName: 'WizeFiAffiliateTree',
        action: 'putItem',
        actionParms:
        {
            affiliateID: 'abcdefg',
            node:
            {
                isActive:true,
                fee:8,
                parent: 'aaaaaaa',
                child: []
            }
        }
    }

    // test input for getItem
    invokeParms =
    {
        tableName: 'WizeFiAffiliateTree',
        action: 'getItem',
        actionParms:
        {
            affiliateID: 'abcdefg'
        }
    }

    // test input for getAllItems
    invokeParms =
    {
        {
            tableName: 'WizeFiAffiliateTree',
            action: 'getAllItems'
        }
    }

    // test input for putItem
    invokeParms =
    {
        tableName: 'WizeFiAffiliateTree',
        action: 'putItem',
        actionParms:
        {
            affiliateID: 'abcdefg',
            affiliateAlias:"u56",
            node:
            {
                isActive: true,
                fee: 8,
                parent: 'aaaaaaa',
                child: []
            }
        }
    }

    // test input for updateItem
    invokeParms =
    {
        tableName: 'WizeFiAffiliateTree',
        action: 'updateItem',
        actionParms:
        {
            affiliateID: 'ibzahde',
            version: 0,
            node:
            {
                isActive: true,
                fee: 9,
                parent: 'aaaaaaa',
                child: ['fgctfqo','zyrgvxm','mcvyihv']
            },
            updates:
            {
                // list only attributes that have a new value
                // isActive: true,
                fee: 9,
                // parent: 'aaaaaaa',
                // child: {insert:'abcdefw'},
                // child: {delete:'pqrstuv'}
            }
        }
    }
    */

  public testInvoke(dataModelService: any, invokeParms: any) {
    const processResult = (result): void => {
      console.log('Process ' + invokeParms.action + ' result here:');
      console.log(result);
      console.log('end testInvoke'); // %//
    };

    const handleError = (err: any): void => {
      console.log('Error in testInvoke:'); // %//
      console.log(err); // %//
    };

    console.log('start testInvoke'); // %//
    const tableName = invokeParms.tableName;
    const action = invokeParms.action;
    const actionParms = invokeParms.actionParms;

    dataModelService.affiliateManagement
      .invokeManageAffiliateTree(dataModelService, tableName, action, actionParms)
      .then(processResult)
      .catch(handleError);
  } // testInvoke

  public initializeAffiliateManagement(dateModelService) {
    console.log('ItemManagement.initializeAffiliateManagement');
    dateModelService.initializeAffiliateManagement();
  } // initializeAffiliateManagement

  public runAffiliateTests(dataModelService) {
    /*
        // test the dataModelService.affiliateManagement class

        console.log('rootAlias: ' + dataModelService.affiliateManagement.rootAffiliateAlias);
        console.log('rootAffiliateID: ' + dataModelService.affiliateManagement.rootAffiliateID);
        console.log('maxLevel: ' + dataModelService.affiliateManagement.maxLevel);
        */

    /*
        // let affiliateID = 'aaaaaaa';
        let affiliateID = 'fqeyxzc';  // Diana (in prod environment)
        // let affiliateID = 'yrifkyb';  // Tom Allen (in prod environment)
        // let affiliateID = 'ldmxvfd';  //  (in prod environment)

        dataModelService.affiliateManagement.getTreeAffiliateCounts(affiliateID)
        .then((treeAffiliateCounts) => {console.log('treeAffiliateCounts: ', treeAffiliateCounts)})
        .catch((err) => {console.log(err)});

        levelCount = dataModelService.affiliateManagement.getLevelCount(affiliateID);
        console.log('levelCount: ', levelCount);

        levelTotalCount = dataModelService.affiliateManagement.getLevelTotalCount(affiliateID);
        console.log('levelTotalCount: ', levelTotalCount);
        */

    //
    const affiliateID = 'fqeyxzc';

    dataModelService.affiliateManagement
      .getTier1Lists(affiliateID)
      .then(tier1Lists => {
        console.log('tier1Lists', tier1Lists);
      })
      .catch(err => {
        console.log(err);
      });
    //

    /*
        // test getAffiliatePayoutInfo
        let lpad = (str,len) =>
        {
            while (str.length < len) str = ' ' + str;
            return str;
        }   // lpadString

        let rpad = (str,len) =>
        {
            while (str.length < len) str =  str + ' ';
            return str;
        }   // rpadString

        let reportSingleMonth = (payoutInfo) =>
        {
            console.log(' ');
            console.log('Single month report:');
            console.log(
                dataModelService.cd(payoutInfo.monthDate) + '  ' +
                'WizeFiPayouts: ' + payoutInfo.wizeFiPayouts.toFixed(2) + '   ' +
                'WizeFiBank: ' + payoutInfo.wizeFiBank.toFixed(2)
            );
            for (let i = 0; i < payoutInfo.payoutList.length; i++)
            {
                console.log(dataModelService.cd(payoutInfo.payoutList[i].monthDate) + '   ' + payoutInfo.payoutList[i].payout.toFixed(2));
            }
            for (let i = 0; i < payoutInfo.maturedList.length; i++)
            {
                console.log(dataModelService.cd(payoutInfo.maturedList[i].monthDate) + '   ' + payoutInfo.maturedList[i].matured.toFixed(2));
            }
            for (let i = 0; i < payoutInfo.growingList.length; i++)
            {
                console.log(dataModelService.cd(payoutInfo.growingList[i].monthDate) + '*  ' + payoutInfo.growingList[i].growing.toFixed(2));
            }
        }   // reportSingleMonth

        let reportMultipleMonths = (payoutInfo) =>
        {
            console.log(' ');
            console.log('Multiple month report:');
            console.log(
                rpad('Month',8)             + '  ' +
                lpad('Income',6)            + '  ' +
                lpad('Payout',6)            + '  ' +
                lpad('WizeFiPayouts',13)     + '  ' +
                lpad('WizeFiBank',10)   + '  ' +
                lpad('PendingEarnings',15) + '  ' +
                'PayoutMonth'
             );
            for (let i = 0; i < payoutInfo.length; i++)
            {
                let payoutDate = '';
                if (Number(payoutInfo[i].pendingEarnings.toFixed(2)) >= 100)
                {
                    payoutDate =  '  ' + dataModelService.cd(dataModelService.changeDate(payoutInfo[i].monthDate,0,2,0).substr(0,7));
                }
                let str =
                    rpad(dataModelService.cd(payoutInfo[i].monthDate),8) + '  ' +
                    lpad(payoutInfo[i].income.toFixed(2),6)           + '  ' +
                    lpad(payoutInfo[i].payout.toFixed(2),6)           + '  ' +
                    lpad(payoutInfo[i].wizeFiPayouts.toFixed(2),13)   + '  ' +
                    lpad(payoutInfo[i].wizeFiBank.toFixed(2),10)      + '  ' +
                    lpad(payoutInfo[i].pendingEarnings.toFixed(2),15) +
                    payoutDate;
                console.log(str);
            }
        }   // reportMultipleMonths

        let affiliateID = 'fqeyxzc';

        dataModelService.affiliateManagement.getAffiliatePayoutInfo(affiliateID,'2018-05')
        .then(reportSingleMonth)
        dataModelService.affiliateManagement.getAffiliatePayoutInfo(affiliateID,'2017-09','2018-06')
        .then(reportMultipleMonths)
        .catch((err) => {console.log(err)});
        */

    /*
        dataModelService.affiliateManagement.displayBreadthTree(affiliateID);
        */

    //
    console.log('Preorder:');
    // dataModelService.affiliateManagement.displayTree(dataModelService.affiliateManagement.rootAffiliateID);
    dataModelService.affiliateManagement.displayTree('fqeyxzc');
    // dataModelService.affiliateManagement.displayTree('yrifkyb');
    // dataModelService.affiliateManagement.displayTree('mnfcpvh');  // Chad
    // dataModelService.affiliateManagement.displayTree('xhreead');  // Glenn Sugarman
    // dataModelService.affiliateManagement.displayTree('ldmxvfd');  // Melanie Fletcher (and Mark Stevens)
    //

    /*
        console.log('Postorder:')
        dataModelService.affiliateManagement.displayPostTree(dataModelService.affiliateManagement.rootAffiliateID);

        console.log('Breadthorder:')
        dataModelService.affiliateManagement.displsayBreadthTree(dataModelService.affiliateManagement.rootAffiliateID);

        console.log('root level count:')
        levelCount = dataModelService.affiliateManagement.getLevelCount(dataModelService.affiliateManagement.rootAffiliateID);
        console.log(levelCount);
        */

    /*
        // generate some test affiliateID values
        console.log('Alias  ID');
        for (let i = 1; i <= 30; i++)
        {
            let tmp = i.toString();
            if (tmp.length < 2) tmp = '0' + tmp;
            console.log('u' + tmp + '    ' + dataModelService.affiliateManagement.generateAffiliateID());
        }
        */

    /*
        let affiliateID = 'yrifkyb';  // Tom Allen  (production)
        // let affiliateID = 'ylrjxfe';  // Jeff  (production)
        dataModelService.affiliateManagement.displayTree(affiliateID);
        levelCount = dataModelService.affiliateManagement.getLevelCount(affiliateID);
        console.log(levelCount);
        */

    /*
        dataModelService.affiliateManagement.displayTree('andvmun');
        levelCount = dataModelService.affiliateManagement.getLevelCount('andvmun');
        console.log(levelCount);

        console.log("addNode('potiyue','u22',8,dataModelService.affiliateManagement.rootAffiliateID)) -- already present");
        console.log("addNode('mmwwjcy','u26',8,'xyz') -- bad parent");
        console.log("addNode('hjpyutr','u27',8, dataModelService.affiliateManagement.rootAffiliateID");
        console.log("addNode('xbcuclr','u28',8,'wzisnqh')");

        dataModelService.affiliateManagement.addNode('potiyue','u22',8, dataModelService.affiliateManagement.rootAffiliateID)  // already present
        .catch((err) => {console.log(err)});

        dataModelService.affiliateManagement.addNode('mmwwjcy','u26',8,'xyz')  // invalid parent
        .catch((err) => {console.log(err)});

        dataModelService.affiliateManagement.addNode('hjpyutr','u27',8, dataModelService.affiliateManagement.rootAffiliateID)
        .catch((err) => {console.log(err)});

        dataModelService.affiliateManagement.addNode('xbcuclr','u28',8,'wzisnqh')
        .catch((err) => {console.log(err)});

        dataModelService.affiliateManagement.displayTree(dataModelService.affiliateManagement.rootAffiliateID);
        */

    /*
        console.log(' ');
        // let affiliateID = dataModelService.affiliateManagement.rootAffiliateID;
        let affiliateID = 'ylrjxfe';
        // let affiliateID = 'fqeyxzc';
        let parms = dataModelService.affiliateManagement.getTreeStats(affiliateID);
        console.log('node: ' + affiliateID + '(' + dataModelService.affiliateManagement.ID2Alias[affiliateID] + ')');
        console.log('activeNodeCount: ' + parms.activeNodeCount);
        console.log('inactiveNodeCount: ' + parms.inactiveNodeCount);
        console.log('allNodeCount: ' + parms.allNodeCount);
        console.log('height: ' + parms.height);
        console.log('maxWidth: ' + parms.maxWidth);
        */

    /*
        console.log('Sample affiliate Alias values:')
        console.log('joe: ' +dataModelService.affiliateManagement.generateAffiliateAlias('joe'));
        console.log('u02: ' + dataModelService.affiliateManagement.generateAffiliateAlias('u02'));

        console.log('Sample affiliate ID values:')
        for (let i = 1; i <= 5; i++) console.log(dataModelService.affiliateManagement.generateAffiliateID());
        */

    /*
        console.log(' ');
        console.log('Leader Board');
        console.log('Rank  User  Affiliates')
        let leaderBoardInfo = dataModelService.affiliateManagement.getLeaderBoardInfo('wzisnqh');
        for (let i = 0; i < leaderBoardInfo.length; i++)
        {
            console.log(leaderBoardInfo[i].rank + '  ' + leaderBoardInfo[i].user + '  ' + leaderBoardInfo[i].affiliateCount);
        }

        console.log(' ');
        console.log('Affiliate Breakdown');
        console.log('Tier  Commission  Affiliates  UnlockCount  Earnings');
        let affiliateBreakdownInfo = dataModelService.affiliateManagement.getAffiliateBreakdownInfo('aaaaaaa');
        for (let i = 0; i < affiliateBreakdownInfo['tierList'].length; i++)
        {
            console.log(affiliateBreakdownInfo['tierList'][i].tier + '  ' +
                affiliateBreakdownInfo['tierList'][i].commission + '  ' +
                affiliateBreakdownInfo['tierList'][i].affiliateCount + '  ' +
                affiliateBreakdownInfo['tierList'][i].unlockCount + '  ' +
                affiliateBreakdownInfo['tierList'][i].earnings);
        }
        console.log('affiliateSum: ' + affiliateBreakdownInfo['affiliateSum'] + '  earningsSum: ' + affiliateBreakdownInfo['earningsSum']);
        */

    /*
        console.log(' ');
        console.log('Affiliate Calculator');
        let tier1Affiliates = 20;
        let affiliatesMultiplier = 10;
        let affiliateCalculatorInfo = dataModelService.affiliateManagement.getAffiliateCalculatorInfo(tier1Affiliates,affiliatesMultiplier);
        console.log('tier1Affiliates: ' + affiliateCalculatorInfo['tier1Affiliates']);
        console.log('affiliatesMultiplier: ' + affiliateCalculatorInfo['affiliatesMultiplier']);
        console.log('Tier  Commission  Affiliates  UnlockCount  Earnings');
        for (let i = 0; i < affiliateCalculatorInfo['tierList'].length; i++)
        {
            console.log(affiliateCalculatorInfo['tierList'][i].tier + '  ' +
                affiliateCalculatorInfo['tierList'][i].commission + '  ' +
                affiliateCalculatorInfo['tierList'][i].affiliateCount + '  ' +
                affiliateCalculatorInfo['tierList'][i].unlockCount + '  ' +
                affiliateCalculatorInfo['tierList'][i].earnings);
        }
        console.log('affiliateSum: ' + affiliateCalculatorInfo['affiliateSum'] + '  earningsSum: ' + affiliateCalculatorInfo['earningsSum']);
        */

    /*
        console.log(' ');
        console.log('Test Lambda function manageAffiliateTree');
        let invokeParms: any;
        */

    // NOTE: the following tests run asynchronously (gives correct results -- not worth the time to make them synchronous)

    /*
        // test getItem
        console.log("Test getItem('aaaaaaa')");
        invokeParms =
        {
            tableName: 'WizeFiAffiliateTree',
            action: 'getItem',
            actionParms:
            {
                affiliateID:'aaaaaaa'
            }
        }
        this.testInvoke(dataModelService, invokeParms);
        */

    // test getItem
    console.log('Test getItem');
    console.log("Test getItem('aaaaaaa')");
    const invokeParms = {
      tableName: 'WizeFiAffiliateTree',
      action: 'getItem',
      actionParms: {
        affiliateID: 'aaaaaaa'
      }
    };
    this.testInvoke(dataModelService, invokeParms);

    /*
        // test getAllItems
        console.log('Test getAllItems');
        invokeParms =
        {
            tableName: 'WizeFiAffiliateTree',
            action: 'getAllItems'
        }
        this.testInvoke(dataModelService, invokeParms);
        */

    /*
        // test putItem
        console.log('Test putItem');
        console.log("Test putItem('abcdefh')");
        invokeParms =
        {
            tableName: 'WizeFiAffiliateTree',
            action: 'putItem',
            actionParms:
            {
                affiliateID: 'abcdefg',
                affiliateAlias:"u56",
                node:
                {
                    isActive: true,
                    fee: 8,
                    parent: 'aaaaaaa',
                    child: []
                }
            }
        }
        this.testInvoke(dataModelService, invokeParms);
        */

    /*
        // test updateItem
        invokeParms =
        {
            tableName: 'WizeFiAffiliateTree',
            action: 'updateItem',
            actionParms:
            {
                affiliateID: 'ibzahde',
                version: 7,
                node:
                {
                    isActive: true,
                    fee: 12,
                    parent: 'aaaaaaa',
                    child: ['fgctfqo','zyrgvxm','mcvyihv']
                },
                updates:
                {
                    // list only attributes that have a new value
                    // isActive: true,
                    fee: 12,
                    // parent: 'aaaaaaa',
                    // insertChild: 'abcdefw',
                    // deleteChild: 'pqrstuv'
                }
            }
        }
        console.log('before testInvoke updateItem');
        this.testInvoke(dataModelService, invokeParms);
        console.log('after  testInvoke updateItem');
        */

    /*
        // test changeItemInfo
        invokeParms =
        {
            tableName: 'WizeFiAffiliateTree',
            action: 'changeItemInfo',
            actionParms:
            {
                affiliateID: 'aaaaaaa',
                updates:
                {
                    affiliateAlias: 'uxx',
                    node:
                    {
                        // fee: 12,
                        insertChild: 'abcmxyz',
                        deleteChild: 'ppngahv'
                    }
                }
            }
        }
        console.log('before testInvoke changeItemInfo');
        this.testInvoke(dataModelService, invokeParms);
        console.log('after  testInvoke changeItemInfo');
        */

    /*
        // test lookupAliasID
        console.log('Test lookupAffiliateID');
        console.log("lookupAffiliateID('Tahni')");
        invokeParms =
        {
            tableName: 'WizeFiAffiliateTree',
            action: 'lookupAffiliateID',
            actionParms:
            {
                affiliateAlias: 'u00',
            }
        }
        this.testInvoke(dataModelService, invokeParms);
        */

    /*
        // test addNode (add child and parent info to memory resident and DynamoDB version of the data)
        console.log('before testInvoke addNode')
        dataModelService.affiliateManagement.addNode('ibzahde', 'u02', 8, 'aaaaaaaa');
        console.log('after  testInvoke addNode')
        */

    /*
        // test addNode for a few nodes into an empty tree

        let step1 = () =>
        {
            console.log("addNode('ibzahde', 'u01', 8, 'aaaaaaa')");
            return dataModelService.affiliateManagement.addNode('ibzahde', 'u01', 8, 'aaaaaaa')
        };

        let step2 = () =>
        {
            console.log("addNode('dkeviow', 'u02', 8, 'aaaaaaa')");
            return dataModelService.affiliateManagement.addNode('dkeviow', 'u02', 8, 'aaaaaaa')
        };

        let step3 = () =>
        {
            console.log("addNode('kdwrdju', 'u03', 8, 'ibzahde')");
            return dataModelService.affiliateManagement.addNode('kdwrdju', 'u03', 8, 'ibzahde')
        };

        let step4 = () =>
        {
            console.log("addNode('mnsjtyu', 'u04', 8, 'ibzahde')");
            return dataModelService.affiliateManagement.addNode('mnsjtyu', 'u04', 8, 'ibzahde')
        };

        let processResult = ():void => {console.log('after  adding a few nodes into an empty tree')};
        let handleError = (err:any):void => {console.log(err)};

        console.log('before adding a few nodes into an empty tree');
        step1()
        .then(step2)
        .then(step3)
        .then(step4)
        .then(processResult)
        .catch(handleError);
        */

    /*
        //test changeItemInfo
        let processResult = (result):void => {console.log('after changeItemInfo: ' + result)};
        let handleError = (err:any):void => {console.log(err)};

        let updates =
        {
            affiliateAlias: 'uxx',
            node:
            {
                fee: 12,
                insertChild: 'abcmxyz'
            }
        }
        console.log('before changeItemInfo');
        dataModelService.affiliateManagement.changeItemInfo('aaaaaaa', updates)
        .then(processResult)
        .catch(handleError);
        */

    /*
        // list affiliate level 1 information
        // let affiliateID = 'aaaaaaa';   // root
        let affiliateID = 'ylrjxfe';   // thefosters
        dataModelService.affiliateManagement.getLevel1Affiliates(affiliateID)
        .then((level1AffiliateList) => {console.log('level1AffiliateList: ', level1AffiliateList)})
        .catch((err) => {console.log(err)});
        */

    /*
        let affiliateID = 'ylrjxfe';   // thefosters
        dataModelService.affiliateManagement.retrievePendingEarnings(affiliateID)
        .then((pendingEarnings) => {console.log('pendingEarnings: ', pendingEarnings)})
        .catch((err) => {console.log(err)});
        */

    /*
        dataModelService.affiliateManagement.getUniqueAffiliateID()
        .then((affiliateID) => {console.log('affiliateID: ' + affiliateID)})
        .catch((err) => {console.log(err)});
        */

    /*
        dataModelService.affiliateManagement.getUniqueAffiliateID()
        .then((affiliateID) => {console.log('affiliateID: ', affiliateID)})
        .catch((err) =>
        {
            // kludge to work around "Missing credentials in config" error (things work on the second try)
            dataModelService.affiliateManagement.getUniqueAffiliateID()
            .then((affiliateID) => {console.log('affiliateID: ', affiliateID)})
            .catch((err) => {console.log('DataModelService -- error: ', err)});
        })
        */

    /*
        // note: function is called twice to accomodate problem with "Missing credentials in config" error (things work on the second try)
        dataModelService.affiliateManagement.getUniqueAffiliateID()
        .then((affiliateID) => {Promise.resolve()})
        .catch((err) => {Promise.resolve()})
        .then(dataModelService.affiliateManagement.getUniqueAffiliateID)
        .then((affiliateID) => {console.log('affiliateID: ' + affiliateID)})
        .catch((err) => {console.log(err)});
        */
  } // runAffiliateTests

  public runRetrievePendingEarnings(dataModelService: any) {
    const retrievePendingEarnings = affiliateID =>
      new Promise((resolve, reject) => {
        // set params to guide function invocation
        const payload = { affiliateID };
        const params = {
          FunctionName: 'retrievePendingEarnings',
          Payload: JSON.stringify(payload)
        };

        // invoke Lambda function to process data
        dataModelService.dataModel.global.lambda.invoke(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            const funcPayload = JSON.parse(data.Payload);
            resolve(funcPayload);
          }
        }); // lambda invoke
      }); // return Promise // retrievePendingEarnings
    // retrievePendingEarnings('ylrjxfe')  // Jeff
    retrievePendingEarnings('ldmxvfd') // Melanie
      .then(result => {
        console.log('Pending earnings: ' + result);
      })
      .catch(err => {
        console.log(err);
      });
  } // runRetrievePendingEarnings

  public runScheduleTests(dataModelService: any): void {
    const convertArrayOfObjectsToCSV = data => {
      let columnDelimiter, lineDelimiter, keys, result, count;

      columnDelimiter = ',';
      lineDelimiter = '\n';

      keys = Object.keys(data[0]);

      result = '';
      result += keys.join(columnDelimiter);
      result += lineDelimiter;

      for (const item of data) {
        count = 0;
        for (const key of keys) {
          if (count > 0) {
            result += columnDelimiter;
          }
          result += item[key];
          count++;
        }
        result += lineDelimiter;
      }

      return result;
    }; // convertArrayOfObjectsToCSV

    const downloadCSV = (sourceData, filename) => {
      let csv, data, link;

      csv = convertArrayOfObjectsToCSV(sourceData);
      csv = 'data:text/csv;charset=utf-8,' + csv;
      data = encodeURI(csv);

      link = document.createElement('a');
      link.setAttribute('href', data);
      link.setAttribute('download', filename);
      link.click();
    }; // downloadCSV

    let numMonths, method, CAFRscheduleSummary;

    numMonths = 240; // 20 years
    // numMonths = 480;  // 40 years
    // numMonths = 720  // 60 years
    // method = 'guideline';
    method = 'actual';

    // capture the necessary data
    dataModelService.cafrManagement.getCafrDataProjectionInfo({ terminationType: 'monthCount', terminationValue: numMonths }, method);
    console.log('CAFRscheduleDetails (' + method + '): ', dataModelService.cafrManagement.CAFRscheduleDetails);

    // download a CSV file that contains the CAFRscheduleDetails data
    downloadCSV(dataModelService.cafrManagement.CAFRscheduleDetails[method], 'WizeFiCAFRscheduleDetails_' + method + '.csv'); // %//

    // download a CSV file that contains the CAFRcalculationDetails data
    downloadCSV(dataModelService.cafrManagement.CAFRcalculationDetails[method], 'WizeFiCAFRcalculationDetails_' + method + '.csv'); // %//

    // download a CSV file that contains the CAFRprojectionDetails data
    downloadCSV(dataModelService.cafrManagement.CAFRprojectionDetails, 'WizeFiCAFRprojectionDetails.csv'); // %//

    // show CAFR schedule
    CAFRscheduleSummary = dataModelService.cafrManagement.getCAFRscheduleSummary();
    console.log('CAFRscheduleSummary (' + method + '): ', CAFRscheduleSummary);

    // show projections schedule
    /* not yet implemented for guideline
        projectionsSummary = dataModelService.cafrManagement.getProjectionsSummary();
        console.log('projectionsSummary (' + method + '): ', projectionsSummary);
        */

    /*
        // show goal dates
        goalDates = dataModelService.cafrManagement.getGoalDates();
        console.log('goalDates: ', goalDates);

        method = 'actual';

        // capture the necessary data
        dataModelService.cafrManagement.getCafrDataProjectionInfo({terminationType:"monthCount", terminationValue:numMonths},method);
        console.log('CAFRscheduleDetails (' + method + '): ', dataModelService.cafrManagement.CAFRscheduleDetails);

        // show CAFR schedule
        CAFRscheduleSummary = dataModelService.cafrManagement.getCAFRscheduleSummary();
        console.log('CAFRscheduleSummary (' + method + '): ', CAFRscheduleSummary);

        // show projections schedule
        projectionsSummary = dataModelService.cafrManagement.getProjectionsSummary();
        console.log('projectionsSummary (' + method + '): ', projectionsSummary);

        // show goal dates
        goalDates = dataModelService.cafrManagement.getGoalDates();
        console.log('goalDates: ', goalDates);
        */
  } // runScheduleTests

  public runSubscriptionManagementTests(dataModelService: any) {
    const subscriptionManagement = new SubscriptionManagement(dataModelService);

    /*
        // test generateClientToken  (this function is replaced by dataModelService.braintreeManagement.generateClientToken)
        let mode = environment.mode;
        subscriptionManagement.generateClientToken()
        .then((clientToken) => {console.log('clientToken: ' + clientToken)})
        .catch((err) => {console.log(err)});
        */

    /*
        // test establishSubscription
        let subscriptionInfo =
        {
            wizeFiID: "87654",
            firstName: "Joe",
            lastName: "Jones",
            email: "joe@abc.com",
            paymentMethodNonce: "fake-valid-nonce",
            planId: "WizeFiPlan"
        };

        NOTE: this is replaced by dataModelService.braintreeManagement.establishSubscription
        subscriptionManagement.establishSubscription(subscriptionInfo)
        .then((parms) => {console.log(parms)})
        .catch((err) => {console.log(err)});
        */
  } // runSubscriptionManagementTests

  public runBraintreeManagementTests(dataModelService: any) {
    // COMBINED

    /*
        let subscriptionInfo: any;  // workaround to deal with type compatability issues in Typescript
        subscriptionInfo =
        {
            wizeFiID: '34567',
            firstName: 'Joe',
            lastName: 'Testing2',
            email: 'joe@abc.com',
            // paymentMethodNonce: 'fake-valid-nonce',
            noncePayload:
            {
                nonce: 'fake-valid-nonce',
                type: 'CreditCard',         // payment type (e.g. CreditCard, Paypal)
                details:
                {
                    lastTwo: 81,  // last two digits of credit card number
                    type: 'Visa'      // credit card type (e.g. Visa, Master Card)
                }
            },
            planId: 'WizeFiPlan'
        }

        let wantTrialPeriod = true;
        if (wantTrialPeriod)
        {
            subscriptionInfo.trialPeriod = true;
            subscriptionInfo.trialDuration = 30;
            subscriptionInfo.trialDurationUnit = 'day';
        }

        dataModelService.braintreeManagement.establishSubscription(subscriptionInfo)
        .then((result) => {console.log('establishSubscription: ', result)})
        .catch((err) => {console.log(err)});
        */

    /*
        // let customerID = '10213363587114053';   // Dave (in dev environment)  outdated
        // let customerID = '10100417492544182';   // Greg (in dev environment)  outdated
        // let customerID = '10100507229934732';   // Greg (in prod environment)
        // let customerID = '10154672182542352';   // Jeff (in dev environment)
        // let customerID = '10154672182542352';   // Jeff (in prod environment)
        let customerID = '941047192701414';     // Tom Allen (in prod environment)
        // let customerID = 'snikevj';   // Sean (in dev environment -- Tony Tiger)
        // let customerID = '10212381931995957';   // Brandon Wells (in prod environment)
        // customerID = '10213673851776332';   // Kristin Conroy McKay (customer with more than one subscription in prod environment)

        dataModelService.braintreeManagement.getBraintreeData(customerID)
        .then((braintreeData) => {console.log('braintreeData: ', braintreeData)})
        .catch((err) => {console.log(err)});
        */

    /*
        // let customerID = '10154672182542352';  let monthDate = '2018-04';  let dayDate = '2017-09-22';  // Jeff (in prod environment)  result: correct
        let customerID = '10154672182542352';  let monthDate = '2018-04';  let dayDate = '2017-09-25';  // Jeff (in prod environment)  result: correct
        // let customerID = '10212381931995957';  let monthDate = '2018-04';   // user on prod with $48 in one month    result: correct
        // let customerID = '10212381931995957';  let monthDate = '2018-03';   // user on prod with $0 in one month     result: correct
        // let customerID = '10212381931995957';  let monthDate = '2017-10';   // user on prod with $8 in one month     result: correct
        // let customerID = '1021238193199595z';  let monthDate = '2018-04';   // invalid customerID (should return 0)  result: correct
        // let customerID = '146441322626155';    let monthDate = '2017-09';   // Sean on prod with subscription refund (sum should be 0)   result: correct

        dataModelService.braintreeManagement.getUserMonthPayments(customerID,monthDate)
        .then((userMonthPayments) => {console.log('userMonthPayments (' + monthDate + '): ' + userMonthPayments)})
        .catch((err) => {console.log(err)});

        dataModelService.braintreeManagement.getUserDayPayments(customerID,dayDate)
        .then((userDayPayments) => {console.log('userDayPayments (' + dayDate + '): ' + userDayPayments)})
        .catch((err) => {console.log(err)});
        */

    /*
        // dataModelService.braintreeManagement.generateClientToken(customerID)
        dataModelService.braintreeManagement.generateClientToken()
        .then((result) => {console.log('generateClientToken: ', result)})
        .catch((err) => {console.log('generateClientToken: ', err)});
        */

    /*
        dataModelService.braintreeManagement.listCustomerPaymentMethods(customerID)
        .then((result) => {console.log('listCustomerPaymentMethods: ', result)})
        .catch((err) => {console.log('listCustomerPaymentMethods: ', err)});
        */

    /*
        let wantAll = true;
        dataModelService.braintreeManagement.listCustomerSubscriptions(customerID,wantAll)
        .then((result) => {console.log('listCustomerSubscriptions: ', result)})
        .catch((err) => {console.log('listCustomerSubscriptions: ', err)});
        */

    /*
        dataModelService.braintreeManagement.hasActiveSubscription(customerID)
        .then((result) => {console.log('hasActiveSubscription: ', result)})
        .catch((err) => {console.log('hasActiveSubscription: ', err)});
        */

    /*
        // establish Braintree customer with a subscription
        let findCustomer = () =>
        {
            return dataModelService.braintreeManagement.findCustomer(chainParms.customerID);
        }   // findCustomer

        let processFindCustomer = (result) =>
        {
            console.log('findCustomer: ', result);  //%//

            // determine how many payment methods are in place
            let paymentMethodCount = result.paymentMethods.length;

            // determine how many useful subscriptions are in place
            let subscriptionCount = 0;
            for (let i = 0; i < result.paymentMethods.length; i++)
            {
                for (let j = 0; j < result.paymentMethods[i].subscriptions.length; j++)
                {
                    let status = result.paymentMethods[i].subscriptions[j].status;
                    if (status !== 'Canceled' && status !== 'Expired')
                    {
                        subscriptionCount++;
                    }
                }   // for j
            }   // for i

            // set information needed later
            chainParms.needCustomer = false;
            chainParms.needPaymentMethod = (paymentMethodCount === 0);
            chainParms.needSubscription = (subscriptionCount === 0);
            if (paymentMethodCount > 0) chainParms.paymentMethodToken = result.paymentMethods[0].token;

            return Promise.resolve();
        }   // processFindCustomer

        let processFindCustomerError = (err) =>
        {
            console.log('findCustomer: ', err);  //%//

            if (err !== 'notFoundError')
            {
                return Promise.reject(err);
            }
            else
            {
                chainParms.needCustomer = true;
                chainParms.needPaymentMethod = true;
                chainParms.needSubscription = true;
                return Promise.resolve();
            }
        }   // processFindCustomerError

        let createCustomer = () =>
        {
            if (chainParms.needCustomer)
            {
                return dataModelService.braintreeManagement.createCustomer(chainParms.customerParms);
            }
            else
            {
                return Promise.resolve();
            }
        }   // createCustomer

        let processCreateCustomer = (result) =>
        {
            if (result !== null) console.log('createCustomer: ', result);  //%//
            return Promise.resolve();
        }   // processCreateCustomer

        let createPaymentMethod = () =>
        {
            if (chainParms.needPaymentMethod)
            {
                return dataModelService.braintreeManagement.createPaymentMethod(chainParms.paymentMethodParms);
            }
            else
            {
                chainParms.subscriptionParms =
                {
                    planId: 'WizeFiPlan',
                    paymentMethodToken: chainParms.paymentMethodToken
                }
                return Promise.resolve();
            }
        }   // createPaymentMethod

        let processCreatePaymentMethod = (result) =>
        {
            if (result !== null)
            {
                console.log('createPaymentMethod: ', result);  //%//
                chainParms.subscriptionParms =
                {
                    planId: 'WizeFiPlan',
                    paymentMethodToken: result.paymentMethod.token
                }
            }
            return Promise.resolve();
        }   // processCreatePaymentMethod

        let createSubscription = () =>
        {
            if (chainParms.needSubscription)
            {
                return dataModelService.braintreeManagement.createSubscription(chainParms.subscriptionParms);
            }
            else
            {
                return Promise.resolve();
            }
        }   // createSubscription

        let processCreateSubscription = (result) =>
        {
            if (result !== null) console.log('createSubscription: ', result);  //%//
            return Promise.resolve();
        }   // processCreateSubscription

        // initialize
        let customerID = '98765';                     // this.dataModelService.dataModel.global.wizeFiID
        let paymentMethodNonce = 'fake-valid-nonce';  // from subscription screen
        let chainParms:IchainParms =  // chainParms contains values needed in the promise chain processing
        {
            customerID: customerID,
            paymentMethodNonce: paymentMethodNonce,
            paymentMethodToken: 'unknown',
            needCustomer:  false,
            needPaymentMethod:  false,
            needSubscription:  false,
            customerParms:
            {
                id: customerID,       // wizeFiID
                firstName: 'Joe',     // this.dataModelService.dataModel.persistent.profile.nameFirst
                lastName: 'Testing',  // this.dataModelService.dataModel.persistent.profile.nameLast
                email: 'joe@abc.com'  // thithis.dataModelService.dataModel.global.braintreeData.email
            },
            paymentMethodParms:
            {
                customerId: customerID,  // wizeFiID
                paymentMethodNonce: paymentMethodNonce
            },
            subscriptionParms:
            {
                planId: 'WizeFiPlan',
                paymentMethodToken: 'unknown'    // to be determined later
            }
        }

        // find customer, create customer (if necessary), create payment method for that customer (if necessary), create subscription for that user (if necessary)
        findCustomer()
        .then(processFindCustomer)
        .catch(processFindCustomerError)
        .then(createCustomer)
        .then(processCreateCustomer)
        .then(createPaymentMethod)
        .then(processCreatePaymentMethod)
        .then(createSubscription)
        .then(processCreateSubscription)
        .then(() => {console.log('Now have a customer with a subscription')})
        .catch((err) => {console.log(err)});
        */

    /*
        // create customer, create payment method for that customer, create subscription for that user
        let createCustomer = (customerParms) =>
        {
            return dataModelService.braintreeManagement.createCustomer(customerParms);
        }   // createCustomer

        let processCreateCustomer = (result) =>
        {
            console.log('createCustomer: ', result);
            let paymentMethodParms =
            {
                customerId: result.customer.id,          // wizeFiID
                paymentMethodNonce: paymentMethodNonce
            }
            return Promise.resolve(paymentMethodParms);
        }   // processCreateCustomer

        let createPaymentMethod = (paymentMethodParms) =>
        {
            return dataModelService.braintreeManagement.createPaymentMethod(paymentMethodParms);
        }   // createPaymentMethod

        let processCreatePaymentMethod = (result) =>
        {
            console.log('createPaymentMethod: ', result);
            let subscriptionParms =
            {
                planId: 'WizeFiPlan',
                paymentMethodToken: result.paymentMethod.token
            }
            return Promise.resolve(subscriptionParms);
        }   // processCreatePaymentMethod

        let createSubscription = (subscriptionParms) =>
        {
            return dataModelService.braintreeManagement.createSubscription(subscriptionParms);
        }   // createSubscription

        // input from WizeFi app subscription screen
        let customerID = '98765';
        let paymentMethodNonce = 'fake-valid-nonce';

        // create customer, create payment method for that customer, create subscription for that user
        let customerParms =
        {
            id: customerID,
            firstName: 'Joe',
            lastName: 'Testing',
            email: 'joe@abc.com'
        };
        createCustomer(customerParms)
        .then(processCreateCustomer)
        .then(createPaymentMethod)
        .then(processCreatePaymentMethod)
        .then(createSubscription)
        .then((result) => {console.log('createSubscription: ', result)})
        .catch((err) => {console.log(err)});
        */

    // INDIVIDUAL

    /*
        // create a customer
        customerID = '98765';
        parms =
        {
            id: customerID,
            firstName: 'Joe',
            lastName: 'Testing',
            email: 'joe@abc.com'
        }
        dataModelService.braintreeManagement.createCustomer(parms)
        .then((result) => {console.log('createCustomer: ', result)})
        .catch((err) => {console.log(err)});
        */

    /*
        // create a payment method
        paymentMethodNonce = 'fake-valid-nonce';
        parms =
        {
            customerId: customerID,          // wizeFiID
            paymentMethodNonce: paymentMethodNonce
        }
        dataModelService.braintreeManagement.createPaymentMethod(parms)
        .then((result) => {console.log('createPaymentMethod: ', result)})
        .catch((err) => {console.log(err)});
        */

    /*
        // create a subscription
        paymentMethodToken = 'jyv5ny';
        parms =
        {
            planId: 'WizeFiPlan',
            paymentMethodToken: paymentMethodToken
        }
        dataModelService.braintreeManagement.createSubscription(parms)
        .then((result) => {console.log('createSubscription: ', result)})
        .catch((err) => {console.log(err)});
        */

    /*
        dataModelService.braintreeManagement.deleteCustomer(customerID)
        .then((result) => {console.log('deleteCustomer: ', result)})
        .catch((err) => {console.log(err)});
        */

    /*
        // find customer information
        customerID = '98765';
        dataModelService.braintreeManagement.findCustomer(customerID)
        .then((result) => {console.log('findCustomer: ', result)})
        .catch((err) => {console.log(err)});
        */

    /*
        // create a subscription
        paymentMethodToken = '2ymcjj';
        paymentMethodNonce = 'fake-valid-nonce';
        parms =
        {
            planId: 'WizeFiPlan',
            paymentMethodToken: paymentMethodToken
            // paymentMethodNonce: paymentMethodNonce
            // note: provide only one or the other of paymentMethodToken or paymentMethodNonce
        }
        dataModelService.braintreeManagement.createSubscription(parms)
        .then((result) => {console.log('createSubscription: ', result)})
        .catch((err) => {console.log(err)});
        */

    /*
        // find a subscription
        // let subscriptionID = 'fwvm8b';
        let subscriptionID = dataModelService.dataModel.global.braintreeData.subscriptionInfo.subscriptionID;
        dataModelService.braintreeManagement.findSubscription(subscriptionID)
        .then((subscription) => {console.log('findSubscription: ', subscription)})
        .catch((err) => {console.log(err)});
        */

    /*
        // cancel a subscription
        subscriptionID = '6dwxsr';
        dataModelService.braintreeManagement.cancelSubscription(subscriptionID)
        .then((result) => {console.log('cancelSubscription: ', result)})
        .catch((err) => {console.log(err)});
        */

    /*
        // find a transaction
        let transactionID = '3nxbmh0r';
        dataModelService.braintreeManagement.findTransaction(transactionID)
        .then((transaction) => {console.log('findTransaction: ', transaction)})
        .catch((err) => {console.log(err)});
        */

    /*
        // find a list of transactions
        let parms =
        {
            startDate: '2017-09-26T00:00:00.000Z',
            endDate:   '2017-09-26T23:59:59.999Z',
            status: 'settled',
            type: 'credit'
        }
        dataModelService.braintreeManagement.searchTransactions(parms)
        .then((transactionList) => {console.log('searchTransactions: ', transactionList)})
        .catch((err) => {console.log(err)});
        */

    /*
        // find a list of disputes
        let parms =
        {
            monthDate: '2018-05',        // YYYY-MM
            // startDate: '2018-05-15',  // YYYY-MM-DD
            // endDate:   '2018-05-15',  // YYYY-MM-DD
            kind: ['chargeback'],
            status: ['accepted','lost']
        }
        dataModelService.braintreeManagement.searchDisputes(parms)
        .then((disputeList) => {console.log('searchDisputes: ', disputeList)})
        .catch((err) => {console.log(err)});
        */

    //
    const customerID = '10212381931995957';
    const monthDate = '2018-05';
    const dayDate = '2018-05-15';

    dataModelService.braintreeManagement
      .getUserMonthDisputeRefunds(customerID, monthDate)
      .then(userMonthDisputeRefunds => {
        console.log('getUserMonthDisputeRefunds: ', userMonthDisputeRefunds);
      })
      .catch(err => {
        console.log(err);
      });

    dataModelService.braintreeManagement
      .getUserDayDisputeRefunds(customerID, dayDate)
      .then(userDayDisputeRefunds => {
        console.log('getUserDayDisputeRefunds: ', userDayDisputeRefunds);
      })
      .catch(err => {
        console.log(err);
      });
    //

    /*
        // get list of subscription refunds
        let startDate: '2017-09-26T00:00:00.000Z';
        // let endDate: '2017-09-26T23:59:59.999Z';
        let endDate: '2018-05-30T23:59:59.999Z';
        dataModelService.braintreeManagement.getSubscriptionRefundList(startDate,endDate)
        .then((subscriptionRefundList) => {console.log('subscriptionRefundList: ', subscriptionRefundList)})
        .catch((err) => {console.log(err)});
        */

    /*
        // get user email
        let wizeFiID = '10100507229934732';  // Greg
        dataModelService.braintreeManagement.getUserEmail(wizeFiID)
        .then((email) => {console.log('getUserEmail: ', email)})
        .catch((err) => {console.log(err)});
        */

    /*
        // set user email
        let wizeFiID = '10100507229934732';  // Greg
        // let email = 'greg@wizefi.com';
        let email = 'gregaeland@gmail.com';
        dataModelService.braintreeManagement.setUserEmail(wizeFiID,email)
        .then(() => {console.log('email has been set to ' + email)})
        .catch((err) => {console.log(err)});
        */
  } // runBraintreeManagementTests

  public runDataManagementTests(dataModelService: any) {
    // save data in plans
    const savePlans = JSON.parse(JSON.stringify(dataModelService.dataModel.persistent.plans));

    async function runPlansTest() {
      const getWizeFiData = funcWizeFiID =>
        new Promise((resolve, reject) => {
          // define params to guide get operation
          const params = {
            TableName: 'WizeFiDataB',
            Key: { wizeFiID: funcWizeFiID }
          };

          // get info from DynamoDB table
          dataModelService.dataModel.global.docClient.get(params, (err, data) => {
            if (err) {
              reject(err);
            } else {
              // return results
              if (!data.hasOwnProperty('Item')) {
                reject('item not found in WizeFiData');
              } else {
                const item = data.Item;
                item.persistent = JSON.parse(item.persistent);
                resolve(item);
              }
            }
          });
        }); // return Promise // getWizeFiData
      // initialize
      let memoryPlanList;

      const wizeFiID = 'ylrjxfe'; // user exists in prod
      // let wizeFiID = 'rpakayj';  // user exists in demo
      // let wizeFiID = 'unknown';  // user does not exist
      // let newCurplan = 'p201902';     let msg = 'plan that does not exist';
      // let newCurplan = 'p201805';  let msg = 'existing plan in WizeFiDataPlans';
      const newCurplan = 'p201811';
      const msg = 'existing plan in memory';

      // obtain sample wizeFiData
      dataModelService.dataModel = await getWizeFiData(wizeFiID);

      // fill in necessary items in global object
      dataModelService.dataModel.global = {};
      dataModelService.dataModel.global.wizeFiID = wizeFiID;
      dataModelService.dataModel.global.docClient = new AWS.DynamoDB.DocumentClient();
      dataModelService.dataModel.global.planList = await dataModelService.dataManagement
        .getPlanList(dataModelService.dataModel.global.wizeFiID)
        .catch(err => {
          throw err;
        });

      // set up data planList
      dataModelService.dataModel.planList = await dataModelService.dataManagement.getPlanList(wizeFiID);
      dataModelService.dataModel.planList.unshift('original'); // add 'original' to beginning of list
      const lastPlan = dataModelService.dataModel.planList[dataModelService.dataModel.planList.length - 1];

      // obtain latest plan
      const planData = await dataModelService.dataManagement.getMonthPlan(wizeFiID, lastPlan).catch(err => {
        throw err;
      });
      dataModelService.dataModel.persistent.plans[lastPlan] = JSON.parse(planData);
      this.dataModelService.setCurPlan(lastPlan);

      // set up plansOldData
      dataModelService.dataModel.global.plansOldData = {};
      for (const plan of dataModelService.dataModel.planList) {
        if (plan !== 'original') {
          dataModelService.dataModel.global.plansOldData[plan] = '';
        }
      }
      dataModelService.dataModel.global.plansOldData[lastPlan] = JSON.stringify(dataModelService.dataModel.persistent.plans[lastPlan]);

      // show data configuration before changeCurrentPlan
      console.log(' ');
      console.log('runPlansTest -- data before changeCurrentPlan');
      console.log('newCurplan: ' + newCurplan + '   (' + msg + ')   <===============');
      console.log('curplan: ' + dataModelService.dataModel.persistent.header.curplan);
      console.log('runPlansTest -- planList (original + data from WizeFiDataPlans):');
      console.log(dataModelService.dataModel.planList);
      console.log('runPlansTest -- plansOldData:');
      for (const plan of Object.keys(dataModelService.dataModel.global.plansOldData).sort()) {
        console.log(plan + ': ' + dataModelService.dataModel.global.plansOldData[plan]);
      }
      console.log('runPlansTest -- plans in memory:');
      memoryPlanList = Object.keys(dataModelService.dataModel.persistent.plans).sort();
      for (const plan of memoryPlanList) {
        console.log(plan);
      }

      // make change to curplan
      await dataModelService.dataManagement.changeCurrentPlan(newCurplan);

      // show data configuration after changeCurrentPlan
      console.log(' ');
      console.log('runPlansTest -- data after changeCurrentPlan');
      console.log('curplan: ' + dataModelService.dataModel.persistent.header.curplan);
      console.log('runPlansTest -- plansOldData:');
      for (const plan of Object.keys(dataModelService.dataModel.global.plansOldData).sort()) {
        console.log(plan + ': ' + dataModelService.dataModel.global.plansOldData[plan]);
      }
      console.log('runPlansTest -- plans in memory:');
      memoryPlanList = Object.keys(dataModelService.dataModel.persistent.plans).sort();
      for (const plan of memoryPlanList) {
        console.log(plan);
      }
    } // runPlansTest

    async function runDataTest() {
      /*
            // initialize empty dataModel (simulate WizeFi app dataModel initialization via data-model_0001.data.ts)
            dataModel =
            {
                global: {},
                affiliateID: 'affilid',
                affiliateAlias: 'affilAlias',
                persistent:
                {
                    header: {},
                    profile: {nameLast: 'unknown'},
                    plans: {'original': {'info': 'here is the original plan'}}
                },
                planList: [],
                plansOldData: {}
            };
            */

      // test fetchdata
      console.log(' ');
      console.log('runDataTest -- test fetchdata');
      // dataModelService.dataModel.global.wizeFiID = 'ylrjxfe';  // user exists (prod)
      dataModelService.dataModel.global.wizeFiID = 'rpakayj'; // user exists (demo)
      // dataModel.global.wizeFiID = 'testzzz';  // user does not exist
      await dataModelService.dataManagement.fetchdata();
      console.log('isNewUser: ' + dataModelService.dataModel.global.isNewUser);
      console.log('user name: ' + dataModelService.dataModel.persistent.profile.nameLast);

      //
      // consider whether to comment this out when testing storedata
      // test makeNewPlan
      console.log(' ');
      console.log('runDataTest -- test makeNewPlan');
      await dataModelService.dataManagement.makeNewPlan(2018, 9);
      //

      //
      // test storedata
      console.log(' ');
      console.log('runDataTest -- test storedata');
      await dataModelService.dataManagement.storeinfo();
      console.log('use DynamoDB console to see if item has been written');
      //
    } // runDataTest

    ///////////////////////////
    // perform tests
    ///////////////////////////

    // run one or the other of the following

    runPlansTest().catch(err => {
      console.log(err);
    });

    // runDataTest().catch((err) => {console.log(err)});

    // restore data to plans
    dataModelService.dataModel.persistent.plans = savePlans;
  } // runDataManagementTests

  public runLoginManagementTests(dataModelService: any) {
    let email;

    /*
        // get token info
        let tokenInfo;

        tokenInfo = dataModelService.loginManagement.getTokenInfo(dataModelService.loginManagement.access_token);
        console.log('access_token -- tokenInfo: ', tokenInfo);
        tokenInfo = dataModelService.loginManagement.getTokenInfo(dataModelService.loginManagement.access_token2);
        console.log('access_token2 -- tokenInfo: ', tokenInfo);
        // test attempt to process token that is not in JWT format
        // tokenInfo = dataModelService.loginManagement.getTokenInfo(dataModelService.loginManagement.refreshToken);
        // console.log('refreshToken -- tokenInfo: ', tokenInfo);
        */

    /*
        // refresh access token
        dataModelService.loginManagement.refreshSession()
        .catch((err) => {console.log(err)});
        */

    /*
        // register user
        email = 'daveland@oru.edu';
        password = 'Alphabet456';
        affiliateID = 'abcdefg';
        username = affiliateID;
        dataModelService.loginManagement.registerUser(username,email,password,affiliateID)
        .then((result) => {console.log('registerUser: ', result)})
        .catch((err) => {console.log(err)});
        */

    /*
        // confirm user
        username = 'abcdefg';
        confirmationVerificationCode = '135063';
        dataModelService.loginManagement.confirmUser(username, confirmationVerificationCode)
        .then((result) => {console.log('confirmUser: ', result)})
        .catch((err) => {console.log(err)});
        */

    /*
        // resend confirmation code
        username = 'abcdefg';
        dataModelService.loginManagement.resendConfirmationCode(username)
        .then((data) => {console.log('resendConfirmationCode: ', data)})
        .catch((err) => {console.log(err)});
        */

    /*
        // login user
        let testLambdaInvocation = () =>
        {
            return new Promise((resolve,reject) =>
            {
                // set params to guide function invocation
                let payload = {};
                let params =
                {
                    FunctionName: 'generateClientToken',  // this function is replaced by dataModelService.braintreeManagement.generateClientToken
                    Payload: JSON.stringify(payload)
                };

                // invoke Lambda function to process data
                this.dataModelService.dataModel.global.lambda.invoke(params, (err,data) =>
                {
                    if (err)
                    {
                        reject(err);
                    }
                    else
                    {
                        let clientToken = JSON.parse(data.Payload);
                        resolve(clientToken);
                    }
                }); // lambda invoke
            }); // return Promise
        }   // testLambdaInvocation

        username = 'daveland@oru.edu';  // use email alias for user name
        password = 'Alphabet456';
        dataModelService.loginManagement.doCognitoLogin(username,password)
        .then((logins) => {return this.dataModelService.loginManagement.establishAWSobjects(logins)})
        .then(testLambdaInvocation)
        .then((clientToken) => {console.log('clientToken: ', clientToken)})
        .catch((err) => {console.log(err)});
        */

    /*
        // delete user
        // note: must execute doCognitoLogin function to set cognitoUser before invoking the deleteUser function
        email = 'daveland@oru.edu';
        password = 'Alphabet456';

        dataModelService.loginManagement.doCognitoLogin(email,password)
        .then(() => {return dataModelService.loginManagement.deleteUser()})
        .then((data) => {console.log('deleteUser: ', data)})
        .catch((err) => {console.log(err)});
        */

    /*
        // change password
        // note: must execute doCognitoLogin function to set cognitoUser before invoking the deleteUser function
        email = 'daveland@oru.edu';
        password = 'Alphabet123';
        newPassword = 'Alphabet456'

        dataModelService.loginManagement.doCognitoLogin(email,password)
        .then(() => {return dataModelService.loginManagement.changePassword(password,newPassword)})
        .then((result) => {console.log('changePassword: ', result)})
        .catch((err) => {console.log(err)});
        */

    /*
        // reset password (help user has forgotten their password)
        email = 'daveland@oru.edu';
        password = 'Alphabet456';
        dataModelService.loginManagement.resetPassword(email,password)
        .then((result) => {console.log('resetPassword: ', result)})
        .catch((err) => {console.log(err)});
        */

    /*
        // request password change verification code
        email = 'daveland@oru.edu';
        dataModelService.loginManagement.requestPasswordChangeVerificationCode(email)
        .then((result) => {console.log('requestPasswordChangeVerificationCode: ', result)})
        .catch((err) => {console.log(err)});
        */

    /*
        // apply password change verification code
        email = 'daveland@oru.edu';
        confirmationVerificationCode = '492181';
        newPassword = 'Alplhabet345';
        dataModelService.loginManagement.applyPasswordChangeVerificationCode(email,confirmationVerificationCode,newPassword)
        .then((result) => {console.log('applyPasswordChangeVerificationCode: ', result)})
        .catch((err) => {console.log(err)});
        */

    /*
        // set user attributes
        // note: must execute doCognitoLogin function to set cognitoUser before invoking the setUserAttributes function
        email = 'daveland@oru.edu';
        password = 'Alphabet456';
        attributeList = [{Name:'custom:affiliateID', Value:'jcmdkbd'}];

        dataModelService.loginManagement.doCognitoLogin(email,password)
        .then(() => {return dataModelService.loginManagement.setUserAttributes(attributeList)})
        .then((result) => {console.log('setUserAttributes: ', result)})
        .catch((err) => {console.log(err)});
        */

    /*
        // get user attributes
        // note: must execute doCognitoLogin function to set cognitoUser before invoking the getUserAttributes function
        email = 'daveland@oru.edu';
        password = 'Alphabet77';

        dataModelService.loginManagement.doCognitoLogin(email,password)
        .then(() => {return dataModelService.loginManagement.getUserAttributes()})
        .then((userAttributes) => {console.log('getUserAttributes: ', userAttributes)})
        .catch((err) => {console.log(err)});
        */

    /*
        // get user email  (this only works for a user with a Cognito login)
        dataModelService.loginManagement.getUserEmail()
        .then((email) => {console.log('user Cognito email: ', email)})
        .catch((err) => {console.log(err)});
        */

    /*
        // get some user attribute values
        // note: must execute doCognitoLogin function to set cognitoUser before invoking the getUserAttributes function
        email = 'daveland@oru.edu';
        password = 'Alphabet456';

        dataModelService.loginManagement.doCognitoLogin(email,password)
        .then(() => {return dataModelService.loginManagement.getUserEmail()})
        .then((result) => {console.log('email: ' + result)})
        .then(() => {return dataModelService.loginManagement.getUserPreferredUsername()})
        .then((result) => {console.log('preferred_username: ' + result)})
        .then(() => {return dataModelService.loginManagement.getUserAffiliateID()})
        .then((result) => {console.log('affiliateID: ' + result)})
        .catch((err) => {console.log(err)});
        */

    /*
        // get email count for Cognito users
        email = 'dave@wizefi.com';

        console.log('Note -- there can be a bit of a delay in the response if the number of users is large.')
        dataModelService.loginManagement.getEmailCount(email)
        .then((emailCount) => {console.log('emailCount: ', emailCount)})
        .catch((err) => {console.log(err)});
        */

    /*
        email = 'dave@wizefi.com';

        console.log('Note -- there can be a bit of a delay in the response if the number of users is large.')
        dataModelService.loginManagement.getCognitoUsersInfo(email)
        .then((result) => {console.log('getCognitoUsersInfo: ', result)})
        .catch((err) => {console.log(err)});
        */

    //
    email = 'dave@wizefi.com';
    dataModelService.loginManagement
      .checkEmailVerification(email)
      .then(needEmailVerification => {
        console.log('email for ' + email + ' needs to be verified: ' + needEmailVerification);
      })
      .catch(err => {
        console.log(err);
      });

    email = 'abcd@xyzq.com';
    dataModelService.loginManagement
      .checkEmailVerification(email)
      .then(needEmailVerification => {
        console.log('email for ' + email + ' needs to be verified: ' + needEmailVerification);
      })
      .catch(err => {
        console.log(err);
      });

    email = 'dave.r.eland@gmail.com';
    dataModelService.loginManagement
      .checkEmailVerification(email)
      .then(needEmailVerification => {
        console.log('email for ' + email + ' needs to be verified: ' + needEmailVerification);
      })
      .catch(err => {
        console.log(err);
      });
    //
  } // runLoginManagementTests

  public runYodleeManagementTests(dataModelService: any) {
    let yodleeUserName = 'sbMemaMOAscXyJqAAu1'; // first sandbox test user in list
    let email;

    /*
        dataModelService.yodleeManagement.createCobrandToken()
        .then((cobrandToken) => {console.log('cobrandToken: ' + cobrandToken)})
        .catch((err) => {console.log('error in createCobrandToken: ', err)});

        dataModelService.yodleeManagement.createUserToken(yodleeUserName)
        .then((userToken) => {console.log('userToken: ' + userToken)})
        .catch((err) => {console.log('error in createUserTokenn: ', err)});
        */

    /*
        yodleeUserName = 'testuser1';
        email = 'testuser1@abczyzscfe.com';

        dataModelService.yodleeManagement.registerUser(yodleeUserName, email)
        .then((result) => {console.log('registerUser: ', result)})
        .catch((err) => {console.log('error in registerUser: ', err)});
        */

    /*
        yodleeUserName = 'testuser1';
        dataModelService.yodleeManagement.getUserInfo(yodleeUserName)
        .then((userInfo) => {console.log('userInfo: ', userInfo)})
        .catch((err) => {console.log('error in getUserInfo: ', err)});
        */

    /*
        yodleeUserName = 'testuser1';
        dataModelService.yodleeManagement.deleteUser(yodleeUserName)
        .then((result) => {console.log('deleteUser: ', result)})
        .catch((err) => {console.log('error in deleteUser: ', err)});
        */

    //
    // yodleeUserName = '3298365740';
    yodleeUserName = 'testuser3';
    email = 'testuser1@abczyzscfe.com';

    dataModelService.yodleeManagement
      .registerUser(yodleeUserName, email)
      .then(result => {
        console.log('registerUser: ', result);
      })
      .then(() => dataModelService.yodleeManagement.getUserInfo(yodleeUserName))
      .then(userInfo => {
        console.log('userInfo: ', userInfo);
      })
      .then(() => dataModelService.yodleeManagement.deleteUser(yodleeUserName))
      .then(result => {
        console.log('deleteUser: ', result);
      })
      .then(() => dataModelService.yodleeManagement.getUserInfo(yodleeUserName))
      .then(userInfo => {
        console.log('userInfo: ', userInfo);
      })
      .catch(err => {
        console.log('error in getUserInfo: ', err);
      });
    //

    /*
        dataModelService.yodleeManagement.getTransactionCategories()
        .then((transactionCategories) => {console.log('transactionCategories: ', transactionCategories)})
        .catch((err) => {console.log('error in getTransactionCategories: ', err)});
        */

    /*
        dataModelService.yodleeManagement.getUserAccounts(yodleeUserName)
        .then((userAccounts) => {console.log('userAccounts: ', userAccounts)})
        .catch((err) => {console.log('error in getUserAccounts: ', err)});
        */

    /*
        let yearMonth = '2019-05';
        dataModelService.yodleeManagement.getUserTransactions(yodleeUserName,yearMonth)
        .then((userTransactions) => {console.log('userTransactions: ', userTransactions)})
        .catch((err) => {console.log('error in getUserTransactions: ', err)});
        */
  } // runYodleeManagementTests

  public runPlaidManagementTests(dataModelService: any) {
    const publicToken = 'public-sandbox-3c8e70ff-ad2c-4caa-8019-cf66d4a2f505';

    const wizeFiID = '10213363587114053'; // Dave demo
    // let wizeFiID = '10215006983557937';  // Dave prod

    /*
        dataModelService.plaidManagement.getPublicKey()
        .then((response) => {console.log('getPublicKey: ', response)})
        .catch((err) => {console.log('getPublicKey error: ', err)});

        dataModelService.plaidManagement.getPlaidEnv()
        .then((response) => {console.log('getPlaidEnv: ', response)})
        .catch((err) => {console.log('getPlaidEnv error: ', err)});
        */

    /*
        dataModelService.plaidManagement.removeItem(accessTokenList[0])
        .then((response) => {console.log('removeItem: ', response)})
        .catch((err) => {console.log('removeItem error: ', err)});
        */

    /*
        dataModelService.plaidManagement.getWizeFiPlaidInstitutions(wizeFiID)
        .then((wizeFiPlaidInstitutions) => {console.log('wizeFiPlaidInstitutions: ', wizeFiPlaidInstitutions)})
        .catch((err) => {console.log('error in getCategories: ', err)});
        */

    /*
        dataModelService.plaidManagement.getCategories()
        .then((categories) => {console.log('categories: ', categories)})
        .catch((err) => {console.log('error in getCategories: ', err)});
        */

    /*
        dataModelService.plaidManagement.getAccounts(accessTokenList)
        .then((response) => {console.log('getAccounts: ', response)})
        .catch((err) => {console.log('getAccounts error: ', err)});
        */

    /*

        const dateRange = {wantMonthRange:true, yearMonth:'2019:06'};

        the following is outdated (accessTokenList is not a valid parameter)
        dataModelService.plaidManagement.getTransactions(accessTokenList,title,dateRange,activeWizeFiPlaidAccounts)
        .then((transactionInfo) => {console.log('transactionInfo: ', transactionInfo)})
        .catch((err) => {console.log('error in getCategories: ', err)});
        */

    /*
        dataModelService.plaidManagement.getInstitutionsErrorStatus(wizeFiID)
        .then((errorStatusList) => {console.log('errorStatusList: ', errorStatusList)})
        .catch((err) => {console.log('error in getInstitutionsErrorStatus: ', err)});
        */

    //
    dataModelService.plaidManagement
      .getPlaidData()
      .then(() => {
        console.log('global.plaidData:', dataModelService.dataModel.global.plaidData);
      })
      .catch(err => {
        console.log('error in getPlaidData: ', err);
      });
    //
  } // runPlaidManagementTests

  public runScreenDataManagementTests(dataModelService: any) {
    const wizeFiID = 'abcdefg';
    const info = { identity: 'John Doe jdoe@gmail.com', authorizedScreens: ['admin-screen-data'] };
    const secureScreen = 'admin-test-plaid';

    /*  works
        dataModelService.screenDataManagement.storeScreenData(wizeFiID,info)
        .then(() => {console.log('storeScreenData completed')})
        .catch((err) => {console.log(err)});
        */

    /*  works
        dataModelService.screenDataManagement.fetchScreenData(wizeFiID)
        .then((item) => {console.log('fetchScreenData: ', item)})
        .catch((err) => {console.log(err)});
        */

    /*  works
        dataModelService.screenDataManagement.fetchAllScreenData()
        .then((items) => {console.log('fetchAllScreenData: ', items)})
        .catch((err) => {console.log(err)});
        */

    /*  works
        dataModelService.screenDataManagement.hasScreenAccess(wizeFiID,secureScreen)
        .then((result) => {console.log('hasScreenAccess to ' + secureScreen + ': ' + result)})
        .catch((err) => {console.log(err)});
        */

    /*  works
        dataModelService.screenDataManagement.deleteScreenData(wizeFiID)
        .then(() => {console.log('deleteScreenData completed')})
        .catch((err) => {console.log(err)});
        */

    /*  works
        dataModelService.screenDataManagement.fetchScreenInterfaceData()
        .then((data) => {console.log('fetchScreenInterfaceData: ', data)})
        .catch((err) => {console.log(err)});
        */

    /*  works
        dataModelService.screenDataManagement.storeScreenData(wizeFiID,info)
        .then(() => {console.log('storeScreenData -- item created for ' + wizeFiID)})
        .then(() => {return dataModelService.screenDataManagement.fetchScreenData(wizeFiID)})
        .then((item) => {console.log('fetchScreenData: ', item)})
        .then(() => {return dataModelService.screenDataManagement.hasScreenAccess(wizeFiID,secureScreen)})
        .then((result) => {console.log('hasScreenAccess: ', result)})
        .then(() => {return dataModelService.screenDataManagement.deleteScreenData(wizeFiID)})
        .then(() => {console.log('deleteScreenData -- item deleted for ' + wizeFiID)})
        .then(() => {return dataModelService.screenDataManagement.fetchAllScreenData()})
        .then((items) => {console.log('fetchAllScreenData: ', items)})
        .catch((err) => {console.log(err)});
        */
  } // runScreenDataManagementTests

  public runCategoryManagementTests(dataModelService: any) {
    const company = 'WizeFi';

    /*
        let wizeFiGenericCategories = ['none', 'cat 1', 'category 2'];

        dataModelService.categoryManagement.putWizeFiGenericCategories(company, wizeFiGenericCategories)
        .then((result) => {console.log(result)})
        .catch((err) => {console.log(err)});

        dataModelService.categoryManagement.getWizeFiGenericCategories(company)
        .then((wizeFiGenericCategories) => {console.log('wizeFiGenericCategories: ', wizeFiGenericCategories)})
        .catch((err) => {console.log(err)});
        */

    /*
        let wizeFiPlaidCategoryMap = {'PlaidCat_one': 'gencat1', 'PlaidCat_two': 'gencat2'};

        dataModelService.categoryManagement.putWizeFiPlaidCategoryMap(company, wizeFiPlaidCategoryMap)
        .then((result) => {console.log(result)})
        .catch((err) => {console.log(err)});

        dataModelService.categoryManagement.getWizeFiPlaidCategoryMap(company)
        .then((wizeFiPlaidCategoryMap) => {console.log('wizeFiPlaidCategoryMap: ', wizeFiPlaidCategoryMap)})
        .catch((err) => {console.log(err)});
        */
  } // runCategoryManagementTests

  public runFetchData(dataModelService: any) {
    dataModelService.dataManagement
      .fetchdata()
      .then(() => {
        console.log('data has been fetched');
      })
      .catch(err => {
        console.log(err);
      });
  } // runFetchData

  public runIdleTest(dataModelService: any) {
    let timeoutID;
    const idleTimeUp = 5000; // amount of time after which to signal idle time is up (in milliseconds)

    const setup = () => {
      document.addEventListener('mousemove', resetTimer, false);
      document.addEventListener('mousedown', resetTimer, false);
      document.addEventListener('keypress', resetTimer, false);
      document.addEventListener('DOMMouseScroll', resetTimer, false);
      document.addEventListener('mousewheel', resetTimer, false);
      document.addEventListener('touchmove', resetTimer, false);
      document.addEventListener('MSPointerMove', resetTimer, false);
      startTimer();
    }; // setup

    const startTimer = () => {
      timeoutID = window.setTimeout(signalTimeUp, idleTimeUp);
    }; // startTimer

    const resetTimer = () => {
      window.clearTimeout(timeoutID);
      startTimer();
    }; //

    const signalTimeUp = () => {
      if (confirm('Exit from WizeFi (Cancel) of continue using WizeFi (OK)')) {
        dataModelService.showMessage('info', 'continue', 2000);
        startTimer();
      } else {
        dataModelService.showMessage('info', 'exit', 2000);
        window.clearTimeout(timeoutID);
      }
    }; // signalTimeUp

    setup();
  } // runIdleTest

  public runTimeoutTest(dataModelService: any) {
    function startTimer() {
      const timeUpTime = 4000;
      timeoutID = setTimeout(processTimeUp, timeUpTime);
    } // startTimer

    function stopTimer() {
      clearTimeout(timeoutID);
      console.log('timer stopped');
    } // stopTimer

    function processTimeUp() {
      const doAction = () =>
        new Promise((resolve, reject) => {
          console.log('do action here');
          resolve();
        }); // doAction

      doAction()
        .then(() => {
          startTimer();
        })
        .catch(err => {
          console.log(err);
        });
    } // processTimeUp

    let timeoutID: any;

    startTimer();
  } // runTimeoutTest

  public obtainFacebookLongLifeToken(dataModelService: any) {
    const obtainLongLifeToken = funcShortLifeToken =>
      new Promise((resolve, reject) => {
        // set params to guide function invocation
        const payload = { shortLifeToken: funcShortLifeToken };
        const params = {
          FunctionName: 'obtainFacebookLongLifeToken',
          Payload: JSON.stringify(payload)
        };

        // invoke Lambda function to process data
        dataModelService.dataModel.global.lambda.invoke(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            const funcPayload = JSON.parse(data.Payload);
            if (funcPayload.hasOwnProperty('errorMessage')) {
              reject(funcPayload.errorMessage);
            } else {
              resolve(funcPayload);
            }
          }
        }); // lambda invoke
      }); // return Promise // obtainLongLifeToken
    const shortLifeToken = dataModelService.loginManagement.access_token;

    obtainLongLifeToken(shortLifeToken)
      .then(result => {
        console.log('Facebook Long Life Token: ' + result);
      })
      .catch(err => {
        console.log(err);
      });
  } // obtainFacebookLongLifeToken

  public generateIntercomEvent(dataModelService: any) {
    const metadata = {
      errorMessage: 'here is error message',
      stackTrace: 'stack trace line 1\nstack trace line 2\nstack trace line 3'
    };
    (window as any).Intercom('trackEvent', 'reportError', metadata);
    console.log('event has been generated');
  } // generateIntercomEvent

  public testErrorHandling(dataModelService: any, testnum: number) {
    const doprocess = () => {
      // let a = b;  // b is undefined
      throw new Error('Here is an error message');
    }; // doprocess

    const test1 = () => {
      console.log(' ');
      console.log('test1 -- error is not handled');
      // comment out the following to utilize Angular CustomErrorHandler
      /*
            window.onerror = function (message, file, line, col, error)
            {
                console.log('window.onerror');
                console.log('message: ' + message + '  file: ' + file + '  line: ' + line + '  col: ' + col + '  error: ', error);  //%//
                this.logMessageService.reportError(message,error);
                return true;  // return true to prevent firing of the default event handler
            };
            */
      doprocess();
    }; // test1

    const test2 = () => {
      console.log(' ');
      console.log('test2 -- promise error is not handled');

      // comment out the following to utilize Angular CustomErrorHandler
      /*
            // this gets the error: Property 'onunhandledrejection' does not exist on type 'Window' -- fixed by notation (<any>window)
            // window.onunhandledrejection = function(e)
            (<any>window).onunhandledrejection = function(e)
            {
                console.log('window.onunhandledrejection');
                // console.log('e.reason: ',e.reason);  //%//
                // console.log('e: ',e);                //%//
                this.logMessageService.reportError(e.reason.message, e.reason.stack);
                e.returnValue = false;  // return false to prevent firing of the default event handler
            }
            */

      /* this gets the following error:
            Argument of type '(err: any, promise: any) => void' is not assignable to parameter of type 'EventListenerOrEventListenerObject'.
            Type '(err: any, promise: any) => void' is not assignable to type 'EventListenerObject'.
            Property 'handleEvent' is missing in type '(err: any, promise: any) => void'.)
            */
      /*
            window.addEventListener("unhandledrejection", function(err,promise)
            {
                console.log('unhandledrejection');
                console.log('err: ', err);          //%//
                console.log('promise: ', promise);  //%//
            });
            */

      const test2b = () =>
        new Promise((resolve, reject) => {
          doprocess();
          resolve();
        }); // return Promise
      test2b().then(() => {
        console.log('done');
      });
    }; // test2

    const test3 = async () => {
      console.log(' ');
      console.log('test3 -- promise await error is not handled');

      const test3b = () =>
        new Promise((resolve, reject) => {
          doprocess();
          resolve();
        }); // return Promise // test3b
      await test3b();
    }; // test3

    const test4 = () => {
      console.log(' ');
      console.log('test4 -- error is handled via try-catch');
      try {
        doprocess();
      } catch (ex) {
        // console.log('error: ', ex);  //%//
        // this.logMessageService.reportError(ex.message, ex.stack);
        // logMessage.reportError(ex.message, dataModelService.dataModel.global.wizeFiID, ex.stack);
      }
    }; // test4

    const test5 = () => {
      console.log(' ');
      console.log('test5 -- error in promise is handled via .catch');

      const test5b = () =>
        new Promise((resolve, reject) => {
          doprocess();
          resolve();
        }); // return Promise // test5b
      test5b()
        .then(() => {
          console.log('done');
        })
        .catch(err => {
          this.logMessageService.reportError(err.message, err.stack);
        });
    }; // test5

    const test6 = async () => {
      console.log(' ');
      console.log('test6 -- promise in promise await error is handled via catch and throw');

      const test6b = () =>
        new Promise((resolve, reject) => {
          doprocess();
          resolve();
        }); // return Promise // test6b
      await test6b();
    }; // test6

    const test7 = () => {
      console.log(' ');
      console.log('test7 -- error in invoking lambda function');

      const retrievePendingEarnings = affiliateID =>
        new Promise((resolve, reject) => {
          // set params to guide function invocation
          const payload = { affiliateID };
          const params = {
            FunctionName: 'retrievePendingEarningszzz',
            Payload: JSON.stringify(payload)
          };

          // invoke Lambda function to process data
          dataModelService.dataModel.global.lambda.invoke(params, (err, data) => {
            if (err) {
              reject(this.logMessageService.makeErrorObject('ItemManagement.testErrorHandling.test7.lambda.invoke', err)); // custom Error data
              // reject(err);   // original err data
            } else {
              const funcPayload = JSON.parse(data.Payload);
              resolve(funcPayload);
            }
          }); // lambda invoke
        }); // return Promise // retrievePendingEarnings
      retrievePendingEarnings('ylrjxfe') // Jeff
        .then(result => {
          console.log('Pending earnings: ' + result);
        })
        .catch(err => {
          this.logMessageService.reportError(err.message, err.stack);
        });
    }; // test7

    const test8 = () => {
      console.log(' ');
      console.log('test8 -- error in accessing DynamoDB table');

      const getNode = p =>
        new Promise((resolve, reject) => {
          // define params to guide get operation
          const params = {
            TableName: 'WizeFiAffiliateTreezzz',
            Key: { affiliateID: p }
          };

          // get Item from DynamoDB table
          this.docClient.get(params, (err, data) => {
            if (err) {
              reject(this.logMessageService.makeErrorObject('ItemManagement.testErrorHandling.test8.docClient.get', err)); // custom Error data
              // reject(err);   // original err data
            } else {
              // return results
              if (!data.hasOwnProperty('Item')) {
                // TODO determine whether to return null for this situation, and deal with the problem in calling code
                resolve(null);
              } else {
                try {
                  data.Item.node = JSON.parse(data.Item.node);
                  resolve(data.Item.node);
                } catch (ex) {
                  resolve(null);
                }
              }
            }
          });
        }); // return Promise // getNode
      const affiliateID = 'ylrjxfe00';

      getNode(affiliateID)
        .then(node => {
          console.log('node: ', node);
        })
        .catch(err => {
          this.logMessageService.reportError(err.message, err.stack);
        });
    }; // test8

    switch (testnum) {
      case 1:
        test1();
        break;
      case 2:
        test2();
        break;
      case 3:
        test3().catch(err => {
          console.log('error in test3: ', err);
        });
        break;
      case 4:
        test4();
        break;
      case 5:
        test5();
        break;
      case 6:
        test6().catch(err => {
          console.log('error in test6: ', err);
        });
        break;
      case 7:
        test7();
        break;
      case 8:
        test8();
        break;
    }
  } // testErrorHandling

  public runWizeFiDataManagementTests(dataModelService: any) {
    /*
        // change wizeFiID
        let oldWizeFiID = '10213363587114053';  // Dave in demo  affiliateID zbpizmq
        let newWizeFiID = '10213363587114053z';

        // reset wizeFiID
        // let oldWizeFiID = '10213363587114053z';  // Dave in demo  affiliateID zbpizmq
        // let newWizeFiID = '10213363587114053';

        dataModelService.changeWizeFiID(oldWizeFiID,newWizeFiID)
        .then((result) => {console.log(result)})
        .catch((err) => {console.log(err)});
        */

    const parms = { property: 'nameLast', propertyValue: 'Allen' };
    // let parms = {property:dateCreated, propertyValue:'2017-09-01_2017-09-26'};

    console.log('findWizeFiUser -- ', parms);
    dataModelService
      .findWizeFiUser(parms)
      .then(result => {
        console.log(result);
      })
      .catch(err => {
        console.log(err);
      });
  } // runWizeFiDataManagementTests

  public runTimingTests1(dataModelService: any) {
    // machinery to support timing of processing steps
    let stepName = 'first step';
    const timePoint = [];

    const addTimePoint = () => {
      timePoint.push(new Date().getTime());
    }; // addTimePoint

    const reportStepElapsedTime = () => {
      const elapsedTime = timePoint[timePoint.length - 1] - timePoint[timePoint.length - 2];
      const elapsedSeconds = elapsedTime / 1000;
      const minutes = Math.floor(elapsedSeconds / 60);
      const seconds = elapsedSeconds - minutes * 60;
      let msg = stepName + ':  ';
      if (minutes > 0) {
        msg += minutes + ' minutes and ';
      }
      msg += seconds.toFixed(3) + ' seconds';
      console.log(msg);
    }; // reportStepElapsedTime

    const reportTotalElapsedTime = () => {
      const elapsedTime = timePoint[timePoint.length - 1] - timePoint[0];
      const elapsedSeconds = elapsedTime / 1000;
      const minutes = Math.floor(elapsedSeconds / 60);
      const seconds = elapsedSeconds - minutes * 60;
      let msg = stepName + ':  ';
      if (minutes > 0) {
        msg += minutes + ' minutes and ';
      }
      msg += seconds.toFixed(3) + ' seconds';
      console.log(msg);
    }; // reportTotalElapsedTime

    // machinery to manage affiliate tree
    const buildAffiliateIDList = () =>
      new Promise((resolve, reject) => {
        const getNode = p => {
          if (!parms.tree.hasOwnProperty(p)) {
            return null;
          } else {
            return parms.tree[p].node;
          }
        }; // getNode

        const postTrav = (p, level) => {
          // recursively visit each child of the current node
          const node = getNode(p);
          if (node === null) {
            console.log('node (' + p + ') does not exist in the affiliate tree');
          } else if (level < 6) {
            for (const q of node.child) {
              postTrav(q, level + 1);
            }
          }

          // visit the current node (add item to the list of affiliateID values)
          // NOTE: skip root of subtree, and ignore all levels above 6
          if (level > 0 && level <= 6) {
            parms.affiliateIDList.push(p);
          }
        }; // postTrav

        parms.affiliateIDList = [];
        postTrav(parms.subtreeRoot, 0);
        resolve();
      }); // return Promise // buildAffiliateIDList
    const processAffiliateIDList = () =>
      new Promise((resolveprocessAffiliateIDList, rejectprocessAffiliateIDList) => {
        const obtainWizeFiID = () =>
          new Promise((resolve, reject) => {
            // define params to guide the query operation
            const params = {
              TableName: 'WizeFiData',
              IndexName: 'WizeFiData-AffiliateID',
              KeyConditionExpression: 'affiliateID = :affiliateID',
              ExpressionAttributeValues: { ':affiliateID': parms.affiliateID }
            };

            // get info from DynamoDB WizeFiData-AffiliateID index
            this.docClient.query(params, (err, data) => {
              if (err) {
                reject(err);
              } else {
                const wizeFiID = data.Items.length === 0 ? 'unknown' : data.Items[0].wizeFiID;
                // console.log('affiliateID: ' + parms.affiliateID + '  wizeFiID: ' + wizeFiID);  //%//
                resolve(wizeFiID);
              }
            });
          }); // return Promise // obtainWizeFiID
        const promiseIteration = i => {
          const updateCounts = braintreeData => {
            parms.treeAffiliateCounts.totalAffiliateCount++;
            if (braintreeData.isInTrialPeriod) {
              parms.treeAffiliateCounts.trialAffiliateCount++;
            }
            if (braintreeData.isActive) {
              parms.treeAffiliateCounts.activeAffiliateCount++;
            }
          }; // updateCounts

          const promise = new Promise((resolve, reject) => {
            parms.affiliateID = parms.affiliateIDList[i];

            obtainWizeFiID()
              .then(wizeFiID => dataModelService.braintreeManagement.getBraintreeData(wizeFiID))
              .then(updateCounts)
              .then(() => {
                resolve();
              })
              .catch(err => {
                reject(err);
              });
          })
            .then(() => {
              if (i < parms.affiliateIDList.length - 1) {
                promiseIteration(i + 1);
              } else {
                resolveprocessAffiliateIDList(parms);
              }
            })
            .catch(err => {
              rejectprocessAffiliateIDList(err);
            });
        }; // promiseIteration

        // process each affiliateID in affiliateIDList
        if (parms.affiliateIDList.length === 0) {
          resolveprocessAffiliateIDList();
        } else {
          promiseIteration(0);
        }
      }); // return Promise // processAffiliateIDList
    // initialize information to process
    // let affiliateID = 'ylrjxfe';  // Jeff (in prod environment)
    // let affiliateID = 'fqeyxzc';  // Diana (in prod environment)
    // let affiliateID = 'xhreead';  // random test subject with smaller tree
    const affiliateID = 'pwbqkwx'; // random test subject with yet smaller tree (6 nodes)

    // view affiliate subtree for selected affiliateID
    // dataModelService.affiliateManagement.displayTree(affiliateID);  //%//

    let parms: any; // holds information for various processing steps
    parms = {};
    parms.subtreeRoot = affiliateID; // root of affiliate subtree to process
    parms.tree = dataModelService.affiliateManagement.tree;
    parms.treeAffiliateCounts = {
      totalAffiliateCount: 0,
      trialAffiliateCount: 0,
      activeAffiliateCount: 0
    };
    const stepname = 'unknown';

    // process data
    console.log('Timing test 1 -- memory resident tree -- long time (waits for all processing to complete)');
    addTimePoint(); // capture time prior to first processing step

    dataModelService
      .initializeAffiliateManagement()
      .then(addTimePoint) // capture time at end of initializeAffiliateManagement processing step
      .then(() => {
        stepName = 'load affiliate tree into memory';
      })
      .then(reportStepElapsedTime)

      .then(buildAffiliateIDList)
      .then(addTimePoint) // capture time at end of buildAffiliateIDList processing step
      .then(() => {
        stepName = 'buildAffiliateIDList';
      })
      .then(reportStepElapsedTime)

      .then(processAffiliateIDList)
      .then(addTimePoint) // capture time at end of processAffiliateIDList processing step
      .then(() => {
        stepName = 'processAffiliateIDList';
      })
      .then(reportStepElapsedTime)

      .catch(err => {
        console.log(err);
      })

      .then(() => {
        console.log('treeAffiliateCounts:', parms.treeAffiliateCounts);
      })
      .then(addTimePoint) // capture time at end of all processing steps
      .then(() => {
        stepName = 'total elapsed time';
      })
      .then(reportTotalElapsedTime);
  } // runTimingTests1

  public runTimingTests1a(dataModelService: any) {
    // machinery to support timing of processing steps
    let stepName = 'first step';
    const timePoint = [];

    const addTimePoint = () => {
      timePoint.push(new Date().getTime());
    }; // addTimePoint

    const reportStepElapsedTime = () => {
      const elapsedTime = timePoint[timePoint.length - 1] - timePoint[timePoint.length - 2];
      const elapsedSeconds = elapsedTime / 1000;
      const minutes = Math.floor(elapsedSeconds / 60);
      const seconds = elapsedSeconds - minutes * 60;
      let msg = stepName + ':  ';
      if (minutes > 0) {
        msg += minutes + ' minutes and ';
      }
      msg += seconds.toFixed(3) + ' seconds';
      console.log(msg);
    }; // reportStepElapsedTime

    const reportTotalElapsedTime = () => {
      const elapsedTime = timePoint[timePoint.length - 1] - timePoint[0];
      const elapsedSeconds = elapsedTime / 1000;
      const minutes = Math.floor(elapsedSeconds / 60);
      const seconds = elapsedSeconds - minutes * 60;
      let msg = stepName + ':  ';
      if (minutes > 0) {
        msg += minutes + ' minutes and ';
      }
      msg += seconds.toFixed(3) + ' seconds';
      console.log(msg);
    }; // reportTotalElapsedTime

    // machinery to manage affiliate tree
    const obtainAffiliateCounts = () =>
      new Promise((resolve, reject) => {
        let p, level, stack, node, stkItem;

        const getNode = funcP => {
          if (!parms.tree.hasOwnProperty(funcP)) {
            return null;
          } else {
            return parms.tree[funcP].node;
          }
        }; // getNode

        const visitNode = (funcNode, funcLevel) => {
          if (funcLevel > 0 && funcLevel <= 6) {
            parms.treeAffiliateCounts.totalAffiliateCount++;
            if (funcNode.isInTrialPeriod) {
              parms.treeAffiliateCounts.trialAffiliateCount++;
            }
            if (funcNode.isActive) {
              parms.treeAffiliateCounts.activeAffiliateCount++;
            }
          }
        }; // visitNode

        // initialize
        p = parms.subtreeRoot; // root of subtree to traverse
        level = 0;
        stack = []; // array used as stack to track the tree traversal

        // use preorder traversal to examine all nodes under given subtree
        stack.push({ p, level });
        while (stack.length > 0) {
          // get current node to process
          stkItem = stack.pop();
          p = stkItem.p;
          level = stkItem.level;
          node = getNode(p);

          if (node !== null) {
            // visit the current node
            visitNode(node, level);

            // set things up to process children of the current node
            if (level < 6) {
              for (const q of node.child) {
                stack.push({ p: q, level: level + 1 });
              }
            }
          }
        } // while stack is not empty

        resolve();
      }); // return Promise // obtainAffiliateCounts
    // initialize information to process
    // let affiliateID = 'ylrjxfe';  // Jeff (in prod environment)
    const affiliateID = 'fqeyxzc'; // Diana (in prod environment)
    // let affiliateID = 'xhreead';  // random test subject with smaller tree
    // let affiliateID = 'pwbqkwx';  // random test subject with yet smaller tree (6 nodes)
    // let affiliateID = 'aaaaaaa';  // root of tree

    // view affiliate subtree for selected affiliateID
    // dataModelService.affiliateManagement.displayTree(affiliateID);  //%//

    let parms: any; // holds information for various processing steps
    parms = {};
    parms.subtreeRoot = affiliateID; // root of affiliate subtree to process
    parms.tree = dataModelService.affiliateManagement.tree;
    parms.treeAffiliateCounts = {
      totalAffiliateCount: 0,
      trialAffiliateCount: 0,
      activeAffiliateCount: 0
    };
    const stepname = 'unknown';

    // process data
    console.log('Timing test 1b -- memory resident tree -- Braintree status in affiliate tree nodes');
    addTimePoint(); // capture time prior to first processing step

    dataModelService
      .initializeAffiliateManagement()
      .then(addTimePoint)
      .then(() => {
        stepName = 'load affiliate tree into memory';
      })
      .then(reportStepElapsedTime)

      .then(obtainAffiliateCounts)
      .then(addTimePoint)
      .then(() => {
        stepName = 'obtainAffiliateCounts';
      })
      .then(reportStepElapsedTime)

      .catch(err => {
        console.log(err);
      })

      .then(() => {
        console.log('treeAffiliateCounts:', parms.treeAffiliateCounts);
      })
      .then(addTimePoint)
      .then(() => {
        stepName = 'total elapsed time';
      })
      .then(reportTotalElapsedTime);
  } // runTimingTests1a

  public runTimingTests1b(dataModelService: any) {
    // machinery to support timing of processing steps
    let stepName = 'first step';
    const timePoint = [];

    const addTimePoint = () => {
      timePoint.push(new Date().getTime());
    }; // addTimePoint

    const reportStepElapsedTime = () => {
      const elapsedTime = timePoint[timePoint.length - 1] - timePoint[timePoint.length - 2];
      const elapsedSeconds = elapsedTime / 1000;
      const minutes = Math.floor(elapsedSeconds / 60);
      const seconds = elapsedSeconds - minutes * 60;
      let msg = stepName + ':  ';
      if (minutes > 0) {
        msg += minutes + ' minutes and ';
      }
      msg += seconds.toFixed(3) + ' seconds';
      console.log(msg);
    }; // reportStepElapsedTime

    const reportTotalElapsedTime = () => {
      const elapsedTime = timePoint[timePoint.length - 1] - timePoint[0];
      const elapsedSeconds = elapsedTime / 1000;
      const minutes = Math.floor(elapsedSeconds / 60);
      const seconds = elapsedSeconds - minutes * 60;
      let msg = stepName + ':  ';
      if (minutes > 0) {
        msg += minutes + ' minutes and ';
      }
      msg += seconds.toFixed(3) + ' seconds';
      console.log(msg);
    }; // reportTotalElapsedTime

    // machinery to manage affiliate tree

    const obtainWizeFiID = () =>
      new Promise((resolve, reject) => {
        // define params to guide the query operation
        const params = {
          TableName: 'WizeFiData',
          IndexName: 'WizeFiData-AffiliateID',
          KeyConditionExpression: 'affiliateID = :affiliateID',
          ExpressionAttributeValues: { ':affiliateID': parms.affiliateID }
        };

        // get info from DynamoDB WizeFiData-AffiliateID index
        this.docClient.query(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            const wizeFiID = data.Items.length === 0 ? 'unknown' : data.Items[0].wizeFiID;
            // console.log('affiliateID: ' + parms.affiliateID + '  wizeFiID: ' + wizeFiID);  //%//
            resolve(wizeFiID);
          }
        });
      }); // return Promise // obtainWizeFiID
    const getNode = p => {
      let node = null;
      if (parms.tree.hasOwnProperty(p)) {
        node = parms.tree[p].node;
      }
      return node;
    }; // getNode

    const visitNode = (p, level) =>
      new Promise((resolve, reject) => {
        if (parms.wantVisit) {
          obtainWizeFiID()
            .then(wizeFiID => dataModelService.braintreeManagement.getBraintreeData(wizeFiID))
            .then(braintreeData => {
              parms.braintreeData[p] = braintreeData;
            })
            .then(() => {
              resolve();
            })
            .catch(err => {
              reject(err);
            });
        } else {
          resolve();
        }
      }); // return Promise // visitNode
    const pretrav = async (p, level) => {
      parms.affiliateID = p; // set this value for use in other routines

      // visit the current node
      await visitNode(p, level);

      // recursively visit each child of the current node
      const node = getNode(p);
      if (node !== null) {
        for (const q of node.child) {
          pretrav(q, level + 1);
        }
      }
    }; // pretrav

    const traverseTree = () => {
      pretrav(parms.affiliateID, 0);
    }; // traverseTree

    /*
        // test getBraintreeData
        // let customerID = '10154672182542352';  // Jeff (in dev environment)
        let customerID = '10154672182542352';  // Jeff (in prod environment)
        addTimePoint();  // capture time prior to first processing step
        dataModelService.braintreeManagement.getBraintreeData(customerID)
        .then((braintreeData) => {console.log('braintreeData: ', braintreeData)})
        .catch((err) => {console.log(err)})
        .then(addTimePoint)  // capture time at end of getBraintreeData processing step
        .then(() => {stepName = 'getBraintreeData'})
        .then(reportStepElapsedTime)
        .then(() => {stepName = 'total elapsed time for getBraintreeData'})
        .then(reportTotalElapsedTime);
        */

    // test different versions of access to data (memory resident vs DynamoDB and Braintree access)

    // initialize information to process
    // let affiliateID = 'ylrjxfe';  // Jeff (in prod environment)
    // let affiliateID = 'fqeyxzc';  // Diana (in prod environment)
    // let affiliateID = 'xhreead';  // random test subject with smaller tree
    const affiliateID = 'pwbqkwx'; // random test subject with yet smaller tree (6 nodes)

    // view affiliate subtree for selected affiliateID
    // dataModelService.affiliateManagement.displayTree(affiliateID);  //%//

    let parms: any; // deal with Typescript type checking
    parms = {}; // holds information for various processing steps
    parms.affiliateID = affiliateID;
    parms.tree = dataModelService.affiliateManagement.tree;
    parms.wantVisit = true;
    parms.braintreeData = {};

    // process data
    console.log('Timing test 1b -- memory resident tree -- does NOT wait for all processing to complete');
    addTimePoint(); // capture time prior to first processing step

    dataModelService
      .initializeAffiliateManagement()
      .then(addTimePoint) // capture time at end of initializeAffiliateManagement processing step
      .then(() => {
        stepName = 'load affiliate tree into memory';
      })
      .then(reportStepElapsedTime)

      .then(traverseTree)
      .then(addTimePoint) // capture time at end of traverseTree processing step
      .then(() => {
        stepName = 'traverseTree';
      })
      .then(reportStepElapsedTime)
      .then(() => {
        console.log('parms.braintreeData: ', parms.braintreeData);
      })

      .catch(err => {
        console.log(err);
      })

      .then(addTimePoint) // capture time at end of all processing steps
      .then(() => {
        stepName = 'total elapsed time';
      })
      .then(reportTotalElapsedTime);
  } // runTimingTests1b

  public runTimingTests2(dataModelService: any) {
    // machinery to support timing of processing steps
    let stepName = 'first step';
    const timePoint = [];

    const addTimePoint = () => {
      timePoint.push(new Date().getTime());
    }; // addTimePoint

    const reportElapsedTime = (time1, time2) => {
      const elapsedTime = time1 - time2;
      const elapsedSeconds = elapsedTime / 1000;
      const minutes = Math.floor(elapsedSeconds / 60);
      const seconds = elapsedSeconds - minutes * 60;
      let msg = stepName + ':  ';
      if (minutes > 0) {
        msg += minutes + ' minutes and ';
      }
      msg += seconds.toFixed(3) + ' seconds';
      console.log(msg);
    }; // reportElapsedTime

    const reportStepElapsedTime = () => {
      reportElapsedTime(timePoint[timePoint.length - 1], timePoint[timePoint.length - 2]);
    }; // reportStepElapsedTime

    const reportTotalElapsedTime = () => {
      reportElapsedTime(timePoint[timePoint.length - 1], timePoint[0]);
    }; // reportTotalElapsedTime

    // machinery to manage affiliate tree

    const obtainWizeFiID = () =>
      new Promise((resolve, reject) => {
        // define params to guide the query operation
        const params = {
          TableName: 'WizeFiData',
          IndexName: 'WizeFiData-AffiliateID',
          KeyConditionExpression: 'affiliateID = :affiliateID',
          ExpressionAttributeValues: { ':affiliateID': parms.affiliateID }
        };

        // get info from DynamoDB WizeFiData-AffiliateID index
        this.docClient.query(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            const wizeFiID = data.Items.length === 0 ? 'unknown' : data.Items[0].wizeFiID;
            resolve(wizeFiID);
          }
        });
      }); // return Promise // obtainWizeFiID
    const getNode = p =>
      new Promise((resolve, reject) => {
        // define params to guide get operation
        const params = {
          TableName: 'WizeFiAffiliateTree',
          Key: { affiliateID: p }
        };

        // get Item from DynamoDB table
        this.docClient.get(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            // return results
            if (!data.hasOwnProperty('Item')) {
              // TODO determine whether to return null for this situation, and deal with the problem in calling code
              reject(null);
            } else {
              try {
                data.Item.node = JSON.parse(data.Item.node);
                resolve(data.Item.node);
              } catch (ex) {
                reject(null);
              }
            }
          }
        });
      }); // return Promise // getNode
    const visitNode = (p, level) =>
      new Promise((resolve, reject) => {
        const updateCounts = braintreeData => {
          parms.treeAffiliateCounts.totalAffiliateCount++;
          if (braintreeData.isInTrialPeriod) {
            parms.treeAffiliateCounts.trialAffiliateCount++;
          }
          if (braintreeData.isActive) {
            parms.treeAffiliateCounts.activeAffiliateCount++;
          }

          return Promise.resolve;
        }; // updateCounts

        if (level === 0 || level > 6) {
          resolve();
        } else {
          parms.affiliateID = p;

          obtainWizeFiID()
            .then(wizeFiID => dataModelService.braintreeManagement.getBraintreeData(wizeFiID))
            .then(updateCounts)
            .then(() => {
              resolve();
            })
            .catch(err => {
              reject(err);
            });
        }
      }); // return Promise // visitNode
    const getTreeAffiliateCounts = async () => {
      let p, level, stack, node, stkItem;

      p = parms.affiliateID; // root of subtree to traverse
      level = 0;
      stack = []; // array used as stack to track the tree traversal

      stack.push({ p, level });
      while (stack.length > 0) {
        // get current node to process
        stkItem = stack.pop();
        p = stkItem.p;
        level = stkItem.level;
        node = await getNode(p);

        if (node !== null) {
          // visit the current node
          await visitNode(p, level);

          // set things up to process children of the current node
          if (level < 6) {
            for (const q of node.child) {
              stack.push({ p: q, level: level + 1 });
            }
          }
        }
      } // while stack is not empty
    }; // getTreeAffiliateCounts

    // initialize information to process
    // let affiliateID = 'ylrjxfe';  // Jeff (in prod environment)
    // let affiliateID = 'fqeyxzc';  // Diana (in prod environment)
    // let affiliateID = 'xhreead';  // random test subject with smaller tree
    const affiliateID = 'pwbqkwx'; // random test subject with yet smaller tree (6 nodes)

    let parms: any; // deal with Typescript type checking
    parms = {}; // holds information for various processing steps
    parms.affiliateID = affiliateID;
    parms.treeAffiliateCounts = {
      totalAffiliateCount: 0,
      trialAffiliateCount: 0,
      activeAffiliateCount: 0
    };

    // process data
    console.log('Timing test 2 -- DynamoDB resident tree -- long time (waits for all processing to complete)');
    addTimePoint(); // capture time prior to first processing step

    getTreeAffiliateCounts()
      .then(addTimePoint) // capture time at end of getTreeAffiliateCounts processing step
      .then(() => {
        stepName = 'getTreeAffiliateCounts';
      })
      .then(reportStepElapsedTime)
      .then(() => {
        console.log('treeAffiliateCounts:', parms.treeAffiliateCounts);
      })

      .catch(err => {
        console.log(err);
      })

      .then(addTimePoint) // capture time at end of all processing steps
      .then(() => {
        stepName = 'total elapsed time';
      })
      .then(reportTotalElapsedTime);
  } // runTimingTests2

  public runTimingTests2b(dataModelService: any) {
    // machinery to support timing of processing steps
    let stepName = 'first step';
    const timePoint = [];

    const addTimePoint = () => {
      timePoint.push(new Date().getTime());
    }; // addTimePoint

    const reportElapsedTime = (time1, time2) => {
      const elapsedTime = time1 - time2;
      const elapsedSeconds = elapsedTime / 1000;
      const minutes = Math.floor(elapsedSeconds / 60);
      const seconds = elapsedSeconds - minutes * 60;
      let msg = stepName + ':  ';
      if (minutes > 0) {
        msg += minutes + ' minutes and ';
      }
      msg += seconds.toFixed(3) + ' seconds';
      console.log(msg);
    }; // reportElapsedTime

    const reportStepElapsedTime = () => {
      reportElapsedTime(timePoint[timePoint.length - 1], timePoint[timePoint.length - 2]);
    }; // reportStepElapsedTime

    const reportTotalElapsedTime = () => {
      reportElapsedTime(timePoint[timePoint.length - 1], timePoint[0]);
    }; // reportTotalElapsedTime

    // machinery to manage affiliate tree

    const obtainWizeFiID = () =>
      new Promise((resolve, reject) => {
        // define params to guide the query operation
        const params = {
          TableName: 'WizeFiData',
          IndexName: 'WizeFiData-AffiliateID',
          KeyConditionExpression: 'affiliateID = :affiliateID',
          ExpressionAttributeValues: { ':affiliateID': parms.affiliateID }
        };

        // get info from DynamoDB WizeFiData-AffiliateID index
        this.docClient.query(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            const wizeFiID = data.Items.length === 0 ? 'unknown' : data.Items[0].wizeFiID;
            resolve(wizeFiID);
          }
        });
      }); // return Promise // obtainWizeFiID
    const getNode = p =>
      new Promise((resolve, reject) => {
        // define params to guide get operation
        const params = {
          TableName: 'WizeFiAffiliateTree',
          Key: { affiliateID: p }
        };

        // get Item from DynamoDB table
        this.docClient.get(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            // return results
            if (!data.hasOwnProperty('Item')) {
              // TODO determine whether to return null for this situation, and deal with the problem in calling code
              reject('affiliateID ' + p + ' is not in affiliate tree');
            } else {
              try {
                data.Item.node = JSON.parse(data.Item.node);
                resolve(data.Item.node);
              } catch (ex) {
                reject('error ' + ex.message + ' in parsing node for affiliateID: ' + p);
              }
            }
          }
        });
      }); // return Promise // getNode
    const visitNode = (p, level) =>
      new Promise((resolve, reject) => {
        if (parms.wantVisit) {
          obtainWizeFiID()
            .then(wizeFiID => dataModelService.braintreeManagement.getBraintreeData(wizeFiID))
            .then(braintreeData => {
              parms.braintreeData[p] = braintreeData;
            })
            .then(() => {
              resolve();
            })
            .catch(err => {
              reject(err);
            });
        } else {
          resolve();
        }
      }); // return Promise // visitNode
    const pretrav = async (p, level, rejectTraverseTree) => {
      try {
        parms.affiliateID = p; // set this value for use in other routines

        // visit the current node
        // await visitNode(p,level);
        await visitNode(p, level).catch(err => {
          throw err;
        });

        // recursively visit each child of the current node
        // let node = await getNode(p)
        let node: any;
        node = await getNode(p).catch(err => {
          throw err;
        });
        if (node !== null) {
          for (const q of node.child) {
            pretrav(q, level + 1, rejectTraverseTree);
          }
        }
      } catch (ex) {
        rejectTraverseTree(ex);
      }
    }; // pretrav

    const traverseTree = () =>
      new Promise((resolveTraverseTree, rejectTraverseTree) => {
        pretrav(parms.affiliateID, 0, rejectTraverseTree);
        resolveTraverseTree();
      }); // return Promise // traverseTree
    /*
        // test getBraintreeData
        // let customerID = '10154672182542352';  // Jeff (in dev environment)
        let customerID = '10154672182542352';  // Jeff (in prod environment)
        addTimePoint();  // capture time prior to first processing step
        dataModelService.braintreeManagement.getBraintreeData(customerID)
        .then((braintreeData) => {console.log('braintreeData: ', braintreeData)})
        .catch((err) => {console.log(err)})
        .then(addTimePoint)  // capture time at end of getBraintreeData processing step
        .then(() => {stepName = 'getBraintreeData'})
        .then(reportStepElapsedTime)
        .then(() => {stepName = 'total elapsed time for getBraintreeData'})
        .then(reportTotalElapsedTime);
        */

    // test different versions of access to data (memory resident vs DynamoDB and Braintree access)

    // initialize information to process
    // let affiliateID = 'ylrjxfe';  // Jeff (in prod environment)
    // let affiliateID = 'fqeyxzc';  // Diana (in prod environment)
    // let affiliateID = 'xhreead';  // random test subject with smaller tree
    const affiliateID = 'pwbqkwx'; // random test subject with yet smaller tree (6 nodes)

    // view affiliate subtree for selected affiliateID
    // dataModelService.affiliateManagement.displayTree(affiliateID);  //%//

    let parms: any; // deal with Typescript type checking
    parms = {}; // holds information for various processing steps
    parms.affiliateID = affiliateID;
    parms.tree = dataModelService.affiliateManagement.tree;
    parms.wantVisit = true;
    parms.braintreeData = {};

    // process data
    console.log('Timing test 2b -- DynamoDB resident tree -- does NOT wait for all processing to complete');
    addTimePoint(); // capture time prior to first processing step
    traverseTree()
      .then(addTimePoint) // capture time at end of traverseTree processing step
      .then(() => {
        stepName = 'traverseTree';
      })
      .then(reportStepElapsedTime)
      .then(() => {
        console.log('parms.braintreeData: ', parms.braintreeData);
      })

      .catch(err => {
        console.log(err);
      })

      .then(addTimePoint) // capture time at end of all processing steps
      .then(() => {
        stepName = 'total elapsed time';
      })
      .then(reportTotalElapsedTime);
  } // runTimingTests2b

  public runVersionManagementTests(dataModelService: any) {
    let versionManagement: VersionManagement;

    console.log('start version management tests');

    //////////////////////////////////
    // test non plan data update
    /////////////////////////////////
    try {
      versionManagement = new VersionManagement(dataModelService);

      // extract non plan data to be updated
      const nonPlan = {
        // persistentDataVersion: dataModelService.dataModel.persistent.persistentDataVersion,
        persistentDataVersion: 1, // test value
        header: dataModelService.dataModel.persistent.header,
        settings: dataModelService.dataModel.persistent.settings,
        profile: dataModelService.dataModel.persistent.profile
      };
      console.log('nonPlan before update:');
      console.log(nonPlan);
      const newNonPlan = versionManagement.updateNonPlanVersion(nonPlan);
      console.log('nonPlan after update:');
      console.log(newNonPlan);
      //
      // normal action after calling updateNonPlanVersion would be to plant updated version of the data as shown below
      dataModelService.dataModel.persistent.persistentDataVersion = newNonPlan.persistentDataVersion;
      dataModelService.dataModel.persistent.header = newNonPlan.header;
      dataModelService.dataModel.persistent.settings = newNonPlan.settings;
      dataModelService.dataModel.persistent.profile = newNonPlan.profile;
      //
    } catch (ex) {
      console.log('Error:');
      console.log(ex);
    }

    //////////////////////////////////
    // test plan data update
    /////////////////////////////////
    try {
      versionManagement = new VersionManagement(dataModelService);

      // extract plan data to be updated
      const curplan = dataModelService.dataModel.persistent.header.curplan;
      const plan = dataModelService.dataModel.persistent.plans[curplan];

      console.log('plan before update:');
      console.log(plan);
      const newPlan = versionManagement.updatePlanVersion(plan);
      console.log('plan after update:');
      console.log(newPlan);
      //
      // normal action after calling updatePlanVersion would be to plant updated version of the data as shown below
      dataModelService.dataModel.persistent.plans[curplan] = newPlan;
      //
    } catch (ex) {
      console.log('Error:');
      console.log(ex);
    }

    console.log('end version management tests');
  } // runVersionManagementTests

  public testComputeAffiliateHistory(dataModelService) {
    const subscriptionManagement = new SubscriptionManagement(dataModelService);

    // data to drive this program
    const dayToProcess = '2017-09-26'; // 22 through 29 for early adopters
    const updateAffiliateCount = false;

    console.log('Test computeAffiliateHistory for ' + dayToProcess + '  updateAffiliateCount: ', updateAffiliateCount);
    console.log('(this computation can take over a minute or more -- wait for response to be displayed...)');
    const startTime = new Date().getTime(); // start timer for execution of this code

    subscriptionManagement
      .computeAffiliateHistory(dayToProcess, updateAffiliateCount)
      .then(result => {
        const endTime = new Date().getTime();
        const elapsedTime = endTime - startTime;
        if (typeof result === 'string') {
          console.log(result + '  ' + elapsedTime + ' milliseconds');
        } else {
          console.log(result);
        }
      })
      .catch(err => {
        console.log(err);
      });
  } // testComputeAffiliateHistory

  public testRetrieveAffiliateHistory(dataModelService) {
    // data to drive this program
    // let affiliateID = 'aaaaaaa';   // root
    const affiliateID = 'ylrjxfe'; // thefosters

    console.log('Test retrieveAffiliateHistory for ' + affiliateID);

    dataModelService.affiliateManagement
      .retrieveAffiliateHistory(dataModelService, affiliateID)
      .then(parms => {
        console.log('parms = ', parms);
      })
      .catch(err => {
        console.log(err);
      });
  } // testRetrieveAffiliateHistory

  public loadTestTreeData(dataModelService: any) {
    // initialize tree with test data
    const rootAffiliateID = dataModelService.affiliateManagement.rootAffiliateID;
    const fee = dataModelService.affiliateManagement.defaultFee;
    const tree = {
      aaaaaaa: { version: 0, affiliateAlias: 'u00', node: { isActive: true, fee, parent: null, child: ['ppngahv', 'ibzahde', 'andvmun'] } },
      ppngahv: { version: 0, affiliateAlias: 'u01', node: { isActive: true, fee, parent: rootAffiliateID, child: ['zhqomfi', 'okpkfdy'] } },
      ibzahde: {
        version: 0,
        affiliateAlias: 'u02',
        node: { isActive: true, fee, parent: rootAffiliateID, child: ['fgctfqo', 'zyrgvxm', 'mcvyihv'] }
      },
      andvmun: { version: 0, affiliateAlias: 'u03', node: { isActive: true, fee, parent: rootAffiliateID, child: ['pdlwyyx', 'tkgacwz'] } },
      zhqomfi: { version: 0, affiliateAlias: 'u04', node: { isActive: true, fee, parent: 'ppngahv', child: [] } },
      okpkfdy: { version: 0, affiliateAlias: 'u05', node: { isActive: true, fee, parent: 'ppngahv', child: [] } },
      fgctfqo: { version: 0, affiliateAlias: 'u06', node: { isActive: true, fee, parent: 'ibzahde', child: [] } },
      zyrgvxm: { version: 0, affiliateAlias: 'u07', node: { isActive: true, fee, parent: 'ibzahde', child: ['ekmuads', 'yhfxruf', 'fgklfpf'] } },
      mcvyihv: { version: 0, affiliateAlias: 'u08', node: { isActive: true, fee, parent: 'ibzahde', child: [] } },
      pdlwyyx: { version: 0, affiliateAlias: 'u09', node: { isActive: true, fee, parent: 'andvmun', child: [] } },
      tkgacwz: { version: 0, affiliateAlias: 'u10', node: { isActive: true, fee, parent: 'andvmun', child: [] } },
      ekmuads: { version: 0, affiliateAlias: 'u11', node: { isActive: true, fee, parent: 'zyrgvxm', child: [] } },
      yhfxruf: { version: 0, affiliateAlias: 'u12', node: { isActive: true, fee, parent: 'zyrgvxm', child: ['irvvgur', 'foieplo'] } },
      fgklfpf: { version: 0, affiliateAlias: 'u13', node: { isActive: false, fee, parent: 'zyrgvxm', child: ['wzisnqh', 'ytnvrlh'] } },
      wzisnqh: { version: 0, affiliateAlias: 'u14', node: { isActive: true, fee, parent: 'fgklfpf', child: [] } },
      ytnvrlh: { version: 0, affiliateAlias: 'u15', node: { isActive: true, fee, parent: 'fgklfpf', child: [] } },
      irvvgur: { version: 0, affiliateAlias: 'u16', node: { isActive: true, fee, parent: 'zyrgvxm', child: ['zaeuuvw'] } },
      foieplo: { version: 0, affiliateAlias: 'u17', node: { isActive: true, fee, parent: 'zyrgvxm', child: [] } },
      zaeuuvw: { version: 0, affiliateAlias: 'u18', node: { isActive: true, fee, parent: 'irvvgur', child: ['gsczsdt', 'qkuignn'] } },
      gsczsdt: { version: 0, affiliateAlias: 'u19', node: { isActive: true, fee, parent: 'zaeuuvw', child: [] } },
      qkuignn: { version: 0, affiliateAlias: 'u20', node: { isActive: true, fee, parent: 'zaeuuvw', child: ['lzlwghh', 'potiyue', 'zhvrzmw'] } },
      lzlwghh: { version: 0, affiliateAlias: 'u21', node: { isActive: true, fee, parent: 'qkuignn', child: [] } },
      potiyue: { version: 0, affiliateAlias: 'u22', node: { isActive: true, fee, parent: 'qkuignn', child: ['zryjmbq'] } },
      zhvrzmw: { version: 0, affiliateAlias: 'u23', node: { isActive: true, fee, parent: 'qkuignn', child: [] } },
      zryjmbq: { version: 0, affiliateAlias: 'u24', node: { isActive: true, fee, parent: 'potiyue', child: ['tjomylk'] } },
      tjomylk: { version: 0, affiliateAlias: 'u25', node: { isActive: true, fee, parent: 'potiyue', child: [] } }
    };

    if (
      !confirm(
        'WARNING!!!!\n' +
          'This option is used to load data into an empty DynamoDB affiliate tree.\n' +
          'Loading into a non-empty tree can corrupt the data.\n' +
          'Do you wish to proceed?'
      )
    ) {
      console.log('No tree data was loaded');
    } else {
      console.log('Tree data will be loaded');

      // initialize
      const tableName = 'WizeFiAffiliateTree';
      const action = 'putItem';
      let actionParms: any;
      let count = 0;

      for (const affiliateID in tree) {
        if (tree.hasOwnProperty(affiliateID)) {
          actionParms = {
            affiliateID,
            affiliateAlias: tree[affiliateID].affiliateAlias,
            node: tree[affiliateID].node
          };
          count++;
          console.log(count + '  ' + affiliateID + '  ' + tree[affiliateID].affiliateAlias);
          // note: ignore for now that the following is asynchronous (the end result is correct)
          dataModelService.affiliateManagement.invokeManageAffiliateTree(dataModelService, tableName, action, actionParms);
        }
      }
    }
  } // loadTestTreeData
} // class ItemManagement
