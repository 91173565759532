import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cafr-created',
  templateUrl: './cafr-created.component.html',
  styleUrls: ['./cafr-created.component.scss']
})
export class CafrCreatedComponent implements OnInit {
  @Input() public guidelineCafr: number;
  @Input() public expenses: number;
  @Input() public income: number;

  constructor() {}

  public ngOnInit() {}
}
