import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

const CLOSE_ICON = '../../../assets/icon_close.svg';

@Component({
  selector: 'app-manual-account-chooser',
  templateUrl: './manual-account-chooser.component.html',
  styleUrls: ['./manual-account-chooser.component.scss']
})
export class ManualAccountChooserComponent {
  public showAccountTypeMenu = false;
  public accountType: string;
  public closeButtonSrc = CLOSE_ICON;

  @Input() public selectedSubtype;
  @Input() public selectedSubcategory;
  @Input() public selectedCategory;
  @Input() public selectedType;
  @Input() public types;
  @Input() public subtypes;

  @Output() public onTypeChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onSubtypeChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onCancel: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Controls showing the account subtypes menu
   *
   * @param type
   */
  public selectAccountType(type) {
    this.accountType = type;
    this.onTypeChange.emit(type);
  }

  /**
   * Account subtype selection
   *
   * @param subtype
   */
  public selectSubType(subtype) {
    this.onSubtypeChange.emit(subtype);
  }

  _onCancel() {
    this.onCancel.emit();
  }
}
