import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DataModelService } from './data-model/data-model.service';

@Injectable({
  providedIn: 'root'
})
export class SetupService {
  public static readonly lastStepNumber = 9;

  public constructor(private dataModelService: DataModelService, private router: Router) {}

  public isInSetupPage(): boolean {
    return this.router.url.indexOf('setup') !== -1;
  }

  public updateCurrentSetupStep(newPhase: number): void {
    const currentStep = this.dataModelService.dataModel.persistent.header.setupStepCompleted;
    if (newPhase > currentStep) {
      this.dataModelService.dataModel.persistent.header.setupStepCompleted = newPhase;
    }
  }

  public getCurrentSetupStep(): number {
    return this.dataModelService.dataModel.persistent.header.setupStepCompleted;
  }

  public hasCompletedSetup(): boolean {
    return this.dataModelService.dataModel.persistent.header.dateProfileCompleted !== '';
  }
}
