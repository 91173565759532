// admin-run-tests.component.ts

import { Component, OnInit } from '@angular/core';
import fileUploadService from 'src/app/services/fileupload.service';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { environment } from '../../../../environments/environment';
import { categoryExcludeList, subcategoryExcludeList, pictureNotAvailableObjectKey } from '../../../services/data-model/data-model_0001.data';

declare const AWS: any;

@Component({
  selector: 'app-admin-run-tests',
  templateUrl: './admin-run-tests.component.html',
  styleUrls: ['./admin-run-tests.component.scss']
})
export class AdminRunTestsComponent implements OnInit {
  public loading: any; // used to control "is working" visual on screen

  // manage Amazon S3 option
  public wantAmazonS3: boolean;
  public wantViewPictures: boolean;
  public wantgetObject: boolean;
  public wantupload: boolean;
  public wantdeleteObject: boolean;
  public wantSetURL: boolean;
  public wantbucketList: boolean;
  public fileURL: string;
  public virtualStyleURL: string;
  public pathStyleURL: string;
  public wizeFiUserPictureURL: string;
  public pictureNotAvailableURL: string;
  public wizeFiIDList: string[];
  public pictureURLmap: any;

  constructor(private dataModelService: DataModelService, protected profilePictureService: fileUploadService) {}

  public ngOnInit() {
    this.loading = {};
    this.loading.isLoading = false;

    // manage Amazon S3 option
    this.wantAmazonS3 = false;
    this.wantViewPictures = false;
    this.wantgetObject = false;
    this.wantupload = false;
    this.wantdeleteObject = false;
    this.wantSetURL = false;
    this.wantbucketList = false;
    this.pictureNotAvailableURL = this.setPictureNotAvailableURL();
    this.fileURL = this.pictureNotAvailableURL;
    this.virtualStyleURL = this.setVirtualURL(environment.AWSRegion, environment.bucketName, 'DaveEland.jpg');
    this.pathStyleURL = this.setPathURL(environment.AWSRegion, environment.bucketName, 'DaveEland.jpg');
    this.wizeFiUserPictureURL = this.setWizeFiUserPictureURL();
    this.wizeFiIDList = [];
    this.pictureURLmap = {};
  } // ngOnInit

  public runAffiliatetreeTests() {
    const runTreeTests = async () => {
      //
      const affiliateID = 'aaaaaaa'; // root of tree
      // let affiliateID = 'fqeyxzc';  // Diana (in prod environment)
      // let affiliateID = 'yrifkyb';  // Tom Allen (in prod environment)
      // let affiliateID = 'ldmxvfd';  //  (in prod environment)

      console.log('Preorder:');
      this.dataModelService.affiliateManagement.displayTree(affiliateID);

      const treeAffiliateCounts = await this.dataModelService.affiliateManagement.getTreeAffiliateCounts(affiliateID);
      console.log('treeAffiliateCounts: ', treeAffiliateCounts);

      const levelCount = this.dataModelService.affiliateManagement.getLevelCount(affiliateID);
      console.log('levelCount: ', levelCount);

      const levelTotalCount = this.dataModelService.affiliateManagement.getLevelTotalCount(affiliateID);
      console.log('levelTotalCount: ', levelTotalCount);
      //
    }; // runTreeTests

    runTreeTests().catch(err => {
      console.log('error in runTests: ', err);
    });
  } // runAffiliatetreeTests

  public runCafrTests() {
    const method = 'guideline';
    const monthCount = 8;

    console.log('test results');
    console.log('dataModelService.dataModel: ', this.dataModelService.dataModel);

    // initialize data required in subsequent tests
    const plan = this.dataModelService.dataModel.persistent.header.curplan;
    console.log('plan: ' + plan);

    /*
        let income = this.dataModelService.dataModel.persistent.plans[plan].income;
        console.log('income: ', income);

        let budget = this.dataModelService.dataModel.persistent.plans[plan].budget;
        console.log('budget: ', budget);

        let assets = this.dataModelService.dataModel.persistent.plans[plan].assets;
        console.log('assets: ', assets);

        let assetProtection = this.dataModelService.dataModel.persistent.plans[plan].assetProtection;
        console.log('assetProtection: ', assetProtection);

        let liabilities = this.dataModelService.dataModel.persistent.plans[plan].liabilities;
        console.log('liabilities: ', liabilities);
        */

    /*
        // test projection information (for generating graphs)
        console.log('test projection information');

        let dataProjectionInfo,cafrDataProjectionInfo,goalDates;

        dataProjectionInfo = this.dataModelService.cafrManagement.getDataProjectionInfo(monthCount,assets,assetProtection,liabilities);
        console.log('dataProjectionInfo:');
        console.log(dataProjectionInfo);

        cafrDataProjectionInfo = this.dataModelService.cafrManagement.getCafrDataProjectionInfo({terminationType:"monthCount", terminationValue:monthCount},'actual');
        console.log('cafrDataProjectionInfo -- actual: ');
        console.log(cafrDataProjectionInfo);

        console.log(' ');
        console.log('Sample asset projection values');
        console.log('Date      No CAFR    With CAFR');
        for (let i = 0; i < monthCount; i++)
        {
            console.log(cafrDataProjectionInfo.assets.summary.projection[i].x.substr(0,7) + '   ' +
                Math.floor(dataProjectionInfo.assets.summary.projection[i].y) + '     ' +
                Math.floor(cafrDataProjectionInfo.assets.summary.projection[i].y));
        }
        console.log('Sample productive debt projection values');
        console.log('Date      No CAFR    With CAFR');
        for (let i = 0; i < monthCount; i++)
        {
            console.log(cafrDataProjectionInfo.assets.summary.projection[i].x.substr(0,7) + '   ' +
                Math.floor(dataProjectionInfo.productiveDebt.summary.projection[i].y) + '     ' +
                Math.floor(cafrDataProjectionInfo.productiveDebt.summary.projection[i].y));
        }
        console.log('Sample nonproductive debt projection values');
        console.log('Date      No CAFR    With CAFR');
        for (let i = 0; i < monthCount; i++)
        {
            console.log(cafrDataProjectionInfo.assets.summary.projection[i].x.substr(0,7) + '   ' +
                Math.floor(dataProjectionInfo.nonproductiveDebt.summary.projection[i].y) + '     ' +
                Math.floor(cafrDataProjectionInfo.nonproductiveDebt.summary.projection[i].y));
        }

        // show goal dates
        goalDates = this.dataModelService.cafrManagement.getGoalDates();
        console.log('goalDates: ', goalDates);

        cafrDataProjectionInfo = this.dataModelService.cafrManagement.getCafrDataProjectionInfo({terminationType:"monthCount", terminationValue:monthCount},'guideline');
        console.log('cafrDataProjectionInfo -- guideline: ');
        console.log(cafrDataProjectionInfo);

        console.log(' ');
        console.log('Sample asset projection values');
        console.log('Date      No CAFR    With CAFR');
        for (let i = 0; i < monthCount; i++)
        {
            console.log(cafrDataProjectionInfo.assets.summary.projection[i].x.substr(0,7) + '   ' +
                Math.floor(dataProjectionInfo.assets.summary.projection[i].y) + '     ' +
                Math.floor(cafrDataProjectionInfo.assets.summary.projection[i].y));
        }
        console.log('Sample productive debt projection values');
        console.log('Date      No CAFR    With CAFR');
        for (let i = 0; i < monthCount; i++)
        {
            console.log(cafrDataProjectionInfo.assets.summary.projection[i].x.substr(0,7) + '   ' +
                Math.floor(dataProjectionInfo.productiveDebt.summary.projection[i].y) + '     ' +
                Math.floor(cafrDataProjectionInfo.productiveDebt.summary.projection[i].y));
        }
        console.log('Sample nonproductive debt projection values');
        console.log('Date      No CAFR    With CAFR');
        for (let i = 0; i < monthCount; i++)
        {
            console.log(cafrDataProjectionInfo.assets.summary.projection[i].x.substr(0,7) + '   ' +
                Math.floor(dataProjectionInfo.nonproductiveDebt.summary.projection[i].y) + '     ' +
                Math.floor(cafrDataProjectionInfo.nonproductiveDebt.summary.projection[i].y));
        }
        */

    /* show goal dates
        goalDates = this.dataModelService.cafrManagement.getGoalDates();
        console.log('goalDates: ', goalDates);
        */

    //

    let terminationCriteria;
    let guidelineCafrDataProjectionInfo, guidelineLastIndex, guidelineTerminationDate, guidelineTerminationAdjustedNetValue;
    let actualCafrDataProjectionInfo, actualLastIndex, actualTerminationDate, actualTerminationAdjustedNetValue;

    terminationCriteria = { terminationType: 'monthCount', terminationValue: 120 };
    console.log('\n====> terminationCriteria', terminationCriteria);

    guidelineCafrDataProjectionInfo = this.dataModelService.cafrManagement.getCafrDataProjectionInfo(terminationCriteria, 'guideline');
    guidelineLastIndex = guidelineCafrDataProjectionInfo.adjustedNetWorth.summary.projection.length - 1;
    guidelineTerminationDate = guidelineCafrDataProjectionInfo.adjustedNetWorth.summary.projection[guidelineLastIndex].x;
    guidelineTerminationAdjustedNetValue = guidelineCafrDataProjectionInfo.adjustedNetWorth.summary.projection[guidelineLastIndex].y;
    console.log('guidelineCafrDataProjectionInfo: ', guidelineCafrDataProjectionInfo);
    console.log('guidelineTerminationDate: ' + guidelineTerminationDate);
    console.log('guidelineTerminationAdjustedNetValue: ' + guidelineTerminationAdjustedNetValue);

    actualCafrDataProjectionInfo = this.dataModelService.cafrManagement.getCafrDataProjectionInfo(terminationCriteria, 'actual');
    actualLastIndex = actualCafrDataProjectionInfo.adjustedNetWorth.summary.projection.length - 1;
    actualTerminationDate = actualCafrDataProjectionInfo.adjustedNetWorth.summary.projection[actualLastIndex].x;
    actualTerminationAdjustedNetValue = actualCafrDataProjectionInfo.adjustedNetWorth.summary.projection[actualLastIndex].y;
    console.log('actualCafrDataProjectionInfo: ', actualCafrDataProjectionInfo);
    console.log('actualTerminationDate: ', actualTerminationDate);
    console.log('actualTerminationAdjustedNetValue: ' + actualTerminationAdjustedNetValue);

    terminationCriteria = { terminationType: 'financialFreedomTargetAmount', terminationValue: 1500000 };
    console.log('\n====> terminationCriteria', terminationCriteria);

    guidelineCafrDataProjectionInfo = this.dataModelService.cafrManagement.getCafrDataProjectionInfo(terminationCriteria, 'guideline');
    guidelineLastIndex = guidelineCafrDataProjectionInfo.adjustedNetWorth.summary.projection.length - 1;
    guidelineTerminationDate = guidelineCafrDataProjectionInfo.adjustedNetWorth.summary.projection[guidelineLastIndex].x;
    guidelineTerminationAdjustedNetValue = guidelineCafrDataProjectionInfo.adjustedNetWorth.summary.projection[guidelineLastIndex].y;
    console.log('guidelineCafrDataProjectionInfo: ', guidelineCafrDataProjectionInfo);
    console.log('guidelineTerminationDate: ', guidelineTerminationDate);
    console.log('guidelineTerminationAdjustedNetValue: ' + guidelineTerminationAdjustedNetValue);

    actualCafrDataProjectionInfo = this.dataModelService.cafrManagement.getCafrDataProjectionInfo(terminationCriteria, 'actual');
    actualLastIndex = actualCafrDataProjectionInfo.adjustedNetWorth.summary.projection.length - 1;
    actualTerminationDate = actualCafrDataProjectionInfo.adjustedNetWorth.summary.projection[actualLastIndex].x;
    actualTerminationAdjustedNetValue = actualCafrDataProjectionInfo.adjustedNetWorth.summary.projection[actualLastIndex].y;
    console.log('actualCafrDataProjectionInfo: ', actualCafrDataProjectionInfo);
    console.log('actualTerminationDate: ', actualTerminationDate);
    console.log('actualTerminationAdjustedNetValue: ' + actualTerminationAdjustedNetValue);
    //
  } // runCafrTests

  public runPlaidManagementTests() {
    const publicToken = 'public-sandbox-3c8e70ff-ad2c-4caa-8019-cf66d4a2f505';

    const testPlaid = async () => {
      // NOTE: some tests use wizeFiID = "zbpizmq" in the demo environment

      let link_token, result, item_id, isActive, transactionDate;
      const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
      const country_codes = this.dataModelService.dataModel.persistent.settings.userCountryCodes;
      const access_token = 'access-sandbox-6545d3f6-bb4f-45c0-ae5e-af16f862b77a';
      const funcWizeFiID = this.dataModelService.dataModel.global.wizeFiID;

      console.log('start tests');

      /*
            link_token = await this.dataModelService.plaidManagement.createLinkToken(wizeFiID, country_codes);
            console.log('createLinkToken -- link_token: ' + link_token);

        link_token = await this.dataModelService.plaidManagement.createUpdateLinkToken(wizeFiID, country_codes, access_token);
        console.log('createUpdateLinkToken -- link_token: ' + link_token);

        const publicKey = await this.dataModelService.plaidManagement.getPublicKey();
        console.log('getPublicKey -- publicKey: ' + publicKey);

            const plaidEnv = await this.dataModelService.plaidManagement.getPlaidEnv();
            console.log('getPlaidEnv -- plaidEnv: ' + plaidEnv);
            */

      /* this works correctly
            console.log('resetLogin');
            const accessTokenList = ["access-sandbox-6545d3f6-bb4f-45c0-ae5e-af16f862b77a"];
            const resetLogin = async () =>
            {
                for (const access_token of accessTokenList)
                {
                    result = await this.dataModelService.plaidManagement.resetLogin(access_token);
                    console.log('resetLogin -- result: ', result);
                }
            };  // resetLogin

            await resetLogin();
            */

      const wizeFiPlaidInstitutions = await this.dataModelService.plaidManagement.getWizeFiPlaidInstitutions(funcWizeFiID);
      console.log('getWizeFiPlaidInstitutions -- wizeFiPlaidInstitutions: ', wizeFiPlaidInstitutions);

      // NOTE: test the following in the context of the Plaid Link institution process
      // const country_codes = this.dataModelService.dataModel.persistent.settings.userCountryCodes;
      // const title = this.dataModelService.dataManagement.getDraftTitle();
      // await this.dataModelService.plaidManagement.addInstitution(wizeFiID, title, public_token, country_codes);
      // console.log('addInstitution -- result: ', result);

      /* this works correctly for both a manual institution and a Plaid institution
            // item_id = "taqnky";  // manual institution
            item_id = "JjJGXBXeXkHW81RGodX6H931PjBAv3hB63WRw";  // Plaid institution
            const title = this.dataModelService.dataManagement.getDraftTitle();
            const itemIdCount = await this.dataModelService.dataManagement.getItemIdCount(wizeFiID,item_id);
            result = await this.dataModelService.plaidManagement.deleteWizeFiPlaidInstitution(wizeFiID,title,item_id,itemIdCount);
            console.log('deleteWizeFiPlaidInstitution -- result: ', result);
            */

      /*
            //  this works correctly
            const institution =
            {
              access_token: this.dataModelService.generateIDcode(6),
              item_id: 'taqnky',
              institution_id: this.dataModelService.generateIDcode(4),
              institutionName: 'MyNewInstitution',
              error: 'none',
              isManual: true,
              isActive: true
            };
            result = await this.dataModelService.plaidManagement.addManualInstitution(wizeFiID, institution);
            console.log('addManualInstitution -- item_id:taqnky -- wizeFiPlaidInstitutions: ', JSON.parse(JSON.stringify(wizeFiPlaidInstitutions)));
            */

      /*
            //  this works correctly
            item_id = "taqnky";
            result = await this.dataModelService.plaidManagement.deleteManualInstitution(wizeFiID, item_id);
            console.log('deleteManualInstitution -- item_id:taqnky -- wizeFiPlaidInstitutions: ', JSON.parse(JSON.stringify(wizeFiPlaidInstitutions)));
            */

      /*
            item_id = "ow6vQRJYRRuEqErRA0wgtgk3KbBdR9fB7M9bk"; isActive = false;
            result = await this.dataModelService.plaidManagement.setInstitutionIsActive(wizeFiID, item_id, isActive);
            console.log('setInstitutionIsActive -- result: ' + result);

            title = this.dataModelService.dataManagement.getDraftTitle();
            transactionDate = new Date().toISOString();
            result = await this.dataModelService.plaidManagement.setTransactionDate(wizeFiID, title, transactionDate);
            console.log('setTransactionDate -- result: ' + result);

            result = await this.dataModelService.plaidManagement.updateInstitutionErrorStatus(wizeFiID,title);
            console.log('updateInstitutionErrorStatus -- result: ' + result);
            */

      const title = this.dataModelService.dataManagement.getDraftTitle();
      await this.dataModelService.plaidManagement.updateBalance(funcWizeFiID, title);

      /*
            const categories = await this.dataModelService.plaidManagement.getCategories();
            console.log('getCategories -- categories: ', categories);

            const accounts = await this.dataModelService.plaidManagement.getAccounts(wizeFiID,title);
            console.log('getAccounts -- accounts: ', accounts);
            */

      /*
            const dateRange = {wantMonthRange: true, yearMonth: "2020-10"};
            const activeWizeFiPlaidAccountIds =
            [
                "G4JGXKXPXwhNa8k3LXD6CorgzVxMwgh1KX51V",
                "n7vXdxdVd4SxWQBwlPn1Ulpm8B4eomf69Nj6L",
                "bKZanPnVnpCaWrMDKlwoIXznLDye4nCVn1XVA",
                "mjvex3xVxJHBV45gRNPLIkW9q54m19uLenDLq",
                "yEwa1J1l1dugWDZoNGl6hGKlnQ83xliyPbz4E",
                "9mJvXyXnXktWRGEBrkd8Hxkl1wQVmlfRowp4A",
                "vovrQeQVQBUwArD8dmLlTEWaADJzNaTWbBMqr",
                "RpqenjngnBIL8eGM1BgVtd7JaWMEqJfRNWgaB",
                "6GpwDyDWDXTGXRyKoeNJIne8MA3DR8CgQpB8p"
            ];
            const title = this.dataModelService.dataManagement.getDraftTitle();
            const transactions = await this.dataModelService.plaidManagement.getTransactions(funcWizeFiID, title, dateRange, activeWizeFiPlaidAccountIds);
            console.log("getTransactions -- transactions: ", transactions);
            */

      // console.log("end tests");
    }; // testPlaid

    /*
        testPlaid()
        .catch((err) => {console.log('error in testPlaid: ', err)})
        .finally(() => {console.log("end testPlaid tests")});
        */

    // const wizeFiID = "zbpizmq";  // Dave demo
    // const wizeFiID = "dvduvrt";  // Dave staging

    /*
        this.dataModelService.plaidManagement.getPublicKey()
        .then((response) => {console.log('getPublicKey: ', response)})
        .catch((err) => {console.log('getPublicKey error: ', err)});

        this.dataModelService.plaidManagement.getPlaidEnv()
        .then((response) => {console.log('getPlaidEnv: ', response)})
        .catch((err) => {console.log('getPlaidEnv error: ', err)});
        */

    /*
        this.dataModelService.plaidManagement.removeItem(accessTokenList[0])
        .then((response) => {console.log('removeItem: ', response)})
        .catch((err) => {console.log('removeItem error: ', err)});
        */

    /*
        let item_id = 'bYRDJYb0B7FO4JXMegZMhAygEnxwjzUqYRmKk';
        let isActive = false;

        this.dataModelService.plaidManagement.setInstitutionIsActive(wizeFiID, item_id, isActive)
        .then((result) => {console.log('result: ', result)})
        .catch((err) => {console.log('error in setInstitutionIsActive: ', err)});
        */

    /*
        let transactionDate = new Date().toISOString();

        this.dataModelService.plaidManagement.setTransactionDate(wizeFiID,transactionDate)
        .then((result) => {console.log('result: ', result)})
        .catch((err) => {console.log('error in setTransactionDate: ', err)});
        */

    /*
        this.dataModelService.plaidManagement.getWizeFiPlaidInstitutions(wizeFiID)
        .then((wizeFiPlaidInstitutions) => {console.log('wizeFiPlaidInstitutions: ', wizeFiPlaidInstitutions)})
        .catch((err) => {console.log('error in getWizeFiPlaidInstitutions: ', err)});
        */

    /*
        this.dataModelService.plaidManagement.getCategories()
        .then((categories) => {console.log('categories: ', categories)})
        .catch((err) => {console.log('error in getCategories: ', err)});
        */

    /*
        this.dataModelService.plaidManagement.getAccounts(accessTokenList)
        .then((response) => {console.log('getAccounts: ', response)})
        .catch((err) => {console.log('getAccounts error: ', err)});
        */

    /*

        let dateRange = {wantMonthRange:true, yearMonth:'2020-07'};

        this.dataModelService.plaidManagement.getTransactions(accessTokenList,title,dateRange,activeWizeFiPlaidAccounts)
        .then((transactionInfo) => {console.log('transactionInfo: ', transactionInfo)})
        .catch((err) => {console.log('error in getTransactions: ', err)});
        */

    /*
        this.dataModelService.plaidManagement.getInstitutionsErrorStatus(wizeFiID)
        .then((errorStatusList) => {console.log('errorStatusList: ', errorStatusList)})
        .catch((err) => {console.log('error in getInstitutionsErrorStatus: ', err)});
        */

    /*
        this.dataModelService.plaidManagement.getPlaidData()
        .then(() => {console.log('global.plaidData:', this.dataModelService.dataModel.global.plaidData)})
        .catch((err) => {console.log('error in getPlaidData: ', err)});
        */

    /*
        // test new version of updateWizeFiTransactions in PlaidManagement
        const processError = (err) => {
          console.log("error in updateWizeFiTransactions: ", err);
          if (err.hasOwnProperty("errorMessage") && err.errorMessage === "ITEM_LOGIN_REQUIRED") {
              console.log("You need to use the Update Plaid Link feature to restore access to your financial institution");  // %//
              this.dataModelService.showMessage("error", "You need to use the Update Plaid Link feature to restore access to your financial institution", 20000)
          }
        };  // processError

        const yearMonth = "2020-10";
        this.loading.isLoading = true;

        this.dataModelService.plaidManagement.updateWizeFiTransactions(yearMonth)
        .then((wizeFiTransactions) => {console.log("AdminRunTests -- updateWizeFiTransactions -- wizeFiTransactions: ", wizeFiTransactions)})
        .catch(processError)
        .finally(() => {this.loading.isLoading = false});
        */

    /*
        console.log("test updateAllBalances");
        const wizeFiID = "zbpizmq";  // Dave demo
        // const wizeFiID = "dvduvrt";  // Dave staging
        // const wizeFiID = "nkibzwr";
        // const wizeFiID = "jblniil";

        this.loading.isLoading = true;
        const title = dataManagement.getDraftTitle();
        this.dataModelService.plaidManagement.updateAllBalances(wizeFiID,title)
            .then((wizeFiPlaidAccounts) => { console.log("AdminRunTests -- updateAllBalances -- wizeFiPlaidAccounts: ", wizeFiPlaidAccounts) })
            .catch((err) => { console.log("error in updateAllBalances: ", err) })
            .finally(() => { this.loading.isLoading = false });
        */

    /*
        // test invocation of modal popup screens
        const destination = "WizeFiAccounts";
        const currencyCode = this.dataModelService.plaidManagement.setCurrencyCode(destination);
        console.log('setCurrencyCode -- currencyCode: ' + currencyCode);

        const countryCodes = this.dataModelService.plaidManagement.setCountryCodes();
        console.log('setCountryCodes -- countryCodes: ' + countryCodes);

        const institution_id = "ins_4";
        const wantInstitutionLink = this.dataModelService.plaidManagement.wantMultipleInstitutionInstances(institution_id);
        console.log('wantMultipleInstitutionInstances -- wantInstitutionLink: ' + wantInstitutionLink);
        */

    /*
        const metadata =
        {
            institution:
            {
                institution_id: "ins_1",
            },
            accounts:
            [
                {
                    id: "zKez7x1JP9Cz6JGrMdVlC55GrbNon8Uovx65L",
                    mask: "0000",
                    name: "Plaid Checking",
                    subtype: "checking",
                    type: "depository"
                },
                {
                    id: "BPjkQdWwG9UAg6XeLqM8fJJ8verwM4CwK3Vbj",
                    mask: "1111",
                    name: "Plaid Saving",
                    subtype: "savings",
                    type: "depository"
                },
                {
                    id: "Z74BNDAbQeUEBaL7kNvGcLL7noAXgWigK79RR",
                    mask: "3333",
                    name: "Plaid Credit Card",
                    subtype: "credit card",
                    type: "credit"
                }
            ]
        };
        console.log("isNewInstitutionInstance: " + this.dataModelService.plaidManagement.isNewInstitutionInstance(metadata));
        */

    /*
        const testPlaid2 = async () =>
        {
            const countryCodes = this.dataModelService.dataModel.persistent.settings.userCountryCodes;

            // const institution_id = "ins_3";  // Chase
            // const institution_id = "ins_11";  // Charles Schwab
            const institution_id = "ins_9";  // Capital One
            const institution = await this.dataModelService.plaidManagement.getInstitutionById(institution_id, countryCodes);
            console.log("institution: ", institution);

            // const searchQuery = "Chase";
            // const searchQuery = "Charles Schwab";
            const searchQuery = "Capital One";
            const products = ["transactions"];
            const institutions = await this.dataModelService.plaidManagement.searchInstitutionsByName(searchQuery, products, countryCodes);
            console.log("institutions: ", institutions);
        }   // testPlaid2

        testPlaid2()
        .catch((err) => {console.log('error in testPlaid2: ', err)})
        .finally(() => {console.log("end testPlaid2 tests")});
        */

    //
    const testPlaid3 = async () => {
      // initialize
      const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
      const titles = ['', 'title1'];

      let plaidAccounts, activeWizeFiPlaidAccountIds;

      // utility functions
      const getActiveBalanceDate = plaidAccounts => {
        let balanceDate = '';
        for (const plaidAccount of plaidAccounts) {
          if (plaidAccount.balanceDate > balanceDate) {
            balanceDate = plaidAccount.balanceDate;
          }
        }
        return balanceDate;
      }; // getActiveBalanceDate

      const showAccountsData = plaidAccounts => {
        const balanceDate = '';
        for (const plaidAccount of plaidAccounts) {
          console.log(plaidAccount.balanceDate + '  ' + plaidAccount.isActive);
        }
      }; // showAccountsData

      const getWizeFiAccountsCount = () => {
        // initialize
        const curplan = this.dataModelService.dataModel.persistent.header.curplan;
        const plans = this.dataModelService.dataModel.persistent.plans;
        let wizeFiAccountsCount = 0;

        // scan through all wizeFi user accounts
        for (const category of Object.keys(plans[curplan])) {
          if (categoryExcludeList.indexOf(category) === -1) {
            for (const subcategory of Object.keys(plans[curplan][category])) {
              if (subcategoryExcludeList.indexOf(subcategory) === -1) {
                wizeFiAccountsCount += plans[curplan][category][subcategory].accounts.length;
              } // if include subcategory
            } // for subcategory
          } // if include category
        } // for category

        return wizeFiAccountsCount;
      }; // getWizeFiAccountsCount
      /*  (block out code)

        //
        for (const title of titles) {

          //
                // setTransactionDate  (this is working correctly)
                console.log("\nsetTransactionDate");

                plaidInstitutions = await this.dataModelService.plaidManagement.getPlaidInstitutions(wizeFiID,title);
                console.log("before setTransactionDate -- plaidInstitutions DynamoDB: ", JSON.parse(JSON.stringify(plaidInstitutions)));

                plaidInstitutions = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions;
                console.log("before setTransactionDate -- plaidInstitutions global: ", JSON.parse(JSON.stringify(plaidInstitutions)));

                const transactionDate = new Date().toISOString();
                await this.dataModelService.plaidManagement.setTransactionDate(wizeFiID, title, transactionDate)

                plaidInstitutions = await this.dataModelService.plaidManagement.getPlaidInstitutions(wizeFiID,title);
                console.log("after setTransactionDate -- plaidInstitutionts DynamoDB: ", JSON.parse(JSON.stringify(plaidInstitutions)));

                plaidInstitutions = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions;
                console.log("after setTransactionDate -- plaidInstitutions global: ", JSON.parse(JSON.stringify(plaidInstitutions)));
                //

          //
                // getAccessTokenNeedUpdate  (this is working correctly)
                console.log("\ngetAccessTokenNeedUpdate");

                access_token = "access-sandbox-b97b45b2-4503-4665-975f-f760edc3c856";  // Wells Fargo (from DynamoDB WizeFiData for wizeFiID = zbpizmq)
                await this.dataModelService.plaidManagement.resetLogin(access_token);  // be sure an institution is in ITEM_LOGIN_REQUIRED state

                country_codes = this.dataModelService.dataModel.persistent.settings.userCountryCodes;
                result = await this.dataModelService.plaidManagement.getAccessTokenNeedUpdate(wizeFiID, title, country_codes);
                console.log("after getAccessTokenNeedUpdate -- result: ", result);
                //

          //
                // loadPlaidLink  (this is working correctly)
                console.log("\nloadPlaidLink");

                if (title == "") await this.dataModelService.dataManagement.fetchdata();     // put user data (not draft) into dataModel
                else await this.dataModelService.dataManagement.fetchDraft(title);  // put draft into dataModel

                plaidInstitutions = await this.dataModelService.plaidManagement.getPlaidInstitutions(wizeFiID,title);
                console.log("before loadPlaidLink -- plaidInstitutions DynamoDB: ", JSON.parse(JSON.stringify(plaidInstitutions)));

                plaidInstitutions = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions;
                console.log("before loadPlaidLink -- plaidInstitutions global: ", JSON.parse(JSON.stringify(plaidInstitutions)));

                result = await this.dataModelService.plaidManagement.loadPlaidLink(title);
                console.log("after loadPlaidLink -- result: ", result);

                plaidInstitutions = await this.dataModelService.plaidManagement.getPlaidInstitutions(wizeFiID,title);
                console.log("after loadPlaidLink -- plaidInstitutionts DynamoDB: ", JSON.parse(JSON.stringify(plaidInstitutions)));
                const institution = plaidInstitutions[plaidInstitutions.length - 1];
                const item_id = institution.item_id;  // get item_id of last added institution
                console.log("item_id: " + item_id + "  " + institution.institutionName);

                plaidInstitutions = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions;
                console.log("after loadPlaidLink -- plaidInstitutions global: ", JSON.parse(JSON.stringify(plaidInstitutions)));

                itemIdCount = await this.dataModelService.dataManagement.getItemIdCount(wizeFiID,item_id);
                result = await this.dataModelService.plaidManagement.deletePlaidInstitution(wizeFiID,title,item_id,itemIdCount);
                console.log("after deletePlaidInstitution -- result", result);

                if (title !== "") await this.dataModelService.dataManagement.fetchdata();  // put user data (not draft) back into dataModel
                //

          //
                // loadUpdatePlaidLink  (this is working correctly)
                console.log("\nloadUpdatePlaidLink");

                console.log("=============  get proper data into dataModel");
                if (title == "") await this.dataModelService.dataManagement.fetchdata();     // put user data (not draft) into dataModel
                else await this.dataModelService.dataManagement.fetchDraft(title);  // put draft into dataModel

                console.log("=============  force an account into ITEM_LOGIN_REQUIRED state");
                access_token = "access-sandbox-b97b45b2-4503-4665-975f-f760edc3c856";  // Wells Fargo (from DynamoDB WizeFiData for wizeFiID = zbpizmq)
                await this.dataModelService.plaidManagement.resetLogin(access_token);  // be sure an institution is in ITEM_LOGIN_REQUIRED state

                console.log("=============  process loadUpdatePlaidLink");
                result = await this.dataModelService.plaidManagement.loadUpdatePlaidLink(title);
                console.log("after loadUpdatePlaidLink -- result: ", result);

                console.log("=============  get proper data into dataModel");
                if (title !== "") await this.dataModelService.dataManagement.fetchdata();  // put user data (not draft) back into dataModel
                //

          //
                // test loadUpdatePlaidLink  (this is working correctly)
                console.log("\nloadUpdatePlaidLink");

                console.log("=============  get proper data into dataModel");
                if (title == "") await this.dataModelService.dataManagement.fetchdata();     // put user data (not draft) into dataModel
                else await this.dataModelService.dataManagement.fetchDraft(title);  // put draft into dataModel

                // set an account into error state
                console.log("===========  set an account into error state");

                // manually obtain access_token information from DynamoDB WizeFiPlaidInstitutions for wizeFiID = zbpizmq
                access_token = "access-sandbox-b97b45b2-4503-4665-975f-f760edc3c856";  // Wells Fargo
                await this.dataModelService.plaidManagement.resetLogin(access_token);

                console.log("===========  run loadUpdatePlaidLink");

                // view data before execution of loadUpdatePlaidLink
                result = await this.dataModelService.plaidManagement.updateInstitutionErrorStatus(wizeFiID,title);
                this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions = result;

                plaidInstitutions = await this.dataModelService.plaidManagement.getPlaidInstitutions(wizeFiID,title);
                console.log("before loadUpdatePlaidLink -- plaidInstitutions DynamoDB: ", JSON.parse(JSON.stringify(plaidInstitutions)));

                // run loadUpdatePlaidLink
                result = await this.dataModelService.plaidManagement.loadUpdatePlaidLink(title);
                console.log("after loadUpdatePlaidLink -- result: ", result);

                // view data after execution of loadUpdatePlaidLink
                result = await this.dataModelService.plaidManagement.updateInstitutionErrorStatus(wizeFiID,title);
                this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions = result;

                plaidInstitutions = await this.dataModelService.plaidManagement.getPlaidInstitutions(wizeFiID,title);
                console.log("after loadUpdatePlaidLink -- plaidInstitutions DynamoDB: ", JSON.parse(JSON.stringify(plaidInstitutions)));

                console.log("=============  get proper data into dataModel");
                if (title !== "") await this.dataModelService.dataManagement.fetchdata();  // put user data (not draft data) back into dataModel
                //

          //
                // updateInstitutionErrorStatus  (this is working correctly)
                console.log("\nupdateInstitutionErrorStatus");

                console.log("===========  get proper data in dataModel");
                if (title == "") await this.dataModelService.dataManagement.fetchdata();     // put user data (not draft) into dataModel
                else await this.dataModelService.dataManagement.fetchDraft(title);  // put draft into dataModel

                console.log("===========  remove error status from account");
                // clear out any error in plaidInstitutions
                plaidInstitutions = await this.dataModelService.plaidManagement.getPlaidInstitutions(wizeFiID,title);
                console.log("before loadUpdatePlaidLink -- plaidInstitutions DynamoDB: ", JSON.parse(JSON.stringify(plaidInstitutions)));

                plaidInstitutions = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions;
                console.log("before loadUpdatePlaidLink -- plaidInstitutions global: ", JSON.parse(JSON.stringify(plaidInstitutions)));


                result = await this.dataModelService.plaidManagement.loadUpdatePlaidLink(title);
                console.log("after loadUpdatePlaidLink -- result: ", result);


                plaidInstitutions = await this.dataModelService.plaidManagement.getPlaidInstitutions(wizeFiID,title);
                console.log("after loadUpdatePlaidLink -- plaidInstitutions DynamoDB: ", JSON.parse(JSON.stringify(plaidInstitutions)));

                plaidInstitutions = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions;
                console.log("after loadUpdatePlaidLink -- plaidInstitutions global: ", JSON.parse(JSON.stringify(plaidInstitutions)));

                result = await this.dataModelService.plaidManagement.updateInstitutionErrorStatus(wizeFiID,title);
                console.log("updateInstitutionErrorStatus -- result: ", result);

                // explicitly set an institution in error status
                access_token = "access-sandbox-b97b45b2-4503-4665-975f-f760edc3c856";  // Wells Fargo (from DynamoDB WizeFiData for wizeFiID = zbpizmq)
                await this.dataModelService.plaidManagement.resetLogin(access_token);  // be sure an institution is in ITEM_LOGIN_REQUIRED state

                // update error status for institutions
                console.log("===========  updateInstitutionErrorStatus");
                plaidInstitutions = await this.dataModelService.plaidManagement.getPlaidInstitutions(wizeFiID,title);
                console.log("before updateInstitutionErrorStatuse -- plaidInstitutions DynamoDB: ", JSON.parse(JSON.stringify(plaidInstitutions)));

                plaidInstitutions = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions;
                console.log("before updateInstitutionErrorStatus -- plaidInstitutions global: ", JSON.parse(JSON.stringify(plaidInstitutions)));

                result = await this.dataModelService.plaidManagement.updateInstitutionErrorStatus(wizeFiID,title);
                console.log("updateInstitutionErrorStatus -- result: ", result);
                this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions = result;

                plaidInstitutions = await this.dataModelService.plaidManagement.getPlaidInstitutions(wizeFiID,title);
                console.log("after updateInstitutionErrorStatus -- plaidInstitutions DynamoDB: ", JSON.parse(JSON.stringify(plaidInstitutions)));

                plaidInstitutions = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions;
                console.log("after updateInstitutionErrorStatus -- plaidInstitutions global: ", JSON.parse(JSON.stringify(plaidInstitutions)));

                console.log("===========  restore user data in dataModel");
                if (title !== "") await this.dataModelService.dataManagement.fetchdata();     // put user data (not draft) into dataModel
                //
          //
                console.log("start testPlaid3");

                // get plaidAccounts from updateBalance function
                console.log("\nupdateBalance");

                console.log("===========  get proper data in dataModel");
                if (title == "") await this.dataModelService.dataManagement.fetchdata();     // put user data (not draft) into dataModel
                else await this.dataModelService.dataManagement.fetchDraft(title);  // put draft into dataModel

                console.log("===========  process updateBalance");

                plaidAccounts = await this.dataModelService.plaidManagement.fetchPlaidAccounts(wizeFiID,title);
                // showAccountsData(plaidAccounts);  //%//
                // console.log("before updateBalance -- plaidAccounts DynamoDB: ", JSON.parse(JSON.stringify(plaidAccounts)));
                console.log("before updateBalance -- plaidAccounts DynamoDB -- balanceDate: " + getActiveBalanceDate(plaidAccounts));

                plaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
                // console.log("before updateBalance -- plaidAccounts global: ", JSON.parse(JSON.stringify(plaidAccounts)));
                console.log("before updateBalance -- plaidAccounts global   -- balanceDate: " + getActiveBalanceDate(plaidAccounts));

                plaidAccounts = await this.dataModelService.plaidManagement.updateBalance(wizeFiID,title);
                // this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts =  plaidAccounts;  // update global data

                // console.log("after updateBalance -- plaidAccounts: ", plaidAccounts);
                console.log("after  updateBalance -- plaidAccounts          -- balanceDate: " + getActiveBalanceDate(plaidAccounts));

                plaidAccounts = await this.dataModelService.plaidManagement.fetchPlaidAccounts(wizeFiID,title);
                // console.log("after updateBalance -- plaidAccounts DynamoDB: ", JSON.parse(JSON.stringify(plaidAccounts)));
                console.log("after  updateBalance -- plaidAccounts DynamoDB -- balanceDate: " + getActiveBalanceDate(plaidAccounts));

                plaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
                // console.log("after updateBalance -- plaidAccounts global: ", JSON.parse(JSON.stringify(plaidAccounts)));
                console.log("after  updateBalance -- plaidAccounts global   -- balanceDate: " + getActiveBalanceDate(plaidAccounts));

                console.log("===========  restore user data in dataModel");
                if (title !== "") await this.dataModelService.dataManagement.fetchdata();     // put user data (not draft) into dataModel
                //

          //
                // test updateAllBalances  (this is working correctly)
                console.log('\ntest updateAllBalances');

                plaidAccounts = await this.dataModelService.plaidManagement.fetchPlaidAccounts(wizeFiID,title);
                // console.log("after updateAllBalances -- plaidAccounts DynamoDB: ", JSON.parse(JSON.stringify(plaidAccounts)));
                console.log("berore updateAllBalances -- plaidAccounts DynamoDB -- balanceDate: " + getActiveBalanceDate(plaidAccounts));

                plaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
                // console.log("after updateAllBalances -- plaidAccounts global: ", JSON.parse(JSON.stringify(plaidAccounts)));
                console.log("berore updateAllBalances -- plaidAccounts global   -- balanceDate: " + getActiveBalanceDate(plaidAccounts));

                const wizeFiPlaidAccounts = await this.dataModelService.plaidManagement.updateAllBalances(wizeFiID,title);
                console.log("after updateAllBalances -- wizeFiPlaidAccounts: ", wizeFiPlaidAccounts);

                plaidAccounts = await this.dataModelService.plaidManagement.fetchPlaidAccounts(wizeFiID,title);
                // console.log("after updateAllBalances -- plaidAccounts DynamoDB: ", JSON.parse(JSON.stringify(plaidAccounts)));
                console.log("after  updateAllBalances -- plaidAccounts DynamoDB -- balanceDate: " + getActiveBalanceDate(plaidAccounts));

                plaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
                // console.log("after updateAllBalances -- plaidAccounts global: ", JSON.parse(JSON.stringify(plaidAccounts)));
                console.log("after  updateAllBalances -- plaidAccounts global   -- balanceDate: " + getActiveBalanceDate(plaidAccounts));
                //

          //
                // getAccounts  (this is working correctly)
                console.log("\ngetAccounts");
                accounts = await this.dataModelService.plaidManagement.getAccounts(wizeFiID,title);
                console.log('getAccounts -- accounts: ', accounts);
                //

          //  (active test)
          // getTransactions  (this is working correctly)
            console.log('\ngetTransactions');
            plaidAccounts = await this.dataModelService.plaidManagement.fetchPlaidAccounts(wizeFiID,title);
            console.log('before getTransactions -- plaidAccounts: ', plaidAccounts);
            activeWizeFiPlaidAccountIds = this.dataModelService.plaidManagement.setActiveWizeFiPlaidAccountIds(plaidAccounts);
            console.log('before getTransactions -- activeWizeFiPlaidAccountIds: ', activeWizeFiPlaidAccountIds);
            const dateRange = {wantMonthRange: true, yearMonth: '2021-08'};
            const transactions = await this.dataModelService.plaidManagement.getTransactions(wizeFiID, title, dateRange, activeWizeFiPlaidAccountIds);
            console.log('after getTransactions -- transactions: ', transactions);
            //

          //
                // deletePlaidInstitution (also handle side effects of the deletion -- e.g. delete accounts in accounts array)
                console.log("\ndeletePlaidInstitution");

                // add new institution to be deleted later
                console.log("===========  add new institution to be deleted later");
                await this.dataModelService.plaidManagement.loadPlaidLink(title);
                plaidInstitutions = await this.dataModelService.plaidManagement.getPlaidInstitutions(wizeFiID,title);
                const institution = plaidInstitutions[plaidInstitutions.length - 1];
                item_id = institution.item_id;  // get item_id of last added institution
                console.log("item_id: " + item_id + "  " + institution.institutionName);

                // perform deletion
                console.log("===========  perform deletePlaidInstitution");

                console.log("before deletePlaidInstitution -- wizeFiAccountsCount", getWizeFiAccountsCount());

                plaidInstitutions = await this.dataModelService.plaidManagement.getPlaidInstitutions(wizeFiID,title);
                console.log("before deletePlaidInstitution -- DynamoDB DraftPlaidInstitutions: ", JSON.parse(JSON.stringify(plaidInstitutions)));

                plaidAccounts = await this.dataModelService.plaidManagement.fetchPlaidAccounts(wizeFiID,title);
                console.log("before deletePlaidInstitution -- DynamoDB DraftPlaidAccounts: ", JSON.parse(JSON.stringify(plaidAccounts)));

                plaidInstitutions = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions;
                console.log("before deletePlaidInstitution -- global plaidInstitutions: ", JSON.parse(JSON.stringify(plaidInstitutions)));

                plaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
                console.log("before deletePlaidInstitution -- global plaidAccounts: ", JSON.parse(JSON.stringify(plaidAccounts)));


                itemIdCount = await this.dataModelService.dataManagement.getItemIdCount(wizeFiID,item_id);
                result = await this.dataModelService.plaidManagement.deletePlaidInstitution(wizeFiID,title,item_id,itemIdCount);
                console.log("after deletePlaidInstitution -- result: ", result);
                console.log("after deletePlaidInstitution -- number of WizeFi accounts to delete: " + result.deleteWizeFiCategoryList.length);


                console.log("after deletePlaidInstitution -- wizeFiAccountsCount", getWizeFiAccountsCount());

                plaidInstitutions = await this.dataModelService.plaidManagement.getPlaidInstitutions(wizeFiID,title);
                console.log("after deletePlaidInstitution -- DynamoDB DraftPlaidInstitutions: ", JSON.parse(JSON.stringify(plaidInstitutions)));

                plaidAccounts = await this.dataModelService.plaidManagement.fetchPlaidAccounts(wizeFiID,title);
                console.log("after deletePlaidInstitution -- DynamoDB DraftPlaidAccounts: ", JSON.parse(JSON.stringify(plaidAccounts)));

                plaidInstitutions = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions;
                console.log("after deletePlaidInstitution -- global plaidInstitutions: ", JSON.parse(JSON.stringify(plaidInstitutions)));

                plaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
                console.log("after deletePlaidInstitution -- global plaidAccounts: ", JSON.parse(JSON.stringify(plaidAccounts)));
                //

        }  // for title
        //
   */ //  (block out code)
    }; // testPlaid3

    /*
      testPlaid3()
        .catch((err) => {
            console.log('error in testPlaid3: ', err)
        })
        .finally(() => {
            console.log('end testPlaid3 tests')
        });
      */

    const testPlaid4 = async () =>
      /*
        The tests in this function are utilized to verify that deletePlaidInstitution is working correctly (including side effects of deleting linked accounts, and updating transaction wizeFiCategory values).
        */
      {
        // initialize
        const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;

        let result,
          title,
          draftsInfo,
          plaidAccounts,
          plaidInstitutions,
          institution,
          accounts,
          activeWizeFiPlaidAccountIds,
          country_codes,
          access_token,
          item_id,
          itemIdCount;
        let monthDate, transactions, wizeFiPlaidAccount, accountLinkInfo;

        console.log('start testPlaid4');

        // utility functions
        const getWizeFiAccountsCount = () => {
          // initialize
          const curplan = this.dataModelService.dataModel.persistent.header.curplan;
          const plans = this.dataModelService.dataModel.persistent.plans;
          let wizeFiAccountsCount = 0;

          // scan through all wizeFi user accounts
          for (const category of Object.keys(plans[curplan])) {
            if (categoryExcludeList.indexOf(category) === -1) {
              for (const subcategory of Object.keys(plans[curplan][category])) {
                if (subcategoryExcludeList.indexOf(subcategory) === -1) {
                  wizeFiAccountsCount += plans[curplan][category][subcategory].accounts.length;
                } // if include subcategory
              } // for subcategory
            } // if include category
          } // for category

          return wizeFiAccountsCount;
        }; // getWizeFiAccountsCount

        // tests to run

        // Add "Navy Federal Credit Union" institution to user data (not draft data)
        /* test1
            title = "";

            console.log('\nAdd "Navy Federal Credit Union" institution to user data');
            console.log("===========  get proper data in dataModel");
            await this.dataModelService.dataManagement.fetchdata();  // put user data (not draft) into dataModel
            console.log("curplan: " + this.dataModelService.dataModel.persistent.header.curplan);

            console.log("===========  execute loadPlaidLink");
            plaidInstitutions = await this.dataModelService.plaidManagement.getPlaidInstitutions(wizeFiID,title);
            console.log("before loadPlaidLink -- DynamoDB plaidInstitutions count: ", plaidInstitutions.length);

            await this.dataModelService.plaidManagement.loadPlaidLink("");
            plaidInstitutions = await this.dataModelService.plaidManagement.getPlaidInstitutions(wizeFiID,title);
            institution = plaidInstitutions[plaidInstitutions.length - 1];
            item_id = institution.item_id;  // get item_id of last added institution
            console.log("after  loadPlaidLink -- item_id: " + item_id + "  " + institution.institutionName);

            plaidInstitutions = await this.dataModelService.plaidManagement.getPlaidInstitutions(wizeFiID,title);
            console.log("after  loadPlaidLink -- DynamoDB plaidInstitutions count: ", plaidInstitutions.length);

            plaidAccounts = await this.dataModelService.plaidManagement.fetchPlaidAccounts(wizeFiID,title);
            console.log("before activating accounts -- DynamoDB plaidAccounts:     ", plaidAccounts);

            console.log("copy plaidAccounts index for 'Navy Federal Credit Union' for 'Plaid Savings' and 'Plaid Credit Card' into test 2")
            console.log("copy item_id value from above into test5 that does deletePlaidInstitution");
            */

        // Activate two of the new wizeFiPlaidAccount entries in the wizeFiPlaidAccounts data
        /* test2
            title = "";

            // get index values (e.g. 53 and 55) from output in test1

            console.log("before add two accounts -- wizeFiAccountsCount", getWizeFiAccountsCount());

            wizeFiPlaidAccount = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts[53];
            accountLinkInfo =
            {
                wizeFiPlaidAccount: wizeFiPlaidAccount,
                accountStatus: "1",
                accountIsActive: true,
                category: "assets",
                subcategory: "cashReserves",
                accountName: wizeFiPlaidAccount.accountName,
                wizeFiCategory: "none"
            };
            await this.dataModelService.categoryManagement.processPlaidAccountLink(accountLinkInfo);

            wizeFiPlaidAccount = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts[55];
            accountLinkInfo =
            {
                wizeFiPlaidAccount: wizeFiPlaidAccount,
                accountStatus: "1",
                accountIsActive: true,
                category: "liabilities",
                subcategory: "creditCard",
                accountName: wizeFiPlaidAccount.accountName,
                wizeFiCategory: "none"
            };
            await this.dataModelService.categoryManagement.processPlaidAccountLink(accountLinkInfo);

            console.log("after add two accounts -- wizeFiAccountsCount", getWizeFiAccountsCount());

            plaidAccounts = await this.dataModelService.plaidManagement.fetchPlaidAccounts(wizeFiID,title);
            console.log("after add two accounts -- DynamoDB plaidAccounts: ", plaidAccounts);

            console.log("get the two new wizeFiCategory values from the plaidAccounts data above, and put them in test3");
            */

        // Assign each of the wizeFiCategory values from test2 above to a distinct transaction
        /* test3
            monthDate = "2020-11";
            transactions = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection[monthDate];
            console.log("before assigning wizeFiCategory -- transactions: ", JSON.parse(JSON.stringify(transactions)));

            transactions[3].wizeFiCategory = "assets_cashReserves_ID001";
            transactions[6].wizeFiCategory = "assets_cashReserves_ID001";
            transactions[9].wizeFiCategory = "liabilities_creditCard_ID001";
            await this.dataModelService.dataManagement.putWizeFiTransactions(wizeFiID, monthDate, transactions);

            transactions = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection[monthDate];
            console.log("after assigning wizeFiCategory -- transactions: ", JSON.parse(JSON.stringify(transactions)));
            */

        // Create a draft with title "Test Deletion"
        /* test4
            console.log("create draft");
            console.log("===========  get proper data in dataModel");
            await this.dataModelService.dataManagement.fetchdata();  // put user data (not draft) into dataModel

            console.log("===========  execute createDraft");

            draftsInfo = await this.dataModelService.dataManagement.fetchDraftsInfo();
            console.log("before createDraft -- draftsInfo: ", JSON.parse(JSON.stringify(draftsInfo)));

            await this.dataModelService.dataManagement.createDraft("Test Deletion", "Test handling of deletion of institution","whatif");

            draftsInfo = await this.dataModelService.dataManagement.fetchDraftsInfo();
            console.log("after createDraft -- draftsInfo: ", JSON.parse(JSON.stringify(draftsInfo)));
            */

        // test the results of deletePlaidInstitution (for both user data and draft data)
        /* test5
            const titles = ["","Test Deletion"];
            for (const title of titles)
            {
                console.log("========================  title: '" + title + "'");

                // deletePlaidInstitution (also handle side effects of the deletion -- e.g. delete accounts in accounts array, and update transaction wizeFiCategory)
                console.log("\ndeletePlaidInstitution");

                console.log("===========  get proper data in dataModel");
                if (title == "") await this.dataModelService.dataManagement.fetchdata();     // put user data (not draft) into dataModel
                else await this.dataModelService.dataManagement.fetchDraft(title);  // put draft into dataModel

                console.log("curplan: " + this.dataModelService.dataModel.persistent.header.curplan);

                // perform deletion
                console.log("===========  perform deletePlaidInstitution");

                console.log("before deletePlaidInstitution -- wizeFiAccountsCount", getWizeFiAccountsCount());

                plaidInstitutions = await this.dataModelService.plaidManagement.getPlaidInstitutions(wizeFiID,title);
                console.log("before deletePlaidInstitution -- DynamoDB plaidInstitutions count: ", plaidInstitutions.length);

                plaidInstitutions = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions;
                console.log("before deletePlaidInstitution -- global   plaidInstitutions count: ", plaidInstitutions.length);

                plaidAccounts = await this.dataModelService.plaidManagement.fetchPlaidAccounts(wizeFiID,title);
                console.log("before deletePlaidInstitution -- DynamoDB plaidAccounts count:     ", plaidAccounts.length);

                plaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
                console.log("before deletePlaidInstitution -- global   plaidAccounts count:     ", plaidAccounts.length);

                //
                item_id = "4Wg6Je3K4EIgvKm4K3XBIeVbdG9B4RidDWmvx";  // get item_id from test1 above, or from DynamoDB WizeFiPlaidInstitutions for "Navy" account
                itemIdCount = await this.dataModelService.dataManagement.getItemIdCount(wizeFiID,item_id);
                result = await this.dataModelService.plaidManagement.deletePlaidInstitution(wizeFiID,title,item_id,itemIdCount);
                console.log("after  deletePlaidInstitution -- result: ", result);
                console.log("after  deletePlaidInstitution -- wantDeleteToken: " + result.wantDeleteToken);
                console.log("after  deletePlaidInstitution -- number of WizeFi accounts to delete: " + result.deleteWizeFiCategoryList.length);
                //

                console.log("after  deletePlaidInstitution -- wizeFiAccountsCount", getWizeFiAccountsCount());

                plaidInstitutions = await this.dataModelService.plaidManagement.getPlaidInstitutions(wizeFiID,title);
                console.log("after  deletePlaidInstitution -- DynamoDB plaidInstitutions count: ", plaidInstitutions.length);

                plaidInstitutions = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions;
                console.log("after  deletePlaidInstitution -- global   plaidInstitutions count: ", plaidInstitutions.length);

                plaidAccounts = await this.dataModelService.plaidManagement.fetchPlaidAccounts(wizeFiID,title);
                console.log("after  deletePlaidInstitution -- DynamoDB plaidAccounts count:     ", plaidAccounts.length);

                plaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
                console.log("after  deletePlaidInstitution -- global   plaidAccounts count:     ", plaidAccounts.length);

                console.log("===========  restore user data in dataModel");
                if (title !== "") await this.dataModelService.dataManagement.fetchdata();  // put user data (not draft data) into dataModel
            }  // for title
            */

        /* test6
            // delete "Test Deletion" draft
            console.log("\ndeleteDraft");

            draftsInfo = await this.dataModelService.dataManagement.fetchDraftsInfo();
            console.log("before deleteDraft -- draftsInfo: ", JSON.parse(JSON.stringify(draftsInfo)));

            await this.dataModelService.dataManagement.deleteDraft(wizeFiID, "Test Deletion");

            draftsInfo = await this.dataModelService.dataManagement.fetchDraftsInfo();
            console.log("after deleteDraft -- draftsInfo: ", JSON.parse(JSON.stringify(draftsInfo)));
            */
      }; // testPlaid4

    /*
        testPlaid4()
        .catch((err) => {console.log('error in testPlaid4: ', err)})
        .finally(() => {console.log("end testPlaid4 tests")});
        */
  } // runPlaidManagementTests

  public runScreenDataManagementTests() {
    const wizeFiID = '10213363587114053';
    const info = { identity: 'John Doe jdoe@gmail.com', authorizedScreens: ['admin-screen-data'] };
    const secureScreen = 'admin-test-plaid';
    const filter = 'nameLast';
    const filterValue = 'Eland';

    /*  works
        this.dataModelService.screenDataManagement.storeScreenData(wizeFiID,info)
        .then(() => {console.log('storeScreenData completed')})
        .catch((err) => {console.log(err)});
        */

    /*  works
        this.dataModelService.screenDataManagement.fetchScreenData(wizeFiID)
        .then((item) => {console.log('fetchScreenData: ', item)})
        .catch((err) => {console.log(err)});
        */

    //  works
    this.dataModelService.screenDataManagement
      .fetchAllScreenData()
      .then(items => {
        console.log('fetchAllScreenData: ', items);
      })
      .catch(err => {
        console.log(err);
      });
    //

    /*  works
        this.dataModelService.screenDataManagement.hasScreenAccess(wizeFiID,secureScreen)
        .then((result) => {console.log('hasScreenAccess to ' + secureScreen + ': ' + result)})
        .catch((err) => {console.log(err)});
        */

    /*  works
        this.dataModelService.screenDataManagement.deleteScreenData(wizeFiID)
        .then(() => {console.log('deleteScreenData completed')})
        .catch((err) => {console.log(err)});
        */

    /*  works
        this.dataModelService.screenDataManagement.fetchScreenInterfaceData()
        .then((data) => {console.log('fetchScreenInterfaceData: ', data)})
        .catch((err) => {console.log(err)});
        */

    /*  works
        this.dataModelService.screenDataManagement.fetchUserIdentity(filter,filterValue)
        .then((users) => {console.log('fetchUserIdentity: ', users)})
        .catch((err) => {console.log(err)});
        */

    /*  works
        this.dataModelService.screenDataManagement.storeScreenData(wizeFiID,info)
        .then(() => {console.log('storeScreenData -- item created for ' + wizeFiID)})
        .then(() => {return this.dataModelService.screenDataManagement.fetchScreenData(wizeFiID)})
        .then((item) => {console.log('fetchScreenData: ', item)})
        .then(() => {return this.dataModelService.screenDataManagement.hasScreenAccess(wizeFiID,secureScreen)})
        .then((result) => {console.log('hasScreenAccess: ', result)})
        .then(() => {return this.dataModelService.screenDataManagement.deleteScreenData(wizeFiID)})
        .then(() => {console.log('deleteScreenData -- item deleted for ' + wizeFiID)})
        .then(() => {return this.dataModelService.screenDataManagement.fetchAllScreenData()})
        .then((items) => {console.log('fetchAllScreenData: ', items)})
        .catch((err) => {console.log(err)});
        */
  } // runScreenDataManagementTests

  public runCategoryManagementTests() {
    let category, subcategory, accountName, curplan, accounts, acntndx, accountID, wizeFiCategory, wizeFiCategoryName, wizeFiCategoryIndex;
    let oldAccount, newAccount;
    let transActionList, oldTransactionList, newTransactionList;

    //
    category = 'budget';
    subcategory = 'housing';
    accountID = 'ID005';
    accountName = 'Water';
    acntndx = 4;
    curplan = this.dataModelService.dataModel.persistent.header.curplan;
    accounts = this.dataModelService.dataModel.persistent.plans[curplan][category][subcategory].accounts;

    console.log('category: ' + category);
    console.log('subcategory: ' + subcategory);
    console.log('accountID: ' + accountID);
    console.log('accountName: ' + accountName);
    console.log('acntndx: ' + acntndx);
    console.log('curplan: ' + curplan + '  accounts: ', accounts); // %//

    console.log('accountID2number("ID005"): ' + this.dataModelService.categoryManagement.accountID2number('ID005'));
    console.log('getNewAccountID(accounts): ' + this.dataModelService.categoryManagement.getNewAccountID(accounts));

    console.log(' ');
    console.log('accountID2accountName(accountID,accounts)): ' + this.dataModelService.categoryManagement.accountID2accountName(accountID, accounts));
    console.log('accountID2acntndx(accountName,accounts): ' + this.dataModelService.categoryManagement.accountID2acntndx(accountID, accounts));
    console.log(' ');
    console.log(
      'accountName2accountID(accountName,accounts): ' + this.dataModelService.categoryManagement.accountName2accountID(accountName, accounts)
    );
    console.log('accountName2acntndx(accountName,accounts): ' + this.dataModelService.categoryManagement.accountName2acntndx(accountName, accounts));
    console.log(' ');
    console.log('acntndx2accountID(acntndx,accounts): ' + this.dataModelService.categoryManagement.acntndx2accountID(acntndx, accounts));
    console.log('acntndx2accountName(acntndx,accounts): ' + this.dataModelService.categoryManagement.acntndx2accountName(acntndx, accounts));

    console.log(' ');
    wizeFiCategory = this.dataModelService.categoryManagement.makeWizeFiCategory(category, subcategory, accountID);
    console.log('makeWizeFiCategory(category,subcategory,accountID): ' + wizeFiCategory);

    wizeFiCategoryName = this.dataModelService.categoryManagement.makeWizeFiCategory(category, subcategory, accountName);
    console.log('makeWizeFiCategory(category,subcategory,accountName): ' + wizeFiCategoryName);

    wizeFiCategoryIndex = this.dataModelService.categoryManagement.makeWizeFiCategory(category, subcategory, acntndx);
    console.log('makeWizeFiCategory(category,subcategory,acntndx): ' + wizeFiCategoryIndex);

    console.log(' ');
    console.log(
      "reformatWizeFiCategory('accountID',wizeFiCategory): " +
        this.dataModelService.categoryManagement.reformatWizeFiCategory('accountID', wizeFiCategory)
    );

    console.log(
      "reformatWizeFiCategory('accountName',wizeFiCategory): " +
        this.dataModelService.categoryManagement.reformatWizeFiCategory('accountName', wizeFiCategory)
    );

    console.log(
      "reformatWizeFiCategory('acntndx',wizeFiCategory): " +
        this.dataModelService.categoryManagement.reformatWizeFiCategory('acntndx', wizeFiCategory)
    );

    console.log(' ');
    console.log('decodeWizeFiCategory(wizeFiCategory): ', this.dataModelService.categoryManagement.decodeWizeFiCategory(wizeFiCategory));
    console.log('decodeWizeFiCategory(wizeFiCategoryName): ', this.dataModelService.categoryManagement.decodeWizeFiCategory(wizeFiCategoryName));
    console.log('decodeWizeFiCategory(wizeFiCategoryIndex): ', this.dataModelService.categoryManagement.decodeWizeFiCategory(wizeFiCategoryIndex));
    console.log('decodeWizeFiCategory("unknown"): ', this.dataModelService.categoryManagement.decodeWizeFiCategory('unknown'));
    console.log('decodeWizeFiCategory("abc"): ', this.dataModelService.categoryManagement.decodeWizeFiCategory('abc'));
    console.log('decodeWizeFiCategory("abc_def"): ', this.dataModelService.categoryManagement.decodeWizeFiCategory('abc_def'));

    // makeAttributePatternString
    console.log(' ');
    const attributePattern = {
      merchantName: 'Charitable Food Services',
      accountName: 'John Checking'
    };
    console.log('attributePattern: ', attributePattern);
    console.log(
      'makeAttributePatternString(attributePattern): ' + this.dataModelService.categoryManagement.makeAttributePatternString(attributePattern)
    );

    console.log(' ');
    console.log('getWizeFiCategoryList(): ', this.dataModelService.categoryManagement.getWizeFiCategoryList());

    // TODO come up with tests that lead to error (e.g. wizeFiCategory does not have correct format, accountName or accountID is not in accounts, etc.)
    //

    ///////////////////////////////////////
    // new functions
    ///////////////////////////////////////

    console.log('getGenericCategory2wizeFiCategory(): ', this.dataModelService.categoryManagement.getGenericCategory2wizeFiCategory());
    console.log(
      'getWizeFiCategoryFromGenericCategory("groceries"): ',
      this.dataModelService.categoryManagement.getWizeFiCategoryFromGenericCategory('groceries')
    );

    /*
        // assignWizeFiCategoryFromGenericCategory
        console.log(" ");
        transActionList = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection["2020-03"];
        oldTransactionList = JSON.parse(JSON.stringify(transActionList));  // clone the data to view unchanged version
        console.log("oldTransactionList: ", oldTransactionList);

        console.log('assignWizeFiCategoryFromGenericCategory("2020-03")');
        this.dataModelService.categoryManagement.assignWizeFiCategoryFromGenericCategory("2020-03");

        newTransactionList = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection["2020-03"];
        console.log("newTransactionList: ", newTransactionList);

        // makeAttributePattern
        console.log(" ");
        const attributePatternInfo = {
            attributePattern:
            {
              merchantName: "Charitable Food Services",
              accountName: "John Checking"
            },
            wizeFiCategory: "budget_giving_tithe"
        }
        console.log("attributePatternInfo: ", attributePatternInfo);
        console.log("makeAttributePatternString(attributePatternInfo): ", this.dataModelService.categoryManagement.makeAttributePatternString(attributePatternInfo));
        */

    // assignGenericCategoryToWizeFiAccount
    console.log(' ');
    curplan = this.dataModelService.dataModel.persistent.header.curplan;
    const genericCategory = 'rent';
    wizeFiCategory = 'budget_housing_ID001';
    const info = this.dataModelService.categoryManagement.decodeWizeFiCategory(wizeFiCategory);
    const account = this.dataModelService.dataModel.persistent.plans[curplan][info.category][info.subcategory].accounts[info.acntndx];
    oldAccount = JSON.parse(JSON.stringify(account)); // clone to preserve old value
    console.log('oldAccount: ', oldAccount);

    console.log('assignGenericCategoryToWizeFiAccount("rent","budget_housing_ID001")');
    this.dataModelService.categoryManagement.assignGenericCategoryToWizeFiAccount(genericCategory, wizeFiCategory);

    newAccount = this.dataModelService.dataModel.persistent.plans[curplan][info.category][info.subcategory].accounts[info.acntndx];
    console.log('newAccount: ', newAccount);

    // assignGenericCategoryToWizeFiAccount (check for errors)
    console.log(' ');
    console.log('assignGenericCategoryToWizeFiAccount("none","income_income_Salary")');
    this.dataModelService.categoryManagement.assignGenericCategoryToWizeFiAccount('none', 'income_income_Salary');

    console.log('assignGenericCategoryToWizeFiAccount("groceries","budget_food_abcd")');
    this.dataModelService.categoryManagement.assignGenericCategoryToWizeFiAccount('groceries', 'budget_food_abcd');

    // getWizeFiCategoryParts(wizeFiCategory)
    console.log(' ');
    console.log('getWizeFiCategoryParts("unknown"): ', this.dataModelService.categoryManagement.getWizeFiCategoryParts('unknown'));
    console.log(
      'getWizeFiCategoryParts("budget_food_ID001"): ',
      this.dataModelService.categoryManagement.getWizeFiCategoryParts('budget_food_ID001')
    );

    // isValidWizeFiCategory
    console.log(' ');
    console.log('isValidWizeFiCategory("unknown"): ' + this.dataModelService.categoryManagement.isValidWizeFiCategory('unknown'));
    console.log('isValidWizeFiCategory("none"): ' + this.dataModelService.categoryManagement.isValidWizeFiCategory('none'));
    console.log('isValidWizeFiCategory("budget_food_ID002"): ' + this.dataModelService.categoryManagement.isValidWizeFiCategory('budget_food_ID002'));
    console.log(
      'isValidWizeFiCategory("budget_food_Dining Out"): ' + this.dataModelService.categoryManagement.isValidWizeFiCategory('budget_food_Dining Out')
    );
    console.log('isValidWizeFiCategory("budget_food_1"): ' + this.dataModelService.categoryManagement.isValidWizeFiCategory('budget_food_1'));
    console.log(' ');
    console.log('isValidWizeFiCategory("george"): ' + this.dataModelService.categoryManagement.isValidWizeFiCategory('george'));
    console.log('isValidWizeFiCategory("sam_food_ID002"): ' + this.dataModelService.categoryManagement.isValidWizeFiCategory('sam_food_ID002'));
    console.log('isValidWizeFiCategory("budget_joe_ID002"): ' + this.dataModelService.categoryManagement.isValidWizeFiCategory('budget_joe_ID002'));
    console.log('isValidWizeFiCategory("budget_food_ID099"): ' + this.dataModelService.categoryManagement.isValidWizeFiCategory('budget_food_ID099'));
    console.log('isValidWizeFiCategory("budget_food_what"): ' + this.dataModelService.categoryManagement.isValidWizeFiCategory('budget_food_what'));
    console.log(
      'isValidWizeFiCategory("budget_food_Dining Outx"): ' + this.dataModelService.categoryManagement.isValidWizeFiCategory('budget_food_Dining Outx')
    );
    console.log('isValidWizeFiCategory("budget_food_98"): ' + this.dataModelService.categoryManagement.isValidWizeFiCategory('budget_food_98'));

    // isValidWizeFiCategoryAccount
    console.log(' ');
    console.log(
      'isValidWizeFiCategoryAccount("budget_food_ID002"): ' +
        this.dataModelService.categoryManagement.isValidWizeFiCategoryAccount('budget_food_ID002')
    );
    console.log(
      'isValidWizeFiCategoryAccount("budget_food_Dining Out"): ' +
        this.dataModelService.categoryManagement.isValidWizeFiCategoryAccount('budget_food_Dining Out')
    );
    console.log(
      'isValidWizeFiCategoryAccount("budget_food_1"): ' + this.dataModelService.categoryManagement.isValidWizeFiCategoryAccount('budget_food_1')
    );
    console.log('isValidWizeFiCategoryAccount("unknown"): ' + this.dataModelService.categoryManagement.isValidWizeFiCategoryAccount('unknown'));
    console.log('isValidWizeFiCategoryAccount("none"): ' + this.dataModelService.categoryManagement.isValidWizeFiCategoryAccount('none'));

    /* the following get error: this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection[monthDate] is not iterable
        // getSelectedTransactionList(wizeFiCategory,monthDate)
        console.log(" ");
        console.log('getSelectedTransactionList("budget_food_ID001","2020-08"): ', this.dataModelService.categoryManagement.getSelectedTransactionList("budget_food_ID001","2020-08"));

        console.log('getSelectedTransactionList("budget_food_ID099","2020-08"): ', this.dataModelService.categoryManagement.getSelectedTransactionList("budget_food_ID099","2020-08"));

        // error: transactions is not iterable
        // getWizeFiCategory2amount(monthDate)
        console.log(" ");
        console.log('getWizeFiCategory2amount("2020-08"): ', this.dataModelService.categoryManagement.getWizeFiCategory2amount("2019-08"));

        /*
        // assignActualMonthlyAmount(monthDate)
        console.log(' ');

        accounts = this.dataModelService.dataModel.persistent.plans[curplan]['budget']['housing'].accounts;
        oldAccountList = JSON.parse(JSON.stringify(accounts));  // clone to preserve old value
        console.log('oldAccountList: ', oldAccountList);
        console.log('assignActualMonthlyAmount("2019-08")');
        this.dataModelService.categoryManagement.assignActualMonthlyAmount("2020-08");
        newAccountList = this.dataModelService.dataModel.persistent.plans[curplan]['budget']['housing'].accounts;
        console.log('newAccountList: ', newAccountList);

        // repairTransactionWizeFiCategoryValues
        console.log(' ');
        console.log('repairTransactionWizeFiCategoryValues("2020-04")');
        this.dataModelService.categoryManagement.repairTransactionWizeFiCategoryValues("2020-04");
        */

    // checkForPatternMatch(transaction,wizeFiTransactionAttributePattern)
    console.log(' ');
    const wizeFiTransactionAttributePattern = {
      wizeFiCategory: 'income_income_ID002',
      attributePattern: {
        merchantName: 'SOC SEC',
        institutionName: 'SELCO'
      }
    };
    const transaction = {
      merchantName: 'XXSOC SEC ID: 9031736013 CO: XXSOC SEC',
      institutionName: 'SELCO Community Credit Union'
    };

    console.log(
      'checkForPatternMatch(transaction,wizeFiTransactionAttributePattern): ' +
        this.dataModelService.categoryManagement.checkForPatternMatch(transaction, wizeFiTransactionAttributePattern)
    );

    // assignWizeFiCategoryFromTransactionPattern
    console.log(' ');
    transActionList = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection['2020-08'];
    oldTransactionList = JSON.parse(JSON.stringify(transActionList)); // clone the data to view unchanged version
    console.log('oldTransactionList: ', oldTransactionList);

    console.log('assignWizeFiCategoryFromTransactionPattern("2020-04")');
    this.dataModelService.categoryManagement.assignWizeFiCategoryFromTransactionPattern('2020-04');

    newTransactionList = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection['2020-08'];
    console.log('newTransactionList: ', newTransactionList);

    // getPlaidGenericCategory
    console.log(' ');
    console.log(
      'getPlaidGenericCategory("Shops_Supermarkets and Groceries"): ' +
        this.dataModelService.categoryManagement.getPlaidGenericCategory('Shops_Supermarkets and Groceries')
    );

    console.log(
      'getPlaidGenericCategory("Shops_Furniture and Home Decor"): ' +
        this.dataModelService.categoryManagement.getPlaidGenericCategory('Shops_Furniture and Home Decor')
    );

    console.log('getPlaidGenericCategory("abcdxyz"): ' + this.dataModelService.categoryManagement.getPlaidGenericCategory('abcdxyz'));

    // getWizeFiGenericCategory
    console.log(' ');
    console.log(
      'getWizeFiGenericCategory("budget_food_Dining Out"): ' +
        this.dataModelService.categoryManagement.getWizeFiGenericCategory('budget_food_Dining Out')
    );
    console.log(
      'getWizeFiGenericCategory("income_income_Salary"): ' + this.dataModelService.categoryManagement.getWizeFiGenericCategory('income_income_Salary')
    );
    console.log('getWizeFiGenericCategory("unknown"): ' + this.dataModelService.categoryManagement.getWizeFiGenericCategory('unknown'));
    console.log('getWizeFiGenericCategory("abcd"): ' + this.dataModelService.categoryManagement.getWizeFiGenericCategory('abcd'));

    // getAccount_id2wizeFiCategory
    console.log(' ');
    console.log('getAccount_id2wizeFiCategory(): ', this.dataModelService.categoryManagement.getAccount_id2wizeFiCategory());

    // getWizeFiPlaidAccountAccount_id2wizeFiCategory
    console.log(
      'getWizeFiPlaidAccountAccount_id2wizeFiCategory(): ',
      this.dataModelService.categoryManagement.getWizeFiPlaidAccountAccount_id2wizeFiCategory()
    );

    // getPlaid_account_id2wizeFiPlaidAccount
    console.log(' ');
    console.log('getPlaid_account_id2wizeFiPlaidAccount(): ', this.dataModelService.categoryManagement.getPlaid_account_id2wizeFiPlaidAccount());

    // getWizeFiCategory2wizeFiPlaidAccount
    console.log(' ');
    console.log('getWizeFiCategory2wizeFiPlaidAccount(): ', this.dataModelService.categoryManagement.getWizeFiCategory2wizeFiPlaidAccount());

    /* error wizeFiTransactions is not iterable
        // getWizeFiCategory2wizeFiTransactionList
        console.log(" ");
        console.log('getWizeFiCategory2wizeFiTransactionList("2019-08"): ', this.dataModelService.categoryManagement.getWizeFiCategory2wizeFiTransactionList("2019-08"));
        */

    /*
        // getSchemaData
        console.log(" ");
        console.log("getSchemaData(): ", this.dataModelService.categoryManagement.getSchemaData());

        const schemaData = this.dataModelService.categoryManagement.getSchemaData();
        const dropList = [];
        for (category of Object.keys(schemaData)) {
            for (subcategory of schemaData[category]) {
                dropList.push(category + "_" + subcategory);
            }
        }
        console.log("list of categories and subcategories in schemaData: ", dropList);

        // getWizeFiAccountSchema
        console.log(" ");
        console.log('getWizeFiAccountSchema("assets","emergencySavings"): ', this.dataModelService.categoryManagement.getWizeFiAccountSchema("assets", "emergencySavings"));

        // getWizeFiCategory2wizeFiAccount()
        console.log(" ");
        console.log("getWizeFiCategory2wizeFiAccount(): ", this.dataModelService.categoryManagement.getWizeFiCategory2wizeFiAccount(curplan));

        // obtainPlanDatesInfo
        console.log(" ");
        console.log("obtainPlanDatesInfo(): ", this.dataModelService.categoryManagement.obtainPlanDatesInfo());

        // obtainTransactionDatesInfo
        console.log(" ");
        this.dataModelService.categoryManagement.obtainTransactionDatesInfo()
        .then((transactionDatesInfo) => {console.log("obtainTransactionDatesInfo(): ", transactionDatesInfo)})
        .catch((err) => {console.log("error in obtainTransactionDatesInfo: ", err)});

        // getWizeFiAccountSchema
        console.log(" ");
        console.log('getWizeFiAccountSchema("assets","emergencySavings"): ', this.dataModelService.categoryManagement.getWizeFiAccountSchema("assets", "emergencySavings"));

        // getWizeFiAccountFromWizeFiCategory(wizeFiCategory)
        console.log(" ");
        console.log('getWizeFiAccountFromWizeFiCategory("budget_food_ID001"): ', this.dataModelService.categoryManagement.getWizeFiAccountFromWizeFiCategory("budget_food_ID001"));
        console.log('getWizeFiAccountFromWizeFiCategory("budget_food_ID009"): ', this.dataModelService.categoryManagement.getWizeFiAccountFromWizeFiCategory("budget_food_ID009"));
        */

    //
    console.log(' ');
    console.log('obtain previous plan actualMonthlyAmount');

    const testPrevData = async () => {
      // set a wizeFiCategory for test purposes
      wizeFiCategory = 'budget_entertainment_ID003';

      // set plan relative to which previous plan to work with will be located
      const plan = this.dataModelService.dataModel.persistent.header.curplan;

      // optain information about memory resident and persistent plans
      const planDatesInfo = this.dataModelService.categoryManagement.obtainPlanDatesInfo();

      // determine previousPlan (formatted as "original" or "pYYYYMM")
      const previousPlan = this.dataModelService.categoryManagement.getPreviousPlan(plan, planDatesInfo);

      // load the previousPlan into memory if necessary
      await this.dataModelService.categoryManagement.loadPreviousPlan(previousPlan, planDatesInfo);

      // construct wizeFiCategory2actualMonthlyAmount object that maps a wizeFiCategory to the actualMonthlyAmount for that wizeFiCategory
      const wizeFiCategory2actualMonthlyAmount = this.dataModelService.categoryManagement.getWizeFiCategory2actualMonthlyAmount(previousPlan);

      // retrieve the desired actualMonthlyAmount
      const actualMonthlyAmount = this.dataModelService.categoryManagement.getActualMonthlyAccount(
        wizeFiCategory2actualMonthlyAmount,
        wizeFiCategory
      );

      console.log('planDatesInfo: ', planDatesInfo); //%//
      console.log('wizeFiCategory2actualMonthlyAmount: ', wizeFiCategory2actualMonthlyAmount); //%//
      console.log('previousPlan: ' + previousPlan + '  wizeFiCategory: ' + wizeFiCategory + '  actualMonthlyAmount: ' + actualMonthlyAmount);
    }; // testPrevData

    testPrevData()
      .then(() => {
        console.log('testPrevData finished');
      })
      .catch(err => {
        console.log('testPrevData error:', err);
      });
    //
  } // runCategoryManagementTests

  //
  public runDataManagementTests() {
    /*
        // initialize
        const curplan = this.dataModelService.dataModel.persistent.header.curplan;

        // make some data changes
        // this.dataModelService.dataModel.persistent.plans[curplan][category][subcategory].accounts

        // store the results
        console.log('storedata');
        this.dataModelService.dataManagement.storeinfo();

        // retrieve and compare the results
        // console.log('run data management test');
        */

    //
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    this.dataModelService.dataManagement
      .getWizeFiDataItem(wizeFiID)
      .then(wizeFiDataItem => {
        console.log('wizeFiDataItem: ', wizeFiDataItem);
      })
      .catch(err => {
        console.log('error in getWizeFiDataItem: ', err);
      });
    //
  } // runDataManagementTests
  //

  public runBraintreeManagementTests(dataModelService: any) {
    // COMBINED

    /*
        let subscriptionInfo: any;  // workaround to deal with type compatability issues in Typescript
        subscriptionInfo =
        {
            wizeFiID: '34567',
            firstName: 'Joe',
            lastName: 'Testing2',
            email: 'joe@abc.com',
            // paymentMethodNonce: 'fake-valid-nonce',
            noncePayload:
            {
                nonce: 'fake-valid-nonce',
                type: 'CreditCard',         // payment type (e.g. CreditCard, Paypal)
                details:
                {
                    lastTwo: 81,  // last two digits of credit card number
                    type: 'Visa'      // credit card type (e.g. Visa, Master Card)
                }
            },
            planId: 'WizeFiPlan'
        }

        let wantTrialPeriod = true;
        if (wantTrialPeriod)
        {
            subscriptionInfo.trialPeriod = true;
            subscriptionInfo.trialDuration = 30;
            subscriptionInfo.trialDurationUnit = 'day';
        }

        dataModelService.braintreeManagement.establishSubscription(subscriptionInfo)
        .then((result) => {console.log('establishSubscription: ', result)})
        .catch((err) => {console.log(err)});
        */

    /*
        // let customerID = '10213363587114053';   // Dave (in dev environment)  outdated
        // let customerID = '10100417492544182';   // Greg (in dev environment)  outdated
        // let customerID = '10100507229934732';   // Greg (in prod environment)
        // let customerID = '10154672182542352';   // Jeff (in dev environment)
        // let customerID = '10154672182542352';   // Jeff (in prod environment)
        let customerID = '941047192701414';     // Tom Allen (in prod environment)
        // let customerID = 'snikevj';   // Sean (in dev environment -- Tony Tiger)
        // let customerID = '10212381931995957';   // Brandon Wells (in prod environment)
        // customerID = '10213673851776332';   // Kristin Conroy McKay (customer with more than one subscription in prod environment)

        dataModelService.braintreeManagement.getBraintreeData(customerID)
        .then((braintreeData) => {console.log('braintreeData: ', braintreeData)})
        .catch((err) => {console.log(err)});
        */

    /*
        // let customerID = '10154672182542352';  let monthDate = '2018-04';  let dayDate = '2017-09-22';  // Jeff (in prod environment)  result: correct
        let customerID = '10154672182542352';  let monthDate = '2018-04';  let dayDate = '2017-09-25';  // Jeff (in prod environment)  result: correct
        // let customerID = '10212381931995957';  let monthDate = '2018-04';   // user on prod with $48 in one month    result: correct
        // let customerID = '10212381931995957';  let monthDate = '2018-03';   // user on prod with $0 in one month     result: correct
        // let customerID = '10212381931995957';  let monthDate = '2017-10';   // user on prod with $8 in one month     result: correct
        // let customerID = '1021238193199595z';  let monthDate = '2018-04';   // invalid customerID (should return 0)  result: correct
        // let customerID = '146441322626155';    let monthDate = '2017-09';   // Sean on prod with subscription refund (sum should be 0)   result: correct

        dataModelService.braintreeManagement.getUserMonthPayments(customerID,monthDate)
        .then((userMonthPayments) => {console.log('userMonthPayments (' + monthDate + '): ' + userMonthPayments)})
        .catch((err) => {console.log(err)});

        dataModelService.braintreeManagement.getUserDayPayments(customerID,dayDate)
        .then((userDayPayments) => {console.log('userDayPayments (' + dayDate + '): ' + userDayPayments)})
        .catch((err) => {console.log(err)});
        */

    /*
        // dataModelService.braintreeManagement.generateClientToken(customerID)
        dataModelService.braintreeManagement.generateClientToken()
        .then((result) => {console.log('generateClientToken: ', result)})
        .catch((err) => {console.log('generateClientToken: ', err)});
        */

    /*
        dataModelService.braintreeManagement.listCustomerPaymentMethods(customerID)
        .then((result) => {console.log('listCustomerPaymentMethods: ', result)})
        .catch((err) => {console.log('listCustomerPaymentMethods: ', err)});
        */

    /*
        let wantAll = true;
        dataModelService.braintreeManagement.listCustomerSubscriptions(customerID,wantAll)
        .then((result) => {console.log('listCustomerSubscriptions: ', result)})
        .catch((err) => {console.log('listCustomerSubscriptions: ', err)});
        */

    /*
        dataModelService.braintreeManagement.hasActiveSubscription(customerID)
        .then((result) => {console.log('hasActiveSubscription: ', result)})
        .catch((err) => {console.log('hasActiveSubscription: ', err)});
        */

    /*
        // establish Braintree customer with a subscription
        let findCustomer = () =>
        {
            return dataModelService.braintreeManagement.findCustomer(chainParms.customerID);
        }   // findCustomer

        let processFindCustomer = (result) =>
        {
            console.log('findCustomer: ', result);  //%//

            // determine how many payment methods are in place
            let paymentMethodCount = result.paymentMethods.length;

            // determine how many useful subscriptions are in place
            let subscriptionCount = 0;
            for (let i = 0; i < result.paymentMethods.length; i++)
            {
                for (let j = 0; j < result.paymentMethods[i].subscriptions.length; j++)
                {
                    let status = result.paymentMethods[i].subscriptions[j].status;
                    if (status !== 'Canceled' && status !== 'Expired')
                    {
                        subscriptionCount++;
                    }
                }   // for j
            }   // for i

            // set information needed later
            chainParms.needCustomer = false;
            chainParms.needPaymentMethod = (paymentMethodCount === 0);
            chainParms.needSubscription = (subscriptionCount === 0);
            if (paymentMethodCount > 0) chainParms.paymentMethodToken = result.paymentMethods[0].token;

            return Promise.resolve();
        }   // processFindCustomer

        let processFindCustomerError = (err) =>
        {
            console.log('findCustomer: ', err);  //%//

            if (err !== 'notFoundError')
            {
                return Promise.reject(err);
            }
            else
            {
                chainParms.needCustomer = true;
                chainParms.needPaymentMethod = true;
                chainParms.needSubscription = true;
                return Promise.resolve();
            }
        }   // processFindCustomerError

        let createCustomer = () =>
        {
            if (chainParms.needCustomer)
            {
                return dataModelService.braintreeManagement.createCustomer(chainParms.customerParms);
            }
            else
            {
                return Promise.resolve();
            }
        }   // createCustomer

        let processCreateCustomer = (result) =>
        {
            if (result !== null) console.log('createCustomer: ', result);  //%//
            return Promise.resolve();
        }   // processCreateCustomer

        let createPaymentMethod = () =>
        {
            if (chainParms.needPaymentMethod)
            {
                return dataModelService.braintreeManagement.createPaymentMethod(chainParms.paymentMethodParms);
            }
            else
            {
                chainParms.subscriptionParms =
                {
                    planId: 'WizeFiPlan',
                    paymentMethodToken: chainParms.paymentMethodToken
                }
                return Promise.resolve();
            }
        }   // createPaymentMethod

        let processCreatePaymentMethod = (result) =>
        {
            if (result !== null)
            {
                console.log('createPaymentMethod: ', result);  //%//
                chainParms.subscriptionParms =
                {
                    planId: 'WizeFiPlan',
                    paymentMethodToken: result.paymentMethod.token
                }
            }
            return Promise.resolve();
        }   // processCreatePaymentMethod

        let createSubscription = () =>
        {
            if (chainParms.needSubscription)
            {
                return dataModelService.braintreeManagement.createSubscription(chainParms.subscriptionParms);
            }
            else
            {
                return Promise.resolve();
            }
        }   // createSubscription

        let processCreateSubscription = (result) =>
        {
            if (result !== null) console.log('createSubscription: ', result);  //%//
            return Promise.resolve();
        }   // processCreateSubscription

        // initialize
        let customerID = '98765';                     // this.dataModelService.dataModel.global.wizeFiID
        let paymentMethodNonce = 'fake-valid-nonce';  // from subscription screen
        let chainParms:IchainParms =  // chainParms contains values needed in the promise chain processing
        {
            customerID: customerID,
            paymentMethodNonce: paymentMethodNonce,
            paymentMethodToken: 'unknown',
            needCustomer:  false,
            needPaymentMethod:  false,
            needSubscription:  false,
            customerParms:
            {
                id: customerID,       // wizeFiID
                firstName: 'Joe',     // this.dataModelService.dataModel.persistent.profile.nameFirst
                lastName: 'Testing',  // this.dataModelService.dataModel.persistent.profile.nameLast
                email: 'joe@abc.com'  // thithis.dataModelService.dataModel.global.braintreeData.email
            },
            paymentMethodParms:
            {
                customerId: customerID,  // wizeFiID
                paymentMethodNonce: paymentMethodNonce
            },
            subscriptionParms:
            {
                planId: 'WizeFiPlan',
                paymentMethodToken: 'unknown'    // to be determined later
            }
        }

        // find customer, create customer (if necessary), create payment method for that customer (if necessary), create subscription for that user (if necessary)
        findCustomer()
        .then(processFindCustomer)
        .catch(processFindCustomerError)
        .then(createCustomer)
        .then(processCreateCustomer)
        .then(createPaymentMethod)
        .then(processCreatePaymentMethod)
        .then(createSubscription)
        .then(processCreateSubscription)
        .then(() => {console.log('Now have a customer with a subscription')})
        .catch((err) => {console.log(err)});
        */

    /*
        // create customer, create payment method for that customer, create subscription for that user
        let createCustomer = (customerParms) =>
        {
            return dataModelService.braintreeManagement.createCustomer(customerParms);
        }   // createCustomer

        let processCreateCustomer = (result) =>
        {
            console.log('createCustomer: ', result);
            let paymentMethodParms =
            {
                customerId: result.customer.id,          // wizeFiID
                paymentMethodNonce: paymentMethodNonce
            }
            return Promise.resolve(paymentMethodParms);
        }   // processCreateCustomer

        let createPaymentMethod = (paymentMethodParms) =>
        {
            return dataModelService.braintreeManagement.createPaymentMethod(paymentMethodParms);
        }   // createPaymentMethod

        let processCreatePaymentMethod = (result) =>
        {
            console.log('createPaymentMethod: ', result);
            let subscriptionParms =
            {
                planId: 'WizeFiPlan',
                paymentMethodToken: result.paymentMethod.token
            }
            return Promise.resolve(subscriptionParms);
        }   // processCreatePaymentMethod

        let createSubscription = (subscriptionParms) =>
        {
            return dataModelService.braintreeManagement.createSubscription(subscriptionParms);
        }   // createSubscription

        // input from WizeFi app subscription screen
        let customerID = '98765';
        let paymentMethodNonce = 'fake-valid-nonce';

        // create customer, create payment method for that customer, create subscription for that user
        let customerParms =
        {
            id: customerID,
            firstName: 'Joe',
            lastName: 'Testing',
            email: 'joe@abc.com'
        };
        createCustomer(customerParms)
        .then(processCreateCustomer)
        .then(createPaymentMethod)
        .then(processCreatePaymentMethod)
        .then(createSubscription)
        .then((result) => {console.log('createSubscription: ', result)})
        .catch((err) => {console.log(err)});
        */

    // INDIVIDUAL

    /*
        // create a customer
        customerID = '98765';
        parms =
        {
            id: customerID,
            firstName: 'Joe',
            lastName: 'Testing',
            email: 'joe@abc.com'
        }
        dataModelService.braintreeManagement.createCustomer(parms)
        .then((result) => {console.log('createCustomer: ', result)})
        .catch((err) => {console.log(err)});
        */

    /*
        // create a payment method
        paymentMethodNonce = 'fake-valid-nonce';
        parms =
        {
            customerId: customerID,          // wizeFiID
            paymentMethodNonce: paymentMethodNonce
        }
        dataModelService.braintreeManagement.createPaymentMethod(parms)
        .then((result) => {console.log('createPaymentMethod: ', result)})
        .catch((err) => {console.log(err)});
        */

    /*
        // create a subscription
        paymentMethodToken = 'jyv5ny';
        parms =
        {
            planId: 'WizeFiPlan',
            paymentMethodToken: paymentMethodToken
        }
        dataModelService.braintreeManagement.createSubscription(parms)
        .then((result) => {console.log('createSubscription: ', result)})
        .catch((err) => {console.log(err)});
        */

    /*
        dataModelService.braintreeManagement.deleteCustomer(customerID)
        .then((result) => {console.log('deleteCustomer: ', result)})
        .catch((err) => {console.log(err)});
        */

    /*
        // find customer information
        customerID = '98765';
        dataModelService.braintreeManagement.findCustomer(customerID)
        .then((result) => {console.log('findCustomer: ', result)})
        .catch((err) => {console.log(err)});
        */

    /*
        // create a subscription
        paymentMethodToken = '2ymcjj';
        paymentMethodNonce = 'fake-valid-nonce';
        parms =
        {
            planId: 'WizeFiPlan',
            paymentMethodToken: paymentMethodToken
            // paymentMethodNonce: paymentMethodNonce
            // note: provide only one or the other of paymentMethodToken or paymentMethodNonce
        }
        dataModelService.braintreeManagement.createSubscription(parms)
        .then((result) => {console.log('createSubscription: ', result)})
        .catch((err) => {console.log(err)});
        */

    //
    // find a subscription
    const subscriptionID = 'bcshp2'; // Dave in demo
    // let subscriptionID = dataModelService.dataModel.global.braintreeData.subscriptionInfo.subscriptionID;
    this.dataModelService.braintreeManagement
      .findSubscription(subscriptionID)
      .then(subscription => {
        console.log('findSubscription: ', subscription);
      })
      .catch(err => {
        console.log(err);
      });
    //

    /*
        // cancel a subscription
        subscriptionID = 'bcshp2';  // Dave in demo
        dataModelService.braintreeManagement.cancelSubscription(subscriptionID)
        .then((result) => {console.log('cancelSubscription: ', result)})
        .catch((err) => {console.log(err)});
        */

    /*
        // find a transaction
        let transactionID = '3nxbmh0r';
        dataModelService.braintreeManagement.findTransaction(transactionID)
        .then((transaction) => {console.log('findTransaction: ', transaction)})
        .catch((err) => {console.log(err)});
        */

    /*
        // find a list of transactions
        let parms =
        {
            startDate: '2017-09-26T00:00:00.000Z',
            endDate:   '2017-09-26T23:59:59.999Z',
            status: 'settled',
            type: 'credit'
        }
        dataModelService.braintreeManagement.searchTransactions(parms)
        .then((transactionList) => {console.log('searchTransactions: ', transactionList)})
        .catch((err) => {console.log(err)});
        */

    /*
        // find a list of disputes
        let parms =
        {
            monthDate: '2018-05',        // YYYY-MM
            // startDate: '2018-05-15',  // YYYY-MM-DD
            // endDate:   '2018-05-15',  // YYYY-MM-DD
            kind: ['chargeback'],
            status: ['accepted','lost']
        }
        dataModelService.braintreeManagement.searchDisputes(parms)
        .then((disputeList) => {console.log('searchDisputes: ', disputeList)})
        .catch((err) => {console.log(err)});
        */

    /*
        let customerID = '10212381931995957';
        let monthDate = '2018-05';
        let dayDate = '2018-05-15';

        dataModelService.braintreeManagement.getUserMonthDisputeRefunds(customerID,monthDate)
        .then((userMonthDisputeRefunds) => {console.log('getUserMonthDisputeRefunds: ', userMonthDisputeRefunds)})
        .catch((err) => {console.log(err)});

        dataModelService.braintreeManagement.getUserDayDisputeRefunds(customerID,dayDate)
        .then((userDayDisputeRefunds) => {console.log('getUserDayDisputeRefunds: ', userDayDisputeRefunds)})
        .catch((err) => {console.log(err)});
        */

    /*
        // get list of subscription refunds
        let startDate: '2017-09-26T00:00:00.000Z';
        // let endDate: '2017-09-26T23:59:59.999Z';
        let endDate: '2018-05-30T23:59:59.999Z';
        dataModelService.braintreeManagement.getSubscriptionRefundList(startDate,endDate)
        .then((subscriptionRefundList) => {console.log('subscriptionRefundList: ', subscriptionRefundList)})
        .catch((err) => {console.log(err)});
        */

    /*
        // get user email
        let wizeFiID = '10100507229934732';  // Greg
        dataModelService.braintreeManagement.getUserEmail(wizeFiID)
        .then((email) => {console.log('getUserEmail: ', email)})
        .catch((err) => {console.log(err)});
        */

    /*
        // set user email
        let wizeFiID = '10100507229934732';  // Greg
        // let email = 'greg@wizefi.com';
        let email = 'gregaeland@gmail.com';
        dataModelService.braintreeManagement.setUserEmail(wizeFiID,email)
        .then(() => {console.log('email has been set to ' + email)})
        .catch((err) => {console.log(err)});
        */
  } // runBraintreeManagementTests

  public runMiscellaneousTests() {
    /*
        // test visitAllWizeFiAccounts function
        console.log('test visitAllWizeFiAccounts function');

        let result: any;

        /////////////////////////////////////////
        // define visit functions
        /////////////////////////////////////////

        let visitWizeFiAccount1 = (plan,category,subcategory,acntndx,account,result) =>
        {
            const wizeFiCategory = this.dataModelService.categoryManagement.makeWizeFiCategory(category,subcategory, account.accountID.val);
            result.push(wizeFiCategory);
        };  // visitWizeFiAccount1

        let visitWizeFiAccount2 = (plan,category,subcategory,acntndx,account,result) =>
        {
            if (account.hasOwnProperty('actualMonthlyAmount'))
            {
                account.actualMonthlyAmount.val = 10;
            }
        };  // visitWizeFiAccount2

        let visitWizeFiAccount3 = (plan,category,subcategory,acntndx,account,result) =>
        {
            if (account.hasOwnProperty('actualMonthlyAmount'))
            {
                const wizeFiCategory = this.dataModelService.categoryManagement.makeWizeFiCategory(category,subcategory, account.accountID.val);
                result.push({wizeFiCategory: wizeFiCategory, actualMonthlyAmount: account.actualMonthlyAmount.val});
            }
        };  // visitWizeFiAccount3

        let visitWizeFiAccount4 = (plan,category,subcategory,acntndx,account,result) =>
        {
            if (account.hasOwnProperty('actualMonthlyAmount'))
            {
                account.actualMonthlyAmount.val = 0;
            }
        };  // visitWizeFiAccount4

        let visitWizeFiAccount5 = (plan,category,subcategory,acntndx,account,result) =>
        {
            if (!account.hasOwnProperty('actualMonthlyAmount') || account.hasOwnProperty('actualMonthlyAmount') && !account.actualMonthlyAmount.hasOwnProperty('val'))
            {
                const wizeFiCategory = this.dataModelService.categoryManagement.makeWizeFiCategory(category,subcategory, account.accountID.val);
                result.push(wizeFiCategory);
                console.log('account with no proper actualMonthlyAmount: ', account);  //%//
            }
        };  // visitWizeFiAccount5

        let visitWizeFiAccount6 = (plan,category,subcategory,acntndx,account,result) =>
        {
            if (account.hasOwnProperty('actualMonthlyAccount'))
            {
                console.log('account before fix: ', JSON.parse(JSON.stringify(account)));  //%//
                // delete incorrect attribute of account
                delete account['actualMonthlyAccount'];

                // add proper attribute to account
                account['actualMonthlyAmount'] = {label: "Actual Monthly Amount", isRequired: true, val: 0};
                console.log('account after fix: ', account);  //%//
            }
        };  // visitWizeFiAccount6

        /////////////////////////////////////////
        // invoke visitAllWizeFiAccounts
        /////////////////////////////////////////

        const plan = this.dataModelService.dataModel.persistent.header.curplan;

        result = [];
        this.dataModelService.visitAllWizeFiAccounts(plan,visitWizeFiAccount1,result);
        console.log('make list of wizeFiCategory values -- result: ', result);

        result = null;
        this.dataModelService.visitAllWizeFiAccounts(plan,visitWizeFiAccount2,result);
        result = [];
        this.dataModelService.visitAllWizeFiAccounts(plan,visitWizeFiAccount3,result);
        console.log('set actualMonthlyAmount to 10 -- result: ', result);

        result = null;
        this.dataModelService.visitAllWizeFiAccounts(plan,visitWizeFiAccount4,result);
        result = [];
        this.dataModelService.visitAllWizeFiAccounts(plan,visitWizeFiAccount3,result);
        console.log('set actualMonthlyAmount to 0 -- result: ', result);

        result = [];
        this.dataModelService.visitAllWizeFiAccounts(plan,visitWizeFiAccount5,result);
        console.log('accounts with no actualMonthlyValue -- result: ', result);

        //
        console.log('fix broken account');
        result = null;
        this.dataModelService.visitAllWizeFiAccounts(plan,visitWizeFiAccount6,result);

        result = [];
        this.dataModelService.visitAllWizeFiAccounts(plan,visitWizeFiAccount5,result);
        console.log('accounts with no actualMonthlyValue -- result: ', result);
        //
        */

    /*
        console.log('test updateBalance');
        // const wizeFiID = "zbpizmq";  // Dave demo
        const wizeFiID = "dvduvrt:;  // Dave staging
        this.dataModelService.plaidManagement.updateBalance(wizeFiID)
        .then((wizeFiPlaidAccounts) => {console.log('wizeFiPlaidAccounts: ', wizeFiPlaidAccounts)})
        .catch((err) => {console.log('error in updateBalance: ', err)});
        */

    /*
        console.log('test updateAllBalances');
        // const wizeFiID = "zbpizmq";  // Dave demo
        const wizeFiID = "dvduvrt:;  // Dave staging
        this.loading.isLoading = true;
        const title = this.dataModelService.dataManagement.getDraftTitle();

        this.dataModelService.plaidManagement.updateAllBalances(wizeFiID,title)
        .then((wizeFiPlaidAccounts) => {console.log('AdminRunTests -- updateAllBalances -- wizeFiPlaidAccounts: ', wizeFiPlaidAccounts)})
        .catch((err) => {console.log('error in updateAllBalances: ', err)})
        .finally(() => {this.loading.isLoading = false});
        */

    /*
        console.log('test deletion of WizeFiDataPlans item');

        // const wizeFiID = "zbpizmq";  // Dave demo
        const wizeFiID = "dvduvrt:;  // Dave staging
        const plan = "p202005";

        // delete plan from memory storage
        let memoryPlanList = Object.keys(this.dataModelService.dataModel.persistent.plans).sort();
        console.log("before -- plans in memory: ", memoryPlanList);

        delete this.dataModelService.dataModel.persistent.plans[plan];
        delete this.dataModelService.dataModel.global.plansOldData[plan]

        memoryPlanList = Object.keys(this.dataModelService.dataModel.persistent.plans).sort();
        console.log("after  -- plans in memory: ", memoryPlanList);

        // delete plan from persistent storage (DynamoDB)
        this.dataModelService.dataManagement.deleteMonthPlan(wizeFiID,plan)
        .then((result) => {console.log(result)})
        .catch((err) => {console.log('error in deleteMonthPlan: ', err)});
        */

    /*
        let parms: any;
        parms = {};

        const scanWizeFiData = async () => {
            let scanResult: any;  // list of items for a single scan operation (there is a limit on the number of items that a scan can retrieve)
            let params: any;
            params = {};      // parameters to guide scan operation
            params.TableName = "WizeFiData";
            parms.wizeFiIDvalues = {};

            // scan through all data items in the DynamoDB WizeFiData table
            do {
                // process data for each user in the current scanResult
                scanResult =  await this.dataModelService.dataModel.global.docClient.scan(params).promise();
                for (const item of scanResult.Items) {
                    parms.wizeFiIDvalues[item.wizeFiID] = 0;
                }   // for each item (user)

                params.ExclusiveStartKey = scanResult.LastEvaluatedKey;
            } while (typeof scanResult.LastEvaluatedKey !== "undefined");

            console.log("number of wizeFiID values in WizeFiData: " + Object.keys(parms.wizeFiIDvalues).length);  // %//
        };  // scanWizeFiData

        const verifyWizeFiID = async (tableName) => {
            // initialize
            let scanResult;   // list of items for a single scan operation (there is a limit on the number of items that a scan can retrieve)
            let params: any;  // parameters to guide the scan operation
            params = {};      // parameters to guide scan operation

            params.TableName = tableName;

            if (!parms.hasOwnProperty("missingWizeFiID")) {
              parms.missingWizeFiID = {};
            }
            parms.missingWizeFiID[tableName] = {};

            // scan through all data items in DynamoDB table named tableName
            do {
              // process data for each user in the current scanResult
              scanResult =  await this.dataModelService.dataModel.global.docClient.scan(params).promise();
              for (const item of scanResult.Items) {
                if (!parms.wizeFiIDvalues.hasOwnProperty(item.wizeFiID)) {
                  parms.missingWizeFiID[tableName][item.wizeFiID] = 0;
                }
              }   // for each item (user)

              params.ExclusiveStartKey = scanResult.LastEvaluatedKey;
            } while (typeof scanResult.LastEvaluatedKey !== "undefined");
        }; // verifyWizeFiID

        const processData = async () => {
            const tableNames =
            [
                "ManageTreeLog",
                "ScreenData",
                "WizeFiDataPlans",
                "WizeFiPlaidAccounts",
                "WizeFiPlaidInstitutions",
                "WizeFiTransactionAttributePatterns",
                "WizeFiTransactions"
            ];

            // obtain wizeFiID data
            await scanWizeFiData();

            // process each table to add missing wizeFiID values to object
            for (const tableName of tableNames) {
                // console.log(tableName);  //%//

                // obtain data
                await verifyWizeFiID(tableName);

                // report results
                console.log(" ");
                console.log(tableName + " missing wizeFiID values:");
                for (const wizeFiID of Object.keys(parms.missingWizeFiID[tableName])) {
                    console.log(wizeFiID);
                }
                console.log(Object.keys(parms.missingWizeFiID[tableName]).length + " missing values");
            }
            console.log(parms.missingWizeFiID);

        };  // processData

        this.loading.isLoading = true;

        processData()
        .catch((err) => {console.log(err)})
        .finally(() => {this.loading.isLoading = false});
        */

    /*
        console.log(' ');
        console.log('test sort algorithm for rule patterns');

        // execute setPatternsInformation function to prepare for the patternID sort process
        this.dataModelService.categoryManagement.setPatternsInformation.bind(this.dataModelService.categoryManagement)();

        console.log('attributesCount: ', this.dataModelService.categoryManagement.attributesCount);
        console.log('attributeString: ', this.dataModelService.categoryManagement.attributeString);
        console.log('attributeValues: ', this.dataModelService.categoryManagement.attributeValues);
        console.log('attributeValuesString: ', this.dataModelService.categoryManagement.attributeValuesString);
        console.log('wizeFiCategoryPattern: ', this.dataModelService.categoryManagement.wizeFiCategoryPattern);

        const wizeFiTransactionAttributePatterns = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionAttributePatterns;

        let patternIDsequence: any;
        patternIDsequence = Object.keys(wizeFiTransactionAttributePatterns);
        console.log('before -- patternIDsequence: ', JSON.parse(JSON.stringify(patternIDsequence)));

        this.dataModelService.categoryManagement.wantTrace = true;
        this.dataModelService.categoryManagement.totalComparisons = 0;
        patternIDsequence = Object.keys(wizeFiTransactionAttributePatterns).sort(
          this.dataModelService.categoryManagement.sortRuleCompare.bind(this.dataModelService.categoryManagement)
        );
        console.log('totalComparisons: ' + this.dataModelService.categoryManagement.totalComparisons);  //%//

        console.log('after  -- patternIDsequence: ', patternIDsequence);

        console.log( ' ');
        this.dataModelService.categoryManagement.wantTrace = false;
        console.log('rule sequence');
        this.dataModelService.categoryManagement.showRuleSequence.bind(this.dataModelService.categoryManagement)();
        */

    /*
        console.log('show categories and subcategories');
        for (const category of Object.keys(categoryInfo))
        {
            if (category !== "assets2")
            {
                console.log(category);
                for (const subcategory of Object.keys(categoryInfo[category]))
                {
                    if (["label", "tooltip"].indexOf(subcategory) === -1)
                    {
                        console.log("    " + subcategory)
                    }
                }
            }
          })
        */

    /* show list of currency information for each country supported by Plaid
        const currencyCodeCompare = (a,b) =>
        // This routine enables sort to be based on currencyCode.
        {
          const result = (a.currencyCode == b.currencyCode) ? 0 :
            ((a.currencyCode > b.currencyCode ? 1 : -1));
          return result;
        };  // currencyCodeCompare

        const countryNameCompare = (a,b) =>
        // This routine enables sort go be based on currencyCode.
        {
          const result = (a.countryName == b.countryName) ? 0 :
            ((a.countryName > b.countryName ? 1 : -1));
          return result;
        };  // countryNameCompare

        const plaidCountryCodes = this.dataModelService.ancillaryDataManagement.plaidCountryCodes;
        const countryCode2countryCodeItem = this.dataModelService.ancillaryDataManagement.countryCode2countryCodeItem;
        const countryName2currencyCodeItem = this.dataModelService.ancillaryDataManagement.countryName2currencyCodeItem;
        const currencyCode2currencyCodeItem = this.dataModelService.ancillaryDataManagement.currencyCode2currencyCodeItem;
        const currencyCodeItems = this.dataModelService.ancillaryDataManagement.currencyCodeItems;

        console.log('plaidCountryCodes: ', plaidCountryCodes);  //%//
        console.log('countryCode2countryCodeItem: ', countryCode2countryCodeItem);  //%//
        console.log('countryName2currencyCodeItem: ', countryName2currencyCodeItem);  //%//
        console.log('currencyCode2currencyCodeItem: ', currencyCode2currencyCodeItem);  //%//
        console.log('currencyCodeItems: ', currencyCodeItems.sort(currencyCodeCompare));  //%//
        // console.log('currencyCodeItems: ', currencyCodeItems.sort(countryNameCompare));  //%//

        for (const countryCode of plaidCountryCodes)
        {
        const countryName = countryCode2countryCodeItem[countryCode].countryName;
        let currencyCodes = "";
        if (countryName2currencyCodeItem.hasOwnProperty(countryName))
        {

            currencyCodes = countryName2currencyCodeItem[countryName].currencyCodes;
        }
        const currencyCodeList = currencyCodes.split(', ');
        console.log(' ');
        console.log(countryCode + '  ' + countryName);
        for (const currencyCode of currencyCodeList)
        {
            let currencyName = "undefined";
            if (currencyCode2currencyCodeItem.hasOwnProperty(currencyCode))
            {
                currencyName = currencyCode2currencyCodeItem[currencyCode].currencyName;
            }
            console.log('    ' + currencyCode + ' ' + currencyName);
        }
        }
        */

    //
    const getAliasWizeFiDataItem = async affiliateAlias =>
      // This routine will return a wizeFiDataItem from the WizeFiData DynamoDB table utilizing an affiliateAlias index to access the data.
      {
        let lambda: any;

        const obtainWizeFiDataItem = affiliateAlias =>
          new Promise((resolve, reject) => {
            // set params to guide Lambda function invocation
            const payload = {
              action: 'getAliasWizeFiDataItem',
              actionParms: { affiliateAlias }
            };
            const params = {
              FunctionName: 'preliminaryWork',
              Payload: JSON.stringify(payload)
            };

            // invoke Lambda function to process data
            lambda.invoke(params, (err, data) => {
              if (err) {
                reject(err);
              } else {
                const wizeFiDataItem = JSON.parse(data.Payload);
                resolve(wizeFiDataItem);
              }
            }); // lambda invoke
          }); // return Promise // obtainWizeFiDataItem
        // configure AWS object
        AWS.config.update({ region: environment.AWSRegion });
        AWS.config.correctClockSkew = true;
        if (!AWS.config.hasOwnProperty('credentials') || AWS.config.credentials == null) {
          // clear credentials from localStorage to eliminate "Missing credentials in config" error
          const setting = 'aws.cognito.identity-id.' + environment.AWSIdentityPoolId;
          window.localStorage.removeItem(setting);

          // configure AWS object for unauthenticated user (one who has not yet logged in)
          AWS.config.credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: environment.AWSIdentityPoolId });
        }

        // establish lambda object
        lambda = new AWS.Lambda();

        // obtain wizeFiDataItem object
        const wizeFiDataItem = await obtainWizeFiDataItem(affiliateAlias);

        return wizeFiDataItem;
      }; // getAliasWizeFiDataItem

    console.log('getAliasWizeFiDataItem'); //%//
    getAliasWizeFiDataItem('freetrial')
      .then(wizeFiDataItem => {
        console.log('wizeFiDataItem: ', wizeFiDataItem);
      })
      .catch(err => {
        console.log('error in getAliasWizeFiDataItem: ', err);
      });
    //
    let attributesCount: any;
    let attributeString: any;
    let attributeValues: any;
    let attributeValuesString: any;
    let wizeFiCategoryPattern: any;
    let totalComparisons = 0;
    const wantTrace = false;
    /*


        const setPatternsInformation = () =>
        // This routine sets information used during the sortRuleCompare process.
        {
            const wizeFiTransactionAttributePatterns = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionAttributePatterns;
            // console.log('wizeFiTransactionAttributePatterns: ', wizeFiTransactionAttributePatterns);  //%//
            attributesCount = {};
            attributeString = {};
            attributeValues = {};
            attributeValuesString = {};
            wizeFiCategoryPattern = {};
            for (const patternID of Object.keys(wizeFiTransactionAttributePatterns))
            {
                const wizeFiTransactionAttributePattern = wizeFiTransactionAttributePatterns[patternID];
                const attributeList = Object.keys(wizeFiTransactionAttributePattern.attributePattern).sort();
                wizeFiCategoryPattern[patternID] = wizeFiTransactionAttributePattern.wizeFiCategory;
                attributesCount[patternID] = attributeList.length;
                attributeString[patternID] = attributeList.join();
                attributeValues[patternID] = {};
                attributeValuesString[patternID] = "";
                let firstTime = true;
                for (const attribute of Object.keys(wizeFiTransactionAttributePattern.attributePattern))
                {
                    attributeValues[patternID][attribute] = wizeFiTransactionAttributePattern.attributePattern[attribute];

                    const prefix = (firstTime) ? "" : ":";
                    attributeValuesString[patternID] += prefix + wizeFiTransactionAttributePattern.attributePattern[attribute];
                    firstTime = false;
                }
            }
        };  // setPatternsInformation

        /*
        console.log(' ');
        console.log('test sort algorithm for rule patterns');

        setPatternsInformation();

        console.log('attributesCount: ', attributesCount);
        console.log('attributeString: ', attributeString);
        console.log('attributeValues: ', attributeValues);
        console.log('attributeValuesString: ', attributeValuesString);
        console.log('wizeFiCategoryPattern: ', wizeFiCategoryPattern);
        */

    const sortRuleCompare = (
      a,
      b
    ) => /*
    This routine will compare two rules in order to enable the process that will sort the rules into a sequence that applies more specific rules ahead of less specific rules.

    The parameters a and b provide the patternID of the two rules to be compared.

    result = -1 (a is earlier in sort), 1 (a is later in sort, 0 (a and b are equal, order in sort is random between the two))
    */ {
      const info = (a, b, step, result) =>
        // This routine displays information about the comparison step between two rules.
        a + '  ' + b + '  ' + step + ': ' + result; // info

      // initialize
      let result = 0;
      totalComparisons++;

      // determine result when number of attributes is different
      if (attributesCount[a] > attributesCount[b]) {
        result = -1;
        if (wantTrace) {
          console.log(info(a, b, 'A', result));
        }
      } else if (attributesCount[a] < attributesCount[b]) {
        result = 1;
        if (wantTrace) {
          console.log(info(a, b, 'B', result));
        }
      }

      // determine result when same number of attributes are involved
      if (result == 0) {
        // set result when different attributes are involved
        if (attributeString[a] != attributeString[b]) {
          result = -1;
          if (wantTrace) {
            console.log(info(a, b, 'C', result));
          }
        }
      }

      // determine result when same attributes are involved
      if (result == 0) {
        // set result based on whether one attribute value is a subset of the other
        for (const attribute of Object.keys(attributeValues[a])) {
          if (attributeValues[a][attribute] != attributeValues[b][attribute]) {
            if (attributeValues[a][attribute].includes(attributeValues[b][attribute])) {
              result = -1;
              if (wantTrace) {
                console.log(info(a, b, 'D', result));
              }
            } else if (attributeValues[b][attribute].includes(attributeValues[a][attribute])) {
              result = 1;
              if (wantTrace) {
                console.log(info(a, b, 'E', result));
              }
            }
          }
        }

        // set result based on all attribute values
        if (result == 0) {
          if (attributeValuesString[a] < attributeValuesString[b]) {
            result = -1;
            if (wantTrace) {
              console.log(info(a, b, 'F', result));
            }
          } else if (attributeValuesString[a] > attributeValuesString[b]) {
            result = 1;
            if (wantTrace) {
              console.log(info(a, b, 'G', result));
            }
          }
        }
      }

      // determine result if not yet decided
      if (result == 0) {
        if (wizeFiCategoryPattern[a] == wizeFiCategoryPattern[b]) {
          result = -1;
          if (wantTrace) {
            console.log(info(a, b, 'H', result));
          }
        }
      }
      return result;
    }; // sortRuleCompare

    const showRuleSequence = () => {
      const wizeFiTransactionAttributePatterns = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionAttributePatterns;
      for (const patternID of Object.keys(wizeFiTransactionAttributePatterns).sort(sortRuleCompare)) {
        // initialize
        const wizeFiTransactionAttributePattern = wizeFiTransactionAttributePatterns[patternID];

        // set attributePatternInfo
        let attributePatternInfo = '';
        let firstTime = true;
        for (const attribute of Object.keys(wizeFiTransactionAttributePattern.attributePattern).sort()) {
          const prefix = firstTime ? '' : ' ';
          attributePatternInfo += prefix + attribute + ':' + wizeFiTransactionAttributePattern.attributePattern[attribute];
          firstTime = false;
        }

        // display outpue
        console.log(
          patternID +
            '  ' +
            Object.keys(wizeFiTransactionAttributePattern.attributePattern).length +
            '  ' +
            wizeFiTransactionAttributePattern.wizeFiCategory +
            '  ' +
            attributePatternInfo
        );
      }
    }; // showRuleSequence

    /*
        const wizeFiTransactionAttributePatterns = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionAttributePatterns;

        let patternIDsequence: any;
        patternIDsequence = Object.keys(wizeFiTransactionAttributePatterns);
        console.log('before -- patternIDsequence: ', JSON.parse(JSON.stringify(patternIDsequence)));

        wantTrace = true;
        totalComparisons = 0;
        patternIDsequence = Object.keys(wizeFiTransactionAttributePatterns).sort(sortRuleCompare);
        console.log('totalComparisons: ' + totalComparisons);  //%//

        console.log('after  -- patternIDsequence: ', patternIDsequence);

        console.log( ' ');
        wantTrace = false;
        console.log('rule sequence');
        showRuleSequence();
        */

    /* test results for the above
        // rule sequence
        vamyj  2  budget_food_ID002  institutionName:Fidelity  merchantName:Jackson's Corner
        bovdv  2  budget_food_ID002  institutionName:SELCO     merchantName:Jackson's Corner
        kqphl  2  budget_food_ID002  institutionName:Fidelity  merchantName:Jackson
        gyuyc  1  budget_food_ID002  merchantName:Jackson's Corner
        aqafj  1  budget_food_ID002  merchantName:Jackson
        jljpa  1  budget_food_ID002  merchantName:Papa Murphy
        mhbpz  1  budget_food_ID001  merchantName:Safeway

        after -- patternIDsequence: "vamyj", "bovdv", "kqphl", "gyuyc", "aqafj", "jljpa", "mhbpz"

        // place in sort algorithm where sequence of two patternID values was determined
        jljpa  gyuyc  G: 1
        mhbpz  jljpa  G: 1
        kqphl  mhbpz  A: -1
        kqphl  jljpa  A: -1
        kqphl  gyuyc  A: -1
        aqafj  jljpa  F: -1
        aqafj  gyuyc  E: 1
        vamyj  aqafj  A: -1
        vamyj  gyuyc  A: -1
        vamyj  kqphl  D: -1
        bovdv  aqafj  A: -1
        bovdv  kqphl  D: -1
        bovdv  vamyj  G: 1

        totalComparisons: 13
        */
  } // runMiscellaneousTests

  public runAncillaryDataManagementTests() {
    const testAncillaryDataManagement = async () => {
      // initialize ancillary data
      await this.dataModelService.ancillaryDataManagement.loadAncillaryData();

      // show available data in AncillaryDataManagement class
      console.log(' ');
      console.log('countryCodeItems: ', this.dataModelService.ancillaryDataManagement.countryCodeItems);
      console.log('countryCode2countryCodeItem: ', this.dataModelService.ancillaryDataManagement.countryCode2countryCodeItem);
      console.log('countryName2countryCodeItem: ', this.dataModelService.ancillaryDataManagement.countryName2countryCodeItem);

      console.log(' ');
      console.log('currencyCodeItems: ', this.dataModelService.ancillaryDataManagement.currencyCodeItems);
      console.log('currencyCode2currencyCodeItem: ', this.dataModelService.ancillaryDataManagement.currencyCode2currencyCodeItem);
      console.log('countryName2currencyCodeItem: ', this.dataModelService.ancillaryDataManagement.countryName2currencyCodeItem);

      console.log(' ');
      console.log('currencyConversion: ', this.dataModelService.ancillaryDataManagement.currencyConversion);
      console.log('currencyCode2exchangeRate: ', this.dataModelService.ancillaryDataManagement.currencyCode2exchangeRate);

      const userCurrencyCode = 'USD';
      console.log('userCurrencyCode:' + userCurrencyCode); // %//
      const currencyCode2currencyCodeItem = this.dataModelService.ancillaryDataManagement.currencyCode2currencyCodeItem;
      console.log(currencyCode2currencyCodeItem[userCurrencyCode].currencyName);
      console.log(currencyCode2currencyCodeItem[userCurrencyCode].countryNames);

      // test currency conversion
      console.log(' ');
      console.log('convert 100 USD USD: ' + this.dataModelService.ancillaryDataManagement.convertCurrency(100, 'USD', 'USD'));
      console.log('convert 100 EUR EUR: ' + this.dataModelService.ancillaryDataManagement.convertCurrency(100, 'EUR', 'EUR'));
      console.log('convert 1000 USD GBP: ' + this.dataModelService.ancillaryDataManagement.convertCurrency(1000, 'USD', 'GBP'));
      console.log(
        'convert 784.6219318230668 GBP USD: ' + this.dataModelService.ancillaryDataManagement.convertCurrency(784.6219318230668, 'GBP', 'USD')
      );
      console.log('convert 784.62 GBP USD: ' + this.dataModelService.ancillaryDataManagement.convertCurrency(784.62, 'GBP', 'USD'));
      console.log('convert 1000 USD VND: ' + this.dataModelService.ancillaryDataManagement.convertCurrency(1000, 'USD', 'VND'));
      console.log('convert 23193001.135709066 VND USD: ' + this.dataModelService.ancillaryDataManagement.convertCurrency(23164300, 'VND', 'USD'));
      console.log('convert 23193001.14 VND USD: ' + this.dataModelService.ancillaryDataManagement.convertCurrency(23193001.14, 'VND', 'USD'));

      console.log(' ');
      console.log('ancillaryDataManagement: ', this.dataModelService.ancillaryDataManagement);
    }; // testAncillaryDataManagement

    testAncillaryDataManagement().catch(err => {
      console.log('error in testAncillaryDataManaement: ', err);
    });
  } // runAncillaryDataManagementTest

  public runSuggestionManagementTests() {
    const runSuggestionManagementTests0 = async () => {
      /* works correctly
            console.log('store suggestionData in DynamoDB');
            suggestionData =
            {
                "xopwh":
                {
                    timestamp: new Date().toISOString(),
                    suggestionTitle: "First suggestion here",
                    suggestionDescription: "Suggestion description here",
                    priority: 0,
                    votes:
                    [
                      {wizeFiID: "zbpizmq", nameFirst: "Dave", nameLast: "Eland", email: "daveland@oru.edu"},
                      {wizeFiID: "amdiigx", nameFirst: "Jeff", nameLast: "Foster", email: "jethfo@gmail.com"},
                    ],
                },
                "kmwpx":
                {
                    timestamp: new Date().toISOString(),
                    suggestionTitle: "Second suggestion here",
                    suggestionDescription: "Here is a little bit longer description",
                    priority: 0,
                    votes:
                    [
                      {wizeFiID: "zbpizmq", nameFirst: "Dave", nameLast: "Eland", email: "daveland@oru.edu"},
                      {wizeFiID: "amdiigx", nameFirst: "Jeff", nameLast: "Foster", email: "jethfo@gmail.com"},
                      {wizeFiID: "fkcgrsp", nameFirst: "matt", nameLast: "heinke", email: "matt.heinke@gmail.com"},
                    ],
                },
            };
            await this.dataModelService.suggestionManagement.store(suggestionData);
            console.log('suggestionData: ', suggestionData);
            console.log('suggestionData stored in DynamoDB')
            */

      /* works correctly
            console.log('retrieve suggestionData from DynamoDB')
            suggestionData = await this.dataModelService.suggestionManagement.fetch();
            console.log('suggestionData retrieved from DynamoDB: ', suggestionData);
            */

      //
      console.log(' ');
      console.log('this.dataModelService.suggestionManagement.suggestionData: ', this.dataModelService.suggestionManagement.suggestionData);
      //

      /* works correctly
            console.log(' ');
            const suggestionTitle = "Here is the title of a new suggestion";
            const suggestionDescription = "This is a more complete description for a new suggestion";
            console.log('before addSuggestion -- suggestionData', JSON.parse(JSON.stringify(this.dataModelService.suggestionManagement.suggestionData)));
            await this.dataModelService.suggestionManagement.addSuggestion(suggestionTitle, suggestionDescription);
            console.log('after addSuggestion  -- suggestionData', this.dataModelService.suggestionManagement.suggestionData);
            */

      /* works correctly
            console.log(' ');
            // suggestionID = "xopwh";
            suggestionID = "kmwpx";
            console.log('deleteSuggestion -- suggestionID: ' + suggestionID);
            console.log('before deleteSuggestion -- suggestionData', JSON.parse(JSON.stringify(this.dataModelService.suggestionManagement.suggestionData)));
            await this.dataModelService.suggestionManagement.deleteSuggestion(suggestionID);
            console.log('after deleteSuggestion  -- suggestionData', this.dataModelService.suggestionManagement.suggestionData);
            */

      /* works correctly
            console.log(' ');
            suggestionID = "xopwh";
            console.log('addVote to suggestionID: ' + suggestionID);
            console.log('before addVote -- suggestionData', JSON.parse(JSON.stringify(this.dataModelService.suggestionManagement.suggestionData)));
            await this.dataModelService.suggestionManagement.addVote(suggestionID);
            console.log('after addVote  -- suggestionData', this.dataModelService.suggestionManagement.suggestionData);
            */

      /* works correctly (for data now outdated -- vote information now contains the data provided by getUserInfoList)
            console.log(' ');
            console.log('getUserInfoList');
            for (const suggestionID of ["xopwh","kmwpx"])
            {
                const userInfoList = await this.dataModelService.suggestionManagement.getUserInfoList(suggestionID,suggestionData);
                console.log('userInfoList: ', userInfoList);
            }
            */
    }; // runSuggestionManagementTests0

    runSuggestionManagementTests0().catch(err => {
      console.log('error in runSuggestionManagementTest: ', err);
    });
  } // runSuggestionManagementTests

  public runAttributePatternTests() {
    const runAttributePatternTests0 = async () => {
      /* works correctly
            // patternID = "ccsrw";
            patternID = "xepme";
            const transaction = this.dataModelService.categoryManagement.getRuleTransaction(patternID);
            console.log('getRuleTransaction -- transaction: ', transaction);  //%//
            */
      /* works correctly
            console.log(' ');
            console.log('addAttributePattern');

            const wizeFiTransactionAttributePattern =
            {
                patternID: "aaaaa",
                wizeFiCategory: "income_income_ID002",
                transaction_id: "A4wzOera58CVvnpqA4zdiw1zN8gwbQI6AQXdM",
                attributePattern:
                {
                    merchantName: "SOC SEC",
                    institutionName: "SELCO"
                }
            };
            await this.dataModelService.categoryManagement.addAttributePattern(wizeFiTransactionAttributePattern);
            */
      /* works correctly
            console.log(' ');
            console.log('deleteAttributePattern');

            patternID = "uswlt";
            await this.dataModelService.categoryManagement.deleteAttributePattern(patternID);
            */
      /* works correctly
            console.log(' ');
            console.log('modifyAttributePattern');

            patternID = "ccsrw";
            const wizeFiTransactionAttributePattern = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionAttributePatterns[patternID];
            wizeFiTransactionAttributePattern.attributePattern.merchantName = "changed value";

            await this.dataModelService.categoryManagement.modifyAttributePattern(wizeFiTransactionAttributePattern);
            */
    }; // runAttributePatternTests0

    console.log('start runAttributePatternTests');
    runAttributePatternTests0()
      .then(() => {
        console.log('end   runAttributePatternTests');
      })
      .catch(err => {
        console.log('error in runAttributePatternTests: ', err);
      });
  } // runAttributePatternTests

  public runAmazonS3Tests() {
    console.log('run Amazon S3 tests');
    this.wantAmazonS3 = !this.wantAmazonS3; // toggle setting
    this.fileURL = this.pictureNotAvailableURL;
  } // runAmazonS3Tests()

  //////////////////////////////////////////
  // utility functions for Amazon S3
  //////////////////////////////////////////

  public getObject(bucketName, objectKey) /*
    This JavaScript SDK function gets an object from an Amazon S3 bucket.
    */ {
    return new Promise((resolve, reject) => {
      const params = {
        Bucket: bucketName,
        Key: objectKey
      };
      this.dataModelService.dataModel.global.s3.getObject(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    }); // return Promise
  } // getObject

  public upload(bucketName, objectKey, fileData) /*
    This JavaScript SDK function uploads an object to an Amazon S3 bucket.
    */ {
    return new Promise((resolve, reject) => {
      const params = {
        Body: fileData,
        Bucket: bucketName,
        Key: objectKey
      };
      this.dataModelService.dataModel.global.s3.upload(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    }); // return Promise
  } // upload

  public deleteObject(bucketName, objectKey) /*
    This JavaScript SDK function deletes an object from an Amazon S3 bucket.
    */ {
    return new Promise((resolve, reject) => {
      const params = {
        Bucket: bucketName,
        Key: objectKey
      };
      this.dataModelService.dataModel.global.s3.deleteObject(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    }); // return Promise
  } // deleteObject

  public getfileURL(
    file: any
  ) /*
    This routine obtains a URL for a memory resident version of a file.

    The file parameter is derived from the event data provided by the HTML input file widget.
    */ {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e: any) {
        const fileURL = e.target.result;
        resolve(fileURL);
      };
      // TODO refine error handling
      reader.onabort = () => {
        reject('abort error occurred');
      };
      reader.addEventListener('error', () => {
        reject('event error occurred');
      });
    }); // return Promise
  } // getfileURL

  public getfileData(
    file: any
  ) /*
    This routine obtains the fileData for a memory resident version of a file.

    The file parameter is derived from the event data provided by the HTML input file widget.
    */ {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = function (e: any) {
        const fileData = new Uint8Array(e.target.result);
        resolve(fileData);
      };
      // TODO refine error handling
      reader.onabort = () => {
        reject('abort error occurred');
      };
      reader.addEventListener('error', () => {
        reject('event error occurred');
      });
    }); // return Promise
  } // getfileData

  public setVirtualURL(
    region,
    bucketName,
    objectKey
  ) /*
    This routine returns a URL of an Amazon S3 object in the "virtual-hosted–style access" format.
    (This is the proper format to use.)
    */ {
    const virtualURL = 'https://' + bucketName + '.s3.' + region + '.amazonaws.com/' + objectKey;
    return virtualURL;
  } // setVirtualURL

  public setPathURL(
    region,
    bucketName,
    objectKey
  ) /*
    This routine returns a URL of an Amazon S3 object in the "path-style access" format.
    (This format has been deprecated.)
    */ {
    const pathURL = 'https://s3.' + region + '.amazonaws.com/' + bucketName + '/' + objectKey;
    return pathURL;
  } // setPathURL

  public setWizeFiUserPictureURL() /*
    This routine sets the proper URL for displaying for the current WizeFi user a picture in an HTML img widget.

    In Angular, this is utilized as illustrated below:

    this.wizeFiUserPictureURL = this.setWizeFiUserPictureURL();  // in the component .ts file code, set the URL local variable

    <img [src]="wizeFiUserPictureURL" width="100" height="100">  // in the HTML code, plant the URL value as the src value

    */ {
    const region = environment.AWSRegion;
    const bucketName = environment.bucketName;
    const objectKey = this.dataModelService.dataModel.persistent.profile.userPictureObjectKey;
    const wizeFiUserPictureURL = this.setVirtualURL(region, bucketName, objectKey);
    return wizeFiUserPictureURL;
  } // setWizeFiUserPictureURL

  public async setSelectedWizeFiUserPictureURL(
    wizeFiID
  ) /*
    This routine sets the proper URL for displaying the selected WizeFi user picture.

    The wizeFiID identifies which selected user picture to view.  The provided wizeFiID must be in the same DynamoDB table as the current user of the WizeFi app.
    */ {
    const getUserPictureObjectKey = () =>
      /*
        This routine will return the userPictureObjectKey value for the user identified by wizeFiID.
        */
      new Promise((resolve, reject) => {
        // define params to guide get operation
        const params = {
          TableName: 'WizeFiData',
          Key: { wizeFiID }
        };

        // get info from DynamoDB table
        this.dataModelService.dataModel.global.docClient.get(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            const item = data.Item;
            console.log('item: ', item); //%//
            item.persistent = JSON.parse(item.persistent);
            const userPictureObjectKey = item.persistent.profile.userPictureObjectKey;
            resolve(userPictureObjectKey);
          }
        }); // docClient.get
      }); // return Promise // getUserPictureObjectKey
    const userPictureObjectKey = await getUserPictureObjectKey();

    const region = environment.AWSRegion;
    const bucketName = environment.bucketName;
    const objectKey = userPictureObjectKey;
    const selectedWizeFiUserPictureURL = this.setVirtualURL(region, bucketName, objectKey);
    return selectedWizeFiUserPictureURL;
  } // setSelectedWizeFiUserPictureURL

  public async getPictureURLmap(
    wizeFiIDList
  ) /*
    This routine sets a map that defines the proper URL for displaying a user picture for each user in the map.

    The wizeFiIDList identifies which wizeFiID values to inclued in the map.  Each wizeFiID must be in the same DynamoDB table as the current user of the WizeFi app.
    */ {
    const getUserPictureObjectKey = wizeFiID =>
      /*
        This routine will return the userPictureObjectKey value for the user identified by wizeFiID.
        */
      new Promise((resolve, reject) => {
        // define params to guide get operation
        const params = {
          TableName: 'WizeFiData',
          Key: { wizeFiID }
        };

        // get info from DynamoDB table
        this.dataModelService.dataModel.global.docClient.get(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            const item = data.Item;
            item.persistent = JSON.parse(item.persistent);
            const userPictureObjectKey = item.persistent.profile.userPictureObjectKey;
            resolve(userPictureObjectKey);
          }
        }); // docClient.get
      }); // return Promise // getUserPictureObjectKey
    const region = environment.AWSRegion;
    const bucketName = environment.bucketName;
    const pictureURLmap = {};
    for (const wizeFiID of wizeFiIDList) {
      const userPictureObjectKey = await getUserPictureObjectKey(wizeFiID);
      const objectKey = userPictureObjectKey;
      const selectedWizeFiUserPictureURL = this.setVirtualURL(region, bucketName, objectKey);
      pictureURLmap[wizeFiID] = selectedWizeFiUserPictureURL;
    }
    return pictureURLmap;
  } // getPictureURLmap

  public setPictureNotAvailableURL() /*
    This routine sets the proper URL for the "picture not available" image.
    */ {
    const pictureNotAvailableURL = this.setVirtualURL(environment.AWSRegion, environment.bucketName, pictureNotAvailableObjectKey);
    return pictureNotAvailableURL;
  } // setPictureNotAvailableURL

  //////////////////////////////////////////
  // end of utility programs for Amazon S3
  //////////////////////////////////////////

  public testGetObject() /*
    This routine illustrates how to get an object from an Amazon S3 bucket.
    */ {
    const testGetObject0 = async () => {
      console.log('\nget object');
      const bucketName = environment.bucketName;
      const objectKey = 'DaveEland.jpg'; // note: providing a non-existent key results in "Access Denied" error
      let result: any; // deal with Typescript data type
      result = await this.getObject(bucketName, objectKey);
      const fileData = result.Body;
      console.log('getObject(' + bucketName + ',' + objectKey + ') -- fileData: ', fileData);
    }; // testGetObject0

    testGetObject0()
      .then(() => {
        console.log('finished get object test');
      })
      .catch(err => {
        console.log('error in testGetObject: ', err);
      });
  } // getObject

  public uploadPicture(
    event: any
  ) /*
    This routine uploads a WizeFi user picture to Amazon S3 and stores the reference to the picture in the WizeFi data model.

    The event parameter is sent by the HTML input file widget (this function is invoked in the HTML code).

    TODO -- consider alternative techniques for stepping through step 1 and step 2 other than using the confirm function.

    Note: the confirm function does not work as expected if attempting to execute this function multiple times.
    */ {
    const uploadPicture0 = async () => {
      console.log('\nupload picture'); //%//

      // step 1 -- select and display the selected picture file

      // set value of file object (which contains information about the user selected file)
      let file: any; // deal with Typescript data type issues
      file = event.target.files[0];

      // obtain fileURL for the memory resident file in order to display the selected picture
      let fileURL: any; // deal with Typescript data type issue
      fileURL = await this.getfileURL(file);
      this.fileURL = fileURL; // enable display of memory resident version of selected picture
      console.log('memory resident fileURL: ' + fileURL); //%//

      await this.dataModelService.sleep(200); // kludge to let screen content settle before doing confirm action
      const wantPicture = confirm('Do you wish to store the selected picture?');
      if (wantPicture) {
        // step 2 -- store the selected picture in Amazon S3 and in the WizeFi data model

        // obtain information to enable upload of the picture file data to Amazon S3
        const bucketName = environment.bucketName;
        const objectKey = file.name;
        const fileData = await this.getfileData(file);
        console.log('fileData: ', fileData); //%//

        // upload picture file to Amazon S3 for storage
        let result: any; // deal with Typescript data type issue
        result = await this.upload(bucketName, objectKey, fileData);
        console.log('upload(' + bucketName + ',' + objectKey + ') result: ', result); //%//

        // store picture identification information in WizeFi data model
        this.dataModelService.dataModel.persistent.profile.userPictureObjectKey = objectKey;
        await this.dataModelService.dataManagement.storeinfo();

        // obtain wizeFiUserPictureURL for the user picture identified in the WizeFi data model
        this.wizeFiUserPictureURL = this.setWizeFiUserPictureURL(); // enable display of picture referenced in WizeFi data model
        console.log('wizeFiUserPictureURL: ' + this.wizeFiUserPictureURL); //%//
      }
    }; // uploadPicture0

    uploadPicture0()
      .then(() => {
        console.log('finished uploadPicture');
      })
      .catch(err => {
        console.log('error in uploadPicture: ', err);
      });
  } // uploadPicture

  public deletePicture() /*
    This routine deletes a picture from Amazon S3 and from the WizeFi data model.

    The function is invoked upon clicking on a "Delete Picture" button (this function is invoked in the HTML code)
    */ {
    const deletePicture0 = async () => {
      console.log('\ndelete picture'); //%//

      const userPictureObjectKey = this.dataModelService.dataModel.persistent.profile.userPictureObjectKey;

      // note: only delete a picture that does not have the objectKey value of pictureNotAvailableObjectKey
      if (userPictureObjectKey !== pictureNotAvailableObjectKey) {
        // initialize
        const bucketName = environment.bucketName;
        const objectKey = userPictureObjectKey;

        // delete picture from Amazon S3
        const result = await this.deleteObject(bucketName, objectKey);
        console.log('deleteObject(' + bucketName + ',' + objectKey + ') -- result: ', result); //%//

        // adjust WizeFi data model to indicate a picture is not available
        this.dataModelService.dataModel.persistent.profile.userPictureObjectKey = pictureNotAvailableObjectKey;
        await this.dataModelService.dataManagement.storeinfo();

        // update wizeFiUserPictureURL for the user picture identified in the WizeFi data model
        this.wizeFiUserPictureURL = this.setWizeFiUserPictureURL(); // enable display of picture referenced in WizeFi data model
      }
    }; // deletePicture0

    deletePicture0()
      .then(() => {
        console.log('finished deletePicture');
      })
      .catch(err => {
        console.log('error in deletePicture: ', err);
      });
  } // deletePicture

  public async testSetURL() /*
    This routine illustrates how to set a bucket URL value.
    */ {
    console.log('\nset URL');
    const region = environment.AWSRegion;
    const bucketName = environment.bucketName;
    const objectKey = 'DaveEland.jpg';

    const virtualURL = this.setVirtualURL(region, bucketName, objectKey);
    console.log('virtualURL: ' + virtualURL);

    const pathURL = this.setPathURL(region, bucketName, objectKey);
    console.log('pathURL: ' + pathURL);

    const pictureNotAvailableURL = this.setPictureNotAvailableURL();
    console.log('pictureNotAvailableURL: ' + pictureNotAvailableURL);

    let wizeFiID: string;
    let wizeFiIDList: string[];
    if (environment.mode == 'demo') {
      wizeFiID = 'zbpizmq';
      wizeFiIDList = ['zbpizmq', 'adwfnoz', 'fvhcnjj'];
    } else {
      wizeFiID = 'cekxtgq';
      wizeFiIDList = ['cekxtgq', 'aglzbbc', 'dvduvrt'];
    }

    const selectedWizeFiUserPictureURL = await this.setSelectedWizeFiUserPictureURL(wizeFiID);
    console.log('selectedWizeFiUserPictureURL: ' + selectedWizeFiUserPictureURL);

    this.wizeFiIDList = wizeFiIDList;
    const pictureURLmap = await this.getPictureURLmap(wizeFiIDList);
    console.log('pictureURLmap: ', pictureURLmap);
    this.pictureURLmap = pictureURLmap;
  } // testSetURL

  public setBucketObjectKeyList() /*
    This routine will make a list of all object key values in a given Amazon S3 bucket.
    */ {
    // initialize
    const bucketObjectKeyList = [];

    // set parameters to guide the listObjectsV2 action
    const params = {
      Bucket: environment.bucketName
    };

    // retrieve the list of objects
    this.dataModelService.dataModel.global.s3.listObjectsV2(params, (err, data) => {
      if (err) {
        console.log('error in setBucketObjectKeyList: ', err);
      } else {
        for (const bucketObject of data.Contents) {
          bucketObjectKeyList.push(bucketObject.Key);
        }
        console.log('bucketObjectKeyList: ', bucketObjectKeyList);
      }
    });
  } // setBucketObjectKeyList

  public runWizeFiDraftsTests() {
    const cloneDataModel = dataModel =>
      /*
        This routine will remove the global data from a clone of the dataModel (for debug purposes).
        */
      {
        const saveGlobal = dataModel.global;
        dataModel.global = null;
        const clonedDataModel = JSON.parse(JSON.stringify(dataModel));
        dataModel.global = saveGlobal;
        return clonedDataModel;
      }; // cloneDataModel

    const runDraftTests = async () => {
      // initialize
      let title, description, draftsInfo;

      const dataModel = this.dataModelService.dataModel;
      const dataManagement = this.dataModelService.dataManagement;
      const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;

      /*
            title = "title1";
            description = "description1";

            // createDraft
            console.log(" ");
            console.log("createDraft");
            console.log("before isDraft: " + dataManagement.isDraft());
            console.log("dataModel: ", cloneDataModel(dataModel));
            await dataManagement.createDraft(title, description,"whatif");
            console.log("after  isDraft: " + dataManagement.isDraft());
            console.log("dataModel: ", cloneDataModel(dataModel));

            // store draft
            console.log(" ");
            console.log("storeDraft");
            await this.dataModelService.draftService.storeDraft();
            await dataManagement.fetchdata();  // put user data (not draft) into dataModel
            console.log("reload user data dataModel: ", cloneDataModel(dataModel));

            // create and store another draft
            await dataManagement.fetchdata();  // put user data (not draft) into dataModel
            await dataManagement.createDraft("title2", "description2","whatif");
            await this.dataModelService.draftService.storeDraft();

            // fetchDraftsInfo
            console.log(" ");
            console.log("fetchDraftsInfo");
            draftsInfo = await dataManagement.fetchDraftsInfo();
            console.log("draftsInfo: ", draftsInfo);

            // create another version of a draft
            await dataManagement.createDraft("title2", "description2","whatif");
            await this.dataModelService.draftService.storeDraft();
            console.log("fetchDraftsInfo");
            draftsInfo = await dataManagement.fetchDraftsInfo();
            console.log("another version of a draft -- draftsInfo: ", draftsInfo);

            // promote draft
            console.log(" ");
            console.log("promoteDraft");
            console.log("before isDraft: " + dataManagement.isDraft());
            console.log("dataModel: ", cloneDataModel(dataModel));
            await this.dataModelService.draftService.promoteDraft();
            console.log("after  isDraft: " + dataManagement.isDraft());
            console.log("dataModel: ", cloneDataModel(dataModel));

            // fetch draft
            console.log(" ");
            console.log("fetchDraft");
            await dataManagement.fetchDraft(title);
            console.log("dataModel: ", cloneDataModel(dataModel));

            // delete draft
            console.log(" ");
            console.log("deleteDraft");
            await dataManagement.deleteDraft(wizeFiID, title);
            draftsInfo = await dataManagement.fetchDraftsInfo();
            console.log("draftsInfo: ", draftsInfo);

            // test fetchinfo and storeinfo
            console.log(" ");
            console.log("test fetchinfo and storeinfo");
            await dataManagement.fetchDraft("title2");  // load a draft into the dataModel
            console.log("fetchinfo");
            await dataManagement.fetchinfo();
            console.log("storeinfo");
            await dataManagement.storeinfo();
            */

      /*
        // test createDraft (including multiple versions of a draft)
        console.log(' ');
        console.log('test createDraft');
        await dataManagement.createDraft("title1","description1","whatif");  // create draft based on user data in dataModel
        await dataManagement.createDraft("title1","description1","whatif");  // create a version of the draft in dataModel
        await dataManagement.createDraft("title1","description1","whatif");  // create another version of the draft in dataModel
        console.log('planInfo: ', dataManagement.fetchPlanInfo());  //%//

        await dataManagement.fetchdata();  // put user data back into dataModel
        await dataManagement.createDraft("title2","description2","whatif");  // create a new draft
        // draftsInfo = await dataManagement.fetchDraftsInfo();
        console.log('draftsInfo: ', this.dataModelService.dataModel.global.draftsInfo);
        */

      /*
            // test retrieval of draftsInfo from WizeFiDraftsInfo DynamoDB table
            console.log("compare draftsInfo results");
            const wizeFiIDList = ["fhqkqyo","hvkojsc","tmwakqm","zbpizmq"];
            for (const wizeFiID of wizeFiIDList)
            {
                const draftsInfo1 = await dataManagement.fetchDraftsInfo();
                console.log(wizeFiID + "  draftsInfo1: ", draftsInfo1);

                // const draftsInfo2 = await dataManagement.fetchDraftsInfo2(wizeFiID);  <==== fetchDraftsInfo2 no longer exists
                // console.log(wizeFiID + "  draftsInfo2: ", draftsInfo2);
            }
            */

      /*
            // createDraft
            console.log(" ");
            console.log("createDraft");
            console.log("before isDraft: " + dataManagement.setIsDraft());
            console.log("dataModel: ", cloneDataModel(dataModel));
            await dataManagement.createDraft("title1", "description1","whatif");

            // store draft
            console.log(" ");
            console.log("storeDraft");
            await this.dataModelService.draftService.storeDraft();
            await dataManagement.fetchdata();  // put user data (not draft) into dataModel
            console.log("reload user data dataModel: ", cloneDataModel(dataModel));
            */

      /*
            // test edit, add, and delete in draftsInfo array; and test storeDraft and deleteDraft and effect on draftsInfo data
            let index, draftInfo, originalDraftsInfo;

            draftsInfo = this.dataModelService.dataModel.global.draftsInfo;
            originalDraftsInfo = JSON.parse(JSON.stringify(draftsInfo));
            console.log("originalDraftsInfo: ", originalDraftsInfo);

            index = dataManagement.findArrayIndex("title1--02", draftsInfo);
            console.log("index of title1--02: " + index);

            index = dataManagement.findArrayIndex("title1---02", draftsInfo);
            console.log("index of title1---02: " + index);

            index = 2;
            draftInfo = JSON.parse(JSON.stringify(draftsInfo[index]));
            draftInfo.description = "description01";
            dataManagement.addDraftInfo(draftInfo);
            console.log("after add of description01 draftsInfo: ", JSON.parse(JSON.stringify(draftsInfo)));

            index = 3;
            draftInfo = JSON.parse(JSON.stringify(draftsInfo[index]));
            draftInfo.title = "title3";
            draftInfo.description = "description3";
            dataManagement.addDraftInfo(draftInfo);
            console.log("after add of test3 draftsInfo: ", JSON.parse(JSON.stringify(draftsInfo)));

            dataManagement.deleteDraftInfo("title1---01");
            console.log("after deleteDraftInfo title1---01: ", JSON.parse(JSON.stringify(draftsInfo)));
            */

      /*
            // test storeDraft and deleteDraft (check for handlinig of global draftsInfo)
            console.log("\ncreateDraft with title3");
            await dataManagement.createDraft("title3", "description3","whatif");
            await this.dataModelService.draftService.storeDraft();
            console.log("add title3 -- draftsInfo: ", JSON.parse(JSON.stringify(this.dataModelService.dataModel.global.draftsInfo)));
            console.log("WizeFiDraftsInfo: ", await dataManagement.fetchDraftsInfo());

            console.log("\ncreateDraft with title4");
            await dataManagement.fetchdata();  // put user data (not draft) into dataModel
            await dataManagement.createDraft("title4", "description4","whatif");
            await this.dataModelService.draftService.storeDraft();
            console.log("add title4 -- draftsInfo: ", JSON.parse(JSON.stringify(this.dataModelService.dataModel.global.draftsInfo)));
            console.log("WizeFiDraftsInfo: ", await dataManagement.fetchDraftsInfo());

            console.log("\ndeleteDraft with title3");
            await dataManagement.deleteDraft(wizeFiID, "title3");
            console.log("delete title3 -- draftsInfo: ", JSON.parse(JSON.stringify(this.dataModelService.dataModel.global.draftsInfo)));
            console.log("WizeFiDraftsInfo: ", await dataManagement.fetchDraftsInfo());

            console.log("\ndeleteDraft with title4");
            await dataManagement.deleteDraft(wizeFiID, "title4");
            console.log("delete title4 -- draftsInfo: ", JSON.parse(JSON.stringify(this.dataModelService.dataModel.global.draftsInfo)));
            console.log("WizeFiDraftsInfo: ", await dataManagement.fetchDraftsInfo());
            */

      /*
        // test management of wizeFiPlaidInstitutions and wizeFiPlaidAccounts for use in drafts
        await dataManagement.createDraft("title5", "description5","whatif");
        await this.dataModelService.draftService.storeDraft();

        await dataManagement.fetchDraft('title5');
        */
    }; // runDraftTests

    /*
      runDraftTests()
        .then(() => {
          console.log('runDraftTests finished');
        })
        .catch((err) => {
          console.log('error in runDraftTests: ', err);
        });
        */

    // this test verifies that the new draftType attribute is processed correctly
    const runDraftTests2 = async () => {
      // initialize
      const dataModel = this.dataModelService.dataModel;
      const dataManagement = this.dataModelService.dataManagement;
      const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;

      // put user data in data model
      await dataManagement.fetchUserData(wizeFiID);

      // create some test drafts
      console.log('\ncreate some test drafts');
      console.log('before creating drafts DynamoDB -- draftsInfo: ', await this.dataModelService.draftService.fetchDraftsInfo());
      console.log('before creating drafts global   -- draftsInfo: ', JSON.parse(JSON.stringify(this.dataModelService.dataModel.global.draftsInfo)));

      await this.dataModelService.draftService.createDraft('title1', 'description1', 'open'); // initial draft
      await this.dataModelService.draftService.createDraft('title1', 'description1a', 'open'); // version draft
      await this.dataModelService.draftService.createDraft('title1', 'description1b', 'open'); // version draft
      await this.dataModelService.draftService.createDraft('title1', 'description1c', 'open'); // version draft

      await dataManagement.fetchdata();

      await this.dataModelService.draftService.createDraft('title2', 'description2', 'versionHistory'); // initial draft
      await this.dataModelService.draftService.createDraft('title2', 'description2a', 'versionHistory'); // version draft
      await this.dataModelService.draftService.createDraft('title2', 'description2b', 'versionHistory'); // version draft

      console.log('after creating drafts DynamoDB -- draftsInfo: ', await this.dataModelService.draftService.fetchDraftsInfo());
      console.log('after creating drafts global   -- draftsInfo: ', JSON.parse(JSON.stringify(this.dataModelService.dataModel.global.draftsInfo)));

      // create a list of all draftTypes that appear in draftsInfo
      const draftTypeList = [];
      const draftsInfo = JSON.parse(JSON.stringify(this.dataModelService.dataModel.global.draftsInfo));
      for (const draftInfo of draftsInfo) {
        const draftType = draftInfo.draftType;
        if (!draftTypeList.includes(draftType)) {
          draftTypeList.push(draftType);
        }
      }
      console.log('draftTypeList: ', draftTypeList);

      //
      // illustrate filter of draftInfo based on draftType
      let filteredDraftsInfo: any;
      filteredDraftsInfo = {};
      for (const draftType of draftTypeList) {
        const filteredDraftsInfo = draftsInfo.filter(draftInfo => draftInfo.draftType == draftType);
        console.log('filteredDraftsInfo: ', filteredDraftsInfo);

        // const filteredAccounts = manualAccounts.filter(account => account.isManual === true);
      }
      //

      // delete all test drafts that were created
      for (const draftInfo of draftsInfo) {
        await this.dataModelService.draftService.deleteDraft(draftInfo.title);
      }
      console.log('after delete drafts DynamoDB -- draftsInfo: ', await this.dataModelService.draftService.fetchDraftsInfo());
      console.log('after delete drafts global   -- draftsInfo: ', JSON.parse(JSON.stringify(this.dataModelService.dataModel.global.draftsInfo)));
    }; // runDraftTests2

    /*
      console.log("runDraftTests2");  //%//
      runDraftTests2()
        .then(() => {
          console.log('runDraftTests2 finished');
        })
        .catch((err) => {
          console.log('error in runDraftTests2: ', err);
        });
      */

    // this test verifies that promotion of a draft is processed correctly
    const runDraftTests3 = async () => {
      // initialize
      const dataModel = this.dataModelService.dataModel;
      const dataManagement = this.dataModelService.dataManagement;
      const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;

      console.log('before creating drafts DynamoDB -- draftsInfo: ', await this.dataModelService.draftService.fetchDraftsInfo());
      console.log('before creating drafts global   -- draftsInfo: ', JSON.parse(JSON.stringify(this.dataModelService.dataModel.global.draftsInfo)));

      // put user data in data model
      await dataManagement.fetchUserData(wizeFiID);

      // report original value of retirementAge
      const originalRetirementAge = dataModel.persistent.profile.retirementAge;
      console.log('retirementAge -- original value in user data: ' + dataModel.persistent.profile.retirementAge);

      // create a new draft to promote
      await this.dataModelService.draftService.createDraft('Test Promote', 'Test promote of a draft', 'open');

      // report retirementAge after change in draft to promote
      const newRetirementAge = 66;
      dataModel.persistent.profile.retirementAge = newRetirementAge;
      console.log('retirementAge -- new value in draft to promote: ' + dataModel.persistent.profile.retirementAge);

      // promote that draft
      await this.dataModelService.draftService.promoteDraft();

      // report retirementAge with new value in user data that was promoted
      console.log('retirementAge -- new value in user data that was promoted: ' + dataModel.persistent.profile.retirementAge);

      console.log('after  creating drafts DynamoDB -- draftsInfo: ', await this.dataModelService.draftService.fetchDraftsInfo());
      console.log('after  creating drafts global   -- draftsInfo: ', JSON.parse(JSON.stringify(this.dataModelService.dataModel.global.draftsInfo)));

      // create a list of all draftTypes that appear in draftsInfo
      const draftTypeList = [];
      const draftsInfo = JSON.parse(JSON.stringify(this.dataModelService.dataModel.global.draftsInfo));
      for (const draftInfo of draftsInfo) {
        const draftType = draftInfo.draftType;
        if (!draftTypeList.includes(draftType)) {
          draftTypeList.push(draftType);
        }
      }
      console.log('draftTypeList: ', draftTypeList);

      // restore retirementAge to original value before this test
      dataModel.persistent.profile.retirementAge = originalRetirementAge;
      await dataManagement.storedata();

      // delete all drafts that were created
      for (const draftInfo of draftsInfo) {
        await this.dataModelService.draftService.deleteDraft(draftInfo.title);
      }

      console.log('after  deleting drafts DynamoDB -- draftsInfo: ', await this.dataModelService.draftService.fetchDraftsInfo());
      console.log('after  deleting drafts global   -- draftsInfo: ', JSON.parse(JSON.stringify(this.dataModelService.dataModel.global.draftsInfo)));
    }; // runDraftTests3

    //
    console.log('runDraftTests3'); //%//
    runDraftTests3()
      .then(() => {
        console.log('runDraftTests3 finished');
      })
      .catch(err => {
        console.log('error in runDraftTests3: ', err);
      });
    //
  } // runWizeFiDraftsTests
} // class AdminRunTestsComponent
