import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { MatCardModule } from '@angular/material';
import { RouterModule } from '@angular/router';

import { LOCALE_ID } from '@angular/core';
import localeENUS from '@angular/common/locales/en-US-POSIX';
import { registerLocaleData } from '@angular/common';
import { CardComponent } from './card/card.component';
import { CurrencyInputFormatDirective } from './directives/currency-input-format.directive';
import { EmptyStateComponent } from './empty-state/empty-state.component';

registerLocaleData(localeENUS);
@NgModule({
  declarations: [EmptyStateComponent, CurrencyInputFormatDirective, CardComponent],
  imports: [CommonModule, MatCardModule, RouterModule],
  exports: [EmptyStateComponent, CurrencyInputFormatDirective, CardComponent],
  entryComponents: [],
  providers: [
    CurrencyPipe,
    {
      provide: LOCALE_ID,
      useValue: 'en-US'
    }
  ]
})
export class SharedModule {}
