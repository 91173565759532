import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { CProfile } from '../../../utilities/profile.class';
import { ReportBugComponent } from './report-bug/report-bug.component';
import { SuggestFeatureComponent } from './suggest-feature/suggest-feature.component';

@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html',
  styleUrls: ['./change-log.component.scss']
})
export class ChangeLogComponent implements OnInit {
  public cProfile: CProfile;

  constructor(public dataModelService: DataModelService, protected router: Router, private dialog: MatDialog) {}

  public ngOnInit() {
    this.cProfile = new CProfile(this.dataModelService.getdata('profile'));
  }

  public openReportBugDialog() {
    this.dialog.open(ReportBugComponent);
  }

  public openFeatureSuggestionDialog(): void {
    this.dialog.open(SuggestFeatureComponent);
  }
}
