import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-verify-spending-complete',
  templateUrl: './verify-spending-complete.component.html',
  styleUrls: ['./verify-spending-complete.component.scss']
})
export class VerifySpendingCompleteComponent implements OnInit {
  public isUpdating = false;

  constructor(protected router: Router) {}

  public ngOnInit() {}

  public onComplete() {
    this.router.navigate(['/setup-step-menu'], { queryParams: { step: 5 } });
  }
}
