import { Component } from '@angular/core';
import { AccountTypeService } from 'src/app/services/account-type/account-type.service';
import { AccountService } from 'src/app/services/account/account.service';
import { DataModelService } from '../../../../services/data-model/data-model.service';
import { AccountDetailsComponent } from '../account-details/account-details.component';

@Component({
  selector: 'app-personal-property-account-details',
  templateUrl: './personal-property-account-details.component.html',
  styleUrls: ['../account-details-template/account-details-template.component.scss']
})
export class PersonalPropertyAccountDetailsComponent extends AccountDetailsComponent {
  protected isNonProductiveAsset = true; // determines the direction of acccount value change for the asset
  protected bal: number;
  public ngOnInit() {
    super.ngOnInit();
    this.bal = (this.wizeFiAccount && this.wizeFiAccount.accountValue && this.wizeFiAccount.accountValue.val) || this.plaidAccount.balance || 0;
  }

  constructor(public dataModelService: DataModelService, accountTypeService: AccountTypeService, accountService: AccountService) {
    super(dataModelService, accountTypeService, accountService);
  }

  protected onRateChange(value: string) {
    this.wizeFiAccount.interestRate.val = parseInt(value, 10) || 0;
  }

  public onBalanceChange(value: string) {
    if (this.wizeFiAccount && this.wizeFiAccount.accountValue) {
      this.wizeFiAccount.accountValue.val = parseFloat(value) || 0;
    }
    this.plaidAccount.balance = parseFloat(value) || 0;
  }
}
