import { Component, ViewChild, OnInit } from '@angular/core';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { SetupFlowDataManagementService, SetupFlowScreen } from '../../../services/setup/setup-flow-data-management.service';
import { FinancialFreedomDetailsComponent } from '../financial-freedom-details/financial-freedom-details.component';
import { ProfileLightComponent } from '../profile-light/profile-light.component';
import { SetupService } from '@app/services/setup.service';

@Component({
  selector: 'app-goals-screen',
  templateUrl: './goals-screen.component.html',
  styleUrls: ['./goals-screen.component.scss']
})
export class GoalsScreenComponent implements SetupFlowScreen, OnInit {
  @ViewChild(FinancialFreedomDetailsComponent, { static: false })
  public financialFreedomDetails: FinancialFreedomDetailsComponent;
  @ViewChild(ProfileLightComponent, { static: false })
  public profileLight: ProfileLightComponent;

  constructor(
    protected dataModelService: DataModelService,
    protected dataManagement: SetupFlowDataManagementService,
    private setupService: SetupService
  ) {
    this.dataManagement.currentSetupScreen = this;
  }

  public async ngOnInit() {
    this.setupService.updateCurrentSetupStep(2);
  }

  public canSave(): boolean {
    return this.financialFreedomDetails.canSave() && this.profileLight.canSave();
  }

  public async onSave() {
    this.financialFreedomDetails.onSave();
    this.profileLight.onSave();
  }

  public showOnSaveErrorMessage(): void {
    if (this.financialFreedomDetails.canSave() === false) {
      this.dataModelService.showErrorMessage(
        'Please enter a Desired Monthly Income at Retirement, Inflation Rate, and Investment Rate of Return',
        20000
      );
    } else {
      this.dataModelService.showErrorMessage('Please enter a date of birth before continuing', 20000);
    }
  }
}
