import { Component } from '@angular/core';
import { DataModelService } from 'src/app/services/data-model/data-model.service';
import { AccountTypeService } from 'src/app/services/account-type/account-type.service';
import { AccountService } from 'src/app/services/account/account.service';
import { AccountDetailsComponent } from '../account-details/account-details.component';

@Component({
  selector: 'app-emergency-savings-account-details',
  templateUrl: './emergency-savings-account-details.component.html',
  styleUrls: ['../account-details-template/account-details-template.component.scss']
})
export class EmergencySavingsAccountDetailsComponent extends AccountDetailsComponent {
  public productivityList = ['Productive', 'Non-productive', 'Limited'];

  protected bal: number;

  constructor(public dataModelService: DataModelService, accountTypeService: AccountTypeService, accountService: AccountService) {
    super(dataModelService, accountTypeService, accountService);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.bal = (this.wizeFiAccount && this.wizeFiAccount.accountValue && this.wizeFiAccount.accountValue.val) || this.plaidAccount.balance || 0;
  }
  public setIsActive() {
    this.isActive = !this.isActive;
  }
  protected onYieldChange(value: string) {
    this.wizeFiAccount.interestRate.val = parseFloat(value) || 0;
  }

  protected onOutsideContributionChange(value: string) {
    this.wizeFiAccount.employerContribution.val = parseFloat(value) || 0;
  }

  protected onYourContributionChange(value: string) {
    this.wizeFiAccount.monthlyAmount.val = parseFloat(value) || 0;
  }

  public onBalanceChange(value: string) {
    if (this.wizeFiAccount && this.wizeFiAccount.accountValue) {
      this.wizeFiAccount.accountValue.val = parseFloat(value) || 0;
    }
    this.plaidAccount.balance = parseFloat(value) || 0;
  }
}
