import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogClose } from '@angular/material';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart } from '@angular/router';
import fileUploadService from 'src/app/services/fileupload.service';
import { SetupStepMenuComponent } from '../../components/setup-flow/setup-step-menu/setup-step-menu.component';
import { DataModelService } from '../../services/data-model/data-model.service';
import { SetupFlowDataManagementService } from '../../services/setup/setup-flow-data-management.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public name: string;
  public menuOptions: any;
  public iconname: string;
  public iconsplit: any;
  protected wizeFiPicture: any;
  activeSettings = false;
  menuFounded = [];

  constructor(
    public router: Router,
    protected dataModelService: DataModelService,
    protected setupFlowDataManagement: SetupFlowDataManagementService,
    protected route: ActivatedRoute,
    private dialog: MatDialog,
    public profilePictureService: fileUploadService
  ) {
    router.events.subscribe((val: NavigationStart) => {
      // see also
      if (val.url) {
        const menu = this.menuOptions.filter(menu => {
          const url = val.url.split('#')[0];
          if (menu.click.includes(url.replace('/', ''))) {
            return menu;
          }
        });
        this.menuFounded = menu;
      }
    });

    this.menuOptions = [
      {
        base_route: 'dashboard',
        click: 'dashboard',
        icon: 'icon_dashboard',
        title: 'Dashboard'
      },
      {
        base_route: 'plan',
        click: 'plan#expenses',
        icon: 'icon_plan',
        title: 'Plan'
      },
      {
        base_route: 'accounts',
        click: 'accounts#all',
        icon: 'icon_accounts',
        title: 'Accounts'
      },
      {
        base_route: 'transactions',
        click: 'transactions',
        icon: 'icon_transactions',
        title: 'Transactions'
      },
      /*
      {
        base_route: 'dashboard',
        click: '',
        icon: 'icon_affiliates',
        title: 'Affiliates'
      },
      */
      {
        base_route: 'intercom',
        click: 'showIntercom',
        icon: 'icon_help',
        title: 'Help'
      }
    ];
  }

  protected switchRoute(route: string) {
    return this.router.navigateByUrl(route);
  }

  protected isLoggedIn() {
    return this.dataModelService.dataModel.global.isLoggedIn;
  }

  protected hasLoadedPlaidData() {
    const plaidData = this.dataModelService.dataModel.global.plaidData;
    return plaidData && Object.keys(plaidData).length !== 0;
  }

  protected isOnSetup() {
    const isSetup = new SetupStepMenuComponent(
      this.router,
      this.route,
      this.setupFlowDataManagement,
      this.dataModelService,
      this.dialog
    ).detectSetupScreen();
    return isSetup;
  }

  public ngOnInit() {
    // console.log(this.isLoggedIn());
    this.setMenu();
  }

  setMenu() {
    setTimeout(() => {
      if (!this.menuFounded.length) {
        this.setMenu();
        this.wizeFiPicture = this.profilePictureService.setWizeFiUserPictureURL();
      } else {
        this.optionClick(this.menuFounded[0]);
        this.wizeFiPicture = this.profilePictureService.setWizeFiUserPictureURL();
      }
    }, 1000);
  }

  protected getName(): string {
    const profile = this.dataModelService.dataModel.persistent.profile;
    this.name = profile.nameFirst + ' ' + profile.nameLast;
    return this.name;
  }

  public optionClick(option) {
    this.activeSettings = false;
    if (option.click === 'showIntercom') {
      this.showIntercom();
      return;
    }
    this.clearActive();
    this.switchRoute(option.click);
  }

  public settingsOption() {
    this.activeSettings = true;
    this.clearActive();
    this.switchRoute('settings');
  }

  public clearActive() {
    this.menuOptions.forEach(element => {
      this.iconname = element.icon;
      this.iconsplit = this.iconname.split('-');
      if (this.iconsplit.length > 1) {
        element.icon = this.iconsplit[0];
      }
    });
  }

  public showIntercom() {
    (window as any).Intercom('show');
  }

  imageInputChange(fileInputEvent: any) {
    console.log(fileInputEvent.target.files[0]);
    this.profilePictureService.uploadPicture(fileInputEvent);
    console.log(this.dataModelService.dataModel.persistent.profile);
    // this.dataModelService.dataModel.persistent.profile.picture = fileInputEvent.target.files[0];
    // this.dataModelService.putdata("profile", this.dataModelService.dataModel.persistent.profile);
  }
}
