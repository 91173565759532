import { Component, OnInit } from '@angular/core';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { LogMessageService } from '../../../services/log-message/log-message.service';

declare const AWS: any;

@Component({
  selector: 'app-admin-view-error-log',
  templateUrl: './admin-view-error-log.component.html',
  styleUrls: ['./admin-view-error-log.component.css']
})
export class AdminViewErrorLogComponent implements OnInit {
  public wizeFiID = '';
  public startDate = '';
  public endDate = '';
  public messageContains = '';
  public logData = '';
  public logSummary: any = {};

  constructor(public dataModelService: DataModelService, public logMessageService: LogMessageService) {}

  public ngOnInit() {} // ngOnInit

  public queryLogData() {
    return new Promise((resolve, reject) => {
      let params: any;

      params = {};
      params.ExpressionAttributeValues = {};
      params.TableName = 'ErrorLog';
      if (this.startDate === '') {
        params.KeyConditionExpression = 'wizeFiID = :wizeFiID';
        params.ExpressionAttributeValues[':wizeFiID'] = this.wizeFiID;
      } else {
        params.KeyConditionExpression = 'wizeFiID = :wizeFiID AND errtimestamp BETWEEN :startDate AND :endDate';
        params.ExpressionAttributeValues[':wizeFiID'] = this.wizeFiID;
        params.ExpressionAttributeValues[':startDate'] = this.startDate;
        params.ExpressionAttributeValues[':endDate'] = this.endDate;
      }

      const docClient = new AWS.DynamoDB.DocumentClient();
      docClient.query(params, (err, data) => {
        if (err) {
          reject(this.logMessageService.makeErrorObject('AdminViewErrorLogComponent.queryLogData', err));
        } else {
          const items = data.Items;
          if (items.length > 0) {
            for (const item of items) {
              const haveMessageContains = this.messageContains !== '';
              const haveMessageMatch = haveMessageContains && item.message.toLowerCase().indexOf(this.messageContains.toLowerCase()) !== -1;
              const wantData = !haveMessageContains || (haveMessageContains && haveMessageMatch);

              if (wantData) {
                this.logData += item.errtimestamp + ' ' + item.wizeFiID + ' ' + item.message + '\n' + item.stack + '\n\n';
              }
            }
          }
          resolve();
        }
      });
    }); // return Promise
  } // queryLogData

  public scanLogData() {
    return new Promise((resolve, reject) => {
      const params = {
        TableName: 'ErrorLog'
      };

      const getAllItemsExecute = callback => {
        const docClient = new AWS.DynamoDB.DocumentClient();
        docClient.scan(params, (err, data) => {
          if (err) {
            callback(this.logMessageService.makeErrorObject('AdminViewErrorLogComponent.scanLogData.getAllItemsExecute', err));
          } else {
            const items = data.Items;
            if (items.length > 0) {
              for (const item of items) {
                const haveStartDate = this.startDate !== '';
                const haveMessageContains = this.messageContains !== '';
                const haveDateRangeMatch = haveStartDate && item.errtimestamp >= this.startDate && item.errtimestamp <= this.endDate;
                const haveMessageMatch = haveMessageContains && item.message.toLowerCase().indexOf(this.messageContains.toLowerCase()) !== -1;

                const wantData =
                  (!haveStartDate && !haveMessageContains) ||
                  (haveStartDate && !haveMessageContains && haveDateRangeMatch) ||
                  (!haveStartDate && haveMessageContains && haveMessageMatch) ||
                  (haveStartDate && haveDateRangeMatch && haveMessageContains && haveMessageMatch);

                if (wantData) {
                  this.logData += item.errtimestamp + ' ' + item.wizeFiID + ' ' + item.message + '\n' + item.stack + '\n\n';
                }
              }
            }

            // determine whether all items have been processed
            if (data.LastEvaluatedKey) {
              getAllItemsExecute(callback);
            } else {
              callback(null, '');
            }
          }
        });
      }; // getAllItemsExecute

      // invoke recursive function that does the work
      getAllItemsExecute((err, logResults) => {
        // this is the callback function
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      }); // invoke getAllItemsExecute
    }); // return Promise
  } // scanLogData

  public retrieveLogData() {
    if (this.wizeFiID !== '') {
      return this.queryLogData();
    } else {
      return this.scanLogData();
    }
  } // retrieveLogData

  public viewErrorLog() {
    this.logData = '';
    this.logData += 'timestamp  wizeFiID  message  stack\n\n';

    this.retrieveLogData()
      .then(() => {
        this.logData += 'end of log\n';
      })
      .catch(err => {
        this.logMessageService.reportError(err.message, err.stack);
      });
  } // viewErrorLog

  public obtainLogSummary() {
    return new Promise((resolve, reject) => {
      const params = {
        TableName: 'ErrorLog'
      };

      const getAllItemsExecute = callback => {
        const docClient = new AWS.DynamoDB.DocumentClient();
        docClient.scan(params, (err, data) => {
          if (err) {
            callback(this.logMessageService.makeErrorObject('AdminViewErrorLogComponent.scanLogData.getAllItemsExecute', err));
          } else {
            const items = data.Items;
            if (items.length > 0) {
              for (const item of items) {
                const monthDay = item.errtimestamp.substr(0, 10);
                if (!this.logSummary.hasOwnProperty(monthDay)) {
                  this.logSummary[monthDay] = 0;
                }
                this.logSummary[monthDay]++;
              }
            }

            // determine whether all items have been processed
            if (data.LastEvaluatedKey) {
              getAllItemsExecute(callback);
            } else {
              callback(null, '');
            }
          }
        });
      }; // getAllItemsExecute

      // invoke recursive function that does the work
      getAllItemsExecute((err, logResults) => {
        // this is the callback function
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      }); // invoke getAllItemsExecute
    }); // return Promise
  } // obtainLogSummary

  public showLogSummary() {
    const lpad = (num, len) => {
      let str = num.toString();
      while (str.length < len) {
        str = ' ' + str;
      }
      return str;
    }; // lpad

    return new Promise((resolve, reject) => {
      // get list of summary data sorted by date
      const keyList = Object.keys(this.logSummary).sort();

      // show the list on the screen
      for (const monthDay of keyList) {
        this.logData += monthDay + '   ' + lpad(this.logSummary[monthDay], 6) + '\n';
      }
      resolve();
    }); // return Promise
  } // showLogSummary

  public viewErrorLogSummary() {
    this.logSummary = {};
    this.logData = '';
    this.logData += 'date          count\n\n';

    this.obtainLogSummary()
      .then(this.showLogSummary.bind(this)) // <====  note notation for getting proper value of "this" in showLogSummary function
      .then(() => {
        this.logData += '\nend of log\n';
      })
      .catch(err => {
        this.logMessageService.reportError(err.message, err.stack);
      });
  } // viewErrorLog
} // class AdminViewErrorLogComponent
