import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DataModelService } from '../../services/data-model/data-model.service';

declare const AWS: any;

@Component({
  selector: 'app-validate-email',
  templateUrl: './validate-email.component.html',
  styleUrls: ['./validate-email.component.scss']
})
export class ValidateEmailComponent implements OnInit {
  public vcode: string;
  public loading = false;
  public screen = 'validate';
  public body = document.getElementsByTagName('body')[0];
  public email: string;
  public password: string;
  public username: string;
  public route: ActivatedRoute;

  constructor(public router: Router, public dataModelService: DataModelService, public activeRoute: ActivatedRoute) {
    this.route = activeRoute;
  }

  public ngOnInit() {
    this.loading = false;
    // fill out form with get params
    if (this.route) {
      this.route.queryParams.subscribe((params: Params) => {
        this.username = params.u;
        this.vcode = params.v;
      });
    }

    if (this.email == undefined) {
      this.email = window.localStorage.getItem('email');
    }

    if (this.username == undefined) {
      this.username = window.localStorage.getItem('username');
    }

    if (this.username && this.vcode) {
      this.confirm();
    }

    while (this.body.classList.length > 0) {
      this.body.classList.remove(this.body.classList.item(0));
    }
    this.body.classList.add('verify');
  }

  public confirm = (): void => {
    this.loading = true;
    // console.log(this.username, this.vcode);
    if (this.vcode) {
      const verifiedCode = this.vcode.trim();
      this.dataModelService.loginManagement
        .confirmUser(this.username, verifiedCode)
        .then(() => {
          this.dataModelService.dataModel.persistent.profile.email = this.email;
        })
        .then(() => {
          console.log('storing profile in the data model');
          this.dataModelService.putdata('profile', this.dataModelService.dataModel.persistent.profile);
        })
        .then(() => {
          console.log(this.dataModelService.dataModel.persistent.profile);
        })
        .then(response => {
          // console.log("confirmUser response", response);
          this.dataModelService.showMessage('success', 'Email confirmed!', 3000);
          this.router.navigateByUrl('/login');
        })
        .catch(err => {
          this.dataModelService.loginManagement.handleError(err);
        });
    } else {
      this.dataModelService.showMessage('error', 'Please check your email and input the verification code', 3000);
    }
  }; // register

  public resendCode = (): void => {
    this.loading = true;

    this.dataModelService.loginManagement
      .resendConfirmationCode(this.username)
      .then(response => {
        // console.log("resendConfirmationCode response", response);
        this.dataModelService.showMessage('success', 'New code sent, please check your email: ' + this.email, 3000);
        this.loading = false;
      }) // Probably not needed
      .catch(err => {
        this.dataModelService.loginManagement.handleError(err);
      });
  }; // resendCode
}
