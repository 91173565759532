import { Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ProfileService } from 'src/app/services/profile/profile.service';
import { TransactionRuleService } from 'src/app/services/transaction-rule/transaction-rule.service';
import { IBudget } from '../../../interfaces/iBudget.interface';
import { WizefiTransaction } from '../../../interfaces/wizefi-transaction';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { BudgetCategoryComponent } from '../budget-category/budget-category.component';
import { CurrentExpensesCategoryComponent } from '../current-expenses-category/current-expenses-category.component';
import { UncategorizedTransactionsComponent } from '../uncategorized-transactions/uncategorized-transactions.component';

@Component({
  selector: 'app-verify-spending',
  templateUrl: './verify-spending.component.html',
  styleUrls: ['./verify-spending.component.scss']
})
export class VerifySpendingComponent implements OnInit {
  @Output() public displaySideComponent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChildren('budgetCategory') public budgetCategoryComponents: QueryList<BudgetCategoryComponent>;
  @ViewChildren('currentExpensesCategory') public currentExpensesCategoryComponents: QueryList<CurrentExpensesCategoryComponent>;
  @ViewChild(UncategorizedTransactionsComponent, { static: true })
  public uncategorizedTransactions: UncategorizedTransactionsComponent;
  public uncategorizedTransactionsList: any;
  public typeOfSpendingToDisplay: 'Spent' | 'Remaining' = 'Remaining';
  public budget: IBudget;
  public categoryKeys: string[];
  public transactions: WizefiTransaction[];
  public totalActualSpending: number;
  public totalPlannedSpending: number;
  public totalLastMonthSpending: number;
  public totalVerifiedSpending: number;
  public isLoading: boolean;
  public isUpdating = false;
  public grossMonthlyIncome: number;
  public deductionsTotal: number;
  protected profileService: ProfileService;
  protected isInSetup: boolean;
  protected transactionsYearMonth: string;
  protected origTransactionsYearMonth = moment(new Date()).subtract(1, 'month').toISOString().substr(0, 7);

  public allrules: any[];
  public allTransactionRules: any;
  public allTransactions: any;
  public selectedMonth = new Date();
  public excludedTransactions: any;
  public selectedTransactions: any[];
  public screen: string;
  public categorizedTransactions: any;
  public rulesTransactions: any;
  protected selectedWizeFiCategory: string;

  constructor(protected dataModelService: DataModelService, protected router: Router, public transactionRuleService: TransactionRuleService) {
    this.setTransactionsYearMonth();
    this.isLoading = false;
    this.loadData();
    this.profileService = new ProfileService(dataModelService);
  }

  public async ngOnInit() {}

  public loadTransactions(alltransactions = null) {
    if (alltransactions === null) {
      this.allTransactions = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection[this.formatDateToTransactionsMapKey()];
      this.getCategorizedTransactions();
      this.getUncategorizedTransactions();
      this.getExcludedTransactions();
      this.getRulesTransactions();
    } else {
      this.getCategorizedTransactions(false);
      this.getUncategorizedTransactions(false);
      this.getExcludedTransactions(false);
      this.getRulesTransactions(false);
    }
  }

  private formatDateToTransactionsMapKey(): string {
    return this.selectedMonth.toISOString().substr(0, 7);
  }

  private getCategorizedTransactions(getFromModel = true) {
    let transactions;
    if (getFromModel) {
      transactions = this.getSelectedMonthTransactions();
    } else {
      transactions = this.allTransactions;
    }

    this.categorizedTransactions = transactions.filter(
      transaction => transaction.wizeFiCategory.indexOf('unknown') === -1 && transaction.wizeFiCategory !== 'ignore' && transaction.splitStatus === 0
    );
    if (this.isLoading) {
      this.isLoading = false;
    }
    return this.categorizedTransactions;
  }

  private getSelectedMonthTransactions(): WizefiTransaction[] {
    let curTransactions = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection[this.formatDateToTransactionsMapKey()];

    curTransactions = curTransactions ? curTransactions : [];

    return curTransactions.sort((leftSide, rightSide) => {
      if (leftSide.date < rightSide.date) {
        return -1;
      }
      if (leftSide.date > rightSide.date) {
        return 1;
      }
      return 0;
    });
  }

  private getUncategorizedTransactions(getFromModel = true) {
    let transactions;
    if (getFromModel === true) {
      transactions = this.getSelectedMonthTransactions();
    } else {
      transactions = this.allTransactions;
    }
    this.uncategorizedTransactionsList = transactions.filter(transaction => transaction.wizeFiCategory.indexOf('unknown') !== -1);

    return this.uncategorizedTransactionsList;
  }

  private getExcludedTransactions(getFromModel = true) {
    let transactions;
    if (getFromModel === true) {
      transactions = this.getSelectedMonthTransactions();
    } else {
      transactions = this.allTransactions;
    }
    this.excludedTransactions = transactions.filter(transaction => transaction.wizeFiCategory === 'ignore');

    return this.excludedTransactions;
  }

  private getRulesTransactions(getFromModel = true) {
    let transactions;
    if (getFromModel === true) {
      transactions = this.getSelectedMonthTransactions();
    } else {
      transactions = this.allTransactions;
    }
    this.rulesTransactions = transactions.filter(transaction => transaction.ruleApplied === true);

    return this.rulesTransactions;
  }

  private setTransactionsYearMonth() {
    const header = this.dataModelService.dataModel.persistent.header;
    this.isInSetup = !header.dateOrigPlanCompleted || this.router.url.includes('setup/spending');
    // console.log("verifySpending isInSetup", this.isInSetup);
    this.transactionsYearMonth = this.isInSetup ? this.origTransactionsYearMonth : this.dataModelService.dataModel.persistent.header.curplanYearMonth;
  }

  public hasAnyUncategorizedTransactions(): boolean {
    return this.uncategorizedTransactions.uncategorizedTransactionsLength() > 0;
  }

  public _notifyParent(wizeFiCategory) {
    /* console.log(
      "verifySpending notifyParent -- wizeFiCategory",
      wizeFiCategory
    ); */
    this.selectedWizeFiCategory = wizeFiCategory;
    this.displaySideComponent.emit({ wizeFiCategory, transactionsYearMonth: this.transactionsYearMonth });
  }

  public updateBudgetCategorySum() {
    // todo: optimize this call to only call ngOnInit() for the appropriate list item that
    // corresponds to the transaction
    this.budgetCategoryComponents.forEach(component => component.ngOnInit());
    this.currentExpensesCategoryComponents.forEach(component => component.ngOnInit());
    setTimeout(() => {
      this.totalActualSpending = this.getTotalActualSpending();
      this.totalPlannedSpending = this.getTotalPlannedSpending();
      this.totalLastMonthSpending = this.getTotalLastMonthSpending();
      this.totalVerifiedSpending = this.getTotalVerifiedSpending();
    }, 120);

    if (this.selectedWizeFiCategory) {
      this.displaySideComponent.emit({ wizeFiCategory: this.selectedWizeFiCategory, transactionsYearMonth: this.transactionsYearMonth });
    }
  }

  // Private

  private async loadData() {
    this.isUpdating = true;
    try {
      // console.log("get plaid data");
      await this.dataModelService.plaidManagement.getPlaidData();
    } catch (err) {
      this.isLoading = false;
      // console.log(err);
      alert(err); // TypeError: failed to fetch
    }
    try {
      // console.log("get budget data");

      this.budget = await this.dataModelService.getdata('budget');
    } catch (err) {
      this.budget = {
        clothing: { label: '', accounts: [] },
        entertainment: { label: '', accounts: [] },
        food: { label: '', accounts: [] },
        giving: { label: '', accounts: [] },
        health: { label: '', accounts: [] },
        housing: { label: '', accounts: [] },
        transportation: { label: '', accounts: [] }
      };
      this.isUpdating = false;
      this.isLoading = false;
      // console.log(err);
      alert(err); // TypeError: failed to fetch
    }
    try {
      // console.log("get budget category data");

      this.categoryKeys = await this.getBudgetCategories();
    } catch (err) {
      this.isUpdating = false;
      this.isLoading = false;
      // console.log(err);

      alert(err); // TypeError: failed to fetch
    }
    try {
      // console.log("get transaction data");

      this.transactions = await this.getTransactions();
    } catch (err) {
      this.isUpdating = false;
      this.isLoading = false;
      alert(err); // TypeError: failed to fetch
    }
    setTimeout(() => {
      this.totalActualSpending = this.getTotalActualSpending();
      this.totalPlannedSpending = this.getTotalPlannedSpending();
      this.totalLastMonthSpending = this.getTotalLastMonthSpending();
      this.totalVerifiedSpending = this.getTotalVerifiedSpending();
    });
  }

  private getBudgetCategories(): string[] {
    return Object.keys(this.budget).filter(key => key !== 'attributeName' && key !== 'label');
  }

  private async updateWizeFiTransactions() {
    const transactionsCollection = await this.getUpdatedWizeFiTransactions();
    await this.saveUpdatedWizeFiTransactions(transactionsCollection);
  }

  private async getTransactions() {
    await this.updateWizeFiTransactions().catch(err => {
      console.log(err);
    });
    // await console.log("update first!");
    const transactionsCollection = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection;
    const allTransactions = this.dataModelService.dataManagement.getTransactionsFromCollection(transactionsCollection);
    return allTransactions;
  }

  private async getUpdatedWizeFiTransactions() {
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    const wizeFiPlaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    const activeWizeFiPlaidAccountIds = this.dataModelService.plaidManagement.setActiveWizeFiPlaidAccountIds(wizeFiPlaidAccounts);
    const title = this.dataModelService.dataManagement.getDraftTitle();
    const transactionInfo = await this.dataModelService.plaidManagement
      .getTransactions(wizeFiID, title, { wantMonthRange: true, yearMonth: this.transactionsYearMonth }, activeWizeFiPlaidAccountIds)
      .catch(err => {
        this.dataModelService.showMessage('error', 'Cannot retrieve all transactions at this time.', 500000);
        this.isLoading = false;
        console.log(err);
      });

    const wizeFiTransactionsCollection = await this.dataModelService.dataManagement
      .getwizeFiTransactionsCollection(wizeFiID, this.transactionsYearMonth, this.transactionsYearMonth)
      .catch(err => {
        this.dataModelService.showMessage('error', 'Cannot retrieve all transactions at this time.', 500000);
        console.log(err);
      });
    if (transactionInfo) {
      for (const transaction of transactionInfo.transactions) {
        if (this.isNewPlaidTransaction(transaction, wizeFiTransactionsCollection[this.transactionsYearMonth])) {
          delete transaction.category_id;
          transaction.isManual = false;
          transaction.wizeFiCategory = 'unknown';
          transaction.splitStatus = 0;
          const plaidAccount = (wizeFiPlaidAccounts as any[]).find(account => transaction.account_id === account.account_id);
          if (plaidAccount && plaidAccount.isActive) {
            wizeFiTransactionsCollection[this.transactionsYearMonth].push(transaction);
          }
        }
      }
    }

    return wizeFiTransactionsCollection;
  }

  private isNewPlaidTransaction(transaction, wizeFiTransactions) {
    let isNew = true;
    let i = -1;
    while (++i < wizeFiTransactions.length && isNew) {
      if (transaction.transaction_id === wizeFiTransactions[i].transaction_id) {
        isNew = false;
      }
    }
    return isNew;
  }

  public closeAllOtherAccordians(event) {
    this.budgetCategoryComponents.map(e => {
      if (event.component != e) {
        e.toggleDropdown(false, false);
      }
    });
    this.currentExpensesCategoryComponents.map(e => {
      if (event.component != e) {
        e.toggleDropdown(false, false);
      }
    });
  }

  protected getTotalActualSpending(): number {
    return this.profileService.getRemainingSpendingSum().toFixed(2);
  }

  protected getTotalPlannedSpending(): number {
    return this.profileService.getPlannedSpendingSum().toFixed(2);
  }

  protected getTotalLastMonthSpending(): number {
    const spending =
      (this.budgetCategoryComponents &&
        this.budgetCategoryComponents.length &&
        this.budgetCategoryComponents.reduce((total, component) => total + component.getLastMonthCategorySum(), 0)) ||
      0;
    return spending;
  }

  protected getTotalVerifiedSpending(): number {
    const spending =
      (this.budgetCategoryComponents &&
        this.budgetCategoryComponents.length &&
        this.budgetCategoryComponents.reduce((total, component) => total + component.getCategorySum(), 0)) ||
      0;
    return spending;
  }

  private async saveUpdatedWizeFiTransactions(transactionCollection) {
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    await this.dataModelService.dataManagement.putWizeFiTransactions(
      wizeFiID,
      this.transactionsYearMonth,
      transactionCollection[this.transactionsYearMonth].filter(transaction => transaction.date.includes(this.transactionsYearMonth))
    );
    await this.dataModelService.plaidManagement.getPlaidData();
  }

  // dev utilities to reset transactions during development

  private async uncategorizeTransactions(transactionCollection) {
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    transactionCollection[this.transactionsYearMonth] = transactionCollection[this.transactionsYearMonth].map(transaction => ({
      ...transaction,
      wizeFiCategory: 'unknown'
    }));
    await this.dataModelService.dataManagement.putWizeFiTransactions(
      wizeFiID,
      this.transactionsYearMonth,
      transactionCollection[this.transactionsYearMonth]
    );
  }

  private async removeTransactions() {
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    await this.dataModelService.dataManagement.putWizeFiTransactions(wizeFiID, this.transactionsYearMonth, []);
    await this.dataModelService.plaidManagement.getPlaidData();
    /* console.log(
      "data after resetting transaction collections:",
      this.dataModelService.dataModel.global.plaidData
    ); */
  }

  protected toggleTypeShowing() {
    this.typeOfSpendingToDisplay = this.typeOfSpendingToDisplay === 'Spent' ? 'Remaining' : 'Spent';
    this.budgetCategoryComponents.forEach(component => component.toggleTypeShowing());
  }

  public ngOnDestroy() {
    if (this.router.url.includes('plan')) {
      this.dataModelService.dataManagement.storeinfo();
    }
  }
}
