import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'values' })
export class ValuesPipe implements PipeTransform {
  public transform(value: any, args?: any[]): object[] {
    const keyArr: any[] = Object.keys(value),
      dataArr = [],
      keyName = args[0];

    keyArr.forEach((key: any) => {
      // key === keyName is a hacky fix to prevent some strange issues with nested templates
      if (key === keyName) {
        delete value[key];
      } else if (typeof value[key] === 'object') {
        value[key][keyName] = key;
        dataArr.push(value[key]);
      }
    });

    if (args[1]) {
      dataArr.sort((a: object, b: object): number => (a[keyName] > b[keyName] ? 1 : -1));
    }

    return dataArr;
  }
}
