// set-currency-code.component.ts

import { Component, OnInit } from '@angular/core';
import { DataModelService } from '../../services/data-model/data-model.service';

@Component({
  selector: 'app-set-currency-code',
  templateUrl: './set-currency-code.component.html',
  styleUrls: ['./set-currency-code.component.scss']
})
export class SetCurrencyCodeComponent implements OnInit {
  public selectedCurrencyCode: string;
  public currencyCodeItems: any;
  public currencyCode2currencyCodeItem: any;
  public currencyCheckboxStatus: any;
  public destination: string;

  constructor(public dataModelService: DataModelService) {}

  public ngOnInit() {
    this.selectedCurrencyCode = this.getSelectedCurrencyCode();
    this.currencyCodeItems = this.dataModelService.ancillaryDataManagement.currencyCodeItems;
    this.currencyCode2currencyCodeItem = this.dataModelService.ancillaryDataManagement.currencyCode2currencyCodeItem;
    this.currencyCheckboxStatus = this.getCurrencyCheckboxStatus(this.selectedCurrencyCode, this.currencyCode2currencyCodeItem);
    this.destination = 'wizeFiAccount';
  } // ngOnInit

  public objectKeys(obj) {
    return Object.keys(obj).sort();
  } // objectKeys

  public getSelectedCurrencyCode() {
    let selectedCurrencyCode;

    if (this.dataModelService.dataModel.global.hasOwnProperty(selectedCurrencyCode)) {
      selectedCurrencyCode = this.dataModelService.dataModel.global.selectedCurrencyCode;
    } else {
      selectedCurrencyCode = this.dataModelService.dataModel.persistent.settings.currencyCode;
      if (selectedCurrencyCode === 'undefined') {
        selectedCurrencyCode = 'USD';
      }
    }
    return selectedCurrencyCode;
  } // getSelectedCurrencyCode

  public getCurrencyCheckboxStatus(selectedCurrencyCode, currencyCode2currencyCodeItem) {
    const currencyCheckboxStatus = {};
    for (const currencyCode of Object.keys(currencyCode2currencyCodeItem)) {
      currencyCheckboxStatus[currencyCode] = currencyCode === selectedCurrencyCode ? true : false;
    }
    return currencyCheckboxStatus;
  } // getCurrencyCheckboxStatus

  public processCurrencyCheckboxStatus(currencyCode) {
    if (this.currencyCheckboxStatus[currencyCode]) {
      // set currencyCode value
      this.selectedCurrencyCode = this.currencyCode2currencyCodeItem[currencyCode].currencyCode;

      // reset currencyCheckboxStatus
      this.currencyCheckboxStatus = this.getCurrencyCheckboxStatus(this.selectedCurrencyCode, this.currencyCode2currencyCodeItem);
    }
  } // processCurrencyCheckboxStatus

  public changeDestination() {
    let radioButtonWizeFiAccount: any;
    let radioButtonTransactionAmount: any;

    radioButtonWizeFiAccount = document.getElementById('wizeFiAccounts') as HTMLOptionElement;
    radioButtonTransactionAmount = document.getElementById('transactionAmount') as HTMLOptionElement;

    if (radioButtonWizeFiAccount.checked) {
      this.destination = 'wizeFiAccount';
    }
    if (radioButtonTransactionAmount.checked) {
      this.destination = 'transactionAmount';
    }

    //console.log("destination: " + this.destination);  // %//
  } // changeDestination

  public exitSetCurrencyCode() {
    // temporary method for passing a return value from the screen back to the calling environment
    this.dataModelService.dataModel.global.selectedCurrencyCode = this.selectedCurrencyCode;
    //console.log("selectedCurrencyCode return value: " + this.dataModelService.dataModel.global.selectedCurrencyCode);  // %//

    // this is how to handle the userCurrencyCode for the WizeFi app (as distinct from a transactionAmount)
    if (this.destination === 'wizeFiAccount') {
      const currencyCode2currencyCodeItem = this.dataModelService.ancillaryDataManagement.currencyCode2currencyCodeItem;
      const decimalDigits = currencyCode2currencyCodeItem[this.selectedCurrencyCode].decimalDigits;

      this.dataModelService.dataModel.persistent.settings.currencyCode = this.selectedCurrencyCode;
      this.dataModelService.dataModel.persistent.settings.decimalDigits = decimalDigits;

      // note: await is not used below because memory resident data is already updated,
      // and any time delay in DynamoDB update will not affect anything downstream (TODO -- review accuracy of this)
      this.dataModelService.dataManagement.storeinfo();
      //console.log("currencyCode and decimalDigits have been updated in DynamoDB");  // %//
    }
  } // exitSetCurrencyCode
} // SetCurrencyCodeComponent
