import { Component, Input } from '@angular/core';
import { IGraphItem } from '../../../interfaces/iGraphItem.interface';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { NetWorthProjectionComponent } from '../net-worth-projection/net-worth-projection.component';

const blue = '#327ACE';
const white = '#ffffff';
const green = '#2FAFA9';

@Component({
  selector: 'app-cafr-details',
  templateUrl: './cafr-details.component.html',
  styleUrls: ['./cafr-details.component.scss']
})
export class CafrDetailsComponent extends NetWorthProjectionComponent {
  @Input() public totalExpenses: number;
  @Input() public totalIncome: number;
  @Input() public currentCAFR: number;
  @Input() public targetCAFR: number;
  public datasets: Array<{ data: IGraphItem[]; color: string }>;
  public isLoading = true;
  public wizeFiGuidelineCAFR = 1000;

  constructor(protected dataModelService: DataModelService) {
    super(dataModelService);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.setProjectionDatasets();
    this.isLoading = false;
  }

  private setProjectionDatasets() {
    this.datasets = [
      { data: this.wizeFiFFData, color: blue },
      { data: this.currentFFData, color: green },
      { data: this.originalFFData, color: white }
    ];
  }

  public getYearMonthLabel(totalMonths: number): string {
    const years = Math.floor(totalMonths / 12);
    const months = Math.floor(totalMonths % 12);
    return `${years} yr ${months} mo`;
  }
}
