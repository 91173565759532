// affiliate-management.class.ts

import * as moment from 'moment';

import { environment } from '../../environments/environment';
import { vOption } from '../enums/VOption.enum';
import { INode } from '../interfaces/iNode.interface';
/*
Notes:

1. the key of a tree node is the affiliateID value
*/

declare const AWS: any;

export class AffiliateManagement {
  public affiliateBreakdownInfo: any;
  public affiliateCalculatorInfo: any;
  public Alias2ID: any = {}; // map affiliateAlias to affiliateID
  public AliasDisallowedList: string[] = [
    'wizefi',
    'wizefi lifestyle',
    'home',
    'america',
    'seanallen',
    'sean',
    'robert',
    'robert sean',
    'allen',
    'wealthgrade',
    'a wealthgrade',
    'b wealthgrade',
    'c wealthgrade',
    'd wealthgrade',
    '4step plan',
    'net worth',
    'communities',
    'community',
    'affiliate',
    'affiliates',
    'plan',
    'wealth building',
    'wealth',
    'beyond wealth',
    'make the most of your money',
    'money',
    'make money',
    'live your ideal lifestyle',
    'organize my money',
    'organize my debt',
    'million millionare/s',
    'billionare/s',
    'cafr',
    'lord',
    'god',
    'jesus',
    'jesus christ',
    'satan',
    'fiscalfitness'
  ]; // Disallowed Affiliate Aliases
  public defaultFee = 8.99; // default fee is $8.99
  public ID2Alias: any = {}; //  map affiliateID to affiliateAlias
  public isTreeInitialized = false; // indicates whether tree has been populated with data

  // data for screens in affiliate feature
  public leaderBoardInfo: any;
  public level6CommissionPercent = [
    // table that defines the level 6 commission percent
    { totalAffiliateCount: 0, commissionPercent: 0 },
    { totalAffiliateCount: 1000, commissionPercent: 0.1 },
    { totalAffiliateCount: 5000, commissionPercent: 0.2 },
    { totalAffiliateCount: 10000, commissionPercent: 0.3 },
    { totalAffiliateCount: 25000, commissionPercent: 0.4 },
    { totalAffiliateCount: 50000, commissionPercent: 0.5 },
    { totalAffiliateCount: 100000, commissionPercent: 0.6 },
    { totalAffiliateCount: 250000, commissionPercent: 0.7 },
    { totalAffiliateCount: 500000, commissionPercent: 0.8 },
    { totalAffiliateCount: 750000, commissionPercent: 0.9 },
    { totalAffiliateCount: 1000000, commissionPercent: 1 }
  ];
  public levelCommissionPercent = [0, 20, 10, 5, 3, 1, 0]; // commission percent for each level
  public maxLevel = 6; // maximum number of levels to traverse
  public rootAffiliateID = 'aaaaaaa'; // affiliate ID for root of the tree
  public rootAffiliateAlias = 'root'; // affiliate alias for root of the tree
  public orphanAffiliateID = 'orphans'; // node in tree to hold nodes that have no valid parentAffiliateID
  public orphanAffiliateAlias = 'orphans123'; // affiliate alias for orphans node in tree
  public tree: any = {}; // memory resident version of tree information

  // (Note: element 0 of array is ignored. For subsequent elements, the array subscript is the level number to which the count applies.)
  public unlockLevelCount = [0, 0, 2, 3, 6, 12, 20]; // table of level 1 count values required to unlock a given level

  public constructor(public dataModelService: any) {
    this.isTreeInitialized = false;
    this.tree = {};
    this.Alias2ID = {};
    this.ID2Alias = {};
  } // constructor

  public getInitialOrphanItem() {
    const item = {
      affiliateID: this.orphanAffiliateID,
      affiliateAlias: this.orphanAffiliateID + '123',
      node: {
        fee: 8.99,
        parent: this.rootAffiliateID,
        child: [],
        isActive: true,
        isInTrialPeriod: false
      },
      version: 0
    };
    return item;
  } // getInitialOrphanItem

  public addNode(p: string, affiliateAlias: string, fee: number, parent: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let msg: string;
      let newNode, parentNode: INode;
      let parentVersion: number;

      // check for presence of new node and parent node
      newNode = this.getNode(p);

      if (parent === undefined || parent === '' || parent === null) {
        parent = this.rootAffiliateID;
      }
      parentNode = this.getNode(parent);

      if (newNode !== undefined && newNode !== null) {
        console.log('new node is not undefined or null');
        console.log(newNode);
        msg = 'Error -- new node(' + p + ') is already present with ' + newNode.child.length + ' affiliates on first level';
        console.log(msg);
        reject(msg);
      } else if (parentNode === undefined || parentNode === null) {
        // TODO add new node under root if parent is invalid?  (then later update to the proper parent?)
        msg = 'Error -- new node(' + p + ') has invalid parent(' + parent + ')';
        console.log(msg);
        reject(msg);
      } else {
        // add new node
        newNode = {
          isActive: false,
          isInTrialPeriod: false,
          fee,
          parent,
          child: []
        };
        this.putNode(p, affiliateAlias, newNode);

        // add this node as a child of the parent node
        if (parentNode.child.indexOf(p) === -1) {
          parentNode.child.push(p);
          this.putNode(parent, this.rootAffiliateAlias, parentNode);
        }

        // update lookup tables
        this.Alias2ID[affiliateAlias] = p;
        this.ID2Alias[p] = affiliateAlias;

        // update DynamoDB (WizeFiAffiliateTree and Alias2ID and ID2Alias)
        parentVersion = this.getVersion(parent);

        const processResult = (result: any): void => {
          resolve(result);
        };
        const handleError = (err: any): void => {
          reject(err);
        };

        this.addNodeDynamoDB(p, affiliateAlias, fee, parent, parentVersion, parentNode).then(processResult).catch(handleError);
      }
    }) // return Promise
      .catch(err => {
        console.log('error from addNode');
        console.log(err);
      });
  } // addNode

  public addNodeDynamoDB(
    childID: string,
    affiliateAlias: string,
    fee: number,
    parentID: string,
    parentVersion: number,
    parentNode: INode
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let tableName, childActionParms, parentActionParms;

      const addChild = () => this.invokeManageAffiliateTree(this.dataModelService, tableName, 'putItem', childActionParms);

      const updateParent = () => this.invokeManageAffiliateTree(this.dataModelService, tableName, 'updateItem', parentActionParms);

      const processResult = (result): void => {
        resolve(result);
      };

      const handleError = (err: any): void => {
        console.log('Error in addNodeDynamoDB -- chidID: ' + childID); // %//
        console.log(err); // %//
        reject(err);
      };

      // initialize
      tableName = 'WizeFiAffiliateTree';
      childActionParms = {
        affiliateID: childID,
        affiliateAlias,
        node: {
          isActive: false,
          fee,
          parent: parentID,
          child: []
        }
      };
      parentActionParms = {
        affiliateID: parentID,
        version: parentVersion,
        affiliateAlias: this.ID2Alias[parentID],
        node: parentNode,
        updates: {
          node: {
            insertChild: childID
          }
        }
      };

      addChild().then(updateParent).then(processResult).catch(handleError);
    }); // return Promise
  } // 	addNodeDynamoDB

  public applyUpdates(item: any, updates: any): void {
    let val, ndx;

    for (const itemprop in updates) {
      if (itemprop === 'affiliateAlias') {
        item.affiliateAlias = updates.affiliateAlias;
      } else if (itemprop === 'node') {
        for (const nodeprop in updates.node) {
          if (nodeprop === 'insertChild') {
            val = updates.node.insertChild;
            ndx = item.node.child.indexOf(val);
            if (ndx === -1) {
              item.node.child.push(val);
            }
          } else if (nodeprop === 'deleteChild') {
            val = updates.node.deleteChild;
            ndx = item.node.child.indexOf(val);
            if (ndx !== -1) {
              item.node.child.slice(ndx, 1);
            }
          } else {
            item.node[nodeprop] = updates.node[nodeprop];
          }
        } // for nodeprop
      } // if is node property
    } // for itemprop
  } // applyUpdates

  public breadthtrav(visitOption: vOption, p: string, level: number, parms: any = null): void {
    const queue: any[] = [];
    let node: INode;
    let item: any;

    // insert node to be processed into queue
    queue.push({ p, level });

    // process nodes in queue
    while (queue.length > 0) {
      // retrieve node to process
      item = queue[0];
      queue.splice(0, 1);
      p = item.p;
      level = item.level;

      // visit this node
      this.visit(visitOption, p, level, parms);

      // place children of this node into queue for subsequent processing
      node = this.getNode(p);
      if (node === undefined) {
        // TODO consider logging this some place for further analysis
        console.log('node (' + p + ') does not exist in the affiliate tree');
      } else {
        for (const q of node.child) {
          queue.push({ p: q, level: level + 1 });
        }
      }
    } // while
  } // breadthtrav

  public changeDate(ISOdate, years, months, days) {
    const m = moment(ISOdate, ['YYYY', 'YYYY-MM', 'YYYY-MM-DD']);
    /*  const m = moment.utc(
      moment(ISOdate, ["YYYY", "YYYY-MM", "YYYY-MM-DD"]).format()
    ); // identify expected input formats */
    m.add({ years, months, days });

    return m.format();
  } // changeDate

  public changeItemInfo(p: string, updates: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let item: any;

      item = this.getItem(p);
      if (item !== undefined) {
        this.applyUpdates(item, updates);

        const processResult = (result): void => {
          resolve(result);
        };
        const handleError = (err: any): void => {
          reject(err);
        };

        // initialize
        const tableName = 'WizeFiAffiliateTree';
        const action = 'changeItemInfo';
        const actionParms = {
          affiliateID: p,
          updates
        };

        // TODO handle update of Alias2ID and ID2Alias
        this.invokeManageAffiliateTree(this.dataModelService, tableName, action, actionParms).then(processResult).catch(handleError);
      } // if have item
    }); // return Promise
  } // changeItemInfo

  public changeItemInfoDynamoDB(p: string, updates: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let tableName, action, actionParms;

      const processResult = (result: any): void => {
        resolve(result);
      };
      const handleError = (err: any): void => {
        reject(err);
      };

      // initialize
      tableName = 'WizeFiAffiliateTree';
      action = 'changeItemInfo';
      actionParms = {
        affiliateID: p,
        updates
      };

      // TODO handle update of Alias2ID and ID2Alias
      this.invokeManageAffiliateTree(this.dataModelService, tableName, action, actionParms).then(processResult).catch(handleError);
    }); // return Promise
  } // 	changeItemInfoDynamoDB

  public displayBreadthTree(subroot: string): void {
    try {
      // recursively visit each node in the subtree identified by subroot
      this.breadthtrav(vOption.DISPLAYTREE, subroot, 0);
    } catch (ex) {
      console.log('Error in displayBreadthTree operation:'); // %//
      console.log(ex); // %//
    }
  } // displayBreadthTree

  public displayPostTree(subroot: string): void {
    try {
      // recursively visit each node in the subtree identified by subroot
      this.posttrav(vOption.DISPLAYTREE, subroot, 0);
    } catch (ex) {
      console.log('Error in displayPostTree operation:'); // %//
      console.log(ex); // %//
    }
  } // displayPostTree

  public displayTree(subroot: string): void {
    try {
      // recursively visit each node in the subtree identified by subroot
      this.pretrav(vOption.DISPLAYTREE, subroot, 0);
    } catch (ex) {
      console.log('Error in displayTree operation:'); // %//
      console.log(ex); // %//
    }
  } // displayTree

  public generateAffiliateAlias(affiliateAlias: string): string {
    const digits = '0123456789';
    let haveUnique: boolean;
    let suffix: string;
    let count: number;

    count = 0;
    do {
      count += 1;
      suffix = '';
      if (count >= 1) {
        for (let i = 1; i <= 3; i++) {
          suffix += digits[Math.floor(Math.random() * 10)];
        }
      }
      affiliateAlias += suffix;
      haveUnique = true; // @TODO restore this this.isAliasUnique(affiliateAlias);
    } while (!haveUnique);

    return affiliateAlias;
  } // generateAffiliateAlias

  public generateAffiliateID(): string {
    const letters = 'abcdefghijklmnopqrstuvwxyz';
    let haveUnique: boolean;
    let affiliateID: string;

    do {
      affiliateID = '';
      for (let i = 1; i <= 7; i++) {
        affiliateID += letters[Math.floor(Math.random() * 26)];
      }
      // TODO review how best to guarantee uniqueness (use DynamoDB source of info, or will it be memory resident info)
      haveUnique = true; // @TODO restore this !this.ID2Alias.hasOwnProperty(affiliateID);
    } while (!haveUnique);

    return affiliateID;
  } // generateAffiliateID

  public getAffiliateAlias(p: string): string {
    let affiliateAlias = 'unknown';
    if (this.tree.hasOwnProperty(p)) {
      affiliateAlias = this.tree[p].affiliateAlias;
    }

    return affiliateAlias;
  } // getAffiliateAlias

  public getAffiliateBreakdownInfo(p: string): any {
    let affiliateBreakdownInfo: any;
    let earnings: number;
    let affiliateCount, affiliateSum, earningsSum, affiliateSumLocked: number;
    let commissionPercent: number;

    affiliateBreakdownInfo = {};
    affiliateBreakdownInfo.tierList = [];

    try {
      // get affiliate count by level
      const levelCount = this.getLevelCount(p);

      // get total number of affiliates in network
      affiliateSum = 0;
      for (let loopIdx = 1; loopIdx <= 6; loopIdx++) {
        affiliateSum += levelCount[loopIdx];
      }

      // set level 6 commission percent
      let idx = -1;
      while (
        // eslint-disable-next-line no-cond-assign
        (idx += 1) < this.level6CommissionPercent.length &&
        this.level6CommissionPercent[idx].totalAffiliateCount <= affiliateSum
      ) {}
      this.levelCommissionPercent[6] = this.level6CommissionPercent[idx - 1].commissionPercent;

      // determine all other data
      earningsSum = 0;
      affiliateSumLocked = 0;
      for (let i = 1; i <= 6; i++) {
        affiliateCount = levelCount[i];

        // determine commissionPercent
        commissionPercent = this.levelCommissionPercent[i];

        // determine earnings
        earnings = 0;
        if (levelCount[1] >= this.unlockLevelCount[i]) {
          earnings = Number((0.01 * commissionPercent * affiliateCount * this.defaultFee).toFixed(2));
        } else {
          affiliateSumLocked += affiliateCount;
        }

        affiliateBreakdownInfo.tierList.push({
          tier: i,
          commission: commissionPercent,
          affiliateCount: levelCount[i],
          unlockCount: this.unlockLevelCount[i],
          earnings
        });

        earningsSum += earnings;
      } // for i

      affiliateBreakdownInfo.affiliateSum = affiliateSum;
      affiliateBreakdownInfo.affiliateSumLocked = affiliateSumLocked;
      affiliateBreakdownInfo.earningsSum = earningsSum;
    } catch (ex) {
      console.log('Error in getAffiliateBreakdownInfo operation:'); // %//
      console.log(ex); // %//
    }

    return affiliateBreakdownInfo;
  } // getAffiliateBreakdownInfo

  public getAffiliateCalculatorInfo(tier1Affiliates: number, affiliatesMultiplier: number): any {
    let affiliateCalculatorInfo: any;
    let earnings: number;
    let affiliateCount: number;
    let affiliateSum, earningsSum: number;
    let commissionPercent: number;

    // build result information
    affiliateCalculatorInfo = {};
    affiliateCalculatorInfo.tier1Affiliates = tier1Affiliates;
    affiliateCalculatorInfo.affiliatesMultiplier = affiliatesMultiplier;
    affiliateCalculatorInfo.tierList = [];

    // get total number of affiliates in network
    affiliateSum = 0;
    for (let idx = 1; idx <= 6; idx++) {
      affiliateCount = idx === 1 ? tier1Affiliates : affiliatesMultiplier * affiliateCount;
      affiliateSum += affiliateCount;
    }

    // set level 6 commission percent
    let i = -1;
    while (
      // eslint-disable-next-line no-cond-assign
      (i += 1) < this.level6CommissionPercent.length &&
      this.level6CommissionPercent[i].totalAffiliateCount <= affiliateSum
    ) {}
    this.levelCommissionPercent[6] = this.level6CommissionPercent[i - 1].commissionPercent;

    // determine all other data
    earningsSum = 0;
    for (let idx = 1; idx <= 6; idx++) {
      affiliateCount = idx === 1 ? tier1Affiliates : affiliatesMultiplier * affiliateCount;

      commissionPercent = this.levelCommissionPercent[idx];

      earnings = 0;
      if (tier1Affiliates >= this.unlockLevelCount[idx]) {
        earnings = Number((0.01 * commissionPercent * affiliateCount * this.defaultFee).toFixed(2));
      }

      affiliateCalculatorInfo.tierList.push({
        tier: idx,
        commission: commissionPercent,
        affiliateCount,
        unlockCount: this.unlockLevelCount[idx],
        earnings
      });

      earningsSum += earnings;
    }
    affiliateCalculatorInfo.affiliateSum = affiliateSum;
    affiliateCalculatorInfo.earningsSum = earningsSum;

    return affiliateCalculatorInfo;
  } // getAffiliateCalculatorInfo

  public async getAffiliatePayoutInfo(affiliateID, monthDate, endMonth = null) {
    const retreiveAffiliatePayoutInfo = (funcAffiliateID, funcMonthDate) =>
      new Promise((resolve, reject) => {
        // initialize
        let payoutInfo = {
          monthDate: '0000-01',
          income: 0,
          payout: 0,
          pendingEarnings: 0,
          wizeFiPayouts: 0,
          wizeFiBank: 0,
          matured: 0,
          growing: 0,
          payoutList: [],
          maturedList: [],
          growingList: [],
          paymentMonth: ''
        };

        // define params to guide get operation
        const params = {
          TableName: 'AffiliatePayoutInfo',
          Key: { affiliateID: funcAffiliateID, monthDate: funcMonthDate }
        };

        // get info from DynamoDB table
        const docClient = new AWS.DynamoDB.DocumentClient();
        docClient.get(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            // return results
            if (data.hasOwnProperty('Item')) {
              payoutInfo = JSON.parse(data.Item.payoutInfo);

              // embed date in return object
              payoutInfo.monthDate = data.Item.monthDate;

              // set paymentMonth
              payoutInfo.paymentMonth = '';
              if (payoutInfo.pendingEarnings >= 100) {
                payoutInfo.paymentMonth = this.dataModelService.cd(this.dataModelService.changeDate(payoutInfo.monthDate, 0, 2, 0).substr(0, 7));
              }
            }
            resolve(payoutInfo);
          }
        });
      }); // return Promise // retreiveAffiliatePayoutInfo

    const cmp = (v1, v2) => {
      let funcResult = 0;
      if (v1.monthDate < v2.monthDate) {
        funcResult = -1;
      } else if (v1.monthDate > v2.monthDate) {
        funcResult = 1;
      }

      return funcResult;
    }; // cmp

    // initialize
    let wantSingleMonth = false;
    if (endMonth === null) {
      wantSingleMonth = true;
      endMonth = monthDate;
    }
    const payoutInfoList = [];

    while (monthDate <= endMonth) {
      const payoutInfo = await retreiveAffiliatePayoutInfo(affiliateID, monthDate);
      payoutInfoList.push(payoutInfo);

      // advance to next month to process
      monthDate = this.changeDate(monthDate, 0, 1, 0).substr(0, 7);
    }

    payoutInfoList.sort(cmp);

    const result = wantSingleMonth ? payoutInfoList[0] : payoutInfoList;
    return result;
  } // getAffiliatePayoutInfo

  public getItem(p: string): any {
    if (!this.tree.hasOwnProperty(p)) {
      return null;
    } else {
      return this.tree[p];
    }
  } // getItem

  public getLeaderBoardInfo(p: string): any {
    function compareCount(v1: any, v2: any): number {
      return v2.affiliateCount - v1.affiliateCount;
    } // compareCount

    // initialize return values
    const parms = {} as any;
    parms.leaderBoardInfo = [];

    try {
      // recursively visit each node in the subtree identified by subroot
      this.pretrav(vOption.GETLEADERBOARDINFO, this.rootAffiliateID, 0, parms);

      // sort list into descending order
      parms.leaderBoardInfo.sort(compareCount);

      // add rank information and adjust affiliateCount
      let saveItem = null;
      for (let i = 0; i < parms.leaderBoardInfo.length; i++) {
        parms.leaderBoardInfo[i].rank = i;
        parms.leaderBoardInfo[i].affiliateCount -= 1; // remove root of subtree from count
        if (parms.leaderBoardInfo[i].affiliateCount < 0) {
          parms.leaderBoardInfo[i].affiliateCount = 0;
        }
        if (this.Alias2ID[parms.leaderBoardInfo[i].user] === p) {
          saveItem = parms.leaderBoardInfo[i];
        }
      }

      // remove all but the first 10 items, and add item at end of list for user identifed by p
      parms.leaderBoardInfo.splice(0, 1); // remove root from the list
      parms.leaderBoardInfo.splice(10); // remove all items past the 10th from the list
      if (saveItem !== undefined) {
        parms.leaderBoardInfo.push(saveItem);
      } // add information for requested user
    } catch (ex) {
      console.log('Error in getLeaderBoardInfo operation:'); // %//
      console.log(ex); // %//
    }

    return parms.leaderBoardInfo;
  } // getLeaderBoardInfo

  public getLevel1Affiliates(affiliateID) {
    return new Promise((resolveGetLevel1Affiliates, rejectGetLevel1Affiliates) => {
      const obtainWizeFiID = funcParms =>
        new Promise((resolve, reject) => {
          // define params to guide the query operation
          const params = {
            TableName: 'WizeFiData',
            IndexName: 'WizeFiData-AffiliateID',
            KeyConditionExpression: 'affiliateID = :affiliateID',
            ExpressionAttributeValues: { ':affiliateID': funcParms.affiliateID }
          };

          // get info from DynamoDB WizeFiData-AffiliateID index
          const docClient = new AWS.DynamoDB.DocumentClient();
          docClient.query(params, (err, data) => {
            if (err) {
              reject(err);
            } else {
              if (data.Items.length === 0) {
                // add affiliateID to exceptions list for later analysis to determine action to be taken
                funcParms.missingWizeFiIDList.push(funcParms.affiliateID);
                funcParms.wizeFiID = 'unknown';
              } else {
                funcParms.wizeFiID = data.Items[0].wizeFiID;
              }
              resolve(funcParms);
            }
          });
        }); // return Promise // obtainWizeFiID

      const retreiveAffiliateUserData = funcParms =>
        new Promise((resolve, reject) => {
          // define params to guide get operation
          const params = {
            TableName: 'WizeFiData',
            Key: { wizeFiID: funcParms.wizeFiID },
            AttributesToGet: ['persistent']
          };

          // get info from DynamoDB table
          const docClient = new AWS.DynamoDB.DocumentClient();
          docClient.get(params, (err, data) => {
            if (err) {
              reject(err);
            } else {
              // return results
              if (data.hasOwnProperty('Item')) {
                const persistent = JSON.parse(data.Item.persistent);
                const item = {
                  dateCreated: persistent.header.dateCreated,
                  nameFirst: persistent.profile.nameFirst,
                  nameLast: persistent.profile.nameLast
                  // TODO get the following two items from Braintree data
                  // NOTE: at the present time this function is not used in any production code -- only in ItemManager tests
                  // subscriptionAccountStatus: persistent.header.subscriptionAccountStatus,
                  // subscriptionPaidThrough: persistent.header.subscriptionPaidThrough
                };
                funcParms.level1AffiliateList.push(item);
              }
              resolve(funcParms);
            }
          });
        }); // return Promise // retreiveAffiliateUserData

      const promiseIteration = i => {
        const promise = new Promise((resolve, reject) => {
          parms.affiliateID = parms.childList[i];

          obtainWizeFiID(parms)
            .then(retreiveAffiliateUserData)
            .then(funcParms => {
              resolve(funcParms);
            })
            .catch(err => {
              reject(err);
            });
        })
          .then(() => {
            if (i < parms.childList.length - 1) {
              promiseIteration(i + 1);
            } else {
              resolveGetLevel1Affiliates(parms.level1AffiliateList);
            }
          })
          .catch(err => {
            rejectGetLevel1Affiliates(err);
          });
      }; // promiseIteration

      // get tree node for the affiliateID for which to display level 1 info
      const node = this.getNode(affiliateID);

      // establish object to mange data involved in getting results
      const parms = {
        affiliateID,
        level1AffiliateList: [],
        childList: node.child
      };

      // process each child in childList array
      if (parms.childList.length === 0) {
        resolveGetLevel1Affiliates(parms.level1AffiliateList);
      } else {
        promiseIteration(0);
      }
    }); // return Promise
  } // getLevel1Affiliates

  public getLevelCount(subroot: string): number[] {
    // initialize levelCount to have a place for a value for each desired level
    const parms = {} as any;
    parms.levelCount = [];
    for (let level = 0; level <= this.maxLevel; level++) {
      parms.levelCount.push(0);
    }

    try {
      // recursively visit each node in the subtree identified by subroot
      this.pretrav(vOption.GETLEVELCOUNT, subroot, 0, parms);
    } catch (ex) {
      console.log('Error in getLevelCount operation:'); // %//
      console.log(ex); // %//
    }

    return parms.levelCount;
  } // getLevelCount

  public getLevelEarnings(subroot: string, userFeesPaid): number[] {
    // initialize userFeesPaid to have a place for a value for each desired level
    const parms = {} as any;
    parms.userFeesPaid = userFeesPaid;
    for (let level = 0; level <= this.maxLevel; level++) {
      parms.userFeesPaid.push(0);
    }

    try {
      // recursively visit each node in the subtree identified by subroot
      this.pretrav(vOption.GETLEVELEARNINGS, subroot, 0, parms);
    } catch (ex) {
      console.log('Error in getLevelEarnings operation:'); // %//
      console.log(ex); // %//
    }

    return parms.userFeesPaid;
  } // getLevelEarnings

  public getLevelTotalCount(subroot: string): number[] {
    // initialize levelCount to have a place for a value for each desired level
    const parms = {} as any;
    parms.levelCount = [];
    for (let level = 0; level <= this.maxLevel; level++) {
      parms.levelCount.push(0);
    }

    try {
      // recursively visit each node in the subtree identified by subroot
      this.pretrav(vOption.GETLEVELTOTALCOUNT, subroot, 0, parms);
    } catch (ex) {
      console.log('Error in getLevelCount operation:'); // %//
      console.log(ex); // %//
    }

    return parms.levelCount;
  } // getLevelTotalCount

  public getNode(p: string): INode {
    if (!this.tree.hasOwnProperty(p)) {
      return null;
    } else {
      return this.tree[p].node;
    }
  } // getNode

  public getTier1Lists(affiliateID) {
    return new Promise((resolve, reject) => {
      const active = [];
      const trial = [];
      if (this.tree.hasOwnProperty(affiliateID)) {
        for (const child of this.tree[affiliateID].node.child) {
          const childAffiliateID = child;
          if (this.tree.hasOwnProperty(childAffiliateID)) {
            if (this.tree[childAffiliateID].node.isActive) {
              active.push(this.tree[childAffiliateID].affiliateAlias);
            }
            if (this.tree[childAffiliateID].node.isInTrialPeriod) {
              trial.push(this.tree[childAffiliateID].affiliateAlias);
            }
          }
        }
      }
      const tier1Lists = { active, trial };
      resolve(tier1Lists);
    }); // return Promise
  } // getTier1Lists

  public getTreeAffiliateCounts(affiliateID) {
    return new Promise((resolve, reject) => {
      let p, level, stack, treeAffiliateCounts, node, stkItem;

      // initialize
      p = affiliateID; // root of subtree to traverse
      level = 0;
      stack = []; // array used as stack to track the tree traversal
      treeAffiliateCounts = {
        totalAffiliateCount: 0,
        trialAffiliateCount: 0,
        activeAffiliateCount: 0
      };

      // use preorder traversal to examine all nodes under given subtree
      stack.push({ p, level });
      while (stack.length > 0) {
        // get current node to process
        stkItem = stack.pop();
        p = stkItem.p;
        level = stkItem.level;
        node = this.getNode(p);

        if (node !== undefined) {
          // visit the current node
          if (level > 0 && level <= 6) {
            treeAffiliateCounts.totalAffiliateCount += 1;
            if (node.isInTrialPeriod) {
              treeAffiliateCounts.trialAffiliateCount += 1;
            }
            if (node.isActive) {
              treeAffiliateCounts.activeAffiliateCount += 1;
            }
          }

          // set things up to process children of the current node (limit traversal to at most 6 levels deep)
          if (level < 6) {
            for (const q of node.child) {
              stack.push({ p: q, level: level + 1 });
            }
          }
        }
      } // while stack is not empty

      resolve(treeAffiliateCounts);
    }); // return Promise
  } // getTreeAffiliateCounts

  public getTreeStats(subroot): any {
    // initialize return values
    const parms = {} as any;
    parms.activeNodeCount = 0;
    parms.inactiveNodeCount = 0;
    parms.allNodeCount = 0;
    parms.height = 0;
    parms.maxWidth = 0;

    try {
      // recursively visit each node in the subtree identified by subroot
      this.pretrav(vOption.GETTREESTATS, subroot, 0, parms);
    } catch (ex) {
      console.log('Error in getTreeStats operation:'); // %//
      console.log(ex); // %//
    }

    return parms;
  } // getTreeStats

  public getUniqueAffiliateID() {
    return new Promise((resolveGetUniqueAffiliateID, rejectGetUniqueAffiliateID) => {
      const configureAWS = () =>
        new Promise((resolve, reject) => {
          AWS.config.update({ region: environment.AWSRegion });
          AWS.config.correctClockSkew = true;
          if (!AWS.config.hasOwnProperty('credentials') || AWS.config.credentials === undefined) {
            // clear credentials from localStorage to eliminate "Missing credentials in config" error
            const setting = 'aws.cognito.identity-id.' + environment.AWSIdentityPoolId;
            window.localStorage.removeItem(setting);
            console.log('cleared aws.cognito.identity-id setting in localStorage'); // %//

            // configure AWS object for unauthenticated user (one who has not yet logged in)
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
              IdentityPoolId: environment.AWSIdentityPoolId
            });
          }

          resolve();
        }); // return Promise // configureAWS

      const setAWSobjects = () => {
        // establish AWS objects
        parms.lambda = new AWS.Lambda();

        return Promise.resolve();
      }; // setAWSobjects

      const obtainUniqueAffiliateID = () =>
        new Promise((resolve, reject) => {
          // set params to guide Lambda function invocation
          const payload = { action: 'getUniqueAffiliateID' };
          const params = {
            FunctionName: 'preliminaryWork',
            Payload: JSON.stringify(payload)
          };

          // invoke Lambda function to process data
          parms.lambda.invoke(params, (err, data) => {
            if (err) {
              reject(err);
            } else {
              if (typeof data.Payload !== 'string') {
                reject(data.Payload);
              } else {
                const funcPayload = JSON.parse(data.Payload);
                resolve(funcPayload);
              }
            }
          }); // lambda invoke
        }); // return Promise // obtainUniqueAffiliateID

      const parms = { lambda: null };

      configureAWS()
        .then(setAWSobjects)
        .then(obtainUniqueAffiliateID)
        .then(affiliateID => {
          resolveGetUniqueAffiliateID(affiliateID);
        })
        .catch(err => {
          rejectGetUniqueAffiliateID(err);
        });
    }); // return Promise
  } // getUniqueAffiliateID

  public getVersion(p: string): number {
    let version = 0;
    if (this.tree.hasOwnProperty(p) && this.tree[p].hasOwnProperty(version)) {
      version = this.tree[p].version;
    }

    return version;
  } // getVersion

  public invokeManageAffiliateTree(dataModelService: any, tableName: string, action: string, actionParms: any = null): Promise<any> {
    return new Promise((resolve, reject) => {
      let payload, params;

      // set params to guide function invocation
      payload = {
        tableName,
        action,
        actionParms
      };
      params = {
        FunctionName: 'manageAffiliateTree',
        Payload: JSON.stringify(payload)
      };

      // invoke lambda function to process data
      dataModelService.dataModel.global.lambda.invoke(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          if (!data.hasOwnProperty('Payload')) {
            reject('Data does not contain Payload attribute');
          } else {
            payload = JSON.parse(data.Payload);
            if (payload !== undefined && payload.hasOwnProperty('errorMessage')) {
              reject('errorMessage: ' + payload.errorMessage);
            } else {
              resolve(payload);
            }
          }
        }
      }); // lambda invoke
    }); // return Promise
  } // invokeManageAffiliateTree

  public isAliasAllowed(alias: string): boolean {
    return !this.AliasDisallowedList.includes(alias.toLowerCase());
  } // isAliasAllowed

  public isAliasUnique(alias: string): boolean {
    // TODO review how best to guarantee uniqueness (use DynamoDB source of info, or will it be memory resident info)
    return !this.Alias2ID.hasOwnProperty(alias);
  } // isAliasUnique

  public loadAffiliateTree(): Promise<any> {
    return new Promise((resolve, reject) => {
      const buildMemoryResidentTree = (result: any): Promise<any> => {
        // create memory resident version of the affiliate tree
        let count = 0;
        for (const item of result) {
          count += 1;

          // add information to memory resident tree structure
          this.tree[item.affiliateID] = {
            version: item.version,
            affiliateAlias: item.affiliateAlias,
            node: item.node
          };

          // add information to mapping data structures
          this.Alias2ID[item.affiliateAlias] = item.affiliateID;
          this.ID2Alias[item.affiliateID] = item.affiliateAlias;
        }

        return Promise.resolve(count);
      };

      const guaranteeRootNode = (count: number): Promise<any> => {
        if (count === 0) {
          // create root node
          const rootNode = {
            isActive: true,
            isInTrialPeriod: false,
            fee: this.defaultFee,
            parent: null,
            child: []
          };

          // add root node to memory resident tree
          this.putNode(this.rootAffiliateID, this.rootAffiliateAlias, rootNode);

          // add information to mapping data structures
          this.Alias2ID[this.rootAffiliateAlias] = this.rootAffiliateID;
          this.ID2Alias[this.rootAffiliateID] = this.rootAffiliateAlias;

          // add root node to DynamoDB tree
          const tableName = 'WizeFiAffiliateTree';
          const action = 'putItem';
          const actionParms = {
            affiliateID: this.rootAffiliateID,
            affiliateAlias: this.rootAffiliateAlias,
            node: rootNode
          };

          return this.invokeManageAffiliateTree(this.dataModelService, tableName, action, actionParms);
        } else {
          return Promise.resolve(count);
        }
      }; // guaranteeRootNode

      const processResult = (result): void => {
        this.isTreeInitialized = true;
        resolve(result);
      }; // processResult

      const handleError = (err: any): void => {
        console.error(err);
        reject('Error in loadAffiliateTree. See console log for details.');
      }; // handleError

      if (this.isTreeInitialized) {
        resolve();
      } else {
        // populate memory resident tree with nodes found in DynamoDB
        this.invokeManageAffiliateTree(this.dataModelService, 'WizeFiAffiliateTree', 'getAllItems')
          .then(buildMemoryResidentTree)
          .then(guaranteeRootNode)
          // TODO add this: .then(guaranteeOrphansNode)
          .then(processResult)
          .catch(handleError);
      }
    }); // return Promise
  } // loadAffiliateTree

  public posttrav(visitOption: vOption, p: string, level: number, parms: any = null): void {
    let node: INode;

    node = this.getNode(p);
    if (node === undefined) {
      // TODO consider logging this some place for further analysis
      console.log('node (' + p + ') does not exist in the affiliate tree');
    } else {
      // recursively visit each child of the current node
      for (const q of node.child) {
        this.posttrav(visitOption, q, level + 1, parms);
      }

      // visit the current node
      this.visit(visitOption, p, level, parms);
    }
  } // posttrav

  public pretrav(visitOption: vOption, p: string, level: number, parms: any = null): void {
    let node: INode;

    // visit the current node
    this.visit(visitOption, p, level, parms);

    // recursively visit each child of the current node
    node = this.getNode(p);
    if (node === undefined) {
      // TODO consider logging this some place for further analysis
      console.log('node (' + p + ') does not exist in the affiliate tree');
    } else {
      for (const q of node.child) {
        this.pretrav(visitOption, q, level + 1, parms);
      }
    }
  } // pretrav

  public putItem(p: string, item: any): void {
    this.tree[p] = item;
    // TODO update DynamoDB (or only do this through update?)
  } // putItem

  public putNode(p: string, affiliateAlias: string, node: INode): void {
    this.tree[p] = { version: 0, affiliateAlias, node };
    // TODO update DynamoDB (or only do this through update or addNode?)
  } // putNode

  public retrieveAffiliateHistory(dataModelService, affiliateID) {
    return new Promise((resolve, reject) => {
      let payload, params;

      // set params to guide function invocation
      payload = {
        affiliateID
      };
      params = {
        FunctionName: 'retrieveAffiliateHistory',
        Payload: JSON.stringify(payload)
      };

      // invoke lambda function to process data
      dataModelService.dataModel.global.lambda.invoke(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          if (!data.hasOwnProperty('Payload')) {
            reject('Data does not contain Payload attribute');
          } else {
            payload = JSON.parse(data.Payload);
            if (payload !== undefined && payload.hasOwnProperty('errorMessage')) {
              reject('errorMessage: ' + payload.errorMessage);
            } else {
              resolve(payload);
            }
          }
        }
      }); // lambda invoke
    }); // return Promise
  } // retrieveAffiliateHistory

  public retrievePendingEarnings(affiliateID) {
    return new Promise((resolve, reject) => {
      // set params to guide function invocation
      const payload = { affiliateID };
      const params = {
        FunctionName: 'retrievePendingEarnings',
        Payload: JSON.stringify(payload)
      };

      // invoke Lambda function to process data
      this.dataModelService.dataModel.global.lambda.invoke(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const funcPayload = JSON.parse(data.Payload);
          resolve(funcPayload);
        }
      }); // lambda invoke
    }); // return Promise
  } // retrievePendingEarnings

  public updateAlias(affiliateID: string, alias: string): any {
    const params = {
      affiliateID,
      version: this.getVersion(affiliateID), // @TODO is this right?
      updates: {
        affiliateAlias: { update: alias }
      }
    };

    return this.invokeManageAffiliateTree(this.dataModelService, 'WizeFiAffiliateTree', 'updateNode', params);
  } // updateAlias

  public visit(visitOption: vOption, p: string, level: number, parms: any = null): void {
    switch (visitOption) {
      case vOption.DISPLAYTREE:
        this.visitDisplayTree(p, level);
        break;
      case vOption.GETLEVELCOUNT:
        this.visitGetLevelCount(p, level, parms);
        break;
      case vOption.GETLEVELTOTALCOUNT:
        this.visitGetLevelTotalCount(p, level, parms);
        break;
      case vOption.GETLEVELEARNINGS:
        this.visitGetLevelEarnings(p, level, parms);
        break;
      case vOption.GETTREESTATS:
        this.visitGetTreeStats(p, level, parms);
        break;
      case vOption.GETLEADERBOARDINFO:
        this.visitGetLeaderBoardInfo(p, level, parms);
    } // switch
  } // visit

  public visitDisplayTree(p: string, level: number) {
    let pad: string;
    let status: string;
    let node: INode;

    node = this.getNode(p);
    if (node === undefined) {
      console.log('node (' + p + ') does not exist in the affiliate tree');
    } else {
      pad = '';
      for (let i = 0; i < 3 * level; i++) {
        pad = pad + ' ';
      }
      status = node.isActive ? 'active' : 'inactive';
      console.log(pad + p + ' ' + this.ID2Alias[p] + '  ' + status + '  ' + node.fee + '  level: ' + level);
    }
  } // visitDisplayTree

  public visitGetLeaderBoardInfo(p: string, level: number, parms: any): void {
    const statParms = this.getTreeStats(p);
    parms.leaderBoardInfo.push({
      rank: 0,
      user: this.ID2Alias[p],
      affiliateCount: statParms.activeNodeCount
    });
  } // visitGetLeaderBoardInfo

  public visitGetLevelCount(p: string, level: number, parms: any): void {
    let node: INode;

    node = this.getNode(p);
    if (node === undefined) {
      // TODO consider logging this some place for further analysis
      console.log('node (' + p + ') does not exist in the affiliate tree');
    } else {
      if (node.isActive && level <= this.maxLevel) {
        parms.levelCount[level] += 1;
      }
    }
  } // visitGetLevelCount

  public visitGetLevelEarnings(p: string, level: number, parms: any): void {
    let node: INode;

    node = this.getNode(p);
    if (node === undefined) {
      // TODO consider logging this some place for further analysis
      console.log('node (' + p + ') does not exist in the affiliate tree');
    } else {
      if (node.isActive && level <= this.maxLevel) {
        parms.levelCount[level] += 1;
      }
    }
  } // visitGetLevelEarnings

  public visitGetLevelTotalCount(p: string, level: number, parms: any): void {
    let node: INode;

    node = this.getNode(p);
    if (node === undefined) {
      // TODO consider logging this some place for further analysis
      console.log('node (' + p + ') does not exist in the affiliate tree');
    } else {
      if (level <= this.maxLevel) {
        parms.levelCount[level] += 1;
      }
    }
  } // visitGetLevelTotalCount

  public visitGetTreeStats(p: string, level: number, parms: any): void {
    let node: INode;
    let childCount: number;

    node = this.getNode(p);
    if (node === undefined) {
      // TODO consider logging this some place for further analysis
      console.log('node (' + p + ') does not exist in the affiliate tree');
    } else {
      if (node.isActive) {
        parms.activeNodeCount += 1;
      }
      if (!node.isActive) {
        parms.inactiveNodeCount += 1;
      }
      parms.allNodeCount += 1;
      if (parms.height < level) {
        parms.height = level;
      }
      childCount = node.child.length;
      if (parms.maxWidth < childCount) {
        parms.maxWidth = childCount;
      }
    }
  } // visitGetTreeStats
} // AffiliateManagement
