import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { ITransactionRule } from '../../../interfaces/iTransactionRule.interface';
import { IWizeFiCategory } from '../../../interfaces/iWizeFiCategory.interface';
import { WizefiTransaction } from '../../../interfaces/wizefi-transaction';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { TransactionRuleService } from '../../../services/transaction-rule/transaction-rule.service';
import { DropdownLabelManagement } from '../../../utilities/dropdown-label-management.class';
import { AdminTestPlaidComponent } from '../../admin/admin-test-plaid/admin-test-plaid.component';
import { ITransactionRuleDialogData, TransactionRuleDialogComponent } from '../../transaction-rule-dialog/transaction-rule-dialog.component';

const UNCATEGORIZED = 'Uncategorized';
@Component({
  selector: 'app-edit-transaction-category',
  templateUrl: './edit-transaction-category.component.html',
  styleUrls: ['./edit-transaction-category.component.scss']
})
export class EditTransactionCategoryComponent implements OnInit, OnChanges {
  @Output() public onSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onCancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onRuleCreated: EventEmitter<void> = new EventEmitter<void>();
  @Input() public transaction: WizefiTransaction;
  @Input() public goToSplit = false;
  @Input() public cameDirectToSplit = false;

  protected selectedCategory = ''; // (actually category)
  protected selectedCategoryLabel = ''; // (category label)
  protected selectedSubcategory = ''; // (actually subcategory)
  protected selectedSubcategoryLabel = '';
  protected selectedAccount = ''; // (actually account)
  protected selectedWizeFiCategory = '';
  protected categoryLabels: string[] = [];
  protected subcategoryLabels: string[] = [];
  protected accountLabels: string[] = [];
  protected sortedlabels;
  protected wizeFiCategories: IWizeFiCategory[];
  public wizeFiCategorySelectList: any;
  public createdTransactionRule?: ITransactionRule;
  public wizeFiPlaidAccountName;
  public isLoading = false;
  public Disabled = false;
  public isDetailsExpanded = false;
  public wantManageSplitTransaction = false;
  public splitCount = 2;
  public splitTransactionInfo;
  public format;
  public showCreateNewSubCategory = false;
  public createNewSubCategoryForIndex = null;

  protected transactionYearMonth;
  protected monthTransactions;
  protected labelManagement;
  protected adminPlaid;
  protected originalTransaction;
  // setup

  constructor(
    protected dataModelService: DataModelService,
    protected router: Router,
    protected dialog: MatDialog,
    public transactionRuleService: TransactionRuleService
  ) {
    this.labelManagement = new DropdownLabelManagement(dataModelService);
    this.adminPlaid = new AdminTestPlaidComponent(this.dataModelService);
  }

  public async ngOnInit() {
    this.setupWizeFiCategories();
    this.transactionYearMonth = this.transaction.date.substr(0, 7);
    if (!this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection) {
      await this.dataModelService.plaidManagement.getPlaidData();
    }
    this.monthTransactions = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection[this.transactionYearMonth];
    this.categoryLabels = this.labelManagement.getCategoryLabels();
    this.subcategoryLabels = this.createSubcategoryLabels();
    this.accountLabels = this.createAccountLabels();
    this.wantManageSplitTransaction = false;
    this.wizeFiCategorySelectList = this.dataModelService.categoryManagement.getWizeFiCategoryList();
    this.format = 'accountName';
    this.Disabled = false;
    const originalTransaction = this.transaction.transaction_id.split('_');
    // Set Type default value to Budget
    this.cameDirectToSplit = this.goToSplit;
    this.selectCategory('Budget');
    if (originalTransaction.length > 1 && this.goToSplit) {
      this.originalTransaction = this.monthTransactions.find(t => t.transaction_id === originalTransaction[0]);
      this.splitTransactionInfo = this.getInitialSplitTransactionInfo();
      this.splitTransactionInfo.parent.transaction = this.originalTransaction;
      this.splitTransactionInfo.children = this.monthTransactions.filter(t => {
        if (t.transaction_id.includes(originalTransaction[0] + '_')) {
          return t;
        }
      });
      this.EditSplitTransactions();
    } else {
      this.splitTransactionInfo = this.getInitialSplitTransactionInfo();
      this.splitTransactionInfo.parent.transaction = this.transaction;
      if (this.goToSplit) {
        this.ToggleSplitTransactions();
      }
    }
    this.createdTransactionRule = this.transactionRuleService.getTransactionRules().find(i => i.source_id === this.transaction.transaction_id);
    this.getTransactionAccountNickname();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.transaction && changes.transaction.previousValue !== changes.transaction.currentValue) {
      // In case the transaction reference is changed to another one, we should lose the reference to the transaction rule created.
      this.createdTransactionRule = undefined;
    }
    if (changes.selectedCategory && changes.selectedCategory.previousValue !== changes.selectedCategory.currentValue) {
      console.log('changing category');
      this.createSubcategoryLabels();
    }
    if (changes.selectedSubcategory && changes.selectedSubcategory.previousValue !== changes.selectedSubcategory.currentValue) {
      console.log('changing subcategory');
      this.createAccountLabels();
    }
  }

  public getTransactionAccountNickname() {
    const WizefiPlaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    let account;
    if (WizefiPlaidAccounts) {
      account = this.wizeFiPlaidAccountName = WizefiPlaidAccounts.find(accountInfo => accountInfo.account_id === this.transaction.account_id);
    }

    if (account) {
      return account.accountName;
    } else {
      return '';
    }
  }

  public createSubcategoryLabels() {
    return this.labelManagement.getSubcategoryLabels(this.selectedCategory);
  }
  public sortAccountLabels(accountlabels) {
    console.log('sorting');
    let productiveAccounts;
    let nonproductiveAccounts;
    let limitedAccounts;
    accountlabels.forEach(label => {
      console.log(label);
    });
  }
  public createAccountLabels(category = null, subcategory = null) {
    let accountLabels, shouldGetCustomLabels;
    if (this.selectedCategory !== 'assets' && this.selectedCategory !== 'liabilities' && this.selectedCategory !== 'assetProtection') {
      shouldGetCustomLabels = false;
    } else {
      shouldGetCustomLabels = true;
    }

    if (category && subcategory) {
      accountLabels = this.labelManagement.getAccountLabels(category, subcategory, true);
    } else {
      accountLabels = this.labelManagement.getAccountLabels(this.selectedCategory, this.selectedSubcategory, true);
    }

    return accountLabels;
  }

  public dcat(format, wizeFiCategory) {
    return this.dataModelService.categoryManagement.reformatWizeFiCategory(format, wizeFiCategory);
  }

  public getInitialSplitTransactionInfo() {
    return this.dataModelService.categoryManagement.getInitialSplitTransactionInfo();
  } // getInitialSplitTransactionInfo

  public detailsClicked() {
    return (this.isDetailsExpanded = !this.isDetailsExpanded);
  }

  public splitTransaction() {
    this.wantManageSplitTransaction = this.goToSplit;
    this.splitCount = 2;
    this.splitTransactionInfo.parent.transaction.splitStatus = 0;
    console.log(this.splitTransactionInfo.children);
    console.log(this.splitTransactionInfo);
    this.createSplitTransactions();
  }

  public childAmountsTotal() {
    return this.dataModelService.categoryManagement.childAmountsTotal(this.splitTransactionInfo);
  } // childAmountsTotal

  public childAmountsDifference() {
    return this.dataModelService.categoryManagement.childAmountsDifference(this.splitTransactionInfo);
  } // childAmountsDifference

  // category dropdown management

  private setupWizeFiCategories() {
    this.wizeFiCategories = this.dataModelService.categoryManagement.getWizeFiCategories();
    let wizeFiCategory;
    if (!this.dataModelService.specialWizefiCategory.includes(this.transaction.wizeFiCategory)) {
      wizeFiCategory = this.dataModelService.categoryManagement.decodeWizeFiCategory(this.transaction.wizeFiCategory);
    }
    this.setSelectedDropdownItems(wizeFiCategory);
  }

  private setSelectedDropdownItems(wizeFiCategory) {
    if (this.transaction.wizeFiCategory !== 'unknown' && !this.selectedCategory) {
      console.log('we have a data problem');
      console.log(this.transaction);
      if (this.transaction.wizeFiCategory) {
        const decodedCat = this.dataModelService.categoryManagement.decodeWizeFiCategory(this.transaction.wizeFiCategory);
        console.log('i have a transaction cat');
        if (decodedCat) {
          console.log('i decoded it');
          this.selectedCategory = decodedCat.category;
          this.selectedSubcategory = decodedCat.subcategory;
          const curplan = this.dataModelService.dataModel.persistent.header.curplan;
          if (this.selectedCategory && this.selectedSubcategory) {
            console.log('i have a cat and subcat');
            if (this.dataModelService.dataModel.persistent.plans[curplan][this.selectedCategory]) {
              const accounts = this.dataModelService.dataModel.persistent.plans[curplan][this.selectedCategory][this.selectedSubcategory].accounts;
              console.log('i have an account');
              this.selectedAccount = this.dataModelService.categoryManagement.accountID2accountName(decodedCat.accountID, accounts);
            }
          } else {
            console.log('no cat or subcat');
            return;
          }
        } else {
          console.log('no decode');
          return;
        }
      } else {
        console.log('no transaction cat');
        return;
      }
    } else {
      if (
        wizeFiCategory !== undefined &&
        wizeFiCategory.category !== undefined &&
        wizeFiCategory.category !== 'unknown' &&
        wizeFiCategory.category !== 'none'
      ) {
        this.selectedCategory = wizeFiCategory.category;
        this.selectedSubcategory = wizeFiCategory.subcategory;
        this.selectedAccount = wizeFiCategory.accountName;
      } else {
        this.selectedCategory = UNCATEGORIZED;
        this.selectedSubcategory = UNCATEGORIZED;
        this.selectedAccount = UNCATEGORIZED;
      }
    }
    console.log(this.selectedCategory);
    console.log(this.selectedSubcategory);
    console.log(this.selectedAccount);
    this.createAccountLabels();
  }

  public isDisabled() {
    if (this.selectedSubcategory === UNCATEGORIZED || this.selectedAccount === UNCATEGORIZED) {
      if (this.selectedAccount !== 'ignore') {
        return (this.Disabled = true);
      }
    } else {
      return (this.Disabled = false);
    }
  }
  // exclude transactions
  public excludeTransaction() {
    this.selectedCategory = 'budget';
    this.selectedSubcategory = 'giving';
    this.selectedAccount = 'ignore';
    this.save();
    document.getElementById('edit-transaction-category-modal-card').scrollTop = 0;
    document.getElementById('wiz-modal').scrollTop = 0;
  }

  // saving transaction changes

  public cancel() {
    this.onCancel.emit();
  }

  public cancelSplitTransaction() {
    this.wantManageSplitTransaction = !this.wantManageSplitTransaction;
  }

  public presave() {
    this.isLoading = true;
    if (this.Disabled === true) {
      this.excludeTransaction();
    } else {
      this.save(true);
    }
  }

  public async save(fromSplit = false) {
    const confirmedWizeFiCategory = this.getConfirmedWizeFiCategory();

    if (this.selectedAccount === UNCATEGORIZED || this.selectedCategory === UNCATEGORIZED || this.selectedSubcategory === UNCATEGORIZED) {
      return this.dataModelService.showMessage('info', 'Select a category and subcategory to continue', 4000);
    }

    if (this.selectedCategory !== UNCATEGORIZED && this.selectedSubcategory !== UNCATEGORIZED) {
      this.transaction.wizeFiCategory = confirmedWizeFiCategory;
      this.updateTransactionInMemory(confirmedWizeFiCategory);

      await this.updateTransactionInDatabase(confirmedWizeFiCategory);
      this.onSave.emit(this.transaction);
      this.isLoading = false;
      this.selectedCategory = UNCATEGORIZED;
      this.selectedSubcategory = UNCATEGORIZED;
      this.selectedAccount = UNCATEGORIZED;
      this.Disabled = false;
      // Set Type default value to Budget
      this.selectCategory('Budget');
    }
  }

  private getCustomWizeFiCategory(cat, subcat, accountName) {
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;
    const accounts = this.dataModelService.dataModel.persistent.plans[curplan][cat][subcat].accounts;
    const accountID = this.dataModelService.categoryManagement.accountName2accountID(accountName, accounts);
    const confirmedWizeFiCategory = this.dataModelService.categoryManagement.makeWizeFiCategoryName(cat, subcat, accountID);
    return confirmedWizeFiCategory;
  }

  private getConfirmedWizeFiCategory() {
    if (this.Disabled) {
      return 'ignore';
    }

    if (this.selectedWizeFiCategory) {
      const confirmedWizeFiCategory = this.selectedWizeFiCategory;
      this.selectedWizeFiCategory = null;
      return confirmedWizeFiCategory;
    }

    if (this.selectedCategory !== UNCATEGORIZED && this.selectedSubcategory !== UNCATEGORIZED) {
      const curplan = this.dataModelService.dataModel.persistent.header.curplan;
      const accounts = this.dataModelService.dataModel.persistent.plans[curplan][this.selectedCategory][this.selectedSubcategory].accounts;
      const accountID = this.dataModelService.categoryManagement.accountName2accountID(this.selectedAccount, accounts);
      const confirmedWizeFiCategory = this.dataModelService.categoryManagement.makeWizeFiCategoryName(
        this.selectedCategory,
        this.selectedSubcategory,
        accountID
      );
      return confirmedWizeFiCategory;
    } else {
      this.dataModelService.showMessage('info', 'Select a category and subcategory to continue', 4000);
      return '';
    }
  }

  private updateTransactionInMemory(wizeFiCategory: string) {
    let monthsTransactions;
    if (this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection) {
      monthsTransactions = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection[this.transactionYearMonth];
      const theTransaction = monthsTransactions.find(t => t.transaction_id === this.transaction.transaction_id);
      if (theTransaction) {
        theTransaction.wizeFiCategory = wizeFiCategory;
      }
    }
    return true;
  }

  private async updateTransactionInDatabase(wizeFiCategory: string) {
    this.transaction.wizeFiCategory = wizeFiCategory;
    const wizeFiId = this.dataModelService.dataModel.global.wizeFiID;
    const yearMonth = this.transaction.date.substr(0, 7);
    const index = this.monthTransactions.findIndex(transaction => this.transaction.transaction_id === transaction.transaction_id);

    if (index !== -1) {
      this.monthTransactions[index] = this.transaction;
    }
    try {
      await this.dataModelService.dataManagement.putWizeFiTransactions(wizeFiId, yearMonth, this.monthTransactions);
    } catch (error) {
      console.error('edit-transaction-category: error saving transactions', error);
    }
  }

  // ************************* Label Dropdown management ********************************

  public selectCategory(categoryLabel: string, isChild: boolean = false, index: number = 0) {
    const category =
      categoryLabel === 'Debt' ? this.labelManagement.getCategoryFromLabel('Liability') : this.labelManagement.getCategoryFromLabel(categoryLabel);

    this.selectedCategoryLabel = categoryLabel;

    if (isChild) {
      if (this.splitTransactionInfo.children[index].type !== category) {
        this.splitTransactionInfo.children[index].cat = UNCATEGORIZED;
        this.splitTransactionInfo.children[index].subcat = UNCATEGORIZED;
      }
      this.splitTransactionInfo.children[index].type = category;
      this.splitTransactionInfo.children[index].typeLabel = categoryLabel;
      this.splitTransactionInfo.children[index].subcatLabels = this.labelManagement.getSubcategoryLabels(category);
    } else {
      if (this.selectedCategory !== category) {
        this.selectedSubcategory = UNCATEGORIZED;
        this.selectedAccount = UNCATEGORIZED;
      }
      this.selectedCategory = category;
      this.subcategoryLabels = this.labelManagement.getSubcategoryLabels(this.selectedCategory);

      this.createAccountLabels();
    }
  }

  public addSubcategory() {}

  public selectSubcategory(subcategoryLabel: string, isChild: boolean = false, index: number = 0) {
    if (isChild) {
      const category = this.splitTransactionInfo.children[index].type;
      const subcategory = this.labelManagement.getSubcategoryFromLabel(subcategoryLabel, category);

      this.splitTransactionInfo.children[index].cat = subcategory;
      this.splitTransactionInfo.children[index].catLabel = subcategoryLabel;
      this.splitTransactionInfo.children[index].accountLabels = this.createAccountLabels(category, subcategory).map(e => e.label);
    } else {
      const subcategory = this.labelManagement.getSubcategoryFromLabel(subcategoryLabel, this.selectedCategory);
      if (this.selectedSubcategory !== subcategory) {
        this.selectedAccount = UNCATEGORIZED;
      }
      this.selectedSubcategory = subcategory;
      this.accountLabels = this.createAccountLabels();
      this.sortAccountLabels(this.accountLabels);
    }
  }

  public selectAccountWithAccount(account) {
    if (account.wizeFiCategory) {
      this.selectedWizeFiCategory = account.wizeFiCategory;
    }
    this.selectedAccount = account.label;
  }

  public selectAccount(accountLabel: string, isChild: boolean = false, index: number = 0) {
    if (isChild) {
      this.splitTransactionInfo.children[index].subcat = accountLabel;
    } else {
      this.selectedAccount = accountLabel;
    }
  }

  public createSplitTransactions() {
    const wizeFiTransactions = this.monthTransactions;

    this.splitTransactionInfo.children.forEach(child => {
      let category = 'none';
      let subcategory = 'none';
      if (child.typeLabel) {
        category = this.labelManagement.getCategoryFromLabel(child.typeLabel);
      }

      if (child.catLabel && child.type) {
        child.showMessage = false;
        subcategory = this.labelManagement.getSubcategoryFromLabel(child.catLabel, child.type);
        child.wizeFiCategory = this.getCustomWizeFiCategory(category, subcategory, child.subcat);
      } else {
        child.showMessage = true;
      }
    });

    if (this.splitTransactionInfo.children.find(c => c.showMessage)) {
      this.dataModelService.showMessage('info', 'Select a category and subcategory to continue', 4000);
      return;
    } else if (this.splitTransactionInfo.children.find(c => !c.amount)) {
      this.dataModelService.showMessage('info', 'A value need to be defined', 4000);
      return;
    } else {
      this.dataModelService.categoryManagement.createSplitTransactions(this.splitTransactionInfo, wizeFiTransactions);
      wizeFiTransactions.sort(this.adminPlaid.wizeFiTransactionsCompare);

      // store the modified data in persistent storage
      this.saveWizeFiTransactions();
      this.wantManageSplitTransaction = false;
      this.onSave.emit(this.transaction);
      // this.presave();
    }
    // if(empty) {
    //   //show alert
    //   return;
    // }
  } // createSplitTransactions

  public saveWizeFiTransactions() {
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    const monthDate = this.transactionYearMonth;
    this.dataModelService.dataManagement
      .putWizeFiTransactions(wizeFiID, monthDate, this.monthTransactions)
      .then(() => {
        console.log('WizeFi transaction data has been saved');
      })
      .catch(err => {
        console.log(err);
      });
  } // saveWizeFiTransactions

  protected getAmount(transaction): number {
    const info = this.dataModelService.categoryManagement.extractWizeFiCategoryNameParts(transaction.wizeFiCategory);
    return info.category !== 'assets' ? -transaction.amount : transaction.amount;
  }

  public getAccounts(category, subcategory) {
    return this.labelManagement.getAccountLabels(category, subcategory, true).map(e => e.label);
  }

  public createSplitTransactionsExit() {
    this.wantManageSplitTransaction = false;
  } // createSplitTransactionsExit

  public ToggleDisabled() {
    this.Disabled = !this.Disabled;
    this.presave();
  }

  public EditSplitTransactions() {
    this.splitTransactionInfo.children.forEach((transaction, index) => {
      const decodedCategory = this.dataModelService.categoryManagement.decodeWizeFiCategory(transaction.wizeFiCategory);

      this.splitTransactionInfo.children[index].type = this.selectedCategory;
      this.splitTransactionInfo.children[index].typeLabel = this.selectedCategoryLabel;

      this.splitTransactionInfo.children[index].cat = decodedCategory.subcategory;
      // this.splitTransactionInfo.children[index].subcat = this.selectedCategoryLabel

      this.splitTransactionInfo.children[index].subcatLabels = this.labelManagement.getSubcategoryLabels(decodedCategory.category);

      this.splitTransactionInfo.children[index].subcat = decodedCategory.accountName;
      this.splitTransactionInfo.children[index].accountLabels = this.createAccountLabels();
      this.splitTransactionInfo.children[index].catLabel = this.splitTransactionInfo.children[index].subcatLabels.find(item =>
        item.toLowerCase().includes(decodedCategory.subcategory)
      );
    });

    const decodedOriginalCategory = this.splitTransactionInfo.parent.transaction.wizeFiCategory;
    if (decodedOriginalCategory === 'none') {
      this.selectedAccount = UNCATEGORIZED;
    }

    this.splitTransactionInfo.parent.transaction.splitStatus = 0;
    return (this.wantManageSplitTransaction = !this.wantManageSplitTransaction);
  }

  public ToggleSplitTransactions() {
    this.selectedSubcategoryLabel = this.labelManagement
      .getSubcategoryLabels(this.selectedCategory)
      .find(item => item.toLowerCase().includes(this.selectedSubcategory));

    this.splitTransactionInfo.children.forEach((transaction, index) => {
      this.splitTransactionInfo.children[index].type = this.selectedCategory;
      this.splitTransactionInfo.children[index].typeLabel = this.selectedCategoryLabel;
      this.splitTransactionInfo.children[index].subcatLabels = this.labelManagement.getSubcategoryLabels(this.selectedCategory);
    });

    this.splitTransactionInfo.parent.transaction.splitStatus = 0;
    return (this.wantManageSplitTransaction = !this.wantManageSplitTransaction);
  }

  public increaseSplitCount() {
    return (this.splitCount += 1);
  }

  public decreaseSplitCount() {
    return (this.splitCount -= 1);
  }

  public addSplit() {
    this.increaseSplitCount();
    this.dataModelService.categoryManagement.changeChildListLength(this.splitCount, this.splitTransactionInfo);

    this.splitTransactionInfo.children[this.splitCount - 1].type = this.selectedCategory;
    this.splitTransactionInfo.children[this.splitCount - 1].typeLabel = this.selectedCategoryLabel;
    this.splitTransactionInfo.children[this.splitCount - 1].subcatLabels = this.labelManagement.getSubcategoryLabels(this.selectedCategory);
  }

  public removeSplit(index) {
    this.decreaseSplitCount();
    this.splitTransactionInfo.children.splice(index, 1);
    return this.dataModelService.categoryManagement.changeChildListLength(this.splitCount, this.splitTransactionInfo);
  }

  public addChildAccount(i) {}
  public addChildCategory(category, index) {
    this.labelManagement.getSubcategoryLabels(this.splitTransactionInfo.children[index].type);
  }
  public addChildSubcat(i) {}

  /**
   * Save a new subcategory (account)
   *
   * @param subCategoryName
   */
  public saveNewSubCategory(subCategoryName) {
    let cat = this.selectedCategory;
    let subcat = this.selectedSubcategory;

    if (this.createNewSubCategoryForIndex !== null) {
      cat = this.splitTransactionInfo.children[this.createNewSubCategoryForIndex].type;
      subcat = this.splitTransactionInfo.children[this.createNewSubCategoryForIndex].cat;
    }

    // Get the current plan
    const plan = this.dataModelService.dataModel.persistent.header.curplan;
    // Get the wizefi account schema
    const account = this.dataModelService.categoryManagement.getWizeFiAccountSchema(cat, subcat);
    // Get all the accounts for this category/sub category and add the new account
    const accounts = this.dataModelService.dataModel.persistent.plans[plan][cat][subcat].accounts;
    const accountID = this.dataModelService.categoryManagement.getNewAccountID(accounts);
    const wizeFiCategory = this.dataModelService.categoryManagement.makeWizeFiCategory(cat, subcat, accountID);

    account.accountID.val = accountID;
    account.accountName.val = subCategoryName;
    account.wizeFiCategory.val = wizeFiCategory;
    accounts.push(account);

    this.dataModelService.dataManagement
      .storeinfo()
      .then(() => {
        if (this.createNewSubCategoryForIndex !== null) {
          this.splitTransactionInfo.children[this.createNewSubCategoryForIndex].accountLabels.push(subCategoryName);
          this.splitTransactionInfo.children[this.createNewSubCategoryForIndex].subcat = subCategoryName;
        } else {
          this.accountLabels.push(subCategoryName);
          this.selectedAccount = subCategoryName;
        }

        // Need to reset wizeFiCategories so other dropdowns will have new subcategory
        this.labelManagement.resetWizeFiCategories();
      })
      .catch(err => {
        console.log(err);
        this.dataModelService.showMessage('error', 'Error saving new sub-category: ' + err, 10000);
      })
      .finally(() => {
        this.showCreateNewSubCategory = false;
      });
  }

  public showCreateNewSubCategorySplitTrans(index) {
    this.createNewSubCategoryForIndex = index;
    this.showCreateNewSubCategory = true;
  }

  public openRulesDialog(): void {
    const transactionClone = { ...this.transaction };
    if (this.selectedAccount !== UNCATEGORIZED && this.selectedCategory !== UNCATEGORIZED && this.selectedSubcategory !== UNCATEGORIZED) {
      transactionClone.wizeFiCategory = this.getConfirmedWizeFiCategory();
    }

    const curplan = this.dataModelService.dataModel.persistent.header.curplan;

    let accounts = [];
    if (this.selectedCategory !== UNCATEGORIZED && this.selectedSubcategory !== UNCATEGORIZED) {
      accounts = this.dataModelService.dataModel.persistent.plans[curplan][this.selectedCategory][this.selectedSubcategory].accounts;
    }
    const accountId = this.dataModelService.categoryManagement.accountName2accountID(this.selectedAccount, accounts);

    const dialogData: ITransactionRuleDialogData = {
      transaction: this.transaction,
      rule: this.createdTransactionRule,
      selectedType: this.selectedCategory,
      selectedSubcategory: this.selectedSubcategory,
      selectedAccount: accountId
    };
    this.dialog
      .open<TransactionRuleDialogComponent, any, ITransactionRule | boolean>(TransactionRuleDialogComponent, { data: dialogData })
      .afterClosed()
      .subscribe(rule => {
        if (rule === true || rule) {
          this.createdTransactionRule = rule === true ? undefined : rule;
          this.transactionRuleService.runRulesForMonth(this.transaction.date);
          this.onRuleCreated.emit();
        }
      });
  }

  public closeSplitModal() {
    this.wantManageSplitTransaction = !this.wantManageSplitTransaction;
    setTimeout(() => {
      if (this.cameDirectToSplit) {
        this.cameDirectToSplit = false;
      }
    });
  }
}
