import { Injectable } from '@angular/core';
import { DataModelService } from '../data-model/data-model.service';

declare const AWS: any;

@Injectable()
export class LogMessageService {
  constructor(private dataModelService: DataModelService) {}

  public makeErrorObject(msg, err) {
    let systemMessage = 'unknown message';
    if (typeof err === 'string') {
      systemMessage = err;
    } else if (err !== null && typeof err === 'object') {
      if (err.hasOwnProperty('message')) {
        systemMessage = err.message;
      }
      // TODO accomodate possibility of whether err object can have message string in other than err.message
    }

    const customErr = new Error(msg + ' -- ' + systemMessage);

    // TODO determine whether to remove first line from stack stace
    // custom_err.stack = custom_err.stack.substring(custom_err.stack.indexOf("\n") + 1);  // remove first line from stack trace (which contains error message)
    return customErr;
  } // makeErrorObject

  public reportError(msg, stack) {
    // initialize
    const errtimestamp = new Date().toISOString();
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;

    // show message in console
    console.log('timestamp: ' + errtimestamp + '  wizeFiID: ' + wizeFiID + '  message: ' + msg + '  stack:');
    console.log(stack);

    // show message on screen
    // TODO determine whether to do this at all (perhaps add flag to determine whether to do it); consider alert message vs actual detailed message
    // this.dataModelService.showMessages('error', msg, 7000);

    /////////////////////////////////
    // log error in DynamoDB
    /////////////////////////////////

    // define params to guide put operation
    const params = {
      TableName: 'ErrorLog',
      Item: {
        wizeFiID,
        errtimestamp,
        message: msg,
        stack
      }
    };

    // put info in DynamoDB table
    const docClient = new AWS.DynamoDB.DocumentClient();
    docClient.put(params, (err, data) => {
      if (err) {
        console.log(err);
      } else {
        console.log('error has been logged in DynamoDB');
      }
    });
  } // reportError
} // class LogMessageService
