// plaid-management.class.ts

import { DataModelService } from '../services/data-model/data-model.service';
import { PlaidAccount } from '@app/interfaces/plaid-account';
import { PlaidInstitution } from '@app/interfaces/plaid-institution';
import { AddNewPlaidInstitutionResult } from '@app/interfaces/add-new-plaid-institution-result';
declare let Plaid: any;

export class PlaidManagement {
  public wizeFiID: string;

  constructor(public dataModelService: DataModelService) {}

  //////////////////////////////////////////////////////////////////////
  // routines to support access to Lambda function features
  //////////////////////////////////////////////////////////////////////

  public invokeManagePlaid(action: string, actionParms: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // set params to guide function invocation
      let payload = {
        action,
        actionParms
      };
      const params = {
        // FunctionName: "managePlaid",  // Plaid version 8.0.0
        FunctionName: 'managePlaid_new', // Plaid version 8.0.0
        Payload: JSON.stringify(payload)
      };

      // invoke lambda function to process data
      this.dataModelService.dataModel.global.lambda.invoke(params, (err, data) => {
        if (err) {
          console.log(err);
          if (err.error_code && err.error_code === 'ITEM_LOGIN_REQUIRED') {
            console.log('You need to use the Update Plaid Link feature to restore access to a financial institution');
          } else {
            reject(err);
          }
        } else {
          payload = JSON.parse(data.Payload);
          if (typeof payload === 'object' && payload.hasOwnProperty('errorMessage')) {
            reject(payload);
          } else {
            resolve(payload);
          }
        }
      }); // lambda invoke
    }); // return Promise
  } // invokeManagePlaid

  public runTest() {
    return this.invokeManagePlaid('runTest', {});
  }

  public createLinkToken(wizeFiID, country_codes) {
    return this.invokeManagePlaid('createLinkToken', { wizeFiID, country_codes });
  }

  public createUpdateLinkToken(wizeFiID, country_codes, access_token) {
    return this.invokeManagePlaid('createUpdateLinkToken', { wizeFiID, country_codes, access_token });
  }

  public getAccessTokenNeedUpdate(wizeFiID, title, country_codes) {
    return this.invokeManagePlaid('getAccessTokenNeedUpdate', { wizeFiID, title, country_codes });
  }

  public getPublicKey() {
    return this.invokeManagePlaid('getPublicKey', {});
  }

  public getPlaidEnv() {
    return this.invokeManagePlaid('getPlaidEnv', {});
  }

  public resetLogin(access_token) {
    return this.invokeManagePlaid('resetLogin', { access_token });
  }

  public getWizeFiPlaidInstitutions(wizeFiID) {
    return this.invokeManagePlaid('getWizeFiPlaidInstitutions', { wizeFiID });
  }

  public copyPlaidData(wizeFiID, srce_title, dest_title) {
    return this.invokeManagePlaid('copyPlaidData', { wizeFiID, srce_title, dest_title });
  }

  public getDraftPlaidInstitutions(wizeFiID, title) {
    return this.invokeManagePlaid('getDraftPlaidInstitutions', { wizeFiID, title });
  }

  public addInstitution(wizeFiID: string, title: string, publicToken: string, countryCodes: string[]): Promise<AddNewPlaidInstitutionResult> {
    return this.invokeManagePlaid('addInstitution', { wizeFiID, title, public_token: publicToken, country_codes: countryCodes });
  }

  public deleteWizeFiPlaidInstitution(wizeFiID, item_id, itemIdCount) {
    return this.invokeManagePlaid('deleteWizeFiPlaidInstitution', { wizeFiID, item_id, itemIdCount });
  }

  public deleteDraftPlaidInstitution(wizeFiID, title, item_id, itemIdCount) {
    return this.invokeManagePlaid('deleteDraftPlaidInstitution', { wizeFiID, title, item_id, itemIdCount });
  }

  public addManualInstitution(institution: PlaidInstitution) {
    return this.invokeManagePlaid('addManualInstitution', {
      wizeFiID: this.dataModelService.dataModel.global.wizeFiID,
      institution,
      title: this.dataModelService.dataManagement.getDraftTitle()
    });
  }

  public deleteManualInstitution(itemId: string) {
    return this.invokeManagePlaid('deleteManualInstitution', {
      wizeFiID: this.dataModelService.dataModel.global.wizeFiID,
      item_id: itemId,
      title: this.dataModelService.dataManagement.getDraftTitle()
    });
  }

  public setInstitutionIsActive(wizeFiID, itemId, isActive) {
    return this.invokeManagePlaid('setInstitutionIsActive', { wizeFiID, item_id: itemId, isActive });
  }

  public setTransactionDate(wizeFiID, title, transactionDate) {
    return this.invokeManagePlaid('setTransactionDate', { wizeFiID, title, transactionDate });
  }

  public updateInstitutionErrorStatus(wizeFiID, title) {
    return this.invokeManagePlaid('updateInstitutionErrorStatus', { wizeFiID, title });
  }

  public async updateBalance(wizeFiID, title) {
    const plaidAccounts = await this.invokeManagePlaid('updateBalance', { wizeFiID, title });
    this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts = plaidAccounts;
    return plaidAccounts;
  }

  public getInstitutionById(institution_id, countryCodes) {
    return this.invokeManagePlaid('getInstitutionById', { institution_id, countryCodes });
  }

  public searchInstitutionsByName(searchQuery, products, countryCodes) {
    return this.invokeManagePlaid('searchInstitutionsByName', { searchQuery, products, countryCodes });
  }

  public getLinkTokenInfo(wizeFiID, title, country_codes) {
    return this.invokeManagePlaid('getLinkTokenInfo', { wizeFiID, title, country_codes });
  }

  public getCategories() {
    return this.invokeManagePlaid('getCategories', {});
  }

  public getAccounts(wizeFiID, title) {
    return this.invokeManagePlaid('getAccounts', { wizeFiID, title });
  }

  public async getPlaidInstitutions(wizeFiID: string, title: string) {
    return title === '' ? this.getWizeFiPlaidInstitutions(wizeFiID) : this.getDraftPlaidInstitutions(wizeFiID, title);
  }

  /*
  This routine will return information about modified plaidInstitutions and plaidAccounts.  In addition it will provide the deleteWizeFiCategoryList.

  It will also handle side effects of an institution deletion:
  a) Utilize the deleteWizeFiCategoryList to guide the deletion of user WizeFi accounts from the accounts array.
  b) Reset wizeFiCategory to "unknown" in transactions that do not have a wizeFiCategory value that corresponds to a valid WizeFi account in the accounts array.
  */
  public async deletePlaidInstitution(wizeFiID, title, itemId, itemIdCount) {
    /*
      This routine will delete WizeFi user accounts from the accounts array for each wizeFiCategory in the deleteWizeFiCategoryList.

      The information in deleteWizeFiCategoryList is based on wizeFiPlaidAccounts data that was deleted when an institution was deleted.
      */
    const deleteWizeFiUserAccounts = async deleteWizeFiCategoryList => {
      // initialize
      const categoryManagement = this.dataModelService.categoryManagement;
      const curplan = this.dataModelService.dataModel.persistent.header.curplan;
      const plans = this.dataModelService.dataModel.persistent.plans;

      // process each wizeFiCategory
      let haveChange = false;
      for (const wizeFiCategory of deleteWizeFiCategoryList) {
        if (categoryManagement.isValidWizeFiCategoryAccount(wizeFiCategory)) {
          haveChange = true;
          const info = categoryManagement.decodeWizeFiCategory(wizeFiCategory);
          plans[curplan][info.category][info.subcategory].accounts.splice(info.acntndx, 1);
        }
      }

      if (haveChange) {
        await this.dataModelService.dataManagement.storeinfo(); // store data in DynamoDB
      }
    }; // deleteWizeFiUserAccounts
    /*
      This routine will scan through all of the transactions in a given monthDate, and set transaction.wizeFiCategory to "unknown" if the current value of wizeFiCategory does not correspond to an actual WizeFi account in the accounts array in a user WizeFi schema.

      @MonthDate is in the format YYYY-MM
      */
    const updateTransactionsWizeFiCategory = async (wizeFiID, title, monthDate, wizeFiPlaidAccounts) => {
      // initialize
      let changeCount = 0;

      // transactions are only updated for user data (not draft data)
      if (title === '') {
        // initialize
        const dateRange = { wantMonthRange: true, yearMonth: monthDate };
        const activeWizeFiPlaidAccountIds = this.dataModelService.plaidManagement.setActiveWizeFiPlaidAccountIds(wizeFiPlaidAccounts);
        const transactions: any = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection[monthDate];

        // scan through all transactions
        for (const transaction of transactions) {
          const wizeFiCategory = transaction.wizeFiCategory;
          if (!this.dataModelService.categoryManagement.isValidWizeFiCategoryAccount(wizeFiCategory)) {
            if (!['none', 'unknown', 'ignore'].includes(wizeFiCategory)) {
              changeCount++;
              transaction.wizeFiCategory = 'unknown';
            }
          }
        }

        if (changeCount > 0) {
          await this.dataModelService.dataManagement.putWizeFiTransactions(wizeFiID, monthDate, transactions);
        }
      }
      return changeCount;
    }; // updateTransactionsWizeFiCategory

    // initialize
    let result;

    // process the proper data
    if (title === '') {
      result = await this.deleteWizeFiPlaidInstitution(wizeFiID, itemId, itemIdCount);
    } else {
      result = await this.deleteDraftPlaidInstitution(wizeFiID, title, itemId, itemIdCount);
    }

    // delete the appropriate WizeFi user accounts
    await deleteWizeFiUserAccounts(result.deleteWizeFiCategoryList);

    // update the appropriate wizeFiCategory for transactions
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;
    const monthDate = this.dataModelService.setPlanDate(curplan);
    result.changeCount = await updateTransactionsWizeFiCategory(wizeFiID, title, monthDate, result.wizeFiPlaidAccounts);

    // update the global wizeFiPlaidInstitutions and wizeFiPlaidAccounts
    this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions = result.wizeFiPlaidInstitutions;
    this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts = result.wizeFiPlaidAccounts;

    return result;
  }

  public getTransactions(wizeFiID, title, dateRange, activeWizeFiPlaidAccountIds) {
    if (!wizeFiID) {
      console.error('no wizefi id');
      return;
    }
    if (!dateRange) {
      console.error('no date range');
      return;
    }
    if (!activeWizeFiPlaidAccountIds) {
      console.error('no activeWizeFiPlaidAccountIds');
      return;
    }
    const action = 'getTransactions';
    const actionParms = { wizeFiID, title, dateRange, activeWizeFiPlaidAccountIds };
    return this.invokeManagePlaid(action, actionParms);
  } // getTransactions

  //////////////////////////////////////////////////////////////////////
  // routines to support access to DynamoDB data
  //////////////////////////////////////////////////////////////////////

  public async fetchWizeFiPlaidAccounts(wizeFiID: string): Promise<PlaidAccount[]> {
    const params = {
      TableName: 'WizeFiPlaidAccounts',
      Key: { wizeFiID }
    };

    const data = await this.dataModelService.dataModel.global.docClient.get(params).promise();
    let wizeFiPlaidAccounts = [];
    if (data && data.hasOwnProperty('Item') && data.Item.wizeFiPlaidAccounts) {
      wizeFiPlaidAccounts = JSON.parse(data.Item.wizeFiPlaidAccounts);
    }
    return wizeFiPlaidAccounts;
  }

  public fetchDraftPlaidAccounts(wizeFiID: string, title: string): Promise<PlaidAccount[]> {
    return new Promise((resolve, reject) => {
      // define params to guide get operation
      const params = {
        TableName: 'DraftPlaidAccounts',
        Key: { wizeFiID, title }
      };

      // get info from DynamoDB table
      this.dataModelService.dataModel.global.docClient.get(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          let draftPlaidAccounts = [];
          if (data && data.hasOwnProperty('Item') && data.Item.draftPlaidAccounts) {
            draftPlaidAccounts = JSON.parse(data.Item.draftPlaidAccounts);
          }
          resolve(draftPlaidAccounts);
        }
      });
    });
  }

  public async fetchPlaidAccounts(wizeFiID: string, title: string): Promise<PlaidAccount[]> {
    return title === '' ? this.fetchWizeFiPlaidAccounts(wizeFiID) : this.fetchDraftPlaidAccounts(wizeFiID, title);
  }

  private storeWizeFiPlaidAccounts(wizeFiID: string, wizeFiPlaidAccounts: PlaidAccount[]) {
    return new Promise((resolve, reject) => {
      // define params to guide put operation
      const params = {
        TableName: 'WizeFiPlaidAccounts',
        Item: {
          wizeFiID,
          wizeFiPlaidAccounts: JSON.stringify(wizeFiPlaidAccounts)
        }
      };

      // put info into DynamoDB table
      this.dataModelService.dataModel.global.docClient.put(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      });
    });
  }

  private storeDraftPlaidAccounts(wizeFiID: string, title: string, draftPlaidAccounts: PlaidAccount[]) {
    return new Promise((resolve, reject) => {
      // define params to guide put operation
      const params = {
        TableName: 'DraftPlaidAccounts',
        Item: {
          wizeFiID,
          title,
          draftPlaidAccounts: JSON.stringify(draftPlaidAccounts)
        }
      };

      // put info into DynamoDB table
      this.dataModelService.dataModel.global.docClient.put(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      });
    });
  }

  public async storePlaidAccounts(wizeFiID: string, title: string, plaidAccounts: PlaidAccount[]) {
    return title === '' ? this.storeWizeFiPlaidAccounts(wizeFiID, plaidAccounts) : this.storeDraftPlaidAccounts(wizeFiID, title, plaidAccounts);
  }

  public convertBalanceCurrency(wizeFiPlaidAccounts: PlaidAccount[]) {
    const userCurrencyCode = this.dataModelService.dataModel.persistent.settings.currencyCode;
    const decimalDigits = this.dataModelService.dataModel.persistent.settings.decimalDigits;
    for (const wizeFiPlaidAccount of wizeFiPlaidAccounts) {
      const originalBalance = wizeFiPlaidAccount.originalBalance;
      const originalCurrencyCode = wizeFiPlaidAccount.originalCurrencyCode;
      wizeFiPlaidAccount.balance = this.dataModelService.ancillaryDataManagement.convertCurrency(
        originalBalance,
        originalCurrencyCode,
        userCurrencyCode
      );
      wizeFiPlaidAccount.balance = Number(wizeFiPlaidAccount.balance.toFixed(decimalDigits)); // round to proper number of digits after the decimal
    }
  }

  public async updateAllBalances(wizeFiID, title) {
    // plant balance update information in wizeFiPlaidAccounts (or draftPlaidAccounts)
    const wizeFiPlaidAccounts = await this.updateBalance(wizeFiID, title);

    // convert balance amount to currency used by the user with the current wizeFiID
    this.convertBalanceCurrency(wizeFiPlaidAccounts);

    // update global variable
    this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts = wizeFiPlaidAccounts;

    // updateDynamoDB
    await this.storePlaidAccounts(wizeFiID, title, wizeFiPlaidAccounts);

    // obtain map of wizeFi account in accounts array for each wizeFiCategory
    const curplan = this.dataModelService.dataModel.persistent.header.curplan;
    const wizeFiCategory2wizeFiAccount = this.dataModelService.categoryManagement.getWizeFiCategory2wizeFiAccount(curplan);

    // plant balance update information in each appropriate wizeFiPlaidAccount
    let dataUpdated = false;
    for (const wizeFiPlaidAccount of wizeFiPlaidAccounts) {
      if (wizeFiPlaidAccount && wizeFiPlaidAccount.isManual === false) {
        const wizeFiCategory = wizeFiPlaidAccount.wizeFiCategory;
        if (wizeFiPlaidAccount.isActive && this.dataModelService.categoryManagement.isValidWizeFiCategoryAccount(wizeFiCategory)) {
          dataUpdated = true;
          const account = wizeFiCategory2wizeFiAccount[wizeFiCategory];
          console.log('updating account', account);
          if (!account.hasOwnProperty('accountValue') || typeof account.accountValue === 'number') {
            account.accountValue = {
              label: 'Account Balance',
              isRequired: true,
              val: 0
            };
          }
          account.accountValue.val = wizeFiPlaidAccount.balance;

          if (!account.hasOwnProperty('balanceDate') || typeof account.balanceDate === 'string') {
            account.balanceDate = {
              label: 'Balance Date',
              isRequired: true,
              val: ''
            };
          }
          account.balanceDate.val = wizeFiPlaidAccount.balanceDate;
        }
      } else {
        console.log('manual account:', wizeFiPlaidAccount);
      }
    }

    // TODO determine whether there is a better place in the app flow to store updated balance data to DynamoDB
    if (dataUpdated) {
      await this.dataModelService.dataManagement.storeinfo();
    }

    return wizeFiPlaidAccounts;
  } // updateAllBalances

  public getWizeFiTransactionsDateList(wizeFiID /*
  This routine returns a list of yearMonth values for all wizeFiTransactions in DynamoDB.
  */) {
    return new Promise((resolve, reject) => {
      // define params to guide query operation
      const params = {
        TableName: 'WizeFiTransactions',
        KeyConditionExpression: '#wizeFiID = :wizeFiID',
        ExpressionAttributeNames: {
          '#wizeFiID': 'wizeFiID',
          '#monthDate': 'monthDate'
        },
        ExpressionAttributeValues: { ':wizeFiID': wizeFiID },
        ProjectionExpression: '#monthDate'
      };

      this.dataModelService.dataModel.global.docClient.query(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const wizeFiTransactionsDateList = [];
          for (const item of data.Items) {
            wizeFiTransactionsDateList.push(item.monthDate);
          }
          wizeFiTransactionsDateList.sort();

          resolve(wizeFiTransactionsDateList);
        }
      });
    }); // return Promise
  } // getWizeFiTransactionsDateList

  public async getPlaidData() {
    const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
    const endDate = new Date().toISOString().substr(0, 7);
    const startDate = this.dataModelService.changeDate(endDate, 0, -11, 0).substr(0, 7);

    const title = this.dataModelService.dataManagement.getDraftTitle();
    const promiseResults = Promise.all([
      this.getPlaidInstitutions(wizeFiID, title),
      this.fetchPlaidAccounts(wizeFiID, title),
      this.dataModelService.dataManagement.getwizeFiTransactionsCollection(wizeFiID, startDate, endDate),
      this.dataModelService.categoryManagement.getWizeFiTransactionAttributePatterns(wizeFiID),
      this.dataModelService.categoryManagement.getWizeFiPlaidCategoryMap('WizeFi')
    ]);
    const [
      wizeFiPlaidInstitutions,
      wizeFiPlaidAccounts,
      wizeFiTransactionsCollection,
      wizeFiTransactionAttributePatterns,
      wizeFiPlaidCategoryMap
    ] = await promiseResults;

    // place data in the global area of the data model
    this.dataModelService.dataModel.global.plaidData = {
      wizeFiPlaidInstitutions,
      wizeFiPlaidAccounts,
      wizeFiTransactionsCollection,
      wizeFiTransactionAttributePatterns,
      wizeFiPlaidCategoryMap
    };
  } // getPlaidData

  //////////////////////////////////////////////////////////////////////
  // utility routines
  //////////////////////////////////////////////////////////////////////

  public setActiveWizeFiPlaidAccountIds(wizeFiPlaidAccounts) {
    const institutionIsActive = itemId => {
      let isActive = false;
      if (this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions) {
        for (const institution of this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions) {
          if (institution.item_id === itemId && institution.isActive) {
            isActive = true;
          }
        }
      }
      return isActive;
    }; // institutionIsActive

    const activeWizeFiPlaidAccountIds = {};
    if (wizeFiPlaidAccounts) {
      for (const account of wizeFiPlaidAccounts) {
        if (!account.isManual) {
          if (!account.isManual && account.isActive && account.status === '1' && institutionIsActive(account.item_id)) {
            if (!activeWizeFiPlaidAccountIds.hasOwnProperty(account.item_id)) {
              activeWizeFiPlaidAccountIds[account.item_id] = [];
            }
            activeWizeFiPlaidAccountIds[account.item_id].push(account.account_id);
          }
        }
      }
    }

    return activeWizeFiPlaidAccountIds;
  } // setActiveWizeFiPlaidAccountIds

  public loadPlaidLink(title: string): Promise<undefined | AddNewPlaidInstitutionResult> {
    return new Promise((resolve, reject) => {
      const loadPlaidLink0 = async () => {
        // initialize
        const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
        const countryCodes = this.dataModelService.dataModel.persistent.settings.userCountryCodes;
        const linkToken = await this.dataModelService.plaidManagement.createLinkToken(wizeFiID, countryCodes).catch(err => console.error(err));

        // set configuration to guide the institution link process
        const configs = {
          token: linkToken,
          onLoad: () => {},
          onEvent: (eventName, metadata) => {},
          onSuccess: async (publicToken, metadata) => {
            if (!this.dataModelService.plaidManagement.isNewInstitutionInstance(metadata)) {
              this.dataModelService.showErrorMessage('This institution has already been linked to your WizeFi Account', 20000);
              resolve(undefined);
            } else {
              const result = await this.dataModelService.plaidManagement.addInstitution(wizeFiID, title, publicToken, countryCodes);
              this.dataModelService.plaidManagement.convertBalanceCurrency(result.wizeFiPlaidAccounts.filter(a => !a.isManual));
              await this.getPlaidData();
              this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions = result.wizeFiPlaidInstitutions;
              this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts = result.wizeFiPlaidAccounts;
              resolve(result);
            }
          },
          onExit: async (err, metadata) => {
            if (err != null) {
              reject(err);
            }
          }
        };

        // perform the link process
        const linkHandler = Plaid.create(configs);
        linkHandler.open();
      }; // loadPlaidLink0

      loadPlaidLink0().catch(err => {
        console.error(err);
      });
    }); // return Promise
  } // loadPlaidLink

  public async loadUpdatePlaidLink(title) {
    return new Promise((resolve, reject) => {
      const loadUpdatePlaidLink0 = async () => {
        // initizliae
        const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
        const country_codes = this.dataModelService.dataModel.persistent.settings.userCountryCodes;
        const linkTokenInfo = await this.getLinkTokenInfo(wizeFiID, title, country_codes);
        const tokenCount = linkTokenInfo.tokenCount;
        const link_token = linkTokenInfo.link_token;

        if (tokenCount == 0) {
          console.log('There is no institution that needs to be updated'); //%//
          this.dataModelService.showMessage('info', 'There is no institution that needs to be updated', 10000);
          resolve(tokenCount);
        } else {
          // create Plaid object to enable update of link with financial institution
          const configs = {
            token: link_token,
            clientName: 'WizeFi',
            countryCodes: country_codes,
            language: 'en',
            onLoad: () => {},
            onSuccess: (public_token, metadata) => {
              resolve(tokenCount);
            },
            onExit: (err, metadata) => {
              if (err != null) {
                console.log('Plaid.create -- onExit -- error: ', err);
                reject(err);
              } else {
                resolve(tokenCount);
              }
            }
          };
          const updatePaidLink = Plaid.create(configs);

          // activate the screen interface for handling this feature
          updatePaidLink.open();
        }
      }; // loadUpdatePlaidLink0

      loadUpdatePlaidLink0().catch(err => {
        console.log('error in loadUpdatePlaidLink', err);
      });
    }); // return Promise
  } // loadUpdatePlaidLink

  public async updateWizeFiTransactions(yearMonth) {
    const wizeFiTransactionsCompare = (t1, t2) => {
      let result = 0;
      if (t1.date < t2.date) {
        result = 1;
      } else if (t1.date > t2.date) {
        result = -1;
      } else if (t1.institutionName < t2.institutionName) {
        result = -1;
      } else if (t1.institutionName > t2.institutionName) {
        result = 1;
      } else if (t1.accountName < t2.accountName) {
        result = -1;
      } else if (t1.accountName < t2.accountName) {
        result = 1;
      }
      return result;
    }; // wizeFiTransactionsCompare

    const isNewPlaidTransaction = (plaidTransaction, funcWizeFiTransactions) => {
      let isNew = true;
      let i = -1;
      while (++i < funcWizeFiTransactions.length && isNew) {
        if (plaidTransaction.transaction_id === funcWizeFiTransactions[i].transaction_id) {
          isNew = false;
        }
      }
      return isNew;
    }; // isNewPlaidTransaction

    // initialize
    let haveNewData = false;
    let wizeFiTransactions;

    if (
      this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection &&
      !this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection.hasOwnProperty(yearMonth)
    ) {
      wizeFiTransactions = [];
      this.dataModelService.showErrorMessage(
        yearMonth + ' is not within the range of the 12 months of data now in memory ending with the current month',
        10000
      );
    } else {
      if (this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection) {
        wizeFiTransactions = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection[yearMonth];
      } else {
        await this.dataModelService.plaidManagement
          .getPlaidData()
          .then(() => {
            wizeFiTransactions = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection[yearMonth];
          })
          .catch(() => {
            wizeFiTransactions = [];
          });
      }
      const wizeFiID = this.dataModelService.dataModel.global.wizeFiID;
      const wizeFiPlaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
      const activeWizeFiPlaidAccountIds = this.setActiveWizeFiPlaidAccountIds(wizeFiPlaidAccounts);
      const dateRange = { wantMonthRange: true, yearMonth };

      // retrieve the Plaid transactions
      const title = this.dataModelService.dataManagement.getDraftTitle();
      const transactionInfo = await this.getTransactions(wizeFiID, title, dateRange, activeWizeFiPlaidAccountIds);
      const plaidTransactions = transactionInfo.transactions;

      // add to wizeFiTransactions any plaidTransactions that are new
      for (const plaidTransaction of plaidTransactions) {
        if (isNewPlaidTransaction(plaidTransaction, wizeFiTransactions)) {
          haveNewData = true;

          // remove attributes from the transaction that are not of interest
          delete plaidTransaction.category_id;

          // add attributes to the transaction
          plaidTransaction.isManual = false;
          plaidTransaction.wizeFiCategory = 'unknown';
          plaidTransaction.splitStatus = 0;
          plaidTransaction.ruleApplied = false;

          // handle management of currencyCode
          const funcUserCurrencyCode = this.dataModelService.dataModel.persistent.settings.currencyCode;
          const funcOriginalCurrencyCode = plaidTransaction.iso_currency_code;
          delete plaidTransaction.iso_currency_code; // remove iso_currency_code from result (it is replaced by originalCurrencyCode)
          const funcOriginalAmount = plaidTransaction.amount;
          let funcAmount = this.dataModelService.ancillaryDataManagement.convertCurrency(
            funcOriginalAmount,
            funcOriginalCurrencyCode,
            funcUserCurrencyCode
          );

          // round result as specifed by decimalDigits
          const funcDecimalDigits = this.dataModelService.dataModel.persistent.settings.decimalDigits;
          funcAmount = Number(funcAmount.toFixed(funcDecimalDigits));

          plaidTransaction.originalAmount = funcOriginalAmount;
          plaidTransaction.originalCurrencyCode = funcOriginalCurrencyCode;
          plaidTransaction.amount = funcAmount;

          // add this plaidTransaction to wizeFiTransactions
          wizeFiTransactions.push(plaidTransaction);
        }
      } // for

      // store new data in DynamoDB
      if (haveNewData) {
        wizeFiTransactions.sort(wizeFiTransactionsCompare);
        await this.dataModelService.dataManagement.putWizeFiTransactions(wizeFiID, yearMonth, wizeFiTransactions);
      }
    }
    return wizeFiTransactions;
  } // updateWizeFiTransactions

  public setCurrencyCode(destination) {
    // this is where you pass the destination to the SetCurrencyCode modal popup
    // for example: this.dataModelService.dataModel.global.destination = destination;

    // use the following kludge to simulate invocation of the SetCurrencyCode modal popup
    confirm('Select your currency code');

    // this is where you get the return value from the SetCurrencyCodes modal popup
    // for example: const currencyCode = this.dataModelService.dataModel.global.selectedCurrencyCode;
    const currencyCode = this.dataModelService.dataModel.persistent.settings.currencyCode; // temporary code until the above is implemented

    return currencyCode;
  } // setCurrencyCode

  public setCountryCodes() {
    // use the following kludge to simulate invocation of the SetCountryCodes modal popup
    confirm('Select your country codes');

    // this is where you get the return value from SetCountryCodes modal popup
    // for example: const userCountryCodes = this.dataModelService.dataModel.global.selectedCountryCodes;
    const userCountryCodes = this.dataModelService.dataModel.persistent.settings.userCountryCodes; // temporary code until the above is implemented
    return userCountryCodes;
  } // setCountryCodes

  public wantMultipleInstitutionInstances(metadata) {
    // this is where you pass the metadata to the MultipleInstitutionInstances modal popup
    // for example: this.dataModelService.dataModel.global.metadata = metadata;

    // use the following kludge to simulate invocation of the MultipleInstitutionInstances modal popup
    let wantMultipleInstances = false;
    if (confirm('Choose whether to allow multiple institution instances')) {
      wantMultipleInstances = true;
    } else {
      wantMultipleInstances = false;
    }

    return wantMultipleInstances;
  } // wantMultipleInstitutionInstances

  public haveMultipleInstance(institution_id) {
    const wizeFiPlaidInstitutions = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidInstitutions;
    let haveInstitution_id = false;
    for (const wizeFiPlaidInstitution of wizeFiPlaidInstitutions) {
      if (wizeFiPlaidInstitution.institution_id == institution_id) {
        haveInstitution_id = true;
      }
      if (haveInstitution_id) {
        break;
      } // terminate for loop upon finding that the given institution_id is present
    }
    return haveInstitution_id;
  } // haveMultipleInstance

  public isNewInstitutionInstance(metadata) {
    const makeAccountListString = accountList => {
      accountList.sort();
      let accountListString = '';
      for (const account of accountList) {
        const prefix = accountListString === '' ? '' : ':';
        accountListString += prefix + account;
      }
      return accountListString;
    }; // makeAccountListString

    // initialize
    const wizeFiPlaidAccounts = this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts;
    const institution_id = metadata.institution.institution_id;

    // build list of new accounts to be added for newly linked institution instance
    const newAccountList = [];
    for (const account of metadata.accounts) {
      newAccountList.push(account.name + '(' + account.mask + ')');
    }
    const newAccountListString = makeAccountListString(newAccountList);

    // build lists of accounts for already existing institution instances with the same institution_id (grouped by item_id)
    let existingAccounts: any;
    existingAccounts = {};
    for (const wizeFiPlaidAccount of wizeFiPlaidAccounts) {
      if (wizeFiPlaidAccount.institution_id == institution_id) {
        const item_id = wizeFiPlaidAccount.item_id;
        if (!existingAccounts.hasOwnProperty(item_id)) {
          existingAccounts[item_id] = [];
        }
        existingAccounts[item_id].push(wizeFiPlaidAccount.accountName + '(' + wizeFiPlaidAccount.mask + ')');
      }
    }
    // determine whether the list of accounts in the metadata is different from all of the institution instances with the same institution_id in wizeFiPlaidAccounts
    let haveDifferentAccounts = true;
    for (const item_id of Object.keys(existingAccounts)) {
      haveDifferentAccounts = newAccountListString != makeAccountListString(existingAccounts[item_id]);
      if (!haveDifferentAccounts) {
        break;
      } // terminate the for loop if an existing institution instance has the same accounts as the metadata
    }

    return haveDifferentAccounts;
  }
}
