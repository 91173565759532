import { Component, OnInit, ViewChild } from '@angular/core';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { SetupFlowDataManagementService, SetupFlowScreen } from '../../../services/setup/setup-flow-data-management.service';
import { ReallocateSpendingComponent } from '../reallocate-spending/reallocate-spending.component';
import { SetupService } from '@app/services/setup.service';

@Component({
  selector: 'app-create-cafr-screen',
  templateUrl: './create-cafr-screen.component.html',
  styleUrls: ['./create-cafr-screen.component.scss']
})
export class CreateCafrScreenComponent implements SetupFlowScreen, OnInit {
  @ViewChild(ReallocateSpendingComponent, { static: false }) public reallocateSpending: ReallocateSpendingComponent;

  constructor(
    protected dataModelService: DataModelService,
    protected dataManagement: SetupFlowDataManagementService,
    private setupService: SetupService
  ) {
    this.dataManagement.currentSetupScreen = this;
  }

  public async ngOnInit() {
    this.setupService.updateCurrentSetupStep(7);
  }

  public canSave() {
    return this.reallocateSpending.plannedSpending <= this.reallocateSpending.totalIncome;
  }

  public onSave() {
    this.reallocateSpending.onSave();
  }

  public showOnSaveErrorMessage(): void {
    this.dataModelService.showErrorMessage('Your budget exceeds your income. Please reduce your budget accordingly.', 3000);
  }
}
