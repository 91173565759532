import { Component, Input, OnInit } from '@angular/core';
import { DebtPayoffProjectionsComponent } from '../setup-flow/debt-payoff-projections/debt-payoff-projections.component';

@Component({
  selector: 'app-updated-debt-projections',
  templateUrl: './updated-debt-projections.component.html',
  styleUrls: ['./updated-debt-projections.component.scss']
})
export class UpdatedDebtProjectionsComponent extends DebtPayoffProjectionsComponent implements OnInit {
  @Input() public dataBeforeEditingPlan: { data: any; age: number };
  public beforeEditingDebtAge: number;
  public beforeEditingDebtDate: number;
  public beforeEditingDebtWealth: number;
  public beforeEditingGuidelineAge: number;
  public currentPlanAgeDifference: number;
  public originalPlanAgeDifference: number;
  public guidelineAgeDifference: number;

  public ngOnInit() {
    super.ngOnInit();
    this.addDataBeforeEditingPlanIfAvailable();
    this.setCurrentPlanAgeDifference();
    this.setOriginalPlanAgeDifference();
    this.setGuidelineAgeDifference();
  }

  protected truncateDebtData() {
    super.truncateDebtData();
    if (!this.dataBeforeEditingPlan) {
      return;
    }
    const beforeZeroIndex = this.dataBeforeEditingPlan.data.findIndex(data => data.y === 0);

    if (beforeZeroIndex !== -1) {
      this.dataBeforeEditingPlan.data = this.dataBeforeEditingPlan.data.slice(0, beforeZeroIndex + 12);
    }
  }

  public setGuidelineAgeDifference() {
    return (this.guidelineAgeDifference = this.wizeFiDebtAge - this.wizeFiDebtAge);
  }

  public setOriginalPlanAgeDifference() {
    return (this.originalPlanAgeDifference = this.originalDebtAge - this.currentDebtAge);
  }

  public setCurrentPlanAgeDifference() {
    return (this.currentPlanAgeDifference = this.beforeEditingDebtAge - this.currentDebtAge);
  }

  public addDataBeforeEditingPlanIfAvailable() {
    if (this.dataBeforeEditingPlan) {
      if (isNaN(this.dataBeforeEditingPlan.age)) {
        this.beforeEditingDebtAge = this.currentDebtAge;
      } else {
        this.beforeEditingDebtAge = this.dataBeforeEditingPlan.age;
      }
      this.datasets.push({ data: this.dataBeforeEditingPlan.data, color: this.darkBlue });
    } else {
      this.beforeEditingDebtAge = this.currentDebtAge;
      this.datasets.push({ data: this.currentDebtData, color: this.darkBlue });
    }
    this.datasets.sort((a, b) => b.data.length - a.data.length || 0);
  }
}
