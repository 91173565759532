import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DataModelService } from 'src/app/services/data-model/data-model.service';

declare const AWS: any;

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  vcode: string;
  username: string;
  password: string;
  loading: any = {}; // this is a "by reference" object {isLoading:boolean} used to get values into and out from LoginManagement class
  screen = 'verify';

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private dataModelService: DataModelService) {}

  ngOnInit() {
    this.loading.isLoading = false;
    // fill out form with get params
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      // console.log('queryParams',params);
      this.username = params.u;
      this.vcode = params.v;
    });

    /* if (!this.username) {
			this.dataModelService.showMessage('error', 'Invalid password reset link', 5000);
			this.router.navigateByUrl('/login');
		} */

    if (this.username && this.vcode) {
      this.screen = 'update';
    }

    const body = document.getElementsByTagName('body')[0];
    body.classList.add('login');
  }

  verifyCode = (): void => {
    if (this.vcode) {
      this.screen = 'update';
    } else {
      this.dataModelService.showMessage('error', 'Invalid verification code', 5000);
    }
  };

  resetPassword = (): void => {
    this.dataModelService.loginManagement.loading = this.loading;
    this.loading.isLoading = true;

    this.dataModelService.loginManagement
      .applyPasswordChangeVerificationCode(this.username, this.vcode, this.password)
      .then(response => {
        console.log('confirmUser response', response);
        this.dataModelService.showMessage('success', 'Password Reset!', 3000);
        this.router.navigateByUrl('/login');
      })
      .catch(err => {
        this.dataModelService.loginManagement.handleError(err);
      });
  }; // register
}
