import * as _ from 'lodash';

export const shadowAccounts = (
  accountsKey: string,
  prop: string,
  gdBudget,
  gdIncome,
  gdAssets,
  gdAssetProtection,
  gdLiabilities,
  cProfile,
  cIncome,
  cBudget,
  cAssets,
  cAssetProtection,
  cLiabilities
) => {
  /*
  console.log(cBudget);
  console.log(gdBudget);
  console.error(gdLiabilities)
  console.error(cLiabilities);
  Budget Shadow Accounts
  */
  const accounts = [];

  // Assets
  let subcats = _.filter(gdAssets.category, v => typeof v === 'object');
  _.forEach(subcats, o => {
    _.forEach(o.accounts, a => {
      if (a.hasOwnProperty('productivity') && a.productivity.val !== 'Productive') {
        a.shadowType = 'asset';
        a.category = 'asset';
      } else if (prop === 'monthlyIncome') {
        a.shadowType = 'asset';
        a.category = 'asset';
      }

      a.modal = false;
      accounts.push(a);
    });
  });

  // AssetProtection
  subcats = _.filter(gdAssetProtection.category, v => typeof v === 'object');
  _.forEach(subcats, o => {
    _.forEach(o.accounts, a => {
      a.shadowType = 'assetProtection';
      a.category = 'assetProtection';
      if (a.hasOwnProperty('monthlyMinimum') && a.hasOwnProperty('monthlyAmount')) {
        a.shadowType = 'split';
        a.extraAmount = (a.monthlyAmount.val - a.monthlyMinimum.val).toFixed(2);
        // console.error(a);
      }
      a.modal = false;
      accounts.push(a);
    });
  });

  // Liabilities
  subcats = _.filter(gdLiabilities.category, v => typeof v === 'object');
  _.forEach(subcats, o => {
    _.forEach(o.accounts, a => {
      a.shadowType = 'liability';
      a.category = 'liabilities';
      if (
        a.hasOwnProperty('monthlyMinimum') &&
        a.hasOwnProperty('monthlyAmount') &&
        a.hasOwnProperty('productivity') &&
        a.productivity.val !== 'Productive'
      ) {
        a.shadowType = 'split';
        a.extraAmount = (a.monthlyAmount.val - a.monthlyMinimum.val).toFixed(2);
      }
      a.modal = false;
      accounts.push(a);
    });
  });

  _.filter(accounts, v => v.hasOwnProperty(prop) && v[prop].val > 0);

  return accounts;
};
