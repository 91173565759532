import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ITransactionRule } from '../../interfaces/iTransactionRule.interface';
import { WizefiTransaction } from '../../interfaces/wizefi-transaction';
import { CategoryService } from '../../services/category/category.service';
import { DataModelService } from '../../services/data-model/data-model.service';
import { TransactionRuleService } from '../../services/transaction-rule/transaction-rule.service';
import { DefaultInfoDialogComponent, IDefaultInfoDialogData } from '../default-info-dialog/default-info-dialog.component';
import {
  IManualTransactionDialogData,
  ManualTransactionDetailsComponent
} from '../setup-flow/manual-transaction-details/manual-transaction-details.component';
import { TransactionRuleDialogComponent } from '../transaction-rule-dialog/transaction-rule-dialog.component';
import { MonthPlanSelectorComponent } from './month-plan-selector/month-plan-selector.component';
import { ITransactionsFilter } from './transactions-filter/i-transactions-filter';
import { TransactionsFilterComponent } from './transactions-filter/transactions-filter.component';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {
  @Output() public onTransactionWasCategorized: EventEmitter<any> = new EventEmitter<any>();

  protected screen = 'transactions';

  protected transactionAttributes: any;

  protected allTransactions: WizefiTransaction[] = [];
  protected categorizedTransactions: WizefiTransaction[] = [];
  protected uncategorizedTransactions: WizefiTransaction[] = [];
  protected excludedTransactions: WizefiTransaction[] = [];

  protected transactionsYearMonth: string;

  protected transactionRuleDialogComponent: TransactionRuleDialogComponent;

  public selectedMonth = new Date();
  public splitCategories: any;

  protected groupedTransactions: any; // array of arrays organized by date

  public checkedAll: boolean;
  public selectedTransactions: WizefiTransaction[] = [];
  public transactionSelected: ITransactionRule;
  public allrules: any;
  public allTransactionRules: any;

  public searchFlag = true;
  public searchInput;
  public isEditingTransaction: boolean;
  public goToSplitOnTransactionEdit: boolean;
  public transactionToEdit: WizefiTransaction;
  public bulkCategorizeFlag = false;
  public selectedType?: string;
  public selectedAccount?: string;
  public selectedCategory?: string;
  public types: string[] = [];
  public categories: string[] = [];
  public subCategories: string[] = [];
  public selectedSubCategory?: string;
  public categoryErrorMessage = 'Error saving transaction. Please select a type, category and subcategory.';

  public format;

  public appliedFilters: ITransactionsFilter = {};

  protected transactionMonth;

  public cameDirectToSplit = false;
  public isLoading = false;

  constructor(
    protected router: Router,
    protected dataModelService: DataModelService,
    protected route: ActivatedRoute,
    private dialog: MatDialog,
    public categoryService: CategoryService,
    public transactionRuleService: TransactionRuleService
  ) {
    this.selectedMonth = new Date(this.dataModelService.dataModel.persistent.header.curplanYearMonth + '-02');
    this.loadTransactions();
    this.transactionRuleService.getUpdateTransactions().subscribe(update => {
      if (update) {
        this.refresh();
        this.closeModal();
      }
    });
  }

  protected changeScreen(label) {
    this.screen = label;
    this.checkedAll = false;
    this.selectedTransactions = [];
  }

  private getCategorizedTransactions() {
    this.categorizedTransactions = this.allTransactions.filter(
      transaction => transaction.wizeFiCategory.indexOf('unknown') === -1 && transaction.wizeFiCategory !== 'ignore' && transaction.splitStatus !== 1
    );
    if (this.isLoading) {
      this.isLoading = false;
    }
    return this.categorizedTransactions;
  }

  protected getAmount(transaction): number {
    const info = this.dataModelService.categoryManagement.extractWizeFiCategoryNameParts(transaction.wizeFiCategory);
    return info.category !== 'assets' ? -transaction.amount : transaction.amount;
  }

  private getUncategorizedTransactions() {
    const transactions = this.getSelectedMonthTransactions();
    this.uncategorizedTransactions = transactions.filter(transaction => transaction.wizeFiCategory.indexOf('unknown') !== -1);

    return this.uncategorizedTransactions;
  }

  private getExcludedTransactions() {
    const transactions = this.getSelectedMonthTransactions();
    this.excludedTransactions = transactions.filter(transaction => transaction.wizeFiCategory === 'ignore');

    return this.excludedTransactions;
  }

  public saveTransaction() {
    this.onTransactionWasCategorized.emit();
    this.uncategorizedTransactions = this.uncategorizedTransactions.splice(1);
    this.closeModal();
  }

  // exclude selected transactions
  public excludeSelectedTransactions(transactionselements) {
    let yearMonth;
    const wizeFiId = this.dataModelService.dataModel.global.wizeFiID;
    this.allTransactions.forEach(transaction => {
      transactionselements.forEach(element => {
        if (transaction.transaction_id === element.transaction_id) {
          yearMonth = element.date.substr(0, 7);
          transaction.wizeFiCategory = 'ignore';
        }
      });
    });
    this.dataModelService.dataManagement.putWizeFiTransactions(wizeFiId, yearMonth, this.allTransactions);
    this.ngOnInit();
  }

  private showTransactionModal(goToSplit: boolean) {
    this.isEditingTransaction = true;
    this.goToSplitOnTransactionEdit = goToSplit;
    // needs to include a validation to split status like 0 because just status 0 could be splited

    if (this.transactionToEdit.isManual) {
      this.dialog
        .open<ManualTransactionDetailsComponent, IManualTransactionDialogData, WizefiTransaction>(ManualTransactionDetailsComponent, {
          data: { transaction: { ...this.transactionToEdit } }
        })
        .afterClosed()
        .subscribe(result => {
          if (result) {
            this.saveManualTransaction(result);
          }
        });
    }
  }

  public onReview(transaction: WizefiTransaction, goToSplit: boolean = false) {
    this.transactionToEdit = transaction;
    this.cameDirectToSplit = goToSplit;
    if (this.transactionToEdit) {
      this.showTransactionModal(goToSplit);
    }
  }

  public closeModal() {
    this.isEditingTransaction = false;
    this.transactionToEdit = null;
    this.loadTransactions();
  }

  public loadTransactions() {
    this.isLoading = true;
    this.dataModelService.plaidManagement
      .getPlaidData()
      .then(() => {
        if (
          this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection[
            this.dataModelService.dataModel.persistent.header.curplanYearMonth
          ] === []
        ) {
          return this.loadTransactions();
        }
      })
      .then(() => {
        this.allTransactions = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection[
          this.dataModelService.dataModel.persistent.header.curplanYearMonth
        ];

        this.getCategorizedTransactions();
        this.getUncategorizedTransactions();
        this.getExcludedTransactions();
        this.isLoading = false;
      })
      .catch(err => {
        this.isLoading = false;
        console.error('error:', err);
        this.allTransactions = [];
        this.getCategorizedTransactions();
        this.getUncategorizedTransactions();
        this.getExcludedTransactions();
      });
  }

  public async ngOnInit() {
    this.isLoading = true;
    await this.loadTransactions();
    this.allrules = this.transactionRuleService.getTransactionRules();
    this.transactionRuleService.runRulesForMonth(this.transactionMonth);
    this.selectedTransactions = [];

    if (this.router.url.includes('#')) {
      const hash = this.router.url.split('#')[1];
      this.screen = hash;
    } else {
      this.screen = this.uncategorizedTransactions.length > 0 ? 'uncategorized' : 'categorized';
    }
    this.isLoading = false;
  }

  public openFiltersDialog(): void {
    this.dialog
      .open(TransactionsFilterComponent, { data: this.appliedFilters })
      .afterClosed()
      .subscribe(result => {
        this.appliedFilters = result || this.appliedFilters;
      });
  }

  protected showManualTransactionModal() {
    this.dialog
      .open<ManualTransactionDetailsComponent, IManualTransactionDialogData, WizefiTransaction>(ManualTransactionDetailsComponent, {
        data: { defaultTransactionDate: this.getDefaultManualTransactionDate() }
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.isLoading = true;
          this.saveManualTransaction(result);
        }
      });
  }

  public openSearchInput() {
    this.searchInput = '';
    this.searchFlag = !this.searchFlag;
  }

  protected switchRoute(route: string) {
    return this.router.navigateByUrl(route);
  }

  public refresh(): void {
    this.isLoading = true;
    this.dataModelService.plaidManagement
      .updateWizeFiTransactions(this.dataModelService.dataModel.persistent.header.curplanYearMonth)
      .then(transactions => {
        this.ngOnInit();
        this.isLoading = false;
      });
  }

  protected getDefaultManualTransactionDate() {
    const today = moment(Date.now()).startOf('day');
    return today.toISOString().substr(0, 10);
  }

  protected saveManualTransaction(transaction) {
    this.getCategorizedTransactions();
    this.onTransactionWasCategorized.emit();
  }

  private getSelectedMonthTransactions(): WizefiTransaction[] {
    let curTransactions = this.dataModelService.dataModel.global.plaidData.wizeFiTransactionsCollection[this.formatDateToTransactionsMapKey()];
    curTransactions = curTransactions ? curTransactions : [];

    curTransactions.map(transaction => {
      (transaction as any).categoryLabel = this.dcat(transaction);
    });

    return curTransactions.sort((leftSide, rightSide) => {
      if (leftSide.date < rightSide.date) {
        return -1;
      }
      if (leftSide.date > rightSide.date) {
        return 1;
      }
      return 0;
    });
  }

  private formatDateToTransactionsMapKey(): string {
    return this.selectedMonth.toISOString().substr(0, 7);
  }

  public dcat(transaction) {
    const wizeFiCategory = transaction.wizeFiCategory;
    const info = this.dataModelService.categoryManagement.decodeWizeFiCategory(wizeFiCategory);
    const category = info.subcategory.charAt(0).toUpperCase() + info.subcategory.slice(1);

    const wizeFiCategoryInfo = this.dataModelService.categoryManagement.decodeWizeFiCategory(transaction.wizeFiCategory);
    let accountName = wizeFiCategoryInfo.accountName;
    if (wizeFiCategoryInfo.category !== 'budget') {
      accountName = this.getCustomAccountName(transaction);
    }
    return `${category}: ${accountName}`;
  }

  public removeFilter(field: string): void {
    this.appliedFilters[field] = undefined;
  }
  public getCustomAccountName(transaction) {
    let name = '';
    if (this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts) {
      this.dataModelService.dataModel.global.plaidData.wizeFiPlaidAccounts.forEach(account => {
        if (account.account_id === transaction.account_id) {
          name = account.accountName;
        }
      });
    }
    return name;
  }

  public filterTransactions(inputTransactions: WizefiTransaction[]): WizefiTransaction[] {
    // We need to convert the applied filter to UTC, since the default from the date picker is the local timezone

    // this if solution prevent the filters be applied in the rules tab
    if (this.screen === 'rules') {
      return inputTransactions.filter(i => {
        if (i.ruleApplied) {
          return i;
        }
      });
    }

    // return the transactions in other tabs like categorized uncategorized and excluded with applied filters
    const searchInputLower = this.searchInput ? this.searchInput.toLowerCase() : '';
    return inputTransactions
      .filter(i => this.appliedFilters.date === undefined || this.equalDates(new Date(this.appliedFilters.date + ' UTC'), new Date(i.date)))
      .filter(i => this.appliedFilters.type === undefined || i.wizeFiCategory.startsWith(this.appliedFilters.type + '_'))
      .filter(i => this.appliedFilters.category === undefined || i.wizeFiCategory.includes(`_${this.appliedFilters.category}_`))
      .filter(
        i =>
          this.appliedFilters.subcategory === undefined ||
          this.appliedFilters.subcategory === this.dataModelService.categoryManagement.decodeWizeFiCategory(i.wizeFiCategory).accountName
      )

      .filter(i => this.appliedFilters.account === undefined || this.appliedFilters.account.account_id === i.account_id)
      .filter(i => {
        if (searchInputLower && searchInputLower.length > 2) {
          return (
            searchInputLower === undefined ||
            this.dcat(i.wizeFiCategory).toLowerCase().includes(searchInputLower) ||
            i.merchantName.toLowerCase().includes(searchInputLower) ||
            i.accountName.toLowerCase().includes(searchInputLower) ||
            this.getAmount(i).toString().toLowerCase().includes(searchInputLower)
          );
        } else {
          return i;
        }
      });
  }

  public formatRuleCategorization(categorization: string): string {
    const type = this.categoryService.getTypeFromDbCategoryFormat(categorization);
    const category = this.categoryService.getCategoryFromDbCategoryFormat(categorization);
    const subcategory = this.categoryService.getSubcategoryFromDbCategoryFormat(categorization);
    if (this.categoryService.getTypeLabel(type) === 'undefined') {
      return 'Cannot Properly assign a category, please reassign';
    } else {
      return `${this.categoryService.getTypeLabel(type)}:${this.categoryService.getCategoryLabel(
        type,
        category
      )}:${this.categoryService.getSubcategoryLabel(type, category, subcategory, true)}`;
    }
  }

  public openTransactionRuleModal(rule: ITransactionRule): void {
    this.dialog
      .open<TransactionRuleDialogComponent, any, ITransactionRule | undefined>(TransactionRuleDialogComponent, { data: { rule } })
      .afterClosed()
      .subscribe(result => {
        this.closeModal();
        this.ngOnInit();
      });
  }

  private equalDates(date1: Date, date2: Date): boolean {
    return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
  }

  public selectAll() {
    if (this.checkedAll) {
      if (this.screen === 'categorized') {
        this.selectedTransactions = this.categorizedTransactions;
      } else if (this.screen === 'uncategorized') {
        this.selectedTransactions = this.uncategorizedTransactions;
      }
    } else {
      this.selectedTransactions = [];
    }
  }

  public verifyAnyChecked(transaction, value) {
    if (value) {
      this.selectedTransactions.push(transaction);
    } else {
      this.selectedTransactions = this.selectedTransactions.filter(item => transaction.transaction_id !== item.transaction_id);
    }
  }

  public findSelectedCheckedTransaction(transaction): boolean {
    return this.selectedTransactions.find(i => i.transaction_id === transaction.transaction_id) !== undefined;
  }

  public closeBulkCategorize() {
    this.bulkCategorizeFlag = false;
  }

  public toggleBulkCategorize() {
    this.types = this.categoryService.getTypes();
    this.cleanBulkVariables();
    this.bulkCategorizeFlag = !this.bulkCategorizeFlag;
    this.calculateAvailableDropdownOptions();
  }

  public cleanBulkVariables() {
    this.selectedSubCategory = undefined;
    this.selectedCategory = undefined;
    this.selectedType = 'budget';
  }
  public selectType(type: string): void {
    if (type !== this.selectedType) {
      this.selectedType = type;
      this.selectedCategory = undefined;
      this.selectedSubCategory = undefined;
      this.calculateAvailableDropdownOptions();
    }
  }

  private calculateAvailableDropdownOptions(): void {
    this.types = this.categoryService.getTypes();
    this.categories = this.categoryService.getCategories(this.selectedType);
    this.subCategories = this.categoryService.getSubcategories(this.selectedType, this.selectedCategory);
  }

  public selectCategory(category: string): void {
    if (category !== this.selectedCategory) {
      this.selectedCategory = category;
      this.selectedSubCategory = undefined;
      this.calculateAvailableDropdownOptions();
    }
  }

  public selectSubCategory(subCategory: string): void {
    this.selectedSubCategory = subCategory;
  }

  private validateFields(): boolean {
    let isOK = true;
    // category
    if (this.selectedCategory === 'Uncategorized' || this.selectedSubCategory === 'Uncategorized') {
      isOK = false;
      this.dataModelService.showMessage('error', this.categoryErrorMessage, 5000);
    }
    return isOK;
  }

  public async saveBulk() {
    if (!this.validateFields()) {
      return;
    }
    await this.saveBulkTransactions();
    this.toggleBulkCategorize();
    //setTimeout(function() { this.refresh() }.bind(this), 5000)
  }

  private async saveBulkTransactions() {
    const confirmedWizeFiCategory = this.getConfirmedWizeFiCategory();
    await this.updateTransactionsInDatabase(confirmedWizeFiCategory);
    this.selectedCategory = 'Uncategorized';
    this.selectedSubCategory = 'Uncategorized';
    this.selectedAccount = 'Uncategorized';
    this.refresh();
  }

  private async updateTransactionsInDatabase(wizeFiCategory: string) {
    this.selectedTransactions.forEach(async transaction => {
      transaction.wizeFiCategory = wizeFiCategory;
      const wizeFiId = this.dataModelService.dataModel.global.wizeFiID;

      const index = this.selectedTransactions.findIndex(transactionSelected => transactionSelected.transaction_id === transaction.transaction_id);

      if (index !== -1) {
        this.selectedTransactions[index] = transaction;
      }

      try {
        await this.dataModelService.dataManagement.putWizeFiTransactions(
          wizeFiId,
          this.dataModelService.dataModel.persistent.header.curplanYearMonth,
          this.allTransactions
        );
      } catch (error) {
        console.error('edit-transaction-category: error saving transactions', error);
      }
    });
  }

  private getConfirmedWizeFiCategory() {
    const confirmedWizeFiCategory = this.dataModelService.categoryManagement.makeWizeFiCategoryName(
      this.selectedType,
      this.selectedCategory,
      this.selectedSubCategory
    );
    return confirmedWizeFiCategory;
  }

  public openMonthPicker() {
    this.dialog
      .open<MonthPlanSelectorComponent, Date, Date | undefined>(MonthPlanSelectorComponent, {
        data: this.selectedMonth,
        position: { top: '100px', left: '300px' }
      })
      .afterClosed()
      .subscribe(result => {
        if (result) {
          this.selectedMonth = result;
          this.loadTransactions();
        }
      });
  }

  public openRuleInstructions(): void {
    this.dialog.open<DefaultInfoDialogComponent, IDefaultInfoDialogData>(DefaultInfoDialogComponent, {
      width: '500px',
      data: {
        header: 'How To Create a New Rule',
        body: `Currently, rules are based on an existing transaction.
                To create a new rule, click on a transaction to open the transaction details modal pop-up.
                Then, scroll to the bottom and click “Create a Transaction Rule”.`
      }
    });
  }
}
