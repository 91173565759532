import { Component, OnInit } from '@angular/core';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { categoryExcludeList, subcategoryExcludeList } from '../../../services/data-model/data-model_0001.data';

@Component({
  selector: 'app-admin-view-basic-data',
  templateUrl: './admin-view-basic-data.component.html',
  styleUrls: ['./admin-view-basic-data.component.scss']
})
export class AdminViewBasicDataComponent implements OnInit {
  // persistent data support
  public plan: string; // current plan to process
  public newplan: string; // newplan selected in select widget
  public persistentAttributesList: string[];
  public persistentItemsAttributeList: any;

  // plan data support
  public categoryList: any[];
  public subcategoryList: any;
  public accountList: any;
  public fieldList: any;
  public planList: string[]; // list of actual plans that are present in the WizeFi data

  // exclusion lists
  public persistentExcludeAttributeList: string[];
  public itemExcludeAttributeList: string[];
  public fieldExcludeList: string[];

  // CSV data
  public csvData: string;

  constructor(public dataModelService: DataModelService) {}

  public ngOnInit() {
    // set exclusion lists
    this.persistentExcludeAttributeList = ['persistentDataVersion', 'plans'];
    this.itemExcludeAttributeList = [
      'dateSubscriptionCreated',
      'subscriptionFee',
      'subscriptionID',
      'subscriptionAccountStatus',
      'subscriptionEmail',
      'subscriptionPaidThrough',
      'paymentRef',
      'picture'
    ];
    this.fieldExcludeList = [
      'isEditable',
      'isRequired',
      'tooltip',
      'accountName',
      'accountType',
      'shadowType',
      'subcat',
      'modal',
      'k',
      'isNonProductiveDebt',
      'extraAmount'
    ];

    /*
		// set other data
		this.plan = this.dataModelService.dataModel.persistent.header.curplan;
		this.setPersistentAttributeLists();
		this.setPlanLists();
		*/

    const dateCreated = this.dataModelService.dataModel.persistent.header.dateCreated;
    const originalMonth = dateCreated.substr(0, 4) + '-' + dateCreated.substr(5, 2);
    this.planList = this.dataModelService.dataModel.global.planList; // 'original' plus data from DynamoDB table WizeFiPlans
    const lastPlan = this.planList[this.planList.length - 1];
    this.plan = lastPlan;
    this.newplan = this.plan;
    this.setPersistentAttributeLists();
    this.setPlanLists();
  } // ngOnInit

  public updateData() {
    const loadPlan = async () => {
      const saveCurplan = this.dataModelService.dataModel.persistent.header.curplan;
      await this.dataModelService.dataManagement.changeCurrentPlan(this.newplan);
      this.dataModelService.setCurPlan(saveCurplan);
    }; // loadPlan

    const changeData = () =>
      new Promise((resolve, reject) => {
        this.plan = this.newplan;
        this.setPlanLists();
        resolve();
      }); // return Promise
    // changeData

    loadPlan()
      .then(changeData)
      .catch(ex => {
        console.log('Active plan could not be switched:'); // %//
        console.log(ex); // %//
        this.dataModelService.showMessage('error', 'Active plan could not be switched', 7000);
      });
  } // updateData

  public onPlanChange(event) {
    this.updateData();
  } // onPlanChange

  public setPersistentAttributeLists() {
    this.persistentAttributesList = [];
    this.persistentItemsAttributeList = {};
    for (const persistentAttribute of Object.keys(this.dataModelService.dataModel.persistent)) {
      if (this.persistentExcludeAttributeList.indexOf(persistentAttribute) === -1) {
        this.persistentAttributesList.push(persistentAttribute);
        this.persistentItemsAttributeList[persistentAttribute] = [];
        for (const itemAttribute of Object.keys(this.dataModelService.dataModel.persistent[persistentAttribute])) {
          if (this.itemExcludeAttributeList.indexOf(itemAttribute) === -1) {
            this.persistentItemsAttributeList[persistentAttribute].push(itemAttribute);
          }
        } // for itemAttribute
      }
    } // for persistentAttribute
  } // setPersistentAttributeLists

  public setPlanLists() {
    this.categoryList = [];
    this.subcategoryList = {};
    this.accountList = {};
    this.fieldList = {};
    for (const category of Object.keys(this.dataModelService.dataModel.persistent.plans[this.plan])) {
      if (categoryExcludeList.indexOf(category) === -1) {
        this.categoryList.push(category);
        this.subcategoryList[category] = [];
        this.accountList[category] = {};
        this.fieldList[category] = {};
        for (const subcategory of Object.keys(this.dataModelService.dataModel.persistent.plans[this.plan][category])) {
          if (subcategoryExcludeList.indexOf(subcategory) === -1) {
            this.subcategoryList[category].push(subcategory);
            this.accountList[category][subcategory] = [];
            this.fieldList[category][subcategory] = {};
            for (
              let acntndx = 0;
              acntndx < this.dataModelService.dataModel.persistent.plans[this.plan][category][subcategory].accounts.length;
              acntndx++
            ) {
              this.accountList[category][subcategory].push(acntndx);
              this.fieldList[category][subcategory][acntndx] = [];
              for (const field of Object.keys(this.dataModelService.dataModel.persistent.plans[this.plan][category][subcategory].accounts[acntndx])) {
                if (this.fieldExcludeList.indexOf(field) === -1) {
                  this.fieldList[category][subcategory][acntndx].push(field);
                } // if include field
              }
            } // for acntndx
          } // if include subcategory
        } // for subcategory
      } // if include category
    } // for category
  } // setPlanLists

  public makeCSVdata() {
    // initialize
    this.csvData = '';

    // process identification data
    this.csvData += 'identification data\n';
    this.csvData += '\n';
    this.csvData += 'wizeFiID,' + this.dataModelService.dataModel.global.wizeFiID + '\n';
    this.csvData += 'affiliateID,' + this.dataModelService.dataModel.affiliateID + '\n';
    this.csvData += 'affiliateAlias,' + this.dataModelService.dataModel.affiliateAlias + '\n';
    this.csvData += '\n';

    // process persistent data
    for (const persistentAttribute of Object.keys(this.dataModelService.dataModel.persistent)) {
      if (this.persistentExcludeAttributeList.indexOf(persistentAttribute) === -1) {
        this.csvData += persistentAttribute + ' data\n';
        this.csvData += '\n';
        for (const itemAttribute of Object.keys(this.dataModelService.dataModel.persistent[persistentAttribute])) {
          if (this.itemExcludeAttributeList.indexOf(itemAttribute) === -1) {
            this.csvData += itemAttribute + ',';
            this.csvData += this.dataModelService.dataModel.persistent[persistentAttribute][itemAttribute] + '\n';
          } // if include itemAttribute
        } // for itemAttribute
        this.csvData += '\n';
      } // if include persistentAttribute
    } // for persistentAttribute

    // process plan data
    this.csvData += 'plan data (' + this.plan + ')\n';
    this.csvData += '\n';
    this.csvData += 'category,subcategory,accountName,field,value\n';
    for (const category of Object.keys(this.dataModelService.dataModel.persistent.plans[this.plan])) {
      if (categoryExcludeList.indexOf(category) === -1) {
        for (const subcategory of Object.keys(this.dataModelService.dataModel.persistent.plans[this.plan][category])) {
          if (subcategoryExcludeList.indexOf(subcategory) === -1) {
            for (const account of this.dataModelService.dataModel.persistent.plans[this.plan][category][subcategory].accounts) {
              for (const field of Object.keys(account)) {
                if (this.fieldExcludeList.indexOf(field) === -1) {
                  this.csvData += category + ',';
                  this.csvData += subcategory + ',';
                  this.csvData += account.accountName.val + ',';
                  this.csvData += field + ',';
                  this.csvData += account[field].val + '\n';
                } // if include field
              } // for field
            }
          } // if include subcategory
        } // for subcategory
      } // if include category
    } // for category

    return this.csvData;
  } // makeCSVdata

  public downloadCSVdata() {
    let csvData = this.makeCSVdata();
    csvData = 'data:text/csv;charset=utf-8,' + csvData;
    const data = encodeURI(csvData);
    const filename = 'WizeFiBasicData.csv';

    const link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', filename);
    link.click();
  } // downloadCSVdata
} // class AdminViewBasicDataComponent
