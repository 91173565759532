import { Component, OnInit } from '@angular/core';
import { DataModelService } from '../../../services/data-model/data-model.service';
import { SetupFlowDataManagementService, SetupFlowScreen } from '../../../services/setup/setup-flow-data-management.service';
import { SetupService } from '@app/services/setup.service';

@Component({
  selector: 'app-finish-screen',
  templateUrl: './finish-screen.component.html',
  styleUrls: ['./finish-screen.component.scss']
})
export class FinishScreenComponent implements SetupFlowScreen, OnInit {
  constructor(
    protected dataManagement: SetupFlowDataManagementService,
    protected dataModelService: DataModelService,
    private setupService: SetupService
  ) {
    dataManagement.currentSetupScreen = this;
  }

  public ngOnInit() {
    this.setupService.updateCurrentSetupStep(9);
    this.removeAllActualSpending();
    this.dataModelService.dataManagement.storeinfo();
  }

  public canSave() {
    return true;
  }

  public onSave() {
    this.updateDateProfileCompleted();
  }

  private updateDateProfileCompleted() {
    const header = this.dataManagement.dataModelService.dataModel.persistent.header;
    header.dateProfileCompleted = new Date().toISOString();
  }

  public showOnSaveErrorMessage(): void {}

  protected getYears(age: number): number {
    return Math.floor(age);
  }

  protected getMonths(age: number): number {
    return Math.round((age % 1) * 12);
  }

  /*
   * Up to this point, the create cafr and apply cafr screens have used the accounts actualMonthlyAmounts
   * of the curplan to populate the data for "Last Month." This is simply a specific scenario for the
   * setup phase. When a user finishes the setup flow and lands on the expenses page of the dashboard,
   * we must show them that they have zero transactions categorized for the curplan (current month) and
   * that each of the items in the spending component have zeroes for actualMonthlyAmounts. This function
   * makes sure that this is the case by resetting actualMonthlyAmount values for the curplan.
   */

  private removeAllActualSpending() {
    this.dataModelService.visitAllWizeFiAccounts(this.dataModelService.dataModel.persistent.header.curplan, this._removeAllActualSpending, {});
  }

  private _removeAllActualSpending(plan, category, subcategory, acntndx, account, result) {
    if (category === 'budget' && account.hasOwnProperty('actualMonthlyAmount') && account.hasOwnProperty('monthlyAmount')) {
      account.actualMonthlyAmount.val = 0;
    }
  }
}
