import { IChainParms } from '../interfaces/iChainParams.interface';
import { ISubscriptionInfo } from '../interfaces/iSubscriptionInfo.interface';
import { DataModelService } from '../services/data-model/data-model.service';

// @TODO figure out how to eliminate the need for IchainParms (which was introduced to resolve compile time type checking errors)

export class BraintreeManagement {
  public constructor(public dataModelService: DataModelService) {}

  public cancelSubscription(subscriptionID) {
    const action = 'cancelSubscription';
    const actionParms = { subscriptionID };

    return this.invokeManageBraintree(action, actionParms);
  } // cancelSubscription

  ////////////////////////////////////////////////////
  // customer routines
  ////////////////////////////////////////////////////

  public createCustomer(parms) {
    const action = 'createCustomer';
    const actionParms = parms;

    return this.invokeManageBraintree(action, actionParms);
  } // createCustomer

  ////////////////////////////////////////////////////
  // payment method routines
  ////////////////////////////////////////////////////

  public createPaymentMethod(parms) {
    const action = 'createPaymentMethod';
    const actionParms = parms;

    return this.invokeManageBraintree(action, actionParms);
  } // createPaymentMethod

  ////////////////////////////////////////////////////
  // subscription routines
  ////////////////////////////////////////////////////

  public createSubscription(parms) {
    const action = 'createSubscription';
    const actionParms = parms;

    return this.invokeManageBraintree(action, actionParms);
  } // createSubscription

  public deleteCustomer(customerID) {
    const action = 'deleteCustomer';
    const actionParms = { customerID };

    return this.invokeManageBraintree(action, actionParms);
  } // deleteCustomer

  public deletePaymentMethod(paymentMethodToken) {
    const action = 'deletePaymentMethod';
    const actionParms = { paymentMethodToken };

    return this.invokeManageBraintree(action, actionParms);
  } // deletePaymentMethod

  ////////////////////////////////////////////////////////////////////////////////
  // utility functions that utilize the basic Braintree API functions
  ////////////////////////////////////////////////////////////////////////////////

  public establishSubscription(subscriptionInfo: ISubscriptionInfo) {
    // the following provides the return value for this function
    const resultParms = {
      customer: null,
      paymentMethod: null,
      subscription: null
    };

    return new Promise((resolveEstablishSubscription, rejectEstablishSubscription) => {
      const findCustomer = () => this.findCustomer(chainParms.customerID); // findCustomer

      const processFindCustomer = result => {
        let haveMatch;

        resultParms.customer = result;
        chainParms.needCustomer = false;
        chainParms.subscriptionID = 'unknown';
        chainParms.needSubscriptionUpdate = false;

        // initialize
        const nonceLastTwo = chainParms.noncePayload.details.lastTwo;
        const nonceCardType = chainParms.noncePayload.details.type;
        let haveSubscriptionMatch = false; // indicates whether subscription paymentMethodToken matches paymentMethodNonce

        // determine whether payment method and subscription is in place
        let paymentMethodCount = 0; // number of payment methods that match paymentMethodNonce
        let subscriptionCount = 0; // number of active subscriptions (not canceled and not expired)
        for (const paymentMethod of result.paymentMethods) {
          haveMatch = false;
          const lastTwo = paymentMethod.last4.substr(2, 2);
          const cardType = paymentMethod.type;
          if (lastTwo === nonceLastTwo && cardType === nonceCardType) {
            haveMatch = true;
            paymentMethodCount += 1;
            chainParms.paymentMethodToken = paymentMethod.token;
            resultParms.paymentMethod = paymentMethod;
          }
          for (const subscription of paymentMethod.subscriptions) {
            const status = subscription.status;
            if (status !== 'Canceled' && status !== 'Expired') {
              subscriptionCount += 1;

              // remember last subscription information encountered
              chainParms.subscriptionID = subscription.id;
              resultParms.subscription = subscription;

              // process subscription that is a match
              if (haveMatch) {
                haveSubscriptionMatch = true;
              }
            }
          } // for j
        } // for i

        if (paymentMethodCount === 0) {
          chainParms.needPaymentMethod = true;
        }
        if (paymentMethodCount > 0) {
          chainParms.needPaymentMethod = false;
        }
        if (subscriptionCount === 0) {
          chainParms.needSubscription = true;
        }
        if (subscriptionCount > 0) {
          chainParms.needSubscription = false;
        }
        if (subscriptionCount === 1 && !haveSubscriptionMatch) {
          chainParms.needSubscriptionUpdate = true;
        }
        if (subscriptionCount > 1) {
          this.dataModelService.showMessage('error', 'Contact WizeFi support to resolve problem of multiple subscriptions');
        }

        return Promise.resolve(null);
      }; // processFindCustomer

      const processFindCustomerError = err => {
        console.log('findCustomer: ', err); // %//

        if (err !== 'notFoundError') {
          return Promise.reject(err);
        } else {
          chainParms.needCustomer = true;
          chainParms.needPaymentMethod = true;
          chainParms.needSubscription = true;

          return Promise.resolve(null);
        }
      }; // processFindCustomerError

      const createCustomer = () => {
        if (chainParms.needCustomer) {
          const customerParms = {
            id: subscriptionInfo.wizeFiID,
            firstName: subscriptionInfo.firstName,
            lastName: subscriptionInfo.lastName,
            email: subscriptionInfo.email
          };

          return this.createCustomer(customerParms);
        } else {
          return Promise.resolve(null);
        }
      }; // createCustomer

      const processCreateCustomer = result => {
        if (result !== undefined && result !== null) {
          console.log('createCustomer: ', result); // %//
          resultParms.customer = result.customer;
        } else {
          console.log(resultParms.customer);
        }

        return Promise.resolve(null);
      }; // processCreateCustomer

      const createPaymentMethod = () => {
        if (chainParms.needPaymentMethod) {
          const paymentMethodParms = {
            customerId: subscriptionInfo.wizeFiID,
            paymentMethodNonce: subscriptionInfo.noncePayload.nonce
          };

          return this.createPaymentMethod(paymentMethodParms);
        } else {
          return Promise.resolve(null);
        }
      }; // createPaymentMethod

      const processCreatePaymentMethod = result => {
        if (result !== undefined && result !== null) {
          resultParms.paymentMethod = result.paymentMethod;
          chainParms.paymentMethodToken = result.paymentMethod.token;
        } else {
          chainParms.paymentMethodToken = resultParms.paymentMethod.token;
        }

        return Promise.resolve(null);
      }; // processCreatePaymentMethod

      const updateSubscription = () => {
        if (chainParms.needSubscriptionUpdate) {
          const subscriptionUpdateParms = {
            subscriptionID: chainParms.subscriptionID,
            parms: { paymentMethodToken: chainParms.paymentMethodToken }
          };

          return this.updateSubscription(subscriptionUpdateParms);
        } else {
          return Promise.resolve(null);
        }
      }; // updateSubscription

      const processUpdateSubscription = result => {
        if (result !== undefined && result !== null) {
          resultParms.subscription = result.subscription;
        } else {
        }

        return Promise.resolve(null);
      }; // processUpdateSubscription

      const createSubscription = () => {
        interface ISubscriptionParms {
          paymentMethodToken: string;
          planId: string;
          trialDuration?: number;
          trialDurationUnit?: string;
          trialPeriod?: boolean;
        }

        if (chainParms.needSubscription) {
          const subscriptionParms: ISubscriptionParms = {
            planId: 'WizeFiPlan',
            paymentMethodToken: chainParms.paymentMethodToken
          };
          if (subscriptionInfo.hasOwnProperty('trialPeriod')) {
            subscriptionParms.trialPeriod = subscriptionInfo.trialPeriod;
          }
          if (subscriptionInfo.hasOwnProperty('trialDuration')) {
            subscriptionParms.trialDuration = subscriptionInfo.trialDuration;
          }
          if (subscriptionInfo.hasOwnProperty('trialDurationUnit')) {
            subscriptionParms.trialDurationUnit = subscriptionInfo.trialDurationUnit;
          }

          return this.createSubscription(subscriptionParms);
        } else {
          return Promise.resolve(null);
        }
      }; // createSubscription

      const processCreateSubscription = result => {
        if (result !== undefined) {
          resultParms.subscription = result.subscription;
        }

        return Promise.resolve(null);
      }; // processCreateSubscription

      // initialize
      const chainParms: IChainParms = {
        customerID: subscriptionInfo.wizeFiID,
        noncePayload: subscriptionInfo.noncePayload,
        paymentMethodToken: 'unknown', // to be determined later
        needCustomer: true,
        needPaymentMethod: true,
        needSubscription: true,
        subscriptionID: 'unknown',
        needSubscriptionUpdate: false
      };

      // find customer, create customer (if necessary), create payment method for that customer (if necessary), create subscription for that customer (if necessary)
      findCustomer()
        .then(processFindCustomer)
        .catch(processFindCustomerError)
        .then(createCustomer)
        .then(processCreateCustomer)
        .then(createPaymentMethod)
        .then(processCreatePaymentMethod)
        .then(updateSubscription)
        .then(processUpdateSubscription)
        .then(createSubscription)
        .then(processCreateSubscription)
        .then(() => {
          resolveEstablishSubscription(resultParms);
        })
        .catch(err => {
          rejectEstablishSubscription(err);
        });
    }); // return Promise
  } // establishSubscription

  public findCustomer(customerID) {
    const action = 'findCustomer';
    const actionParms = { customerID };

    return this.invokeManageBraintree(action, actionParms);
  } // findCustomer

  public findPaymentMethod(paymentMethodToken) {
    const action = 'findPaymentMethod';
    const actionParms = { paymentMethodToken };

    return this.invokeManageBraintree(action, actionParms);
  } // findPaymentMethod

  public findSubscription(subscriptionID) {
    const action = 'findSubscription';
    const actionParms = { subscriptionID };

    return this.invokeManageBraintree(action, actionParms);
  } // findSubscription

  ////////////////////////////////////////////////////
  // transaction routines
  ////////////////////////////////////////////////////

  public findTransaction(transactionID) {
    const action = 'findTransaction';
    const actionParms = { transactionID };

    return this.invokeManageBraintree(action, actionParms);
  } // findTransaction

  ////////////////////////////////////////////////////
  // general routines
  ////////////////////////////////////////////////////

  public generateClientToken(customerID = null) {
    const action = 'generateClientToken';
    const actionParms = customerID !== undefined ? { customerID } : {};

    return this.invokeManageBraintree(action, actionParms);
  } // generateClientToken

  ////////////////////////////////////////////////////
  // Braintree data routines
  ////////////////////////////////////////////////////

  public getBraintreeData(customerID) {
    const action = 'getBraintreeData';
    const actionParms = { customerID };

    return this.invokeManageBraintree(action, actionParms);
  } // getBraintreeData

  public getSubscriptionRefundList(startDate, endDate) {
    return new Promise((resolve, reject) => {
      const processTransactionList = transactionList => {
        const subscriptionRefundList = [];

        for (const transaction of transactionList) {
          const wizeFiID = transaction.customer.id;
          const paymentDate = transaction.createdAt;
          const transactionID = transaction.id;
          const amount = transaction.amount;

          subscriptionRefundList.push({
            wizeFiID,
            paymentDate,
            braintreeEvent: {
              kind: 'subscription_refund',
              transactionID,
              subscription: { price: -amount }
            }
          });
        }

        return Promise.resolve(subscriptionRefundList);
      }; // processTransactionList

      // initialize
      const parms = { startDate, endDate, status: 'settled', type: 'credit' };

      // construct list of refund information for the given date
      this.searchTransactions(parms)
        .then(processTransactionList)
        .then(subscriptionRefundList => {
          resolve(subscriptionRefundList);
        })
        .catch(err => {
          reject(err);
        });
    }); // return Promise
  } // getSubscriptionRefundList

  public getUserDayDisputeRefunds(customerID, dayDate) {
    const action = 'getUserDayDisputeRefunds';
    const actionParms = { customerID, dayDate };
    return this.invokeManageBraintree(action, actionParms);
  } // getUserDayDisputeRefunds

  public getUserDayPayments(customerID, dayDate) {
    const action = 'getUserDayPayments';
    const actionParms = { customerID, dayDate };
    return this.invokeManageBraintree(action, actionParms);
  } // getUserMonthPayments

  /*
	listCustomerPaymentMethods(customerID)
	{
		let action = 'listCustomerPaymentMethods';getInitialSubscriptionInfo
		let actionParms = {customerID: customerID};
		return this.invokeManageBraintree(action,actionParms);
	}   // listCustomerPaymentMethods
	*/

  public getUserEmail(customerID) {
    return new Promise((resolve, reject) => {
      this.findCustomer(customerID)
        .then(customer => {
          resolve(customer.email);
        })
        .catch(err => {
          reject(err);
        });
    }); // return Promise
  } // getUserEmail

  public getUserMonthDisputeRefunds(customerID, monthDate) {
    const action = 'getUserMonthDisputeRefunds';
    const actionParms = { customerID, monthDate };
    return this.invokeManageBraintree(action, actionParms);
  } // getUserMonthDisputeRefunds

  public getUserMonthPayments(customerID, monthDate) {
    const action = 'getUserMonthPayments';
    const actionParms = { customerID, monthDate };
    return this.invokeManageBraintree(action, actionParms);
  } // getUserMonthPayments

  public hasActiveSubscription(customerID) {
    return new Promise((resolve, reject) => {
      const setResult = subscriptionList => {
        let i = -1;
        let haveActive = false;
        // eslint-disable-next-line no-cond-assign
        while ((i += 1) < subscriptionList.length && !haveActive) {
          // TODO consider whether active subscription is under WizeFiPlan
          if (subscriptionList[i].status === 'Active') {
            haveActive = true;
          }
        }

        return Promise.resolve(haveActive);
      }; // setResult

      const wantAll = true;

      this.listCustomerSubscriptions(customerID, wantAll)
        .then(setResult)
        .then(haveActive => {
          resolve(haveActive);
        })
        .catch(err => {
          reject(err);
        });
    }); // return Promise
  } // hasActiveSubscription

  public invokeManageBraintree(action: string, actionParms: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // set params to guide function invocation
      let payload: any = {
        action,
        actionParms
      };
      const params = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        FunctionName: 'manageBraintree',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Payload: JSON.stringify(payload)
      };

      // invoke lambda function to process data
      this.dataModelService.dataModel.global.lambda.invoke(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          if (!data.hasOwnProperty('Payload')) {
            reject('Data does not contain Payload attribute');
          } else {
            try {
              payload = JSON.parse(data.Payload);
              if (payload.hasOwnProperty('errorType')) {
                reject(payload.errorType);
              } else if (payload.hasOwnProperty('success') && !payload.success) {
                reject(payload.message);
              } else {
                resolve(payload);
              }
            } catch (ex) {
              reject(ex.message);
            }
          }
        }
      }); // lambda invoke
    }); // return Promise
  } // invokeManageBraintree

  public listCustomerPaymentMethods(customerID) {
    return new Promise((resolve, reject) => {
      const findCustomer = funcCustomerID => this.findCustomer(funcCustomerID); // findCustomer

      const processFindCustomer = result => {
        // initialize
        const paymentMethodList = [];

        // add each payment method to list
        for (const paymentMethod of result.paymentMethods) {
          const item = {
            paymentMethodToken: paymentMethod.token,
            createdAt: paymentMethod.createdAt,
            last4: paymentMethod.last4, // e.g. 1111
            maskedNumber: paymentMethod.maskedNumber, // e.g. 411111******1111
            cardType: paymentMethod.cardType, // e.g. Visa
            expirationMonth: paymentMethod.expirationMonth, // MM
            expirationYear: paymentMethod.expirationYear, // YYYY
            expirationDate: paymentMethod.expirationDate // MM/YYYY
          };
          paymentMethodList.push(item);
        } // for i

        return Promise.resolve(paymentMethodList);
      }; // processFindCustomer

      // construct the desired list of information
      findCustomer(customerID)
        .then(processFindCustomer)
        .then(paymentMethodList => {
          resolve(paymentMethodList);
        })
        .catch(err => {
          reject(err);
        });
    }); // return Promise
  } // listCustomerPaymentMethods

  public listCustomerSubscriptions(customerID, wantAll): Promise<SubscriptionList[]> {
    return new Promise((resolve, reject) => {
      const findCustomer = funcCustomerID => this.findCustomer(funcCustomerID); // findCustomer

      const processFindCustomer = result => {
        const subscriptionList: SubscriptionList[] = [];

        // add each payment method to list
        for (const paymentMethod of result.paymentMethods) {
          for (const subscription of paymentMethod.subscriptions) {
            const status = subscription.status;
            if (wantAll || (status !== 'Canceled' && status !== 'Expired')) {
              const item: SubscriptionList = {
                planID: subscription.planId,
                subscriptionID: subscription.id,
                subscriptionCreatedAt: subscription.createdAt,
                status,
                price: subscription.price,
                subscriptionPaymentMethodToken: subscription.paymentMethodToken,

                paymentMethodToken: paymentMethod.token,
                paymentCreatedAt: paymentMethod.createdAt,
                last4: paymentMethod.last4, // e.g. 1111
                maskedNumber: paymentMethod.maskedNumber, // e.g. 411111******1111
                cardType: paymentMethod.cardType, // e.g. Visa
                expirationMonth: paymentMethod.expirationMonth, // MM
                expirationYear: paymentMethod.expirationYear, // YYYY
                expirationDate: paymentMethod.expirationDate // MM/YYYY
              };
              subscriptionList.push(item);
            }
          }
        }

        return Promise.resolve(subscriptionList);
      };

      // construct the desired list of information
      findCustomer(customerID)
        .then(processFindCustomer)
        .then(subscriptionList => {
          resolve(subscriptionList);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  ////////////////////////////////////////////////////
  // dispute routines
  ////////////////////////////////////////////////////

  public searchDisputes(parms) {
    const action = 'searchDisputes';
    const actionParms = parms;

    return this.invokeManageBraintree(action, actionParms);
  } // searchTransactions

  public searchTransactions(parms) {
    const action = 'searchTransactions';
    const actionParms = parms;

    return this.invokeManageBraintree(action, actionParms);
  } // searchTransactions

  public setUserEmail(customerID, email) {
    return new Promise((resolve, reject) => {
      const parms = {
        customerID,
        parms: { email }
      };

      this.updateCustomer(parms)
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    }); // return Promise
  } // setUserEmail

  public updateCustomer(parms) {
    const action = 'updateCustomer';
    const actionParms = parms;

    return this.invokeManageBraintree(action, actionParms);
  } // updateCustomer

  public updatePaymentMethod(parms: { paymentMethodToken: string; parms: { [key: string]: any } }) {
    const action = 'updatePaymentMethod';
    const actionParms = parms;

    return this.invokeManageBraintree(action, actionParms);
  } // updatePaymentMethod

  public updateSubscription(parms) {
    const action = 'updateSubscription';
    const actionParms = parms;

    return this.invokeManageBraintree(action, actionParms);
  } // updateSubscription
} // class BraintreeManagement

export interface SubscriptionList {
  cardType: string;
  expirationDate: string;
  expirationMonth: string;
  expirationYear: string;
  last4: number;
  maskedNumber: string;
  paymentCreatedAt: string;
  paymentMethodToken: string;
  planID: string;
  price: string;
  status: string;
  subscriptionCreatedAt: string;
  subscriptionID: string;
  subscriptionPaymentMethodToken: string;
}
