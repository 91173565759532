import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataModelService } from '../../services/data-model/data-model.service';
import { AccountService } from './../../services/account/account.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  public static readonly FREE_TRIAL_SIGNUP_MODE = 'signup';
  public static readonly SUBSCRIPTION_SIGNUP_MODE = 'subscription';

  public loading = false;
  public signupMode: string;
  public signupComponentType = SignupComponent;
  public affiliateId: string;
  public affiliateName = '';
  public accessCode = '';
  public parentaffiliateData: any;

  constructor(public dataModelService: DataModelService, public accountService: AccountService, private activatedRoute: ActivatedRoute) {}

  public async ngOnInit() {
    this.signupMode = this.activatedRoute.snapshot.data.mode;
    this.removeDefaultPageStyling();
    // TODO: Get the affiliate id and display it correctly.
    this.parentaffiliateData = await this.accountService.getParentAffiliateInfo(this.activatedRoute);
    await this.setParentData(this.parentaffiliateData);
  }

  private async setParentData(parentData) {
    if (parentData && parentData.persistent) {
      this.affiliateName = parentData.persistent.profile.nameFirst + ' ' + parentData.persistent.profile.nameLast;
      this.accessCode = parentData.wizeFiID;
      return true;
    } else {
      console.error(parentData);
    }
  }

  private removeDefaultPageStyling(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('register');
    body.classList.add('h-100');
    document.getElementById('sidebar').style.display = 'none';
    document.getElementById('page-content').style.backgroundColor = 'rgb(47, 47, 47)';
    document.getElementById('page-content').style.boxShadow = 'none';
    document.getElementById('page-content').style.border = '0';
  }
}
