import { Component } from '@angular/core';
import { AccountTypeService } from 'src/app/services/account-type/account-type.service';
import { AccountService } from 'src/app/services/account/account.service';
import { DataModelService } from '../../../../services/data-model/data-model.service';
import { AccountDetailsComponent } from '../account-details/account-details.component';

@Component({
  selector: 'app-term-insurance-account-details',
  templateUrl: './term-insurance-account-details.component.html',
  styleUrls: ['../account-details-template/account-details-template.component.scss']
})
export class TermInsuranceAccountDetailsComponent extends AccountDetailsComponent {
  protected extraYouPayValue = 0;
  protected bal: number;
  public ngOnInit() {
    super.ngOnInit();
    this.bal = (this.wizeFiAccount && this.wizeFiAccount.accountValue && this.wizeFiAccount.accountValue.val) || this.plaidAccount.balance || 0;
  }

  constructor(public dataModelService: DataModelService, accountTypeService: AccountTypeService, accountService: AccountService) {
    super(dataModelService, accountTypeService, accountService);
  }

  protected onExpirationChange(value: string) {
    // @todo check that the date is actually in the future
    if (!this.wizeFiAccount.expirationDate) {
      this.wizeFiAccount.expirationDate = {};
    }
    this.wizeFiAccount.expirationDate.val = value;
  }

  protected onCoverageAmountChange(value: string) {
    this.wizeFiAccount.coverageAmount.val = parseFloat(value) || 0;
  }

  protected onPremiumChange(value: string) {
    this.wizeFiAccount.monthlyMinimum.val = parseFloat(value) || 0;
  }
}
