import { ChangeDetectorRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';

/**
 * How to use this directive?
 *
 * ```
 * <div *ngIfMediaQuery="'(min-width: 500px)'">
 *     Div element will exist only when media query matches, and created/destroyed when the viewport size changes.
 * </div>
 * ```
 */
@Directive({
  selector: '[ngIfMediaQuery]'
})
export class NgIfMediaQuery implements OnDestroy {
  /**
   * Called whenever the media query input value changes.
   */
  @Input()
  set ngIfMediaQuery(newMediaQuery: string) {
    if (!this.mql) {
      this.mql = window.matchMedia(newMediaQuery);

      /* Register for future events */
      this.mqlListener = mq => {
        this.onMediaMatchChange(mq.matches);
      };
      this.mql.addListener(this.mqlListener);
    }

    this.onMediaMatchChange(this.mql.matches);
  }
  public i = 0;
  private prevCondition: boolean = null;
  private mql: MediaQueryList;
  private mqlListener: any; // reference kept for cleaning up in ngOnDestroy()
  constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<object>, private ref: ChangeDetectorRef) {}

  public ngOnDestroy() {
    this.mql.removeListener(this.mqlListener);
    this.mql = this.mqlListener = null;
  }

  private onMediaMatchChange(matches: boolean) {
    if (matches && !this.prevCondition) {
      this.prevCondition = true;
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (!matches && this.prevCondition) {
      this.prevCondition = false;
      this.viewContainer.clear();
    }

    /**
     * Infinitive loop when we fire detectChanges during initialization
     * (first run on that func)
     */
    if (this.i > 0) {
      this.ref.detectChanges();
    } else {
      this.i++;
    }
  }
}
