import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { ILoading } from '../interfaces/iLoading.interface';
import { IResponse } from '../interfaces/iResponse.interface';
import { DataModelService } from '../services/data-model/data-model.service';

declare const FB: any;
declare const AWS: any;
declare const AWSCognito: any; // required when aws-cognito-sdk is defined in script tag in index.html
declare const AmazonCognitoIdentity: any; // required when amazon-cognito-identity.min.js is defined in script tag in index.html

export class LoginManagement {
  public isCollapsed = true;
  public loading: ILoading; // this is a "by reference" object {isLoading:boolean} set by the user of this class

  public cognitoUser: any; // cognitoUser value is set in the doCognitoLogin function (which must precede the use of some functions)
  public access_token: any; // token to establish AWS objects
  public access_token2: string; // Cognito access token
  public refreshToken: string; // used to refresh token using cognitoUser.refreshSession(refreshToken, callback)
  public sessionRefreshTimeoutID: any; // utilized in refreshing Cognito session token
  public timeoutID: any; // test value  //%//

  constructor(private router: Router, public dataModelService: DataModelService) {
    AWS.config.region = environment.AWSRegion;
    AWS.config.correctClockSkew = true;
    AWSCognito.config.region = environment.AWSRegion;
    this.loading = { isLoading: false };
  } // constructor

  /*
  Template for Facebook login
  ---------------------------
  this.dataModelService.loginManagement.fbLogin()
  .then((loginResponse) => {return this.dataModelService.loginManagement.fbGetInfo(loginResponse)})
  .then((logins) => {return this.dataModelService.loginManagement.establishAWSobjects(logins)})
  .then(() => {return this.dataModelService.dataManagement.fetchdata()})
  .then(() => {return this.dataModelService.dataManagement.loadDraftsInfo()})
  .then(() => {return this.dataModelService.getBraintreeData()})
  .then(() => {return this.dataModelService.plaidManagement.getPlaidData()})
  .then(() => {return this.dataModelService.initializeAffiliateManagement()})
  .then(() => {return this.dataModelService.loginManagement.setAffilliate()})
  .then(() => {return this.dataModelService.loginManagement.wrapup()})
  .then(() => {return this.dataModelService.loginManagement.redirect()})
  .catch((err) => {this.dataModelService.loginManagement.handleError(err)});

  Template for CognitoLogin
  -------------------------
  this.dataModelService.loginManagement.doCognitoLogin(email,password)
  .then((logins) => {return this.dataModelService.loginManagement.establishAWSobjects(logins)})
  .then(() => {return this.dataModelService.dataManagement.fetchdata()})
  .then(() => {return this.dataModelService.dataManagement.loadDraftsInfo()})
  .then(() => {return this.dataModelService.getBraintreeData()})
  .then(() => {return this.dataModelService.plaidManagement.getPlaidData()})
  .then(() => {return this.dataModelService.initializeAffiliateManagement()})
  .then(() => {return this.dataModelService.loginManagement.setAffilliate()})
  .then(() => {return this.dataModelService.loginManagement.wrapup()})
  .then(() => {return this.dataModelService.loginManagement.redirect()})
  .catch((err) => {this.dataModelService.loginManagement.handleError(err)});
  */

  ///////////////////////////////////////////////
  // routines for Facebook login
  ///////////////////////////////////////////////

  public initializeFacebook() {
    FB.init({
      appId: environment.FacebookAppID,
      autoLogAppEvents: true,
      status: true,
      xfbml: true,
      version: 'v2.10'
    });
  } // initializeFacebook

  public fbLogin(): Promise<any> {
    return new Promise((resolve, reject) => {
      FB.getLoginStatus((response: IResponse) => {
        if (response.status === 'connected') {
          console.log('already logged in'); // %//
          resolve(response);
        } else {
          FB.login(
            response => {
              if (response.authResponse) {
                console.log('new login'); // %//
                resolve(response);
              } else {
                this.dataModelService.showMessage('error', 'Login was not successful');
                console.log('Login was not successful'); // %//
                reject('Login was not successful');
              }
            },
            { scope: 'public_profile,email' }
            // note: add auth_type: 'reauthenticate' property to force authentication even if already logged in to Facebook
            // {scope: 'public_profile', auth_type: 'reauthenticate'}
          );
        }
      });
    });
  } // fbLogin

  public async fbGetInfo(loginResponse): Promise<any> {
    // use FaceBook ID as wizeFiID
    this.dataModelService.dataModel.global.isFBLogin = true;
    window.sessionStorage.setItem('isFBLogin', '1');
    if (this.dataModelService.dataModel.global.debugID !== '') {
      this.dataModelService.dataModel.global.wizeFiID = this.dataModelService.dataModel.global.debugID;
    } else {
      this.dataModelService.dataModel.global.wizeFiID = loginResponse.authResponse.userID;
    }

    this.access_token = loginResponse.authResponse.accessToken; // this works for AWS logins information (access to AWS services)

    // configure Lambda object for use in obtainLongLifeToken function
    const logins = {};
    logins['graph.facebook.com'] = this.access_token;
    AWS.config.region = environment.AWSRegion;
    AWS.config.correctClockSkew = true;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: environment.AWSIdentityPoolId,
      Logins: logins
    });
    this.dataModelService.dataModel.global.lambda = new AWS.Lambda();
    this.dataModelService.dataModel.global.docClient = new AWS.DynamoDB.DocumentClient();
    this.dataModelService.awsService.dynamodbClient = this.dataModelService.dataModel.global.docClient;
    this.dataModelService.dataModel.global.s3 = new AWS.S3();

    // exchange Facebook short life token for long life token
    let msg = '';
    try {
      const longLifeToken = (await this.obtainLongLifeToken(this.access_token).catch(err => {
        throw err;
      })) as any;
      if (typeof longLifeToken == 'object' && longLifeToken.hasOwnProperty('errorMessage')) {
        msg = longLifeToken.errorMessage;
      } else if (typeof longLifeToken != 'string') {
        msg = 'Unable to obtain long life Facebook token';
      } else {
        this.access_token = longLifeToken;
      }
    } catch (ex) {
      msg = 'Unable to obtain long life Facebook token';
    }

    if (msg !== '') {
      this.dataModelService.showMessage('warning', 'Unable to obtain long life Facebook token', 5000);
    }

    // set logins info for subsequent AWS configuration
    logins['graph.facebook.com'] = this.access_token;
    // NOTE: Facebook access_token is NOT a JWT token and Cognito access_token is a JWT token

    // save information to enable bypass of login after browser refresh
    window.sessionStorage.setItem('wizeFiID', this.dataModelService.dataModel.global.wizeFiID);
    window.sessionStorage.setItem('logins', JSON.stringify(logins));

    if (!this.dataModelService.dataModel.persistent.profile.email) {
      FB.api(
        '/me',
        {
          locale: 'en_US',
          // fields: "id,email,first_name,last_name,picture,birthday"
          fields: 'id,email,first_name,last_name,birthday' // remove picture -- it has been changed to work with Amazon S3
        },
        response2 => {
          this.dataModelService.dataModel.persistent.profile.email = response2.email;
          this.dataModelService.dataModel.persistent.profile.nameFirst = response2.first_name;
          this.dataModelService.dataModel.persistent.profile.nameLast = response2.last_name;
          // this.dataModelService.dataModel.persistent.profile.picture =
          response2.picture; // remove picture -- it has been changed to work with Amazon S3
        }
      );
    }
    return logins;
  } // fbGetInfo

  public obtainLongLifeToken(shortLifeToken) {
    return new Promise((resolve, reject) => {
      // set params to guide function invocation
      const payload = { shortLifeToken };
      const params = {
        FunctionName: 'obtainFacebookLongLifeToken',
        Payload: JSON.stringify(payload)
      };

      // invoke Lambda function to process data
      this.dataModelService.dataModel.global.lambda.invoke(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const Payload = JSON.parse(data.Payload);
          resolve(Payload);
        }
      }); // lambda invoke
    }); // return Promise
  } // obtainLongLifeToken

  ///////////////////////////////////////////////
  // routines for Cognito login
  ///////////////////////////////////////////////

  public getUserPool() {
    const poolData = {
      UserPoolId: environment.AWSUserPoolId,
      ClientId: environment.AWSClientId
    };
    const userPool = new AWSCognito.CognitoIdentityServiceProvider.CognitoUserPool(poolData);

    return userPool;
  } // getUserPool

  public getCognitoUser(username, userPool) {
    const userData = {
      Username: username.toLowerCase(),
      Pool: userPool
    };
    const cognitoUser = new AWSCognito.CognitoIdentityServiceProvider.CognitoUser(userData);

    return cognitoUser;
  } // getCognitoUser

  public checkEmailVerification(email) {
    return new Promise((resolveCheckEmailVerification, rejectCheckEmailVerification) => {
      const setNeedEmailVerification = result =>
        new Promise((resolve, reject) => {
          let haveEmail = false;
          let isVerified = false;

          for (let i = 0; i < result.length; i++) {
            haveEmail = true;
            for (let j = 0; j < result[i].Attributes.length; j++) {
              if (result[i].Attributes[j].Name == 'email_verified' && result[i].Attributes[j].Value == 'true') {
                isVerified = true;
              }
            }
          }

          const needEmailVerification = haveEmail && !isVerified;
          console.log('haveEmail: ' + haveEmail + '  isVerified: ' + isVerified + '  needEmailVerification: ' + needEmailVerification); // %//
          resolve(needEmailVerification);
        }); // return Promise // setNeedEmailVerification
      this.getCognitoUsersInfo(email)
        .then(setNeedEmailVerification)
        .then(needEmailVerification => {
          resolveCheckEmailVerification(needEmailVerification);
        })
        .catch(err => {
          rejectCheckEmailVerification(err);
        });
    }); // return Promise
  } // checkEmailVerification

  public getEmailCount(email) {
    return new Promise((resolveGetEmailCount, rejectGetEmailCount) => {
      const configureAWS = () =>
        new Promise((resolve, reject) => {
          AWS.config.update({ region: environment.AWSRegion });
          AWS.config.correctClockSkew = true;
          if (!AWS.config.hasOwnProperty('credentials') || AWS.config.credentials == null) {
            // clear credentials from localStorage to eliminate "Missing credentials in config" error
            const setting = 'aws.cognito.identity-id.' + environment.AWSIdentityPoolId;
            window.localStorage.removeItem(setting);

            // configure AWS object for unauthenticated user (one who has not yet logged in)
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
              IdentityPoolId: environment.AWSIdentityPoolId
            });
          }

          resolve();
        }); // return Promise // configureAWS
      const establishAWSobjects = () => {
        // establish AWS objects
        parms.lambda = new AWS.Lambda();

        return Promise.resolve();
      }; // establishAWSobjects

      const obtainEmailCount = () =>
        new Promise((resolve, reject) => {
          // set params to guide Lambda function invocation
          const payload = {
            action: 'getEmailCount',
            actionParms: {
              email: email.toLowerCase()
            }
          };
          const params = {
            FunctionName: 'preliminaryWork',
            Payload: JSON.stringify(payload)
          };

          // invoke Lambda function to process data
          parms.lambda.invoke(params, (err, data) => {
            if (err) {
              reject(err);
            } else {
              let payload = JSON.parse(data.Payload);
              if (typeof payload == 'string') {
                payload = Number(payload);
              }
              resolve(payload);
            }
          }); // lambda invoke
        }); // return Promise // obtainEmailCount
      const parms = { lambda: null };

      configureAWS()
        .then(establishAWSobjects)
        .then(obtainEmailCount)
        .then(emailCount => {
          resolveGetEmailCount(emailCount);
        })
        .catch(err => {
          rejectGetEmailCount(err);
        });
    }); // return Promise
  } // getEmailCount

  public getCognitoUsersInfo(email) {
    return new Promise((resolveGetCognitoUsersInfo, rejectGetCognitoUsersInfo) => {
      const configureAWS = () =>
        new Promise<void>((resolve, reject) => {
          AWS.config.update({ region: environment.AWSRegion });
          AWS.config.correctClockSkew = true;
          if (!AWS.config.hasOwnProperty('credentials') || AWS.config.credentials == null) {
            // clear credentials from localStorage to eliminate "Missing credentials in config" error
            const setting = 'aws.cognito.identity-id.' + environment.AWSIdentityPoolId;
            window.localStorage.removeItem(setting);
            console.log('cleared aws.cognito.identity-id setting in localStorage'); // %//

            // configure AWS object for unauthenticated user (one who has not yet logged in)
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
              IdentityPoolId: environment.AWSIdentityPoolId
            });
          }

          resolve();
        }); // return Promise // configureAWS
      const establishAWSobjects = () => {
        // establish AWS objects
        parms.lambda = new AWS.Lambda();

        return Promise.resolve();
      }; // establishAWSobjects

      const obtainCognitoUsersInfo = () =>
        new Promise((resolve, reject) => {
          // set params to guide Lambda function invocation
          const payload = {
            action: 'getCognitoUsersInfo',
            actionParms: {
              email: email.toLowerCase()
            }
          };
          const params = {
            FunctionName: 'preliminaryWork',
            Payload: JSON.stringify(payload)
          };

          // invoke Lambda function to process data
          parms.lambda.invoke(params, (err, data) => {
            if (err) {
              reject(err);
            } else {
              const payload = JSON.parse(data.Payload);
              resolve(payload);
            }
          }); // lambda invoke
        }); // return Promise // obtainCognitoUsersInfo
      const parms = { lambda: null };

      configureAWS()
        .then(establishAWSobjects)
        .then(obtainCognitoUsersInfo)
        .then(result => {
          resolveGetCognitoUsersInfo(result);
        })
        .catch(err => {
          rejectGetCognitoUsersInfo(err);
        });
    }); // return Promise
  } // getCognitoUsersInfo

  public checkDuplicateAccount(email): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      console.log('checked email -- ' + email);
      this.dataModelService.loginManagement
        .getEmailCount(email)
        .then(emailCount => {
          if (emailCount > 0) {
            reject('Your chosen email is associated to an existing account.  Please login to your account or try a different email.');
          } else {
            // put this inside an else just to prevent fall through...it shouldn't happen...but it could.
            resolve();
          }
          reject('your user email could not be identified, please refresh your browser & try again. -- Error: Duplicate account check Fall Through');
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  } // checkDuplicateAccount

  public registerUser(username, email, password, affiliateID) {
    return new Promise((resolve, reject) => {
      console.log('username', username.toLowerCase()); // %//
      console.log('affiliateID', affiliateID); // %//
      // initialize
      const userPool = this.getUserPool();
      const attributeList = [];
      const updateAttributesList = [
        { Name: 'custom:affiliateID', Value: affiliateID },
        { Name: 'email', Value: email.toLowerCase() }
      ];

      // process each attribute to be updated
      for (let i = 0; i < updateAttributesList.length; i++) {
        const attribute = new AWSCognito.CognitoIdentityServiceProvider.CognitoUserAttribute(updateAttributesList[i]);
        attributeList.push(attribute);
      }

      // add new user to User Pool
      userPool.signUp(username.toLowerCase(), password, attributeList, null, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve('Get verification code from email and use that value to complete registration');
        }
      });
    }); // return Promise
  } // registerUser

  public confirmUser(username, verificationCode) {
    return new Promise((resolve, reject) => {
      // initialize
      const userPool = this.getUserPool();
      const cognitoUser = this.getCognitoUser(username, userPool);

      cognitoUser.confirmRegistration(verificationCode, true, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve('User is confirmed');
        }
      });
    }); // return Promise
  } // confirmUser

  public resendConfirmationCode(username) {
    return new Promise((resolve, reject) => {
      // initialize
      const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();

      const params = {
        ClientId: environment.AWSClientId,
        Username: username.toLowerCase()
      };

      cognitoidentityserviceprovider.resendConfirmationCode(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    }); // return Promise
  } // resendConfirmationCode

  public getUserAttributeAffiliateID(UserAttributes) {
    let affiliateID = 'unknown';
    let i = -1;
    while (++i < UserAttributes.length && affiliateID == 'unknown') {
      if (UserAttributes[i].Name == 'custom:affiliateID') {
        affiliateID = UserAttributes[i].Value;
      }
    }
    return affiliateID;
  } // getUserAttributeAffiliateID

  public doCognitoLogin(username, password) {
    return new Promise((resolve, reject) => {
      // initialize
      const userPool = this.getUserPool();
      this.cognitoUser = this.getCognitoUser(username, userPool); // save value of cognitoUser in class variable for use by other routines

      const authenticationData = {
        Username: username.toLowerCase(),
        Password: password
      };
      const authenticationDetails = new AWSCognito.CognitoIdentityServiceProvider.AuthenticationDetails(authenticationData);

      this.cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: result => {
          // get access token information
          this.access_token = result.getIdToken().getJwtToken(); // this works for AWS logins information (access to AWS services)
          this.access_token2 = result.getAccessToken().getJwtToken(); // this works for getUser function
          this.refreshToken = result.getRefreshToken().getToken(); // used to refresh tokens
          const tokenInfo = this.getTokenInfo(this.access_token);
          console.log('Cognito session expires: ' + tokenInfo.exp + '  ' + this.unixTimeStampToISO(tokenInfo.exp));
          this.startSessionRefreshTimer(); // set things up for automatic refresh of Cognito session

          // get user information and process the results
          const params = { AccessToken: this.access_token2 };
          const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
          cognitoidentityserviceprovider.getUser(params, (err, data) => {
            if (err) {
              reject(err);
            } else {
              const affiliateID = this.getUserAttributeAffiliateID(data.UserAttributes);

              if (affiliateID == 'unknown') {
                reject('user ' + data.Username + ' does not have an affiliateID attribute');
              } else {
                // use affiliateID as wizeFiID
                if (this.dataModelService.dataModel.global.debugID !== '') {
                  this.dataModelService.dataModel.global.wizeFiID = this.dataModelService.dataModel.global.debugID;
                } else {
                  this.dataModelService.dataModel.global.wizeFiID = affiliateID;
                }

                // set logins info for subsequent AWS configuration
                const logins = {};
                logins[environment.AWSLoginType] = this.access_token;
                // NOTE: Facebook access_token is NOT a JWT token and Cognito access_token is a JWT token

                // save information to enable bypass of login after browser refresh
                window.sessionStorage.setItem('wizeFiID', this.dataModelService.dataModel.global.wizeFiID);
                window.sessionStorage.setItem('logins', JSON.stringify(logins));

                resolve(logins);
              }
            }
          }); // getUser
        },
        onFailure: err => {
          reject(err);
        }
      });
    }); // return Promise
  } // doCognitoLogin

  public getUserInformation() {
    return new Promise((resolve, reject) => {
      const params = { AccessToken: this.access_token2 };
      const cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
      cognitoidentityserviceprovider.getUser(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data);
        }
      });
    }); // return Promise
  } // getUserInformation

  public getUserAttributes() {
    return new Promise((resolve, reject) => {
      this.cognitoUser.getUserAttributes((err, result) => {
        if (err) {
          reject(err);
        } else {
          const userAttributes = {};
          for (let i = 0; i < result.length; i++) {
            userAttributes[result[i].getName()] = result[i].getValue();
          }
          resolve(userAttributes);
        }
      });
    }); // return Promise
  } // getUserAttributes

  public getUserAttribute(attributeName) {
    return new Promise((resolve, reject) => {
      this.cognitoUser.getUserAttributes((err, result) => {
        if (err) {
          reject(err);
        } else {
          let attributeValue = 'unknown';
          let i = -1;
          while (++i < result.length && attributeValue == 'unknown') {
            if (result[i].getName() == attributeName) {
              attributeValue = result[i].getValue();
            }
          }
          resolve(attributeValue);
        }
      });
    }); // return Promise
  } // getUserAttribute

  public getUserEmail() {
    return this.getUserAttribute('email');
  } // getUserEmail

  public getUserLoginEmail() {
    return new Promise((resolve, reject) => {
      let email;

      if (this.dataModelService.dataModel.global.isFBLogin) {
        // Facebook email
        email = this.dataModelService.dataModel.persistent.profile.email;
        resolve();
      } else {
        // Cognito email
        email = this.getUserAttribute('email')
          .then(() => {
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      }
    }); // return Promise
  } // getUserLoginEmail

  public getUserPreferredUsername() {
    return this.getUserAttribute('preferred_username');
  } // getUserPreferredUsername

  public getUserAffiliateID() {
    return this.getUserAttribute('custom:affiliateID');
  } // getUserAffiliateID

  public setUserAttributes(updateAttributesList) {
    return new Promise((resolve, reject) => {
      // initialize
      const attributeList = [];

      // process each attribute to be updated
      for (let i = 0; i < updateAttributesList.length; i++) {
        const attribute = new AWSCognito.CognitoIdentityServiceProvider.CognitoUserAttribute(updateAttributesList[i]);
        attributeList.push(attribute);
      }

      // Note: cognitoUser value is set in the doCognitoLogin function (which must precede the setUserAttributes action)
      this.cognitoUser.updateAttributes(attributeList, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    }); // return Promise
  } // setUserAttributes

  public setUserAttribute(attributeName, attributeValue) {
    return new Promise((resolve, reject) => {
      // initialize
      const attributeList = [];
      const attributeItem = { Name: attributeName, Value: attributeValue };
      const attribute = new AWSCognito.CognitoIdentityServiceProvider.CognitoUserAttribute(attributeItem);
      attributeList.push(attribute);

      this.cognitoUser.updateAttributes(attributeList, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    }); // return Promise
  } // setUserAttribute

  public setUserEmail(email) {
    return this.setUserAttribute('email', email);
  } // setUserEmail

  public setUserPreferredUsername(preferred_username) {
    return this.setUserAttribute('preferred_username', preferred_username);
  } // setUserPreferredUsername

  public setUserAffiliateID(affiliateID) {
    return this.setUserAttribute('custom:affiliateID', affiliateID);
  } // setUserAffiliateID

  public listAllUserAttributes() {
    return new Promise((resolve, reject) => {
      this.cognitoUser.getUserAttributes((err, result) => {
        if (err) {
          reject(err);
        } else {
          const userAttributes = {};
          for (let i = 0; i < result.length; i++) {
            userAttributes[result[i].getName()] = result[i].getValue();
          }
          resolve(userAttributes);
        }
      });
    }); // return Promise
  } // listAllUserAttributes

  public deleteUser() {
    return new Promise((resolve, reject) => {
      // TODO -- make user verify deletion is to take place?  or is this done elsewhere?
      this.cognitoUser.deleteUser((err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    }); // return Promise
  } // deleteUser

  public changePassword(oldPassword, newPassword) {
    return new Promise((resolve, reject) => {
      this.cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    }); // return Promise
  } // changePassword

  public requestPasswordChangeVerificationCode(username) {
    return new Promise((resolve, reject) => {
      // initialize
      const userPool = this.getUserPool();
      const cognitoUser = this.getCognitoUser(username, userPool);

      cognitoUser.forgotPassword({
        onSuccess: result => {
          resolve(result);
        },
        onFailure: err => {
          reject(err);
        }
      });
    }); // return Promise
  } // requestPasswordChangeVerificationCode

  public applyPasswordChangeVerificationCode(username, verificationCode, newPassword) {
    return new Promise((resolve, reject) => {
      // initialize
      const userPool = this.getUserPool();
      const cognitoUser = this.getCognitoUser(username, userPool);

      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: result => {
          resolve('Password has been changed');
        },
        onFailure: err => {
          reject(err);
        }
      });
    }); // return Promise
  } // applyPasswordChangeVerificationCode

  public requestUserAttributeVerificationCode(attribute) {
    return new Promise((resolve, reject) => {
      this.cognitoUser.getAttributeVerificationCode(attribute, {
        onSuccess(result) {
          resolve(result);
        },
        onFailure(err) {
          reject(err);
        }
      });
    }); // return Promise
  } // requestUserAttributeVerificationCode

  public applyUserAttributeVerifcationCode(attribute, verificationCode) {
    return new Promise((resolve, reject) => {
      this.cognitoUser.verifyAttribute(attribute, verificationCode, {
        onSuccess(result) {
          resolve(result);
        },
        onFailure(err) {
          reject(err);
        }
      });
    }); // return Promise
  } // applyUserAttributeVerifcationCode

  ///////////////////////////////////////////////
  // routines for either type of login
  ///////////////////////////////////////////////

  public establishAWSobjects(logins: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // clear credentials from localStorage to eliminate "Missing credentials in config" error
      const setting = 'aws.cognito.identity-id.' + environment.AWSIdentityPoolId;
      window.localStorage.removeItem(setting);

      // configure AWS object
      AWS.config.region = environment.AWSRegion;
      AWS.config.correctClockSkew = true;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: environment.AWSIdentityPoolId,
        Logins: logins
      });

      // establish AWS service objects
      this.dataModelService.dataModel.global.lambda = new AWS.Lambda();
      this.dataModelService.dataModel.global.docClient = new AWS.DynamoDB.DocumentClient();
      this.dataModelService.awsService.dynamodbClient = this.dataModelService.dataModel.global.docClient;
      this.dataModelService.dataModel.global.s3 = new AWS.S3();

      resolve();
    }); // return Promise
  } // establishAWSobjects

  public checkReferral(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.dataModelService.dataModel.global.isNewUser) {
        resolve();
        return;
      }
      const referral = window.localStorage.getItem('a');
      let referralID = this.dataModelService.affiliateManagement.rootAffiliateID;
      // referral can be an affiliate alias or an affiliate ID
      if (referral) {
        if (this.dataModelService.affiliateManagement.ID2Alias.hasOwnProperty(referral)) {
          referralID = referral;
        } else if (this.dataModelService.affiliateManagement.Alias2ID.hasOwnProperty(referral)) {
          referralID = this.dataModelService.affiliateManagement.Alias2ID[referral];
        }
      }

      this.dataModelService.dataModel.persistent.header.parentAffiliateID = referralID;
      resolve();
    });
  }

  public setAffilliate(generate: boolean = false): Promise<any> {
    // @TODO this only works for authenticated users
    return new Promise((resolve, reject) => {
      if (!this.dataModelService.dataModel.global.isNewUser) {
        resolve();
        return;
      }

      if (generate) {
        this.dataModelService.dataModel.affiliateID = this.dataModelService.affiliateManagement.generateAffiliateID();
      } else {
        this.dataModelService.dataModel.affiliateID = this.dataModelService.dataModel.global.wizeFiID;
      }

      this.dataModelService.dataModel.affiliateAlias = this.dataModelService.affiliateManagement.generateAffiliateAlias(
        this.dataModelService.dataModel.affiliateID
      );
      resolve();
    });
  } // setAffiliate

  public async wrapup(): Promise<any> {
    if (!this.dataModelService.dataModel.global.isNewUser) {
      return;
    }

    // params necessary to create a node
    const node: any = {
      affiliateID: this.dataModelService.dataModel.affiliateID,
      affiliateAlias: this.dataModelService.dataModel.affiliateAlias,
      fee: this.dataModelService.affiliateManagement.defaultFee,
      parentAffiliate: this.dataModelService.dataModel.persistent.header.parentAffiliateID
    };

    // add to affiliate tree
    await this.dataModelService.affiliateManagement.addNode(node.affiliateID, node.affiliateAlias, node.fee, node.parentAffiliate);
    this.dataModelService.dataModel.persistent.header.wizeFiAccountStatus = 'active';
    await this.dataModelService.dataManagement.storeinfo();
  }

  public async redirect(subscription = false): Promise<any> {
    const year = this.dataModelService.getCurrentYear();
    const month = this.dataModelService.getCurrentMonth();
    this.dataModelService.dataModel.global.isLoggedIn = true;
    const body = document.getElementsByTagName('body')[0];

    // Intercom Init
    (window as any).Intercom('boot', {
      app_id: environment.IntercomID,
      user_id: this.dataModelService.dataModel.global.wizeFiID,
      email: this.dataModelService.dataModel.persistent.profile.email,
      name: `${this.dataModelService.dataModel.persistent.profile.nameFirst} ${this.dataModelService.dataModel.persistent.profile.nameLast}`
    });

    // DEBUG
    if (this.dataModelService.dataModel.global.debug && this.dataModelService.dataModel.global.screen !== '') {
      body.classList.remove('login');
      body.classList.add('h-100');

      this.router.navigateByUrl('/' + this.dataModelService.dataModel.global.screen);
    } else if (this.dataModelService.dataModel.global.braintreeData.subscriptionInfo.status === 'Active') {
      if (this.dataModelService.dataModel.persistent.header.dateProfileCompleted === '') {
        body.classList.remove('login');
        body.classList.add('h-100');
        if (
          this.dataModelService.dataModel.persistent.header.setupStepCompleted < 6 &&
          Object.keys(this.dataModelService.dataModel.persistent.plans).length > 1
        ) {
          Object.keys(this.dataModelService.dataModel.persistent.plans).forEach(plan => {
            if (plan !== 'original') {
              // delete plan from memory storage
              delete this.dataModelService.dataModel.persistent.plans[plan];
              delete this.dataModelService.dataModel.global.plansOldData[plan];
              // delete plan from persistent storage (DynamoDB)
              this.dataModelService.dataManagement.deleteMonthPlan(this.dataModelService.dataModel.affiliateID, plan);
            }
          });
        }
        if (this.dataModelService.dataModel.persistent.header.setupStepCompleted < 6) {
          this.dataModelService.setCurPlan('original');
        }
        body.classList.remove('login');
        body.classList.add('h-100');

        switch (this.dataModelService.dataModel.persistent.header.setupStepCompleted) {
          case 0: {
            return this.router.navigateByUrl('/setup/connect-bank');
          }
          case 1: {
            return this.router.navigateByUrl('/setup/review-accounts');
          }
          case 2: {
            return this.router.navigateByUrl('/setup/goals');
          }
          case 3: {
            return this.router.navigateByUrl('/setup/income');
          }
          case 4: {
            return this.router.navigateByUrl('/setup/spending');
          }
          case 5: {
            return this.router.navigateByUrl('/setup/review');
          }
          case 6: {
            return this.router.navigateByUrl('/setup/projections');
          }
          case 7: {
            return this.router.navigateByUrl('/setup/create-cafr');
          }
          case 8: {
            return this.router.navigateByUrl('/setup/apply-cafr');
          }
          case 9: {
            return this.router.navigateByUrl('/setup/finish');
          }
          default: {
            return this.router.navigateByUrl('/setup/connect-bank');
          }
        }
      } else {
        if (!subscription) {
          const planName = this.dataModelService.dataManagement.makePlanName(year, month);
          await this.dataModelService.dataManagement.changeCurrentPlan(planName);
        }

        body.classList.remove('login');
        body.classList.add('h-100');

        this.router.navigateByUrl('/plan#income');
      }
    } else {
      if (this.dataModelService.dataModel.persistent.header.dateProfileCompleted) {
        this.dataModelService.showErrorMessage('Your subscription is no longer active.', 7000);
      }
      body.classList.remove('login');
      this.router.navigateByUrl('/subscription');
    }
    this.loading.isLoading = false;
  } // redirect

  public validateEmailRedirect = (affiliateID): void => {
    this.router.navigateByUrl('/validate-email?u=' + encodeURI(affiliateID));
  };

  public resetPasswordRedirect = (email): void => {
    this.router.navigateByUrl('/reset-password?u=' + encodeURI(email));
  };

  public handleError(err) {
    this.loading.isLoading = false;
    if (typeof err === 'string') {
      this.dataModelService.showErrorMessage(err, 5000);
    } else if (err.hasOwnProperty('message')) {
      if (err.message !== "Cannot read property 'toLowerCase' of undefined") {
        this.dataModelService.showMessage('error', err.message, 5000);
      }
    }
  }

  public unixTimeStampToISO = timestamp => new Date(timestamp * 1000).toISOString(); // unixTimeStampToISO

  public getTokenInfo(token) {
    try {
      // get token header
      const base64HeaderUrl = token.split('.')[0];
      const base64Header = base64HeaderUrl.replace('-', '+').replace('_', '/');
      const headerData = JSON.parse(window.atob(base64Header));

      // get token payload
      const base64PayloadUrl = token.split('.')[1];
      const base64Payload = base64PayloadUrl.replace('-', '+').replace('_', '/');
      const payloadData = JSON.parse(window.atob(base64Payload));
      payloadData.header = headerData;
      return payloadData;
    } catch (err) {
      return {};
    }
  } // getTokenInfo

  public refreshSession() {
    return new Promise((resolveRefreshSession, rejectRefreshSession) => {
      const getRefreshedToken = () =>
        new Promise((resolve, reject) => {
          const token = new AmazonCognitoIdentity.CognitoRefreshToken({
            RefreshToken: this.refreshToken
          });

          this.cognitoUser.refreshSession(token, (err, result) => {
            if (err) {
              reject(err);
            } else {
              // set updated tokens
              this.access_token = result.getIdToken().getJwtToken(); // this works for AWS logins information
              this.access_token2 = result.getAccessToken().getJwtToken(); // this works for Cognito getUser function
              this.refreshToken = result.getRefreshToken().getToken(); // used to refresh tokens
              const logins = {};
              logins[environment.AWSLoginType] = this.access_token;
              resolve(logins);
            }
          });
        }); // return Promise // getRefreshedToken
      getRefreshedToken()
        .then(logins => this.establishAWSobjects(logins))
        .then(() => {
          resolveRefreshSession();
        })
        .catch(err => {
          rejectRefreshSession(err);
        });
    }); // return Promise
  } // refreshSession

  public startSessionRefreshTimer() {
    const timeUpTime = 3000000; // number of milliseconds after which to refresh Cognito session (set to 50 minutes since default is one hour until expiration)
    // NOTE: must use fat arrow function definition as shown below in order to keep the proper "this" in effect
    this.sessionRefreshTimeoutID = setTimeout(() => {
      this.handleSessionRefresh();
    }, timeUpTime);
  } // startSessionRefreshTimer

  public stopSessionRefreshTimer() {
    clearTimeout(this.sessionRefreshTimeoutID);
  } // stopSessionRefreshTimer

  public handleSessionRefresh() {
    this.refreshSession()
      .then(() => {
        this.startSessionRefreshTimer();
      })
      .catch(err => {
        console.error(err);
      });
    // TODO handle error above in manner appropriate for production version of app
  } // handleSessionRefresh
} // LoginManagement
