import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { BrowserModule } from '@angular/platform-browser';
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask/src/currency-mask.config';
import { ToastaModule } from 'ngx-toasta';

import { TextMaskModule } from 'angular2-text-mask';
import { AppComponent } from '../components/app/app.component';
import { ButtonComponent } from '../components/button/button.component';
import { DateDropdownComponent } from '../components/date-dropdown/date-dropdown.component';
import { FooterComponent } from '../components/footer/footer.component';
import { HeaderComponent } from '../components/header/header.component';
import { HamburgerButton } from '../components/header/menu/hamburger-button/hamburger.component';
import { MenuComponent } from '../components/header/menu/menu.component';
import { SetupFlowHeaderComponent } from '../components/header/setup-flow-header/setup-flow-header.component';
import { CloseIconComponent } from '../components/iconography/close-icon/close-icon.component';
import { SearchIconComponent } from '../components/iconography/search-icon/search-icon.component';
import { SpinnerComponent } from '../components/iconography/spinner/spinner.component';
import { InfoDropdownComponent } from '../components/info-dropdown/info-dropdown.component';
import { LoaderComponent } from '../components/loader/loader.component';
import { ModalComponent } from '../components/modal/modal.component';
import { ProgressBarComponent } from '../components/progress-bar/progress-bar.component';
import { SettingsDeactivateGuard } from '../components/settings/settings-deactivate.guard';
import { SetupStepMenuItemComponent } from '../components/setup-flow/setup-step-menu/menu-item/setup-step-menu-item.component';
import { SetupStepMenuComponent } from '../components/setup-flow/setup-step-menu/setup-step-menu.component';
import { SidebarComponent } from '../components/sidebar/sidebar.component';
import { TextInputComponent } from '../components/text-input/text-input.component';
import { DataModelService } from '../services/data-model/data-model.service';
import { LogMessageService } from '../services/log-message/log-message.service';
import { SharedModule } from '../shared/shared.module';
import { CustomErrorHandler } from '../utilities/custom-error-handler.class';
import { ScreenLoginGuard } from '../utilities/screen-login.guard';

@NgModule({
  declarations: [
    SidebarComponent,
    HamburgerButton,
    MenuComponent,
    HeaderComponent,
    SetupFlowHeaderComponent,
    SetupStepMenuItemComponent,
    SetupStepMenuComponent,
    FooterComponent,
    LoaderComponent,
    ButtonComponent,
    TextInputComponent,
    InfoDropdownComponent,
    ModalComponent,
    SpinnerComponent,
    SearchIconComponent,
    CloseIconComponent,
    ProgressBarComponent,
    DateDropdownComponent
  ],
  imports: [BrowserModule, FormsModule, ToastaModule.forRoot(), MatButtonModule, SharedModule, TextMaskModule],
  exports: [
    SidebarComponent,
    HamburgerButton,
    MenuComponent,
    HeaderComponent,
    SetupFlowHeaderComponent,
    SetupStepMenuItemComponent,
    SetupStepMenuComponent,
    FooterComponent,
    LoaderComponent,
    ButtonComponent,
    TextInputComponent,
    InfoDropdownComponent,
    ModalComponent,
    SpinnerComponent,
    SearchIconComponent,
    CloseIconComponent,
    ToastaModule,
    ProgressBarComponent,
    DateDropdownComponent
  ],
  providers: [
    DataModelService,
    LogMessageService,
    SettingsDeactivateGuard,
    ScreenLoginGuard,
    { provide: ErrorHandler, useClass: CustomErrorHandler } // this leads to error: Cannot instantiate cyclic dependency
  ],
  bootstrap: [AppComponent]
})
export class StructureModule {}
